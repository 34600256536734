import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from "@angular/core";

@Component({
  selector: "bl-users-header",
  templateUrl: "./users-header.component.html",
  styleUrls: ["./users-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersHeaderComponent {
  @Output() usersHeaderChangeInput: EventEmitter<string> = new EventEmitter();
  @Output() usersHeaderClickAddUser: EventEmitter<any> = new EventEmitter();

  handleInput(value: string): void {
    this.usersHeaderChangeInput.emit(value);
  }

  openAddUserPopUp(): void {
    this.usersHeaderClickAddUser.emit();
  }
}
