import { ITableHeaders } from "@ui/tables/components/table/table.component";

import { IDashboardProductQueryParams } from "../../dashboard/interfaces";

import { MobileDropDownParams } from "@ui/drop-down/mobile/mobile-drop-down/models";

import {
  DASHBOARD_ENTITY_TYPES,
  DASHBOARD_SORT_BY,
  DASHBOARD_SORT_DIRECTION,
} from "../../dashboard/constants/dashboard";

export const VIEWS_DEFAULT_QUERY_PARAMS: IDashboardProductQueryParams = {
  sortDirection: DASHBOARD_SORT_DIRECTION.DESC,
  sortBy: DASHBOARD_SORT_BY.CREATE_AT,
  entityType: [DASHBOARD_ENTITY_TYPES.VIEWS],
};

export const DASHBOARD_VIEW_DEFAULT_PARAMS: MobileDropDownParams = {
  scrollOnToggle: true,
};

export const DASHBOARD_VIEW_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "View name",
    sortable: true,
  },
  {
    key: "entityType",
    title: "View type",
  },
  {
    key: "count",
    title: "Records",
    sortable: true,
  },
  {
    key: "created_at",
    title: "Creation date",
    sortable: true,
  },
  {
    key: "updated_at",
    title: "Last modified",
    sortable: true,
  },
  {
    key: "",
    title: "",
  },
];
