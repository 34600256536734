import { Component } from "@angular/core";

import { CORE_PATHS } from "../../constants/core-paths";

@Component({
  selector: "bl-not-found-page",
  templateUrl: "./not-found-page.component.html",
  styleUrls: ["./not-found-page.component.scss"],
})
export class NotFoundPageComponent {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
}
