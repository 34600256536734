import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IListTokenData } from "@core/interfaces";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  CHECK_DOWNLOAD_LIST_BY_TOKEN = "[DOWNLOAD LARGE LIST]: Check if large list available by token",
  START_DOWNLOAD_LIST_BY_TOKEN = "[DOWNLOAD LARGE LIST]: Start Download large list by token",
  DOWNLOAD_LARGE_LIST_ERROR = "[DOWNLOAD LARGE LIST]: Download large list is fail",
}

export const checkDownloadListByTokenAction: CreateActionType<
  ACTION_TYPES.CHECK_DOWNLOAD_LIST_BY_TOKEN,
  IListTokenData
> = createAction(
  ACTION_TYPES.CHECK_DOWNLOAD_LIST_BY_TOKEN,
  createPayload<IListTokenData>(),
);
export const startDownloadLargeListByTokenAction: CreateActionType<
  ACTION_TYPES.START_DOWNLOAD_LIST_BY_TOKEN,
  IListTokenData
> = createAction(
  ACTION_TYPES.START_DOWNLOAD_LIST_BY_TOKEN,
  createPayload<IListTokenData>(),
);
export const downloadLargeListErrorAction: CreateActionType<
  ACTION_TYPES.DOWNLOAD_LARGE_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DOWNLOAD_LARGE_LIST_ERROR,
  createPayload<IServerError>(),
);
