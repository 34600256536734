import { ITableHeader } from "@ui/tables/components/table/table.component";

export enum COLLEGE_CAMPUSES_SORTING {
  NAME = "name",
}

export const COLLEGE_CAMPUSES_TABLE_HEADERS: ITableHeader[] = [
  {
    key: "name",
    title: "Name",
    sortable: true,
  },
  {
    key: "schoolType",
    title: "School Type",
    sortable: true,
  },
  {
    key: "city",
    title: "City",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "enrollment",
    title: "Enrollment",
    sortable: true,
  },
];

export const MAX_CACHE_COLLEGE_CAMPUSES: number = 180;
