import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cdl-list-history.action";

import { IPagination } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import { ICDLListHistoryItem } from "../../interfaces";

import { ICDLListHistoryRequest } from "../../models";

import {
  DEFAULT_LIST_HISTORY_LIMIT,
  DEFAULT_LIST_HISTORY_OFFSET,
} from "../../constants";

export interface ICDLListHistoryState {
  cdlListHistory: ICDLListHistoryItem[];
  total: number;
  loading: boolean;
  error: IServerError | null;
}

const initialState: ICDLListHistoryState = {
  cdlListHistory: [],
  total: 0,
  loading: false,
  error: null,
};

const getCDLListHistory: OnReducer<ICDLListHistoryState, ActionType<any>> = (
  state: ICDLListHistoryState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getCDLListHistoryError: OnReducer<
  ICDLListHistoryState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListHistoryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: payload,
});

const getCDLListHistorySuccess: OnReducer<
  ICDLListHistoryState,
  ActionType<Payload<any>>
> = (
  state: ICDLListHistoryState,
  {
    payload: {
      response: { items, total },
    },
  }: Payload<any>,
) => ({
  ...state,
  loading: false,
  cdlListHistory: items,
  total,
});

const resetState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLListHistoryState> =
  createReducer<ICDLListHistoryState>(
    initialState,

    on(actions.getCDLListHistoryAction, getCDLListHistory),
    on(actions.getCDLListHistoryErrorAction, getCDLListHistoryError),
    on(actions.getCDLListHistorySuccessAction, getCDLListHistorySuccess),

    on(actions.resetCDLListHistoryStateAction, resetState),
  );

export function cdlListHistoryReducer(
  state: ICDLListHistoryState,
  action: Action,
): ICDLListHistoryState {
  return reducer(state, action);
}

export const cdlListHistoryQueryParams: GetFromState<
  Partial<ICDLListHistoryRequest>,
  string,
  string,
  IPagination
> = (
  sortDirection: string,
  sortBy: string,
  searchParams: IPagination,
): Partial<ICDLListHistoryRequest> => {
  const { limit, offset }: IPagination = searchParams;

  return {
    sortDirection,
    sortBy,
    limit,
    offset,
  };
};

export const cdlListHistoryCurrentPage: GetFromState<
  number,
  Partial<ICDLListHistoryRequest>
> = ({
  offset = DEFAULT_LIST_HISTORY_OFFSET,
  limit = DEFAULT_LIST_HISTORY_LIMIT,
}: Partial<ICDLListHistoryRequest>): number => Math.ceil(offset / limit) + 1;

export const cdlListHistorySearchParams: GetFromState<
  Partial<ICDLListHistoryRequest>,
  Partial<ICDLListHistoryRequest>
> = ({
  offset = DEFAULT_LIST_HISTORY_OFFSET,
  limit = DEFAULT_LIST_HISTORY_LIMIT,
}: Partial<ICDLListHistoryRequest>): Partial<ICDLListHistoryRequest> => ({
  offset,
  limit,
});

export const cdlListHistory: GetFromState<
  ICDLListHistoryItem[],
  ICDLListHistoryState
> = (state: ICDLListHistoryState): ICDLListHistoryItem[] =>
  state && state.cdlListHistory;
export const cdlListHistoryLoading: GetFromState<
  boolean,
  ICDLListHistoryState
> = (state: ICDLListHistoryState): boolean => state && state.loading;
export const cdlListHistoryTotalCount: GetFromState<
  number,
  ICDLListHistoryState
> = (state: ICDLListHistoryState): number => state.total;
