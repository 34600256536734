import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-alerts-overview.action";

import { IProductCounter } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { IOverview } from "../../interfaces";

export interface IViewAlertsOverviewState {
  overview: IOverview | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IViewAlertsOverviewState = {
  overview: null,
  pending: false,
  error: null,
};

const getViewAlertsOverview: OnReducer<
  IViewAlertsOverviewState,
  ActionType<any>
> = (state: IViewAlertsOverviewState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewAlertsOverviewError: OnReducer<
  IViewAlertsOverviewState,
  ActionType<Payload<IServerError>>
> = (state: IViewAlertsOverviewState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewAlertsOverviewSuccess: OnReducer<
  IViewAlertsOverviewState,
  ActionType<Payload<any>>
> = (state: IViewAlertsOverviewState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  overview: { ...payload },
});

const reducer: ActionReducer<IViewAlertsOverviewState> =
  createReducer<IViewAlertsOverviewState>(
    initialState,

    on(actions.getViewAlertsOverviewAction, getViewAlertsOverview),
    on(actions.getViewAlertsOverviewErrorAction, getViewAlertsOverviewError),
    on(
      actions.getViewAlertsOverviewSuccessAction,
      getViewAlertsOverviewSuccess,
    ),
  );

export function viewAlertsOverviewReducer(
  state: IViewAlertsOverviewState,
  action: Action,
): IViewAlertsOverviewState {
  return reducer(state, action);
}

export const overview: GetFromState<
  IOverview | {},
  IViewAlertsOverviewState
> = (state: IViewAlertsOverviewState): IOverview | {} => state.overview || {};
export const overviewCounter: GetFromState<IProductCounter, IOverview> = ({
  institutionsCount,
  grantsCount,
  rfpIbfCount,
  count,
}: IOverview): IProductCounter => ({
  institutionsCount,
  grantsCount,
  rfpIbfCount,
  count,
});

export const name: GetFromState<string, IOverview> = (
  overviewData: IOverview,
): string => overviewData.name;

export const overviewPending: GetFromState<
  boolean,
  IViewAlertsOverviewState
> = (state: IViewAlertsOverviewState): boolean => state.pending;
export const overviewError: GetFromState<
  IServerError,
  IViewAlertsOverviewState
> = (state: IViewAlertsOverviewState): IServerError => state.error;
