export enum FLAT_INPUT_THEME {
  DEFAULT = "default",
  SIMPLE = "simple",
  MOBILE = "mobile",
  MAT_LIKE = "mat-like",
}

export enum INPUT_AUTOCOMPLETE {
  ON = "on",
  OFF = "off",
  NEWPASSWORD = "new-password",
}

export enum PASSWORD_CRITERIA_TYPE {
  DIGIT = "onlyDigits",
  LOWER = "onlyLowerCase",
  UPPER = "onlyUpperCase",
  SYMBOL = "onlySymbols",
  MIN = "min",
}
