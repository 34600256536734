import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { getOpenedJobBrowse } from "@modules/segment/store/selectors/jobs-browse.selector";
import {
  doNotShowGeoSuggestTooltip,
  isChangedJobTag,
  lastIdTooltip,
  showCalculatorSegmentPageInfo,
  showFirstTimeSegmentPageInfo,
  showRefreshCreditsTooltipOnInit,
  tooltips,
} from "../reducers/tooltips.reducer";

import { ITooltips } from "@core/interfaces/tooltip";

import { selectTooltipsState } from "./";

export const getTooltips: MemoizedSelector<CoreState, ITooltips> =
  createSelector(selectTooltipsState, tooltips);
export const getLastIdTooltip: MemoizedSelector<CoreState, number> =
  createSelector(getTooltips, lastIdTooltip);

export const getShouldShowFirstTimeSegmentPageInfo: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(selectTooltipsState, showFirstTimeSegmentPageInfo);
export const getShouldShowCalculatorSegmentPageInfo: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(selectTooltipsState, showCalculatorSegmentPageInfo);
export const getShowRefreshCreditsTooltipOnInit: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(selectTooltipsState, showRefreshCreditsTooltipOnInit);
export const getDoNotShowGeoSuggestTooltip: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(selectTooltipsState, doNotShowGeoSuggestTooltip);
export const getIsChangedJobTag: MemoizedSelector<CoreState, boolean> =
  createSelector(selectTooltipsState, isChangedJobTag);

export const getIsShowJobTagTooltip: MemoizedSelector<CoreState, boolean> =
  createSelector(
    getIsChangedJobTag,
    getOpenedJobBrowse,
    (isChangedTag: boolean, isOpenBrowse: boolean): boolean =>
      isChangedTag && !isOpenBrowse,
  );
