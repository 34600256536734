import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IProductResultsResponse } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { IRecipients } from "../../interfaces/recipients";

enum ACTION_TYPES {
  GET = "[GRANT PROFILE RECIPIENTS] Get",
  GET_ERROR = "[GRANT PROFILE RECIPIENTS] Get error",
  GET_SUCCESS = "[GRANT PROFILE RECIPIENTS] Get success",

  SORT = "[GRANT PROFILE RECIPIENTS] Sort",
  SORT_ERROR = "[GRANT PROFILE RECIPIENTS] Sort error",
  SORT_SUCCESS = "[GRANT PROFILE RECIPIENTS] Sort success",

  ORDER = "[GRANT PROFILE RECIPIENTS] Order",

  TOGGLE = "[GRANT PROFILE RECIPIENT] Toggle",

  SET_CHECKED_RECIPIENT = "[GRANT PROFILE RECIPIENT] Toggle recipient",
  SET_ALL_RECIPIENT_CHECKED = "[GRANT PROFILE RECIPIENTS] Set all recipient checked state",

  DOWNLOAD_FILE_RECIPIENTS = "[GRANT PROFILE RECIPIENTS] download file",
  DOWNLOAD_FILE_RECIPIENTS_ERROR = "[GRANT PROFILE RECIPIENTS] download file error",
  DOWNLOAD_FILE_RECIPIENTS_SUCCESS = "[GRANT PROFILE RECIPIENTS] download file success",

  RESET = "[GRANT PROFILE RECIPIENTS] Reset state",
}

export const getGrantProfileRecipientsAction: CreateActionType<
  ACTION_TYPES.GET,
  number
> = createAction(ACTION_TYPES.GET, createPayload<number>());
export const getGrantProfileRecipientsErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getGrantProfileRecipientsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IProductResultsResponse<IRecipients>
> = createAction(
  ACTION_TYPES.GET_SUCCESS,
  createPayload<IProductResultsResponse<IRecipients>>(),
);

export const sortGrantProfileRecipientAction: CreateActionType<
  ACTION_TYPES.SORT,
  Params
> = createAction(ACTION_TYPES.SORT, createPayload<Params>());
export const sortGrantProfileRecipientErrorAction: CreateActionType<
  ACTION_TYPES.SORT_ERROR,
  IServerError
> = createAction(ACTION_TYPES.SORT_ERROR, createPayload<IServerError>());
export const sortGrantProfileRecipientSuccessAction: CreateActionType<
  ACTION_TYPES.SORT_SUCCESS,
  IProductResultsResponse<IRecipients>
> = createAction(
  ACTION_TYPES.SORT_SUCCESS,
  createPayload<IProductResultsResponse<IRecipients>>(),
);

export const toggleGrantProfileRecipientAction: CreateActionType<
  ACTION_TYPES.TOGGLE,
  boolean
> = createAction(ACTION_TYPES.TOGGLE, createPayload<boolean>());
export const toggleGrantProfileCheckedRecipientAction: CreateActionType<
  ACTION_TYPES.SET_CHECKED_RECIPIENT,
  number
> = createAction(ACTION_TYPES.SET_CHECKED_RECIPIENT, createPayload<number>());
export const toggleGrantProfileAllCheckedRecipientsAction: CreateActionType<
  ACTION_TYPES.SET_ALL_RECIPIENT_CHECKED,
  boolean
> = createAction(
  ACTION_TYPES.SET_ALL_RECIPIENT_CHECKED,
  createPayload<boolean>(),
);

export const downloadFileRecipientsAction: ActionCreator<
  ACTION_TYPES.DOWNLOAD_FILE_RECIPIENTS,
  () => TypedAction<ACTION_TYPES.DOWNLOAD_FILE_RECIPIENTS>
> = createAction(ACTION_TYPES.DOWNLOAD_FILE_RECIPIENTS);
export const downloadFileRecipientsErrorAction: CreateActionType<
  ACTION_TYPES.DOWNLOAD_FILE_RECIPIENTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DOWNLOAD_FILE_RECIPIENTS_ERROR,
  createPayload<IServerError>(),
);
export const downloadFileRecipientsSuccessAction: CreateActionType<
  ACTION_TYPES.DOWNLOAD_FILE_RECIPIENTS_SUCCESS,
  Blob
> = createAction(
  ACTION_TYPES.DOWNLOAD_FILE_RECIPIENTS_SUCCESS,
  createPayload<Blob>(),
);

export const resetGrantProfileRecipientsStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
