import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-institution-colleges.action";

import { IInstitutionsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { INSTITUTIONS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IViewResultsInstitutionCollegesState {
  colleges: IInstitutionsRecord[];
  pending: boolean;
  error: IServerError | null;

  selectedCollegesIds: number[];

  count: number;
}

const initialState: IViewResultsInstitutionCollegesState = {
  colleges: [],
  pending: false,
  error: null,

  selectedCollegesIds: [],

  count: 0,
};

const getViewResultsInstitutionColleges: OnReducer<
  IViewResultsInstitutionCollegesState,
  ActionType<any>
> = (state: IViewResultsInstitutionCollegesState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsInstitutionCollegesError: OnReducer<
  IViewResultsInstitutionCollegesState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsInstitutionCollegesState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsInstitutionCollegesSuccess: OnReducer<
  IViewResultsInstitutionCollegesState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionCollegesState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  colleges: [...payload.items],
  count: payload.count,
});

const toggleViewResultsInstitutionCollege: OnReducer<
  IViewResultsInstitutionCollegesState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionCollegesState,
  { payload: { pid } }: Payload<any>,
) => {
  const setArray: Set<number> = new Set(state.selectedCollegesIds);

  setArray.has(pid) ? setArray.delete(pid) : setArray.add(pid);

  return {
    ...state,
    selectedCollegesIds: Array.from(setArray),
  };
};

const setViewResultsInstitutionCollegesSpinnerState: OnReducer<
  IViewResultsInstitutionCollegesState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionCollegesState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetSelectedInstitutionColleges: OnReducer<
  IViewResultsInstitutionCollegesState,
  ActionType<any>
> = (state: IViewResultsInstitutionCollegesState) => ({
  ...state,
  selectedCollegesIds: [],
});

const resetViewResultsInstitutionCollegesState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsInstitutionCollegesState> =
  createReducer<IViewResultsInstitutionCollegesState>(
    initialState,

    on(
      actions.getViewResultsInstitutionCollegesAction,
      getViewResultsInstitutionColleges,
    ),
    on(
      actions.getViewResultsInstitutionCollegesErrorAction,
      getViewResultsInstitutionCollegesError,
    ),
    on(
      actions.getViewResultsInstitutionCollegesSuccessAction,
      getViewResultsInstitutionCollegesSuccess,
    ),

    on(
      actions.toggleViewResultsInstitutionCollegeAction,
      toggleViewResultsInstitutionCollege,
    ),

    on(
      actions.setViewResultsInstitutionCollegesSpinnerStateAction,
      setViewResultsInstitutionCollegesSpinnerState,
    ),

    on(
      actions.resetSelectedInstitutionCollegesAction,
      resetSelectedInstitutionColleges,
    ),

    on(
      actions.resetViewResultsInstitutionCollegesStateAction,
      resetViewResultsInstitutionCollegesState,
    ),
  );

export function viewResultsInstitutionCollegesReducer(
  state: IViewResultsInstitutionCollegesState,
  action: Action,
): IViewResultsInstitutionCollegesState {
  return reducer(state, action);
}

export const colleges: GetFromState<
  IInstitutionsRecord[],
  IViewResultsInstitutionCollegesState
> = (state: IViewResultsInstitutionCollegesState): IInstitutionsRecord[] =>
  state.colleges;
export const pending: GetFromState<
  boolean,
  IViewResultsInstitutionCollegesState
> = (state: IViewResultsInstitutionCollegesState): boolean => state.pending;
export const collegesError: GetFromState<
  IServerError,
  IViewResultsInstitutionCollegesState
> = (state: IViewResultsInstitutionCollegesState): IServerError => state.error;

export const collegesCount: GetFromState<
  number,
  IViewResultsInstitutionCollegesState
> = (state: IViewResultsInstitutionCollegesState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const params: Params = {
    ...INSTITUTIONS_DEFAULT_PARAMS,
    ...queryParams,
    viewId,
    filterBy: "colleges",
  };

  return segmentId ? { ...params, segmentId } : params;
};

export const selectedInstitutionCollegesIds: GetFromState<
  number[],
  IViewResultsInstitutionCollegesState
> = (state: IViewResultsInstitutionCollegesState): number[] =>
  state.selectedCollegesIds;
