import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IPersonnelRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { ITogglePersonnelRecordPayload } from "../../interfaces";

enum ACTION_TYPES {
  GET_VIEW_RESULTS_PERSONNEL_COLLEGES = "[VIEW RESULTS PERSONNEL COLLEGES] Get personnel colleges",
  GET_VIEW_RESULTS_PERSONNEL_COLLEGES_ERROR = "[VIEW RESULTS PERSONNEL COLLEGES] Get personnel colleges error",
  GET_VIEW_RESULTS_PERSONNEL_COLLEGES_SUCCESS = "[VIEW RESULTS PERSONNEL COLLEGES] Get personnel colleges success",

  TOGGLE_PERSONNEL_COLLEGE = "[VIEW RESULTS PERSONNEL COLLEGES] Toggle",

  SET_VIEW_RESULTS_PERSONNEL_COLLEGES_SPINNER_STATE = "[VIEW RESULTS PERSONNEL COLLEGES] Set spinner state",

  CHANGE_VIEW_RESULTS_PERSONNEL_COLLEGES_PAGE = "[VIEW RESULTS PERSONNEL COLLEGES] Change page",
  CHANGE_VIEW_RESULTS_PERSONNEL_COLLEGES_QUERY = "[VIEW RESULTS PERSONNEL COLLEGES] Change query",

  RESET_SELECTED = "[VIEW RESULTS PERSONNEL COLLEGES] Reset selected personnel colleges",

  RESET_VIEW_RESULTS_PERSONNEL_COLLEGES_PAGE_STATE = "[VIEW RESULTS PERSONNEL COLLEGES] Reset state",
}

export const getViewResultsPersonnelCollegesAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_COLLEGES,
  () => TypedAction<ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_COLLEGES>
> = createAction(ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_COLLEGES);
export const getViewResultsPersonnelCollegesErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_COLLEGES_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_COLLEGES_ERROR,
  createPayload<IServerError>(),
);
export const getViewResultsPersonnelCollegesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_COLLEGES_SUCCESS,
  IProductResultsResponse<IPersonnelRecords>
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_COLLEGES_SUCCESS,
  createPayload<IProductResultsResponse<IPersonnelRecords>>(),
);

export const toggleViewResultsPersonnelCollegeAction: CreateActionType<
  ACTION_TYPES.TOGGLE_PERSONNEL_COLLEGE,
  ITogglePersonnelRecordPayload
> = createAction(
  ACTION_TYPES.TOGGLE_PERSONNEL_COLLEGE,
  createPayload<ITogglePersonnelRecordPayload>(),
);

export const setViewResultsPersonnelCollegesSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_VIEW_RESULTS_PERSONNEL_COLLEGES_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_VIEW_RESULTS_PERSONNEL_COLLEGES_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeViewResultsPersonnelCollegesPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_COLLEGES_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_COLLEGES_PAGE,
  createPayload<number>(),
);

export const changeViewResultsPersonnelCollegesQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_COLLEGES_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_COLLEGES_QUERY,
  createPayload<Params>(),
);

export const resetSelectedPersonnelCollegesAction: ActionCreator<
  ACTION_TYPES.RESET_SELECTED,
  () => TypedAction<ACTION_TYPES.RESET_SELECTED>
> = createAction(ACTION_TYPES.RESET_SELECTED);

export const resetViewResultsPersonnelCollegesStateAction: ActionCreator<
  ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_COLLEGES_PAGE_STATE,
  () => TypedAction<ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_COLLEGES_PAGE_STATE>
> = createAction(ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_COLLEGES_PAGE_STATE);
