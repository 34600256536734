import {
  ITargetingCriteriaControls,
  ITargetingCriteriaDataItemControlDataItem,
  ITargetingCriteriaItemDetailedData,
  TARGETING_DATA_CONTROL_TYPES,
} from "./inputs";

export interface ITargetingCriteriaChanges {
  targetingControlId: number;
}

export interface IDetailedTargetingCriteriaChanges {
  detailedTargetingControlId: number;
  groupId?: number;
}

export interface ICheckboxes {
  checkboxIds: number[];
}

export interface ISwitchingCheckbox {
  id: number;
  include: 1 | 0;
}

export type ISwitchingCheckboxes = ISwitchingCheckbox[];

export interface ISwitchCheckboxes {
  switchingCheckboxes: ISwitchingCheckboxes;
}

export interface IRadioButton {
  radioButtonId: number;
}

export interface ISlider {
  slider: {
    min: number;
    max: number;
  };
}

export interface IDate {
  date: number;
  dateId: number;
}

export interface IChangeCheckboxes
  extends ITargetingCriteriaChanges,
    ICheckboxes {}

export interface IChangeDetailedCheckboxes
  extends IDetailedTargetingCriteriaChanges,
    ICheckboxes {}

export interface IChangeSwitchCheckboxes
  extends ITargetingCriteriaChanges,
    ISwitchCheckboxes {}

export interface IChangeDetailedSwitchCheckboxes
  extends IDetailedTargetingCriteriaChanges,
    ISwitchCheckboxes {}

export interface IChangeRadioButton
  extends ITargetingCriteriaChanges,
    IRadioButton {}

export interface IChangeDetailedRadioButton
  extends IDetailedTargetingCriteriaChanges,
    IRadioButton {}

export interface IChangeSliders extends ITargetingCriteriaChanges, ISlider {}

export interface IChangeDetailedSliders
  extends IDetailedTargetingCriteriaChanges,
    ISlider {}

export interface IChangeDates extends ITargetingCriteriaChanges, IDate {}

export interface IChangeDetailedDates
  extends IDetailedTargetingCriteriaChanges,
    IDate {}

export type IChangeTargetingCriteriaData =
  | Partial<IChangeCheckboxes>
  | Partial<IChangeSwitchCheckboxes>
  | Partial<IChangeDetailedCheckboxes>
  | Partial<IChangeDetailedSwitchCheckboxes>
  | Partial<IChangeSliders>
  | Partial<IChangeDetailedSliders>
  | Partial<IChangeRadioButton>
  | Partial<IChangeDetailedRadioButton>
  | Partial<IChangeDates>
  | Partial<IChangeDetailedDates>;

export interface ISegmentCriteriaIdChanges {
  segmentCriteriaId: number;
  groupType?: TARGETING_DATA_CONTROL_TYPES;
}

export interface IChangeTargetingCriteria extends ISegmentCriteriaIdChanges {
  data: IChangeTargetingCriteriaData;
}

export interface IOnUpdateDataControls {
  targetingControlId: number;
  control: ITargetingCriteriaDataItemControlDataItem | null;
  data: ITargetingCriteriaControls;
  detailedData: ITargetingCriteriaItemDetailedData;
  selectAll?: boolean;
  groupType?: TARGETING_DATA_CONTROL_TYPES;
}

export interface IOnUpdateDetailedDataControls {
  detailedTargetingControlId: number;
  control: ITargetingCriteriaDataItemControlDataItem | null;
  data: ITargetingCriteriaControls;
  groupId?: number;
  filterLogic?: number;
  groupType?: TARGETING_DATA_CONTROL_TYPES;
}

export interface IOnSegmentUpdateDataControls
  extends ISegmentCriteriaIdChanges {
  data: IOnUpdateDataControls;
}

export interface IOnSegmentUpdateDetailedDataControls
  extends ISegmentCriteriaIdChanges {
  data: IOnUpdateDetailedDataControls;
}
