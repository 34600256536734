import { IOverlayTargetParams } from "@ui/overlay-target/interfaces";
import { IProfileRoadData } from "../interfaces/on-board";

export enum FIRST_SIGN_UP_ROAD {
  SHOW_HELLO_POP_UP = "[FIRST SIGN UP] SHOW HELLO POP UP",

  SHOW_PROFILE_BUTTON = "[FIRST SIGN UP] SHOW PROFILE BUTTON",
  HIDE_PROFILE_BUTTON = "[FIRST SIGN UP] HIDE PROFILE BUTTON",

  SHOW_MARKET_VIEW_LINK = "[FIRST SIGN UP] SHOW MARKET VIEW LINK",
  HIDE_MARKET_VIEW_LINK = "[FIRST SIGN UP] HIDE MARKET VIEW LINK",

  SHOW_CREATE_LIST_BUTTON = "[FIRST SIGN UP] SHOW CREATE LIST BUTTON",
  HIDE_CREATE_LIST_BUTTON = "[FIRST SIGN UP] HIDE CREATE LIST BUTTON",
}

export const PROFILE_BUTTON_DATA: IProfileRoadData = {
  title: "1",
  subtitle: "Add your team",
  message:
    "The MDR ConnectED " +
    "Cloud allows unlimited users for free. Go into your Products & Users tab and click +Add under Users.",
};

export const VIEW_LINK_DATA: IProfileRoadData = {
  title: "2",
  subtitle: "Find new customers",
  message:
    "Search over 7.4MM+ prospects on MarketView. View detailed profiles of K-12 schools, districts, and colleges.",
};

export const CREATE_LIST_BUTTON_DATA: IProfileRoadData = {
  title: "3",
  subtitle: "Build targeted lists",
  message:
    "Target your ideal markets and drive revenue. Send lists to your CRM or MA.",
  nextBtnName: "Let’s Go!",
};

export const VIEW_LINK_PARAMS: IOverlayTargetParams = {
  padding: [10, 10, 0, 10],
};

export const PROFILE_BUTTON_DATA_PARAMS: IOverlayTargetParams = {
  targetBg: "#073F4D",
  padding: [10, 0, 10, 10],
};

export const CREATE_LIST_BUTTON_DATA_PARAMS: IOverlayTargetParams = {
  padding: [10, 10, 10, 10],
};
