import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { environment } from "@env/environment";

import { COMMON_ERROR } from "@shared/constants/error-message";

@Component({
  selector: "bl-server-errors-common",
  templateUrl: "./server-errors-common.component.html",
  styleUrls: ["./server-errors-common.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServerErrorsCommonComponent {
  @Input() isShowError: boolean;
  @Input() email: string = environment.contactMail;
  @Input() message: string = COMMON_ERROR;
}
