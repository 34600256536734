import { ITableHeaders } from "@ui/tables/components/table/table.component";

import { IRequestViewAlertsFilters } from "../interfaces";

import { SORT_DIRECTION } from "@shared/constants/sort";

export interface IAlertType {
  headerColor: string;
  color: string;
}

export interface IAlertTypes {
  [key: string]: IAlertType;
}

export const ALL_ALERTS_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "Name",
    sortable: true,
  },
  {
    key: "alertType",
    title: "Type",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "alert",
    title: "Alert",
    sortable: true,
  },
  {
    key: "alertDate",
    title: "Alert Date",
    sortable: true,
  },
];

export const INSTITUTION_ALERTS_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "Institution",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "alert",
    title: "Alert",
    sortable: true,
  },
  {
    key: "alertDate",
    title: "Alert Date",
    sortable: true,
  },
];

export const BIDS_ALERTS_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "Project Name",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "alert",
    title: "Alert",
    sortable: true,
  },
  {
    key: "alertDate",
    title: "Alert Date",
    sortable: true,
  },
];

export const GRANT_ALERTS_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "Grant Name",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "alert",
    title: "Alert",
    sortable: true,
  },
  {
    key: "alertDate",
    title: "Alert Date",
    sortable: true,
  },
];

export enum VIEW_RESULTS_ALERT_TYPE {
  INSTITUTION = "Institution",
  GRANT = "Grant",
  BID = "Bid",
}

export enum VIEW_ALERT_ID {
  "New Leadership" = 1,
  "New Schools" = 2,

  "New RFPs & IFBs" = 3,
  "Updated RFPs & IFB" = 7,

  "New Grant Programs" = 4,
  "New Grant Milestones" = 5,
  "Grant Awards" = 6,
}

export const REQUEST_VIEW_ALERTS_OFFSET: number = 0;
export const REQUEST_VIEW_ALERTS_LIMIT: number = 25;

export enum REQUEST_VIEW_ALERTS_FILTERS {
  INSTITUTION = "institutions",
  BID = "bids",
  GRANT = "grants",
  ALL = "all",
}

export enum REQUEST_VIEW_ALERTS_SORT {
  NAME = "name",
  TYPE = "alertType",
  STATE = "state",
  DATE = "alertDate",
}

export const DEFAULT_ALERT_REQUEST_PARAMS: IRequestViewAlertsFilters = {
  filterBy: REQUEST_VIEW_ALERTS_FILTERS.ALL,
  sortBy: REQUEST_VIEW_ALERTS_SORT.DATE,
  orderBy: SORT_DIRECTION.DESC,
  offset: REQUEST_VIEW_ALERTS_OFFSET,
  limit: REQUEST_VIEW_ALERTS_LIMIT,
};

export const ALERT_TYPES: IAlertTypes = {
  [VIEW_RESULTS_ALERT_TYPE.INSTITUTION]: {
    headerColor: "#015C8A",
    color: "#4285A8",
  },
  [VIEW_RESULTS_ALERT_TYPE.BID]: {
    headerColor: "#1D7A79",
    color: "#579C9B",
  },
  [VIEW_RESULTS_ALERT_TYPE.GRANT]: {
    headerColor: "#2E7128",
    color: "#63955F",
  },
};
