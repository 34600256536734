import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "dateMask",
})
export class DateMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value
      .replace(/\D+/g, "")
      .replace(/(^\d{1,6}).*$/, "$1")
      .replace(
        /^(\d{2})(\d{1,4})?$/,
        (substring: string, month: string, year: string) => {
          return [month, year].filter((match: string) => !!match).join("-");
        },
      );
  }

  parse(value: string): string {
    return this.transform(value);
  }
}
