import { InjectionToken } from "@angular/core";

export interface IWebSocketConfig {
  url: string;
  token?: string;
  reconnectInterval?: number;
  reconnectAttempts?: number;
}

export interface IWebSocketUpdateConfigValues {
  url?: string;
  token?: string;
  reconnectInterval?: number;
  reconnectAttempts?: number;
}

export interface IWsMessage<T> {
  event: string;
  data: T;
}

export const WEB_SOCKET_CONFIG_TOKEN: InjectionToken<IWebSocketConfig> =
  new InjectionToken<IWebSocketConfig>("wsConfig");
