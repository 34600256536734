import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/account-users.action";

import { MapAccountUser } from "../../utils";
import { accountUserToEditUser } from "../../utils/map-forms";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IAccountUser,
  IAccountUsers,
  IEditAccountUser,
} from "../../interfaces/marketview";

export interface IAccountUsersState {
  accountUsers: IAccountUsers | null;
  usersHistory: null;
  error: IServerError | null;
  pending: boolean;
}

const initialState: IAccountUsersState = {
  accountUsers: null,
  usersHistory: null,
  error: null,
  pending: false,
};

// ACCOUNT USERS
const getAccountUsersHandler: OnReducer<IAccountUsersState, ActionType<any>> = (
  state: IAccountUsersState,
) => ({
  ...state,
  error: null,
  pending: true,
});

const getAccountUsersErrorHandler: OnReducer<
  IAccountUsersState,
  ActionType<Payload<any>>
> = (state: IAccountUsersState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getAccountUsersSuccessHandler: OnReducer<
  IAccountUsersState,
  ActionType<Payload<any>>
> = (state: IAccountUsersState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  accountUsers: [...payload],
});

// REMOVE USER
const removeUserHandler: OnReducer<IAccountUsersState, ActionType<any>> = (
  state: IAccountUsersState,
) => ({
  ...state,
  error: null,
  pending: true,
});

const removeUserErrorHandler: OnReducer<
  IAccountUsersState,
  ActionType<Payload<any>>
> = (state: IAccountUsersState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const removeUserSuccessHandler: OnReducer<
  IAccountUsersState,
  ActionType<any>
> = (state: IAccountUsersState) => ({
  ...state,
  pending: false,
});

// ASSIGN DATA
const assignDataHandler: OnReducer<IAccountUsersState, ActionType<any>> = (
  state: IAccountUsersState,
) => ({
  ...state,
  error: null,
  pending: true,
});

const assignDataErrorHandler: OnReducer<
  IAccountUsersState,
  ActionType<Payload<any>>
> = (state: IAccountUsersState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const assignDataSuccessHandler: OnReducer<
  IAccountUsersState,
  ActionType<any>
> = (state: IAccountUsersState) => ({
  ...state,
  pending: false,
  error: null,
});

// ASSIGN OWNER TO ANOTHER OWNER
const assignOwnerHandler: OnReducer<IAccountUsersState, ActionType<any>> = (
  state: IAccountUsersState,
) => ({
  ...state,
  error: null,
  pending: true,
});

const assignOwnerErrorHandler: OnReducer<
  IAccountUsersState,
  ActionType<Payload<any>>
> = (state: IAccountUsersState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const assignOwnerSuccessHandler: OnReducer<
  IAccountUsersState,
  ActionType<any>
> = (state: IAccountUsersState) => ({
  ...state,
  pending: false,
  error: null,
});

// USER HISTORY
const userHistoryHandler: OnReducer<IAccountUsersState, ActionType<any>> = (
  state: IAccountUsersState,
) => ({
  ...state,
  error: null,
  pending: true,
});

const userHistoryErrorHandler: OnReducer<
  IAccountUsersState,
  ActionType<Payload<any>>
> = (state: IAccountUsersState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const userHistorySuccessHandler: OnReducer<
  IAccountUsersState,
  ActionType<any>
> = (state: IAccountUsersState, { payload }: Payload<any>) => ({
  ...state,
  usersHistory: payload,
  pending: false,
  error: null,
});

// RESET STATE
const resetAccountUsersHandler: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IAccountUsersState> =
  createReducer<IAccountUsersState>(
    initialState,

    on(actions.getAccountUsersAction, getAccountUsersHandler),
    on(actions.getAccountUsersErrorAction, getAccountUsersErrorHandler),
    on(actions.getAccountUsersSuccessAction, getAccountUsersSuccessHandler),

    on(actions.removeUserAction, removeUserHandler),
    on(actions.removeUserErrorAction, removeUserErrorHandler),
    on(actions.removeUserSuccessAction, removeUserSuccessHandler),

    on(actions.assignDataAction, assignDataHandler),
    on(actions.assignDataErrorAction, assignDataErrorHandler),
    on(actions.assignDataSuccessAction, assignDataSuccessHandler),

    on(actions.assignOwnerAction, assignOwnerHandler),
    on(actions.assignOwnerErrorAction, assignOwnerErrorHandler),
    on(actions.assignOwnerSuccessAction, assignOwnerSuccessHandler),

    on(actions.resetAccountUsersAction, resetAccountUsersHandler),

    on(actions.userHistoryAction, userHistoryHandler),
    on(actions.userHistorySuccessAction, userHistorySuccessHandler),
    on(actions.userHistoryErrorAction, userHistoryErrorHandler),
  );

export const accountUsers: GetFromState<
  IAccountUsers | null,
  IAccountUsersState
> = (state: IAccountUsersState): IAccountUsers | null => state.accountUsers;
export const pending: GetFromState<boolean, IAccountUsersState> = (
  state: IAccountUsersState,
): boolean => state.pending;
export const accountUsersError: GetFromState<
  IServerError,
  IAccountUsersState
> = (state: IAccountUsersState): IServerError => state.error;

export const accountUsersHistory: GetFromState<
  IAccountUsers | null,
  IAccountUsersState
> = (state: IAccountUsersState): IAccountUsers | null => state.usersHistory;

export const mapAccountUsersAddOns: GetFromState<
  IAccountUsers,
  IAccountUsers
> = (_accountUsers: IAccountUsers): IAccountUsers => {
  return (
    _accountUsers &&
    _accountUsers.map((user: IAccountUser) => new MapAccountUser(user))
  );
};

export const usersWithoutSelf: GetFromState<
  IAccountUsers,
  IAccountUsers,
  number
> = (users: IAccountUsers, userId: number): IAccountUsers => {
  return users && users.filter((item: IAccountUser) => item.id !== userId);
};

export const selfAccount: GetFromState<IAccountUser, IAccountUsers, number> = (
  users: IAccountUsers,
  userId: number,
): IAccountUser => {
  return users && users.find((item: IAccountUser) => item.id === userId);
};

export const isHasProductsOrCredits: GetFromState<
  boolean,
  boolean,
  boolean,
  IAccountUser
> = (
  isHasProducts: boolean,
  isUnlimited: boolean,
  user: IAccountUser,
): boolean => {
  return (
    isHasProducts ||
    isUnlimited ||
    (user && user.dataCount && user.dataCount.credits > 0)
  );
};

export const selfAccountDataForEditPopUp: GetFromState<
  IEditAccountUser,
  IAccountUser
> = (user: IAccountUser): IEditAccountUser =>
  user && accountUserToEditUser(user);

export function accountUsersReducer(
  state: IAccountUsersState,
  action: Action,
): IAccountUsersState {
  return reducer(state, action);
}
