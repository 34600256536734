import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
} from "@angular/router";
import { NOTIFICATION_TYPES } from "@core/constants/notifications";
import { StorageService } from "@core/services/storage.service";
import { CoreState } from "@core/store/reducers";
import { InvitationService } from "@modules/auth/services/invitation.service";
import { signInSuccessAction } from "@modules/auth/store/actions/auth.action";
import {
  verifyInviteErrorAction,
  verifyInviteSuccessAction,
} from "@modules/auth/store/actions/sign-up-by-invite.action";
import { getVerifyInvite } from "@modules/auth/store/selectors/sign-up-by-invite.selector";
import { showNotificationAction } from "@modules/notifications/store/actions/notification.action";
import { clearOktaSessionAction } from "@modules/profile/store/actions/profile.action";
import { select, Store } from "@ngrx/store";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";
import { IServerError } from "@shared/interfaces/server-error";
import { Observable, of, throwError } from "rxjs";
import {
  catchError,
  map,
  mapTo,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";

@Injectable()
export class verifyInvitationGuard {
  constructor(
    private _store: Store<CoreState>,
    private _invitationService: InvitationService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {}

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    if (!!this.oktaAuth.getAccessToken()) {
      return this.verifyInvitation();
    }
    return of(true);
  }

  verifyInvitation(): Observable<boolean> | Promise<boolean> | boolean {
    return this._store.pipe(
      select(getVerifyInvite),
      take(1),
      switchMap((invite) => {
        if (!invite) {
          return this._invitationService.verifyInvitation().pipe(
            map((_user) => {
              this._store.dispatch(verifyInviteSuccessAction());
              return true;
            }),
            catchError((error: IServerError) => {
              return throwError(error);
            }),
          );
        } else if (StorageService.verifyStatus) {
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError(() => of(false)),
    );
  }
}
