import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  TemplateRef,
} from "@angular/core";

import { MobilePortalComponent } from "../components/mobile-protal/mobile-portal.component";

import { MobileDropDownContentDirective } from "../directives/mobile-drop-down-content.directive";

import { PortalService } from "@core/services/portal.service";

import {
  AnimationState,
  ANIMATION_END,
  ANIMATION_START,
  CONTENT_POSITION,
  DEFAULT_MOBILE_DROP_DOWN_PARAMS,
  IDetachMobileContent,
  IMobileDropDownPortalClasses,
  MobileDropDownParams,
  MOBILE_DROP_DOWN_CLASSES,
  MOBILE_DROPDOWN_TEMPLATE_TOKEN,
  PARAMS_CLASSES,
} from "../models";

import { CustomInjector } from "@core/custom-injector";

@Injectable()
export class MobileDropDownProviderService {
  constructor(
    private portalService: PortalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
  ) {}

  open(
    template: TemplateRef<MobileDropDownContentDirective>,
    params: MobileDropDownParams,
  ): IDetachMobileContent {
    const injector: CustomInjector = this.createInjector(params, template);
    const componentRef: ComponentRef<MobilePortalComponent> =
      this.componentFactoryResolver
        .resolveComponentFactory(MobilePortalComponent)
        .create(injector);

    this.setClassesByParams(componentRef.instance, params);

    return {
      componentRef: componentRef,
      detach: this.portalService.attachComponentRef(componentRef),
    };
  }

  setClassesByParams(
    instance: MobilePortalComponent,
    params: MobileDropDownParams,
  ): void {
    this.getClassesByParams(params).forEach((className: string) => {
      instance.renderer.addClass(instance.elementRef.nativeElement, className);
    });
  }

  getClassesByParams(
    params: MobileDropDownParams,
  ): IMobileDropDownPortalClasses {
    return (
      Object.entries(params)
        .reduce(
          (acc: any, [key, value]: [string, typeof MobileDropDownParams]) => {
            if (Array.isArray(value)) {
              const val: any = [];
              value.forEach((subVal: CONTENT_POSITION) => {
                val.push([key, subVal]);
              });
              return acc.concat(val);
            }
            return acc.concat([[key, value]]);
          },
          [],
        )
        // @ts-ignore
        .map(
          ([key, value]: [string, typeof MobileDropDownParams]) =>
            PARAMS_CLASSES[key] && PARAMS_CLASSES[key](value),
        )
        .filter((className: MOBILE_DROP_DOWN_CLASSES) => !!className)
    );
  }

  private createInjector(
    params: MobileDropDownParams,
    template: TemplateRef<MobileDropDownContentDirective>,
  ): CustomInjector {
    const tokens: WeakMap<object, any> = new WeakMap();
    const animationPosition: CONTENT_POSITION =
      params.animationPosition ||
      DEFAULT_MOBILE_DROP_DOWN_PARAMS.animationPosition;
    const { onAnimationStart, onAnimationEnd }: MobileDropDownParams = params;

    tokens.set(MOBILE_DROPDOWN_TEMPLATE_TOKEN, template);
    tokens.set(AnimationState, animationPosition);
    tokens.set(ANIMATION_START, onAnimationStart);
    tokens.set(ANIMATION_END, onAnimationEnd);

    return new CustomInjector(this.injector, tokens);
  }
}
