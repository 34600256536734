import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";

import { resetECommerceCartAction } from "@modules/e-commerce/store/actions/cart.action";
import { CoreState } from "../store/reducers";

import { SuccessPopUpContentComponent } from "@ui/pop-up/components/success-pop-up-content/success-pop-up-content.component";

import { PopUpService } from "@ui/pop-up/services/pop-up/pop-up.service";

@Injectable({ providedIn: "root" })
export class ReasonCodeGuard {
  constructor(
    private _store: Store<CoreState>,
    private _location: Location,
    private _popUpService: PopUpService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): boolean {
    if (
      route.queryParams &&
      route.queryParams["reasonCode"] &&
      route.queryParams["reasonCode"] === "100"
    ) {
      this._store.dispatch(resetECommerceCartAction());
      this._location.replaceState(state.url.replace("reasonCode=100", ""));

      this._popUpService.open(SuccessPopUpContentComponent, {
        showCloseBtn: false,
        timer: 3000,
        data: {
          title: "Purchase Successful!",
          iconClass: "icon-success",
        },
      });
    }

    return true;
  }
}
