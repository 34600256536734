import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  createListFromViewReducer,
  ICreateListFromViewState,
} from "./create-list-from-view.reducer";
import {
  viewResultsBidsReducer,
  IViewResultsBidsState,
} from "./view-results-bids.reducer";
import {
  viewResultsGrantsReducer,
  IViewResultsGrantsState,
} from "./view-results-grants.reducer";
import {
  viewResultsAllInstitutionReducer,
  IViewResultsAllInstitutionsState,
} from "./view-results-institution-all.reducer";
import {
  viewResultsInstitutionCollegesReducer,
  IViewResultsInstitutionCollegesState,
} from "./view-results-institution-colleges.reducer";
import {
  viewResultsInstitutionCountersReducer,
  IViewResultsInstitutionCountersState,
} from "./view-results-institution-counters.reducer";
import {
  viewResultsInstitutionDistrictsReducer,
  IViewResultsInstitutionDistrictsState,
} from "./view-results-institution-districts.reducer";
import {
  viewResultsInstitutionSchoolsReducer,
  IViewResultsInstitutionSchoolsState,
} from "./view-results-institution-schools.reducer";
import {
  viewResultsOverviewReducer,
  IViewResultsOverviewState,
} from "./view-results-overview.reducer";
import {
  viewResultsAllPersonnelReducer,
  IViewResultsAllPersonnelState,
} from "./view-results-personnel-all.reducer";
import {
  viewResultsPersonnelCollegesReducer,
  IViewResultsPersonnelCollegeState,
} from "./view-results-personnel-colleges.reducer";
import {
  viewResultsPersonnelCountersReducer,
  IViewResultsPersonnelCountersState,
} from "./view-results-personnel-counters.reducer";
import {
  viewResultsPersonnelDistrictReducer,
  IViewResultsPersonnelDistrictsState,
} from "./view-results-personnel-districts.reducer";
import {
  viewResultsPersonnelSchoolsReducer,
  IViewResultsPersonnelSchoolsState,
} from "./view-results-personnel-schools.reducer";

export interface IViewResultsModuleState {
  customerViewResults: IViewResultsOverviewState;

  customerViewResultsBids: IViewResultsBidsState;
  customerViewResultsGrants: IViewResultsGrantsState;

  customerViewResultsInstitutionCounters: IViewResultsInstitutionCountersState;
  customerViewResultsAllInstitution: IViewResultsAllInstitutionsState;
  customerViewResultsInstitutionSchoolsState: IViewResultsInstitutionSchoolsState;
  customerViewResultsInstitutionCollegesState: IViewResultsInstitutionCollegesState;
  customerViewResultsInstitutionDistrictsState: IViewResultsInstitutionDistrictsState;

  customerViewResultsPersonnelCounters: IViewResultsPersonnelCountersState;
  customerViewResultsAllPersonnel: IViewResultsAllPersonnelState;
  customerViewResultsPersonnelSchoolsState: IViewResultsPersonnelSchoolsState;
  customerViewResultsPersonnelCollegesState: IViewResultsPersonnelCollegeState;
  customerViewResultsPersonnelDistrictsState: IViewResultsPersonnelDistrictsState;

  createListFromViewState: ICreateListFromViewState;
}

const viewResultsModuleReducer: ActionReducerMap<IViewResultsModuleState> = {
  customerViewResults: viewResultsOverviewReducer,

  customerViewResultsBids: viewResultsBidsReducer,
  customerViewResultsGrants: viewResultsGrantsReducer,

  customerViewResultsInstitutionCollegesState:
    viewResultsInstitutionCollegesReducer,
  customerViewResultsPersonnelDistrictsState:
    viewResultsPersonnelDistrictReducer,
  customerViewResultsPersonnelSchoolsState: viewResultsPersonnelSchoolsReducer,

  customerViewResultsPersonnelCollegesState:
    viewResultsPersonnelCollegesReducer,
  customerViewResultsInstitutionSchoolsState:
    viewResultsInstitutionSchoolsReducer,
  customerViewResultsInstitutionDistrictsState:
    viewResultsInstitutionDistrictsReducer,

  customerViewResultsAllInstitution: viewResultsAllInstitutionReducer,
  customerViewResultsAllPersonnel: viewResultsAllPersonnelReducer,

  createListFromViewState: createListFromViewReducer,

  customerViewResultsPersonnelCounters: viewResultsPersonnelCountersReducer,
  customerViewResultsInstitutionCounters: viewResultsInstitutionCountersReducer,
};

// eslint-disable-next-line
export const VIEW_RESULTS_STATE_MODULE_NAME = 'viewResultsModule';
export const VIEW_RESULTS_STATE: InjectionToken<
  ActionReducerMap<IViewResultsModuleState>
> = new InjectionToken<ActionReducerMap<IViewResultsModuleState>>(
  VIEW_RESULTS_STATE_MODULE_NAME,
  {
    factory: () => viewResultsModuleReducer,
  },
);
