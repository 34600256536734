import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-institution-schools.action";

import { IInstitutionsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { INSTITUTIONS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsInstitutionSchoolsState {
  schools: IInstitutionsRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IQuickSearchResultsInstitutionSchoolsState = {
  schools: [],
  pending: false,
  error: null,

  count: 0,
};

const quickSearchInstitutionSchools: OnReducer<
  IQuickSearchResultsInstitutionSchoolsState,
  ActionType<any>
> = (state: IQuickSearchResultsInstitutionSchoolsState) => ({
  ...state,
  pending: true,
  error: null,
});

const quickSearchInstitutionSchoolsError: OnReducer<
  IQuickSearchResultsInstitutionSchoolsState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsInstitutionSchoolsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const quickSearchInstitutionSchoolsSuccess: OnReducer<
  IQuickSearchResultsInstitutionSchoolsState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsInstitutionSchoolsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  schools: [...payload.items],
  count: payload.count,
});

const setQuickSearchInstitutionSchoolsSpinnerState: OnReducer<
  IQuickSearchResultsInstitutionSchoolsState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsInstitutionSchoolsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetQuickSearchInstitutionSchoolsState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsInstitutionSchoolsState> =
  createReducer<IQuickSearchResultsInstitutionSchoolsState>(
    initialState,

    on(
      actions.getQuickSearchInstitutionSchoolsAction,
      quickSearchInstitutionSchools,
    ),
    on(
      actions.getQuickSearchInstitutionSchoolsErrorAction,
      quickSearchInstitutionSchoolsError,
    ),
    on(
      actions.getQuickSearchInstitutionSchoolsSuccessAction,
      quickSearchInstitutionSchoolsSuccess,
    ),

    on(
      actions.changeQuickSearchInstitutionSchoolsPageAction,
      quickSearchInstitutionSchools,
    ),
    on(
      actions.changeQuickSearchInstitutionSchoolsQueryAction,
      quickSearchInstitutionSchools,
    ),

    on(
      actions.setQuickSearchInstitutionSchoolsSpinnerStateAction,
      setQuickSearchInstitutionSchoolsSpinnerState,
    ),

    on(
      actions.resetQuickSearchInstitutionSchoolsStateAction,
      resetQuickSearchInstitutionSchoolsState,
    ),
  );

export function quickSearchResultsInstitutionSchoolsReducer(
  state: IQuickSearchResultsInstitutionSchoolsState,
  action: Action,
): IQuickSearchResultsInstitutionSchoolsState {
  return reducer(state, action);
}

export const schools: GetFromState<
  IInstitutionsRecord[],
  IQuickSearchResultsInstitutionSchoolsState
> = (
  state: IQuickSearchResultsInstitutionSchoolsState,
): IInstitutionsRecord[] => state.schools;
export const pending: GetFromState<
  boolean,
  IQuickSearchResultsInstitutionSchoolsState
> = (state: IQuickSearchResultsInstitutionSchoolsState): boolean =>
  state.pending;
export const schoolsError: GetFromState<
  IServerError,
  IQuickSearchResultsInstitutionSchoolsState
> = (state: IQuickSearchResultsInstitutionSchoolsState): IServerError =>
  state.error;

export const schoolsPaginationCount: GetFromState<
  number,
  IQuickSearchResultsInstitutionSchoolsState
> = (state: IQuickSearchResultsInstitutionSchoolsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  return {
    ...INSTITUTIONS_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    filterBy: "schools",
  };
};
