import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results.action";

import { IProductCounter } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

export interface IQuickSearchResultsState {
  overview: IProductCounter;

  pending: boolean;
  error: IServerError;
}

const initialState: IQuickSearchResultsState = {
  overview: null,

  pending: false,
  error: null,
};

const getQuickSearchOverview: OnReducer<
  IQuickSearchResultsState,
  ActionType<any>
> = (state: IQuickSearchResultsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getQuickSearchOverviewSuccess: OnReducer<
  IQuickSearchResultsState,
  ActionType<Payload<IProductCounter>>
> = (
  state: IQuickSearchResultsState,
  { payload }: Payload<IProductCounter>,
) => ({
  ...state,
  pending: false,
  overview: { ...payload },
});

const getQuickSearchOverviewError: OnReducer<
  IQuickSearchResultsState,
  ActionType<Payload<IServerError>>
> = (state: IQuickSearchResultsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const reducer: ActionReducer<IQuickSearchResultsState> =
  createReducer<IQuickSearchResultsState>(
    initialState,

    on(actions.getQuickSearchOverviewAction, getQuickSearchOverview),
    on(
      actions.getQuickSearchOverviewSuccessAction,
      getQuickSearchOverviewSuccess,
    ),
    on(actions.getQuickSearchOverviewErrorAction, getQuickSearchOverviewError),
  );

export function quickSearchResultsReducer(
  state: IQuickSearchResultsState,
  action: Action,
): IQuickSearchResultsState {
  return reducer(state, action);
}

export const overview: GetFromState<
  IProductCounter,
  IQuickSearchResultsState
> = (state: IQuickSearchResultsState): IProductCounter =>
  state && state.overview;
export const overviewPending: GetFromState<
  boolean,
  IQuickSearchResultsState
> = (state: IQuickSearchResultsState): boolean => state && state.pending;
export const overviewError: GetFromState<
  IServerError,
  IQuickSearchResultsState
> = (state: IQuickSearchResultsState): IServerError => state && state.error;
