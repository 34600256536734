import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
  TemplateRef,
} from "@angular/core";

import { fromEvent, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { LayoutService } from "@core/services/layout.service";
import { OverlayService } from "@ui/overlay/providers/overlay.service";
import { MobileDropDownProviderService } from "../providers/mobile-drop-down-provider.service";

import {
  CONTENT_POSITION,
  DEFAULT_MOBILE_DROP_DOWN_PARAMS,
  IDetachMobileContent,
  MobileDropDownParams,
} from "../models";

import { MobileDropDownContentDirective } from "./mobile-drop-down-content.directive";
import { MobileDropDownTargetDirective } from "./mobile-drop-down-target.directive";

@Directive({
  selector: "bl-mobile-drop-down, [blMobileDropDown]",
  exportAs: "mobileDropDown",
  providers: [OverlayService],
})
export class MobileDropDownDirective implements AfterContentInit, OnDestroy {
  @Input() params: MobileDropDownParams = {};
  @Input() isDisabled: boolean;

  @ContentChildren(MobileDropDownTargetDirective, { read: ElementRef })
  targetList: QueryList<ElementRef<MobileDropDownTargetDirective>>;

  @ContentChildren(MobileDropDownContentDirective, { read: TemplateRef })
  contentList: QueryList<TemplateRef<MobileDropDownContentDirective>>;

  @Output() mobileDropDownToggle: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() animationStart: EventEmitter<any> = new EventEmitter<any>();
  @Output() animationEnd: EventEmitter<any> = new EventEmitter<any>();

  private destroyer$: Subject<void> = new Subject();
  private _detachFunctions: IDetachMobileContent[] = [];

  constructor(
    private _provider: MobileDropDownProviderService,
    private _overlay: OverlayService,
    private _layout: LayoutService,
    private _chdRef: ChangeDetectorRef,
  ) {}

  get isOpen(): boolean {
    return !!this._detachFunctions.length;
  }

  ngAfterContentInit(): void {
    this.targetList.forEach(
      (targetElement: ElementRef<MobileDropDownTargetDirective>) =>
        this.addTargetHandle(targetElement),
    );
  }

  ngOnDestroy(): void {
    this.close(false);

    this.destroyer$.next();
    this.destroyer$.complete();
  }

  addTargetHandle(
    targetElement: ElementRef<MobileDropDownTargetDirective>,
  ): void {
    fromEvent(targetElement.nativeElement as any, "click")
      .pipe(
        takeUntil(this.destroyer$),
        filter(() => !this.isDisabled),
      )
      .subscribe(() => this.toggle());
  }

  toggle(): void {
    // TODO add slide params;
    !!this._detachFunctions.length ? this.close() : this.open();
  }

  open(params: MobileDropDownParams = {}): void {
    // TODO add slide params;
    const _params: MobileDropDownParams = {
      ...DEFAULT_MOBILE_DROP_DOWN_PARAMS,
      ...this.params,
      ...params,
      onAnimationStart: () => this.onAnimationStart(),
      onAnimationEnd: () => this.onAnimationEnd(),
    };

    if (_params.withOverlay) {
      this._overlay.show({ onCLick: () => this.close() });
    }

    this._detachFunctions = this.contentList.map(
      (contentTemplate: TemplateRef<MobileDropDownContentDirective>) =>
        this._provider.open(contentTemplate, _params),
    );
    this._layout.disableGlobalScroll();
    this.mobileDropDownToggle.emit(!!this._detachFunctions.length);
    this._chdRef.detectChanges();
  }

  close(enableScroll: boolean = true): void {
    // TODO add slide params;
    this._detachFunctions.forEach((element: IDetachMobileContent) =>
      element.detach(),
    );
    this._detachFunctions = [];
    this._chdRef.detectChanges();

    if (enableScroll) {
      this._layout.enableGlobalScroll();
    }

    this.mobileDropDownToggle.emit(false);

    this._overlay.hide();

    if (this.params.scrollOnToggle) {
      this.scrollToTarget();
    }
  }

  scrollToTarget(): void {
    this.targetList.forEach(
      (element: ElementRef<MobileDropDownTargetDirective>) => {
        const elementToScroll: HTMLElement =
          element.nativeElement as HTMLElement;
        elementToScroll.scrollIntoView();
      },
    );
  }

  private onAnimationStart(): void {
    this.animationStart.emit();
  }

  private onAnimationEnd(): void {
    this.animationEnd.emit();
  }

  private setAnimationState(state: CONTENT_POSITION): void {
    this._detachFunctions.forEach(
      (elem: IDetachMobileContent) =>
        (elem.componentRef.instance.animationState = state),
    );
  }

  reset(): void {
    this.setAnimationState(CONTENT_POSITION.INITIAL);
  }

  slideLeft(): void {
    this.setAnimationState(CONTENT_POSITION.LEFT);
  }

  slideRight(): void {
    this.setAnimationState(CONTENT_POSITION.RIGHT);
  }

  slideTop(): void {
    this.setAnimationState(CONTENT_POSITION.TOP);
  }

  slideBottom(): void {
    this.setAnimationState(CONTENT_POSITION.BOTTOM);
  }
}
