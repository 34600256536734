export const leftDays: (startDate: number, lastDate: number) => number = (
  startDate: number,
  lastDate: number,
): number => {
  if (startDate && lastDate) {
    const delta: number = Math.abs((lastDate - startDate) / 1000);
    return Math.floor(delta / 86400);
  }

  throw new Error(
    `Invalid arguments start date - ${startDate}, last date - ${lastDate}`,
  );
};
