import { Pipe, PipeTransform } from "@angular/core";

import { HIDDEN_BY } from "@shared/modules/institution-profiles/constants/profiles";

@Pipe({
  name: "isDescription",
})
export class IsDescriptionPipe implements PipeTransform {
  transform(value: any, description: string = ""): string {
    if (
      (typeof value !== "string" && typeof value !== "number") ||
      value === HIDDEN_BY.value
    ) {
      return value;
    }

    return `${value}${description}`;
  }
}
