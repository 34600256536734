import { ITableHeader } from "@ui/tables/components/table/table.component";

export enum PERSONNEL_ENTITY_SORTING {
  FIRST_NAME = "firstName",
  LAST_NAME = "lastName",
  JOB_TITLE = "jobTitle",
  PREFIX = "prefix",
  POSTED_ON = "postedOn",
}

export const PERSONNEL_ENTITY_TABLE_HEADERS: ITableHeader[] = [
  {
    key: "isDone",
    title: "",
    checkbox: true,
  },
  {
    key: "jobTitle",
    title: "Job Function",
    sortable: true,
  },
  {
    key: "prefix",
    title: "Prefix",
    sortable: true,
  },
  {
    key: "firstName",
    title: "First Name",
    sortable: true,
  },
  {
    key: "lastName",
    title: "Last Name",
    sortable: true,
  },
  {
    key: "email",
    title: "Show Email",
    sortable: true,
  },
  {
    key: "postedOn",
    align: "center",
    title: "Posted on",
    sortable: true,
  },
];

export const PERSONNEL_ENTITY_TABLE_HEADERS_MOBILE: ITableHeader[] = [
  {
    key: "isDone",
    title: "",
    checkbox: true,
  },
  {
    key: "jobTitle",
    title: "Job Function",
    sortable: true,
  },
  {
    key: "firstName",
    title: "First Name",
    sortable: true,
  },
  {
    key: "lastName",
    title: "Last Name",
    sortable: true,
  },
  {
    key: "postedOn",
    align: "center",
    title: "Posted on",
    sortable: true,
  },
  {
    key: "email",
    title: "Email",
    sortable: true,
  },
];

export const MAX_PERSONNEL_ENTITY_CACHE: number = 180;
