import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from "@angular/core";
import { LoaderType } from "@progress/kendo-angular-indicators";

@Component({
  selector: "bl-spinner",
  templateUrl: "./spinner.component.html",
  styleUrls: ["./spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() @HostBinding("class.is-white") white: boolean;
  @Input() type: LoaderType = "converging-spinner";
}
