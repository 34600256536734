import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cdl-list-purchase.action";

import { IDataListSummary, ISummaryList } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import { IListMainTypes } from "../../../../list-shared/interfaces";
import {
  ISegmentData,
  ISegmentTargetingCriteria,
} from "../../../../segment/interfaces/segment";

import { PRODUCT_ENTITY_TYPES } from "@shared/constants/data/entity";

export interface ICDLListPurchaseState {
  summaryData: IDataListSummary | null;
  listSegmentData: ISegmentData | null;

  fetching: boolean;
  fetchDataError: IServerError | null;

  statusChangeError: IServerError | null;
  statusChanging: boolean;

  renaming: boolean;
  renameError: IServerError | null;
}

const initialState: ICDLListPurchaseState = {
  summaryData: null,
  listSegmentData: null,

  fetching: false,
  fetchDataError: null,

  statusChangeError: null,
  statusChanging: false,

  renaming: false,
  renameError: null,
};

const fetchCDLListData: OnReducer<ICDLListPurchaseState, ActionType<any>> = (
  state: ICDLListPurchaseState,
) => ({
  ...state,
  fetching: true,
  fetchDataError: null,
});

export const fetchCDLListPurchaseDataError: OnReducer<
  ICDLListPurchaseState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  fetching: false,
  fetchDataError: payload,
});

const fetchCDLListPurchaseDataSuccess: OnReducer<
  ICDLListPurchaseState,
  ActionType<Payload<IDataListSummary>>
> = (state: ICDLListPurchaseState, { payload }: Payload<IDataListSummary>) => ({
  ...state,
  fetching: false,
  summaryData: payload,
});

export const fetchCDLListSegmentData: OnReducer<
  ICDLListPurchaseState,
  ActionType<any>
> = (state: ICDLListPurchaseState) => ({
  ...state,
  fetching: true,
  fetchDataError: null,
});

export const fetchCDLListSegmentDataError: OnReducer<
  ICDLListPurchaseState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  fetching: false,
  fetchDataError: payload,
});

export const fetchCDLListSegmentDataSuccess: OnReducer<
  ICDLListPurchaseState,
  ActionType<Payload<ISegmentData>>
> = (state: ICDLListPurchaseState, { payload }: Payload<ISegmentData>) => ({
  ...state,
  fetching: false,
  listSegmentData: payload,
});

const changeCDLListStatus: OnReducer<ICDLListPurchaseState, ActionType<any>> = (
  state: ICDLListPurchaseState,
) => ({
  ...state,
  statusChanging: true,
  statusChangeError: null,
});

const changeCDLListStatusError: OnReducer<
  ICDLListPurchaseState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  statusChanging: false,
  statusChangeError: payload,
});

const changeCDLListStatusSuccess: OnReducer<
  ICDLListPurchaseState,
  ActionType<any>
> = (state: ICDLListPurchaseState) => ({
  ...state,
  statusChanging: false,
  statusChangeError: null,
});

const renameCDLList: OnReducer<ICDLListPurchaseState, ActionType<any>> = (
  state: ICDLListPurchaseState,
) => ({
  ...state,
  loading: true,
  renaming: true,
  renameError: null,
});

const renameCDLListError: OnReducer<
  ICDLListPurchaseState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListPurchaseState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  renaming: false,
  renameError: payload,
});

const renameCDLListSuccess: OnReducer<
  ICDLListPurchaseState,
  ActionType<any>
> = (state: ICDLListPurchaseState) => ({
  ...state,
  loading: false,
  renaming: false,
  renameError: null,
});

const renameCDLListCancel: OnReducer<ICDLListPurchaseState, ActionType<any>> = (
  state: ICDLListPurchaseState,
) => ({
  ...state,
  renaming: false,
  renameError: null,
});

const resetCDLListState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLListPurchaseState> =
  createReducer<ICDLListPurchaseState>(
    initialState,

    on(actions.fetchCDLListPurchaseDataAction, fetchCDLListData),
    on(
      actions.fetchCDLListPurchaseDataErrorAction,
      fetchCDLListPurchaseDataError,
    ),
    on(
      actions.fetchCDLListPurchaseDataSuccessAction,
      fetchCDLListPurchaseDataSuccess,
    ),

    on(actions.fetchCDLListSegmentDataAction, fetchCDLListSegmentData),
    on(
      actions.fetchCDLListSegmentDataErrorAction,
      fetchCDLListSegmentDataError,
    ),
    on(
      actions.fetchCDLListSegmentDataSuccessAction,
      fetchCDLListSegmentDataSuccess,
    ),

    on(actions.changeCDLListStatusAction, changeCDLListStatus),
    on(actions.changeCDLListStatusErrorAction, changeCDLListStatusError),
    on(actions.changeCDLListStatusSuccessAction, changeCDLListStatusSuccess),

    on(actions.renameCDLListPurchaseAction, renameCDLList),
    on(actions.renameCDLListPurchaseErrorAction, renameCDLListError),
    on(actions.renameCDLListPurchaseSuccessAction, renameCDLListSuccess),
    on(actions.renameCDLListPurchaseCancelAction, renameCDLListCancel),

    on(actions.resetCDLListStateAction, resetCDLListState),
  );

export function cdlListPurchaseReducer(
  state: ICDLListPurchaseState,
  action: Action,
): ICDLListPurchaseState {
  return reducer(state, action);
}

export const cdlListSummaryData: GetFromState<
  IDataListSummary,
  ICDLListPurchaseState
> = (state: ICDLListPurchaseState): IDataListSummary =>
  state && state.summaryData;
export const cdlListSegmentTargeting: GetFromState<
  ISegmentTargetingCriteria,
  ICDLListPurchaseState
> = (state: ICDLListPurchaseState): ISegmentTargetingCriteria =>
  state && state.listSegmentData && state.listSegmentData.targetingCriteria;
export const cdlListSummaryDataList: GetFromState<
  ISummaryList,
  IDataListSummary
> = (state: IDataListSummary): ISummaryList => state && state.list;
export const cdlSummaryListId: GetFromState<number, ISummaryList> = (
  list: ISummaryList,
): number => list && list.id;
export const cdlListFetching: GetFromState<boolean, ICDLListPurchaseState> = (
  state: ICDLListPurchaseState,
): boolean => state && state.fetching;
export const cdlListMainTypes: GetFromState<
  IListMainTypes,
  ISummaryList,
  PRODUCT_ENTITY_TYPES
> = (
  list: ISummaryList,
  entityType: PRODUCT_ENTITY_TYPES = PRODUCT_ENTITY_TYPES.CDL,
): IListMainTypes => {
  if (list) {
    const { name, recordsType, type }: Partial<ISummaryList> = list;
    return { name, recordType: recordsType.id, listType: type.id, entityType };
  }

  return null;
};

export const renameError: GetFromState<IServerError, ICDLListPurchaseState> = (
  state: ICDLListPurchaseState,
): IServerError => state && state.renameError;
