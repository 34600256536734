import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "cardTypeMask",
})
export class CardTypeMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value
      .replace(/(\W+)/g, "")
      .toUpperCase()
      .replace(/^[^VMAD].*$/, "")
      .replace(/^(VS?|MC?|AM?E?X?|D[SN]?)(.*)$/, "$1");
  }

  parse(value: string): string {
    return this.transform(value);
  }
}
