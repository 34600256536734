import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-personnel-counters.action";

import { IServerError } from "@shared/interfaces/server-error";

export interface IQuickSearchPersonnelCountersState {
  colleges: number | null;
  districts: number | null;
  schools: number | null;

  pending: boolean;
  error: IServerError | null;
}

const initialState: IQuickSearchPersonnelCountersState = {
  colleges: null,
  districts: null,
  schools: null,

  pending: false,
  error: null,
};

const getQuickSearchPersonnelCounters: OnReducer<
  IQuickSearchPersonnelCountersState,
  ActionType<any>
> = (state: IQuickSearchPersonnelCountersState) => ({
  ...state,
  pending: true,
  error: null,
});

const getQuickSearchPersonnelCountersError: OnReducer<
  IQuickSearchPersonnelCountersState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchPersonnelCountersState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getQuickSearchPersonnelCountersSuccess: OnReducer<
  IQuickSearchPersonnelCountersState,
  ActionType<Payload<any>>
> = (state: IQuickSearchPersonnelCountersState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  ...payload,
});

const resetQuickSearchPersonnelCounters: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchPersonnelCountersState> =
  createReducer<IQuickSearchPersonnelCountersState>(
    initialState,

    on(
      actions.getQuickSearchPersonnelCountersAction,
      getQuickSearchPersonnelCounters,
    ),
    on(
      actions.getQuickSearchPersonnelCountersErrorAction,
      getQuickSearchPersonnelCountersError,
    ),
    on(
      actions.getQuickSearchPersonnelCountersSuccessAction,
      getQuickSearchPersonnelCountersSuccess,
    ),

    on(
      actions.resetQuickSearchPersonnelCountersAction,
      resetQuickSearchPersonnelCounters,
    ),
  );

export function quickSearchPersonnelCountersReducer(
  state: IQuickSearchPersonnelCountersState,
  action: Action,
): IQuickSearchPersonnelCountersState {
  return reducer(state, action);
}

export const colleges: GetFromState<
  number,
  IQuickSearchPersonnelCountersState
> = ({ colleges: _colleges }: IQuickSearchPersonnelCountersState): number =>
  _colleges;
export const districts: GetFromState<
  number,
  IQuickSearchPersonnelCountersState
> = ({ districts: _districts }: IQuickSearchPersonnelCountersState): number =>
  _districts;
export const schools: GetFromState<
  number,
  IQuickSearchPersonnelCountersState
> = ({ schools: _schools }: IQuickSearchPersonnelCountersState): number =>
  _schools;

export const pending: GetFromState<
  boolean,
  IQuickSearchPersonnelCountersState
> = ({ pending: _pending }: IQuickSearchPersonnelCountersState): boolean =>
  _pending;
export const error: GetFromState<
  IServerError,
  IQuickSearchPersonnelCountersState
> = ({ error: _error }: IQuickSearchPersonnelCountersState): IServerError =>
  _error;

export const isEmptyResults: (...counters: number[]) => boolean = (
  ...counters: number[]
): boolean =>
  !counters.filter((count: number) => typeof count === "number").length ||
  counters.filter((count: number) => count === 0).length === counters.length;
