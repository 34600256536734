import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { select, Store } from "@ngrx/store";

import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { getCreditsFullInfo } from "@modules/credits/store/selectors/credits.selector";
import * as accountPopUpActions from "@modules/profile/store/actions/account-pop-ups.action";
import { removeUserAction } from "@modules/profile/store/actions/account-users.action";
import * as editUserAccountActions from "@modules/profile/store/actions/edit-user-account.action";
import * as editUserAccountSelectors from "@modules/profile/store/selectors/edit-user-account.selector";
import {
  getIsDisableDeleteBtn,
  getSelectedAddOnsIds,
} from "@modules/profile/store/selectors/edit-user-account.selector";

import { StorageService } from "@core/services/storage.service";
import { AddOnsService } from "@modules/profile/services/add-ons.service";
import { ProfileFormsService } from "@modules/profile/services/profile-forms.service";

import { parseEditUserFormForSend } from "@modules/profile/utils/map-forms";

import {
  ICreditsResponse,
  IUpdateICreditsResponse,
} from "@modules/auth/interfaces/user";
import {
  IEditAccountUser,
  IEditUserForm,
  IEditUserFormForRequest,
  ISeatState,
  ISeatStates,
  ISelectedAddOnIds,
  ISelectedUserData,
  Roles,
  SEATS,
} from "@modules/profile/interfaces/marketview";
import { IControlOptions } from "@shared/interfaces/forms";
import { IServerError } from "@shared/interfaces/server-error";
import { IEditUserAccountPopUpData } from "../../interfaces";

import { PopUpRef } from "../../models/pop-up-ref";

import {
  getCanNotManageBidsDescription,
  getCanNotManageGrantsDescription,
} from "@core/constants/permissions";
import { ADD_ON_FIELD } from "@modules/profile/constants/add-ons";
import {
  ROLES_OPTIONS,
  USER_STATUS_IS_MERGE,
} from "@modules/profile/constants/marketview";
import { ACCOUNT_MESSAGES } from "@modules/profile/constants/messages";
import {
  CHECK_BOX_BID_NAME,
  CHECK_BOX_GRANT_NAME,
} from "@modules/profile/constants/products";
import { IS_DISABLED_USER_TEXT } from "@modules/profile/constants/tooltips";
import {
  BID_ICON,
  DEFAULT_FIGURE_CLASS,
  GRANT_ICON,
} from "@shared/constants/tooltips/default-values";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-edit-user-account-pop-up",
  templateUrl: "./edit-user-account-pop-up.component.html",
  styleUrls: ["./edit-user-account-pop-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditUserAccountPopUpComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private destroyer$: Subject<void> = new Subject();

  private _selectedAddOnsIds: ISelectedAddOnIds;

  readonly canNotRemoveAnybody: string = ACCOUNT_MESSAGES.USER_CAN_NOT_REMOVE;
  readonly oneSeatMsg: string = ACCOUNT_MESSAGES.ONE_STATE_MSG;

  readonly checkBoxBidName: string = CHECK_BOX_BID_NAME;
  readonly checkBoxGrantName: string = CHECK_BOX_GRANT_NAME;

  readonly bidIcon: typeof BID_ICON = BID_ICON;
  readonly grantIcon: typeof GRANT_ICON = GRANT_ICON;
  readonly tooltipFigure: string = DEFAULT_FIGURE_CLASS;

  readonly canNotManageGrantsText: string = getCanNotManageGrantsDescription();
  readonly canNotManageBidsText: string = getCanNotManageBidsDescription();

  readonly isDisabledUserText: string = IS_DISABLED_USER_TEXT;

  form: UntypedFormGroup;

  seatId: SEATS;
  selectedSeatId: SEATS = SEATS.Lite;
  rolesOptions: IControlOptions = ROLES_OPTIONS;

  isShowAvailable: boolean;

  isShowAvailableState$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getIsShowAvailableStates),
  );

  seatId$: Observable<SEATS> = this._store.pipe(
    select(editUserAccountSelectors.getSeatId),
  );

  baseSeats$: Observable<IControlOptions> = this._store.pipe(
    select(editUserAccountSelectors.getBaseSeatsOptions),
  );
  uncheckedStates$: Observable<IControlOptions> = this._store.pipe(
    select(editUserAccountSelectors.getUncheckedStatesOrSeatStates),
  );

  selectedAddOnsIds$: Observable<ISelectedAddOnIds> = this._store.pipe(
    select(getSelectedAddOnsIds),
  );

  isShowAddState$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getIsShowAddState),
  );
  isShowAvailableSeatStates$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getIsShouldShowAvailableStates),
  );

  isHasStates$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getIsHasStates),
  );
  canCreateNewState$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getCanCreateNewState),
  );

  error$: Observable<IServerError> = this._store.pipe(
    select(editUserAccountSelectors.getError),
  );
  pending$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getPending),
  );
  pendingInvitation$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getPendingInvitation),
  );

  getCanDeleteUser$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getCanDeleteUser),
  );
  getCanChangePermissions$: Observable<boolean> = this._store.pipe(
    select(editUserAccountSelectors.getCanChangePermissions),
  );

  getIsDisableDeleteBtn$: Observable<boolean> = this._store.pipe(
    select(getIsDisableDeleteBtn),
  );

  creditsInfo$: Observable<ICreditsResponse | IUpdateICreditsResponse> =
    this._store.pipe(select(getCreditsFullInfo));
  creditsInfo: ICreditsResponse;

  get states(): UntypedFormArray {
    return this.form.get("stateIds") as UntypedFormArray;
  }

  get stateIsCreated(): boolean {
    return this.states && this.states.controls && !!this.states.controls.length;
  }

  get isSelectedOneState(): boolean {
    return (
      this.states && this.states.controls && this.states.controls.length === 1
    );
  }

  get isShowRemoveStateBtn(): boolean {
    return (
      this.states && this.states.controls && this.states.controls.length > 1
    );
  }

  get nationalAddOns(): UntypedFormGroup {
    return this.form.get("nationalAddOns") as UntypedFormGroup;
  }

  get isNationalGrantAddOn(): AbstractControl {
    return (
      this.nationalAddOns && this.nationalAddOns.get("isNationalGrantAddOn")
    );
  }

  get isNationalBidAddOn(): AbstractControl {
    return this.nationalAddOns && this.nationalAddOns.get("isNationalBidAddOn");
  }

  get isCreatedNationalAddOns(): boolean {
    return this.nationalAddOns && !!this.nationalAddOns.controls;
  }

  get shouldShowAddState(): boolean {
    return (
      this.states &&
      this.states.value.every(
        (value: any) => value && typeof value.stateId === "number",
      )
    );
  }

  get isNationalSeat(): boolean {
    return this.selectedSeatId === SEATS.National;
  }

  get isDisabledUser(): boolean {
    return this.data.user && this.data.user.isDisabledUser;
  }

  get isInvitedUser(): boolean {
    return this.data.user && this.data.user.isInvited;
  }

  get isDisableInviteUser(): boolean {
    return (
      this.data.user && USER_STATUS_IS_MERGE.includes(this.data.user.status)
    );
  }

  get isAllocated(): boolean {
    return this.form.controls["isAllocated"].value;
  }

  constructor(
    @Inject(POP_UP_DATA) public data: IEditUserAccountPopUpData,
    private _popUpRef: PopUpRef,
    private _fb: UntypedFormBuilder,
    private _store: Store<CoreState>,
    private _formsService: ProfileFormsService,
    private _addOnsService: AddOnsService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.isShowAvailableSeatStates$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((isShowAvailable: boolean) => {
        this.isShowAvailable = isShowAvailable;
        this._cdr.markForCheck();
      });

    this.creditsInfo$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((data: ICreditsResponse) => {
        this.creditsInfo = data;
        this._cdr.markForCheck();
      });

    this.seatId$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((_seatId: SEATS) => {
        this.seatId = _seatId;
        this._cdr.markForCheck();
      });

    this.selectedAddOnsIds$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((_selectedAddOnsIds: ISelectedAddOnIds) => {
        this._selectedAddOnsIds = _selectedAddOnsIds;
        this._cdr.markForCheck();
      });

    const selectedUserData: ISelectedUserData = {
      openAddOns: this.data.openAddOns,
      selectedBidStateAddOnIds: this._selectedAddOnsIds.bidAddOnIds,
      selectedGrantStateAddOnIds: this._selectedAddOnsIds.grantAddOnIds,
      isDisabledUser: this.isDisabledUser,
      creditsInfo: this.creditsInfo,
    };
    this.form = this._formsService.createUserForm(
      this.data.user,
      selectedUserData,
    );
    this.selectedSeatId = this.data.user.seatId;

    this.error$
      .pipe(takeUntil(this.destroyer$))
      .subscribe((error: IServerError) => this._setErrors(error));
  }

  ngAfterViewInit(): void {
    if (this.stateIsCreated) {
      this.selectCurrentState();
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  selectSeat(selectedSeat: number): void {
    this.selectedSeatId = selectedSeat;

    this._store.dispatch(
      editUserAccountActions.changeSeatTypeAction(this.selectedSeatId),
    );

    if (this.selectedSeatId !== SEATS.State) {
      this.form.removeControl("stateIds");
    }

    if (this.selectedSeatId !== SEATS.National) {
      this.form.removeControl("nationalAddOns");
    }

    if (this.selectedSeatId === SEATS.State && !this.stateIsCreated) {
      this.form.addControl(
        "stateIds",
        this._fb.array([
          this._formsService.createStateFormGroup(
            null,
            this.data.openAddOns,
            this._selectedAddOnsIds.bidAddOnIds,
            this._selectedAddOnsIds.grantAddOnIds,
            this.isDisabledUser,
          ),
        ]),
      );
    }

    if (
      this.selectedSeatId === SEATS.National &&
      !this.isCreatedNationalAddOns
    ) {
      this.form.addControl(
        "nationalAddOns",
        this._formsService.createNationalAddOnGroup(
          [],
          this.data.openAddOns,
          this.isDisabledUser,
        ),
      );
    }

    this.form.updateValueAndValidity();
  }

  selectCurrentState(index: number = 0, selectedStateId?: number): void {
    const { bidStateIds, grantStateIds }: IEditAccountUser = this.data.user;

    const stateControl: AbstractControl = this.states.at(index);
    const bidStateAddOnControl: AbstractControl =
      stateControl && stateControl.get(ADD_ON_FIELD.BID);
    const grantStateAddOnControl: AbstractControl =
      stateControl && stateControl.get(ADD_ON_FIELD.GRANT);

    if (
      typeof selectedStateId === "number" &&
      !bidStateIds.includes(selectedStateId)
    ) {
      bidStateAddOnControl.reset();
    }

    if (
      typeof selectedStateId === "number" &&
      !grantStateIds.includes(selectedStateId)
    ) {
      grantStateAddOnControl.reset();
    }

    this._changeSelectedStates();

    if (typeof selectedStateId === "number") {
      this._addOnsService.checkAvailableAddOnsControl(
        this.data.openAddOns,
        bidStateIds,
        grantStateIds,
        this._selectedAddOnsIds,
        selectedStateId,
        bidStateAddOnControl,
        grantStateAddOnControl,
      );

      this._updateAvailableAddOns(false);
    }

    if (
      typeof index === "number" &&
      typeof selectedStateId === "number" &&
      !StorageService.doNotShowAssignStatePopUp
    ) {
      this._store.dispatch(accountPopUpActions.openAssignStateInfoPopUp());
    }

    this._store.dispatch(
      editUserAccountActions.setShowAvailableStatesAction(true),
    );
  }

  selectStateAddOn(isCheck: boolean): void {
    this._changeSelectedStates();
    this._updateAvailableAddOns(isCheck);
    this._isShowAssignAddOnInfoPopUp(isCheck);
  }

  handleSubmit(): void {
    if (this.form.valid) {
      const res: IEditUserFormForRequest = parseEditUserFormForSend({
        ...this.form.value,
      } as IEditUserForm);

      if (this.data.isEditSelf) {
        this._store.dispatch(
          editUserAccountActions.editSelfUserAccountAction(res),
        );
      } else {
        this._store.dispatch(editUserAccountActions.editAccountUserAction(res));
      }
    }
  }

  closeSelf(): void {
    this._store.dispatch(editUserAccountActions.closeEditUserPopUpAction());
  }

  removeUser(): void {
    if (this.data.user) {
      const { id }: IEditAccountUser = this.data.user;

      if (this.data.user.dataCount) {
        const {
          dataCount: { lists, views, credits, emails },
        }: IEditAccountUser = this.data.user;

        if (!credits && !emails && !lists && !views) {
          this.closeSelf();
          this._store.dispatch(removeUserAction({ userId: id }));
        }

        if (credits && !lists && !views && !emails) {
          this._store.dispatch(
            accountPopUpActions.openRemoveUserPopUpAction({ userId: id }),
          );
          return;
        }

        if (lists || views || emails) {
          this._store.dispatch(
            accountPopUpActions.openRemoveUserWithDataPopUpAction(
              this.data.user,
            ),
          );
          return;
        }
      } else {
        this.closeSelf();
        this._store.dispatch(
          removeUserAction({ userId: id, isInvited: this.isInvitedUser }),
        );
      }
    }
  }

  assignUserData(): void {
    this._store.dispatch(
      accountPopUpActions.openAssignDataPopUpAction(this.data.user.id),
    );
  }

  addState(): void {
    this._store.dispatch(
      editUserAccountActions.setShowAvailableStatesAction(true),
    );

    const control: UntypedFormArray = this.states;

    if (!control) {
      return;
    }

    const newControl: UntypedFormGroup =
      this._formsService.createStateFormGroup(
        null,
        this.data.openAddOns,
        this._selectedAddOnsIds.bidAddOnIds,
        this._selectedAddOnsIds.grantAddOnIds,
        true,
      );

    control.push(newControl);
  }

  removeState(index: number): void {
    const control: UntypedFormArray = this.states;

    if (control) {
      control.removeAt(index);

      const states: ISeatStates = control.value.map((item: ISeatState) => item);
      const { openStates, openAddOns }: IEditUserAccountPopUpData = this.data;
      this._store.dispatch(
        editUserAccountActions.changeSelectedStateAction({
          states,
          openStates,
          openAddOns,
        }),
      );

      this._updateAvailableAddOns(false);
    }
  }

  resendInvitation(): void {
    const { id }: IEditAccountUser = this.data.user;
    this._store.dispatch(editUserAccountActions.resendInvitationAction(id));
  }

  isDisabledAssignData(): boolean {
    if (this.data.user && this.data.user.dataCount) {
      const {
        dataCount: { emails, lists, views },
      }: IEditAccountUser = this.data.user;
      return !emails && !lists && !views;
    }

    return true;
  }

  changePermissions(): void {
    // assign self owner permission to another user
    if (this.data.user.roleId === Roles.Owner) {
      this._store.dispatch(
        accountPopUpActions.openAssignOwnerPopUpAction(this.data.user.id),
      );
    }
  }

  onSetShowAvailableStates(): void {
    this._store.dispatch(
      editUserAccountActions.setShowAvailableStatesAction(false),
    );
  }

  trackByIndex(index: number): number {
    return index;
  }

  private _changeSelectedStates(): void {
    const { openStates, openAddOns }: IEditUserAccountPopUpData = this.data;
    const states: ISeatStates = (this.states && this.states.value) || [];
    this._store.dispatch(
      editUserAccountActions.changeSelectedStateAction({
        states,
        openStates,
        openAddOns,
      }),
    );
  }

  private _updateAvailableAddOns(isChecked: boolean): void {
    const _states: UntypedFormArray = this.states;
    const { bidStateIds, grantStateIds }: IEditAccountUser = this.data.user;

    this._addOnsService.updateAvailableAddOnsControls(
      _states.controls,
      isChecked,
      this.data.openAddOns,
      bidStateIds,
      grantStateIds,
      this._selectedAddOnsIds,
    );
  }

  private _isShowAssignAddOnInfoPopUp(isCheck: boolean): void {
    if (isCheck && !StorageService.doNotShowAssignAddOnPopUp) {
      this._store.dispatch(accountPopUpActions.openAssignAddOnInfoPopUp());
    }
  }

  private _setErrors(error: IServerError): void {
    if (error) {
      Object.entries(error.errors).forEach(
        ([key, value]: [string, string[]]) => {
          const control: AbstractControl = this.form.controls[key];

          if (control) {
            control.setErrors({ serverError: value });
          }
        },
      );
    }
  }
}
