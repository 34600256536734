import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IPersonnelRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_QUICK_SEARCH_PERSONNEL_DISTRICTS = "[QUICK SEARCH PERSONNEL] get districts",
  GET_QUICK_SEARCH_PERSONNEL_DISTRICTS_ERROR = "[QUICK SEARCH PERSONNEL] get districts error",
  GET_QUICK_SEARCH_PERSONNEL_DISTRICTS_SUCCESS = "[QUICK SEARCH PERSONNEL] get districts success",

  SET_QUICK_SEARCH_PERSONNEL_DISTRICTS_SPINNER_STATE = "[QUICK SEARCH PERSONNEL] set spinner state",

  CHANGE_QUICK_SEARCH_PERSONNEL_DISTRICTS_PAGE = "[QUICK SEARCH PERSONNEL] Change districts page",
  CHANGE_QUICK_SEARCH_PERSONNEL_DISTRICTS_QUERY = "[QUICK SEARCH PERSONNEL] Change districts query",

  RESET_QUICK_SEARCH_PERSONNEL_DISTRICTS_PAGE_STAGE = "[QUICK SEARCH PERSONNEL] reset districts page state",
}

export const getQuickSearchPersonnelDistrictsAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_DISTRICTS,
  () => TypedAction<ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_DISTRICTS>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_DISTRICTS);
export const getQuickSearchPersonnelDistrictsErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_DISTRICTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_DISTRICTS_ERROR,
  createPayload<IServerError>(),
);
export const getQuickSearchPersonnelDistrictsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_DISTRICTS_SUCCESS,
  IProductResultsResponse<IPersonnelRecords>
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_DISTRICTS_SUCCESS,
  createPayload<IProductResultsResponse<IPersonnelRecords>>(),
);

export const setQuickSearchPersonnelDistrictsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_QUICK_SEARCH_PERSONNEL_DISTRICTS_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_QUICK_SEARCH_PERSONNEL_DISTRICTS_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeQuickSearchPersonnelDistrictsPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_PERSONNEL_DISTRICTS_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_PERSONNEL_DISTRICTS_PAGE,
  createPayload<number>(),
);

export const changeQuickSearchPersonnelDistrictsQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_PERSONNEL_DISTRICTS_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_PERSONNEL_DISTRICTS_QUERY,
  createPayload<Params>(),
);

export const resetQuickSearchPersonnelDistrictsStateAction: ActionCreator<
  ACTION_TYPES.RESET_QUICK_SEARCH_PERSONNEL_DISTRICTS_PAGE_STAGE,
  () => TypedAction<ACTION_TYPES.RESET_QUICK_SEARCH_PERSONNEL_DISTRICTS_PAGE_STAGE>
> = createAction(
  ACTION_TYPES.RESET_QUICK_SEARCH_PERSONNEL_DISTRICTS_PAGE_STAGE,
);
