import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";

import { of, Observable } from "rxjs";
import {
  catchError,
  debounceTime,
  filter,
  map,
  switchMap,
  switchMapTo,
} from "rxjs/operators";

import { ActionWithPayload } from "@shared/interfaces/store";
import * as actions from "../actions/account.action";

import { AccountService } from "../../services/account.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IStateOption } from "@shared/interfaces/forms";
import { IServerError } from "@shared/interfaces/server-error";
import { IAccountSeat } from "../../interfaces/marketview";

import { EMAILS_MIN_LENGTH_FOR_SEARCH } from "../../constants/autocomplete";

@Injectable()
export class AccountEffect {
  constructor(
    private actions$: Actions,
    private service: AccountService,
  ) {}

  getEmails$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getEmailsAction),
      debounceTime(300),
      filter(
        ({ payload }: ActionWithPayload<string>) =>
          payload.length >= EMAILS_MIN_LENGTH_FOR_SEARCH,
      ),
      switchMap(({ payload }: ActionWithPayload<string>) =>
        this.service.getEmailsOfUsers(payload).pipe(
          map((emails: string[]) => actions.getEmailsSuccessAction(emails)),
          catchError((error: IServerError) =>
            of(actions.getEmailsErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  getProfileSeats$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAccountSeatsAction),
      switchMap(() =>
        this.service.getAccountSeats().pipe(
          map((seats: IAccountSeat[]) =>
            actions.getAccountSeatsSuccessAction(seats),
          ),
          catchError((error: IServerError) =>
            of(actions.getAccountSeatsErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  getStates$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getAccountStatesAction),
      switchMapTo(
        this.service.getStates().pipe(
          map((states: IStateOption[]) =>
            actions.getAccountStatesSuccessAction(states),
          ),
          catchError((error: IServerError) =>
            of(actions.getAccountStatesErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );
}
