import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/bid-profile.action";

import { MapDocument } from "../../utils/map-document";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IBidDocument,
  IBidInstitutionDetail,
  IBidProfile,
  IBidProfileCategory,
  IBidProfileOverview,
} from "../../interfaces/bid-profile";

export interface IBidProfileState {
  profile: IBidProfile | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IBidProfileState = {
  profile: null,
  pending: false,
  error: null,
};

const getBidProfile: OnReducer<IBidProfileState, ActionType<any>> = (
  state: IBidProfileState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getBidProfileError: OnReducer<IBidProfileState, ActionType<any>> = (
  state: IBidProfileState,
  { payload: error }: Payload<any>,
) => ({
  ...state,
  pending: false,
  error,
});

const getBidProfileSuccess: OnReducer<IBidProfileState, ActionType<any>> = (
  state: IBidProfileState,
  { payload: profile }: Payload<any>,
) => ({
  ...state,
  pending: false,
  error: null,
  profile,
});

const resetBidProfileState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IBidProfileState> =
  createReducer<IBidProfileState>(
    initialState,

    on(actions.getBidProfileAction, getBidProfile),
    on(actions.getBidProfileErrorAction, getBidProfileError),
    on(actions.getBidProfileSuccessAction, getBidProfileSuccess),

    on(actions.resetBidProfileStateAction, resetBidProfileState),
  );

export const bidProfile: GetFromState<IBidProfile, IBidProfileState> = (
  state: IBidProfileState,
): IBidProfile => state.profile;
export const bidProfilePending: GetFromState<boolean, IBidProfileState> = (
  state: IBidProfileState,
): boolean => state.pending;
export const bidProfileError: GetFromState<
  IServerError | null,
  IBidProfileState
> = (state: IBidProfileState): IServerError | null => state.error;

export const bidProfileOverview: GetFromState<
  IBidProfileOverview,
  IBidProfile
> = (profile: IBidProfile): IBidProfileOverview => profile && profile.overview;
export const bidProfileCategories: GetFromState<
  IBidProfileCategory[] | null,
  IBidProfile
> = (profile: IBidProfile): IBidProfileCategory[] | null =>
  profile && profile.category;
export const bidProfileInstitutionDetail: GetFromState<
  IBidInstitutionDetail | null,
  IBidProfile
> = (profile: IBidProfile): IBidInstitutionDetail | null =>
  profile && profile.institutionDetail;
export const bidProfileDocuments: GetFromState<IBidDocument[], IBidProfile> = (
  profile: IBidProfile,
): IBidDocument[] => profile && profile.documents;
export const documentsWithImg: GetFromState<IBidDocument[], IBidDocument[]> = (
  documents: IBidDocument[],
): IBidDocument[] => {
  return (
    documents &&
    documents.map((document: IBidDocument) => new MapDocument(document))
  );
};

export function bidProfileReducer(
  state: IBidProfileState,
  action: Action,
): IBidProfileState {
  return reducer(state, action);
}
