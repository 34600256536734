import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";

import { tooltipEffects } from "./store/effects";
import {
  TOOLTIPS_MODULE_FEATURE_NAME,
  TOOLTIPS_MODULE_FEATURE_STATE,
} from "./store/reducers";

import { SharedPipesModule } from "@shared/modules/pipes/shared-pipes/shared-pipes.module";

import { TooltipContainerComponent } from "./containers/tooltip-container/tooltip-container.component";

import { TooltipComponent } from "./components/tooltip/tooltip.component";

import { TooltipDirective } from "./directives/tooltip.directive";

const imports: any[] = [
  CommonModule,
  StoreModule.forFeature(
    TOOLTIPS_MODULE_FEATURE_NAME,
    TOOLTIPS_MODULE_FEATURE_STATE,
  ),
  EffectsModule.forFeature(tooltipEffects),

  // TODO Next dependency should be removed
  RouterModule,
  SharedPipesModule,
];

const providers: any[] = [];

const containers: any[] = [TooltipContainerComponent];

const components: any[] = [TooltipComponent];

const directives: any[] = [TooltipDirective];

@NgModule({
  imports,
  providers,
  declarations: [...containers, ...components, ...directives],
  exports: [...containers, ...components, ...directives],
})
export class TooltipModule {}
