import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
} from "@angular/core";

@Component({
  selector: "bl-drop-down-close-footer",
  templateUrl: "./drop-down-close-footer.component.html",
  styleUrls: ["./drop-down-close-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropDownCloseFooterComponent {
  @Output() closeDropDownCloseFooter: EventEmitter<any> = new EventEmitter();

  handleClick(): void {
    this.closeDropDownCloseFooter.emit();
  }
}
