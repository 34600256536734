import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { SUB_MENU_VALIGN } from "@ui/drop-down/components/submenu/submenu.component";

import {
  ICloudSyncPlatforms,
  ISelectPlatformEmit,
} from "@core/interfaces/cloud-sync";
import { ISummaryList } from "@shared/interfaces/list";

import { PLATFORM_INSTANCE } from "@core/constants/cloud-sync";
import { CORE_PATHS } from "@core/constants/core-paths";
import { LIST_CLOUD_SYNC_PATHS } from "@modules/list-cloud-sync/constants/list-cloud-sync-paths";
import { LIST_RECORDS_TYPES } from "@shared/constants/data/list-types";

@Component({
  selector: "bl-cloud-sync-footer",
  templateUrl: "./cloud-sync-footer.component.html",
  styleUrls: ["./cloud-sync-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CloudSyncFooterComponent {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly listCloudSyncPaths: typeof LIST_CLOUD_SYNC_PATHS =
    LIST_CLOUD_SYNC_PATHS;

  @Input() platforms: ICloudSyncPlatforms;
  @Input() listSummary: ISummaryList;
  @Input() sandbox: boolean;

  @Input() isShowNextBtn: boolean = false;
  @Input() isShowBackBtn: boolean = false;
  @Input() isShowSyncToBtn: boolean = false;
  @Input() isShowAuthorizeBtn: boolean = false;

  @Input() isShowDropdownMoreBtn: boolean = false;

  @Output() cloudSyncFooterSelectPlatform: EventEmitter<ISelectPlatformEmit> =
    new EventEmitter<ISelectPlatformEmit>();
  @Output() cloudSyncFooterOpenSyncToPopUp: EventEmitter<ISelectPlatformEmit> =
    new EventEmitter<ISelectPlatformEmit>();

  readonly listRecordsType: typeof LIST_RECORDS_TYPES = LIST_RECORDS_TYPES;
  readonly subMenuValign: typeof SUB_MENU_VALIGN = SUB_MENU_VALIGN;

  get instance(): PLATFORM_INSTANCE {
    return this.sandbox ? PLATFORM_INSTANCE.SANDBOX : PLATFORM_INSTANCE.PROD;
  }

  get listRecords(): number {
    return this.listSummary.recordsType.id ===
      this.listRecordsType.BUILDINGS_ONLY
      ? this.listSummary.institutionsCount
      : this.listSummary.personsCount;
  }

  selectPlatform({ platform, sandbox }: ISelectPlatformEmit): void {
    this.cloudSyncFooterSelectPlatform.emit({
      platform,
      sandbox,
      records: this.listRecords,
      listId: this.listSummary.id,
    });
  }

  openSyncToPopUp(): void {
    this.cloudSyncFooterOpenSyncToPopUp.emit({
      records: this.listRecords,
      listId: this.listSummary.id,
    });
  }
}
