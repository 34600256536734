import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { customerViewReducer, ICustomerViewState } from "./view.reducer";

export interface IViewModuleState {
  customerView: ICustomerViewState;
}

const viewModuleReducer: ActionReducerMap<IViewModuleState> = {
  customerView: customerViewReducer,
};

// eslint-disable-next-line
export const VIEW_STATE_NAME = 'viewModule';
export const VIEW_STATE: InjectionToken<ActionReducerMap<IViewModuleState>> =
  new InjectionToken<ActionReducerMap<IViewModuleState>>(VIEW_STATE_NAME, {
    factory: () => viewModuleReducer,
  });
