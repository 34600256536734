import { Pipe, PipeTransform } from "@angular/core";

import { PHONE_SEPARATORS } from "@shared/constants/data/phone-separators";

@Pipe({
  name: "phone",
})
export class PhonePipe implements PipeTransform {
  transform(value: string | number, separator: string): any {
    const phone: string = typeof value === "number" ? value.toString() : value;

    return phone.replace(
      /(\d{3,3})(\d{3,3})(\d+)/,
      PHONE_SEPARATORS[separator],
    );
  }
}
