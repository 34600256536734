import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";

import { NumberPipesModule } from "@shared/modules/pipes/number-pipes/number-pipes.module";

import { MobileTabsNavItemComponent } from "./components/mobile-tabs-nav-item/mobile-tabs-nav-item.component";
import { MobileTabsNavComponent } from "./components/mobile-tabs-nav/mobile-tabs-nav.component";

const declarations: any[] = [
  MobileTabsNavComponent,
  MobileTabsNavItemComponent,
];

@NgModule({
  declarations,
  imports: [CommonModule, MatRippleModule, NumberPipesModule],
  exports: [...declarations],
})
export class MobileTabsNavModule {}
