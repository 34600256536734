import { Injector } from "@angular/core";

export class CustomInjector implements Injector {
  constructor(
    private parentInjector: Injector,
    private customTokens: WeakMap<any, any>,
  ) {}

  get<T>(token: any, notFoundValue?: any): T {
    const value: any = this.customTokens.get(token);

    if (typeof value !== "undefined") {
      return value;
    }

    return this.parentInjector.get<T>(token, notFoundValue);
  }
}
