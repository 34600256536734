// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  padding: 20px 20px 32px;
  max-width: 1080px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  :host {
    padding: 20px 15px 32px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/profile/pages/profile-settings-page/profile-settings-page.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,uBAAA;EACA,iBAAA;EACA,cAAA;AADF;;AAIA;EACE;IACE,uBAAA;EADF;AACF","sourcesContent":["@import \"../../../../../assets/styles/variables\";\n\n:host {\n  display: block;\n  padding: 20px 20px 32px;\n  max-width: 1080px;\n  margin: 0 auto;\n}\n\n@media (max-width: $mq-width-tablet) {\n  :host {\n    padding: 20px 15px 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
