import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/alerts-history.action";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IAlerts,
  IGetAlertHistoryQueryParams,
} from "../../interfaces/alert-history";

import { DEFAULT_GET_ALERT_HISTORY_PARAMS } from "../../constants/alert-history";

export interface IGrantProfileAlertHistoryState {
  alerts: IAlerts;
  count: number;

  params: IGetAlertHistoryQueryParams;

  pending: boolean;
  loaded: boolean;
  error: IServerError | null;
}

const initialState: IGrantProfileAlertHistoryState = {
  alerts: [],
  count: 0,

  params: DEFAULT_GET_ALERT_HISTORY_PARAMS,

  pending: false,
  loaded: false,
  error: null,
};

const getGrantProfileAlertHistory: OnReducer<
  IGrantProfileAlertHistoryState,
  ActionType<Payload<any>>
> = (state: IGrantProfileAlertHistoryState, { payload }: Payload<any>) => ({
  ...state,
  params: {
    ...state.params,
    offset:
      typeof payload === "number" ? (payload - 1) * state.params.limit : 0,
  },
  pending: true,
  error: null,
});

const getGrantProfileAlertHistoryError: OnReducer<
  IGrantProfileAlertHistoryState,
  ActionType<Payload<IServerError>>
> = (
  state: IGrantProfileAlertHistoryState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getGrantProfileAlertHistorySuccess: OnReducer<
  IGrantProfileAlertHistoryState,
  ActionType<Payload<any>>
> = (state: IGrantProfileAlertHistoryState, { payload }: Payload<any>) => ({
  ...state,
  count: payload.alerts.count,
  alerts: [...payload.alerts.items],
  loaded: true,
  pending: false,
});

const sortGrantProfileAlertHistory: OnReducer<
  IGrantProfileAlertHistoryState,
  ActionType<Payload<any>>
> = (state: IGrantProfileAlertHistoryState, { payload }: Payload<any>) => ({
  ...state,
  params: {
    ...state.params,
    sortBy: payload,
  },
});

const orderGrantProfileAlertHistory: OnReducer<
  IGrantProfileAlertHistoryState,
  ActionType<Payload<any>>
> = (state: IGrantProfileAlertHistoryState, { payload }: Payload<any>) => ({
  ...state,
  params: {
    ...state.params,
    orderBy: payload,
  },
});

const resetGrantProfileAlertsHistory: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IGrantProfileAlertHistoryState> =
  createReducer<IGrantProfileAlertHistoryState>(
    initialState,

    on(actions.getGrantProfileAlertHistoryAction, getGrantProfileAlertHistory),
    on(
      actions.getGrantProfileAlertHistoryErrorAction,
      getGrantProfileAlertHistoryError,
    ),
    on(
      actions.getGrantProfileAlertHistorySuccessAction,
      getGrantProfileAlertHistorySuccess,
    ),

    on(
      actions.sortGrantProfileAlertHistoryAction,
      sortGrantProfileAlertHistory,
    ),
    on(
      actions.orderGrantProfileAlertHistoryAction,
      orderGrantProfileAlertHistory,
    ),

    on(
      actions.resetGrantProfileAlertsHistoryAction,
      resetGrantProfileAlertsHistory,
    ),
  );

export function grantProfileAlertHistoryReducer(
  state: IGrantProfileAlertHistoryState,
  action: Action,
): IGrantProfileAlertHistoryState {
  return reducer(state, action);
}

export const grantProfileAlertsHistory: GetFromState<
  IAlerts,
  IGrantProfileAlertHistoryState
> = (state: IGrantProfileAlertHistoryState): IAlerts => state.alerts;
export const grantProfileAlertsHistoryPending: GetFromState<
  boolean,
  IGrantProfileAlertHistoryState
> = (state: IGrantProfileAlertHistoryState): boolean => state.pending;
export const grantProfileAlertsHistoryError: GetFromState<
  IServerError,
  IGrantProfileAlertHistoryState
> = (state: IGrantProfileAlertHistoryState): IServerError => state.error;

export const grantProfileAlertsHistoryLoaded: GetFromState<
  boolean,
  IGrantProfileAlertHistoryState
> = (state: IGrantProfileAlertHistoryState): boolean => state.loaded;
export const grantProfileAlertsCount: GetFromState<
  number,
  IGrantProfileAlertHistoryState
> = (state: IGrantProfileAlertHistoryState): number => state.count;
export const grantProfileAlertsParams: GetFromState<
  IGetAlertHistoryQueryParams,
  IGrantProfileAlertHistoryState
> = (state: IGrantProfileAlertHistoryState): IGetAlertHistoryQueryParams =>
  state.params;
export const grantProfileAlertsParamsWithId: GetFromState<
  any,
  IGetAlertHistoryQueryParams,
  number
> = (params: IGetAlertHistoryQueryParams, id: number): any => {
  return { ...params, id };
};

export const grantProfileAlertsCurrentPage: GetFromState<
  number,
  IGrantProfileAlertHistoryState
> = (state: IGrantProfileAlertHistoryState): number => {
  const { offset, limit }: IGetAlertHistoryQueryParams = state.params;
  return Math.ceil(offset / limit) + 1;
};
