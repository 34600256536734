import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { Observable } from "rxjs";

import { pluckAndCatch } from "@shared/utils/response-formater";

import { ICreditsResponse } from "../../auth/interfaces/user";

@Injectable({ providedIn: "root" })
export class CreditsService {
  constructor(private _http: HttpClient) {}

  getCredits(): Observable<ICreditsResponse> {
    return this._http
      .get(environment.api.dashboard.creditsCounter)
      .pipe(pluckAndCatch);
  }
}
