import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { getRouterQueryParams } from "@core/store/selectors/router.selector";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { ECommerceState } from "../reducers";
import {
  confirmTerms,
  dataForCalculateTax,
  defaultBillingInfo,
  defaultCardSignatureData,
  defaultCardSignatureLoaded,
  defaultPaymentDataForCreatingSignature,
  newCardSignatureLoaded,
  newCardTransactionData,
  newPaymentDataForCreatingSignature,
  salesOrderAvailable,
  salesResultData,
  toSalesOrderData,
  IPaymentState,
} from "../reducers/payment.reducer";

import {
  IDefaultBillingInfo,
  IDefaultTransactionData,
  INewCardTransactionData,
  ISalesOrderParams,
} from "@modules/e-commerce/interfaces/e-commerce";
import { ITaxDataResponse } from "@modules/e-commerce/interfaces/price";

import { getECommerceState } from "./";
import {
  getCartGrandTotal,
  getCartItems,
  getCartSubTotalPrice,
  getTax,
  getTaxPending,
  getValidateCartItemsForOrder,
} from "./cart.selector";
import {
  getNewCreditCard,
  getSelectedCreditCard,
  isNewCreditCardSelected,
} from "./payment-cards.selector";

export const getPaymentState: GetFromStateInSelectors<
  CoreState,
  IPaymentState
> = createSelector(
  getECommerceState,
  (state: ECommerceState) => state.eCommercePayment,
);

export const getDefaultBillingInfo: MemoizedSelector<
  CoreState,
  IDefaultBillingInfo
> = createSelector(getPaymentState, defaultBillingInfo);

export const getConfirmTerms: MemoizedSelector<CoreState, boolean> =
  createSelector(getPaymentState, confirmTerms);

export const getDefaultCardSignatureLoaded: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(getPaymentState, defaultCardSignatureLoaded);
export const getNewCardSignatureLoaded: MemoizedSelector<CoreState, boolean> =
  createSelector(getPaymentState, newCardSignatureLoaded);

export const getDefaultCardTransactionData: MemoizedSelector<
  CoreState,
  IDefaultTransactionData
> = createSelector(getPaymentState, defaultCardSignatureData);

export const getDataForCalculateTax: MemoizedSelector<
  CoreState,
  ITaxDataResponse
> = createSelector(
  // changed by task https://schooldatamdr.atlassian.net/browse/BL-4084
  // isNewCreditCardSelected,
  // getNewCreditCard,
  // getSelectedCreditCard,
  getCartSubTotalPrice,
  getCartItems,
  getDefaultBillingInfo,
  dataForCalculateTax,
);

export const getSalesOrderSendData: MemoizedSelector<
  CoreState,
  ISalesOrderParams
> = createSelector(
  getValidateCartItemsForOrder,
  isNewCreditCardSelected,
  getNewCreditCard,
  getSelectedCreditCard,
  getTax,
  getRouterQueryParams,
  toSalesOrderData,
);

export const getSaleResultData: MemoizedSelector<CoreState, boolean> =
  createSelector(getPaymentState, salesResultData);

export const getNewCardDataForCreatingSignature: MemoizedSelector<
  CoreState,
  any
> = createSelector(
  getSalesOrderSendData,
  getSaleResultData,
  getCartGrandTotal,
  getDefaultBillingInfo,
  newPaymentDataForCreatingSignature,
);

export const getDefaultCardCreateSignatureData: MemoizedSelector<
  CoreState,
  IDefaultTransactionData
> = createSelector(
  getSalesOrderSendData,
  getSaleResultData,
  getCartGrandTotal,
  defaultPaymentDataForCreatingSignature,
);

export const getNewCardTransactionData: MemoizedSelector<
  CoreState,
  INewCardTransactionData
> = createSelector(getPaymentState, getNewCreditCard, newCardTransactionData);

export const isSalesOrderAvailable: MemoizedSelector<CoreState, boolean> =
  createSelector(
    getSalesOrderSendData,
    getConfirmTerms,
    getCartSubTotalPrice,
    getTaxPending,
    salesOrderAvailable,
  );
