import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import {
  IProfileSetting,
  IProfileSettings,
} from "../../interfaces/profile-settings";

@Component({
  selector: "bl-profile-settings-form",
  templateUrl: "./profile-settings-form.component.html",
  styleUrls: ["./profile-settings-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSettingsFormComponent {
  @Input() settings: IProfileSettings;
  @Input() pending: boolean;

  @Output() profileSettingsFormChange: EventEmitter<IProfileSetting> =
    new EventEmitter();

  onChangeSetting(value: boolean, id: number): void {
    this.profileSettingsFormChange.emit({ value: value, id: id });
  }

  trackById(index: number, setting: IProfileSetting): number {
    return setting.id;
  }
}
