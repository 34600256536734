import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
} from "@angular/forms";

import { ISignUpFormData } from "@modules/auth/interfaces/formsActionsData";
import { INewUser } from "@modules/auth/interfaces/user";
import { IServerError } from "@shared/interfaces/server-error";

import { CORE_PATHS } from "@core/constants/core-paths";
import {
  FLAT_INPUT_THEME,
  INPUT_AUTOCOMPLETE,
} from "@shared/constants/flat-input";
import { PASSWORD_TIP } from "@shared/constants/tooltips/tooltips-text";
import { IControlOption } from "@shared/interfaces/forms";
import { PhoneMaskPipe } from "@shared/modules/pipes/shared-pipes/pipes/phone-mask.pipe";
import { ZipCodeMaskPipe } from "@shared/modules/pipes/shared-pipes/pipes/zip-code-mask.pipe";
import { Validators } from "@shared/validators/validators";

@Component({
  selector: "bl-sign-up-form",
  templateUrl: "./sign-up-form.component.html",
  styleUrls: ["./sign-up-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormComponent implements OnInit, OnChanges {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;

  @ViewChild("signUpBtn") signUpBtn: ElementRef;

  @Input() isReCaptcha: boolean = false;
  @Input() form: UntypedFormGroup;
  @Input() emailId: string;
  @Input() checkUserNameLoad: boolean;
  @Input() checkUserNameMessage: string;
  @Input() getSignUpCompleteStatus: boolean;

  @Input() countries: IControlOption[];
  @Input() states: IControlOption[];
  @Input() loading: boolean = false;
  @Input() serverError: IServerError;
  @Input() isShowFooter: boolean = true;
  @Input() theme: FLAT_INPUT_THEME;
  @Input() isShowTitle: boolean = true;
  @Input() autocompleteNewPassword: INPUT_AUTOCOMPLETE =
    INPUT_AUTOCOMPLETE.NEWPASSWORD;

  @Output() submitSignUpForm: EventEmitter<ISignUpFormData> =
    new EventEmitter();
  @Output() checkUserExistence: EventEmitter<string> = new EventEmitter();

  @Output() selectCountry: EventEmitter<string> = new EventEmitter();

  zipCodeMask: ZipCodeMaskPipe = new ZipCodeMaskPipe();
  phoneMask: PhoneMaskPipe = new PhoneMaskPipe();

  remember: boolean = false;
  passwordTip: string = PASSWORD_TIP;
  remainingFormFlag: boolean;

  get userExistsError(): string[] {
    if (
      this.serverError &&
      this.serverError.errors &&
      this.serverError.errors.userExists
    ) {
      return this.serverError.errors.userExists;
    }
  }

  ngOnInit() {
    this.setEmailField();
  }

  setEmailField() {
    if (this.emailId) {
      this.form.controls["email"].setValue(this.emailId);
      this.form.controls["email"].updateValueAndValidity();
      this.form.controls["email"].disable();
      this.emailFilled("not-blur");
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const errors: SimpleChange = changes.serverError;

    if (errors && errors.currentValue) {
      Object.entries(this.serverError.errors).forEach(
        ([fieldName, message]: [string, string[]]) => {
          const control: AbstractControl = this.form.get(fieldName);

          if (control) {
            control.setErrors(message as ValidationErrors);
          }
        },
      );

      this._resetFieldRecaptcha();
    }
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    let newUser: INewUser = { ...(this.form.value as INewUser) };

    if (!this.form.contains("email")) {
      newUser = { ...newUser, email: this.form.controls["email"].value };
    }

    delete newUser.confirmPassword;

    this.submitSignUpForm.emit({ newUser, remember: this.remember });

    // todo: REMOVE IT IF CLIENT DECIDE TO DELETE PIXEL
    // if (environment.envName === 'prod') {
    //   this.matchPixelService.addMathPixelScript({
    //     element: this.signUpBtn,
    //     url: environment.mathPixelSignUpButton
    //   });
    // }
  }

  emailFilled(blur) {
    this.remainingFormFlag =
      this.form.controls["email"] &&
      this.form.controls["email"].value &&
      this.form.controls["email"].value.length > 0;
    if (blur !== "not-blur") {
      this.checkUserExistence.emit(this.form.controls["email"].value);
    }
  }

  country(countryCode: string): void {
    this.selectCountry.emit(countryCode);
  }

  private _resetFieldRecaptcha(): void {
    this.form.patchValue({ gRecaptchaResponse: "" });
  }
}
