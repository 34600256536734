import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { timer, Observable, Subscription } from "rxjs";
import { finalize } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { setSpinnerAction } from "../store/actions/spinner.action";

import { LayoutService } from "@core/services/layout.service";

@Injectable({
  providedIn: "root",
})
export class LoadingInterceptor implements HttpInterceptor {
  activeRequests: number = 0;

  constructor(
    private _store: Store<CoreState>,
    private _layoutService: LayoutService,
  ) {}

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    this.activeRequests++;

    const subscription$: Subscription = timer(3000).subscribe(() => {
      if (!this._layoutService.globalScrollDisabled) {
        this._layoutService.disableGlobalScroll();
      }
      this._store.dispatch(setSpinnerAction(true));
    });

    return next.handle(req).pipe(
      finalize(() => {
        this.activeRequests--;

        if (subscription$) {
          subscription$.unsubscribe();
        }

        // stop loading when finished last request
        if (this.activeRequests === 0) {
          this._store.dispatch(setSpinnerAction(false));
          if (this._layoutService.globalScrollDisabled) {
            this._layoutService.enableGlobalScroll();
          }
        }
      }),
    );
  }
}
