import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { environment } from "@env/environment";

import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";

import { errorHandler, pluckAndCatch } from "@shared/utils/response-formater";

import { IFieldValues } from "@shared/interfaces/forms";
import {
  IOmsAuthPayload,
  IOmsField,
  IOmsFields,
  IOmsPlatforms,
} from "../interfaces";

import { FORM_VALIDATION_ERRORS } from "@shared/constants/validators/forms-validations-errors";

import { Validators } from "@shared/validators/validators";

@Injectable({
  providedIn: "root",
})
export class OmsService {
  private _baseUrl: string = `${environment.api.cloudSync.main}/${environment.api.cloudSync.oms}`;
  private _platformUrl: string = environment.api.cloudSync.platforms;
  private _authUrl: string = environment.api.cloudSync.auth;

  constructor(
    private http: HttpClient,
    private fb: UntypedFormBuilder,
  ) {}

  getOmsPlatforms(): Observable<IOmsPlatforms> {
    return this.http
      .get(`${this._baseUrl}/${this._platformUrl}`)
      .pipe(pluck("data"), errorHandler);
  }

  getOmsPlatformFields(platformId: number): Observable<IOmsFields> {
    return this.http
      .get(`${this._baseUrl}/${this._authUrl}/${platformId}`)
      .pipe(pluckAndCatch);
  }

  platformSignIn(platformId: number, data: IOmsAuthPayload): Observable<any> {
    return this.http
      .post(`${this._baseUrl}/${this._authUrl}/${platformId}`, { ...data })
      .pipe(errorHandler);
  }

  createOmsAuthForm(
    _fields: IOmsFields,
    sandbox: boolean = true,
    formData?: IFieldValues,
  ): UntypedFormGroup {
    const formControls: IFieldValues = {
      sandbox: [(formData && formData.sandbox) || sandbox],
      accountNumber: [
        (formData && formData.accountNumber) || "",
        Validators.required(FORM_VALIDATION_ERRORS.accountNumber.required),
      ],
    };

    _fields.forEach((item: IOmsField) => {
      formControls[item.name] = [
        (formData && formData[item.name]) || "",
        item.isRequired
          ? Validators.required(`${item.label} is required`)
          : null,
      ];
    });

    return this.fb.group(formControls) as UntypedFormGroup;
  }
}
