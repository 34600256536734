import { DocumentRef } from "@core/refs/document-ref.service";

export type IScriptParamsTypes = string | boolean;

export const appendExternalScriptToBody: (
  document: DocumentRef,
  params: Iterable<readonly [string, IScriptParamsTypes]>,
  elementType?: keyof HTMLElementTagNameMap,
) => Element = (
  document: DocumentRef,
  params: Iterable<readonly [string, IScriptParamsTypes]>,
  elementTagName: keyof HTMLElementTagNameMap = "script",
) => {
  const scriptParams: Map<string, IScriptParamsTypes> = new Map(params);

  const externalScript: Element =
    document.nativeElement.createElement(elementTagName);

  scriptParams.forEach((value: IScriptParamsTypes, key: string) => {
    externalScript[key] = value;
  });

  document.nativeElement.body.appendChild(externalScript);
  return externalScript;
};

// creates div with id and includes required {element} tag
export const appendExternalScriptTo: (
  document: DocumentRef,
  id: string,
  tag?: any,
) => Element = (document: DocumentRef, id: string, tag) => {
  const externalScript: Element = document.nativeElement.createElement("div");
  externalScript["id"] = id;
  document.nativeElement.body.appendChild(externalScript);

  externalScript.appendChild(tag);
  return externalScript;
};
