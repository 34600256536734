import { AbstractControl, ValidatorFn } from "@angular/forms";

import { getCardType } from "../utils/get-card-type";

export default function cardType(msg?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const message: string = msg || "Cart Number is not valid";

    return getCardType(control.value) ? null : { cardType: message };
  };
}
