import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  personnelEntityReducer,
  IPersonnelEntityState,
} from "@shared/modules/personnel-entity/store/reducers/personnel-entity.reducer";

export interface IPersonnelEntityModuleState {
  personnelEntity: IPersonnelEntityState;
}

const personnelEntityReducers: ActionReducerMap<IPersonnelEntityModuleState> = {
  personnelEntity: personnelEntityReducer,
};

// eslint-disable-next-line
export const PERSONNEL_ENTITY_STATE_NAME = 'personnelEntity';

export const PERSONNEL_ENTITY_STATE: InjectionToken<
  ActionReducerMap<IPersonnelEntityModuleState>
> = new InjectionToken<ActionReducerMap<IPersonnelEntityModuleState>>(
  PERSONNEL_ENTITY_STATE_NAME,
  {
    factory: () => personnelEntityReducers,
  },
);
