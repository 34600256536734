import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LayoutService } from "@core/services/layout.service";
import { ViewPointsService } from "../services/view-points.service";

import { WINDOW_POINTS } from "../constants/view-points";

@Directive({
  selector: "[blHidePoint]",
})
export class HidePointDirective implements OnInit, OnDestroy {
  private destroyer$: Subject<void> = new Subject();

  @Input() blHidePoint: WINDOW_POINTS[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private layoutService: LayoutService,
    private viewPointsService: ViewPointsService,
    private chRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.viewPointsService
      .listenerOnHideByPoints(...this.blHidePoint)
      .pipe(takeUntil(this.destroyer$))
      .subscribe((isHide: boolean) => (isHide ? this.clear() : this.create()));
  }

  clear(): void {
    this.viewContainerRef.clear();
    this.chRef.detectChanges();
  }

  create(): void {
    this.viewContainerRef.createEmbeddedView(this.templateRef);
    this.chRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
