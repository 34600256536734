import { formatDate } from "@angular/common";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/all-alerts.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewResultsAlert, IViewResultsAlerts } from "../../interfaces";

export interface IViewResultsAllAlertsState {
  alerts: IViewResultsAlerts | null;
  count: number;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IViewResultsAllAlertsState = {
  alerts: null,
  count: 0,
  pending: false,
  error: null,
};

const getViewResultsAllAlertsHandler: OnReducer<
  IViewResultsAllAlertsState,
  ActionType<any>
> = (state: IViewResultsAllAlertsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsAllAlertsErrorHandler: OnReducer<
  IViewResultsAllAlertsState,
  ActionType<any>
> = (state: IViewResultsAllAlertsState, { payload: error }: Payload<any>) => ({
  ...state,
  pending: false,
  error,
});

const getViewResultsAllAlertsSuccessHandler: OnReducer<
  IViewResultsAllAlertsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsAllAlertsState,
  { payload: { items, count } }: Payload<any>,
) => ({
  ...state,
  count,
  pending: false,
  alerts: items,
});

const setViewResultsAllAlertsSpinnerState: OnReducer<
  IViewResultsAllAlertsState,
  ActionType<Payload<any>>
> = (state: IViewResultsAllAlertsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsAllAlertsStateHandler: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsAllAlertsState> =
  createReducer<IViewResultsAllAlertsState>(
    initialState,

    on(actions.getViewResultsAllAlertsAction, getViewResultsAllAlertsHandler),
    on(
      actions.getViewResultsAllAlertsErrorAction,
      getViewResultsAllAlertsErrorHandler,
    ),
    on(
      actions.getViewResultsAllAlertsSuccessAction,
      getViewResultsAllAlertsSuccessHandler,
    ),

    on(
      actions.setViewResultsAllAlertsSpinnerStateAction,
      setViewResultsAllAlertsSpinnerState,
    ),

    on(
      actions.resetViewResultsAllAlertsStateAction,
      resetViewResultsAllAlertsStateHandler,
    ),
  );

export function viewResultsAllAlertsReducer(
  state: IViewResultsAllAlertsState,
  action: Action,
): IViewResultsAllAlertsState {
  return reducer(state, action);
}

export const alerts: GetFromState<
  IViewResultsAlerts,
  IViewResultsAllAlertsState
> = (state: IViewResultsAllAlertsState): IViewResultsAlerts =>
  state && state.alerts;
export const alertsPending: GetFromState<
  boolean,
  IViewResultsAllAlertsState
> = (state: IViewResultsAllAlertsState): boolean => state && state.pending;
export const alertsError: GetFromState<
  IServerError,
  IViewResultsAllAlertsState
> = (state: IViewResultsAllAlertsState): IServerError => state && state.error;

export const alertsCount: GetFromState<number, IViewResultsAllAlertsState> = (
  state: IViewResultsAllAlertsState,
): number => state && state.count;

export const organizedAlerts: GetFromState<
  { [key: string]: IViewResultsAlerts },
  IViewResultsAllAlertsState
> = (
  state: IViewResultsAllAlertsState,
): { [key: string]: IViewResultsAlerts } => getOrganizedAlerts(state.alerts);

export function getOrganizedAlerts(
  notOrganizedAllStateAlerts: IViewResultsAlerts,
): { [key: string]: IViewResultsAlerts } {
  if (!notOrganizedAllStateAlerts) {
    return;
  }

  const oAlerts: any = {};
  const usedDates: any = {};

  notOrganizedAllStateAlerts.forEach((alert: IViewResultsAlert) => {
    const alertFormattedDate: string = formatDate(
      alert.alertDate,
      "MMM d, yyyy",
      "en",
    );

    if (!usedDates[alertFormattedDate]) {
      usedDates[alertFormattedDate] = alert.alertDate;
      oAlerts[alert.alertDate] = [alert];
    } else {
      oAlerts[usedDates[alertFormattedDate]].push(alert);
    }
  });

  return oAlerts;
}
