import { AbstractControl, ValidatorFn } from "@angular/forms";

import { VALIDATION_REG_EXP } from "../constants/validators/validation-reg-exp";

export default function email(msg?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const message: string = msg || "Email is not valid";

    return VALIDATION_REG_EXP.email.test(control.value) &&
      !control.value.includes("@dnb.")
      ? null
      : { email: message };
  };
}
