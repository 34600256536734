import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewResultsAlertsResponse } from "../../interfaces";

import { SORT_DIRECTION } from "@shared/constants/sort";
import { REQUEST_VIEW_ALERTS_SORT } from "../../constants";

enum ACTION_TYPES {
  GET = "[VIEW RESULTS ALERTS] Get all alerts",
  GET_ERROR = "[VIEW RESULTS ALERTS] Get all alerts error",
  GET_SUCCESS = "[VIEW RESULTS ALERTS] Get all alerts success",

  SORT = "[VIEW RESULTS ALERTS] Sort all",
  ORDER = "[VIEW RESULTS ALERTS] Order all",
  UPDATE_PAGE = "[VIEW RESULTS ALERTS] Update all page",

  SET_SPINNER_STATE = "[VIEW RESULTS ALERTS ALL] Set spinner state",

  UPDATE_QUERY = "[VIEW RESULTS ALERTS] Update Query all page",

  RESET = "[VIEW RESULTS ALERTS] Reset all state",
}

export const getViewResultsAllAlertsAction: CreateActionType<
  ACTION_TYPES.GET,
  any
> = createAction(ACTION_TYPES.GET, createPayload<any>());
export const getViewResultsAllAlertsErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getViewResultsAllAlertsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IViewResultsAlertsResponse
> = createAction(
  ACTION_TYPES.GET_SUCCESS,
  createPayload<IViewResultsAlertsResponse>(),
);

export const sortViewResultsAllAlertsAction: CreateActionType<
  ACTION_TYPES.SORT,
  REQUEST_VIEW_ALERTS_SORT
> = createAction(ACTION_TYPES.SORT, createPayload<REQUEST_VIEW_ALERTS_SORT>());
export const orderViewResultsAllAlertsAction: CreateActionType<
  ACTION_TYPES.ORDER,
  SORT_DIRECTION
> = createAction(ACTION_TYPES.ORDER, createPayload<SORT_DIRECTION>());
export const updateViewResultsAllAlertsPageAction: CreateActionType<
  ACTION_TYPES.UPDATE_PAGE,
  number
> = createAction(ACTION_TYPES.UPDATE_PAGE, createPayload<number>());

export const setViewResultsAllAlertsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_SPINNER_STATE,
  boolean
> = createAction(ACTION_TYPES.SET_SPINNER_STATE, createPayload<boolean>());

export const changeViewResultAllAlertsQueryAction: CreateActionType<
  ACTION_TYPES.UPDATE_QUERY,
  Params
> = createAction(ACTION_TYPES.UPDATE_QUERY, createPayload<Params>());

export const resetViewResultsAllAlertsStateAction: CreateActionType<
  ACTION_TYPES.RESET,
  any
> = createAction(ACTION_TYPES.RESET, createPayload<any>());
