import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/geo-browse.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IBrowseEntities, IBrowseEntity } from "../../interfaces/browse";
import { IGeoTargets } from "../../interfaces/geography";

import { GEO_TARGETS } from "../../constants/geography";

export interface IGeoBrowseState {
  states: IBrowseEntities;
  counties: IBrowseEntities;
  citiesTitles: IBrowseEntities;
  cities: IBrowseEntities;
  citiesForZipCodes: IBrowseEntities;
  zipCodes: IBrowseEntities;
  dmaCodes: IBrowseEntities;
  scfCodes: IBrowseEntities;

  statesLoading: boolean;
  countiesLoading: boolean;
  citiesTitlesLoading: boolean;
  citiesLoading: boolean;
  citiesForZipCodesLoading: boolean;
  zipCodesLoading: boolean;
  dmaCodesLoading: boolean;
  scfCodesLoading: boolean;

  allStatesSelected: boolean;
  allDmaSelected: boolean;

  openedCategoryType: number | null;
  openedStateId: number;
  openedCityTitleName: string;
  openedCityForZipCodesId: number;

  geoBrowseLoadingError: IServerError;
}

const initialState: IGeoBrowseState = {
  states: null,
  counties: null,
  citiesTitles: null,
  cities: null,
  citiesForZipCodes: null,
  zipCodes: null,
  dmaCodes: null,
  scfCodes: null,

  statesLoading: false,
  countiesLoading: false,
  citiesTitlesLoading: false,
  citiesLoading: false,
  citiesForZipCodesLoading: false,
  zipCodesLoading: false,
  dmaCodesLoading: false,
  scfCodesLoading: false,

  allStatesSelected: false,
  allDmaSelected: false,

  openedCategoryType: null,
  openedStateId: null,
  openedCityTitleName: null,
  openedCityForZipCodesId: null,

  geoBrowseLoadingError: null,
};

const loadStates: OnReducer<IGeoBrowseState, ActionType<Payload<any>>> = (
  state: IGeoBrowseState,
  { payload }: Payload<any>,
) =>
  payload.reset
    ? {
        ...state,
        statesLoading: true,
        geoBrowseLoadingError: null,
        states: null,
        counties: null,
        citiesTitles: null,
        cities: null,
        citiesForZipCodes: null,
        zipCodes: null,
        scfCodes: null,
        openedStateId: null,
        openedCityTitleName: null,
        openedCityForZipCodesId: null,
      }
    : {
        ...state,
        statesLoading: true,
        geoBrowseLoadingError: null,
      };

const loadStatesSuccess: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  statesLoading: false,
  states: [...payload],
  allStatesSelected: isEntitiesAllSelected(payload),
});

const resetStates: OnReducer<IGeoBrowseState, ActionType<any>> = (
  state: IGeoBrowseState,
) => ({
  ...state,
  statesLoading: false,
  states: null,
  counties: null,
  citiesTitles: null,
  cities: null,
  citiesForZipCodes: null,
  zipCodes: null,
  scfCodes: null,
  openedStateId: null,
  openedCityTitleName: null,
  openedCityForZipCodesId: null,
});

const loadCounties: OnReducer<IGeoBrowseState, ActionType<Payload<any>>> = (
  state: IGeoBrowseState,
  { payload }: Payload<any>,
) => ({
  ...state,
  countiesLoading: true,
  geoBrowseLoadingError: null,
  openedStateId: payload.stateId,
  counties: payload.reset ? null : [...state.counties],
});

const loadCountiesSuccess: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  countiesLoading: false,
  counties: [...payload],
});

const resetCounties: OnReducer<IGeoBrowseState, ActionType<any>> = (
  state: IGeoBrowseState,
) => ({
  ...state,
  countiesLoading: false,
  counties: null,
  openedStateId: null,
});

const loadCitiesTitles: OnReducer<IGeoBrowseState, ActionType<Payload<any>>> = (
  state: IGeoBrowseState,
  { payload }: Payload<any>,
) =>
  payload.reset
    ? {
        ...state,
        citiesTitlesLoading: true,
        geoBrowseLoadingError: null,
        openedStateId: payload.stateId,
        citiesTitles: null,
        cities: null,
        citiesForZipCodes: null,
        zipCodes: null,
        openedCityTitleName: null,
        openedCityForZipCodesId: null,
      }
    : {
        ...state,
        citiesTitlesLoading: true,
        geoBrowseLoadingError: null,
        openedStateId: payload.stateId,
      };

const loadCitiesTitlesSuccess: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  citiesTitlesLoading: false,
  citiesTitles: [...payload],
});

const resetCitiesTitles: OnReducer<IGeoBrowseState, ActionType<any>> = (
  state: IGeoBrowseState,
) => ({
  ...state,
  citiesTitlesLoading: false,
  citiesTitles: null,
  cities: null,
  citiesForZipCodes: null,
  zipCodes: null,
  openedStateId: null,
  openedCityTitleName: null,
  openedCityForZipCodesId: null,
});

const loadCities: OnReducer<IGeoBrowseState, ActionType<Payload<any>>> = (
  state: IGeoBrowseState,
  { payload }: Payload<any>,
) => ({
  ...state,
  citiesLoading: true,
  geoBrowseLoadingError: null,
  cities: payload.reset ? null : [...state.cities],
  openedCityTitleName: payload.query,
});

const loadCitiesSuccess: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  citiesLoading: false,
  cities: payload,
});

const resetCities: OnReducer<IGeoBrowseState, ActionType<any>> = (
  state: IGeoBrowseState,
) => ({
  ...state,
  citiesLoading: false,
  cities: null,
  openedCityTitleName: null,
});

const loadCitiesForZipCodes: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) =>
  payload.reset
    ? {
        ...state,
        citiesForZipCodesLoading: true,
        geoBrowseLoadingError: null,
        openedCityTitleName: payload.query,
        citiesForZipCodes: null,
        zipCodes: null,
      }
    : {
        ...state,
        citiesForZipCodesLoading: true,
        geoBrowseLoadingError: null,
        openedCityTitleName: payload.query,
      };

const loadCitiesForZipCodesSuccess: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  citiesForZipCodesLoading: false,
  citiesForZipCodes: [...payload],
});

const resetCitiesForZipCodes: OnReducer<IGeoBrowseState, ActionType<any>> = (
  state: IGeoBrowseState,
) => ({
  ...state,
  citiesForZipCodesLoading: false,
  citiesForZipCodes: null,
  zipCodes: null,
  openedCityTitleName: null,
});

const loadZipCodes: OnReducer<IGeoBrowseState, ActionType<Payload<any>>> = (
  state: IGeoBrowseState,
  { payload }: Payload<any>,
) => ({
  ...state,
  zipCodesLoading: true,
  geoBrowseLoadingError: null,
  zipCodes: payload.reset ? null : [...state.zipCodes],
  openedCityForZipCodesId: payload.cityId,
});

const loadZipCodesSuccess: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  zipCodesLoading: false,
  zipCodes: [...payload],
});

const resetZipCodes: OnReducer<IGeoBrowseState, ActionType<any>> = (
  state: IGeoBrowseState,
) => ({
  ...state,
  zipCodesLoading: false,
  zipCodes: null,
  openedCityForZipCodesId: null,
});

// dma codes
const loadDmaCodesHandler: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) =>
  payload.reset
    ? {
        ...state,
        dmaCodesLoading: true,
        geoBrowseLoadingError: null,
        dmaCodes: null,
      }
    : {
        ...state,
        dmaCodesLoading: true,
        geoBrowseLoadingError: null,
      };

const loadDmaCodesSuccessHandler: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  dmaCodesLoading: false,
  dmaCodes: [...payload],
  allDmaSelected: isEntitiesAllSelected(payload),
});

const resetDmaCodesHandler: OnReducer<IGeoBrowseState, ActionType<any>> = (
  state: IGeoBrowseState,
) => ({
  ...state,
  dmaCodesLoading: false,
  dmaCodes: null,
});

const loadSCFCodes: OnReducer<IGeoBrowseState, ActionType<Payload<any>>> = (
  state: IGeoBrowseState,
  { payload }: Payload<any>,
) => ({
  ...state,
  scfCodesLoading: true,
  geoBrowseLoadingError: null,
  scfCodes: payload.reset ? null : [...state.scfCodes],
  openedStateId: payload.stateId,
});

const loadSCFCodesSuccess: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  scfCodesLoading: false,
  scfCodes: [...payload],
});

const resetSCFCodes: OnReducer<IGeoBrowseState, ActionType<any>> = (
  state: IGeoBrowseState,
) => ({
  ...state,
  scfCodesLoading: false,
  scfCodes: null,
  openedStateId: null,
});

const geoBrowserLoadingError: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<IServerError>>
> = (state: IGeoBrowseState, { payload }: Payload<IServerError>) => ({
  ...state,
  statesLoading: false,
  countiesLoading: false,
  citiesTitlesLoading: false,
  citiesLoading: false,
  citiesForZipCodesLoading: false,
  zipCodesLoading: false,
  geoBrowseLoadingError: { ...payload },
});

const setOpenedCategoryType: OnReducer<
  IGeoBrowseState,
  ActionType<Payload<any>>
> = (state: IGeoBrowseState, { payload }: Payload<any>) => ({
  ...state,
  openedCategoryType: payload,
});

const resetGeoBrowseState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IGeoBrowseState> = createReducer<IGeoBrowseState>(
  initialState,

  on(actions.loadStatesAction, loadStates),
  on(actions.loadStatesSuccessAction, loadStatesSuccess),
  on(actions.resetStatesAction, resetStates),

  on(actions.loadCountiesAction, loadCounties),
  on(actions.loadCountiesSuccessAction, loadCountiesSuccess),
  on(actions.resetCountiesAction, resetCounties),

  on(actions.loadCitiesTitlesAction, loadCitiesTitles),
  on(actions.loadCitiesTitlesSuccessAction, loadCitiesTitlesSuccess),
  on(actions.resetCitiesTitlesAction, resetCitiesTitles),

  on(actions.loadCitiesAction, loadCities),
  on(actions.loadCitiesSuccessAction, loadCitiesSuccess),
  on(actions.resetCitiesAction, resetCities),

  on(actions.loadCitiesForZipCodesAction, loadCitiesForZipCodes),
  on(actions.loadCitiesForZipCodesSuccessAction, loadCitiesForZipCodesSuccess),
  on(actions.resetCitiesForZipCodesAction, resetCitiesForZipCodes),

  on(actions.loadZipCodesAction, loadZipCodes),
  on(actions.loadZipCodesSuccessAction, loadZipCodesSuccess),
  on(actions.resetZipCodesAction, resetZipCodes),

  on(actions.loadDmaCodesAction, loadDmaCodesHandler),
  on(actions.loadDmaCodesSuccessAction, loadDmaCodesSuccessHandler),
  on(actions.resetDmaCodesAction, resetDmaCodesHandler),

  on(actions.loadSCFCodesAction, loadSCFCodes),
  on(actions.loadSCFCodesSuccessAction, loadSCFCodesSuccess),
  on(actions.resetSCFCodesAction, resetSCFCodes),

  on(actions.geoBrowserLoadingErrorAction, geoBrowserLoadingError),

  on(actions.setOpenedCategoryTypeAction, setOpenedCategoryType),

  on(actions.resetGeoBrowseStateAction, resetGeoBrowseState),
);

export function geoBrowseReducer(
  state: IGeoBrowseState,
  action: Action,
): IGeoBrowseState {
  return reducer(state, action);
}

export const states: GetFromState<IBrowseEntities, IGeoBrowseState> = (
  state: IGeoBrowseState,
): IBrowseEntities => state.states;
export const counties: GetFromState<IBrowseEntities, IGeoBrowseState> = (
  state: IGeoBrowseState,
): IBrowseEntities => state.counties;
export const citiesTitles: GetFromState<IBrowseEntities, IGeoBrowseState> = (
  state: IGeoBrowseState,
): IBrowseEntities => state.citiesTitles;
export const cities: GetFromState<IBrowseEntities, IGeoBrowseState> = (
  state: IGeoBrowseState,
): IBrowseEntities => state.cities;
export const citiesForZipCodes: GetFromState<
  IBrowseEntities,
  IGeoBrowseState
> = (state: IGeoBrowseState): IBrowseEntities => state.citiesForZipCodes;
export const zipCodes: GetFromState<IBrowseEntities, IGeoBrowseState> = (
  state: IGeoBrowseState,
): IBrowseEntities => state.zipCodes;
export const dmaCodes: GetFromState<IBrowseEntities, IGeoBrowseState> = (
  state: IGeoBrowseState,
): IBrowseEntities => state.dmaCodes;
export const scfCodes: GetFromState<IBrowseEntities, IGeoBrowseState> = (
  state: IGeoBrowseState,
): IBrowseEntities => state.scfCodes;

export const statesLoading: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.statesLoading;
export const countiesLoading: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.countiesLoading;
export const citiesTitlesLoading: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.citiesTitlesLoading;
export const citiesLoading: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.citiesLoading;
export const citiesForZipCodesLoading: GetFromState<
  boolean,
  IGeoBrowseState
> = (state: IGeoBrowseState): boolean => state.citiesForZipCodesLoading;
export const zipCodesLoading: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.zipCodesLoading;
export const dmaCodesLoading: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.dmaCodesLoading;
export const scfCodesLoading: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.scfCodesLoading;

export const allStatesSelected: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.allStatesSelected;
export const allDmaCodesSelected: GetFromState<boolean, IGeoBrowseState> = (
  state: IGeoBrowseState,
): boolean => state.allDmaSelected;

export const openedStateId: GetFromState<number, IGeoBrowseState> = (
  state: IGeoBrowseState,
): number => state.openedStateId;
export const openedCityTitleName: GetFromState<string, IGeoBrowseState> = (
  state: IGeoBrowseState,
): string => state.openedCityTitleName;
export const openedCityForZipCodesId: GetFromState<number, IGeoBrowseState> = (
  state: IGeoBrowseState,
): number => state.openedCityForZipCodesId;

const isEntitiesAllSelected: GetFromState<boolean, IBrowseEntities> = (
  entities: IBrowseEntities,
): boolean => {
  const enabledEntities: IBrowseEntities = entities.filter(
    (entity: IBrowseEntity) => !entity.disabled,
  );
  return (
    enabledEntities &&
    enabledEntities.length &&
    enabledEntities.every(({ selected }: Partial<IBrowseEntity>) => selected)
  );
};

export const geographyTargets: GetFromState<IGeoTargets, boolean, boolean> = (
  _allStatesSelected: boolean,
  _allDmaCodesSelected: boolean,
): IGeoTargets => ({
  ...GEO_TARGETS,
  STATES: { ...GEO_TARGETS.STATES, selected: _allStatesSelected },
  DMA_CODES: { ...GEO_TARGETS.DMA_CODES, selected: _allDmaCodesSelected },
});

export const openedCategoryType: GetFromState<
  number | null,
  IGeoBrowseState
> = (state: IGeoBrowseState): number | null =>
  state && state.openedCategoryType;
