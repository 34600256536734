import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IListCreateData } from "@modules/list-create/interfaces/list-create";
import { ICreateListResult } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

import { LIST_RECORDS_TYPES } from "@shared/constants/data/list-types";

enum ACTION_TYPES {
  CREATE_LIST = "[LIST CREATE]: create new list",
  CREATE_LIST_ERROR = "[LIST CREATE]: create new list error",
  CREATE_LIST_SUCCESS = "[LIST CREATE]: create new list success",

  SHOW_LIST_INFO = "[LIST CREATE] show info",

  RESET = "[LIST CREATE] Reset state",
}

export const createListAction: CreateActionType<
  ACTION_TYPES.CREATE_LIST,
  IListCreateData
> = createAction(ACTION_TYPES.CREATE_LIST, createPayload<IListCreateData>());
export const createListErrorAction: CreateActionType<
  ACTION_TYPES.CREATE_LIST_ERROR,
  IServerError
> = createAction(ACTION_TYPES.CREATE_LIST_ERROR, createPayload<IServerError>());
export const createListSuccessAction: CreateActionType<
  ACTION_TYPES.CREATE_LIST_SUCCESS,
  ICreateListResult
> = createAction(
  ACTION_TYPES.CREATE_LIST_SUCCESS,
  createPayload<ICreateListResult>(),
);

export const showListInfoAction: CreateActionType<
  ACTION_TYPES.SHOW_LIST_INFO,
  LIST_RECORDS_TYPES
> = createAction(
  ACTION_TYPES.SHOW_LIST_INFO,
  createPayload<LIST_RECORDS_TYPES>(),
);

export const resetStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
