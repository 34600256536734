import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { RouterModule } from "@angular/router";

import { CustomControlsModule } from "@ui/custom-controls/custom-controls.module";
import { DropDownModule } from "@ui/drop-down/drop-down.module";
import { SharedPipesModule } from "../pipes/shared-pipes/shared-pipes.module";

import { CloudSyncFooterComponent } from "./components/cloud-sync-footer/cloud-sync-footer.component";
import { ListCloudSyncMetaComponent } from "./components/list-cloud-sync-meta/list-cloud-sync-meta.component";
import { SyncPlatformsListComponent } from "./components/sync-platforms-list/sync-platforms-list.component";
import { SyncToComponent } from "./components/sync-to/sync-to.component";

const components: any[] = [
  SyncToComponent,
  SyncPlatformsListComponent,
  CloudSyncFooterComponent,
  ListCloudSyncMetaComponent,
];

@NgModule({
  imports: [
    CommonModule,
    MatRippleModule,
    RouterModule,
    SharedPipesModule,
    CustomControlsModule,
    DropDownModule,
  ],
  declarations: [...components],
  exports: [...components],
})
export class CloudSyncComponentsModule {}
