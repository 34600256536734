import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "isNull",
})
export class IsNullPipe implements PipeTransform {
  transform(value: any): string | number {
    if (value === null) {
      return "n/a";
    }

    return value;
  }
}
