import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  grantProfileAlertHistoryReducer,
  IGrantProfileAlertHistoryState,
} from "./alerts-history.reducer";
import { grantProfileReducer, IGrantProfileState } from "./profile.reducer";
import {
  grantProfileRecipientsReducer,
  IGrantProfileRecipientsState,
} from "./recipients.reducer";

export interface IGrantProfileModuleState {
  profile: IGrantProfileState;
  alertsHistory: IGrantProfileAlertHistoryState;
  recipients: IGrantProfileRecipientsState;
}

const grantProfileModuleState: ActionReducerMap<IGrantProfileModuleState> = {
  profile: grantProfileReducer,
  alertsHistory: grantProfileAlertHistoryReducer,
  recipients: grantProfileRecipientsReducer,
};

// eslint-disable-next-line
export const GRANT_PROFILE_STATE_NAME = 'grantProfile';
export const GRANT_PROFILE_STATE: InjectionToken<
  ActionReducerMap<IGrantProfileModuleState>
> = new InjectionToken<ActionReducerMap<IGrantProfileModuleState>>(
  GRANT_PROFILE_STATE_NAME,
  {
    factory: () => grantProfileModuleState,
  },
);
