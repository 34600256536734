import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/recipients.action";

import { IProductResultsResponse } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import {
  IGetRecipientsQueryParams,
  IRecipient,
  IRecipients,
} from "../../interfaces/recipients";

import { DEFAULT_GET_RECIPIENTS_PARAMS } from "../../constants/recipients";

export interface IGrantProfileRecipientsState {
  recipients: IRecipients;

  count: number;
  paginationCount: number;

  pending: boolean;
  error: IServerError | number;

  params: IGetRecipientsQueryParams;
  isOpen: boolean;

  checkedRecipients: number[];
  isAllRecipientsChecked: boolean;
}

const initialState: IGrantProfileRecipientsState = {
  recipients: [],

  pending: false,
  error: null,

  count: 0,
  paginationCount: 0,

  params: { ...DEFAULT_GET_RECIPIENTS_PARAMS },
  isOpen: false,

  checkedRecipients: [],
  isAllRecipientsChecked: false,
};

const getGrantProfileRecipients: OnReducer<
  IGrantProfileRecipientsState,
  ActionType<Payload<any>>
> = (state: IGrantProfileRecipientsState, { payload }: Payload<any>) => ({
  ...state,
  pending: true,
  error: null,
  params: {
    ...state.params,
    offset:
      typeof payload === "number" ? (payload - 1) * state.params.limit : 0,
  },
});

const getGrantProfileRecipientsError: OnReducer<
  IGrantProfileRecipientsState,
  ActionType<Payload<IServerError>>
> = (
  state: IGrantProfileRecipientsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getGrantProfileRecipientsSuccess: OnReducer<
  IGrantProfileRecipientsState,
  ActionType<Payload<any>>
> = (state: IGrantProfileRecipientsState, { payload }: Payload<any>) => ({
  ...state,
  recipients: [...payload.items],
  count: payload.count,
  paginationCount: payload.paginationCount,
  pending: false,
  checkedRecipients: getCheckedRecipients(state, payload),
});

const toggleGrantProfileRecipients: OnReducer<
  IGrantProfileRecipientsState,
  ActionType<Payload<any>>
> = (state: IGrantProfileRecipientsState, { payload }: Payload<any>) => ({
  ...state,
  isOpen: payload,
});

const sortGrantProfileRecipient: OnReducer<
  IGrantProfileRecipientsState,
  ActionType<Payload<any>>
> = (state: IGrantProfileRecipientsState, { payload }: Payload<any>) => ({
  ...state,
  pending: true,
  error: null,
  params: {
    ...state.params,
    ...payload,
  },
});

const sortGrantProfileRecipientSuccess: OnReducer<
  IGrantProfileRecipientsState,
  ActionType<Payload<any>>
> = (state: IGrantProfileRecipientsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  count: payload.count,
  paginationCount: payload.paginationCount,
  params: {
    ...state.params,
  },
  recipients: [...payload.items],
  checkedRecipients: getCheckedRecipients(state, payload),
});

const toggleGrantProfileCheckedRecipient: OnReducer<
  IGrantProfileRecipientsState,
  ActionType<Payload<any>>
> = (state: IGrantProfileRecipientsState, { payload }: Payload<any>) => {
  const newCheckedRecipients: number[] = state.checkedRecipients.includes(
    payload,
  )
    ? state.checkedRecipients.filter((pid: number) => pid !== payload)
    : [...state.checkedRecipients, payload];

  return {
    ...state,
    isAllRecipientsChecked: newCheckedRecipients.length === state.count,
    checkedRecipients: newCheckedRecipients,
  };
};

const tooggleGrantProfileAllCheckedRecipients: OnReducer<
  IGrantProfileRecipientsState,
  ActionType<Payload<any>>
> = (state: IGrantProfileRecipientsState, { payload }: Payload<any>) => ({
  ...state,
  isAllRecipientsChecked: payload,
  checkedRecipients: payload
    ? Array.from(
        new Set(
          state.recipients
            .filter((recipient: IRecipient) => recipient.pid)
            .map((recipientItem: IRecipient) => recipientItem.pid),
        ),
      )
    : [],
});

const resetGrantProfileRecipientsState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

function getCheckedRecipients(
  state: IGrantProfileRecipientsState,
  payload: IProductResultsResponse<IRecipients>,
): number[] {
  return state.isAllRecipientsChecked
    ? Array.from(
        new Set([
          ...payload.items
            .filter((recipient: IRecipient) => recipient.pid)
            .map((recipientItem: IRecipient) => recipientItem.pid),
          ...state.checkedRecipients,
        ]),
      )
    : state.checkedRecipients;
}

const reducer: ActionReducer<IGrantProfileRecipientsState> =
  createReducer<IGrantProfileRecipientsState>(
    initialState,

    on(actions.getGrantProfileRecipientsAction, getGrantProfileRecipients),
    on(
      actions.getGrantProfileRecipientsErrorAction,
      getGrantProfileRecipientsError,
    ),
    on(
      actions.getGrantProfileRecipientsSuccessAction,
      getGrantProfileRecipientsSuccess,
    ),

    on(actions.toggleGrantProfileRecipientAction, toggleGrantProfileRecipients),

    on(actions.sortGrantProfileRecipientAction, sortGrantProfileRecipient),
    on(
      actions.sortGrantProfileRecipientSuccessAction,
      sortGrantProfileRecipientSuccess,
    ),
    on(
      actions.toggleGrantProfileCheckedRecipientAction,
      toggleGrantProfileCheckedRecipient,
    ),

    on(
      actions.toggleGrantProfileAllCheckedRecipientsAction,
      tooggleGrantProfileAllCheckedRecipients,
    ),

    on(
      actions.resetGrantProfileRecipientsStateAction,
      resetGrantProfileRecipientsState,
    ),
  );

export function grantProfileRecipientsReducer(
  state: IGrantProfileRecipientsState,
  action: Action,
): IGrantProfileRecipientsState {
  return reducer(state, action);
}

export const grantProfileRecipients: GetFromState<
  IRecipients,
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): IRecipients => state.recipients;
export const selectedGrantProfileRecipients: GetFromState<
  number[],
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): number[] => state.checkedRecipients;
export const isAllRecipientsSelected: GetFromState<
  boolean,
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): boolean =>
  state.isAllRecipientsChecked;
export const grantProfileRecipientsPending: GetFromState<
  boolean,
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): boolean => state.pending;
export const grantProfileRecipientsError: GetFromState<
  IServerError | number,
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): IServerError | number => state.error;
export const grantProfileRecipientsCount: GetFromState<
  number,
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): number => state.count;
export const isGrantProfileRecipientsOpen: GetFromState<
  boolean,
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): boolean => state.isOpen;
export const grantProfileRecipientsParams: GetFromState<
  IGetRecipientsQueryParams,
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): IGetRecipientsQueryParams =>
  state.params;
export const grantProfileRecipientsParamsWithId: GetFromState<
  any,
  IGetRecipientsQueryParams,
  number
> = (params: IGetRecipientsQueryParams, id: number): any => {
  return { ...params, id };
};
export const grantProfileRecipientsCurrentPage: GetFromState<
  number,
  IGrantProfileRecipientsState
> = (state: IGrantProfileRecipientsState): number => {
  const { offset, limit }: IGetRecipientsQueryParams = state.params;
  return Math.ceil(offset / limit) + 1;
};

export const grantProfileCreateListQueryParams: GetFromState<
  Params,
  number,
  string,
  number[],
  boolean
> = (
  id: number,
  name: string,
  checkedRecipientsPids: number[],
  isAllRecipientsChecked: boolean,
): Params => {
  return {
    name,
    ...(!isAllRecipientsChecked && { pids: checkedRecipientsPids.join("-") }),
    ...(isAllRecipientsChecked && { grantId: id }),
  };
};
