import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/bids-alerts.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewResultsAlerts } from "../../interfaces";

import { getOrganizedAlerts } from "./all-alerts.reducer";

export interface IViewResultsBidsAlertsState {
  alerts: IViewResultsAlerts | null;
  count: number;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IViewResultsBidsAlertsState = {
  alerts: null,
  count: 0,
  pending: false,
  error: null,
};

const getViewResultsBidsAlertsHandler: OnReducer<
  IViewResultsBidsAlertsState,
  ActionType<any>
> = (state: IViewResultsBidsAlertsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsBidsAlertsErrorHandler: OnReducer<
  IViewResultsBidsAlertsState,
  ActionType<any>
> = (state: IViewResultsBidsAlertsState, { payload: error }: Payload<any>) => ({
  ...state,
  pending: false,
  error,
});

const getViewResultsBidsAlertsSuccessHandler: OnReducer<
  IViewResultsBidsAlertsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsBidsAlertsState,
  { payload: { items, count } }: Payload<any>,
) => ({
  ...state,
  count,
  pending: false,
  alerts: items,
});

const setViewResultsBidsAlertsSpinnerState: OnReducer<
  IViewResultsBidsAlertsState,
  ActionType<Payload<any>>
> = (state: IViewResultsBidsAlertsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsBidsAlertsStateHandler: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsBidsAlertsState> =
  createReducer<IViewResultsBidsAlertsState>(
    initialState,

    on(actions.getViewResultsBidsAlertsAction, getViewResultsBidsAlertsHandler),
    on(
      actions.getViewResultsBidsAlertsErrorAction,
      getViewResultsBidsAlertsErrorHandler,
    ),
    on(
      actions.getViewResultsBidsAlertsSuccessAction,
      getViewResultsBidsAlertsSuccessHandler,
    ),

    on(
      actions.setViewResultsBidsAlertsSpinnerStateAction,
      setViewResultsBidsAlertsSpinnerState,
    ),

    on(
      actions.resetViewResultsBidsAlertsStateAction,
      resetViewResultsBidsAlertsStateHandler,
    ),
  );

export function viewResultsBidsAlertsReducer(
  state: IViewResultsBidsAlertsState,
  action: Action,
): IViewResultsBidsAlertsState {
  return reducer(state, action);
}

export const alerts: GetFromState<
  IViewResultsAlerts,
  IViewResultsBidsAlertsState
> = (state: IViewResultsBidsAlertsState): IViewResultsAlerts =>
  state && state.alerts;
export const alertsPending: GetFromState<
  boolean,
  IViewResultsBidsAlertsState
> = (state: IViewResultsBidsAlertsState): boolean => state && state.pending;
export const alertsError: GetFromState<
  IServerError,
  IViewResultsBidsAlertsState
> = (state: IViewResultsBidsAlertsState): IServerError => state && state.error;

export const alertsCount: GetFromState<number, IViewResultsBidsAlertsState> = (
  state: IViewResultsBidsAlertsState,
): number => state && state.count;

export const organizedAlerts: GetFromState<
  { [key: string]: IViewResultsAlerts },
  IViewResultsBidsAlertsState
> = (
  state: IViewResultsBidsAlertsState,
): { [key: string]: IViewResultsAlerts } => getOrganizedAlerts(state.alerts);
