import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-personnel-counters.action";

import { IServerError } from "@shared/interfaces/server-error";

export interface IViewResultsPersonnelCountersState {
  colleges: number | null;
  districts: number | null;
  schools: number | null;

  pending: boolean;
  error: IServerError | null;
}

const initialState: IViewResultsPersonnelCountersState = {
  colleges: null,
  districts: null,
  schools: null,

  pending: false,
  error: null,
};

const getViewResultsPersonnelCounters: OnReducer<
  IViewResultsPersonnelCountersState,
  ActionType<any>
> = (state: IViewResultsPersonnelCountersState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsPersonnelCountersError: OnReducer<
  IViewResultsPersonnelCountersState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsPersonnelCountersState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsPersonnelCountersSuccess: OnReducer<
  IViewResultsPersonnelCountersState,
  ActionType<Payload<any>>
> = (state: IViewResultsPersonnelCountersState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  ...payload,
});

const setViewResultsPersonnelCountersSpinnerState: OnReducer<
  IViewResultsPersonnelCountersState,
  ActionType<Payload<any>>
> = (state: IViewResultsPersonnelCountersState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsPersonnelCounters: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsPersonnelCountersState> =
  createReducer<IViewResultsPersonnelCountersState>(
    initialState,

    on(
      actions.getViewResultsPersonnelCountersAction,
      getViewResultsPersonnelCounters,
    ),
    on(
      actions.getViewResultsPersonnelCountersErrorAction,
      getViewResultsPersonnelCountersError,
    ),
    on(
      actions.getViewResultsPersonnelCountersSuccessAction,
      getViewResultsPersonnelCountersSuccess,
    ),

    on(
      actions.setViewResultsPersonnelCountersSpinnerStateAction,
      setViewResultsPersonnelCountersSpinnerState,
    ),

    on(
      actions.resetViewResultsPersonnelCountersAction,
      resetViewResultsPersonnelCounters,
    ),
  );

export function viewResultsPersonnelCountersReducer(
  state: IViewResultsPersonnelCountersState,
  action: Action,
): IViewResultsPersonnelCountersState {
  return reducer(state, action);
}

export const colleges: GetFromState<
  number,
  IViewResultsPersonnelCountersState
> = ({ colleges: _colleges }: IViewResultsPersonnelCountersState): number =>
  _colleges;
export const districts: GetFromState<
  number,
  IViewResultsPersonnelCountersState
> = ({ districts: _districts }: IViewResultsPersonnelCountersState): number =>
  _districts;
export const schools: GetFromState<
  number,
  IViewResultsPersonnelCountersState
> = ({ schools: _schools }: IViewResultsPersonnelCountersState): number =>
  _schools;

export const pending: GetFromState<
  boolean,
  IViewResultsPersonnelCountersState
> = ({ pending: _pending }: IViewResultsPersonnelCountersState): boolean =>
  _pending;
export const error: GetFromState<
  IServerError,
  IViewResultsPersonnelCountersState
> = ({ error: _error }: IViewResultsPersonnelCountersState): IServerError =>
  _error;

export const emptyCriteriaSelected: (...counters: number[]) => boolean = (
  ...counters: number[]
): boolean => !counters.filter((count: number) => count).length;
