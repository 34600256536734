import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-views.action";

import { SessionStorageService } from "@core/services/session-storage.service";

import { IPagination } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import { IViewData } from "@shared/interfaces/view";
import {
  DASHBOARD_VIEW_TYPE,
  IDashboardProductQueryParams,
  IFiltersResult,
} from "../../../dashboard/interfaces";

import {
  DASHBOARD_EMPTY_VIEW_DESCRIPTION,
  DASHBOARD_ENTITY_TYPES,
  DASHBOARD_NO_CHANGES_DESCRIPTION,
} from "../../../dashboard/constants/dashboard";
import {
  DEFAULT_PAGINATION,
  PAGINATION_TABLE,
} from "../../../dashboard/constants/default-pagination";
import { VIEWS_DEFAULT_QUERY_PARAMS } from "../../constants";

export interface IDashboardViewsState {
  views: IViewData[] | null;
  pagination: IPagination | null;

  loaded: boolean;
  loading: boolean;
  error: IServerError | null;

  renaming: boolean;
  renameViewId: number;
  renameViewError: IServerError | null;

  duplicating: boolean;
  duplicateViewId: number;
  duplicateViewError: IServerError | null;

  deleting: boolean;
  deleteViewId: number;
  deleteViewError: IServerError | null;

  navigating: boolean;
  navigatingViewId: number;
}

const initialState: IDashboardViewsState = {
  views: [],
  pagination: { ...DEFAULT_PAGINATION },

  loaded: false,
  loading: false,
  error: null,

  renaming: false,
  renameViewId: null,
  renameViewError: null,

  duplicating: false,
  duplicateViewId: null,
  duplicateViewError: null,

  deleting: false,
  deleteViewId: null,
  deleteViewError: null,

  navigating: false,
  navigatingViewId: null,
};

const getDashboardViews: OnReducer<IDashboardViewsState, ActionType<any>> = (
  state: IDashboardViewsState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getDashboardViewsError: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardViewsState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getDashboardViewsSuccess: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  views: [...payload.lists],
  pagination: {
    ...state.pagination,
    moreAvailable: payload.pagination.moreAvailable,
    total: payload.pagination.total,
  },
  loaded: true,
  loading: false,
});

const getDashboardViewsNextPage: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...state.pagination,
    offset:
      state.pagination.offset +
      (payload ? payload.limit : state.pagination.limit),
  },
  loading: true,
});

const getDashboardViewsNextPageSuccess: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  views: [...state.views, ...payload.lists],
  pagination: {
    ...state.pagination,
    moreAvailable: payload.pagination.moreAvailable,
    total: payload.pagination.total,
  },
  loading: false,
});

const changeViewPageWithoutLoadData: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...state.pagination,
    offset: (payload - 1) * PAGINATION_TABLE.perPage,
  },
});

const changeViewPage: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...state.pagination,
    offset: (payload - 1) * PAGINATION_TABLE.perPage,
  },
  loading: true,
});

const changeViewPageSuccess: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...state.pagination,
    moreAvailable: payload.pagination.moreAvailable,
    total: payload.pagination.total,
  },
  views: [...state.views, ...payload.lists],
  loading: false,
});

const reloadDashboardViews: OnReducer<IDashboardViewsState, ActionType<any>> = (
  state: IDashboardViewsState,
) => ({
  ...state,
  pagination: {
    ...state.pagination,
    limit: state.pagination.offset + state.pagination.limit,
    offset: DEFAULT_PAGINATION.offset,
  },
  loading: true,
});

const reloadDashboardViewsError: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...payload.pagination,
    offset:
      SessionStorageService.dashboardViewsViewType ===
      DASHBOARD_VIEW_TYPE.PRODUCT
        ? state.pagination.limit - DEFAULT_PAGINATION.limit || 0
        : state.pagination.offset,
    limit: DEFAULT_PAGINATION.limit,
  },
  loading: false,
  error: { ...payload },
});

const reloadDashboardViewsSuccess: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  pagination: {
    ...payload.pagination,
    offset:
      SessionStorageService.dashboardViewsViewType ===
      DASHBOARD_VIEW_TYPE.PRODUCT
        ? state.pagination.limit - DEFAULT_PAGINATION.limit || 0
        : state.pagination.offset,
    limit: DEFAULT_PAGINATION.limit,
    moreAvailable: payload.pagination.moreAvailable,
  },
  views: [...payload.lists],
  loading: false,
});

const viewNavigate: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  navigating: true,
  navigatingViewId: payload.productId,
});

const viewNavigateCancel: OnReducer<IDashboardViewsState, ActionType<any>> = (
  state: IDashboardViewsState,
) => ({
  ...state,
  navigating: false,
  navigatingViewId: null,
});

const renameDashboardView: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  renaming: true,
  renameViewId: payload.listId || payload.viewId,
  renameViewError: null,
});

const renameDashboardViewError: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardViewsState, { payload }: Payload<IServerError>) => ({
  ...state,
  renaming: false,
  renameViewError: { ...payload },
});

const renameDashboardViewSuccess: OnReducer<
  IDashboardViewsState,
  ActionType<any>
> = (state: IDashboardViewsState) => ({
  ...state,
  renaming: false,
  renameViewId: null,
  renameViewError: null,
});

const renameDashboardViewCancel: OnReducer<
  IDashboardViewsState,
  ActionType<any>
> = (state: IDashboardViewsState) => ({
  ...state,
  renaming: false,
  renameViewError: null,
});

const duplicateDashboardView: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  duplicating: true,
  duplicateViewId: payload.viewId,
  duplicateViewError: null,
});

const duplicateDashboardViewError: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardViewsState, { payload }: Payload<IServerError>) => ({
  ...state,
  duplicating: false,
  duplicateViewError: { ...payload },
});

const duplicateDashboardSuccessView: OnReducer<
  IDashboardViewsState,
  ActionType<any>
> = (state: IDashboardViewsState) => ({
  ...state,
  duplicating: false,
  duplicateViewId: null,
  duplicateViewError: null,
});

const deleteDashboardView: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsState, { payload }: Payload<any>) => ({
  ...state,
  deleting: true,
  deleteViewId: payload.viewId,
  deleteViewError: null,
});

const deleteDashboardViewError: OnReducer<
  IDashboardViewsState,
  ActionType<Payload<IServerError>>
> = (state: IDashboardViewsState, { payload }: Payload<IServerError>) => ({
  ...state,
  deleting: false,
  deleteViewError: { ...payload },
});

const deleteDashboardViewSuccess: OnReducer<
  IDashboardViewsState,
  ActionType<any>
> = (state: IDashboardViewsState) => ({
  ...state,
  deleting: false,
  deleteViewId: null,
  deleteViewError: null,
});

const resetDashboardViews: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDashboardViewsState> =
  createReducer<IDashboardViewsState>(
    initialState,

    on(actions.getDashboardViewsAction, getDashboardViews),
    on(actions.getDashboardViewsErrorAction, getDashboardViewsError),
    on(actions.getDashboardViewsSuccessAction, getDashboardViewsSuccess),

    on(actions.getDashboardViewsNextPageAction, getDashboardViewsNextPage),
    on(
      actions.getDashboardViewsNextPageSuccessAction,
      getDashboardViewsNextPageSuccess,
    ),

    on(
      actions.changeViewPageWithoutLoadDataAction,
      changeViewPageWithoutLoadData,
    ),
    on(actions.changeViewPageAction, changeViewPage),
    on(actions.changeViewPageSuccessAction, changeViewPageSuccess),

    on(actions.reloadDashboardViewsAction, reloadDashboardViews),
    on(actions.reloadDashboardViewsErrorAction, reloadDashboardViewsError),
    on(actions.reloadDashboardViewsSuccessAction, reloadDashboardViewsSuccess),

    on(actions.viewNavigateAction, viewNavigate),
    on(actions.viewNavigateCancelAction, viewNavigateCancel),

    on(actions.renameDashboardViewAction, renameDashboardView),
    on(actions.renameDashboardViewErrorAction, renameDashboardViewError),
    on(actions.renameDashboardViewSuccessAction, renameDashboardViewSuccess),
    on(actions.renameDashboardViewCancelAction, renameDashboardViewCancel),

    on(actions.duplicateDashboardViewAction, duplicateDashboardView),
    on(actions.duplicateDashboardViewErrorAction, duplicateDashboardViewError),
    on(
      actions.duplicateDashboardSuccessViewAction,
      duplicateDashboardSuccessView,
    ),

    on(actions.deleteDashboardViewAction, deleteDashboardView),
    on(actions.deleteDashboardViewErrorAction, deleteDashboardViewError),
    on(actions.deleteDashboardViewSuccessAction, deleteDashboardViewSuccess),

    on(actions.resetDashboardViewsAction, resetDashboardViews),
  );

export function dashboardViewsReducer(
  state: IDashboardViewsState,
  action: Action,
): IDashboardViewsState {
  return reducer(state, action);
}

export const views: GetFromState<Array<IViewData>, IDashboardViewsState> = (
  state: IDashboardViewsState,
): Array<IViewData> => state.views;
export const pagination: GetFromState<IPagination, IDashboardViewsState> = (
  state: IDashboardViewsState,
): IPagination => state.pagination;
export const currentPage: GetFromState<number, IPagination> = (
  _pagination: IPagination,
): number => {
  const { offset, limit }: Partial<IPagination> = _pagination;

  return Math.ceil(offset / limit) + 1;
};
export const viewsForTable: GetFromState<
  IViewData[],
  IDashboardViewsState,
  number
> = (state: IDashboardViewsState, _currentPage: number): IViewData[] => {
  return (
    state.views &&
    state.views.slice(
      state.pagination.offset,
      state.pagination.offset + state.pagination.limit,
    )
  );
};

export const loaded: GetFromState<boolean, IDashboardViewsState> = (
  state: IDashboardViewsState,
): boolean => state.loaded;
export const loading: GetFromState<boolean, IDashboardViewsState> = (
  state: IDashboardViewsState,
): boolean => state.loading;

export const renaming: GetFromState<boolean, IDashboardViewsState> = (
  state: IDashboardViewsState,
): boolean => state.renaming;
export const renameViewId: GetFromState<number, IDashboardViewsState> = (
  state: IDashboardViewsState,
): number => state.renameViewId;
export const renameViewError: GetFromState<
  IServerError,
  IDashboardViewsState
> = (state: IDashboardViewsState): IServerError => state.renameViewError;

export const duplicating: GetFromState<boolean, IDashboardViewsState> = (
  state: IDashboardViewsState,
): boolean => state.duplicating;
export const duplicateViewId: GetFromState<number, IDashboardViewsState> = (
  state: IDashboardViewsState,
): number => state.duplicateViewId;

export const deleting: GetFromState<boolean, IDashboardViewsState> = (
  state: IDashboardViewsState,
): boolean => state.deleting;
export const deleteViewId: GetFromState<number, IDashboardViewsState> = (
  state: IDashboardViewsState,
): number => state.deleteViewId;

export const navigating: GetFromState<boolean, IDashboardViewsState> = (
  state: IDashboardViewsState,
): boolean => state.navigating;
export const navigatingViewId: GetFromState<number, IDashboardViewsState> = (
  state: IDashboardViewsState,
): number => state.navigatingViewId;

export const viewUpdating: GetFromState<
  boolean,
  boolean,
  boolean,
  boolean,
  boolean
> = (
  _renaming: boolean,
  _duplicating: boolean,
  _deleting: boolean,
  _navigating: boolean,
): boolean => {
  return _renaming || _duplicating || _deleting || _navigating;
};

export const updatingViewId: GetFromState<
  number,
  number,
  number,
  number,
  number
> = (
  _renameViewId: number,
  _duplicateViewId: number,
  _deleteViewId: number,
  _navigatingViewId: number,
): number => {
  return (
    _renameViewId || _duplicateViewId || _deleteViewId || _navigatingViewId
  );
};

export const viewsQueryParams: GetFromState<
  IDashboardProductQueryParams,
  IFiltersResult,
  string,
  string,
  IPagination,
  string
> = (
  filters: IFiltersResult,
  sortDirection: string,
  sortBy: string,
  _pagination: IPagination,
  query: string,
): IDashboardProductQueryParams => {
  const { limit, offset }: Partial<IPagination> = _pagination;

  const queryParams: IDashboardProductQueryParams = {
    ...filters,
    sortDirection,
    sortBy,
    limit,
    offset,
  };

  if (query) {
    queryParams["query"] = query;
  }

  queryParams["entityType"] = [DASHBOARD_ENTITY_TYPES.VIEWS];

  return queryParams;
};

export const lastUpdatedCheckedFilters: GetFromState<
  IDashboardProductQueryParams,
  IFiltersResult,
  string
> = (
  checkedFilters: IFiltersResult,
  query: string,
): IDashboardProductQueryParams =>
  query && query.length
    ? { ...checkedFilters, query, ...VIEWS_DEFAULT_QUERY_PARAMS }
    : { ...checkedFilters, ...VIEWS_DEFAULT_QUERY_PARAMS };

export const viewEmptyDescription: GetFromState<
  string,
  boolean,
  boolean,
  boolean
> = (
  hasViews: boolean,
  isHasCheckedFilters: boolean,
  isHasSearch: boolean,
): string => {
  if (isHasCheckedFilters || isHasSearch) {
    return DASHBOARD_NO_CHANGES_DESCRIPTION;
  }

  return DASHBOARD_EMPTY_VIEW_DESCRIPTION;
};

export const isHasData: GetFromState<boolean, boolean, boolean, boolean> = (
  hasViews: boolean,
  isHasCheckedFilters: boolean,
  isHasSearch: boolean,
): boolean => {
  return hasViews || isHasCheckedFilters || isHasSearch;
};

export const isShowSortDropDown: GetFromState<
  boolean,
  DASHBOARD_VIEW_TYPE,
  boolean
> = (viewType: DASHBOARD_VIEW_TYPE, _isHasData: boolean): boolean => {
  return viewType === DASHBOARD_VIEW_TYPE.PRODUCT && _isHasData;
};
