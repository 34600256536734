import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { mapTo, take, tap } from "rxjs/operators";

import * as actions from "../store/actions/brokers-tou.actions";
import { CoreState } from "../store/reducers";
import { getBrokersTermsOfUseData } from "../store/selectors/brokers-tou.selector";

import { ITermsOfUsePageData } from "../interfaces/terms-of-servise";

@Injectable({
  providedIn: "root",
})
export class BrokersTermsOfUseDataGuard {
  constructor(private _store: Store<CoreState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this._store.pipe(
      select(getBrokersTermsOfUseData),
      take(1),
      tap((data: ITermsOfUsePageData<SafeHtml>) => {
        if (!data) {
          this._store.dispatch(actions.getBrokerTermsOfUseAction());
        }
      }),
      mapTo(true),
    );
  }
}
