import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortDate",
})
export class ShortDatePipe implements PipeTransform {
  transform(value: number | Date): string {
    const date: Date = typeof value === "number" ? new Date(value) : value;

    return `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
  }
}
