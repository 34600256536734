import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { SharedPipesModule } from "@shared/modules/pipes/shared-pipes/shared-pipes.module";
import { AutofocusModule } from "../autofocus/autofocus.module";
import { CustomControlsModule } from "../custom-controls/custom-controls.module";

import { AutocompleteKeywordsComponent } from "./components/autocomplete-keywords/autocomplete-keywords.component";
import { AutocompleteComponent } from "./components/autocomplete/autocomplete.component";

const imports: any[] = [
  CommonModule,
  AutofocusModule,
  FormsModule,
  CustomControlsModule,
  SharedPipesModule,
];

const declarations: any[] = [
  AutocompleteComponent,
  AutocompleteKeywordsComponent,
];

const exports: any[] = [...declarations];

@NgModule({
  imports,
  declarations,
  exports,
})
export class AutocompleteModule {}
