import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/profile.action";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IGrantProfile,
  IGrantProfileContact,
  IGrantProfileDates,
  IGrantProfileFocusArea,
  IGrantProfileGradeLevel,
  IGrantProfileMainInfo,
  IGrantProfileOverview,
  IGrantProfileRelatedLinksResources,
  IGrantProfileSubInfo,
  IGrantProfileTextInfo,
  IGranProfileStates,
} from "../../interfaces";

export interface IGrantProfileState {
  profile: IGrantProfile | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IGrantProfileState = {
  profile: null,
  pending: false,
  error: null,
};

const getGrantProfile: OnReducer<IGrantProfileState, ActionType<any>> = (
  state: IGrantProfileState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getGrantProfileError: OnReducer<
  IGrantProfileState,
  ActionType<Payload<IServerError>>
> = (state: IGrantProfileState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getGrantProfileSuccess: OnReducer<
  IGrantProfileState,
  ActionType<Payload<IGrantProfile>>
> = (state: IGrantProfileState, { payload }: Payload<IGrantProfile>) => ({
  ...state,
  pending: false,
  profile: { ...payload },
});

const resetGrantProfileState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IGrantProfileState> =
  createReducer<IGrantProfileState>(
    initialState,

    on(actions.getGrantProfileAction, getGrantProfile),
    on(actions.getGrantProfileErrorAction, getGrantProfileError),
    on(actions.getGrantProfileSuccessAction, getGrantProfileSuccess),

    on(actions.resetGrantProfileStateAction, resetGrantProfileState),
  );

export function grantProfileReducer(
  state: IGrantProfileState,
  action: Action,
): IGrantProfileState {
  return reducer(state, action);
}

export const grantProfile: GetFromState<IGrantProfile, IGrantProfileState> = (
  state: IGrantProfileState,
): IGrantProfile => state && state.profile;
export const grantProfilePending: GetFromState<boolean, IGrantProfileState> = (
  state: IGrantProfileState,
): boolean => state && state.pending;
export const grantProfileError: GetFromState<
  IServerError,
  IGrantProfileState
> = (state: IGrantProfileState): IServerError => state && state.error;

export const grantProfileOverview: GetFromState<
  IGrantProfileOverview,
  IGrantProfile
> = (state: IGrantProfile): IGrantProfileOverview => state && state.overview;

export const grantProfileStates: GetFromState<
  IGranProfileStates,
  IGrantProfile
> = (state: IGrantProfile): IGranProfileStates => state && state.states;
export const grantProfileGrades: GetFromState<
  IGrantProfileGradeLevel[],
  IGrantProfile
> = (state: IGrantProfile): IGrantProfileGradeLevel[] =>
  state && state.gradeLevel;
export const grantProfileFocusArea: GetFromState<
  IGrantProfileFocusArea[],
  IGrantProfile
> = (state: IGrantProfile): IGrantProfileFocusArea[] =>
  state && state.focusArea;
export const grantProfileContacts: GetFromState<
  IGrantProfileContact[],
  IGrantProfile
> = (state: IGrantProfile): IGrantProfileContact[] => state && state.contacts;

export const grantProfileMainInfo: GetFromState<
  IGrantProfileMainInfo | null,
  IGrantProfileOverview
> = (overview: IGrantProfileOverview): IGrantProfileMainInfo | null => {
  if (overview) {
    const {
      title,
      totalFunds,
      grantTypes,
      source,
      status,
      applicationDue,
    }: IGrantProfileOverview = overview;
    return {
      title,
      totalFunds,
      grantTypes,
      source,
      status,
      applicationDue,
    } as IGrantProfileMainInfo;
  }
  return null;
};

export const grantProfileName: GetFromState<string, IGrantProfileMainInfo> = (
  state: IGrantProfileMainInfo,
): string => state && state.title;

export const grantProfileSubInfo: GetFromState<
  IGrantProfileSubInfo | null,
  IGrantProfileOverview
> = (overview: IGrantProfileOverview): IGrantProfileSubInfo | null => {
  if (overview) {
    const {
      combinable,
      awardNotificationDate,
      fundsAwardedDate,
    }: IGrantProfileOverview = overview;
    return {
      combinable,
      awardNotificationDate,
      fundsAwardedDate,
    } as IGrantProfileSubInfo;
  }
  return null;
};

export const grantProfileDates: GetFromState<
  IGrantProfileDates | null,
  IGrantProfileOverview
> = (overview: IGrantProfileOverview): IGrantProfileDates | null => {
  if (overview) {
    const { startDate, endDate, fiscalYear }: IGrantProfileOverview = overview;
    return { startDate, endDate, fiscalYear } as IGrantProfileDates;
  }
  return null;
};

export const grantProfileTextInfo: GetFromState<
  IGrantProfileTextInfo | null,
  IGrantProfileOverview
> = (overview: IGrantProfileOverview): IGrantProfileTextInfo | null => {
  if (overview) {
    const {
      description,
      purpose,
      eligibilityRequirementsRestrictions,
      comments,
    }: IGrantProfileOverview = overview;
    return {
      description,
      purpose,
      eligibilityRequirementsRestrictions,
      comments,
    } as IGrantProfileTextInfo;
  }

  return null;
};

export const relatedLinksResources: GetFromState<
  IGrantProfileRelatedLinksResources[],
  IGrantProfileOverview
> = (overview: IGrantProfileOverview): IGrantProfileRelatedLinksResources[] =>
  overview &&
  (overview.relatedLinksResources as IGrantProfileRelatedLinksResources[]);

export const grantId: GetFromState<number, Params> = (params: Params): number =>
  params && +params.grantId;
