import { AbstractControl, ValidatorFn } from "@angular/forms";

export default function validateDependent(controlName: string): ValidatorFn {
  return (control: AbstractControl) => {
    const secondControl: AbstractControl = control.root.get(controlName);

    if (secondControl) {
      secondControl.updateValueAndValidity();
    }

    return null;
  };
}
