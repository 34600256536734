import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { FORM_VALIDATION_ERRORS } from "@shared/constants/validators/forms-validations-errors";

import { Validators } from "@shared/validators/validators";

@Injectable()
export class AccountInfoService {
  constructor(private fb: UntypedFormBuilder) {}

  createPasswordForm(email: string): UntypedFormGroup {
    const {
      confirmNewPassword,
      currentPassword,
      newPassword,
    }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;
    return this.fb.group({
      email: [email, [Validators.validateDependent("newPassword")]],
      oldPassword: [
        "",
        [
          Validators.required(currentPassword.required),
          Validators.validateDependent("newPassword"),
        ],
      ],
      newPassword: [
        "",
        [
          Validators.password(newPassword.invalid),
          Validators.required(newPassword.required),
          Validators.validateDependent("confirmPassword"),
          Validators.confirm(newPassword.mailEquality, ["email"]),
          Validators.confirm(newPassword.currentPasswordEquality, [
            "oldPassword",
          ]),
        ],
      ],
      confirmPassword: [
        "",
        [
          Validators.password(confirmNewPassword.invalid),
          Validators.required(confirmNewPassword.required),
          Validators.confirm(confirmNewPassword.invalid, ["newPassword"]),
        ],
      ],
    });
  }
}
