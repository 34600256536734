import { Data, Params } from "@angular/router";
import { RouterReducerState } from "@ngrx/router-store";
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { RouterStateUrl } from "../reducers/router.reducer";

export const getRouterReducerState: GetFromStateInSelectors<
  CoreState,
  RouterReducerState<RouterStateUrl>
> = createFeatureSelector<RouterReducerState<RouterStateUrl>>("router");

export const getRouterState: MemoizedSelector<CoreState, RouterStateUrl> =
  createSelector(
    getRouterReducerState,
    (state: RouterReducerState<RouterStateUrl>) => (state ? state.state : null),
  );

export const getRouterUrl: MemoizedSelector<CoreState, string> = createSelector(
  getRouterState,
  (state: RouterStateUrl) => (state ? state.url : ""),
);

export const getRouterData: MemoizedSelector<CoreState, Data> = createSelector(
  getRouterState,
  (state: RouterStateUrl) => (state ? state.data : {}),
);

export const getRouterParams: MemoizedSelector<CoreState, Params> =
  createSelector(getRouterState, (state: RouterStateUrl) =>
    state ? state.params : {},
  );

export const getRouterQueryParams: MemoizedSelector<CoreState, Params> =
  createSelector(getRouterState, (state: RouterStateUrl) =>
    state ? state.queryParams : {},
  );
