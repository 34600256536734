import { merge, Observable } from "rxjs";
import { filter, mapTo, tap } from "rxjs/operators";

import { IKey } from "../interfaces/keyboard";

import { KEYS } from "../constants/keyboard";

export function matchKey(event: KeyboardEvent, key: IKey): boolean {
  for (const fieldName in key) {
    if (key[fieldName] && key[fieldName].includes(event.code)) {
      return true;
    }
  }

  for (const fieldName in key) {
    if (key[fieldName] && key[fieldName].includes(event.key)) {
      return true;
    }
  }

  return false;
}

export function createKeyboardListeners(
  listenKeyDown$: Observable<KeyboardEvent>,
): Array<Observable<any>> {
  const onUp$: Observable<any> = listenKeyDown$.pipe(
    filter((event: KeyboardEvent) => matchKey(event, KEYS.ARROW_UP)),
    tap((event: KeyboardEvent) => event.preventDefault()),
    mapTo(-1),
  );

  const onDown$: Observable<any> = listenKeyDown$.pipe(
    filter((event: KeyboardEvent) => matchKey(event, KEYS.ARROW_DOWN)),
    tap((event: KeyboardEvent) => event.preventDefault()),
    mapTo(1),
  );

  const moveSelection$: Observable<any> = merge(onUp$, onDown$);

  const onEnter$: any = listenKeyDown$.pipe(
    filter((event: KeyboardEvent) => matchKey(event, KEYS.ENTER)),
    tap((event: KeyboardEvent) => event.preventDefault()),
  );

  return [moveSelection$, onEnter$];
}
