import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { commonCustomerViewReducer, ICustomerViewState } from "./view.reducer";

export interface IViewModuleState {
  customerView: ICustomerViewState;
}

const viewModuleReducer: ActionReducerMap<IViewModuleState> = {
  customerView: commonCustomerViewReducer,
};

// eslint-disable-next-line
export const VIEW_COMMON_STATE_NAME = 'commonViewModule';
export const VIEW_COMMON_STATE: InjectionToken<
  ActionReducerMap<IViewModuleState>
> = new InjectionToken<ActionReducerMap<IViewModuleState>>(
  VIEW_COMMON_STATE_NAME,
  {
    factory: () => viewModuleReducer,
  },
);
