export const DEFAULT_SCROLL_WIDTH: number = 15;
export const SCROLL_WIDTH: number = 17;

export enum MOBILE_KEYWORD_EVENTS {
  MOBILE_KEYWORD_TRIGGER = "[MOBILE KEYWORD] trigger",
}

export enum SCROLL_EVENTS {
  SCROLL = "scroll",
  AUTO = "auto",
}
