export const CUSTOM_OBJECT_NAME_WITH: string = "MDREducation__MDR_";
export const CATCH_API_ERROR_SEARCH_CUSTOM_OBJECT: string = "/custom-objects";
export const CATCH_API_ERROR_SOLUTION: string = "/solution";

export enum CUSTOM_OBJECT_FIELD_ERRORS {
  MISSING_FIELDS = "missedFields",
  EXTRA_FIELDS = "extraFields",
  WRONG_TYPE_FIELDS = "wrongTypeFields",
}

export const CUSTOM_OBJECT_FIELD_ERROR: { [key: string]: string } =
  Object.freeze({
    [CUSTOM_OBJECT_FIELD_ERRORS.MISSING_FIELDS]: "Missed fields",
    [CUSTOM_OBJECT_FIELD_ERRORS.EXTRA_FIELDS]: "Extra fields",
    [CUSTOM_OBJECT_FIELD_ERRORS.WRONG_TYPE_FIELDS]: "Wrong type fields",
  });

export const LIST_SYNC_MESSAGES: { [key: string]: string } = Object.freeze({
  IN_PROCESSING:
    "Nice! Your sync is processing. You will get an email when it is complete.",
});

export enum LIST_SOLUTION_ERROR_MSG {
  SYNC_IS_NOT_AVAILABLE_ERROR = "Sync is not available.",
  EXCEEDED_RECORDS_LIMIT_ERROR = "This list exceeds your product records limit.",
  EXCEEDED_SYNCS_LIMIT_ERROR = "You have exceeded the number of syncs allowed by your product.",
  DO_NOT_HAVE_ENOUGH_CREDITS_ERROR = "You do not have enough credits.",
}

export const LIST_SOLUTION_ERRORS: LIST_SOLUTION_ERROR_MSG[] = [
  LIST_SOLUTION_ERROR_MSG.SYNC_IS_NOT_AVAILABLE_ERROR,
  LIST_SOLUTION_ERROR_MSG.EXCEEDED_RECORDS_LIMIT_ERROR,
  LIST_SOLUTION_ERROR_MSG.EXCEEDED_SYNCS_LIMIT_ERROR,
  LIST_SOLUTION_ERROR_MSG.DO_NOT_HAVE_ENOUGH_CREDITS_ERROR,
];

export const CALL_MESSAGE: string = "Call (800) 333-8802 to upgrade.";

export enum LIST_STATUS_ERRORS_MSG {
  LIST_IN_PROCESSING = "You have already started such sync!",
}

export const LIST_STATUS_ERRORS: LIST_STATUS_ERRORS_MSG[] = [
  LIST_STATUS_ERRORS_MSG.LIST_IN_PROCESSING,
];

export const LIST_IN_SYNC: string =
  "We are in the process of syncing your data. This function will be disabled until your current data sync is complete.";

export enum LIST_SEARCH_ERRORS {
  NOT_FOUND = "Don’t see your object? Click here to refresh",
}

export enum SCRIBE_ERRORS {
  NOT_FOUND = "Scribe Api not found error.",
}

export const SYNC_BUTTON_TOOLTIP: string =
  "You must select a valid custom object from the available list to enable Sync.";

export enum CUSTOM_OBJECT_VALUES {
  PRODUCTION = "0",
  SANDBOX = "1",
}
