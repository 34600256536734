import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { environment } from "@env/environment";

import { PopUpText } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-more-then-any-appends",
  templateUrl: "./more-then-any-appends.component.html",
  styleUrls: ["more-then-any-appends.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreThenAnyAppendsComponent {
  phoneNumber: string = environment.contactPhoneNumber;

  constructor(
    private popUpRef: PopUpRef<MoreThenAnyAppendsComponent>,
    @Inject(POP_UP_DATA) public data: PopUpText,
  ) {}

  onClose(): void {
    this.popUpRef.close();
  }
}
