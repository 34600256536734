import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";

import { of, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import {
  getAccountUsersErrorAction,
  getAccountUsersSuccessAction,
} from "../store/actions/account-users.action";

import { AccountUsersService } from "../services/account-users.service";

import { IServerError } from "@shared/interfaces/server-error";
import { IAccountUser } from "../interfaces/marketview";

@Injectable({
  providedIn: "root",
})
export class AccountUsersExistGuard {
  constructor(
    private store: Store<CoreState>,
    private accountUsersService: AccountUsersService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.accountUsersService.getUsers().pipe(
      map((usersFromServer: IAccountUser[]): boolean => {
        this.store.dispatch(getAccountUsersSuccessAction(usersFromServer));
        return true;
      }),
      catchError((error: IServerError): Observable<boolean> => {
        this.store.dispatch(getAccountUsersErrorAction(error));
        return of(false);
      }),
    );
  }
}
