import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { IFirstLoginFormData } from "@modules/auth/interfaces/formsActionsData";
import { IServerError } from "@shared/interfaces/server-error";

import { CORE_PATHS } from "@core/constants/core-paths";
import { AUTH_PATHS } from "@modules/auth/constants/auth-paths";
import { FORM_VALIDATION_ERRORS } from "@shared/constants/validators/forms-validations-errors";

import { Validators } from "@shared/validators/validators";

@Component({
  selector: "bl-reset-password-form",
  templateUrl: "./reset-password-form.component.html",
  styleUrls: ["./reset-password-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordFormComponent implements OnInit, OnChanges {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly authPaths: typeof AUTH_PATHS = AUTH_PATHS;

  @Input() loading: boolean = false;
  @Input() serverError: IServerError;
  @Input() successMessage: string;
  @Input() isShowForgotPasswordLink: boolean = false;

  @Output() submitResetPasswordForm: EventEmitter<IFirstLoginFormData> =
    new EventEmitter();

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private chdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.form = this.createForm();
    this.form.addControl(
      "oldPassword",
      this.form.controls["temporaryPassword"],
    );
    this.chdRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const errors: SimpleChange = changes.serverError;
    if (errors && errors.currentValue) {
      Object.entries(this.serverError.errors).forEach(
        ([fieldName, message]: [string, string[]]) => {
          this.form.get(fieldName).setErrors(message);
        },
      );
    }

    this.chdRef.markForCheck();
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }
    this.submitResetPasswordForm.emit(this.form.value);
  }

  private createForm(): UntypedFormGroup {
    const formErrors: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    return this.fb.group({
      email: [
        "",
        [
          Validators.required(formErrors.email.required),
          Validators.email(formErrors.email.invalid),
          Validators.validateDependent("newPassword"),
        ],
      ],
      temporaryPassword: [
        "",
        [
          Validators.required(formErrors.temporaryPassword.required),
          Validators.validateDependent("newPassword"),
        ],
      ],
      newPassword: [
        "",
        [
          Validators.required(formErrors.newPassword.required),
          Validators.validateDependent("confirmPassword"),
          Validators.password(formErrors.newPassword.invalid),
          Validators.confirm(formErrors.password.mailEquality, ["email"]),
          Validators.confirm(
            formErrors.temporaryPassword.temporaryPasswordEquality,
            ["temporaryPassword"],
          ),
        ],
      ],
      confirmPassword: [
        "",
        [
          Validators.required(formErrors.confirmNewPassword.required),
          Validators.confirm(formErrors.confirmNewPassword.invalid, [
            "newPassword",
          ]),
        ],
      ],
    });
  }
}
