import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IDistrictSchool,
  IDistrictSchools,
} from "@shared/modules/institution-profiles/interfaces/district-school";

import { SORT_DIRECTION } from "@shared/constants/sort";
import * as actions from "../actions/district-schools.action";
import { DISTRICT_SCHOOLS_SORTING } from "../../constants/district-school";

export interface IDistrictSchoolsState {
  schools: IDistrictSchools;
  pending: boolean;
  loaded: boolean;
  schoolsIsOpen: boolean;
  error: IServerError | null;

  count: number;

  limit: number;
  offset: number;
  sortBy: DISTRICT_SCHOOLS_SORTING;
  orderBy: SORT_DIRECTION;

  checkedSchools: number[];
  isCheckedAllSchools: boolean;
}

const initialState: IDistrictSchoolsState = {
  schools: [],
  pending: false,
  loaded: false,
  schoolsIsOpen: false,
  error: null,

  count: 0,

  limit: 20,
  offset: 0,
  sortBy: DISTRICT_SCHOOLS_SORTING.NAME,
  orderBy: SORT_DIRECTION.ASC,

  checkedSchools: [],
  isCheckedAllSchools: false,
};

const getDistrictSchools: OnReducer<
  IDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: IDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  pending: true,
  error: null,
  offset: typeof payload === "number" ? (payload - 1) * state.limit : 0,
});

const getDistrictSchoolsError: OnReducer<
  IDistrictSchoolsState,
  ActionType<Payload<IServerError>>
> = (state: IDistrictSchoolsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getDistrictSchoolsSuccess: OnReducer<
  IDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: IDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  loaded: true,
  schools: [...payload.items],
  count: payload.count,
  checkedSchools: state.isCheckedAllSchools
    ? Array.from(
        new Set([
          ...payload.items.map(
            (schoolItem: IDistrictSchool) => schoolItem.entity.entityId,
          ),
          ...state.checkedSchools,
        ]),
      )
    : state.checkedSchools,
  schoolsIsOpen: !![...state.schools, ...payload.items].length,
});

const resetDistrictCheckedSchools: OnReducer<
  IDistrictSchoolsState,
  ActionType<any>
> = (state: IDistrictSchoolsState) => ({
  ...state,
  checkedSchools: [],
  isCheckedAllSchools: false,
});

const sortDistrictSchools: OnReducer<
  IDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: IDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  ...payload,
  pending: true,
  error: null,
});

const sortDistrictSchoolsError: OnReducer<
  IDistrictSchoolsState,
  ActionType<Payload<IServerError>>
> = (state: IDistrictSchoolsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const sortDistrictSchoolsSuccess: OnReducer<
  IDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: IDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  schools: [...payload.items],
  checkedSchools: state.isCheckedAllSchools
    ? Array.from(
        new Set([
          ...payload.items.map(
            (schoolItem: IDistrictSchool) => schoolItem.entity.entityId,
          ),
          ...state.checkedSchools,
        ]),
      )
    : state.checkedSchools,
  count: payload.count,
});

const toggleDistrictSchoolsSuccess: OnReducer<
  IDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: IDistrictSchoolsState) => ({
  ...state,
  schoolsIsOpen: !state.schoolsIsOpen,
});

const setDistrictCheckedSchool: OnReducer<any, ActionType<Payload<any>>> = (
  state: any,
  { payload }: Payload<any>,
) => {
  const newCheckedSchool: number[] = state.checkedSchools.includes(payload)
    ? state.checkedSchools.filter((entityId: number) => entityId !== payload)
    : [...state.checkedSchools, payload];

  return {
    ...state,
    isCheckedAllSchools: state.query
      ? false
      : newCheckedSchool.length === state.count,
    checkedSchools: newCheckedSchool,
  };
};

const checkAllDistrictSchools: OnReducer<
  IDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: IDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  isCheckedAllSchools: payload,
  checkedSchools: payload
    ? state.schools.map(
        (personnelItem: IDistrictSchool) => personnelItem.entity.entityId,
      )
    : [],
});

const resetDistrictSchools: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDistrictSchoolsState> =
  createReducer<IDistrictSchoolsState>(
    initialState,

    on(actions.getDistrictSchoolsAction, getDistrictSchools),
    on(actions.getDistrictSchoolsErrorAction, getDistrictSchoolsError),
    on(actions.getDistrictSchoolsSuccessAction, getDistrictSchoolsSuccess),

    on(actions.resetDistrictCheckedSchoolsAction, resetDistrictCheckedSchools),

    on(actions.sortDistrictSchoolsAction, sortDistrictSchools),
    on(actions.sortDistrictSchoolsErrorAction, sortDistrictSchoolsError),
    on(actions.sortDistrictSchoolsSuccessAction, sortDistrictSchoolsSuccess),

    on(
      actions.toggleDistrictSchoolsSuccessAction,
      toggleDistrictSchoolsSuccess,
    ),

    on(actions.setDistrictCheckedSchoolAction, setDistrictCheckedSchool),
    on(actions.checkAllDistrictSchoolsAction, checkAllDistrictSchools),

    on(actions.resetDistrictSchoolsAction, resetDistrictSchools),
  );

export function districtSchoolsReducer(
  state: IDistrictSchoolsState,
  action: Action,
): IDistrictSchoolsState {
  return reducer(state, action);
}

export const schools: GetFromState<IDistrictSchools, IDistrictSchoolsState> = (
  state: IDistrictSchoolsState,
): IDistrictSchools => state.schools;
export const pending: GetFromState<boolean, IDistrictSchoolsState> = (
  state: IDistrictSchoolsState,
): boolean => state.pending;
export const loaded: GetFromState<boolean, IDistrictSchoolsState> = (
  state: IDistrictSchoolsState,
): boolean => state.loaded;
export const schoolsIsOpen: GetFromState<boolean, IDistrictSchoolsState> = (
  state: IDistrictSchoolsState,
): boolean => state.schoolsIsOpen;
export const count: GetFromState<number, IDistrictSchoolsState> = (
  state: IDistrictSchoolsState,
): number => state.count;
export const getOrderBy: GetFromState<SORT_DIRECTION, IDistrictSchoolsState> = (
  state: IDistrictSchoolsState,
): SORT_DIRECTION => state.orderBy;
export const getSortBy: GetFromState<
  DISTRICT_SCHOOLS_SORTING,
  IDistrictSchoolsState
> = (state: IDistrictSchoolsState): DISTRICT_SCHOOLS_SORTING => state.sortBy;

export const districtSchoolsError: GetFromState<
  IServerError,
  IDistrictSchoolsState
> = (state: IDistrictSchoolsState): IServerError => state.error;

export const districtSchoolsQueryParams: GetFromState<
  Partial<IDistrictSchoolsState>,
  IDistrictSchoolsState
> = (state: IDistrictSchoolsState): Partial<IDistrictSchoolsState> => {
  const { limit, offset, sortBy, orderBy }: Partial<IDistrictSchoolsState> =
    state;
  return { limit, offset, sortBy, orderBy };
};

export const checkedDistrictSchool: GetFromState<
  number[],
  IDistrictSchoolsState
> = (state: IDistrictSchoolsState): number[] => state.checkedSchools;
export const isAllDistrictSchoolsChecked: GetFromState<
  boolean,
  IDistrictSchoolsState
> = (state: IDistrictSchoolsState): boolean => state.isCheckedAllSchools;

export const selectedSchoolPids: GetFromState<
  number[],
  IDistrictSchools,
  number[]
> = (allSchools: IDistrictSchools, checkedSchoolIds: number[]): number[] =>
  allSchools
    .filter(
      (school: IDistrictSchool) =>
        !!checkedSchoolIds.find((id: number) => school.entity.entityId === id),
    )
    .map((school: IDistrictSchool) => school.pid);

export const districtSchoolsParamsWithDistrictId: GetFromState<
  Params,
  number,
  Params
> = (id: number, params: Params): Params => ({
  id,
  ...params,
});

export const currentPageByParams: GetFromState<
  number,
  IDistrictSchoolsState
> = (state: IDistrictSchoolsState): number => {
  const { offset, limit }: Partial<IDistrictSchoolsState> = state;

  return Math.ceil(offset / limit) + 1;
};
