import { ValidationErrors } from "@angular/forms";

export const FORM_VALIDATION_ERRORS: ValidationErrors = {
  email: {
    required: "Email is required",
    invalid: "Please enter a valid email",
  },
  password: {
    required: "Password is required",
    invalid: "Password requirements not met",
    mailEquality: "Password should not contain all or parts of Email",
    dependencyRequired: "Please provide Email before creating password",
  },
  temporaryPassword: {
    required: "Temporary Password is required",
    invalid: "Password requirements not met",
    temporaryPasswordEquality:
      "You cannot create a new password using your temporary password",
  },
  confirmNewPassword: {
    required: "Confirm New Password is required",
    invalid: "Your password and confirmation password do not match",
  },
  newPassword: {
    required: "New Password is required",
    invalid: "Password requirements not met",
    mailEquality: "Password should not contain all or parts of Email",
    dependencyRequired: "Please provide Email before creating password",
    currentPasswordEquality:
      "You cannot create a new password using your old password",
  },
  currentPassword: {
    required: "Current Password is required",
  },
  fullName: {
    required: "Full Name is required",
  },
  firstName: {
    required: "First Name is required",
    invalid: "The First Name may not be greater than 75 characters.",
  },
  lastName: {
    required: "Last Name is required",
    invalid: "The Last Name may not be greater than 75 characters.",
  },
  company: {
    required: "Company is required",
  },
  prefix: {
    required: "Prefix is required",
  },
  address1: {
    required: "Address is required",
  },
  city: {
    required: "City is required",
    invalid: "The city may not be greater than 64 characters",
  },
  stateProvince: {
    required: "State/Region is required",
  },
  zipPostalCode: {
    required: "ZIP/Postal Code is required",
    invalid: "The postal code should be 5 digits",
    minLength: "Postal Code should not be shorter than 5 characters",
    maxLength: "Postal Code should not be longer than 10 characters",
  },
  country: {
    required: "Country is required",
    invalid: "Country must be at capital two characters",
  },
  telephoneNumber: {
    required: "Telephone Number is required",
    invalid: "Telephone number should be 10 digits",
  },
  listName: {
    invalid: "The name field must have a value.",
    required: "List name is required",
    minLength: "List name should not be shorter than 1 character",
    maxLength: "List name should not be longer than 75 characters",
  },
  viewName: {
    invalid: "The name field must have a value.",
    required: "View name is required",
    minLength: "View name should not be shorter than 1 character",
    maxLength: "View name should not be longer than 75 characters",
  },
  seat: {
    required: "Seat is required",
  },
  expiredDate: {
    invalid: "Error in format. Enter date as MM/YYYY.",
    invalidMonth: "Month value can not exceed 12",
    lessDate: "Expired card",
  },
  accountNumber: {
    required: "Account Number is required",
  },
};
