import { createFeatureSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { IOmsModuleState } from "../reducers";

export const OMS_MODULE_FEATURE_NAME: string = "omsModule";
export const selectOmsModuleState: GetFromStateInSelectors<
  CoreState,
  IOmsModuleState
> = createFeatureSelector<IOmsModuleState>(OMS_MODULE_FEATURE_NAME);
