import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IOmsAuthPayload,
  IOmsFields,
  IOmsOAuthByRedirectionSocketResponse,
  IOmsPlatform,
  IOmsPlatforms,
} from "../../interfaces";

enum ACTION_TYPES {
  GET_PLATFORMS = "[OMS] Get platforms",
  GET_PLATFORMS_ERROR = "[OMS] Get platforms error",
  GET_PLATFORMS_SUCCESS = "[OMS] Get platforms success",

  GET_FIELDS = "[OMS] Get platform fields",
  GET_FIELDS_ERROR = "[OMS] Get platform fields error",
  GET_FIELDS_SUCCESS = "[OMS] Get platform fields success",

  CHANGE_AUTH_TYPE_FIELDS = "[OMS] change auth type for platform fields",

  PLATFORM_AUTH = "[OMS] platform auth",
  PLATFORM_AUTH_ERROR = "[OMS] platform auth error",
  PLATFORM_AUTH_SUCCESS = "[OMS] platform auth success",

  LISTEN_AUTH_BY_SOCKET = "[OMS] listen auth response by socket",
  LISTEN_AUTH_BY_SOCKET_ERROR = "[OMS] listen auth response by socket error",
  LISTEN_AUTH_BY_SOCKET_SUCCESS = "[OMS] listen auth response by socket success",

  LISTEN_OAUTH_BY_REDIRECTION_SOCKET = "[OMS] listen auth by redirection  socket",
  LISTEN_OAUTH_BY_REDIRECTION_SOCKET_FIRED = "[OMS] auth redirection by socket fired",

  OPEN_OMS_AUTH_POP_UP = "[OMS] Open cloud sync oms auth pop-up",
  OPEN_OMS_OAUTH_REDIRECTION_POP_UP = "[OMS] Open cloud sync oms auth redirect pop-up",
  CLOSE_OMS_AUTH_POP_UP = "[OMS] Close cloud sync oms auth pop-up data",

  RESET_STATE = "[OMS]: reset store",
}

export const getPlatformsAction: ActionCreator<
  ACTION_TYPES.GET_PLATFORMS,
  () => TypedAction<ACTION_TYPES.GET_PLATFORMS>
> = createAction(ACTION_TYPES.GET_PLATFORMS);
export const getPlatformsErrorAction: CreateActionType<
  ACTION_TYPES.GET_PLATFORMS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_PLATFORMS_ERROR,
  createPayload<IServerError>(),
);
export const getPlatformsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_PLATFORMS_SUCCESS,
  IOmsPlatforms
> = createAction(
  ACTION_TYPES.GET_PLATFORMS_SUCCESS,
  createPayload<IOmsPlatforms>(),
);

export const getFieldsAction: CreateActionType<
  ACTION_TYPES.GET_FIELDS,
  number
> = createAction(ACTION_TYPES.GET_FIELDS, createPayload<number>()); // id of platform
export const getFieldsErrorAction: CreateActionType<
  ACTION_TYPES.GET_FIELDS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_FIELDS_ERROR, createPayload<IServerError>());
export const getFieldsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_FIELDS_SUCCESS,
  IOmsFields
> = createAction(ACTION_TYPES.GET_FIELDS_SUCCESS, createPayload<IOmsFields>());

export const changeRelatedToFieldsAction: CreateActionType<
  ACTION_TYPES.CHANGE_AUTH_TYPE_FIELDS,
  string
> = createAction(ACTION_TYPES.CHANGE_AUTH_TYPE_FIELDS, createPayload<string>());

export const platformAuthAction: CreateActionType<
  ACTION_TYPES.PLATFORM_AUTH,
  IOmsAuthPayload
> = createAction(ACTION_TYPES.PLATFORM_AUTH, createPayload<IOmsAuthPayload>());
export const platformAuthErrorAction: CreateActionType<
  ACTION_TYPES.PLATFORM_AUTH_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.PLATFORM_AUTH_ERROR,
  createPayload<IServerError>(),
);
export const platformAuthSuccessAction: CreateActionType<
  ACTION_TYPES.PLATFORM_AUTH_SUCCESS,
  boolean
> = createAction(ACTION_TYPES.PLATFORM_AUTH_SUCCESS, createPayload<boolean>()); // isValid

export const listenAuthBySocketAction: CreateActionType<
  ACTION_TYPES.LISTEN_AUTH_BY_SOCKET,
  IOmsPlatform
> = createAction(
  ACTION_TYPES.LISTEN_AUTH_BY_SOCKET,
  createPayload<IOmsPlatform>(),
);
export const listenAuthBySocketErrorAction: CreateActionType<
  ACTION_TYPES.LISTEN_AUTH_BY_SOCKET_ERROR,
  string
> = createAction(
  ACTION_TYPES.LISTEN_AUTH_BY_SOCKET_ERROR,
  createPayload<string>(),
); // message
export const listenAuthBySocketSuccessAction: CreateActionType<
  ACTION_TYPES.LISTEN_AUTH_BY_SOCKET_SUCCESS,
  string
> = createAction(
  ACTION_TYPES.LISTEN_AUTH_BY_SOCKET_SUCCESS,
  createPayload<string>(),
); // message

export const listenOAuthByRedirectionSocketAction: ActionCreator<
  ACTION_TYPES.LISTEN_OAUTH_BY_REDIRECTION_SOCKET,
  () => TypedAction<ACTION_TYPES.LISTEN_OAUTH_BY_REDIRECTION_SOCKET>
> = createAction(ACTION_TYPES.LISTEN_OAUTH_BY_REDIRECTION_SOCKET);
export const listenOAuthByRedirectionSocketFiredAction: CreateActionType<
  ACTION_TYPES.LISTEN_OAUTH_BY_REDIRECTION_SOCKET_FIRED,
  IOmsOAuthByRedirectionSocketResponse
> = createAction(
  ACTION_TYPES.LISTEN_OAUTH_BY_REDIRECTION_SOCKET_FIRED,
  createPayload<IOmsOAuthByRedirectionSocketResponse>(),
);

export const openOmsAuthPopUpAction: ActionCreator<
  ACTION_TYPES.OPEN_OMS_AUTH_POP_UP,
  () => TypedAction<ACTION_TYPES.OPEN_OMS_AUTH_POP_UP>
> = createAction(ACTION_TYPES.OPEN_OMS_AUTH_POP_UP);
export const openOmsOAuthRedirectionPopUpAction: CreateActionType<
  ACTION_TYPES.OPEN_OMS_OAUTH_REDIRECTION_POP_UP,
  IOmsOAuthByRedirectionSocketResponse
> = createAction(
  ACTION_TYPES.OPEN_OMS_OAUTH_REDIRECTION_POP_UP,
  createPayload<IOmsOAuthByRedirectionSocketResponse>(),
);
export const closeOmsAuthPopUpAction: ActionCreator<
  ACTION_TYPES.CLOSE_OMS_AUTH_POP_UP,
  () => TypedAction<ACTION_TYPES.CLOSE_OMS_AUTH_POP_UP>
> = createAction(ACTION_TYPES.CLOSE_OMS_AUTH_POP_UP);

export const resetOmsStateAction: ActionCreator<
  ACTION_TYPES.RESET_STATE,
  () => TypedAction<ACTION_TYPES.RESET_STATE>
> = createAction(ACTION_TYPES.RESET_STATE);
