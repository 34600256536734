import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/list-cloud-sync.actions";

import { IServerError } from "@shared/interfaces/server-error";
import {
  ICustomObject,
  ISolutionError,
} from "../../../list-cloud-sync/interfaces";

import { PLATFORM_INSTANCE } from "@core/constants/cloud-sync";
import { CUSTOM_OBJECT_NAME_WITH } from "../../../list-cloud-sync/constants";

export interface IListCloudSyncState {
  customObject: ICustomObject;
  customObjectLoading: boolean;
  customObjectError: IServerError | null;
  generalError: string | null;
  search: string;

  createSolutionLoading: boolean;
  createSolutionError: ISolutionError | null;
}

const initialState: IListCloudSyncState = {
  customObject: null,
  customObjectLoading: false,
  customObjectError: null,
  generalError: null,
  search: "",

  createSolutionLoading: false,
  createSolutionError: null,
};

const searchCustomObjectsHandler: OnReducer<
  IListCloudSyncState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncState, { payload }: Payload<any>) => ({
  ...state,
  customObjectLoading: true,
  customObjectError: null,
  createSolutionError: null,
  generalError: null,
  search: payload,
});

const setSearchCustomObjectWithoutSearchRequestHandler: OnReducer<
  IListCloudSyncState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncState, { payload }: Payload<any>) => ({
  ...state,
  customObjectLoading: true,
  customObjectError: null,
  createSolutionError: null,
  generalError: null,
  search: payload,
});

const searchCustomObjectsErrorHandler: OnReducer<
  IListCloudSyncState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncState, { payload }: Payload<any>) => ({
  ...state,
  customObjectLoading: false,
  customObjectError: payload.error,
  search: payload.reset ? "" : state.search,
});

const searchCustomObjectGeneralErrorHandler: OnReducer<
  IListCloudSyncState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncState, { payload }: Payload<any>) => ({
  ...state,
  customObjectLoading: false,
  generalError: payload,
});

const searchCustomObjectsSuccessHandler: OnReducer<
  IListCloudSyncState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncState, { payload }: Payload<any>) => ({
  ...state,
  customObject: { ...payload },
  customObjectLoading: false,
  customObjectError: null,
  generalError: null,
});

const searchResetHandler: OnReducer<IListCloudSyncState, ActionType<any>> = (
  state: IListCloudSyncState,
) => ({
  ...state,
  search: "",
});

// solution
const createSolutionHandler: OnReducer<IListCloudSyncState, ActionType<any>> = (
  state: IListCloudSyncState,
) => ({
  ...state,
  createSolutionLoading: true,
  createSolutionError: null,
});

const createSolutionErrorHandler: OnReducer<
  IListCloudSyncState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncState, { payload }: Payload<any>) => ({
  ...state,
  createSolutionLoading: false,
  createSolutionError: { ...payload.errors } as ISolutionError,
});

const createSolutionSuccessHandler: OnReducer<
  IListCloudSyncState,
  ActionType<any>
> = (state: IListCloudSyncState) => ({
  ...state,
  createSolutionLoading: false,
  createSolutionError: null,
});

const refreshCustomObjectStartProcessErrorAction: OnReducer<
  IListCloudSyncState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncState, { payload }: Payload<any>) => ({
  ...state,
  error: { ...payload },
});

const onResetStateHandler: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IListCloudSyncState> =
  createReducer<IListCloudSyncState>(
    initialState,

    on(actions.searchCustomObjectAction, searchCustomObjectsHandler),
    on(actions.searchCustomObjectErrorAction, searchCustomObjectsErrorHandler),
    on(
      actions.searchCustomObjectGeneralErrorAction,
      searchCustomObjectGeneralErrorHandler,
    ),
    on(
      actions.searchCustomObjectSuccessAction,
      searchCustomObjectsSuccessHandler,
    ),
    on(actions.searchResetAction, searchResetHandler),
    on(
      actions.setSearchCustomObjectWithoutSearchRequestAction,
      setSearchCustomObjectWithoutSearchRequestHandler,
    ),

    on(actions.createSolutionAction, createSolutionHandler),
    on(actions.createSolutionErrorAction, createSolutionErrorHandler),
    on(actions.createSolutionSuccessAction, createSolutionSuccessHandler),

    on(
      actions.refreshCustomObjectStartProcessErrorAction,
      refreshCustomObjectStartProcessErrorAction,
    ),

    on(actions.resetListCloudSyncStateAction, onResetStateHandler),
  );

export const customObject: GetFromState<ICustomObject, IListCloudSyncState> = (
  state: IListCloudSyncState,
): ICustomObject => state && state.customObject;
export const searchCustomObjectLoading: GetFromState<
  boolean,
  IListCloudSyncState
> = (state: IListCloudSyncState): boolean => state && state.customObjectLoading;
export const searchCustomObjectError: GetFromState<
  IServerError | null,
  IListCloudSyncState
> = (state: IListCloudSyncState): IServerError | null =>
  state && state.customObjectError;
export const customObjectGeneralError: GetFromState<
  string | null,
  IListCloudSyncState
> = (state: IListCloudSyncState): string | null => state && state.generalError;
export const getSearch: GetFromState<string, IListCloudSyncState> = (
  state: IListCloudSyncState,
): string => state && state.search;

export const isValidCustomObjectName: GetFromState<boolean, ICustomObject> = (
  _customObject: ICustomObject,
): boolean => {
  return (
    _customObject &&
    _customObject.name &&
    _customObject.name.startsWith(CUSTOM_OBJECT_NAME_WITH)
  );
};

// if need add check validate of name
export const isCanSync: GetFromState<boolean, IServerError, string, string> = (
  error: IServerError,
  generalError: string,
  search: string,
): boolean => {
  return !error && !generalError && search && !!search.length;
};

// solution
export const solutionLoading: GetFromState<boolean, IListCloudSyncState> = (
  state: IListCloudSyncState,
): boolean => state && state.createSolutionLoading;
export const solutionError: GetFromState<
  ISolutionError | null,
  IListCloudSyncState
> = (state: IListCloudSyncState): ISolutionError | null =>
  state && state.createSolutionError;

export const isSandbox: GetFromState<boolean, Params> = (
  _queryParams: Params,
): boolean =>
  _queryParams && _queryParams["instance"] === PLATFORM_INSTANCE.SANDBOX;

export const syncIsLoading: GetFromState<boolean, boolean, boolean> = (
  searchLoading: boolean,
  syncLoading: boolean,
): boolean => searchLoading || syncLoading;

export function listCloudSyncReducer(
  state: IListCloudSyncState,
  action: Action,
): IListCloudSyncState {
  return reducer(state, action);
}
