import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/bid-categories.action";

import { IServerError } from "@shared/interfaces/server-error";
import { ENTITY_TYPE, IBrowseEntities } from "../../interfaces/browse";

export interface IBidCategoriesState {
  groups: IBrowseEntities;
  groupsLoading: boolean;
  groupsLoaded: boolean;
  groupsError: IServerError;
  openedGroupId: number | null;

  categories: IBrowseEntities;
  categoriesLoading: boolean;
  categoriesError: IServerError | null;
}

const initialState: IBidCategoriesState = {
  groups: null,
  groupsLoading: false,
  groupsLoaded: false,
  groupsError: null,
  openedGroupId: null,

  categories: null,
  categoriesLoading: false,
  categoriesError: null,
};

const getGroups: OnReducer<IBidCategoriesState, ActionType<Payload<any>>> = (
  state: IBidCategoriesState,
  { payload }: Payload<any>,
) => ({
  ...state,
  groupsLoading: true,
  groupsLoaded: payload.reset ? null : state.groupsLoaded,
  groupsError: null,
  groups: payload ? null : state.groups ? [...state.groups] : null,
  openedGroupId: null,
});

const getGroupsError: OnReducer<
  IBidCategoriesState,
  ActionType<Payload<IServerError>>
> = (state: IBidCategoriesState, { payload }: Payload<IServerError>) => ({
  ...state,
  groupsLoading: false,
  groupsError: { ...payload },
});

const getGroupsSuccess: OnReducer<
  IBidCategoriesState,
  ActionType<Payload<any>>
> = (state: IBidCategoriesState, { payload }: Payload<any>) => ({
  ...state,
  groupsLoading: false,
  groupsLoaded: true,
  groupsError: null,
  groups: payload ? [...payload] : null,
});

const resetGroups: OnReducer<IBidCategoriesState, ActionType<Payload<any>>> = (
  state: IBidCategoriesState,
  { payload }: Payload<any>,
) => ({
  ...state,
  groupsLoading: false,
  groupsError: null,
  groups: payload ? null : state.groups ? [...state.groups] : null,
  openedGroupId: null,
});

const getCategories: OnReducer<
  IBidCategoriesState,
  ActionType<Payload<any>>
> = (state: IBidCategoriesState, { payload }: Payload<any>) => ({
  ...state,
  categoriesLoading: true,
  categoriesError: null,
  categories: payload.reset ? null : [...state.categories],
  openedGroupId: payload.groupId ? payload.groupId : null,
});

const getCategoriesError: OnReducer<
  IBidCategoriesState,
  ActionType<Payload<IServerError>>
> = (state: IBidCategoriesState, { payload }: Payload<IServerError>) => ({
  ...state,
  categoriesLoading: false,
  entityLoading: false,
  categoriesError: { ...payload },
});

const getCategoriesSuccess: OnReducer<
  IBidCategoriesState,
  ActionType<Payload<any>>
> = (state: IBidCategoriesState, { payload }: Payload<any>) => ({
  ...state,
  categoriesLoading: false,
  entityLoading: false,
  categoriesError: null,
  categories: [...payload],
});

const resetCategories: OnReducer<
  IBidCategoriesState,
  ActionType<Payload<any>>
> = (state: IBidCategoriesState, { payload }: Payload<any>) => ({
  ...state,
  categoriesLoading: false,
  categoriesError: null,
  categories: payload ? null : [...state.categories],
});

const addEntityAndReload: OnReducer<
  IBidCategoriesState,
  ActionType<Payload<any>>
> = (state: IBidCategoriesState, { payload }: Payload<any>) => ({
  ...state,
  groupsLoading: payload.entityType === ENTITY_TYPE.GROUP,
  categoriesLoading: payload.entityType === ENTITY_TYPE.CATEGORY,
});

const resetBidCategoriesState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IBidCategoriesState> =
  createReducer<IBidCategoriesState>(
    initialState,

    on(actions.getGroupsAction, getGroups),
    on(actions.getGroupsErrorAction, getGroupsError),
    on(actions.getGroupsSuccessAction, getGroupsSuccess),

    on(actions.resetGroupsAction, resetGroups),
    on(actions.getCategoriesAction, getCategories),
    on(actions.getCategoriesErrorAction, getCategoriesError),
    on(actions.getCategoriesSuccessAction, getCategoriesSuccess),

    on(actions.resetCategoriesAction, resetCategories),
    on(actions.addEntityAndReloadAction, addEntityAndReload),

    on(actions.resetBidCategoriesStateAction, resetBidCategoriesState),
  );

export function bidCategoriesReducer(
  state: IBidCategoriesState,
  action: Action,
): IBidCategoriesState {
  return reducer(state, action);
}

export const groups: GetFromState<IBrowseEntities, IBidCategoriesState> = (
  state: IBidCategoriesState,
): IBrowseEntities => state.groups;
export const groupsLoading: GetFromState<boolean, IBidCategoriesState> = (
  state: IBidCategoriesState,
): boolean => state.groupsLoading;
export const groupsIsLoaded: GetFromState<boolean, IBidCategoriesState> = (
  state: IBidCategoriesState,
): boolean => state.groupsLoaded;
export const isHasGroups: GetFromState<boolean, IBidCategoriesState> = (
  state: IBidCategoriesState,
): boolean => state.groupsLoaded && !!state.groups && !!state.groups.length;
export const openedGroupId: GetFromState<number, IBidCategoriesState> = (
  state: IBidCategoriesState,
): number => state.openedGroupId;

export const categories: GetFromState<IBrowseEntities, IBidCategoriesState> = (
  state: IBidCategoriesState,
): IBrowseEntities => state.categories;
export const categoriesLoading: GetFromState<boolean, IBidCategoriesState> = (
  state: IBidCategoriesState,
): boolean => state.categoriesLoading;
