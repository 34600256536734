// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.form__container {
  display: block;
  width: 100%;
}
.form__container-footer {
  text-align: center;
}

.auth__profile {
  max-width: 100%;
}

.footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.footer__content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

bl-flat-input {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/profile/components/profile-form/profile-form.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;;AAIA;EACE,cAAA;EACA,WAAA;AADF;AAGE;EACE,kBAAA;AADJ;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,kBAAA;EACA,OAAA;EACA,QAAA;EACA,SAAA;AAFF;AAIE;EACE,aAAA;EACA,8BAAA;EACA,iBAAA;EACA,WAAA;EACA,cAAA;AAFJ;;AAMA;EACE,cAAA;AAHF","sourcesContent":["@import '../../../../../assets/styles/variables';\n\n:host {\n  display: block;\n}\n\n.form__container {\n  display: block;\n  width: 100%;\n\n  &-footer {\n    text-align: center;\n  }\n}\n\n.auth__profile {\n  max-width: 100%;\n}\n\n.footer {\n  position: absolute;\n  left: 0;\n  right: 0;\n  bottom: 0;\n\n  &__content {\n    display: flex;\n    justify-content: space-between;\n    max-width: 1200px;\n    width: 100%;\n    margin: 0 auto;\n  }\n}\n\nbl-flat-input {\n  display: block;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
