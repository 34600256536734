import {
  forwardRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "bl-input-radio-card",
  templateUrl: "./input-radio-card.component.html",
  styleUrls: ["./input-radio-card.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioCardComponent),
      multi: true,
    },
  ],
})
export class InputRadioCardComponent implements ControlValueAccessor {
  @Input() value: any;
  @Input() label: string;
  @Input() description: string;
  @Input() subDescription: string;
  @Input() theme: string = "default";
  @Input() isDisabled: boolean;
  @Input() subControlName: string;
  @Input() subControlValue: boolean;
  @Input() subControlDescription: string;

  @Input() name: string;
  @Input() formControlName: string;

  @Output() changeInputRadioCardValue: EventEmitter<any> = new EventEmitter();
  @Output() inputRadioCardSubControlChange: EventEmitter<{
    name: string;
    checked: boolean;
  }> = new EventEmitter();

  initialChecked: boolean;
  _name: string;

  propagateChange: any = () => {};

  writeValue(initValue: any): void {
    this._name = this.name ? this.name : this.formControlName;
    this.initialChecked = this.value === initValue;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {}

  onChange(event: any): void {
    event.preventDefault();

    this.initialChecked = event.target.checked;

    if (event.target.checked && !this.isDisabled) {
      this.propagateChange(this.value);
      this.changeInputRadioCardValue.emit(this.value);
    }
  }
}
