import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-views-search.action";

import { DASHBOARD_ENTITY_TYPES } from "../../../dashboard/constants/dashboard";

export interface IDashboardViewsSearchState {
  query: string;
  isHasQuery: boolean;

  entityType: DASHBOARD_ENTITY_TYPES.VIEWS;
  isOpen: boolean;
}

const initialState: IDashboardViewsSearchState = {
  query: null,
  isHasQuery: false,

  entityType: DASHBOARD_ENTITY_TYPES.VIEWS,
  isOpen: false,
};

const openDashboardViewsSearch: OnReducer<
  IDashboardViewsSearchState,
  ActionType<any>
> = (state: IDashboardViewsSearchState) => ({
  ...state,
  isOpen: true,
});

const closeDashboardViewsSearch: OnReducer<
  IDashboardViewsSearchState,
  ActionType<any>
> = (state: IDashboardViewsSearchState) => ({
  ...state,
  query: null,
  isOpen: false,
});

const updateDashboardViewsSearch: OnReducer<
  IDashboardViewsSearchState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsSearchState, { payload }: Payload<any>) => ({
  ...state,
  query: payload,
});

const updateDashboardViewSearchSuccess: OnReducer<
  IDashboardViewsSearchState,
  ActionType<any>
> = (state: IDashboardViewsSearchState) => ({
  ...state,
  isHasQuery: !!state.query,
});

const resetDashboardViewsSearch: OnReducer<
  IDashboardViewsSearchState,
  ActionType<any>
> = (state: IDashboardViewsSearchState) => ({
  ...state,
  query: null,
});

const resetDashboardViewsSearchWithoutReload: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDashboardViewsSearchState> =
  createReducer<IDashboardViewsSearchState>(
    initialState,

    on(actions.openDashboardViewsSearchAction, openDashboardViewsSearch),
    on(actions.closeDashboardViewsSearchAction, closeDashboardViewsSearch),

    on(actions.updateDashboardViewsSearchAction, updateDashboardViewsSearch),
    on(
      actions.updateDashboardViewSearchSuccessAction,
      updateDashboardViewSearchSuccess,
    ),

    on(actions.resetDashboardViewsSearchAction, resetDashboardViewsSearch),

    on(
      actions.resetDashboardViewsSearchWithoutReloadAction,
      resetDashboardViewsSearchWithoutReload,
    ),
  );

export const query: GetFromState<string, IDashboardViewsSearchState> = (
  state: IDashboardViewsSearchState,
): string => state.query;
export const isHasQuery: GetFromState<boolean, IDashboardViewsSearchState> = (
  state: IDashboardViewsSearchState,
): boolean => state.isHasQuery;

export const isOpen: GetFromState<boolean, IDashboardViewsSearchState> = (
  state: IDashboardViewsSearchState,
): boolean => state.isOpen;

export function dashboardViewsSearchReducer(
  state: IDashboardViewsSearchState,
  action: Action,
): IDashboardViewsSearchState {
  return reducer(state, action);
}
