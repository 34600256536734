import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatRippleModule } from "@angular/material/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";

import { DirectivesModule } from "@shared/modules/directives/directives.module";
import { FiltersPipesModule } from "@shared/modules/pipes/filters-pipes/filters-pipes.module";
import { ListPipesModule } from "@shared/modules/pipes/list-pipes/list-pipes.module";
import { SharedPipesModule } from "@shared/modules/pipes/shared-pipes/shared-pipes.module";
import { StringPipesModule } from "@shared/modules/pipes/string-pipes/string-pipes.module";
import { AutofocusModule } from "../autofocus/autofocus.module";
import { DropDownModule } from "../drop-down/drop-down.module";
import { TooltipModule } from "../tooltip/tooltip.module";
import { ViewPointsModule } from "../view-points/view-points.module";

import { CustomCheckboxSwitchComponent } from "./components/custom-checkbox-switch/custom-checkbox-switch.component";
import { CustomCheckboxComponent } from "./components/custom-checkbox/custom-checkbox.component";
import { CustomSelectComponent } from "./components/custom-select/custom-select.component";
import { CustomValueCheckboxComponent } from "./components/custom-value-checkbox/custom-value-checkbox.component";
import { DateControlComponent } from "./components/date-control/date-control.component";
import { FakeRadioComponent } from "./components/fake-radio/fake-radio.component";
import { FlatDropDownComponent } from "./components/flat-drop-down/flat-drop-down.component";
import { FlatInputComponent } from "./components/flat-input/flat-input.component";
import { FlatSelectComponent } from "./components/flat-select/flat-select.component";
import { InputRadioCardComponent } from "./components/input-radio-card/input-radio-card.component";
import { PasswordPolicyComponent } from "./components/password-policy/password-policy.component";
import { RadioComponent } from "./components/radio/radio.component";
import { RenameListComponent } from "./components/rename-list/rename-list.component";
import { RoundedSelectComponent } from "./components/rounded-select/rounded-select.component";
import { SelectComponent } from "./components/select/select.component";
import { SliderComponent } from "./components/slider/slider.component";

import { CalendarModule } from "@progress/kendo-angular-dateinputs";

const imports: any[] = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  SharedPipesModule,
  ViewPointsModule,
  DropDownModule,
  MatRippleModule,
  AutofocusModule,
  DirectivesModule,
  StringPipesModule,
  FiltersPipesModule,
  TooltipModule,
  ListPipesModule,
  MatProgressBarModule,
  CalendarModule,
];

const declarations: any[] = [
  FlatInputComponent,
  SliderComponent,
  RadioComponent,
  FakeRadioComponent,
  CustomValueCheckboxComponent,
  FlatSelectComponent,
  FlatDropDownComponent,
  RoundedSelectComponent,
  DateControlComponent,
  CustomCheckboxComponent,
  CustomCheckboxSwitchComponent,
  CustomSelectComponent,
  RenameListComponent,
  SelectComponent,
  InputRadioCardComponent,
  PasswordPolicyComponent,
];

const exports: any[] = [FormsModule, ReactiveFormsModule, ...declarations];

@NgModule({
  imports,
  declarations,
  exports,
})
export class CustomControlsModule {}
