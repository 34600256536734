import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "zipCodeMask",
})
export class ZipCodeMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value.replace(/\D+/, "").replace(/(\d{1,5}).*/, "$1");
  }

  parse(value: string): string {
    return this.transform(value);
  }
}
