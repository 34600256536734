import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
} from "@angular/router";
import { select, Store } from "@ngrx/store";

import { of, Observable } from "rxjs";
import { catchError, filter, take } from "rxjs/operators";

import {
  getTermsOfUseAction,
  getTermsOfUseSuccessAction,
} from "../store/actions/tos.action";
import { CoreState } from "../store/reducers";
import { getTermsOfUseDataLoaded } from "../store/selectors/tos.selector";

@Injectable()
export class TermsOfUseDataGuard {
  constructor(private store: Store<CoreState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.checkData();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.checkData();
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkData();
  }

  private checkData(): Observable<boolean> {
    this.store.dispatch(getTermsOfUseSuccessAction(null));
    this.store.dispatch(getTermsOfUseAction());

    return this.store.pipe(
      select(getTermsOfUseDataLoaded),
      filter((loaded: boolean) => loaded),
      take(1),
      catchError(() => of(false)),
    );
  }
}
