import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IInstitutionsRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_QUICK_SEARCH_INSTITUTION_DISTRICTS = "[QUICK SEARCH INSTITUTION] get districts",
  GET_QUICK_SEARCH_INSTITUTION_DISTRICTS_ERROR = "[QUICK SEARCH INSTITUTION] get districts error",
  GET_QUICK_SEARCH_INSTITUTION_DISTRICTS_SUCCESS = "[QUICK SEARCH INSTITUTION] get districts success",

  SET_QUICK_SEARCH_INSTITUTION_DISTRICTS_SPINNER_STATE = "[QUICK SEARCH INSTITUTION] set spinner state",

  CHANGE_QUICK_SEARCH_INSTITUTION_DISTRICTS_PAGE = "[QUICK SEARCH INSTITUTION] Change districts page",
  CHANGE_QUICK_SEARCH_INSTITUTION_DISTRICTS_QUERY = "[QUICK SEARCH INSTITUTION] Change districts query",

  RESET_QUICK_SEARCH_INSTITUTION_DISTRICTS_PAGE_STAGE = "[QUICK SEARCH INSTITUTION] reset districts page state",
}

export const getQuickSearchInstitutionDistrictsAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_DISTRICTS,
  () => TypedAction<ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_DISTRICTS>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_DISTRICTS);
export const getQuickSearchInstitutionDistrictsErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_DISTRICTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_DISTRICTS_ERROR,
  createPayload<IServerError>(),
);
export const getQuickSearchInstitutionDistrictsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_DISTRICTS_SUCCESS,
  IProductResultsResponse<IInstitutionsRecords>
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_DISTRICTS_SUCCESS,
  createPayload<IProductResultsResponse<IInstitutionsRecords>>(),
);

export const setQuickSearchInstitutionDistrictsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_QUICK_SEARCH_INSTITUTION_DISTRICTS_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_QUICK_SEARCH_INSTITUTION_DISTRICTS_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeQuickSearchInstitutionDistrictsPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_INSTITUTION_DISTRICTS_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_INSTITUTION_DISTRICTS_PAGE,
  createPayload<number>(),
);
export const changeQuickSearchInstitutionDistrictsQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_INSTITUTION_DISTRICTS_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_INSTITUTION_DISTRICTS_QUERY,
  createPayload<Params>(),
);

// RESET
export const resetQuickSearchInstitutionDistrictsStateAction: ActionCreator<
  ACTION_TYPES.RESET_QUICK_SEARCH_INSTITUTION_DISTRICTS_PAGE_STAGE,
  () => TypedAction<ACTION_TYPES.RESET_QUICK_SEARCH_INSTITUTION_DISTRICTS_PAGE_STAGE>
> = createAction(
  ACTION_TYPES.RESET_QUICK_SEARCH_INSTITUTION_DISTRICTS_PAGE_STAGE,
);
