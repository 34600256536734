import { ComponentRef } from "@angular/core";

import { OverlayTargetComponent } from "../components/overlay-target/overlay-target.component";

export class OverlayTargetRef {
  constructor(
    public componentRef: ComponentRef<OverlayTargetComponent>,
    public show: () => void,
    public hide: () => void,
  ) {}
}
