import { ChangeDetectionStrategy, Component, OnDestroy } from "@angular/core";
import { Store } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { resetAccountPopUpsState } from "../../store/actions/account-pop-ups.action";
import { resetAccountStateAction } from "../../store/actions/account.action";

@Component({
  selector: "bl-profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePageComponent implements OnDestroy {
  constructor(private _store: Store<CoreState>) {}

  ngOnDestroy(): void {
    this._store.dispatch(resetAccountPopUpsState());
    this._store.dispatch(resetAccountStateAction());
  }
}
