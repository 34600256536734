import { Component, Input } from "@angular/core";
import { CORE_PATHS, HELPCENTER } from "@core/constants/core-paths";
import { E_COMMERCE_PATHS } from "@modules/e-commerce/constants/e-commerce-paths";

@Component({
  selector: "bl-help-center",
  templateUrl: "./help-center.component.html",
  styleUrls: ["./help-center.component.scss"],
})
export class HelpCenterComponent {
  @Input() theme: "default" | "mobile" = "default";
  @Input() isAdminsAccount: boolean = false;
  @Input() isBroker: boolean = false;
  @Input() isLoggedIn: boolean = false;
  @Input() subscribed: boolean = false;

  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly eCommercePaths: typeof E_COMMERCE_PATHS = E_COMMERCE_PATHS;
  readonly helpCenter: string = HELPCENTER;
}
