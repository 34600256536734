import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { FirstSignUpPopUpData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-first-sign-up-pop-up-content",
  templateUrl: "./first-sign-up-pop-up-content.component.html",
  styleUrls: ["./first-sign-up-pop-up-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FirstSignUpPopUpContentComponent {
  constructor(
    @Inject(POP_UP_DATA) public data: FirstSignUpPopUpData,
    @Inject(PopUpRef) public ref: PopUpRef,
  ) {}
}
