import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { PremiumPopUpData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-premium-data-pop-up",
  templateUrl: "./premium-data-pop-up.component.html",
  styleUrls: ["./premium-data-pop-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PremiumDataPopUpComponent {
  constructor(
    private ref: PopUpRef,
    @Inject(POP_UP_DATA) public data: PremiumPopUpData,
  ) {}

  onBtnClick(e: Event, checkBoxValue: boolean): void {
    this.ref.close(e);
    this.data.updateStorage(checkBoxValue);
  }
}
