import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IAlertsTabsCounts } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[VIEW RESULTS ALERTS TABS] Get tabs",
  GET_ERROR = "[VIEW RESULTS ALERTS TABS] Get tabs error",
  GET_SUCCESS = "[VIEW RESULTS ALERTS TABS] Get tabs success",

  SET_SPINNER_STATE = "[VIEW RESULTS ALERTS TABS] Set spinner state",

  RESET = "[VIEW RESULTS ALERTS TABS] Reset tabs state",
}

export const getViewResultsAlertsTabsAction: CreateActionType<
  ACTION_TYPES.GET,
  any
> = createAction(ACTION_TYPES.GET, createPayload<any>());
export const getViewResultsAlertsTabsErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getViewResultsAlertsTabsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IAlertsTabsCounts
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IAlertsTabsCounts>());

export const setViewResultsAlertsTabsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_SPINNER_STATE,
  boolean
> = createAction(ACTION_TYPES.SET_SPINNER_STATE, createPayload<boolean>());

export const resetViewResultsAlertsTabsStateAction: CreateActionType<
  ACTION_TYPES.RESET,
  IAlertsTabsCounts
> = createAction(ACTION_TYPES.RESET, createPayload<IAlertsTabsCounts>());
