import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from "@angular/core";

import { timer } from "rxjs";

import { WindowRef } from "@core/refs/window-ref.service";

@Directive({
  selector: "[blWindowAutoScroll]",
})
export class WindowAutoScrollDirective implements OnChanges {
  @Input() offsetTop: number = 0;
  @Input() blWindowAutoScroll: boolean = false;

  constructor(
    private _el: ElementRef,
    private _window: WindowRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.blWindowAutoScroll && changes.blWindowAutoScroll.currentValue) {
      this.scrollTo();
    }
  }

  scrollTo(): void {
    if (this.blWindowAutoScroll) {
      timer(0).subscribe(() => {
        const top: any = this._el.nativeElement.offsetTop + this.offsetTop;
        if (this._window.nativeElement.scrollTo) {
          this._window.nativeElement.scrollTo({
            behavior: "smooth",
            left: 0,
            top: top,
          });
        } else {
          document.body.scrollTop = document.documentElement.scrollTop = top;
        }
      });
    }
  }
}
