import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Subject } from "rxjs";
import { filter, map, takeUntil, tap } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { getIsAdminsAccount } from "@modules/profile/store/selectors/profile.selector";

@Directive({
  selector: "[blIfAdminAccount]",
})
export class IfAdminAccountDirective implements OnInit, OnDestroy {
  @Input() blIfAdminAccount: boolean; // reverse

  private destroyer$: Subject<void> = new Subject();

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private store: Store<CoreState>,
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(
        select(getIsAdminsAccount),
        takeUntil(this.destroyer$),
        tap(() => this.viewContainerRef.clear()),
        map((ifAdminAccount: boolean) =>
          this.blIfAdminAccount ? !ifAdminAccount : ifAdminAccount,
        ),
        filter((ifAdminAccount: boolean) => ifAdminAccount),
      )
      .subscribe(() =>
        this.viewContainerRef.createEmbeddedView(this.templateRef),
      );
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
