import { NgModule } from "@angular/core";

import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";

const imports: any[] = [RecaptchaModule, RecaptchaFormsModule];

const exports: any[] = [...imports];

@NgModule({
  imports,
  exports,
})
export class ReCaptchaModule {}
