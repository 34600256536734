import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "lineSlice",
})
export class LineSlicePipe implements PipeTransform {
  transform(line: string, maxSymbols: number, fromStart: number = 0): string {
    let result: string = "";

    if (line && line.length) {
      result =
        line.length > maxSymbols
          ? line.slice(fromStart, maxSymbols) + "..."
          : line;
    }

    return result;
  }
}
