import {
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from "@angular/core";

import { CustomScrollWrapperComponent } from "../components/custom-scroll-wrapper/custom-scroll-wrapper.component";

@Directive({
  selector: "[blCustomScroll]",
})
export class CustomScrollDirective implements AfterViewInit, OnChanges {
  @Input() blCustomScroll: number;
  @Input() enabledXWheelScroll: boolean = false;
  @Input() yScroll: boolean = false;
  @Input() xScroll: boolean = false;

  @Output() changeScrollVisibility: EventEmitter<any> = new EventEmitter();

  component: ComponentRef<CustomScrollWrapperComponent>;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private resolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.viewContainer.clear();
    const componentFactory: ComponentFactory<CustomScrollWrapperComponent> =
      this.resolver.resolveComponentFactory(CustomScrollWrapperComponent);
    this.component = this.viewContainer.createComponent(componentFactory);

    this.component.instance.template = this.templateRef;
    this.component.instance.enabledXWheelScroll = this.enabledXWheelScroll;
    this.component.instance.xScroll = this.xScroll;
    this.component.instance.yScroll = this.yScroll;
    this.component.instance.changeScrollStatus.subscribe((state: boolean) => {
      this.changeScrollVisibility.emit(state);
    });

    if (!this.changeDetectorRef["destroyed"]) {
      this.changeDetectorRef.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.blCustomScroll && this.component) {
      this.component.instance.itemsLength = this.blCustomScroll;
    }
    if (changes.yScroll && this.component) {
      this.component.instance.yScroll = changes.yScroll.currentValue;
    }
    if (changes.xScroll && this.component) {
      this.component.instance.xScroll = changes.xScroll.currentValue;
    }
  }
}
