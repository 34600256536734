import { Component, Input, OnInit } from "@angular/core";

import {
  IAccountUserHistories,
  IAccountUserHistory,
} from "@modules/profile/interfaces/marketview";

import {
  GridDataResult,
  MultipleSortSettings,
} from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor } from "@progress/kendo-data-query";

@Component({
  selector: "bl-user-history-container",
  templateUrl: "./user-history-container.component.html",
  styleUrls: ["./user-history-container.component.scss"],
})
export class UserHistoryContainerComponent implements OnInit {
  public sort: SortDescriptor[] = [{ field: "date", dir: "desc" }];

  @Input() products: IAccountUserHistories;

  gridView: GridDataResult;

  public sortSettings: MultipleSortSettings = {
    mode: "multiple",
    initialDirection: "desc",
    allowUnsort: true,
    showIndexes: true,
  };

  ngOnInit(): void {
    if (this.products) {
      this.loadProducts();
    }
  }

  constructor() {}

  expand(data: IAccountUserHistory): boolean {
    return data.listData === "" ? false : true;
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.loadProducts();
  }

  private loadProducts(): void {
    this.gridView = {
      data: orderBy(this.products, this.sort),
      total: this.products.length,
    };
  }
}
