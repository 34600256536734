import { AnimationTriggerMetadata } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
} from "@angular/core";

import {
  IProfileRoadActions,
  IProfileRoadData,
} from "@core/interfaces/on-board";

import { INFO_COMPONENT_ACTIONS, INFO_COMPONENT_DATA } from "../../models";

import { fadeInOut } from "@ui/animations/fadeInOut";

@Component({
  selector: "bl-base-overlay-info",
  templateUrl: "./base-overlay-info.component.html",
  styleUrls: ["./base-overlay-info.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseOverlayInfoComponent {
  @HostBinding("@fadeInOut") animation: AnimationTriggerMetadata;

  constructor(
    @Inject(INFO_COMPONENT_DATA) public data: IProfileRoadData,
    @Inject(INFO_COMPONENT_ACTIONS) public actions: IProfileRoadActions,
  ) {}
}
