import { ModuleWithProviders } from "@angular/core";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

export const extModules: [ModuleWithProviders<StoreDevtoolsModule>] = [
  StoreDevtoolsModule.instrument({
    maxAge: 25,
    name: "BUILD A LIST APP",
    connectInZone: true,
  }),
];
