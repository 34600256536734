import { AbstractControl, ValidatorFn } from "@angular/forms";

import { VALIDATION_REG_EXP } from "../constants/validators/validation-reg-exp";

export default function postalCode(msg?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const message: string = msg || "Postal Code is not valid";

    return VALIDATION_REG_EXP.postalCode.test(control.value)
      ? null
      : { postalCode: message };
  };
}
