import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-institution-counters.action";

import { IServerError } from "@shared/interfaces/server-error";

export interface IViewResultsInstitutionCountersState {
  colleges: number | null;
  districts: number | null;
  schools: number | null;

  pending: boolean;
  error: IServerError | null;
}

const initialState: IViewResultsInstitutionCountersState = {
  colleges: null,
  districts: null,
  schools: null,

  pending: false,
  error: null,
};

const getViewResultsInstitutionCounters: OnReducer<
  IViewResultsInstitutionCountersState,
  ActionType<any>
> = (state: IViewResultsInstitutionCountersState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsInstitutionCountersError: OnReducer<
  IViewResultsInstitutionCountersState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsInstitutionCountersState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsInstitutionCountersSuccess: OnReducer<
  IViewResultsInstitutionCountersState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionCountersState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  ...payload,
});

const setViewResultsInstitutionCountersSpinnerState: OnReducer<
  IViewResultsInstitutionCountersState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionCountersState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetViewResultsInstitutionCounters: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsInstitutionCountersState> =
  createReducer<IViewResultsInstitutionCountersState>(
    initialState,

    on(
      actions.getViewResultsInstitutionCountersAction,
      getViewResultsInstitutionCounters,
    ),
    on(
      actions.getViewResultsInstitutionCountersErrorAction,
      getViewResultsInstitutionCountersError,
    ),
    on(
      actions.getViewResultsInstitutionCountersSuccessAction,
      getViewResultsInstitutionCountersSuccess,
    ),

    on(
      actions.setViewResultsInstitutionCountersSpinnerStateAction,
      setViewResultsInstitutionCountersSpinnerState,
    ),

    on(
      actions.resetViewResultsInstitutionCountersAction,
      resetViewResultsInstitutionCounters,
    ),
  );

export function viewResultsInstitutionCountersReducer(
  state: IViewResultsInstitutionCountersState,
  action: Action,
): IViewResultsInstitutionCountersState {
  return reducer(state, action);
}

export const colleges: GetFromState<
  number,
  IViewResultsInstitutionCountersState
> = ({ colleges: _colleges }: IViewResultsInstitutionCountersState): number =>
  _colleges;
export const districts: GetFromState<
  number,
  IViewResultsInstitutionCountersState
> = ({ districts: _districts }: IViewResultsInstitutionCountersState): number =>
  _districts;
export const schools: GetFromState<
  number,
  IViewResultsInstitutionCountersState
> = ({ schools: _schools }: IViewResultsInstitutionCountersState): number =>
  _schools;

export const pending: GetFromState<
  boolean,
  IViewResultsInstitutionCountersState
> = ({ pending: _pending }: IViewResultsInstitutionCountersState): boolean =>
  _pending;
export const error: GetFromState<
  IServerError,
  IViewResultsInstitutionCountersState
> = ({ error: _error }: IViewResultsInstitutionCountersState): IServerError =>
  _error;

export const isEmptyCriteriaSelected: (...counters: number[]) => boolean = (
  ...counters: number[]
): boolean => !counters.filter((count: number) => count).length;
