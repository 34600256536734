import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { select, Store } from "@ngrx/store";

import { Observable, Subject } from "rxjs";
import { distinctUntilChanged, filter, skip, takeUntil } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { assignDashboardListAction } from "@modules/dashboard-lists/store/actions/dashboard-lists.action";
import {
  assignListLoading,
  getAssignListError,
  getAssignListLoaded,
} from "@modules/dashboard-lists/store/selectors/dashboard-lists.selector";
import {
  getEmailsAction,
  getEmailsResetAction,
} from "@modules/profile/store/actions/account.action";
import {
  getEmails,
  getEmailsTransformed,
} from "@modules/profile/store/selectors/account.selector";

import { AccountUsersService } from "@modules/profile/services/account-users.service";

import { IServerError } from "@shared/interfaces/server-error";

import { PopUpRef } from "../../models/pop-up-ref";

import { FORM_VALIDATION_ERRORS } from "@shared/constants/validators/forms-validations-errors";

import { Validators } from "@shared/validators/validators";
import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-list-share-pop-up",
  templateUrl: "./list-share-pop-up.component.html",
  styleUrls: ["./list-share-pop-up.component.scss"],
})
export class ListSharePopUpComponent implements OnInit, OnDestroy {
  private _destroyer$: Subject<void> = new Subject();
  private _email: string;

  emails$: Observable<string[]> = this._store.pipe(select(getEmails));
  emailsTransformed$: Observable<Array<{ text: string; value: string }>> =
    this._store.pipe(select(getEmailsTransformed));
  assignListLoading$: Observable<boolean> = this._store.pipe(
    select(assignListLoading),
  );
  getAssignListLoaded$: Observable<boolean> = this._store.pipe(
    select(getAssignListLoaded),
  );
  serverError$: Observable<IServerError> = this._store.pipe(
    select(getAssignListError),
  );

  form: UntypedFormGroup;
  emailValidation: typeof FORM_VALIDATION_ERRORS.email =
    FORM_VALIDATION_ERRORS.email;
  customError: string;
  edited: boolean = false;

  constructor(
    @Inject(POP_UP_DATA) public data: { listId: number },
    private _fb: UntypedFormBuilder,
    private _popUpRef: PopUpRef,
    private _accountUsersService: AccountUsersService,
    private _store: Store<CoreState>,
  ) {}

  ngOnInit(): void {
    this.form = this.createForm();
    this.subscribeAssigningListError();
    this.subscribeAssigningListSuccess();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  onClose(): void {
    this._popUpRef.close({ answer: false });
  }

  loadSuggestions(query: string): void {
    this._store.dispatch(getEmailsAction(query));
  }

  resetSuggestions(): void {
    this._store.dispatch(getEmailsResetAction());
  }

  selectSuggestion = (email: string) => {
    this._email = email;
    this.edited = true;
    this.form.setValue({ email });
  };

  subscribeAssigningListError(): void {
    this.serverError$
      .pipe(
        takeUntil(this._destroyer$),
        filter((errors: IServerError) => !!errors),
      )
      .subscribe((errors: IServerError) => {
        Object.entries(errors.errors).forEach(
          ([fieldName, message]: [string, string[]]) => {
            const control: AbstractControl = this.form.get(fieldName);

            if (control) {
              control.setErrors(message);
              this.customError = message[0];
              this._email = null;
            }
          },
        );
      });
  }

  subscribeAssigningListSuccess(): void {
    this.getAssignListLoaded$
      .pipe(
        takeUntil(this._destroyer$),
        skip(1),
        distinctUntilChanged(),
        filter((loaded: boolean) => loaded),
      )
      .subscribe(() => this.onClose());
  }

  submit(): void {
    const { listId }: { listId: number } = this.data;
    if (listId && this._email) {
      this._store.dispatch(
        assignDashboardListAction({ id: listId, email: this._email }),
      );
    }
  }

  resetEmail(): void {
    this._email = null;
    this.edited = false;
    this.form.setValue({ email: null });
  }

  private createForm(): UntypedFormGroup {
    return this._fb.group({
      email: [
        "",
        [
          Validators.email(this.emailValidation.invalid),
          Validators.required(this.emailValidation.required),
        ],
      ],
    });
  }
}
