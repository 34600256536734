import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { tooltipsReducer, ITooltipsState } from "./tooltips.reducer";

export interface ITooltipsModuleState {
  tooltips: ITooltipsState;
}

const tooltipsModuleState: ActionReducerMap<ITooltipsModuleState> = {
  tooltips: tooltipsReducer,
};

// eslint-disable-next-line
export const TOOLTIPS_MODULE_FEATURE_NAME = 'TOOLTIPS_MODULE_FEATURE_NAME';
export const TOOLTIPS_MODULE_FEATURE_STATE: InjectionToken<
  ActionReducerMap<ITooltipsModuleState>
> = new InjectionToken<ActionReducerMap<ITooltipsModuleState>>(
  TOOLTIPS_MODULE_FEATURE_NAME,
  {
    factory: () => tooltipsModuleState,
  },
);
