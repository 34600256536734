import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { ITooltip } from "@core/interfaces/tooltip";

enum ACTION_TYPES {
  ADD_TOOLTIP = "[TOOLTIP]: add tooltip",
  REMOVE_TOOLTIP = "[TOOLTIP]: remove tooltip",

  SET_FIRST_TIME_SEGMENT_PAGE_STATE = "[TOOLTIP] Set showing first time segment page",
  SET_CALCULATOR_SEGMENT_PAGE_STATE = "[TOOLTIP] Set showing calculator segment page",
  SET_REFRESH_CREDITS_TOOLTIP_STATE = "[TOOLTIP] Set refresh credits tooltip state",
  SET_GEO_SUGGEST_TOOLTIP_STATE = "[TOOLTIP] Set showing geo suggest tooltip",

  RESET_SEGMENT_TOOLTIPS = "[TOOLTIP] Reset segment tooltips",
  RESET_TOOLTIPS_STATE = "[TOOLTIP] Reset tooltips state",
  RESET_JOB_TAG_CHANGES = "[TOOLTIP] Reset Jobs Tag Changes",
}

export const addTooltipAction: CreateActionType<
  ACTION_TYPES.ADD_TOOLTIP,
  ITooltip
> = createAction(ACTION_TYPES.ADD_TOOLTIP, createPayload<ITooltip>());
export const removeTooltipAction: CreateActionType<
  ACTION_TYPES.REMOVE_TOOLTIP,
  number
> = createAction(ACTION_TYPES.REMOVE_TOOLTIP, createPayload<number>());

export const setShowFirstTimeSegmentPageTooltipAction: CreateActionType<
  ACTION_TYPES.SET_FIRST_TIME_SEGMENT_PAGE_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_FIRST_TIME_SEGMENT_PAGE_STATE,
  createPayload<boolean>(),
);
export const setShowCalculatorSegmentPageTooltipAction: CreateActionType<
  ACTION_TYPES.SET_CALCULATOR_SEGMENT_PAGE_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_CALCULATOR_SEGMENT_PAGE_STATE,
  createPayload<boolean>(),
);
export const setRefreshCreditsTooltipStateAction: CreateActionType<
  ACTION_TYPES.SET_REFRESH_CREDITS_TOOLTIP_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_REFRESH_CREDITS_TOOLTIP_STATE,
  createPayload<boolean>(),
);
export const setDoNotShowGeoSuggestTooltipStateAction: CreateActionType<
  ACTION_TYPES.SET_GEO_SUGGEST_TOOLTIP_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_GEO_SUGGEST_TOOLTIP_STATE,
  createPayload<boolean>(),
);

export const resetSegmentTooltipsAction: ActionCreator<
  ACTION_TYPES.RESET_SEGMENT_TOOLTIPS,
  () => TypedAction<ACTION_TYPES.RESET_SEGMENT_TOOLTIPS>
> = createAction(ACTION_TYPES.RESET_SEGMENT_TOOLTIPS);
export const resetTooltipsStateAction: ActionCreator<
  ACTION_TYPES.RESET_TOOLTIPS_STATE,
  () => TypedAction<ACTION_TYPES.RESET_TOOLTIPS_STATE>
> = createAction(ACTION_TYPES.RESET_TOOLTIPS_STATE);
export const resetJobTagChangesAction: ActionCreator<
  ACTION_TYPES.RESET_JOB_TAG_CHANGES,
  () => TypedAction<ACTION_TYPES.RESET_JOB_TAG_CHANGES>
> = createAction(ACTION_TYPES.RESET_JOB_TAG_CHANGES);
