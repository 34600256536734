export enum PRODUCT_ENTITY_TYPES {
  LIST = 1,
  VIEW,
  CUSTOM_LIST,
  CDL,
  DEPLOY_ONLY,
}

export const PRODUCT_ENTITY_TYPES_NAMES: {
  [key in PRODUCT_ENTITY_TYPES]: string;
} = Object.freeze({
  [PRODUCT_ENTITY_TYPES.LIST]: "Download",
  [PRODUCT_ENTITY_TYPES.VIEW]: "View",
  [PRODUCT_ENTITY_TYPES.CUSTOM_LIST]: "Custom",
  [PRODUCT_ENTITY_TYPES.CDL]: "Database",
  [PRODUCT_ENTITY_TYPES.DEPLOY_ONLY]: "DeployOnly",
});
