import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/pids.action";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IPidsChildAndParentPopUpData,
  IPIDSuggestion,
} from "../../interfaces/pids";

import { MIN_LENGTH_FOR_SEARCH } from "../../constants/autocomplete";

export interface IPidsState {
  suggestions: IPIDSuggestion[];
  error: IServerError | null;
  include: boolean;
  shouldReset: boolean;
  popUpData: IPidsChildAndParentPopUpData;
}

const initialState: IPidsState = {
  suggestions: [],
  error: null,
  include: true,
  shouldReset: false,
  popUpData: {},
};

const loadSuggestions: OnReducer<IPidsState, ActionType<Payload<any>>> = (
  state: IPidsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  error: null,
  suggestions:
    payload.length >= MIN_LENGTH_FOR_SEARCH ? [...state.suggestions] : [],
  shouldReset: payload.length < MIN_LENGTH_FOR_SEARCH,
});

const loadSuggestionsError: OnReducer<
  IPidsState,
  ActionType<Payload<IServerError>>
> = (state: IPidsState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  shouldReset: false,
});

const loadSuggestionsSuccess: OnReducer<
  IPidsState,
  ActionType<Payload<any>>
> = (state: IPidsState, { payload }: Payload<any>) => ({
  ...state,
  suggestions: state.shouldReset ? [] : [...payload],
  shouldReset: false,
});

const loadSuggestionsReset: OnReducer<IPidsState, ActionType<any>> = (
  state: IPidsState,
) => ({
  ...state,
  error: null,
  suggestions: [],
  shouldReset: true,
});

const toggleIncludePid: OnReducer<IPidsState, ActionType<Payload<any>>> = (
  state: IPidsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  error: null,
  include: payload ? payload : !state.include,
});

const resetPidError: OnReducer<IPidsState, ActionType<any>> = (
  state: IPidsState,
) => ({
  ...state,
  error: null,
});

const setPidError: OnReducer<IPidsState, ActionType<Payload<IServerError>>> = (
  state: IPidsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  error: { ...payload },
});

const savePidPopUpSelectedData: OnReducer<
  IPidsState,
  ActionType<Payload<any>>
> = (state: IPidsState, { payload }: Payload<any>) => ({
  ...state,
  popUpData: { ...payload },
});

const resetPidPopUpSelectedData: OnReducer<IPidsState, ActionType<any>> = (
  state: IPidsState,
) => ({
  ...state,
  popUpData: {},
});

const resetPid: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IPidsState> = createReducer<IPidsState>(
  initialState,

  on(actions.loadSuggestionsAction, loadSuggestions),
  on(actions.loadSuggestionsErrorAction, loadSuggestionsError),
  on(actions.loadSuggestionsSuccessAction, loadSuggestionsSuccess),
  on(actions.loadSuggestionsResetAction, loadSuggestionsReset),

  on(actions.toggleIncludePidAction, toggleIncludePid),

  on(actions.addPIDAction, resetPidError),
  on(actions.updatePIDAction, resetPidError),

  on(actions.addPIDErrorAction, setPidError),
  on(actions.updatePIDErrorAction, setPidError),
  on(actions.resetPIDErrorAction, resetPidError),

  on(actions.savePidPopUpSelectedDataAction, savePidPopUpSelectedData),
  on(actions.resetPidPopUpSelectedDataAction, resetPidPopUpSelectedData),

  on(actions.resetPidAction, resetPid),
);

export function pidsReducer(state: IPidsState, action: Action): IPidsState {
  return reducer(state, action);
}

export const include: GetFromState<boolean, IPidsState> = (
  state: IPidsState,
): boolean => state && state.include;
export const pidError: GetFromState<string, IPidsState> = (
  state: IPidsState,
): string => state && state.error && state.error.message;
export const suggestions: GetFromState<IPIDSuggestion[], IPidsState> = (
  state: IPidsState,
): IPIDSuggestion[] => state && state.suggestions;
export const pidsModalsResults: GetFromState<
  IPidsChildAndParentPopUpData,
  IPidsState
> = (state: IPidsState): IPidsChildAndParentPopUpData =>
  state && state.popUpData;
