import { IUserAddress } from "@modules/auth/interfaces/user";
import {
  IAddress,
  ICreditCardInfo,
  INewCreditCardAddress,
  INewCreditCartInfo,
  IOrderCartDetails,
  IOrderCartDetailsAddress,
} from "@modules/e-commerce/interfaces/e-commerce";

import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_COUNTRY_NAME,
} from "@modules/e-commerce/constants/payment";
import { STATE_NAMES } from "@modules/segment/constants/geography";

// TAX ADDRESS
export class TaxAddress {
  city: string;
  state: string;
  countryCode: string;
  zipCode: string;

  constructor(address: IAddress) {
    const { city_name, state_code, country_code, zip_code }: IAddress = address;

    this.city = city_name;
    this.state = state_code;
    this.countryCode = country_code;
    this.zipCode = zip_code.toString().substr(0, 5);
  }
}

export class TaxAddressFromNewCard {
  city: string;
  state: string;
  countryCode: string;
  zipCode: string;

  constructor(card: INewCreditCartInfo) {
    const {
      bill_to_address_country,
      bill_to_address_state,
      bill_to_address_zip,
      bill_to_address_city,
    }: INewCreditCartInfo = card;

    this.city = bill_to_address_city;
    this.state = bill_to_address_state;
    this.countryCode = bill_to_address_country;
    this.zipCode = bill_to_address_zip.toString().substr(0, 5);
  }
}

// SALES ORDER ADDRESS
export class OrderCardAddress implements IOrderCartDetailsAddress {
  addressLine1: string;
  addressLine2: string;
  cityName: string;
  stateName: string;
  stateCode: string;
  countryName: string = DEFAULT_COUNTRY_NAME;
  countryCode: string = DEFAULT_COUNTRY_CODE;
  zipCode: string;
  phoneNumber: string;

  constructor(address: IAddress) {
    const {
      address_line_1,
      address_line_2,
      city_name,
      country_code,
      country_name,
      phone_number,
      state_code,
      state_name,
      zip_code,
    }: IAddress = address;

    this.stateName = state_name ? state_name.toUpperCase() : "";
    this.addressLine1 = address_line_1 || "";
    this.addressLine2 = address_line_2 || "";
    this.cityName = city_name || "";
    this.stateCode = state_code || "";
    this.countryCode = country_code || "";
    this.countryName = country_name || "";
    this.zipCode = zip_code ? zip_code.toString().substr(0, 5) : "";
    this.phoneNumber = phone_number || "";
  }
}

export class NewOrderCardAddress implements IOrderCartDetailsAddress {
  addressLine1: string;
  addressLine2: string;
  cityName: string;
  stateName: string;
  stateCode: string;
  countryName: string = DEFAULT_COUNTRY_NAME;
  countryCode: string = DEFAULT_COUNTRY_CODE;
  zipCode: string;
  phoneNumber: string;

  constructor(cart: INewCreditCartInfo) {
    const {
      bill_to_address_line1,
      bill_to_address_line2,
      bill_to_address_city,
      bill_to_address_state,
      bill_to_address_zip,
      bill_to_address_phone,
    }: INewCreditCartInfo = cart;

    this.addressLine1 = bill_to_address_line1 || "";
    this.addressLine2 = bill_to_address_line2 || "";
    this.cityName = bill_to_address_city || "";
    this.stateCode = bill_to_address_state || "";
    this.stateName =
      (STATE_NAMES[this.stateCode] &&
        STATE_NAMES[this.stateCode].toUpperCase()) ||
      this.stateCode;
    this.zipCode = bill_to_address_zip.toString().substr(0, 5);
    this.phoneNumber = bill_to_address_phone || "";
  }
}

// SALES ORDER CARD DETAILS
export class OrderCardDetails implements IOrderCartDetails {
  readonly paymentTokenIdentifier: string;
  readonly firstNameOnCard: string;
  readonly lastNameOnCard: string;
  readonly cardExpirationDate: string;
  readonly cardStatusCode: string;
  readonly createdTimestamp: number;
  readonly cardTypeCode: string;
  readonly cardTypeSubCode: string;
  readonly lastFourDigits: string;
  readonly phoneNumber: string;
  readonly address: IOrderCartDetailsAddress;

  constructor(cart: ICreditCardInfo) {
    const {
      payment_token_identifier,
      first_name_on_card,
      last_name_on_card,
      card_expiration_date,
      card_status_code,
      created_timestamp,
      card_type_code,
      card_type_sub_code,
      last_four_digits,
      address,
    }: ICreditCardInfo = cart;

    this.paymentTokenIdentifier = payment_token_identifier;
    this.firstNameOnCard = first_name_on_card.toUpperCase();
    this.lastNameOnCard = last_name_on_card.toUpperCase();
    this.cardExpirationDate = card_expiration_date;
    this.cardStatusCode = card_status_code;
    this.createdTimestamp = created_timestamp;
    this.cardTypeCode = card_type_code;
    this.cardTypeSubCode = card_type_sub_code;
    this.lastFourDigits = last_four_digits;
    this.address = new OrderCardAddress(address);
  }
}

export class OrderNewCardDetails implements IOrderCartDetails {
  readonly paymentTokenIdentifier: string;
  readonly firstNameOnCard: string;
  readonly lastNameOnCard: string;
  readonly cardExpirationDate: string;
  readonly cardStatusCode: string = "";
  readonly createdTimestamp: number;
  readonly cardTypeCode: string = "CC";
  readonly cardTypeSubCode: string = "";
  readonly lastFourDigits: string;
  readonly address: IOrderCartDetailsAddress;

  constructor(cart: INewCreditCartInfo) {
    const {
      bill_to_forename,
      bill_to_surname,
      card_expiry_date,
      card_type,
      card_number,
    }: INewCreditCartInfo = cart;

    this.firstNameOnCard = bill_to_forename.toUpperCase();
    this.lastNameOnCard = bill_to_surname.toUpperCase();
    this.cardExpirationDate = card_expiry_date;
    this.cardTypeSubCode = card_type;
    this.lastFourDigits = card_number.toString().slice(-4);
    this.address = new NewOrderCardAddress(cart);
  }
}

export class UserPaymentAddressConverter {
  static PAYMENT_ADDRESS_FIELDS_TO_ADDRESS_FIELDS_MAP: any = {
    bill_to_address_line1: "addressFirst",
    bill_to_address_line2: "addressSecond",
    bill_to_address_city: "city",
    bill_to_address_state: "stateCode",
    bill_to_address_country: "countryCode",
    bill_to_address_zip: "postalCode",
  };

  static ADDRESS_FIELDS_TO_PAYMENT_ADDRESS_FIELDS_MAP: any = Object.entries(
    UserPaymentAddressConverter.PAYMENT_ADDRESS_FIELDS_TO_ADDRESS_FIELDS_MAP,
  ).reduce(
    (res: { [key: string]: string }, [key, value]: [string, string]) => ({
      ...res,
      [value]: key,
    }),
    {},
  );

  static convertPaymentToValidationAddress = (
    address: INewCreditCardAddress,
  ): IUserAddress => {
    return Object.entries(address).reduce(
      (res: Partial<INewCreditCardAddress>, [key, value]: [string, string]) => {
        if (
          UserPaymentAddressConverter
            .PAYMENT_ADDRESS_FIELDS_TO_ADDRESS_FIELDS_MAP[key]
        ) {
          return {
            ...res,
            [UserPaymentAddressConverter
              .PAYMENT_ADDRESS_FIELDS_TO_ADDRESS_FIELDS_MAP[key]]: value || "",
          };
        } else {
          return res;
        }
      },
      {},
    ) as IUserAddress;
  };

  static convertValidationAddressToPaymentAddress = (
    address: IUserAddress,
  ): IUserAddress => {
    return Object.entries(address).reduce(
      (
        res: Partial<INewCreditCardAddress>,
        [key, value]: [string, string | number],
      ) => {
        if (
          UserPaymentAddressConverter
            .ADDRESS_FIELDS_TO_PAYMENT_ADDRESS_FIELDS_MAP[key]
        ) {
          return {
            ...res,
            [UserPaymentAddressConverter
              .ADDRESS_FIELDS_TO_PAYMENT_ADDRESS_FIELDS_MAP[key]]: value || "",
          };
        } else {
          return { ...res, [key]: value || "" };
        }
      },
      {},
    ) as IUserAddress;
  };
}
