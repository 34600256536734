import { DatePipe } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { StoreFeatureModule, StoreModule } from "@ngrx/store";

import { creditsModuleState, creditsModuleStateToken } from "./store/reducers";
import { CREDITS_MODULE_FEATURE_NAME } from "./store/selectors";

import { CreditsService } from "./services/credits.service";

import { CreditsGuard } from "./guards/credits.guard";

const imports: ModuleWithProviders<StoreFeatureModule>[] = [
  StoreModule.forFeature(CREDITS_MODULE_FEATURE_NAME, creditsModuleStateToken),
];

const providers: any = [
  CreditsService,
  CreditsGuard,
  {
    provide: creditsModuleStateToken,
    useValue: creditsModuleState,
  },
  DatePipe,
];

const declarations: [] = [];
const exports: [] = [];

@NgModule({
  imports,
  providers,
  declarations,
  exports,
})
export class CreditsModule {}
