import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { IDashboardListsModuleState } from "../reducers";
import {
  dashboardListsViewType,
  IDashboardListsViewType,
} from "../reducers/dashboard-lists-view.reducer";

import { DASHBOARD_VIEW_TYPE } from "@modules/dashboard/interfaces";

import { getDashboardListsModuleState } from "./";

export const getDashboardListsViewTypeState: GetFromStateInSelectors<
  CoreState,
  IDashboardListsViewType
> = createSelector(
  getDashboardListsModuleState,
  (state: IDashboardListsModuleState) => state.dashboardListsViewType,
);

export const getDashboardListsViewType: MemoizedSelector<
  CoreState,
  DASHBOARD_VIEW_TYPE
> = createSelector(getDashboardListsViewTypeState, dashboardListsViewType);
