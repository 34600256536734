import { SafeHtml } from "@angular/platform-browser";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/common-pages.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IOrderData } from "../../interfaces/order-confirmation";
import { IHtmlPageData } from "../../interfaces/terms-of-servise";

// store

// interfaces

export interface ICommonPagesState {
  privacyPolicyData: IHtmlPageData<SafeHtml> | null;
  privacyPolicyDataLoading: boolean;
  privacyPolicyDataLoaded: boolean;
  privacyPolicyDataError: IServerError | null;

  orderData: IOrderData | null;
  orderDataLoading: boolean;
  orderDataLoaded: boolean;
  orderDataError: IServerError | null;

  isOpenMenuMobile: boolean;
}

const initialState: ICommonPagesState = {
  privacyPolicyData: null,
  privacyPolicyDataLoading: false,
  privacyPolicyDataLoaded: false,
  privacyPolicyDataError: null,

  orderData: null,
  orderDataLoading: false,
  orderDataLoaded: false,
  orderDataError: null,

  isOpenMenuMobile: false,
};

// privacy policy
const getPrivacyPolicyData: OnReducer<ICommonPagesState, ActionType<any>> = (
  state: ICommonPagesState,
) => ({
  ...state,
  privacyPolicyDataLoading: true,
  privacyPolicyDataError: null,
  privacyPolicyDataLoaded: false,
});

const getPrivacyPolicyDataSuccess: OnReducer<
  ICommonPagesState,
  ActionType<Payload<any>>
> = (state: ICommonPagesState, { payload }: Payload<any>) => ({
  ...state,
  privacyPolicyDataLoading: false,
  privacyPolicyDataLoaded: true,
  privacyPolicyDataError: null,
  privacyPolicyData: { ...payload },
});

const getPrivacyPolicyDataError: OnReducer<
  ICommonPagesState,
  ActionType<Payload<IServerError>>
> = (state: ICommonPagesState, { payload }: Payload<IServerError>) => ({
  ...state,
  privacyPolicyDataLoading: false,
  privacyPolicyDataLoaded: false,
  privacyPolicyDataError: { ...payload },
});

// order data
const getOrderData: OnReducer<ICommonPagesState, ActionType<any>> = (
  state: ICommonPagesState,
) => ({
  ...state,
  orderDataLoading: true,
  orderDataError: null,
  orderDataLoaded: false,
});

const getOrderDataSuccess: OnReducer<
  ICommonPagesState,
  ActionType<Payload<any>>
> = (state: ICommonPagesState, { payload }: Payload<any>) => ({
  ...state,
  orderDataLoading: false,
  orderDataLoaded: true,
  orderDataError: null,
  orderData: { ...payload },
});

const getOrderDataError: OnReducer<
  ICommonPagesState,
  ActionType<Payload<IServerError>>
> = (state: ICommonPagesState, { payload }: Payload<IServerError>) => ({
  ...state,
  orderDataLoading: false,
  orderDataLoaded: false,
  orderDataError: { ...payload },
});

const resetOrderData: OnReducer<ICommonPagesState, ActionType<any>> = (
  state: ICommonPagesState,
) => ({
  ...state,
  orderData: null,
  orderDataError: null,
  orderDataLoaded: false,
  orderDataLoading: false,
});

// menu mobile
const openMenuMobile: OnReducer<ICommonPagesState, ActionType<any>> = (
  state: ICommonPagesState,
) => ({
  ...state,
  isOpenMenuMobile: true,
});

const closeMenuMobile: OnReducer<ICommonPagesState, ActionType<any>> = (
  state: ICommonPagesState,
) => ({
  ...state,
  isOpenMenuMobile: false,
});

const reducer: ActionReducer<ICommonPagesState> =
  createReducer<ICommonPagesState>(
    initialState,

    on(actions.getPrivacyPolicyDataAction, getPrivacyPolicyData),
    on(actions.getPrivacyPolicyDataErrorAction, getPrivacyPolicyDataError),
    on(actions.getPrivacyPolicyDataSuccessAction, getPrivacyPolicyDataSuccess),

    on(actions.getOrderDataAction, getOrderData),
    on(actions.getOrderDataErrorAction, getOrderDataError),
    on(actions.getOrderDataSuccessAction, getOrderDataSuccess),
    on(actions.resetOrderDataAction, resetOrderData),

    on(actions.openMenuMobileAction, openMenuMobile),
    on(actions.closeMenuMobileAction, closeMenuMobile),
  );

export const privacyPolicyData: GetFromState<
  IHtmlPageData<SafeHtml> | null,
  ICommonPagesState
> = (state: ICommonPagesState): IHtmlPageData<SafeHtml> | null =>
  state.privacyPolicyData;
export const privacyPolicyDataLoaded: GetFromState<
  boolean,
  ICommonPagesState
> = (state: ICommonPagesState): boolean => state.privacyPolicyDataLoaded;

export const orderData: GetFromState<IOrderData | null, ICommonPagesState> = (
  state: ICommonPagesState,
): IOrderData | null => state.orderData;
export const orderDataLoaded: GetFromState<boolean, ICommonPagesState> = (
  state: ICommonPagesState,
): boolean => state.orderDataLoaded;

export const isOpenMenuMobile: GetFromState<boolean, ICommonPagesState> = (
  state: ICommonPagesState,
): boolean => state.isOpenMenuMobile;

export function commonPagesReducer(
  state: ICommonPagesState,
  action: Action,
): ICommonPagesState {
  return reducer(state, action);
}
