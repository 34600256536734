import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { CoreState } from "../store/reducers";

import { DownloadStartedPopUpContentComponent } from "@ui/pop-up/components/download-started-pop-up-content/download-started-pop-up-content.component";
import { ReDownloadPopUpContentComponent } from "@ui/pop-up/components/re-download-pop-up-content/re-download-pop-up-content.component";

import { PopUpService } from "@ui/pop-up/services/pop-up/pop-up.service";

import { RE_DOWNLOAD_POP_UP } from "@ui/pop-up/constants/pop-up-data";

@Injectable()
export class ListDownloadService {
  constructor(
    private store: Store<CoreState>,
    private popUpService: PopUpService,
  ) {}

  largeFilePopUp(): void {
    this.popUpService.open(DownloadStartedPopUpContentComponent, {
      showCloseBtn: false,
      timer: 4000,
    });
  }

  largeFilePopUpError(): void {
    this.popUpService.open(ReDownloadPopUpContentComponent, {
      data: RE_DOWNLOAD_POP_UP,
      showCloseBtn: false,
    });
  }
}
