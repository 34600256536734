import { Component, Inject } from "@angular/core";

import { InfoPopUpData } from "../../models/pop-up-data";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-info-pop-up-content",
  templateUrl: "./info-pop-up-content.component.html",
  styleUrls: ["./info-pop-up-content.component.scss"],
})
export class InfoPopUpContentComponent {
  constructor(@Inject(POP_UP_DATA) public data: InfoPopUpData) {}
}
