import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";

import { of, Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import * as actions from "../actions/brokers-tou.actions";
import { CoreState } from "../reducers";

import { TosService } from "../../services/tos.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IServerError } from "@shared/interfaces/server-error";
import { ITermsOfUsePageData } from "../../interfaces/terms-of-servise";

@Injectable()
export class BrokersTouEffect {
  constructor(
    private _actions$: Actions,
    private _service: TosService,
    private _store: Store<CoreState>,
  ) {}

  getPageData$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getBrokerTermsOfUseAction),
      switchMap(() =>
        this._service.loadBrokersTermsOfUseData().pipe(
          map((pageData: ITermsOfUsePageData<SafeHtml>) =>
            actions.getBrokerTermsOfUseSuccessAction(pageData),
          ),
          catchError((error: IServerError) =>
            of(actions.getBrokerTermsOfUseErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );
}
