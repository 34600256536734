import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DocumentRef } from "@core/refs/document-ref.service";
import { environment } from "@env/environment";
import {
  IScriptParamsTypes,
  appendExternalScriptToBody,
} from "@shared/utils/append-external-script-to-body";
import { GAIN_SIGHT_ANALYTICS_STATE } from "../constants/gainsight-analytics";
import { getGainSightAnalyticsScript } from "../utils/get-gain-sight-analytics-script";
declare let aptrinsic: any;

@Injectable({
  providedIn: "root",
})
export class GainSightAnalyticsTagService {
  private _renderer: Renderer2;

  constructor(
    @Inject(GAIN_SIGHT_ANALYTICS_STATE) private _state: boolean,
    private _rendererFactory: RendererFactory2,
    private _documentRef: DocumentRef,
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  callGainSight(user, data) {
    let isAdmin = false;
    if (data.user === null || data.isAdmin) {
      isAdmin = true;
    }
    if (data.isAdminsAccount) {
      isAdmin = false;
    }
    this.initGainSightAnalyticsTag(user, isAdmin);
  }

  async initGainSightAnalyticsTag(user, isAdmin): Promise<void> {
    if (
      isAdmin ||
      !this._state ||
      !!this._documentRef.nativeElement.getElementById("gain-sight-script")
    ) {
      return;
    }

    const gainSightScriptParams: Iterable<
      readonly [string, IScriptParamsTypes]
    > = [
      ["id", "gain-sight-script"],
      ["innerHTML", getGainSightAnalyticsScript()],
    ];
    await appendExternalScriptToBody(this._documentRef, gainSightScriptParams);
    await this.initializeAnalyticsForUser(user);
  }

  initializeAnalyticsForUser(user) {
    if (user && user.id) {
      aptrinsic(
        "identify",
        {
          //User Fields
          id: this.modifyId(user.id), // Required for logged in app users
        },
        {
          id: this.modifyId(user.account_id),
        },
      );
    }
  }

  modifyId(id) {
    // below to maintain similarity with server id
    const envName =
      environment.envName === "development" ? "dev" : environment.envName;
    return environment.envName !== "prod" ? id + "-" + `${envName}` : id;
  }

  resetAnalyticsForUser(isAdmin) {
    if (aptrinsic && !isAdmin) {
      aptrinsic("reset");
    }
  }
}
