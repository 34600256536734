import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/custom-list.action";

import { IListData } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import {
  IAttachedListing,
  IAttachedListings,
  ICustomListCounter,
} from "../../interfaces";

import { LIST_STATUS_ID } from "@shared/constants/data/list-statuses";
import {
  LIST_RECORDS_TYPES,
  LIST_RELATE_TO_PERSONNEL_TYPE,
} from "@shared/constants/data/list-types";

export interface ICustomListState {
  list: IListData | null;

  loading: boolean;
  error: IServerError | null;

  deleting: boolean;
  deleteError: IServerError | null;

  renaming: boolean;
  renameError: IServerError | null;
}

const initialState: ICustomListState = {
  list: null,

  loading: false,
  error: null,

  deleting: false,
  deleteError: null,

  renaming: false,
  renameError: null,
};

// get list
const getCustomList: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getCustomListError: OnReducer<
  ICustomListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getCustomListSuccess: OnReducer<
  ICustomListState,
  ActionType<Payload<any>>
> = (state: ICustomListState, { payload }: Payload<any>) => ({
  ...state,
  list: { ...payload },
  loading: !!payload.listingCountsCalculating,
});

// reload custom list
const reloadCustomList: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const reloadCustomListError: OnReducer<
  ICustomListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const reloadCustomListSuccess: OnReducer<
  ICustomListState,
  ActionType<Payload<any>>
> = (state: ICustomListState, { payload }: Payload<any>) => ({
  ...state,
  list: { ...payload },
  loading: false,
});

// delete
const deleteCustomList: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  deleting: true,
  deleteError: null,
});

const deleteCustomListError: OnReducer<
  ICustomListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomListState, { payload }: Payload<IServerError>) => ({
  ...state,
  deleting: false,
  deleteError: { ...payload },
});

const deleteCustomListSuccess: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  list: null,
  deleting: false,
  deleteError: null,
});

// rename
const renameCustomList: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  loading: true,
  renaming: true,
  renameError: null,
});

const renameCustomListError: OnReducer<
  ICustomListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  renaming: false,
  renameError: { ...payload },
});

const renameCustomListSuccess: OnReducer<
  ICustomListState,
  ActionType<Payload<any>>
> = (state: ICustomListState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  list: { ...payload },
  renaming: false,
  renameError: null,
});

const renameCustomListCancel: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  renaming: false,
  renameError: null,
});

// apply
const applyCustomList: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const applyCustomListError: OnReducer<
  ICustomListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const applyCustomListSuccess: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  loading: false,
});

const changesCustomListStart: OnReducer<ICustomListState, ActionType<any>> = (
  state: ICustomListState,
) => ({
  ...state,
  loading: true,
});

const updateCustomListState: OnReducer<
  ICustomListState,
  ActionType<Payload<any>>
> = (state: ICustomListState, { payload }: Payload<any>) => ({
  ...state,
  list: { ...state.list, ...payload },
});

const reducer: ActionReducer<ICustomListState> = createReducer(
  initialState,

  on(actions.getCustomListAction, getCustomList),
  on(actions.getCustomListErrorAction, getCustomListError),
  on(actions.getCustomListSuccessAction, getCustomListSuccess),

  on(actions.reloadCustomListAction, reloadCustomList),
  on(actions.reloadCustomListErrorAction, reloadCustomListError),
  on(actions.reloadCustomListSuccessAction, reloadCustomListSuccess),

  on(actions.deleteCustomListAction, deleteCustomList),
  on(actions.deleteCustomListErrorAction, deleteCustomListError),
  on(actions.deleteCustomListSuccessAction, deleteCustomListSuccess),

  on(actions.renameCustomListAction, renameCustomList),
  on(actions.renameCustomListErrorAction, renameCustomListError),
  on(actions.renameCustomListSuccessAction, renameCustomListSuccess),
  on(actions.renameCustomListCancelAction, renameCustomListCancel),

  on(actions.applyCustomListAction, applyCustomList),
  on(actions.applyCustomListErrorAction, applyCustomListError),
  on(actions.applyCustomListSuccessAction, applyCustomListSuccess),

  on(actions.changesCustomListStartAction, changesCustomListStart),

  on(actions.updateCustomListStateAction, updateCustomListState),
);

export function customListReducer(
  state: ICustomListState,
  action: Action,
): ICustomListState {
  return reducer(state, action);
}

export const customList: GetFromState<IListData, ICustomListState> = (
  state: ICustomListState,
): IListData => state && state.list;
export const listId: GetFromState<number, IListData> = (
  list: IListData,
): number => list && list.id;
export const isRum: GetFromState<boolean, IListData> = (
  list: IListData,
): boolean => (list && list.isRum ? (list.isRum === 1 ? true : false) : false);
export const isRumProcessing: GetFromState<boolean, IListData> = (
  list: IListData,
): boolean => (list && list.isProcessing ? list.isProcessing : false);
export const listRecordTypeId: GetFromState<number, IListData> = (
  list: IListData,
): number => list && list.recordsType && list.recordsType.id;
export const isRelateToPersonnelListType: GetFromState<
  boolean,
  LIST_RECORDS_TYPES
> = (recordsTypeId: LIST_RECORDS_TYPES): boolean =>
  LIST_RELATE_TO_PERSONNEL_TYPE.includes(recordsTypeId);
export const listIsOwner: GetFromState<boolean, IListData> = (
  list: IListData,
): boolean => list && list.isOwner;
export const listName: GetFromState<string, IListData> = (
  list: IListData,
): string => list && list.name;
export const listCounts: GetFromState<ICustomListCounter | null, IListData> = (
  list: IListData,
): ICustomListCounter | null => {
  if (list) {
    const {
      statesCount,
      recordsType,
      personsCount,
      institutionsCount,
    }: IListData = list;

    return {
      statesCount,
      recordsType,
      personsCount,
      institutionsCount,
    };
  }

  return null;
};
export const isHasDetailsCount: GetFromState<boolean, IListData> = (
  list: IListData,
): boolean => {
  const count: number =
    list && LIST_RELATE_TO_PERSONNEL_TYPE.includes(list.recordsType.id)
      ? list.personsCount
      : list.institutionsCount;
  return count > 0;
};
export const loading: GetFromState<boolean, ICustomListState> = (
  state: ICustomListState,
): boolean => state && state.loading;
export const renameError: GetFromState<IServerError, ICustomListState> = (
  state: ICustomListState,
): IServerError => state && state.renameError;

export const isShowApplyBtn: GetFromState<boolean, IListData> = (
  list: IListData,
): boolean => {
  const activeLists: IAttachedListings =
    list &&
    list.attachedListings &&
    list.attachedListings.filter(
      (item: IAttachedListing) => item.status === LIST_STATUS_ID.ACTIVE,
    );
  return activeLists && !!activeLists.length;
};
export const isCanApply: GetFromState<boolean, IListData> = (
  list: IListData,
): boolean => list && list.canBeApplied;
