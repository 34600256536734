import { IUserAddress } from "@modules/auth/interfaces/user";

export interface IServerError {
  message: string;
  errors: IServerMessages;
}

export interface IServerMessages {
  [key: string]: string[];
}

export interface IMultiCodesError {
  message: string;
  errors: { [key: string]: string };
}

export class AddressError {
  constructor(
    readonly errorMessages: string[],
    readonly suspectAddress?: IUserAddress,
  ) {
    if (!errorMessages || (errorMessages && !errorMessages.length)) {
      console.error("empty address validation error");
    }

    console.error(errorMessages.join(". "));
  }
}
