import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";

import { CoreState } from "@core/store/reducers";
import { userHistoryAction } from "@modules/profile/store/actions/account-users.action";
import { getUserHistory } from "@modules/profile/store/selectors/account-users.selector";

@Component({
  selector: "bl-user-history",
  templateUrl: "./user-history.component.html",
  styleUrls: ["./user-history.component.scss"],
})
export class UserHistoryComponent implements OnInit {
  products$: Observable<any> = this._store.pipe(select(getUserHistory));

  constructor(private _store: Store<CoreState>) {}

  ngOnInit(): void {
    this._store.dispatch(userHistoryAction());
  }
}
