import { IControlOptions } from "@shared/interfaces/forms";

import { SEGMENT_TYPES } from "@modules/cdl/cdl-list/constants";

export enum FILE_TYPE {
  INSTITUTIONS = 1,
  PERSONNEL = 2,
  FLAT = 6,
}

export const FLAT_FILE_BY_ACTIVE_TAB: Readonly<{ [key: string]: FILE_TYPE }> =
  Object.freeze({
    [SEGMENT_TYPES.INSTITUTIONS]: FILE_TYPE.INSTITUTIONS,
    [SEGMENT_TYPES.PERSONNEL]: FILE_TYPE.PERSONNEL,
  });

export enum COLUMN_TYPE {
  excluded = "excluded",
  included = "included",
}

export enum VIEW_AS {
  TRADITIONAL = 1,
  FRIENDLY,
}

export enum FIELD_TYPES {
  String = "String",
  Integer = "Integer",
}

export const FIELD_TYPES_MAP: { [key: string]: any } = Object.freeze({
  [FIELD_TYPES.String]: "Text",
  [FIELD_TYPES.Integer]: "Number",
});

export const VIEW_AS_OPTIONS: IControlOptions = [
  {
    label: "Friendly",
    value: VIEW_AS.FRIENDLY,
  },
  {
    label: "Traditional",
    value: VIEW_AS.TRADITIONAL,
  },
];

export const DETAILED_OUTPUT_OPTIONS: IControlOptions = [
  {
    label: "Name-only",
    value: false,
  },
  {
    label: "Detail",
    value: true,
  },
];
