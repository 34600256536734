import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-perssonel-districts.action";

import { IPersonnelRecords } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { PERSONNEL_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IViewResultsPersonnelDistrictsState {
  districts: IPersonnelRecords;
  pending: boolean;
  error: IServerError | null;

  selectedDistrictsIds: number[];

  count: number;
}

const initialState: IViewResultsPersonnelDistrictsState = {
  districts: [],
  pending: false,
  error: null,

  selectedDistrictsIds: [],

  count: 0,
};

const getViewResultsPersonnelDistricts: OnReducer<
  IViewResultsPersonnelDistrictsState,
  ActionType<any>
> = (state: IViewResultsPersonnelDistrictsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsPersonnelDistrictsError: OnReducer<
  IViewResultsPersonnelDistrictsState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsPersonnelDistrictsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsPersonnelDistrictsSuccess: OnReducer<
  IViewResultsPersonnelDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsPersonnelDistrictsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  districts: [...payload.items],
  count: payload.count,
});

const toggleViewResultsPersonnelDistrict: OnReducer<
  IViewResultsPersonnelDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsPersonnelDistrictsState,
  { payload: { id } }: Payload<any>,
) => {
  const _setArray: Set<number> = new Set(state.selectedDistrictsIds);

  _setArray.has(id) ? _setArray.delete(id) : _setArray.add(id);

  return {
    ...state,
    selectedDistrictsIds: Array.from(_setArray),
  };
};

const setViewResultsPersonnelDistrictsSpinner: OnReducer<
  IViewResultsPersonnelDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsPersonnelDistrictsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetSelectedPersonnelDistricts: OnReducer<
  IViewResultsPersonnelDistrictsState,
  ActionType<any>
> = (state: IViewResultsPersonnelDistrictsState) => ({
  ...state,
  selectedDistrictsIds: [],
});

const resetViewResultsPersonnelDistrictsPage: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsPersonnelDistrictsState> =
  createReducer<IViewResultsPersonnelDistrictsState>(
    initialState,

    on(
      actions.getViewResultsPersonnelDistrictsAction,
      getViewResultsPersonnelDistricts,
    ),
    on(
      actions.getViewResultsPersonnelDistrictsErrorAction,
      getViewResultsPersonnelDistrictsError,
    ),
    on(
      actions.getViewResultsPersonnelDistrictsSuccessAction,
      getViewResultsPersonnelDistrictsSuccess,
    ),

    on(
      actions.toggleViewResultsPersonnelDistrictAction,
      toggleViewResultsPersonnelDistrict,
    ),

    on(
      actions.setViewResultsPersonnelDistrictsSpinnerAction,
      setViewResultsPersonnelDistrictsSpinner,
    ),

    on(
      actions.resetSelectedPersonnelDistrictsAction,
      resetSelectedPersonnelDistricts,
    ),

    on(
      actions.resetViewResultsPersonnelDistrictsPageState,
      resetViewResultsPersonnelDistrictsPage,
    ),
  );

export function viewResultsPersonnelDistrictReducer(
  state: IViewResultsPersonnelDistrictsState,
  action: Action,
): IViewResultsPersonnelDistrictsState {
  return reducer(state, action);
}

export const districts: GetFromState<
  IPersonnelRecords,
  IViewResultsPersonnelDistrictsState
> = (state: IViewResultsPersonnelDistrictsState): IPersonnelRecords =>
  state.districts;
export const pending: GetFromState<
  boolean,
  IViewResultsPersonnelDistrictsState
> = (state: IViewResultsPersonnelDistrictsState): boolean => state.pending;

export const districtsPaginationCount: GetFromState<
  number,
  IViewResultsPersonnelDistrictsState
> = (state: IViewResultsPersonnelDistrictsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const selectedDistrictsIds: GetFromState<
  number[],
  IViewResultsPersonnelDistrictsState
> = (state: IViewResultsPersonnelDistrictsState): number[] =>
  state.selectedDistrictsIds;

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const { sortBy }: Params = queryParams;

  const params: Params = {
    ...PERSONNEL_DEFAULT_PARAMS,
    ...queryParams,
    sortBy: sortBy || "lastName",
    viewId,
    filterBy: "districts",
  };

  return segmentId ? { ...params, segmentId } : params;
};
