import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { getIsLoggedIn } from "../../../auth/store/selectors/auth.selector";
import {
  canCheckout,
  cartGrandTotal,
  cartItems,
  cartItemsForValidation,
  cartItemsQuantity,
  cartSubTotalPrice,
  cartTotalCredits,
  cartTotalPriceWithTax,
  cartValidationError,
  itemsIsUpdating,
  sortedCartItems,
  tax,
  taxAmount,
  taxPending,
  updatingProduct,
  validateCartItemsForOrder,
  ICartState,
} from "../reducers/cart.reducer";

import {
  ICartItemForOrder,
  IProductUpdate,
  IValidateProduct,
} from "@modules/e-commerce/interfaces/e-commerce";
import {
  IPrices,
  ISalesOrderTaxData,
} from "@modules/e-commerce/interfaces/price";
import { IServerError } from "@shared/interfaces/server-error";

export const getECommerceCartState: GetFromStateInSelectors<
  CoreState,
  ICartState
> = (state: CoreState) => state.eCommerceCart;

export const getCartItems: MemoizedSelector<CoreState, IPrices> =
  createSelector(getECommerceCartState, cartItems);
export const getStorageCartItems: MemoizedSelector<CoreState, any> =
  createSelector(getCartItems, sortedCartItems);

export const getTax: MemoizedSelector<CoreState, ISalesOrderTaxData> =
  createSelector(getECommerceCartState, tax);
export const getTaxPending: MemoizedSelector<CoreState, boolean> =
  createSelector(getECommerceCartState, taxPending);
export const getTaxAmount: MemoizedSelector<CoreState, number> = createSelector(
  getTax,
  taxAmount,
);
export const getValidateCartItemsForOrder: MemoizedSelector<
  CoreState,
  ICartItemForOrder[]
> = createSelector(getCartItems, getTax, validateCartItemsForOrder);

export const getIsCartItemsUpdating: MemoizedSelector<CoreState, boolean> =
  createSelector(getECommerceCartState, itemsIsUpdating);
export const getUpdatingProduct: MemoizedSelector<CoreState, IProductUpdate> =
  createSelector(getECommerceCartState, updatingProduct);

export const getCartItemsQuantity: MemoizedSelector<CoreState, number> =
  createSelector(getCartItems, cartItemsQuantity);
export const getCartSubTotalPrice: MemoizedSelector<CoreState, number> =
  createSelector(getCartItems, cartSubTotalPrice);
export const getCartTotalPriceWithTax: MemoizedSelector<CoreState, number> =
  createSelector(getCartItems, getTaxAmount, cartTotalPriceWithTax);
export const getCartGrandTotal: MemoizedSelector<CoreState, number> =
  createSelector(getTaxAmount, getCartSubTotalPrice, cartGrandTotal);

export const getCartTotalCredits: MemoizedSelector<CoreState, number> =
  createSelector(getCartItems, cartTotalCredits);

export const getCanCheckout: MemoizedSelector<CoreState, boolean> =
  createSelector(getIsLoggedIn, getCartItemsQuantity, canCheckout);

export const getCartValidationError: MemoizedSelector<CoreState, IServerError> =
  createSelector(getECommerceCartState, cartValidationError);
export const getCartItemsForValidation: MemoizedSelector<
  CoreState,
  IValidateProduct[]
> = createSelector(getECommerceCartState, cartItemsForValidation);
