import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";

import { environment } from "@env/environment";

import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";

import { IHtmlPageData } from "../interfaces/terms-of-servise";

@Injectable()
export class PrivacyPolicyService {
  constructor(private http: HttpClient) {}

  loadPrivacyPolicyData(): Observable<IHtmlPageData<SafeHtml>> {
    return this.http
      .get(environment.api.privacyPolicy.data)
      .pipe(pluck("data"));
  }
}
