import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { RouterModule } from "@angular/router";
import { StoreModule } from "@ngrx/store";

import { NOTIFICATION_NAME, NOTIFICATION_STATE } from "./store/reducers";

import { DirectivesModule } from "@shared/modules/directives/directives.module";
import { StickyModule } from "@ui/sticky/sticky.module";
import { TooltipModule } from "@ui/tooltip/tooltip.module";

import { NotificationContainerComponent } from "./containers/notification-container/notification-container.component";

import { MobileNotificationComponent } from "./components/mobile-notification/mobile-notification.component";
import { NotificationComponent } from "./components/notification/notification.component";

const _components: any[] = [MobileNotificationComponent, NotificationComponent];

const _containers: any[] = [NotificationContainerComponent];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatRippleModule,
    TooltipModule,
    StickyModule,
    StoreModule.forFeature(NOTIFICATION_NAME, NOTIFICATION_STATE),
    DirectivesModule,
  ],
  declarations: [..._components, ..._containers],
  exports: [..._components, ..._containers],
  providers: [],
})
export class NotificationsModule {}
