import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";

import {
  SPINNER_MODULE_FEATURE_NAME,
  SPINNER_MODULE_FEATURE_STATE,
} from "./store/reducers";

import { SpinnerContainerComponent } from "./containers/spinner-container/spinner-container.component";

import { CustomSpinnerComponent } from "./components/custom-spinner/custom-spinner.component";
import { SpinnerComponent } from "./components/spinner/spinner.component";

import { BtnSpinnerDirective } from "./directives/btn-spinner.directive";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";

const imports: any[] = [
  CommonModule,
  StoreModule.forFeature(
    SPINNER_MODULE_FEATURE_NAME,
    SPINNER_MODULE_FEATURE_STATE,
  ),
  IndicatorsModule,
];

const declarations: any[] = [
  SpinnerContainerComponent,
  SpinnerComponent,
  BtnSpinnerDirective,
  CustomSpinnerComponent,
];

const exports: any[] = [...declarations];

@NgModule({
  imports,
  declarations,
  exports,
})
export class SpinnerModule {}
