import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";

import { environment } from "@env/environment";

import { defer, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

import { showNotificationAction } from "@modules/notifications/store/actions/notification.action";
import { getAccountUsersAction } from "@modules/profile/store/actions/account-users.action";
import {
  getProfileAction,
  setIsBrokerAction,
} from "@modules/profile/store/actions/profile.action";
import { ActionWithPayload } from "@shared/interfaces/store";
import * as actions from "../actions/common-errors.action";
import { go } from "../actions/router-history.action";
import {
  redirectToTosAction,
  setToUBrokerStatusAction,
  setTOSStatusAction,
} from "../actions/tos.action";
import { CoreState } from "../reducers";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IError } from "../../interfaces";

import { CORE_PATHS } from "../../constants/core-paths";
import { NOTIFICATION_TYPES } from "../../constants/notifications";
import {
  BROKER_PERMISSIONS_TOS_ERRORS,
  PERMISSIONS_ACCESS_SECTION_ERRORS,
  PERMISSIONS_MSG,
  PERMISSIONS_ONLY_FOR_INTERNAL_USERS,
  PERMISSIONS_SEATS_ERRORS,
  PERMISSIONS_TOS_ERRORS,
} from "../../constants/permissions";

@Injectable()
export class CommonErrorsEffects {
  private timeoutDuration: number = 3000;

  constructor(
    private actions$: Actions,
    private store: Store<CoreState>,
  ) {}

  setPermissionsError$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this.actions$.pipe(
          ofType(actions.setPermissionsErrorAction),
          map(({ type, payload }: ActionWithPayload<IError>) => payload),
          tap((error: IError) => {
            const { message }: IError = error;

            if (PERMISSIONS_ACCESS_SECTION_ERRORS.includes(message)) {
              this.store.dispatch(getProfileAction());
              this.store.dispatch(getAccountUsersAction());
              this.store.dispatch(
                showNotificationAction({
                  message: PERMISSIONS_MSG.ADMIN_CHANGE_PERMISSIONS,
                  type: NOTIFICATION_TYPES.SUCCESS,
                  timeout: this.timeoutDuration,
                  canClose: true,
                }),
              );

              this.store.dispatch(go(["/", CORE_PATHS.DASHBOARD]));
            }

            if (PERMISSIONS_SEATS_ERRORS.includes(message)) {
              this.store.dispatch(getProfileAction());
              this.store.dispatch(
                showNotificationAction({
                  message: PERMISSIONS_MSG.DO_NOT_HAVE_SEAT,
                  type: NOTIFICATION_TYPES.ERROR,
                  timeout: this.timeoutDuration,
                  canClose: true,
                }),
              );
            }

            if (PERMISSIONS_TOS_ERRORS.includes(message)) {
              if (!environment.production) {
                console.log(
                  "TOS is unaccepted - set current status and redirect to Accept Tos Page",
                );
              }

              this.store.dispatch(setTOSStatusAction(false));
              this.store.dispatch(redirectToTosAction({ url: error.url }));
            }

            if (BROKER_PERMISSIONS_TOS_ERRORS.includes(message)) {
              if (!environment.production) {
                console.log(
                  "ToU is unaccepted by Broker - set current status and redirect to Accept Tos Page",
                );
              }

              this.store.dispatch(setIsBrokerAction(true));
              this.store.dispatch(setToUBrokerStatusAction(false));

              this.store.dispatch(
                go(["/", CORE_PATHS.COMMON, CORE_PATHS.TERMS_OF_USE]),
              );
            }

            if (PERMISSIONS_ONLY_FOR_INTERNAL_USERS.includes(message)) {
              this.store.dispatch(
                showNotificationAction({
                  message: message,
                  type: NOTIFICATION_TYPES.ERROR,
                  timeout: this.timeoutDuration,
                  canClose: true,
                }),
              );
            }
          }),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );
}
