import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-institution-all.action";

import { IInstitutionsRecords } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { INSTITUTIONS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";
import { CREATE_VIEW_LIST_TYPE } from "../../constants";

export interface IViewResultsAllInstitutionsState {
  institutions: IInstitutionsRecords;
  pending: boolean;
  error: IServerError | null;
  count: number;
}

const initialState: IViewResultsAllInstitutionsState = {
  institutions: [],
  pending: false,
  error: null,
  count: 0,
};

const getViewResultsAllInstitutionAll: OnReducer<
  IViewResultsAllInstitutionsState,
  ActionType<any>
> = (state: IViewResultsAllInstitutionsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsAllInstitutionAllError: OnReducer<
  IViewResultsAllInstitutionsState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsAllInstitutionsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsAllInstitutionAllSuccess: OnReducer<
  IViewResultsAllInstitutionsState,
  ActionType<Payload<any>>
> = (state: IViewResultsAllInstitutionsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  institutions: [...payload.items],
  count: payload.count,
});

const setViewResultsInstitutionAllSpinnerState: OnReducer<
  IViewResultsAllInstitutionsState,
  ActionType<Payload<any>>
> = (state: IViewResultsAllInstitutionsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsAllInstitutionState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsAllInstitutionsState> =
  createReducer<IViewResultsAllInstitutionsState>(
    initialState,

    on(
      actions.getViewResultsAllInstitutionAllAction,
      getViewResultsAllInstitutionAll,
    ),
    on(
      actions.getViewResultsAllInstitutionAllErrorAction,
      getViewResultsAllInstitutionAllError,
    ),
    on(
      actions.getViewResultsAllInstitutionAllSuccessAction,
      getViewResultsAllInstitutionAllSuccess,
    ),

    on(
      actions.setViewResultsInstitutionAllSpinnerStateAction,
      setViewResultsInstitutionAllSpinnerState,
    ),

    on(
      actions.resetViewResultsAllInstitutionStateAction,
      resetViewResultsAllInstitutionState,
    ),
  );

export function viewResultsAllInstitutionReducer(
  state: IViewResultsAllInstitutionsState,
  action: Action,
): IViewResultsAllInstitutionsState {
  return reducer(state, action);
}

export const allInstitutions: GetFromState<
  IInstitutionsRecords,
  IViewResultsAllInstitutionsState
> = (state: IViewResultsAllInstitutionsState): IInstitutionsRecords =>
  state.institutions;
export const allInstitutionsError: GetFromState<
  IServerError,
  IViewResultsAllInstitutionsState
> = (state: IViewResultsAllInstitutionsState): IServerError => state.error;
export const allInstitutionsPending: GetFromState<
  boolean,
  IViewResultsAllInstitutionsState
> = (state: IViewResultsAllInstitutionsState): boolean => state.pending;
export const allInstitutionsCount: GetFromState<
  number,
  IViewResultsAllInstitutionsState
> = (state: IViewResultsAllInstitutionsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const params: Params = {
    ...INSTITUTIONS_DEFAULT_PARAMS,
    ...queryParams,
    viewId,
    filterBy: "all",
  };

  return segmentId ? { ...params, segmentId } : params;
};

export const selectedAllInstitutionsIds: GetFromState<
  number[],
  number[],
  number[],
  number[]
> = (districts: number[], schools: number[], colleges: number[]): number[] => {
  return [...districts, ...schools, ...colleges];
};

export const currentRecordOrAllRecords: GetFromState<
  IInstitutionsRecords,
  IViewResultsAllInstitutionsState,
  IInstitutionsRecords,
  CREATE_VIEW_LIST_TYPE
> = (
  state: IViewResultsAllInstitutionsState,
  currentRecords: IInstitutionsRecords,
  type: CREATE_VIEW_LIST_TYPE,
): IInstitutionsRecords => {
  return type === CREATE_VIEW_LIST_TYPE.INSTITUTION_ALL
    ? state.institutions
    : currentRecords;
};
