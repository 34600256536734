import {
  forwardRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ThemePalette } from "@angular/material/core";
import { ProgressBarMode } from "@angular/material/progress-bar";

import { autoscroll } from "@shared/utils/autoscroll";
import { matchKey } from "@shared/utils/keyboard";

import { ICriteriaValues } from "@shared/interfaces/forms";
import { KEY_CODES } from "@shared/interfaces/keyboard";
import { Mask } from "@shared/interfaces/mask";

import {
  FLAT_INPUT_THEME,
  INPUT_AUTOCOMPLETE,
} from "@shared/constants/flat-input";
import { KEYS } from "@shared/constants/keyboard";
import { SECURITY_TYPE } from "@shared/constants/log-rocket-config";
import { VALIDATION_REG_EXP } from "@shared/constants/validators/validation-reg-exp";

import { PasswordPolicyComponent } from "../password-policy/password-policy.component";

@Component({
  selector: "bl-flat-input",
  templateUrl: "./flat-input.component.html",
  styleUrls: ["./flat-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatInputComponent),
      multi: true,
    },
  ],
})
export class FlatInputComponent
  implements ControlValueAccessor, OnChanges, AfterViewInit
{
  @HostBinding("class.validation-on-dirty")
  get isKeyUpValidation(): boolean {
    return this.validationOnDirty;
  }

  @ViewChild("passWordPolicyElement")
  private passWordPolicyElement: PasswordPolicyComponent;

  @Input() formControlName: string;
  @Input() name: string;
  @Input() placeholder: string;
  @Input() autocomplete: INPUT_AUTOCOMPLETE = INPUT_AUTOCOMPLETE.ON;
  @Input() readonly: boolean = false;
  @Input() type: "text" | "email" | "password" = "text";
  @Input() errors: { [key: string]: any };
  @Input() resetBtn: boolean;
  @Input() mask: Mask;
  @Input() dispatchMaskedValue: boolean = false;
  @Input() customErrors: string;
  @Input() inputLabel: string;
  @Input() theme: FLAT_INPUT_THEME = FLAT_INPUT_THEME.DEFAULT;
  @Input() isAutoFocus: boolean = false;
  @Input() validationOnDirty: boolean = false;
  @Input() shouldHideErrorBox: boolean = false;

  // below attributes needed for passwordPolicy of password fields
  @Input() passwordPolicy: boolean = false;

  @Output() flatnput: EventEmitter<string> = new EventEmitter();
  @Output() focusFlatInput: EventEmitter<string> = new EventEmitter();
  @Output() blurFlatInput: EventEmitter<string> = new EventEmitter();
  @Output() submitFlatInput: EventEmitter<string> = new EventEmitter();
  @Output() resetFlatInputValue: EventEmitter<any> = new EventEmitter();
  @Output() arrowUpFlatInput: EventEmitter<KeyboardEvent> =
    new EventEmitter<KeyboardEvent>();
  @Output() arrowDownFlatInput: EventEmitter<KeyboardEvent> =
    new EventEmitter<KeyboardEvent>();

  @ViewChild("input", { static: true }) inputEl: ElementRef;
  @ViewChild("label", { static: true }) label: ElementRef;

  isFocused: boolean = false;
  error: string = "";

  get isLabelActive(): boolean {
    return this.isFocused || !!this.maskedValue;
  }

  get valueForDispatch(): string {
    return this.dispatchMaskedValue ? this.maskedValue : this.rawValue;
  }

  get placeHolder(): string {
    if (this.isFocused && this.inputLabel && !!this.inputLabel.length) {
      return this.inputLabel;
    }

    if (this.theme === this.inputTheme.SIMPLE && !!this.placeholder.length) {
      return this.placeholder;
    }

    return "";
  }

  readonly inputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;
  readonly securityType: typeof SECURITY_TYPE = SECURITY_TYPE;

  private rawValue: string = "";
  private maskedValue: string = "";

  private shouldScrollToError: boolean = false;

  private propagateChange: Function;
  private propagateTouch: Function;

  constructor(
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private elemRef: ElementRef,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.errors) {
      const errors: any = changes.errors.currentValue;

      if (errors) {
        this.error = errors.required || Object.values(errors)[0];
        // removing below defaults to the original order of errors shown in password fields(only signUp/change password cases)
        if (errors.confirm && errors.password) {
          this.error = errors.confirm;
        }
        if (this.shouldScrollToError) {
          this.scrollToError();
        }
      } else {
        this.error = null;

        if (!changes.errors.firstChange) {
          this.shouldScrollToError = true;
        }
      }

      if (!this.cdr["destroyed"]) {
        this.cdr.detectChanges();
      }
    }

    if (changes.customErrors) {
      changes.customErrors.currentValue
        ? this.renderer.addClass(
            this.elemRef.nativeElement,
            "has-custom-errors",
          )
        : this.renderer.removeClass(
            this.elemRef.nativeElement,
            "has-custom-errors",
          );
    }
  }

  ngAfterViewInit(): void {
    this.inputEl.nativeElement.addEventListener(
      "animationstart",
      (e: AnimationEvent) => {
        switch (e.animationName) {
          case "onAutoFillStart":
            return this.renderer.addClass(this.label.nativeElement, "autofill");
          case "onAutoFillCancel":
            return this.renderer.removeClass(
              this.label.nativeElement,
              "autofill",
            );
        }
      },
    );
  }

  // event handlers
  handleBlur(): void {
    if (!this.readonly) {
      this.isFocused = false;
      this.shouldScrollToError = true;

      this.blurFlatInput.emit(this.valueForDispatch);
      this.propagateTouch();

      this.customErrors = null;
      this.renderer.removeClass(
        this.elemRef.nativeElement,
        "has-custom-errors",
      );
    }
  }

  handleFocus(): void {
    if (!this.readonly) {
      this.isFocused = true;
      this.focusFlatInput.emit(this.valueForDispatch);
      if (!this.cdr["destroyed"]) {
        this.cdr.detectChanges();
      }
    }
  }

  handleChange(value: string): void {
    if (this.passwordPolicy) {
      this.passWordPolicyElement.passwordRequirements(value);
    }
    this.rawValue = this.mask ? this.mask.parse(value, this.rawValue) : value;
    this.maskedValue = this.mask
      ? this.mask.transform(this.rawValue)
      : this.rawValue;

    this.renderer.setProperty(
      this.inputEl.nativeElement,
      "value",
      this.maskedValue,
    );

    this.propagateChange(this.valueForDispatch);
    this.flatnput.emit(this.valueForDispatch);
  }

  onKeyUp(event: KeyboardEvent): void {
    if (matchKey(event, KEYS.ENTER)) {
      this.submitFlatInput.emit(this.valueForDispatch);
    }
  }

  handlerKeyDown(event: KeyboardEvent): void {
    switch (event.code) {
      case KEY_CODES.ArrowUp:
        this._handlerArrowUp(event);
        break;
      case KEY_CODES.ArrowDown:
        this._handlerArrowDown(event);
        break;
    }
  }

  resetValue(): void {
    this.resetFlatInputValue.emit();
  }

  // value accessors methods
  writeValue(value: any): void {
    if (value !== void 0 && value !== null) {
      this.rawValue = value;

      this.maskedValue = this.mask
        ? this.mask.transform(this.rawValue)
        : this.rawValue;
      this.renderer.setProperty(
        this.inputEl.nativeElement,
        "value",
        this.maskedValue,
      );

      // for isLabelActive be correct on prepopulated password fields
      this.cdr.markForCheck();
    }
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.renderer.setProperty(
      this.inputEl.nativeElement,
      "disabled",
      isDisabled,
    );
  }

  triggerFocus(): void {
    (this.inputEl.nativeElement as HTMLElement).focus();
  }

  triggerBlur(): void {
    (this.inputEl.nativeElement as HTMLElement).blur();
  }

  // utils
  private scrollToError(): void {
    const { top }: ClientRect =
      this.inputEl.nativeElement.getBoundingClientRect();

    if (top < 0) {
      autoscroll(top);
    }

    this.shouldScrollToError = false;
  }

  private _handlerArrowUp(event: KeyboardEvent): void {
    this.arrowUpFlatInput.emit(event);
  }

  private _handlerArrowDown(event: KeyboardEvent): void {
    this.arrowDownFlatInput.emit(event);
  }
}
