import {
  ICDLListAppend,
  ICDLListAppends,
  ICDLListAppendField,
  ICDLListAppendFields,
} from "../interfaces";

export const getCDLAllFields: (
  _appends: ICDLListAppends,
) => ICDLListAppendFields = (_appends: ICDLListAppends): ICDLListAppendFields =>
  _appends &&
  _appends.reduce(
    (prev: ICDLListAppendFields = [], append: ICDLListAppend) => [
      ...prev,
      ...append.fields,
    ],
    [],
  );

export const getCDLAllFieldsUnique: (
  appends: ICDLListAppends,
) => ICDLListAppendFields = (
  appends: ICDLListAppends,
): ICDLListAppendFields => [
  ...new Map(
    getCDLAllFields(appends).map((item: ICDLListAppendField) => [
      item.id,
      item,
    ]),
  ).values(),
];
