import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/list-segments.actions";

import { IServerError } from "@shared/interfaces/server-error";

export interface CustomerListSegmentsState {
  creating: boolean;
  createError: IServerError | null;

  deletingId: number | null;
  deleting: boolean;
  deleteError: IServerError | null;

  duplicatingId: number | null;
  duplicating: boolean;
  duplicateError: IServerError | null;

  renamingId: number | null;
  renaming: boolean;
  renameError: IServerError | null;

  settingCapId: number | null;
  settingCap: boolean;
  setCapError: IServerError | null;

  settingBuildingsCapId: number | null;
  settingBuildingsCap: boolean;
  settingBuildingsCapError: IServerError | null;
}

export const initialState: CustomerListSegmentsState = {
  creating: false,
  createError: null,

  deletingId: null,
  deleting: false,
  deleteError: null,

  duplicatingId: null,
  duplicating: false,
  duplicateError: null,

  renamingId: null,
  renaming: false,
  renameError: null,

  settingCapId: null,
  settingCap: false,
  setCapError: null,

  settingBuildingsCapId: null,
  settingBuildingsCap: false,
  settingBuildingsCapError: null,
};

const addListSegment: OnReducer<CustomerListSegmentsState, ActionType<any>> = (
  state: CustomerListSegmentsState,
) => ({
  ...state,
  creating: true,
  createError: null,
});

const addListSegmentError: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<IServerError>>
> = (state: CustomerListSegmentsState, { payload }: Payload<IServerError>) => ({
  ...state,
  creating: false,
  createError: { ...payload },
});

const addListSegmentSuccess: OnReducer<
  CustomerListSegmentsState,
  ActionType<any>
> = (state: CustomerListSegmentsState) => ({
  ...state,
  creating: false,
  createError: null,
});

const deleteListSegment: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<any>>
> = (state: CustomerListSegmentsState, { payload }: Payload<any>) => ({
  ...state,
  deletingId: payload.segmentId,
  deleting: true,
  deleteError: null,
});

const deleteListSegmentError: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<IServerError>>
> = (state: CustomerListSegmentsState, { payload }: Payload<IServerError>) => ({
  ...state,
  deleting: false,
  deleteError: { ...payload },
});

const deleteListSegmentSuccess: OnReducer<
  CustomerListSegmentsState,
  ActionType<any>
> = (state: CustomerListSegmentsState) => ({
  ...state,
  deletingId: null,
  deleting: false,
  duplicateError: null,
});

const duplicateListSegment: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<any>>
> = (state: CustomerListSegmentsState, { payload }: Payload<any>) => ({
  ...state,
  duplicatingId: payload.segmentId,
  duplicating: true,
  duplicateError: null,
});

const duplicateListSegmentError: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<IServerError>>
> = (state: CustomerListSegmentsState, { payload }: Payload<IServerError>) => ({
  ...state,
  duplicating: false,
  duplicateError: { ...payload },
});

const duplicateListSegmentSuccess: OnReducer<
  CustomerListSegmentsState,
  ActionType<any>
> = (state: CustomerListSegmentsState) => ({
  ...state,
  duplicatingId: null,
  duplicating: false,
  duplicateError: null,
});

const renameListSegment: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<any>>
> = (state: CustomerListSegmentsState, { payload }: Payload<any>) => ({
  ...state,
  renamingId: payload.segmentId,
  renaming: true,
  renameError: null,
});

const renameListSegmentError: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<IServerError>>
> = (state: CustomerListSegmentsState, { payload }: Payload<IServerError>) => ({
  ...state,
  renaming: false,
  renameError: { ...payload },
});

const renameListSegmentSuccess: OnReducer<
  CustomerListSegmentsState,
  ActionType<any>
> = (state: CustomerListSegmentsState) => ({
  ...state,
  renamingId: null,
  renaming: false,
  renameError: null,
});

const setCapListSegment: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<any>>
> = (state: CustomerListSegmentsState, { payload }: Payload<any>) => ({
  ...state,
  settingCapId: payload.segmentId,
  settingCap: true,
  setCapError: null,
});

const setCapListSegmentError: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<IServerError>>
> = (state: CustomerListSegmentsState, { payload }: Payload<IServerError>) => ({
  ...state,
  settingCap: false,
  setCapError: { ...payload },
});

const setCapListSegmentSuccess: OnReducer<
  CustomerListSegmentsState,
  ActionType<any>
> = (state: CustomerListSegmentsState) => ({
  ...state,
  settingCapId: null,
  settingCap: false,
  setCapError: null,
});

const setBuildingsSegmentCap: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<any>>
> = (state: CustomerListSegmentsState, { payload }: Payload<any>) => ({
  ...state,
  settingBuildingsCapId: payload.segmentId,
  settingBuildingsCap: false,
  settingBuildingsCapError: null,
});

const setBuildingsSegmentCapError: OnReducer<
  CustomerListSegmentsState,
  ActionType<Payload<IServerError>>
> = (state: CustomerListSegmentsState, { payload }: Payload<IServerError>) => ({
  ...state,
  settingBuildingsCap: false,
  settingBuildingsCapError: { ...payload },
});

const setBuildingsSegmentCapSuccess: OnReducer<
  CustomerListSegmentsState,
  ActionType<any>
> = (state: CustomerListSegmentsState) => ({
  ...state,
  settingBuildingsCapId: null,
  settingBuildingsCap: false,
  settingBuildingsCapError: null,
});

const reducer: ActionReducer<CustomerListSegmentsState> = createReducer(
  initialState,

  on(actions.addListSegmentAction, addListSegment),
  on(actions.addListSegmentErrorAction, addListSegmentError),
  on(actions.addListSegmentSuccessAction, addListSegmentSuccess),

  on(actions.deleteListSegmentAction, deleteListSegment),
  on(actions.deleteListSegmentErrorAction, deleteListSegmentError),
  on(actions.deleteListSegmentSuccessAction, deleteListSegmentSuccess),

  on(actions.duplicateListSegmentAction, duplicateListSegment),
  on(actions.duplicateListSegmentErrorAction, duplicateListSegmentError),
  on(actions.duplicateListSegmentSuccessAction, duplicateListSegmentSuccess),

  on(actions.renameListSegmentAction, renameListSegment),
  on(actions.renameListSegmentErrorAction, renameListSegmentError),
  on(actions.renameListSegmentSuccessAction, renameListSegmentSuccess),

  on(actions.setCapListSegmentAction, setCapListSegment),
  on(actions.setCapListSegmentErrorAction, setCapListSegmentError),
  on(actions.setCapListSegmentSuccessAction, setCapListSegmentSuccess),

  on(actions.setBuildingsSegmentCapAction, setBuildingsSegmentCap),
  on(actions.setBuildingsSegmentCapErrorAction, setBuildingsSegmentCapError),
  on(
    actions.setBuildingsSegmentCapSuccessAction,
    setBuildingsSegmentCapSuccess,
  ),
);

export function customerListSegmentsReducer(
  state: CustomerListSegmentsState,
  action: Action,
): CustomerListSegmentsState {
  return reducer(state, action);
}

export const creating: GetFromState<boolean, CustomerListSegmentsState> = (
  state: CustomerListSegmentsState,
): boolean => state.creating;
export const renameError: GetFromState<
  IServerError | null,
  CustomerListSegmentsState
> = (state: CustomerListSegmentsState): IServerError | null =>
  state.renameError;
