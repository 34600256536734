import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export function sortByFieldAsync<T>(
  fieldName: string,
): (observer: Observable<T[]>) => Observable<T[]> {
  return function (observer: Observable<T[]>): Observable<T[]> {
    return observer.pipe(
      map((array: any[]) =>
        array.sort((a: any, b: any) => {
          if (a[fieldName] === b[fieldName]) {
            return 0;
          }

          if (a[fieldName]) {
            return -1;
          }

          if (b[fieldName]) {
            return 1;
          }
        }),
      ),
    );
  };
}
