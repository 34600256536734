import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../../../list/store/actions/list-appends.action";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IAppendFieldsWithPhones,
  IListAppend,
  IListAppends,
  IListAppendField,
  IListAppendFields,
  IListAppendId,
} from "../../../list-appends/interfaces/appends";

export const DEFAULT_APPEND_ID: string = "All";

export interface IListAppendsState {
  listId: number | null;

  appendsCredits: number;
  appends: IListAppends | null;
  appendsAdditional: IAppendFieldsWithPhones;
  isShowSpinner: boolean;

  selectedId: IListAppendId;

  pending: boolean;
  error: IServerError | null;

  toggledAppendFieldIds: number[];
  toggleAppendFieldError: IServerError | null;
}

const initialState: IListAppendsState = {
  listId: null,

  appendsCredits: 0,
  appends: null,
  appendsAdditional: [],
  isShowSpinner: false,

  selectedId: null,

  pending: false,
  error: null,

  toggledAppendFieldIds: [],
  toggleAppendFieldError: null,
};

const onGetAppends: OnReducer<IListAppendsState, ActionType<Payload<any>>> = (
  state: IListAppendsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  listId: payload,
  pending: true,
  error: null,

  toggledAppendFieldIds: [],
  toggleAppendFieldError: null,
});

const onGetAppendsAdditionalError: OnReducer<
  IListAppendsState,
  ActionType<Payload<IServerError>>
> = (state: IListAppendsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const onGetAppendSuccess: OnReducer<any, ActionType<Payload<any>>> = (
  state: IListAppendsState,
  { payload: { items, appendsCredits } }: Payload<any>,
) => ({
  ...state,
  pending: false,
  selectedId: state.selectedId ? state.selectedId : DEFAULT_APPEND_ID,
  appends: items,
  appendsCredits: appendsCredits,
  isShowSpinner: false,
});

const onGetAppendsAdditional: OnReducer<
  IListAppendsState,
  ActionType<Payload<any>>
> = (state: IListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  pending: true,
  error: null,
});

const onGetAppendsError: OnReducer<
  IListAppendsState,
  ActionType<Payload<IServerError>>
> = (state: IListAppendsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const onGetAppendAdditionalSuccess: OnReducer<
  IListAppendsState,
  ActionType<Payload<any>>
> = (state: IListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  appendsAdditional: [...payload],
});

const setSpinnerHandler: OnReducer<
  IListAppendsState,
  ActionType<Payload<any>>
> = (state: IListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  isShowSpinner: payload,
});

const onSelectAllAppend: OnReducer<any, ActionType<any>> = (state: any) => ({
  ...state,
  selectedId: DEFAULT_APPEND_ID,
});

const onSelectAppend: OnReducer<IListAppendsState, ActionType<Payload<any>>> = (
  state: IListAppendsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  selectedId: payload && payload.id ? payload.id : null,
});

const onToggleAppendField: OnReducer<
  IListAppendsState,
  ActionType<Payload<any>>
> = (state: IListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  toggledAppendFieldIds: [...state.toggledAppendFieldIds, payload.id],
  toggleAppendFieldError: null,
});

const onToggleAppendFieldError: OnReducer<
  IListAppendsState,
  ActionType<Payload<IServerError>>
> = (state: IListAppendsState, { payload }: Payload<IServerError>) => ({
  ...state,
  toggleAppendFieldError: { ...payload },
});

const onToggleAppendFieldSuccess: OnReducer<
  IListAppendsState,
  ActionType<Payload<any>>
> = (state: IListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  toggledAppendFieldIds: state.toggledAppendFieldIds.filter(
    (id: number) => id !== payload.id,
  ),
  appends: state.appends.map((append: IListAppend) => ({
    ...append,
    fields: append.fields.map((field: IListAppendField) =>
      field.id === payload.id ? { ...field, ...payload } : field,
    ),
  })),
});

const includeListAppendFieldWithPhones: OnReducer<
  IListAppendsState,
  ActionType<Payload<any>>
> = (state: IListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  toggledAppendFieldIds: [...state.toggledAppendFieldIds, payload.id],
});

const includeListAppendFieldWithPhonesError: OnReducer<
  IListAppendsState,
  ActionType<Payload<IServerError>>
> = (state: IListAppendsState, { payload }: Payload<IServerError>) => ({
  ...state,
  toggledAppendFieldIds: [],
  error: { ...payload },
});

const includeListAppendFieldWithPhonesSuccess: OnReducer<
  IListAppendsState,
  ActionType<Payload<any>>
> = (state: IListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  toggledAppendFieldIds: payload
    ? state.toggledAppendFieldIds.filter((id: any) => !payload.id === id)
    : [...state.toggledAppendFieldIds],
  appends: state.appends.map((append: IListAppend) => ({
    ...append,
    fields: append.fields.map((field: IListAppendField) =>
      payload.id === field.id ? { ...field, include: true } : field,
    ),
  })),
});

const onResetSelectedAppend: OnReducer<IListAppendsState, ActionType<any>> = (
  state: IListAppendsState,
) => ({
  ...state,
  selectedId: null,
});

const onReset: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IListAppendsState> =
  createReducer<IListAppendsState>(
    initialState,

    on(actions.getListAppendsAction, onGetAppends),
    on(actions.getListAppendsErrorAction, onGetAppendsError),
    on(actions.getListAppendsSuccessAction, onGetAppendSuccess),

    on(actions.getListAppendsAdditionalAction, onGetAppendsAdditional),
    on(
      actions.getListAppendsAdditionalErrorAction,
      onGetAppendsAdditionalError,
    ),
    on(
      actions.getListAppendsAdditionalSuccessAction,
      onGetAppendAdditionalSuccess,
    ),

    on(actions.getAppendsAndSummaryAction, onGetAppends),
    on(actions.getAppendsAndSummaryErrorAction, onGetAppendsError),
    on(actions.getAppendsAndSummarySuccessAction, onGetAppendSuccess),

    on(actions.setSpinner, setSpinnerHandler),

    on(actions.selectAllAppendsAction, onSelectAllAppend),
    on(actions.selectListAppendsAction, onSelectAppend),

    on(actions.toggleListAppendFieldAction, onToggleAppendField),
    on(actions.toggleListAppendFieldErrorAction, onToggleAppendFieldError),
    on(actions.toggleListAppendFieldSuccessAction, onToggleAppendFieldSuccess),

    on(
      actions.includeListAppendFieldWithPhonesAction,
      includeListAppendFieldWithPhones,
    ),
    on(
      actions.includeListAppendFieldWithPhonesErrorAction,
      includeListAppendFieldWithPhonesError,
    ),
    on(
      actions.includeListAppendFieldWithPhonesSuccessAction,
      includeListAppendFieldWithPhonesSuccess,
    ),

    on(actions.resetSelectedAppendAction, onResetSelectedAppend),
    on(actions.resetListAppendsAction, onReset),
  );

export const appendsListId: GetFromState<number, IListAppendsState> = (
  state: IListAppendsState,
): number => state && state.listId;
export const selectedListAppendId: GetFromState<
  IListAppendId,
  IListAppendsState
> = (state: IListAppendsState): IListAppendId => state.selectedId;
export const selectedListAppendName: GetFromState<string, IListAppendsState> = (
  state: IListAppendsState,
): string => {
  if (typeof state.selectedId === "string") {
    return state.selectedId;
  }

  const _found: IListAppend =
    state.appends &&
    state.appends.find((item: IListAppend) => item.id === state.selectedId);
  return _found && _found.name;
};

export const appends: GetFromState<IListAppends, IListAppendsState> = (
  state: IListAppendsState,
): IListAppends => state && state.appends;
export const appendsAdditional: GetFromState<
  IAppendFieldsWithPhones,
  IListAppendsState
> = (state: IListAppendsState): IAppendFieldsWithPhones =>
  state && state.appendsAdditional;
export const isShowSpinner: GetFromState<boolean, IListAppendsState> = (
  state: IListAppendsState,
): boolean => state && state.isShowSpinner;

export const selectedFieldsPrice: GetFromState<number, IListAppendsState> = (
  state: IListAppendsState,
): number => (state && state.appendsCredits) || 0;
export const toggledListAppendFieldIds: GetFromState<
  number[],
  IListAppendsState
> = (state: IListAppendsState): number[] => state.toggledAppendFieldIds;

export const selectedFields: GetFromState<
  IListAppendFields,
  IListAppendFields
> = (fields: IListAppendFields): IListAppendFields =>
  fields && fields.filter((field: IListAppendField) => field.include);

export const boughtFields: GetFromState<
  IListAppendFields,
  IListAppendFields
> = (fields: IListAppendFields): IListAppendFields =>
  fields && fields.filter((field: IListAppendField) => field.isBought);

export const appendsOnlyWithSelectedFields: GetFromState<
  IListAppends,
  IListAppends
> = (_appends: IListAppends): IListAppends =>
  _appends &&
  _appends
    .filter((_append: IListAppend) => !!selectedFields(_append.fields).length)
    .map((_append: IListAppend) => ({
      ..._append,
      fields: selectedFields(_append.fields),
    }));

export const appendsOnlyWithBoughtFields: GetFromState<
  IListAppends,
  IListAppends
> = (_appends: IListAppends): IListAppends =>
  _appends &&
  _appends
    .filter((_append: IListAppend) => !!boughtFields(_append.fields).length)
    .map((_append: IListAppend) => ({
      ..._append,
      fields: boughtFields(_append.fields),
    }));

export const getAllFields: GetFromState<IListAppendFields, IListAppends> = (
  _appends: IListAppends,
): IListAppendFields =>
  _appends &&
  _appends.reduce(
    (prev: any[] = [], append: IListAppend) => [...prev, ...append.fields],
    [],
  );

export const allSelectedFields: GetFromState<
  IListAppendFields,
  IListAppends
> = (_appends: IListAppends): IListAppendFields =>
  selectedFields(getAllFields(_appends));
export const allSelectedFieldsCount: GetFromState<number, IListAppendFields> = (
  fields: IListAppendFields,
): number => {
  if (fields) {
    const result: any = {};
    fields.forEach((field: IListAppendField) => (result[field.id] = field));
    return Object.values(result).length;
  }

  return 0;
};

export const selectedAppendFields: GetFromState<
  IListAppendFields,
  IListAppends,
  IListAppendId
> = (_appends: IListAppends, id: IListAppendId): IListAppendFields => {
  if (_appends && id !== null) {
    return id !== DEFAULT_APPEND_ID
      ? _appends.find((_append: IListAppend) => _append.id === id).fields
      : getAllFields(_appends);
  }

  return null;
};

export const selectedCount: GetFromState<number, IListAppendFields> = (
  _fields: IListAppendFields,
): number =>
  _fields && _fields.filter((field: IListAppendField) => field.include).length;

export const allUnIncludedFields: GetFromState<
  IListAppendFields,
  IListAppends,
  IAppendFieldsWithPhones
> = (
  _appends: IListAppends,
  unIncludedAppendsIds: IAppendFieldsWithPhones,
): IListAppendFields =>
  _appends
    ? getAllFields(_appends).filter(
        (field: IListAppendField) =>
          unIncludedAppendsIds && unIncludedAppendsIds.includes(field.id),
      )
    : [];

export const unIncludedAppendIds: GetFromState<
  IAppendFieldsWithPhones,
  IAppendFieldsWithPhones,
  IListAppendFields
> = (
  _appends: IAppendFieldsWithPhones = [],
  includedAppends: IListAppendFields = [],
): IAppendFieldsWithPhones =>
  _appends
    ? _appends.filter(
        (appendId: number) =>
          includedAppends &&
          !includedAppends.find(
            (includedAppend: IListAppendField) =>
              appendId === includedAppend.id,
          ),
      )
    : [];

export function listAppendsReducer(
  state: IListAppendsState,
  action: Action,
): IListAppendsState {
  return reducer(state, action);
}
