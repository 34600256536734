import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { showNotificationAction } from "@modules/notifications/store/actions/notification.action";

import { AsyncValidatorService } from "@core/services/async-validator.service";
import { PaymentFormService } from "@modules/e-commerce/utils/payment-forms.service";

import { CardFourDigitsMaskPipe } from "@shared/modules/pipes/card-pipes/pipes/card-four-digits-mask.pipe";
import { CardTypeMaskPipe } from "@shared/modules/pipes/card-pipes/pipes/card-type-mask.pipe";
import { LongExpDateMaskPipe } from "@shared/modules/pipes/card-pipes/pipes/long-exp-date-mask.pipe";

import { UserPaymentAddressConverter } from "@shared/utils/payment-mapper";

import { ICheckAddressResponse } from "@modules/auth/interfaces/user";
import {
  ICreditCardInfo,
  IDefaultBillingInfo,
} from "@modules/e-commerce/interfaces/e-commerce";

import { DefaultPaymentMethodPopUpData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";
import { DefaultCreditCardResult } from "../../models/pop-up-result";

import { NOTIFICATION_TYPES } from "@core/constants/notifications";
import { TRANSACTION_FIELD_ERRORS } from "@modules/e-commerce/constants/payment";
import { ACCOUNT_MESSAGES } from "@modules/profile/constants/messages";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-default-payment-method-pop-up-component",
  templateUrl: "./default-payment-method-pop-up-component.component.html",
  styleUrls: ["./default-payment-method-pop-up-component.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DefaultPaymentMethodPopUpComponentComponent implements OnInit {
  cardFourDigitsMask: CardFourDigitsMaskPipe = new CardFourDigitsMaskPipe();
  longExpDateMask: LongExpDateMaskPipe = new LongExpDateMaskPipe();
  cardTypeMask: CardTypeMaskPipe = new CardTypeMaskPipe();

  form: UntypedFormGroup;

  topErrorMsg: string | null;
  invalidFields: string[];
  defaultBillingInfo: IDefaultBillingInfo;
  creditCard: ICreditCardInfo | null;

  constructor(
    @Inject(POP_UP_DATA) public data: DefaultPaymentMethodPopUpData,
    private popUpRef: PopUpRef<
      DefaultPaymentMethodPopUpComponentComponent,
      DefaultCreditCardResult
    >,
    private service: AsyncValidatorService,
    private store: Store<CoreState>,
    private paymentFormService: PaymentFormService,
  ) {}

  ngOnInit(): void {
    const {
      topErrorMsg,
      invalidFields,
      defaultBillingInfo,
      defaultCreditCard,
    }: DefaultPaymentMethodPopUpData = this.data;

    this.topErrorMsg = topErrorMsg || null;
    this.invalidFields = invalidFields || [];
    this.defaultBillingInfo = defaultBillingInfo || null;
    this.creditCard = defaultCreditCard || null;

    this.form = this.paymentFormService.getDefaultPaymentForm({
      ...defaultCreditCard,
    } as ICreditCardInfo);

    this.invalidFields.forEach((field: string) => {
      const control: AbstractControl = this.form.get(field);

      if (control) {
        control.setErrors({
          transactionError: TRANSACTION_FIELD_ERRORS[field] as string,
        });
      }
    });
  }

  submitted(): void {
    const {
      bill_to_forename,
      bill_to_surname,

      bill_to_address_line1,
      bill_to_address_line2,
      bill_to_address_city,
      bill_to_address_state,
      bill_to_address_country,
      bill_to_address_zip,
      bill_to_address_phone,

      ...value
    }: any = this.form.value;

    this.service
      .paymentAddress({
        bill_to_address_line1,
        bill_to_address_line2,
        bill_to_address_city,
        bill_to_address_state,
        bill_to_address_country,
        bill_to_address_zip,
        bill_to_address_phone,
      })
      .subscribe((errorResponse: ICheckAddressResponse) => {
        if (errorResponse) {
          const { errorMessages, suspectAddress }: ICheckAddressResponse =
            errorResponse;

          if (errorMessages && !suspectAddress) {
            this.form.setErrors({ address: errorMessages.join(". ") });
          }

          if (suspectAddress) {
            this.form.patchValue({ ...suspectAddress });
            this.store.dispatch(
              showNotificationAction({
                message: ACCOUNT_MESSAGES.ADDRESS_WAS_CHANGED,
                type: NOTIFICATION_TYPES.INFO,
                timeout: 4000,
                canClose: true,
              }),
            );
          }
        } else {
          this.popUpRef.close({
            answer: true,
            editedDefaultCreditCard: {
              ...value,
              first_name_on_card: bill_to_forename,
              last_name_on_card: bill_to_surname,
              address:
                UserPaymentAddressConverter.convertPaymentToValidationAddress({
                  bill_to_address_line1,
                  bill_to_address_line2,
                  bill_to_address_city,
                  bill_to_address_state,
                  bill_to_address_country,
                  bill_to_address_zip,
                  bill_to_address_phone,
                }),
            } as ICreditCardInfo,
          });
        }
      });
  }

  canceled(): void {
    this.popUpRef.close({
      answer: false,
    });
  }

  getInvalidTransactionFieldError(field: string): string {
    return TRANSACTION_FIELD_ERRORS[field] as string;
  }
}
