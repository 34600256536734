import { SafeHtml } from "@angular/platform-browser";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IHtmlPageData,
  ITermsOfUsePageData,
} from "../../interfaces/terms-of-servise";
import { ITOSPreviousUrl } from "../../interfaces/tos";

enum ACTION_TYPES {
  GET_GENERAL_TERMS = "[TOS] Get General Terms",
  GET_GENERAL_TERMS_SUCCESS = "[TOS] Get General Terms Success",
  GET_GENERAL_TERMS_ERROR = "[TOS] Get General Terms Error",

  RESET_GENERAL_TERMS = "[TOS] Reset General Terms",

  GET_TERMS_OF_USE = "[TOS] Get Terms Of Use",
  GET_TERMS_OF_USE_SUCCESS = "[TOS] Get Terms Of Use Success",
  GET_TERMS_OF_USE_ERROR = "[TOS] Get Terms Of Use Error",

  ACCEPT_TOS = "[TOS] Accept TOS",
  ACCEPT_TOS_SUCCESS = "[TOS] Accept TOS Success",
  ACCEPT_TOS_ERROR = "[TOS] Accept TOS Error",

  ACCEPT_BROKER_TOS = "[TOS] Accept Broker Tos",
  ACCEPT_BROKER_TOS_ERROR = "[TOS] Accept Broker Tos Error",
  ACCEPT_BROKER_TOS_SUCCESS = "[TOS] Accept Broker Tos Success",

  SET_TOS_STATUS = "[TOS] Set TOS Status",
  SET_TOU_BROKER_STATUS = "[TOS] Set TOU Broker Status",

  ON_TOS_PAGE = "[TOS] On Tos Page",
  OUT_TOS_PAGE = "[TOS] Go Out Tos Page",

  REDIRECT_TO_TOS = "[TOS] Redirect to TOS",

  RESET_TOS = "[TOS] Reset TOS state",
}

export const getGeneralTermsAction: ActionCreator<
  ACTION_TYPES.GET_GENERAL_TERMS,
  () => TypedAction<ACTION_TYPES.GET_GENERAL_TERMS>
> = createAction(ACTION_TYPES.GET_GENERAL_TERMS);
export const getGeneralTermsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_GENERAL_TERMS_SUCCESS,
  IHtmlPageData<SafeHtml>
> = createAction(
  ACTION_TYPES.GET_GENERAL_TERMS_SUCCESS,
  createPayload<IHtmlPageData<SafeHtml>>(),
);
export const getGeneralTermsErrorAction: CreateActionType<
  ACTION_TYPES.GET_GENERAL_TERMS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_GENERAL_TERMS_ERROR,
  createPayload<IServerError>(),
);

export const resetGeneralTermsAction: ActionCreator<
  ACTION_TYPES.RESET_GENERAL_TERMS,
  () => TypedAction<ACTION_TYPES.RESET_GENERAL_TERMS>
> = createAction(ACTION_TYPES.RESET_GENERAL_TERMS);

export const getTermsOfUseAction: ActionCreator<
  ACTION_TYPES.GET_TERMS_OF_USE,
  () => TypedAction<ACTION_TYPES.GET_TERMS_OF_USE>
> = createAction(ACTION_TYPES.GET_TERMS_OF_USE);
export const getTermsOfUseErrorAction: CreateActionType<
  ACTION_TYPES.GET_TERMS_OF_USE_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_TERMS_OF_USE_ERROR,
  createPayload<IServerError>(),
);
export const getTermsOfUseSuccessAction: CreateActionType<
  ACTION_TYPES.GET_TERMS_OF_USE_SUCCESS,
  ITermsOfUsePageData<SafeHtml>
> = createAction(
  ACTION_TYPES.GET_TERMS_OF_USE_SUCCESS,
  createPayload<ITermsOfUsePageData<SafeHtml>>(),
);

export const acceptTOSAction: ActionCreator<
  ACTION_TYPES.ACCEPT_TOS,
  () => TypedAction<ACTION_TYPES.ACCEPT_TOS>
> = createAction(ACTION_TYPES.ACCEPT_TOS);
export const acceptTOSErrorAction: CreateActionType<
  ACTION_TYPES.ACCEPT_TOS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.ACCEPT_TOS_ERROR, createPayload<IServerError>());
export const acceptTOSSuccessAction: ActionCreator<
  ACTION_TYPES.ACCEPT_TOS_SUCCESS,
  () => TypedAction<ACTION_TYPES.ACCEPT_TOS_SUCCESS>
> = createAction(ACTION_TYPES.ACCEPT_TOS_SUCCESS);

export const acceptBrokerTOSAction: ActionCreator<
  ACTION_TYPES.ACCEPT_BROKER_TOS,
  () => TypedAction<ACTION_TYPES.ACCEPT_BROKER_TOS>
> = createAction(ACTION_TYPES.ACCEPT_BROKER_TOS);
export const acceptBrokerTOSErrorAction: CreateActionType<
  ACTION_TYPES.ACCEPT_BROKER_TOS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.ACCEPT_BROKER_TOS_ERROR,
  createPayload<IServerError>(),
);
export const acceptBrokerTOSSuccessAction: ActionCreator<
  ACTION_TYPES.ACCEPT_BROKER_TOS_SUCCESS,
  () => TypedAction<ACTION_TYPES.ACCEPT_BROKER_TOS_SUCCESS>
> = createAction(ACTION_TYPES.ACCEPT_BROKER_TOS_SUCCESS);

export const setTOSStatusAction: CreateActionType<
  ACTION_TYPES.SET_TOS_STATUS,
  boolean
> = createAction(ACTION_TYPES.SET_TOS_STATUS, createPayload<boolean>());
export const setToUBrokerStatusAction: CreateActionType<
  ACTION_TYPES.SET_TOU_BROKER_STATUS,
  boolean
> = createAction(ACTION_TYPES.SET_TOU_BROKER_STATUS, createPayload<boolean>());

export const onTosPageAction: ActionCreator<
  ACTION_TYPES.ON_TOS_PAGE,
  () => TypedAction<ACTION_TYPES.ON_TOS_PAGE>
> = createAction(ACTION_TYPES.ON_TOS_PAGE);
export const outTosPageAction: ActionCreator<
  ACTION_TYPES.OUT_TOS_PAGE,
  () => TypedAction<ACTION_TYPES.OUT_TOS_PAGE>
> = createAction(ACTION_TYPES.OUT_TOS_PAGE);

export const resetTosAction: ActionCreator<
  ACTION_TYPES.RESET_TOS,
  () => TypedAction<ACTION_TYPES.RESET_TOS>
> = createAction(ACTION_TYPES.RESET_TOS);

export const redirectToTosAction: CreateActionType<
  ACTION_TYPES.REDIRECT_TO_TOS,
  ITOSPreviousUrl
> = createAction(
  ACTION_TYPES.REDIRECT_TO_TOS,
  createPayload<ITOSPreviousUrl>(),
);
