import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cdl-list-appends.action";

import {
  getCDLAllFields,
  getCDLAllFieldsUnique,
} from "../../../cdl-list-appends/utils";

import { IListMainTypes } from "@modules/list-shared/interfaces";
import { IServerError } from "@shared/interfaces/server-error";
import {
  ICDLListAppend,
  ICDLListAppends,
  ICDLListAppendField,
  ICDLListAppendFields,
} from "../../../cdl-list-appends/interfaces";

import { PRODUCT_ENTITY_TYPES } from "@shared/constants/data/entity";
import { LIST_RECORDS_TYPES } from "@shared/constants/data/list-types";

export interface ICDLListAppendsState {
  listLoading: boolean;
  listError: IServerError | null;
  listMainTypes: IListMainTypes;
  appendsCredits: number;
  appends: ICDLListAppends;
  selectedIds: number[];
  toggledAppendFieldIds: number[];

  pending: boolean;
  error: IServerError | null;
}

const initialState: ICDLListAppendsState = {
  listLoading: false,
  listError: null,
  listMainTypes: {
    name: "",
    entityType: PRODUCT_ENTITY_TYPES.CDL,
    recordType: LIST_RECORDS_TYPES.BUILDINGS_ONLY,
  },
  appendsCredits: 0,
  appends: [],
  selectedIds: [],
  toggledAppendFieldIds: [],

  pending: false,
  error: null,
};

const getAppendFields: OnReducer<ICDLListAppendsState, ActionType<any>> = (
  state: ICDLListAppendsState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getAppendFieldsError: OnReducer<
  ICDLListAppendsState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListAppendsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: payload,
});

const getAppendFieldsSuccess: OnReducer<
  ICDLListAppendsState,
  ActionType<Payload<any>>
> = (state: ICDLListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  appends: payload,
});

const selectAppend: OnReducer<
  ICDLListAppendsState,
  ActionType<Payload<any>>
> = (state: ICDLListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  selectedIds:
    payload && state.selectedIds.includes(payload.id)
      ? state.selectedIds.filter((id: number) => id !== payload.id)
      : [...state.selectedIds, payload.id],
});

const resetSelectedAppends: OnReducer<ICDLListAppendsState, ActionType<any>> = (
  state: ICDLListAppendsState,
) => ({
  ...state,
  selectedIds: [],
});

const toggleAppendField: OnReducer<
  ICDLListAppendsState,
  ActionType<Payload<any>>
> = (state: ICDLListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  toggledAppendFieldIds: [...state.toggledAppendFieldIds, payload.id],
  toggleAppendFieldError: null,
});

const toggleAppendFieldError: OnReducer<
  ICDLListAppendsState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListAppendsState, { payload }: Payload<IServerError>) => ({
  ...state,
  toggleAppendFieldError: payload,
});

const toggleAppendFieldSuccess: OnReducer<
  ICDLListAppendsState,
  ActionType<Payload<any>>
> = (state: ICDLListAppendsState, { payload }: Payload<any>) => ({
  ...state,
  toggledAppendFieldIds: state.toggledAppendFieldIds.filter(
    (id: number) => id !== payload.id,
  ),
  appends: state.appends.map((append: ICDLListAppend) => ({
    ...append,
    fields: append.fields.map((field: ICDLListAppendField) =>
      field.id === payload.id ? { ...field, ...payload } : field,
    ),
  })),
});

const getCDLListSummaryData: OnReducer<
  ICDLListAppendsState,
  ActionType<any>
> = (state: ICDLListAppendsState) => ({
  ...state,
  listLoading: true,
  listError: null,
});

export const getCDLListSummaryDataError: OnReducer<
  ICDLListAppendsState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListAppendsState, { payload }: Payload<IServerError>) => ({
  ...state,
  listLoading: false,
  listError: payload,
});

const getCDLListSummaryDataSuccess: OnReducer<
  ICDLListAppendsState,
  ActionType<Payload<any>>
> = (
  state: ICDLListAppendsState,
  {
    payload: {
      list: { name, recordsType },
    },
  }: Payload<any>,
) => ({
  ...state,
  listLoading: false,
  listError: null,
  listMainTypes: {
    ...state.listMainTypes,
    name,
    listRecordTypeId: recordsType.id,
  },
});

const onReset: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLListAppendsState> =
  createReducer<ICDLListAppendsState>(
    initialState,

    on(actions.getCDLListAppendFieldsAction, getAppendFields),
    on(actions.getCDLListAppendFieldsErrorAction, getAppendFieldsError),
    on(actions.getCDLListAppendFieldsSuccessAction, getAppendFieldsSuccess),

    on(actions.selectCDLListAppendsAction, selectAppend),
    on(actions.resetSelectedCDLListAppendsAction, resetSelectedAppends),

    on(actions.toggleCDLListAppendFieldAction, toggleAppendField),
    on(actions.toggleCDLListAppendFieldErrorAction, toggleAppendFieldError),
    on(actions.toggleCDLListAppendFieldSuccessAction, toggleAppendFieldSuccess),

    on(actions.getCDLAppendsListDataAction, getCDLListSummaryData),
    on(actions.getCDLAppendsListDataErrorAction, getCDLListSummaryDataError),
    on(
      actions.getCDLAppendsListDataSuccessAction,
      getCDLListSummaryDataSuccess,
    ),

    on(actions.resetCDLListAppendsAction, onReset),
  );

export function cdlListAppendsReducer(
  state: ICDLListAppendsState,
  action: Action,
): ICDLListAppendsState {
  return reducer(state, action);
}

export const cdlListMainTypes: GetFromState<
  IListMainTypes,
  ICDLListAppendsState
> = (state: ICDLListAppendsState): IListMainTypes =>
  (state && state.listMainTypes) || null;
export const appends: GetFromState<ICDLListAppends, ICDLListAppendsState> = (
  state: ICDLListAppendsState,
): ICDLListAppends => state && state.appends;
export const pending: GetFromState<boolean, ICDLListAppendsState> = (
  state: ICDLListAppendsState,
): boolean => state && state.pending;
export const selectedCDLListAppendIds: GetFromState<
  number[],
  ICDLListAppendsState
> = (state: ICDLListAppendsState): number[] => state && state.selectedIds;
export const selectedCDLAppendFields: GetFromState<
  ICDLListAppendFields,
  ICDLListAppendsState
> = (state: ICDLListAppendsState): ICDLListAppendFields => {
  if (state.selectedIds && state.selectedIds.length) {
    const cdlAppendsAllFields: ICDLListAppendFields = getCDLAllFields(
      state.appends.filter((item: ICDLListAppend) =>
        state.selectedIds.includes(item.id),
      ),
    );
    return Array.from(
      new Set(
        cdlAppendsAllFields.map((field: ICDLListAppendField) => field.id),
      ),
    ).map((id: number) =>
      cdlAppendsAllFields.find((field: ICDLListAppendField) => field.id === id),
    );
  }

  return getCDLAllFieldsUnique(state.appends);
};
export const toggledAppendFieldIds: GetFromState<
  number[],
  ICDLListAppendsState
> = (state: ICDLListAppendsState): number[] =>
  state && state.toggledAppendFieldIds;

export const selectedFields: GetFromState<
  ICDLListAppendFields,
  ICDLListAppendFields
> = (fields: ICDLListAppendFields): ICDLListAppendFields =>
  fields && fields.filter((field: ICDLListAppendField) => field.include);

export const appendsOnlyWithSelectedFields: GetFromState<
  ICDLListAppends,
  ICDLListAppends
> = (_appends: ICDLListAppends): ICDLListAppends =>
  _appends &&
  _appends
    .filter(
      (_append: ICDLListAppend) => !!selectedFields(_append.fields).length,
    )
    .map((_append: ICDLListAppend) => ({
      ..._append,
      fields: selectedFields(_append.fields),
    }));

export const isHasCheckedAppends: GetFromState<boolean, ICDLListAppends> = (
  _appends: ICDLListAppends,
): boolean => _appends && !!_appends.length;
