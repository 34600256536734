import { IUserTransformed } from "@modules/auth/interfaces/user";

export const isDifferentProfileData: (
  userData: IUserTransformed,
  changedUserData: IUserTransformed,
) => boolean = (
  userData: IUserTransformed,
  changedUserData: IUserTransformed,
) => {
  const { address, company, firstName, lastName, phone }: IUserTransformed =
    userData;

  const _userData: Partial<IUserTransformed> = {
    address,
    company,
    firstName,
    lastName,
    phone,
  };

  const {
    address: _address,
    company: _company,
    firstName: _firstName,
    lastName: _lastName,
    phone: _phone,
  }: IUserTransformed = changedUserData;

  const _changedUserData: Partial<IUserTransformed> = {
    address: _address,
    company: _company,
    firstName: _firstName,
    lastName: _lastName,
    phone: _phone,
  };

  return JSON.stringify(_userData) !== JSON.stringify(_changedUserData);
};
