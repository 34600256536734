import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-personnel-schools.action";

import { IPersonnelRecords } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { PERSONNEL_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IViewResultsPersonnelSchoolsState {
  schools: IPersonnelRecords;
  pending: boolean;
  error: IServerError | null;

  selectedSchoolsIds: number[];

  count: number;
}

const initialState: IViewResultsPersonnelSchoolsState = {
  schools: [],
  pending: false,
  error: null,

  selectedSchoolsIds: [],

  count: 0,
};

const getViewResultsPersonnelSchools: OnReducer<
  IViewResultsPersonnelSchoolsState,
  ActionType<any>
> = (state: IViewResultsPersonnelSchoolsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsPersonnelSchoolsError: OnReducer<
  IViewResultsPersonnelSchoolsState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsPersonnelSchoolsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsPersonnelSchoolsSuccess: OnReducer<
  IViewResultsPersonnelSchoolsState,
  ActionType<Payload<any>>
> = (state: IViewResultsPersonnelSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  schools: payload.items ? [...payload.items] : [],
  count: payload.count,
});

const toggleViewResultsPersonnelSchool: OnReducer<
  IViewResultsPersonnelSchoolsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsPersonnelSchoolsState,
  { payload: { id } }: Payload<any>,
) => {
  const _setArray: Set<number> = new Set(state.selectedSchoolsIds);

  _setArray.has(id) ? _setArray.delete(id) : _setArray.add(id);

  return {
    ...state,
    selectedSchoolsIds: Array.from(_setArray),
  };
};

const setViewResultsPersonnelSchoolsSpinnerState: OnReducer<
  IViewResultsPersonnelSchoolsState,
  ActionType<Payload<any>>
> = (state: IViewResultsPersonnelSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetSelectedPersonnelSchools: OnReducer<
  IViewResultsPersonnelSchoolsState,
  ActionType<any>
> = (state: IViewResultsPersonnelSchoolsState) => ({
  ...state,
  selectedSchoolsIds: [],
});

const resetViewResultsPersonnelSchoolsState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsPersonnelSchoolsState> =
  createReducer<IViewResultsPersonnelSchoolsState>(
    initialState,

    on(
      actions.getViewResultsPersonnelSchoolsAction,
      getViewResultsPersonnelSchools,
    ),
    on(
      actions.getViewResultsPersonnelSchoolsErrorAction,
      getViewResultsPersonnelSchoolsError,
    ),
    on(
      actions.getViewResultsPersonnelSchoolsSuccessAction,
      getViewResultsPersonnelSchoolsSuccess,
    ),

    on(
      actions.toggleViewResultsPersonnelSchoolAction,
      toggleViewResultsPersonnelSchool,
    ),

    on(
      actions.setViewResultsPersonnelSchoolsSpinnerStateAction,
      setViewResultsPersonnelSchoolsSpinnerState,
    ),

    on(
      actions.resetSelectedPersonnelSchoolsAction,
      resetSelectedPersonnelSchools,
    ),

    on(
      actions.resetViewResultsPersonnelSchoolsStateAction,
      resetViewResultsPersonnelSchoolsState,
    ),
  );

export function viewResultsPersonnelSchoolsReducer(
  state: IViewResultsPersonnelSchoolsState,
  action: Action,
): IViewResultsPersonnelSchoolsState {
  return reducer(state, action);
}

export const schools: GetFromState<
  IPersonnelRecords,
  IViewResultsPersonnelSchoolsState
> = (state: IViewResultsPersonnelSchoolsState): IPersonnelRecords =>
  state.schools;
export const pending: GetFromState<
  boolean,
  IViewResultsPersonnelSchoolsState
> = (state: IViewResultsPersonnelSchoolsState): boolean => state.pending;

export const schoolsPaginationCount: GetFromState<
  number,
  IViewResultsPersonnelSchoolsState
> = (state: IViewResultsPersonnelSchoolsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const { sortBy }: Params = queryParams;

  const params: Params = {
    ...PERSONNEL_DEFAULT_PARAMS,
    ...queryParams,
    sortBy: sortBy || "lastName",
    viewId,
    filterBy: "schools",
  };

  return segmentId ? { ...params, segmentId } : params;
};

export const selectedPersonnelSchoolsIds: GetFromState<
  number[],
  IViewResultsPersonnelSchoolsState
> = (state: IViewResultsPersonnelSchoolsState): number[] =>
  state.selectedSchoolsIds;
