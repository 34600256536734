import { InjectionToken } from "@angular/core";
import { Params } from "@angular/router";
import { ActionReducerMap } from "@ngrx/store";

import { IRequestViewAlertsFilters } from "../../interfaces";

import { SORT_DIRECTION } from "@shared/constants/sort";
import {
  DEFAULT_ALERT_REQUEST_PARAMS,
  REQUEST_VIEW_ALERTS_LIMIT,
  REQUEST_VIEW_ALERTS_OFFSET,
  REQUEST_VIEW_ALERTS_SORT,
} from "../../constants";

import {
  viewResultsAlertsTabsReducer,
  IViewResultsAlertsTabsState,
} from "./alerts-tabs.reducer";
import {
  viewResultsAllAlertsReducer,
  IViewResultsAllAlertsState,
} from "./all-alerts.reducer";
import {
  viewResultsBidsAlertsReducer,
  IViewResultsBidsAlertsState,
} from "./bids-alerts.reducer";
import {
  viewResultsGrantsAlertsReducer,
  IViewResultsGrantsAlertsState,
} from "./grant-alerts.reducer";
import {
  viewResultsInstitutionAlertsReducer,
  IViewResultsInstitutionAlertsState,
} from "./institution-alerts.reducer";

export interface IViewResultsAlertsState {
  tabs: IViewResultsAlertsTabsState;

  all: IViewResultsAllAlertsState;
  bids: IViewResultsBidsAlertsState;
  grants: IViewResultsGrantsAlertsState;
  institution: IViewResultsInstitutionAlertsState;
}

const viewResultsAlertsState: ActionReducerMap<IViewResultsAlertsState> = {
  tabs: viewResultsAlertsTabsReducer,

  all: viewResultsAllAlertsReducer,
  bids: viewResultsBidsAlertsReducer,
  grants: viewResultsGrantsAlertsReducer,
  institution: viewResultsInstitutionAlertsReducer,
};

// eslint-disable-next-line
export const VIEW_RESULTS_STATE_NAME = 'viewResultsCommon';
export const VIEW_RESULTS_STATE: InjectionToken<
  ActionReducerMap<IViewResultsAlertsState>
> = new InjectionToken<ActionReducerMap<IViewResultsAlertsState>>(
  VIEW_RESULTS_STATE_NAME,
  {
    factory: () => viewResultsAlertsState,
  },
);

export const requestParams: (params?: Params) => IRequestViewAlertsFilters = (
  params: Params = {},
) => {
  const mappedParams: IRequestViewAlertsFilters = {
    ...DEFAULT_ALERT_REQUEST_PARAMS,
    ...params,
  };
  const {
    filterBy,
    orderBy,
    sortBy,
    offset,
    limit,
  }: IRequestViewAlertsFilters = mappedParams;
  return {
    filterBy,
    orderBy,
    sortBy,
    offset,
    limit,
  } as IRequestViewAlertsFilters;
};

export const currentLimit: (filters: IRequestViewAlertsFilters) => number = (
  filters: IRequestViewAlertsFilters,
) => (filters && +filters.limit) || REQUEST_VIEW_ALERTS_LIMIT;
export const currentOffset: (filters: IRequestViewAlertsFilters) => number = (
  filters: IRequestViewAlertsFilters,
) => (filters && +filters.offset) || REQUEST_VIEW_ALERTS_OFFSET;
export const currentSortBy: (
  filters: IRequestViewAlertsFilters,
) => REQUEST_VIEW_ALERTS_SORT = (filters: IRequestViewAlertsFilters) =>
  (filters && filters.sortBy) || REQUEST_VIEW_ALERTS_SORT.DATE;
export const currentOrderBy: (
  filters: IRequestViewAlertsFilters,
) => SORT_DIRECTION = (filters: IRequestViewAlertsFilters) =>
  (filters && filters.orderBy) || SORT_DIRECTION.DESC;
export const currentPage: (limit: number, offset: number) => number = (
  limit: number,
  offset: number,
) => (offset + limit) / limit;
