import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewData } from "@shared/interfaces/view";

enum ACTION_TYPES {
  GET_VIEW = "[VIEW] Get View",
  GET_VIEW_ERROR = "[VIEW] Get View error",
  GET_VIEW_SUCCESS = "[VIEW] Get View success",

  RELOAD_VIEW = "[VIEW] Reload view",
  RELOAD_VIEW_ERROR = "[VIEW] Reload view error",
  RELOAD_VIEW_SUCCESS = "[VIEW] Reload view success",

  SOCKET_VIEW_COUNTS_REFRESHED = "[LIST]: Socket counts refreshed",
}

export const getViewAction: CreateActionType<ACTION_TYPES.GET_VIEW, number> =
  createAction(ACTION_TYPES.GET_VIEW, createPayload<number>());
export const getViewErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_VIEW_ERROR, createPayload<IServerError>());
export const getViewSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_SUCCESS,
  IViewData
> = createAction(ACTION_TYPES.GET_VIEW_SUCCESS, createPayload<IViewData>());

export const reloadViewAction: ActionCreator<
  ACTION_TYPES.RELOAD_VIEW,
  () => TypedAction<ACTION_TYPES.RELOAD_VIEW>
> = createAction(ACTION_TYPES.RELOAD_VIEW);
export const reloadViewErrorAction: CreateActionType<
  ACTION_TYPES.RELOAD_VIEW_ERROR,
  IServerError
> = createAction(ACTION_TYPES.RELOAD_VIEW_ERROR, createPayload<IServerError>());
export const reloadViewSuccessAction: CreateActionType<
  ACTION_TYPES.RELOAD_VIEW_SUCCESS,
  IViewData
> = createAction(ACTION_TYPES.RELOAD_VIEW_SUCCESS, createPayload<IViewData>());

export const socketViewCountsRefreshedAction: ActionCreator<
  ACTION_TYPES.SOCKET_VIEW_COUNTS_REFRESHED,
  () => TypedAction<ACTION_TYPES.SOCKET_VIEW_COUNTS_REFRESHED>
> = createAction(ACTION_TYPES.SOCKET_VIEW_COUNTS_REFRESHED);
