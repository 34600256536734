import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IPersonnelRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_VIEW_RESULTS_PERSONNEL_ALL = "[VIEW RESULTS PERSONNEL ALL] Get all Personnel",
  GET_VIEW_RESULTS_PERSONNEL_ALL_ERROR = "[VIEW RESULTS PERSONNEL ALL] Get all personnel error",
  GET_VIEW_RESULTS_PERSONNEL_ALL_SUCCESS = "[VIEW RESULTS PERSONNEL ALL] Get all personnel success",

  SET_VIEW_RESULTS_PERSONNEL_ALL_SPINNER_STATE = "[VIEW RESULTS PERSONNEL ALL] Set spinner state",

  CHANGE_VIEW_RESULTS_PERSONNEL_ALL_PAGE = "[VIEW RESULTS PERSONNEL ALL] Change page",
  CHANGE_VIEW_RESULTS_PERSONNEL_ALL_QUERY = "[VIEW RESULTS PERSONNEL ALL] Change query",

  RESET_VIEW_RESULTS_PERSONNEL_ALL_STATE = "[VIEW RESULTS PERSONNEL ALL] Reset state",
}

export const getViewResultsAllPersonnelAllAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_ALL,
  () => TypedAction<ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_ALL>
> = createAction(ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_ALL);
export const getViewResultsAllPersonnelAllErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_ALL_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_ALL_ERROR,
  createPayload<IServerError>(),
);
export const getViewResultsAllPersonnelAllSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_ALL_SUCCESS,
  IProductResultsResponse<IPersonnelRecords>
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_ALL_SUCCESS,
  createPayload<IProductResultsResponse<IPersonnelRecords>>(),
);

export const setViewResultsPersonnelAllSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_VIEW_RESULTS_PERSONNEL_ALL_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_VIEW_RESULTS_PERSONNEL_ALL_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeViewResultsAllPersonnelPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_ALL_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_ALL_PAGE,
  createPayload<number>(),
);

export const changeViewResultsAllPersonnelQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_ALL_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_ALL_QUERY,
  createPayload<Params>(),
);

export const resetViewResultsAllPersonnelStateAction: ActionCreator<
  ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_ALL_STATE,
  () => TypedAction<ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_ALL_STATE>
> = createAction(ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_ALL_STATE);
