import { getOnlySelectedTargetingControls } from "../modules/pipe-controls/utils";

import { SEGMENT_BY_PERSONNEL_ENTITY_TYPE } from "@modules/segment/interfaces/browse";

import {
  ISegmentByItem,
  ISegmentByItems,
} from "@modules/segment/models/segment-by";

import { TARGETING_TYPES_ID } from "../constants/data/list-types";

import {
  ITargetingCriteriaControls,
  ITargetingCriteriaItem,
  ITargetingCriteriaItemDetailedData,
  ITargetingCriteriaItemDetailedDataRadioData,
  ITargetingCriteriaItemDetailedDataRadioDataItem,
  ITargetingCriteriaItemDetailedDataSimpleData,
  ITargetingCriteriaItemDetailedItemAsMultiData,
  TARGETING_DATA_CONTROL_TYPES,
  TARGETING_DATA_TYPES,
} from "../modules/targeting-criteria-controls/data";

export const getSelectedSegmentByControls: (
  data: ITargetingCriteriaControls,
) => ITargetingCriteriaControls = (
  data: ITargetingCriteriaControls,
): ITargetingCriteriaControls => {
  if (!data) {
    return [];
  }

  return getOnlySelectedTargetingControls(
    data,
    false,
    TARGETING_DATA_CONTROL_TYPES.RADIO,
    TARGETING_DATA_CONTROL_TYPES.RADIO_BUTTON,
    TARGETING_DATA_CONTROL_TYPES.DATE,
    TARGETING_DATA_CONTROL_TYPES.CUSTOM_DROPDOWN,
    TARGETING_DATA_CONTROL_TYPES.SLIDER,
    TARGETING_DATA_CONTROL_TYPES.LABEL,
  );
};

export const getSegmentByItemsCountBySimpleData: (
  _targetingCriteriaControls: ITargetingCriteriaControls,
) => number = (
  _targetingCriteriaControls: ITargetingCriteriaControls,
): number => {
  if (!_targetingCriteriaControls) {
    return null;
  }
  return getSelectedSegmentByControls(_targetingCriteriaControls).length;
};

export const createSegmentByDataItem: (
  targetingCriteriaId: TARGETING_TYPES_ID,
  selectedCount: number,
  { targetingControlId, title }: Partial<ITargetingCriteriaItem>,
  personnelType?: SEGMENT_BY_PERSONNEL_ENTITY_TYPE,
) => ISegmentByItem = (
  targetingCriteriaId: TARGETING_TYPES_ID,
  selectedCount: number,
  { targetingControlId, title }: Partial<ITargetingCriteriaItem>,
  personnelType?: SEGMENT_BY_PERSONNEL_ENTITY_TYPE,
): ISegmentByItem => ({
  targetingCriteriaId,
  selectedCount,
  targetingControlId,
  title,
  personnelType,
});

export const createSegmentByDetailedDataItem: (
  targetingCriteriaId: TARGETING_TYPES_ID,
  selectedCount: number,
  targetingControlId: number,
  {
    detailedTargetingControlId,
    title,
  }: Partial<ITargetingCriteriaItemDetailedDataSimpleData>,
  personnelType?: SEGMENT_BY_PERSONNEL_ENTITY_TYPE,
) => ISegmentByItem = (
  targetingCriteriaId: TARGETING_TYPES_ID,
  selectedCount: number,
  targetingControlId: number,
  {
    detailedTargetingControlId,
    title,
  }: Partial<ITargetingCriteriaItemDetailedDataSimpleData>,
  personnelType?: SEGMENT_BY_PERSONNEL_ENTITY_TYPE,
): ISegmentByItem => ({
  targetingCriteriaId,
  selectedCount,
  targetingControlId,
  detailTargetingControlId: detailedTargetingControlId, // Bug on API side - wrong field name
  title,
  personnelType,
});

export const segmentByItemsFromMultiOrSimpleData: (
  targetingCriteriaId: TARGETING_TYPES_ID,
  targetingControlId: number,
  detailedTargetingDataItem:
    | ITargetingCriteriaItemDetailedItemAsMultiData
    | ITargetingCriteriaItemDetailedDataSimpleData,
) => ISegmentByItems = (
  targetingCriteriaId: TARGETING_TYPES_ID,
  targetingControlId: number,
  detailedTargetingDataItem:
    | ITargetingCriteriaItemDetailedItemAsMultiData
    | ITargetingCriteriaItemDetailedDataSimpleData,
): ISegmentByItems => {
  let items: any[] = [];

  detailedTargetingDataItem.data.forEach(
    (detailedTargetingDataItemData: any) => {
      if (
        (
          detailedTargetingDataItemData as ITargetingCriteriaItemDetailedDataRadioData
        ).type === TARGETING_DATA_TYPES.RADIO_BUTTON
      ) {
        detailedTargetingDataItemData.data.forEach(
          (radioItem: ITargetingCriteriaItemDetailedDataRadioDataItem) => {
            radioItem.data.forEach(
              (
                radioItemDataItem: ITargetingCriteriaItemDetailedDataSimpleData,
              ) => {
                const selectedCount: number =
                  getSegmentByItemsCountBySimpleData(radioItemDataItem.data);

                if (selectedCount > 1) {
                  items = [
                    ...items,
                    createSegmentByDetailedDataItem(
                      targetingCriteriaId,
                      selectedCount,
                      targetingControlId,
                      radioItemDataItem,
                    ),
                  ];
                }
              },
            );
          },
        );
      } else {
        const selectedCount: number = getSegmentByItemsCountBySimpleData(
          (
            detailedTargetingDataItemData as ITargetingCriteriaItemDetailedDataSimpleData
          ).data,
        );

        if (selectedCount > 1) {
          items = [
            ...items,
            createSegmentByDetailedDataItem(
              targetingCriteriaId,
              selectedCount,
              targetingControlId,
              detailedTargetingDataItemData as ITargetingCriteriaItemDetailedDataSimpleData,
            ),
          ];
        }
      }
    },
  );

  return items;
};

export const getSegmentByItemsFromDetailedData: (
  targetingCriteriaId: TARGETING_TYPES_ID,
  targetingControlId: number,
  detailedTargetingData: ITargetingCriteriaItemDetailedData,
) => ISegmentByItems = (
  targetingCriteriaId: TARGETING_TYPES_ID,
  targetingControlId: number,
  detailedTargetingData: ITargetingCriteriaItemDetailedData,
): ISegmentByItems => {
  if (!detailedTargetingData) {
    return [];
  }

  let items: any[] = [];
  detailedTargetingData.forEach(
    (
      detailedTargetingDataItem:
        | ITargetingCriteriaItemDetailedItemAsMultiData
        | ITargetingCriteriaItemDetailedDataSimpleData,
    ) => {
      if (
        (
          detailedTargetingDataItem as ITargetingCriteriaItemDetailedItemAsMultiData
        ).type === TARGETING_DATA_TYPES.MULTI
      ) {
        items = [
          ...items,
          ...segmentByItemsFromMultiOrSimpleData(
            targetingCriteriaId,
            targetingControlId,
            detailedTargetingDataItem,
          ),
        ];
      } else {
        const selectedCount: number = getSegmentByItemsCountBySimpleData(
          (
            detailedTargetingDataItem as ITargetingCriteriaItemDetailedDataSimpleData
          ).data,
        );

        if (selectedCount > 1) {
          items = [
            ...items,
            createSegmentByDetailedDataItem(
              targetingCriteriaId,
              selectedCount,
              targetingControlId,
              detailedTargetingDataItem as ITargetingCriteriaItemDetailedDataSimpleData,
            ),
          ];
        }
      }
    },
  );

  return items;
};
