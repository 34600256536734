import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import * as actions from "@shared/modules/personnel-entity/store/actions/personnel-entity.action";
import { GetFromState } from "@shared/store/types/reducer.types";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IGetPersonnelEntity,
  IPersonnelEntities,
  IPersonnelEntity,
  IPersonnelEntityParamsWithId,
  IPersonnelEntityQueryParams,
  IPersonnelEntityResponse,
  ISearchPersonnelEntity,
  ISelectPersonnel,
  ISortPersonnelEntity,
  IUpdateTableEmailByPersonId,
} from "@shared/modules/personnel-entity/interfaces/personnel-entity";

import { SORT_DIRECTION } from "@shared/constants/sort";
import { PERSONNEL_ENTITY_SORTING } from "@shared/modules/personnel-entity/constants/personnel-entity";

export interface IPersonnelEntityState {
  personnel: IPersonnelEntities;

  pending: boolean;
  error: IServerError | null;

  count: number | null;
  paginationCount: number;

  page: number;
  limit: number;
  offset: number;
  query: string;
  sortBy: PERSONNEL_ENTITY_SORTING;
  orderBy: SORT_DIRECTION;

  checkedPersons: number[];
  isCheckedAllPersons: boolean;
}

const initialState: IPersonnelEntityState = {
  personnel: [],

  pending: false,
  error: null,

  count: null,
  paginationCount: 0,

  page: 0,
  limit: 20,
  offset: 0,
  query: "",
  sortBy: PERSONNEL_ENTITY_SORTING.POSTED_ON,
  orderBy: SORT_DIRECTION.DESC,

  checkedPersons: [],
  isCheckedAllPersons: false,
};
const getPersonnelEntity: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<IGetPersonnelEntity>>
> = (
  state: IPersonnelEntityState,
  { payload }: Payload<IGetPersonnelEntity>,
) => ({
  ...state,
  pending: true,
  error: null,
  // calculate offset by page number and limit
  offset:
    typeof payload.page === "number" ? (payload.page - 1) * state.limit : 0,
});

const getPersonnelEntityError: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<IServerError>>
> = (state: IPersonnelEntityState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getPersonnelEntitySuccess: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<IPersonnelEntityResponse>>
> = (
  state: IPersonnelEntityState,
  { payload }: Payload<IPersonnelEntityResponse>,
) => ({
  ...state,
  pending: false,
  personnel: [...payload.items],
  checkedPersons: state.isCheckedAllPersons
    ? Array.from(
        new Set([
          ...payload.items.map(
            (personnelItem: IPersonnelEntity) => personnelItem.id,
          ),
          ...state.checkedPersons,
        ]),
      )
    : state.checkedPersons,
  count: payload.count,
  paginationCount: payload.paginationCount,
  // calculate page number by offset and limit
  page: Math.ceil(state.offset / state.limit) + 1,
});

const sortPersonnelEntity: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<ISortPersonnelEntity>>
> = (
  state: IPersonnelEntityState,
  { payload }: Payload<ISortPersonnelEntity>,
) => ({
  ...state,
  ...payload.params,
  pending: true,
  error: null,
});

const sortPersonnelEntityError: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<IServerError>>
> = (state: IPersonnelEntityState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const sortPersonnelEntitySuccess: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<IPersonnelEntityResponse>>
> = (
  state: IPersonnelEntityState,
  { payload }: Payload<IPersonnelEntityResponse>,
) => ({
  ...state,
  pending: false,
  personnel: [...payload.items],
  checkedPersons: state.isCheckedAllPersons
    ? Array.from(
        new Set([
          ...payload.items.map(
            (personnelItem: IPersonnelEntity) => personnelItem.id,
          ),
          ...state.checkedPersons,
        ]),
      )
    : state.checkedPersons,
  count: payload.count,
  paginationCount: payload.paginationCount,
});

const setPersonnelEntitySearchQuery: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<ISearchPersonnelEntity>>
> = (
  state: IPersonnelEntityState,
  { payload }: Payload<ISearchPersonnelEntity>,
) => ({
  ...state,
  offset: state.query !== payload.query ? 0 : state.offset,
  query: payload.query,
});

const setCheckedPersonnelEntity: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<ISelectPersonnel>>
> = (
  state: IPersonnelEntityState,
  { payload: { id } }: Payload<ISelectPersonnel>,
) => {
  const { checkedPersons, count, query }: IPersonnelEntityState = state;

  const newCheckedPersons: number[] = checkedPersons.includes(id)
    ? checkedPersons.filter((_id: number) => _id !== id)
    : [...checkedPersons, id];

  return {
    ...state,
    isCheckedAllPersons: query ? false : newCheckedPersons.length === count,
    checkedPersons: newCheckedPersons,
  };
};

const checkAllPersonnelEntity: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<boolean>>
> = (state: IPersonnelEntityState, { payload }: Payload<boolean>) => ({
  ...state,
  isCheckedAllPersons: payload,
  checkedPersons: payload
    ? Array.from(
        new Set(
          state.personnel.map(
            (personnelItem: IPersonnelEntity) => personnelItem.id,
          ),
        ),
      )
    : [],
});

const resetCheckedPersonnelEntity: OnReducer<
  IPersonnelEntityState,
  ActionType<void>
> = (state: IPersonnelEntityState) => ({
  ...state,
  checkedPersons: [],
  isCheckedAllPersons: false,
});

const updateTableEmailByPersonId: OnReducer<
  IPersonnelEntityState,
  ActionType<Payload<IUpdateTableEmailByPersonId>>
> = (
  state: IPersonnelEntityState,
  { payload }: Payload<IUpdateTableEmailByPersonId>,
) => ({
  ...state,
  personnel: state.personnel.map((person: IPersonnelEntity) => ({
    ...person,
    email: person.id === payload.personId ? payload.email : person.email,
  })),
});

const resetPersonnelEntity: OnReducer<
  IPersonnelEntityState,
  ActionType<void>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IPersonnelEntityState> =
  createReducer<IPersonnelEntityState>(
    initialState,

    on(actions.getPersonnelEntityAction, getPersonnelEntity),
    on(actions.getPersonnelEntityErrorAction, getPersonnelEntityError),
    on(actions.getPersonnelEntitySuccessAction, getPersonnelEntitySuccess),

    on(actions.sortPersonnelEntityAction, sortPersonnelEntity),
    on(actions.sortPersonnelEntityErrorAction, sortPersonnelEntityError),
    on(actions.sortPersonnelEntitySuccessAction, sortPersonnelEntitySuccess),

    on(actions.searchPersonnelEntityAction, setPersonnelEntitySearchQuery),

    on(actions.setCheckedPersonnelEntityActions, setCheckedPersonnelEntity),
    on(actions.checkAllPersonnelEntityAction, checkAllPersonnelEntity),

    on(actions.resetCheckedPersonnelEntityAction, resetCheckedPersonnelEntity),

    on(actions.updateTableEmailByPersonIdAction, updateTableEmailByPersonId),

    on(actions.resetPersonnelEntityAction, resetPersonnelEntity),
  );

export function personnelEntityReducer(
  state: IPersonnelEntityState,
  action: Action,
): IPersonnelEntityState {
  return reducer(state, action);
}

export const personnelEntity: GetFromState<
  IPersonnelEntities,
  IPersonnelEntityState
> = (state: IPersonnelEntityState): IPersonnelEntities => state.personnel;
export const pending: GetFromState<boolean, IPersonnelEntityState> = (
  state: IPersonnelEntityState,
): boolean => state.pending;
export const personnelEntityCount: GetFromState<
  number,
  IPersonnelEntityState
> = (state: IPersonnelEntityState): number => state.count;
export const personnelEntityPaginationCount: GetFromState<
  number,
  IPersonnelEntityState
> = (state: IPersonnelEntityState): number => state.paginationCount;

export const orderBy: GetFromState<SORT_DIRECTION, IPersonnelEntityState> = (
  state: IPersonnelEntityState,
): SORT_DIRECTION => state.orderBy;
export const sortBy: GetFromState<
  PERSONNEL_ENTITY_SORTING,
  IPersonnelEntityState
> = (state: IPersonnelEntityState): PERSONNEL_ENTITY_SORTING => state.sortBy;

export const checkedPersonnelEntity: GetFromState<
  number[],
  IPersonnelEntityState
> = (state: IPersonnelEntityState): number[] => state.checkedPersons;
export const checkedAllPersonnelEntity: GetFromState<
  boolean,
  IPersonnelEntityState
> = (state: IPersonnelEntityState): boolean => state.isCheckedAllPersons;

export const personnelEntityQueryParams: GetFromState<
  IPersonnelEntityQueryParams,
  IPersonnelEntityState
> = (state: IPersonnelEntityState): IPersonnelEntityQueryParams => {
  const { limit, offset, sortBy, orderBy, query }: IPersonnelEntityState =
    state;
  return { limit, offset, sortBy, orderBy, query };
};

export const personnelEntityParamsWithProfileId: GetFromState<
  IPersonnelEntityParamsWithId,
  number,
  IPersonnelEntityQueryParams
> = (
  id: number,
  params: IPersonnelEntityQueryParams,
): IPersonnelEntityParamsWithId => ({
  id,
  ...params,
});

export const currentPage: GetFromState<number, IPersonnelEntityState> = (
  state: IPersonnelEntityState,
): number => state.page;
