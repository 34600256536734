import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/add-user-account.action";

import { checkedAddOns, checkedAvailableAddOns } from "../../utils/add-ons";
import {
  checkedAvailableStatesIds,
  checkedUnassignedStateIds,
} from "../../utils/states";

import { IControlOption, IControlOptions } from "@shared/interfaces/forms";
import { ICDLLists } from "@shared/interfaces/manage-account-lists";
import { IServerError } from "@shared/interfaces/server-error";
import {
  IAddUserPopUpPayload,
  IOpenSeatAddOns,
  IOpenSeatStates,
  ISelectedAddOnIds,
  ISelectedStateIds,
  SEATS,
} from "../../interfaces/marketview";

import { ADD_ON_FIELD } from "../../constants/add-ons";

export interface IAddUserAccountState {
  isShouldShowAvailableStates: boolean;

  seatId: SEATS;
  selectedStateIds: ISelectedStateIds;
  selectedAddOnsIds: ISelectedAddOnIds;

  error: IServerError | null;
  pending: boolean;
}

const _initialState: IAddUserAccountState = {
  isShouldShowAvailableStates: true,

  seatId: SEATS.Lite,
  selectedStateIds: {
    unassigned: [],
    available: [],
  },
  selectedAddOnsIds: {
    bidAddOnIds: [],
    bidAvailableIds: [],
    bidAddOnIdsOnUser: [],
    grantAddOnIds: [],
    grantAvailableIds: [],
    grantAddOnIdsOnUser: [],
  },

  error: null,
  pending: false,
};

const setShowAvailableStatesHandler: OnReducer<
  IAddUserAccountState,
  ActionType<Payload<any>>
> = (state: IAddUserAccountState, { payload }: Payload<any>) => ({
  ...state,
  isShouldShowAvailableStates: payload
    ? payload
    : !state.isShouldShowAvailableStates,
});

const addAccountUserHandler: OnReducer<
  IAddUserAccountState,
  ActionType<any>
> = (state: IAddUserAccountState) => ({
  ...state,
  pending: true,
  error: null,
});

const addAccountUserErrorHandler: OnReducer<
  IAddUserAccountState,
  ActionType<Payload<any>>
> = (state: IAddUserAccountState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const addAccountUserSuccessHandler: OnReducer<
  IAddUserAccountState,
  ActionType<any>
> = (state: IAddUserAccountState) => ({
  ...state,
  pending: false,
  error: null,
});

const changeSelectedStateHandler: OnReducer<
  IAddUserAccountState,
  ActionType<Payload<any>>
> = (state: IAddUserAccountState, { payload }: Payload<any>) => {
  const {
    states,
    openStates: { availableIds },
    openAddOns: { bidStateAvailable, grantStateAvailable },
  }: any = payload;

  const _unassignedStateIds: number[] = checkedUnassignedStateIds(
    states,
    [],
    availableIds,
  );
  const _availableStateIds: number[] = checkedAvailableStatesIds(
    states,
    availableIds,
  );

  const _bidAddOnIds: number[] = checkedAddOns(states, [], bidStateAvailable);
  const _bidAvailableIds: number[] = checkedAvailableAddOns(
    states,
    bidStateAvailable,
  );

  const _grantAddOnIds: number[] = checkedAddOns(
    states,
    [],
    grantStateAvailable,
    ADD_ON_FIELD.GRANT,
  );
  const _grantAvailableIds: number[] = checkedAvailableAddOns(
    states,
    grantStateAvailable,
    ADD_ON_FIELD.GRANT,
  );

  return {
    ...state,
    selectedStateIds: {
      unassigned: [..._unassignedStateIds],
      available: [..._availableStateIds],
    },
    selectedAddOnsIds: {
      bidAddOnIds: [..._bidAddOnIds],
      bidAvailableIds: [..._bidAvailableIds],
      bidAddOnIdsOnUser: [],
      grantAddOnIds: [..._grantAddOnIds],
      grantAvailableIds: [..._grantAvailableIds],
      grantAddOnIdsOnUser: [],
    },
  };
};

const changeSeatTypeHandler: OnReducer<
  IAddUserAccountState,
  ActionType<Payload<any>>
> = (state: IAddUserAccountState, { payload }: Payload<any>) => ({
  ...state,
  seatId: payload,
  selectedStateIds: {
    unassigned: [],
    available: [],
  },
  selectedAddOnsIds: {
    bidAddOnIds: [],
    bidAvailableIds: [],
    bidAddOnIdsOnUser: [],
    grantAddOnIds: [],
    grantAvailableIds: [],
    grantAddOnIdsOnUser: [],
  },
  isShouldShowAvailableStates: true,
});

const resetStateHandler: OnReducer<any, ActionType<any>> = () => ({
  ..._initialState,
});

const reducer: ActionReducer<IAddUserAccountState> =
  createReducer<IAddUserAccountState>(
    _initialState,

    on(actions.closeUserPopUpAction, resetStateHandler),
    on(actions.setShowAvailableStatesAction, setShowAvailableStatesHandler),

    on(actions.addAccountUserAction, addAccountUserHandler),
    on(actions.addAccountUserErrorAction, addAccountUserErrorHandler),
    on(actions.addAccountUserSuccessAction, addAccountUserSuccessHandler),

    on(actions.changeSelectedStateAction, changeSelectedStateHandler),
    on(actions.changeSeatTypeAction, changeSeatTypeHandler),
    on(actions.resetStateAction, resetStateHandler),
  );

export function addUserAccountReducer(
  _state: IAddUserAccountState,
  _action: Action,
): IAddUserAccountState {
  return reducer(_state, _action);
}

export const pending: GetFromState<boolean, IAddUserAccountState> = (
  _state: IAddUserAccountState,
): boolean => _state.pending;
export const error: GetFromState<IServerError, IAddUserAccountState> = (
  _state: IAddUserAccountState,
): IServerError => _state.error;

export const isShouldShowAvailableStates: GetFromState<
  boolean,
  IAddUserAccountState
> = (_state: IAddUserAccountState): boolean =>
  _state.isShouldShowAvailableStates;
export const seatId: GetFromState<SEATS, IAddUserAccountState> = (
  state: IAddUserAccountState,
): SEATS => state.seatId;

export const selectedAllStateIds: GetFromState<
  number[],
  IAddUserAccountState
> = (_state: IAddUserAccountState): number[] => [
  ..._state.selectedStateIds.available,
  ..._state.selectedStateIds.unassigned,
];
export const selectedAddOnsIds: GetFromState<
  ISelectedAddOnIds,
  IAddUserAccountState
> = (_state: IAddUserAccountState): ISelectedAddOnIds =>
  _state.selectedAddOnsIds;

export const availableUncheckedStates: GetFromState<
  IControlOptions,
  IAddUserAccountState,
  IControlOptions,
  IControlOptions,
  IOpenSeatStates,
  number[]
> = (
  _state: IAddUserAccountState,
  _states: IControlOptions = [],
  _availableStates: IControlOptions = [],
  _openStates: IOpenSeatStates,
  _selectedAllStateIds: number[] = [],
): IControlOptions => {
  if (_state.isShouldShowAvailableStates && _openStates.availableIds.length) {
    return _availableStates.filter(
      (_item: IControlOption) => !_selectedAllStateIds.includes(_item.value),
    );
  }

  if (_state.selectedStateIds.unassigned.length < _openStates.unassigned) {
    return _states.filter(
      (_item: IControlOption) => !_selectedAllStateIds.includes(_item.value),
    );
  }

  return [];
};

export const isShowAddState: GetFromState<
  boolean,
  IAddUserAccountState,
  IOpenSeatStates,
  number[]
> = (
  _state: IAddUserAccountState,
  _openStates: IOpenSeatStates,
  _selectedAllStateIds: number[],
): boolean => {
  return (
    _selectedAllStateIds.length <
    _openStates.availableIds.length + _openStates.unassigned
  );
};

export const isHasStates: GetFromState<boolean, IControlOptions> = (
  _states: IControlOptions,
): boolean => _states && _states.length > 0;

export const canCreateNewState: GetFromState<
  boolean,
  IAddUserAccountState,
  IOpenSeatStates
> = (_state: IAddUserAccountState, _openStates: IOpenSeatStates): boolean => {
  return (
    _state && _state.selectedStateIds.unassigned.length < _openStates.unassigned
  );
};

export const isShowAvailableStates: GetFromState<
  boolean,
  IAddUserAccountState,
  number[]
> = (_state: IAddUserAccountState, _statesAvailable: number[]): boolean =>
  _state && _state.isShouldShowAvailableStates && !!_statesAvailable.length;

export const addUserPopUpPayload: GetFromState<
  IAddUserPopUpPayload,
  IControlOptions,
  IOpenSeatStates,
  IOpenSeatAddOns,
  ICDLLists,
  boolean
> = (
  allStates: IControlOptions,
  openStates: IOpenSeatStates,
  openAddOns: IOpenSeatAddOns,
  cdlLists: ICDLLists,
  asAdmin: boolean,
): IAddUserPopUpPayload => ({
  allStates,
  openStates,
  openAddOns,
  cdlLists,
  asAdmin,
});
