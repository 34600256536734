import { IGetAlertHistoryQueryParams } from "../interfaces/alert-history";

import { SORT_DIRECTION } from "@shared/constants/sort";

export interface AlertHistoryTableHeader {
  key: string;
  title: string;
  sortable: boolean;
}

export enum ALERT_HISTORY_SORT {
  DATE = "date",
  NAME = "name",
}

export const ALERT__HISTORY_DEFAULT_OFFSET: number = 0;
export const ALERT__HISTORY_DEFAULT_LIMIT: number = 5;

export const DEFAULT_GET_ALERT_HISTORY_PARAMS: IGetAlertHistoryQueryParams = {
  offset: ALERT__HISTORY_DEFAULT_OFFSET,
  limit: ALERT__HISTORY_DEFAULT_LIMIT,
  sortBy: ALERT_HISTORY_SORT.DATE,
  orderBy: SORT_DIRECTION.ASC,
};

export const GRANT_ALERT_HISTORY_TABLE_HEADERS: AlertHistoryTableHeader[] = [
  {
    key: "name",
    title: "Alert",
    sortable: true,
  },
  {
    key: "date",
    title: "Date of Alert",
    sortable: true,
  },
];

export const MAX_GRANTS_ALERTS_HISTORY_CACHE: number = 180;
