import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

enum ACTION_TYPES {
  CHANGE_SORT_DIRECTION = "[CDL LIST HISTORY]: Change sort direction",
  CHANGE_SORT_BY = "[CDL LIST HISTORY]: Change sort by",

  SET_SORT_IS_OPEN_STATE = "[CDL LIST HISTORY]: Set sort is open state",
  RESET = "[CDL LIST HISTORY]: Reset sorting",
}

export const changeCDLListHistorySortDirectionAction: CreateActionType<
  ACTION_TYPES.CHANGE_SORT_DIRECTION,
  string
> = createAction(ACTION_TYPES.CHANGE_SORT_DIRECTION, createPayload<string>());
export const changeCDLListHistorySortByAction: CreateActionType<
  ACTION_TYPES.CHANGE_SORT_BY,
  string
> = createAction(ACTION_TYPES.CHANGE_SORT_BY, createPayload<string>());

export const setCDLListHistorySortOpenStateAction: CreateActionType<
  ACTION_TYPES.SET_SORT_IS_OPEN_STATE,
  boolean
> = createAction(ACTION_TYPES.SET_SORT_IS_OPEN_STATE, createPayload<boolean>());
export const resetCDLListHistorySortingAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
