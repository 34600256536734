import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";

import { fromEvent, merge, Subject } from "rxjs";
import { debounceTime, filter, takeUntil, tap } from "rxjs/operators";

import { WindowRef } from "@core/refs/window-ref.service";

export enum SUB_MENU_ALIGN {
  LEFT = "left",
  CENTER = "center",
  RIGHt = "right",
}

export enum SUB_MENU_VALIGN {
  TOP = "top",
  MIDDLE = "middle",
  BOTTOM = "bottom",
}

@Component({
  selector: "bl-submenu",
  templateUrl: "./submenu.component.html",
  styleUrls: ["./submenu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuComponent implements AfterViewInit, OnDestroy {
  @Input() @HostBinding("style.margin-top") offsetTop: string = "0px";
  @Input() @HostBinding("style.z-index") zIndex: number = null;
  @Input() align: SUB_MENU_ALIGN = SUB_MENU_ALIGN.RIGHt;
  @Input() valign: SUB_MENU_VALIGN = SUB_MENU_VALIGN.TOP;
  @Input() disableState: boolean;
  @Input() theme: string = "";

  @Output() isOpenedChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChild("contentWrap", { static: true, read: ElementRef })
  contentWrap: ElementRef;

  private _destroyer$: Subject<any> = new Subject<any>();

  isOpened: boolean = false;
  outsideTheScreenFromTheRight: boolean = false;

  constructor(
    private _window: WindowRef,
    private _elRef: ElementRef,
    private _chdRef: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    this.runListeners();

    if (this.isOpened) {
      this.setSubmenuPosition();
    }
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  runListeners(): void {
    const onScrollEvent: any = fromEvent(this._window.nativeElement, "scroll");
    const onResizeEvent: any = fromEvent(this._window.nativeElement, "resize");

    merge(...[onScrollEvent, onResizeEvent])
      .pipe(
        takeUntil(this._destroyer$),
        filter(() => this.isOpened),
        debounceTime(200),
        tap(() => (this.outsideTheScreenFromTheRight = false)),
      )
      .subscribe(() => this.setSubmenuPosition());

    fromEvent(this._elRef.nativeElement, "mouseenter")
      .pipe(
        takeUntil(this._destroyer$),
        filter(() => !this.disableState),
        tap(() => (this.outsideTheScreenFromTheRight = false)),
      )
      .subscribe(() => this.onOpenSubmenu());

    fromEvent(this._elRef.nativeElement, "mouseleave")
      .pipe(
        takeUntil(this._destroyer$),
        filter(() => !this.disableState),
        tap(() => (this.outsideTheScreenFromTheRight = false)),
      )
      .subscribe(() => this.onCloseSubmenu());
  }

  onOpenSubmenu(): void {
    this.isOpened = true;
    this.isOpenedChange.emit(this.isOpened);
    if (!this._chdRef["destroyed"]) {
      this._chdRef.detectChanges();
    }

    this.setSubmenuPosition();
  }

  onCloseSubmenu(): void {
    this.isOpened = false;
    this.isOpenedChange.emit(this.isOpened);

    if (!this._chdRef["destroyed"]) {
      this._chdRef.detectChanges();
    }
  }

  setSubmenuPosition = () => {
    const { right }: ClientRect =
      this.contentWrap.nativeElement.getBoundingClientRect();

    this.outsideTheScreenFromTheRight =
      this._window.nativeElement.innerWidth <= right;
    if (!this._chdRef["destroyed"]) {
      this._chdRef.detectChanges();
    }
  };
}
