import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/invitation.action";

import { MapInvitation } from "../../utils/invitation";

import { IInvitationUser } from "../../interfaces/invitation";

export interface IInvitationState {
  invitation: IInvitationUser | null;
}

const initialState: IInvitationState = {
  invitation: null,
};

const setInvitationToken: OnReducer<
  IInvitationState,
  ActionType<Payload<any>>
> = (state: IInvitationState, { payload }: Payload<any>) => ({
  ...state,
  invitation: { ...payload },
});

const updateInvitationToken: OnReducer<
  IInvitationState,
  ActionType<Payload<any>>
> = (state: IInvitationState, { payload: { newOwnerId } }: Payload<any>) => ({
  ...state,
  invitation: { ...state.invitation, newOwnerId },
});

const removeInvitationToken: OnReducer<IInvitationState, ActionType<any>> = (
  state: IInvitationState,
) => ({
  ...state,
  invitation: null,
});

const resetInvitationState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IInvitationState> =
  createReducer<IInvitationState>(
    initialState,
    on(actions.setInvitationTokenAction, setInvitationToken),
    on(actions.updateInvitationTokenAction, updateInvitationToken),
    on(actions.removeInvitationTokenAction, removeInvitationToken),
    on(actions.resetInvitationStateAction, resetInvitationState),
  );

export const invitationToken: GetFromState<
  IInvitationUser,
  IInvitationState
> = (state: IInvitationState): IInvitationUser =>
  state.invitation && new MapInvitation(state.invitation);

export function invitationReducer(
  state: IInvitationState,
  action: Action,
): IInvitationState {
  return reducer(state, action);
}
