import { createFeatureSelector, createSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { IAccountPopUpsState } from "@modules/profile/store/reducers/account-pop-ups.reducer";
import { IAccountUsersState } from "@modules/profile/store/reducers/account-users.reducer";
import { IAccountState } from "@modules/profile/store/reducers/account.reducer";
import { IAddUserAccountState } from "@modules/profile/store/reducers/add-user-account.reducer";
import { IEditUserAccountState } from "@modules/profile/store/reducers/edit-user-account.reducer";
import { IProfileState } from "@modules/profile/store/reducers/profile.reducer";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { IProfileModuleState } from "../reducers";

export const PROFILE_MODULE_FEATURE_NAME: string = "profileModule";
export const selectProfileModuleState: GetFromStateInSelectors<
  CoreState,
  IProfileModuleState
> = createFeatureSelector<IProfileModuleState>(PROFILE_MODULE_FEATURE_NAME);

export const selectProfileState: GetFromStateInSelectors<
  CoreState,
  IProfileState
> = createSelector(
  selectProfileModuleState,
  (state: IProfileModuleState) => state.profile,
);

export const selectAccountState: GetFromStateInSelectors<
  CoreState,
  IAccountState
> = createSelector(
  selectProfileModuleState,
  (state: IProfileModuleState) => state.account,
);

export const selectAccountUsersState: GetFromStateInSelectors<
  CoreState,
  IAccountUsersState
> = createSelector(
  selectProfileModuleState,
  (state: IProfileModuleState) => state.accountUsers,
);

export const selectAddUserState: GetFromStateInSelectors<
  CoreState,
  IAddUserAccountState
> = createSelector(
  selectProfileModuleState,
  (state: IProfileModuleState) => state.addUserAccount,
);

export const selectEditUserState: GetFromStateInSelectors<
  CoreState,
  IEditUserAccountState
> = createSelector(
  selectProfileModuleState,
  (state: IProfileModuleState) => state.editUserAccount,
);

export const selectAccountPopUpsState: GetFromStateInSelectors<
  CoreState,
  IAccountPopUpsState
> = createSelector(
  selectProfileModuleState,
  (state: IProfileModuleState) => state.accountPopUps,
);
