import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  viewAlertsOverviewReducer,
  IViewAlertsOverviewState,
} from "./view-alerts-overview.reducer";
import { viewAlertsReducer, IViewAlertsState } from "./view-alerts.reducer";

export interface IViewAlertsModuleState {
  viewAlerts: IViewAlertsState;
  viewAlertsOverview: IViewAlertsOverviewState;
}

const viewAlertsModuleState: ActionReducerMap<IViewAlertsModuleState> = {
  viewAlerts: viewAlertsReducer,
  viewAlertsOverview: viewAlertsOverviewReducer,
};

// eslint-disable-next-line
export const VIEW_ALERTS_STATE_NAME = 'ViewAlertsModule';
export const VIEW_ALERTS_STATE: InjectionToken<
  ActionReducerMap<IViewAlertsModuleState>
> = new InjectionToken<ActionReducerMap<IViewAlertsModuleState>>(
  VIEW_ALERTS_STATE_NAME,
  {
    factory: () => viewAlertsModuleState,
  },
);
