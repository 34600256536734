export enum DASHBOARD_ENTITY_TYPES {
  ALL = 0,
  LISTS = 1,
  VIEWS = 2,
  CUSTOM = 3,
  STRATEGIC = 4,
}

export const DASHBOARD_ENTITY_TYPES_MAP: number[] = [0, 1, 2];

export const DASHBOARD_SORT_DIRECTION: { DESC: string } = {
  DESC: "DESC",
};
export const DASHBOARD_SORT_BY: { CREATE_AT: string } = {
  CREATE_AT: "created_at",
};

export const DEFAULT_FILTER_QUERY_PARAMS: {
  showAll: boolean;
  entityType: DASHBOARD_ENTITY_TYPES[];
} = {
  entityType: [
    DASHBOARD_ENTITY_TYPES.LISTS,
    DASHBOARD_ENTITY_TYPES.STRATEGIC,
    DASHBOARD_ENTITY_TYPES.CUSTOM,
  ],
  showAll: true,
};

export const DASHBOARD_EMPTY_VIEW_DESCRIPTION: string =
  "Click “+ Create New View” to get started";
export const DASHBOARD_EMPTY_LIST_DESCRIPTION: string =
  "Click “+ Create List” to get started";
export const DASHBOARD_NO_CHANGES_DESCRIPTION: string = "No results";
