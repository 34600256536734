import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-views-filter.action";

import { SessionStorageService } from "@core/services/session-storage.service";

import { IServerError } from "@shared/interfaces/server-error";
import { IFilters, IFiltersResult } from "../../../dashboard/interfaces";

export interface IDashboardViewsFilterState {
  filters: IFilters;
  checkedFilters: IFiltersResult | null;
  isHasCheckedFilters: boolean;
  loaded: boolean;
  loading: boolean;
  error: IServerError;
}

const initialState: IDashboardViewsFilterState = {
  filters: null,
  checkedFilters: SessionStorageService.dashboardViewsFiltersChecked || null,
  isHasCheckedFilters: null,
  loaded: false,
  loading: false,
  error: null,
};

const getDashboardViewsFilter: OnReducer<
  IDashboardViewsFilterState,
  ActionType<any>
> = (state: IDashboardViewsFilterState) => ({
  ...state,
  loading: true,
  error: null,
});

const getDashboardViewsFilterError: OnReducer<
  IDashboardViewsFilterState,
  ActionType<Payload<IServerError>>
> = (
  state: IDashboardViewsFilterState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getDashboardViewsFilterSuccess: OnReducer<
  IDashboardViewsFilterState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsFilterState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  loaded: true,
  filters: { ...payload },
  error: null,
});

const updateDashboardViewsFilter: OnReducer<
  IDashboardViewsFilterState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsFilterState, { payload }: Payload<any>) => ({
  ...state,
  checkedFilters: payload ? { ...state.checkedFilters, ...payload } : null,
  error: null,
});

const updateDashboardViewFilterSuccess: OnReducer<
  IDashboardViewsFilterState,
  ActionType<any>
> = (state: IDashboardViewsFilterState) => ({
  ...state,
  isHasCheckedFilters: !!state.checkedFilters,
});

const resetDashboardViewsFilter: OnReducer<
  IDashboardViewsFilterState,
  ActionType<any>
> = (state: IDashboardViewsFilterState) => ({
  ...state,
  loaded: false,
  filters: null,
  error: null,
});

const resetDashboardViewsCheckedFilter: OnReducer<
  IDashboardViewsFilterState,
  ActionType<any>
> = (state: IDashboardViewsFilterState) => ({
  ...state,
  checkedFilters: null,
  error: null,
});

const resetDashboardViewsState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDashboardViewsFilterState> =
  createReducer<IDashboardViewsFilterState>(
    initialState,

    on(actions.getDashboardViewsFilterAction, getDashboardViewsFilter),
    on(
      actions.getDashboardViewsFilterErrorAction,
      getDashboardViewsFilterError,
    ),
    on(
      actions.getDashboardViewsFilterSuccessAction,
      getDashboardViewsFilterSuccess,
    ),

    on(actions.updateDashboardViewsFilterAction, updateDashboardViewsFilter),
    on(
      actions.updateDashboardViewFilterSuccessAction,
      updateDashboardViewFilterSuccess,
    ),

    on(actions.resetDashboardViewsFilterAction, resetDashboardViewsFilter),
    on(
      actions.resetDashboardViewsCheckedFilterAction,
      resetDashboardViewsCheckedFilter,
    ),

    on(actions.resetDashboardViewsStateAction, resetDashboardViewsState),
  );

export const filters: GetFromState<IFilters, IDashboardViewsFilterState> = (
  state: IDashboardViewsFilterState,
): IFilters => state.filters;
export const checkedFilters: GetFromState<
  IFiltersResult | null,
  IDashboardViewsFilterState
> = (state: IDashboardViewsFilterState): IFiltersResult | null =>
  state.checkedFilters;
export const isHasCheckedFilters: GetFromState<
  boolean,
  IDashboardViewsFilterState
> = (state: IDashboardViewsFilterState): boolean => state.isHasCheckedFilters;

export function dashboardViewsFilterReducer(
  state: IDashboardViewsFilterState,
  action: Action,
): IDashboardViewsFilterState {
  return reducer(state, action);
}
