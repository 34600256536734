import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import {
  IParentInstitutionOnlyConfig,
  IParentInstitutionOnlyResult,
} from "../../interfaces";

import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-parent-institution-only",
  templateUrl: "./parent-institution-only.component.html",
  styleUrls: ["./parent-institution-only.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParentInstitutionOnlyComponent {
  forAll: boolean = null;

  constructor(
    private _ref: PopUpRef,
    @Inject(POP_UP_DATA) public config: IParentInstitutionOnlyConfig,
  ) {}

  onCloseClick(): void {
    this.close(null);
  }

  onParentOnlyClick(): void {
    this.close({
      includeChildren: false,
      ...(!this.config.withoutCheckbox && {
        includeChildrenAll: !!this.forAll,
      }),
    });
  }

  onParentAndChildrenClick(): void {
    this.close({
      includeChildren: true,
      ...(!this.config.withoutCheckbox && {
        includeChildrenAll: !!this.forAll,
      }),
    });
  }

  close(resultData: IParentInstitutionOnlyResult): void {
    this._ref.close(resultData);
  }
}
