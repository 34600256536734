export function isIOSDevice(): boolean {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isMobile(): boolean {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

export function isWindowsOS(): boolean {
  return /Win/i.test(navigator.userAgent);
}

export function isTouchDevice(): boolean {
  return "ontouchstart" in window;
}
