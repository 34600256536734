import { createFeatureSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { COUNTRIES_STATE_NAME, ICountriesModuleState } from "../reducers";

export const getCountriesModuleState: GetFromStateInSelectors<
  CoreState,
  ICountriesModuleState
> = createFeatureSelector<ICountriesModuleState>(COUNTRIES_STATE_NAME);
