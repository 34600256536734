import { Component, Input, OnInit } from "@angular/core";
import { ThemePalette } from "@angular/material/core";

import { ICriteriaValues } from "@shared/interfaces/forms";

import { PASSWORD_CRITERIA_TYPE } from "@shared/constants/flat-input";
import { VALIDATION_REG_EXP } from "@shared/constants/validators/validation-reg-exp";
import { ProgressBarMode } from "@angular/material/progress-bar";

@Component({
  selector: "bl-password-policy",
  templateUrl: "./password-policy.component.html",
  styleUrls: ["./password-policy.component.scss"],
})
export class PasswordPolicyComponent {
  containAtLeastMinChars: boolean;
  containAtLeastOneDigit: boolean;
  containAtLeastOneLowerCaseLetter: boolean;
  containAtLeastOneUpperCaseLetter: boolean;
  containAtLeastOneSpecialChar: boolean;
  criteriaStrength: ICriteriaValues = {};

  // below attributes needed for passwordPolicy of password fields

  // make them @Input if dynamicism is needed from the owner component
  private readonly passWordPolicyMin: number = 10;
  private readonly enableDigitRule: boolean = true;
  private readonly enableLowerCaseLetterRule: boolean = true;
  private readonly enableSpecialCharRule: boolean = true;
  private readonly enableUpperCaseLetterRule: boolean = true;

  color: ThemePalette = "warn";
  readonly mode: ProgressBarMode = "determinate";
  progressPercentage: number = 0;
  // above attributes needed for passwordPolicy of password fields

  constructor() {}

  passwordStrength(): void {
    const criteria: number = Object.keys(this.criteriaStrength).length;
    this.progressPercentage = (criteria / 5) * 100;

    if (0 <= this.progressPercentage && this.progressPercentage <= 50) {
      this.color = "warn";
    }
    if (criteria >= 5) {
      this.progressPercentage = 100;
    }
  }

  passwordRequirements(value: string): void {
    this.minimumPassword(value);
    this.digits(value);
    this.lowerCase(value);
    this.upperCase(value);
    this.symbols(value);
    this.passwordStrength();
  }

  assignCriteria(
    enableRule: boolean,
    containAtLeast: boolean,
    type: string,
  ): void {
    if (enableRule) {
      if (containAtLeast) {
        this.criteriaStrength[type] = true;
      } else {
        delete this.criteriaStrength[type];
      }
    }
  }

  validateRegExp(value: string, type: string): boolean {
    return VALIDATION_REG_EXP[type].test(value);
  }

  digits(value: string): void {
    this.containAtLeastOneDigit = this.validateRegExp(
      value,
      PASSWORD_CRITERIA_TYPE.DIGIT,
    );
    this.assignCriteria(
      this.enableDigitRule,
      this.containAtLeastOneDigit,
      PASSWORD_CRITERIA_TYPE.DIGIT,
    );
  }

  lowerCase(value: string): void {
    this.containAtLeastOneLowerCaseLetter = this.validateRegExp(
      value,
      PASSWORD_CRITERIA_TYPE.LOWER,
    );
    this.assignCriteria(
      this.enableLowerCaseLetterRule,
      this.containAtLeastOneLowerCaseLetter,
      PASSWORD_CRITERIA_TYPE.LOWER,
    );
  }

  upperCase(value: string): void {
    this.containAtLeastOneUpperCaseLetter = this.validateRegExp(
      value,
      PASSWORD_CRITERIA_TYPE.UPPER,
    );
    this.assignCriteria(
      this.enableUpperCaseLetterRule,
      this.containAtLeastOneUpperCaseLetter,
      PASSWORD_CRITERIA_TYPE.UPPER,
    );
  }

  symbols(value: string): void {
    this.containAtLeastOneSpecialChar = this.validateRegExp(
      value,
      PASSWORD_CRITERIA_TYPE.SYMBOL,
    );
    this.assignCriteria(
      this.enableSpecialCharRule,
      this.containAtLeastOneSpecialChar,
      PASSWORD_CRITERIA_TYPE.SYMBOL,
    );
  }

  minimumPassword(value: string): void {
    this.containAtLeastMinChars = value.length >= this.passWordPolicyMin;
    // set flag dynamically if required
    this.assignCriteria(
      true,
      this.containAtLeastMinChars,
      PASSWORD_CRITERIA_TYPE.MIN,
    );
  }
}
