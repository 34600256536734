import {
  IRole,
  IUpdateICreditsResponse,
  IUser,
} from "@modules/auth/interfaces/user";
import { IPrice } from "@modules/e-commerce/interfaces/price";
import { ICDLLists } from "@shared/interfaces/manage-account-lists";
import { IChildInstitutionOnlyResult, IPopUp } from "@ui/pop-up/interfaces";

import { DEFAULT_CREDITS_DATA } from "@modules/profile/constants/credits";
import { LOCAL_STORAGE_FIELDS as fields } from "@shared/constants/local-storage-fields";

export class StorageService {
  private static set(
    name: Partial<fields>,
    value: any,
    storage: Storage = localStorage,
  ): void {
    storage.setItem(name, JSON.stringify(value));
  }

  private static get<T>(name: string, storage: Storage = localStorage): T {
    const rawValue: string = storage.getItem(name);

    let value: any;

    try {
      value = JSON.parse(rawValue);
    } catch (e) {
      value = rawValue || null;
    }

    return value;
  }

  private static remove(name: string, storage: Storage = localStorage): void {
    storage.removeItem(name);
  }

  // GETTERS AND SETTERS
  static get token(): string {
    return StorageService.get(fields.token);
  }

  static set token(token: string) {
    StorageService.set(fields.token, token);
  }

  static get refreshToken(): string {
    return StorageService.get(fields.refreshToken);
  }

  static set refreshToken(refreshToken: string) {
    StorageService.set(fields.refreshToken, refreshToken);
  }

  static get fromAdmin(): boolean {
    return StorageService.get(fields.fromAdmin);
  }

  static set fromAdmin(fromAdmin: boolean) {
    StorageService.set(fields.fromAdmin, fromAdmin);
  }

  static get expiryDuration(): number {
    return StorageService.get(fields.expiryDuration);
  }

  static set expiryDuration(expiryDuration: number) {
    StorageService.set(fields.expiryDuration, expiryDuration);
  }

  static get loggedInTime(): Date {
    return StorageService.get(fields.loggedInTime);
  }

  static set loggedInTime(loggedInTime: Date) {
    StorageService.set(fields.loggedInTime, loggedInTime);
  }

  static get refreshTokenCreatedDate(): Date {
    return StorageService.get(fields.refreshTokenCreatedDate);
  }

  static set refreshTokenCreatedDate(refreshTokenCreatedDate: Date) {
    StorageService.set(fields.refreshTokenCreatedDate, refreshTokenCreatedDate);
  }

  static get idleTimerPopUp(): Boolean {
    return StorageService.get(fields.idleTimerPopUp);
  }

  static set idleTimerPopUp(idleTimerPopUp: Boolean) {
    StorageService.set(fields.idleTimerPopUp, idleTimerPopUp);
  }

  static get id(): number {
    return StorageService.get(fields.id);
  }

  static set id(id: number) {
    StorageService.set(fields.id, id);
  }

  static get remember(): boolean {
    return !!StorageService.get(fields.remember);
  }

  static set remember(remember: boolean) {
    StorageService.set(fields.remember, remember);
  }

  static get firstSignUp(): boolean {
    return !!StorageService.get(fields.firstLogin);
  }

  static set firstSignUp(state: boolean) {
    StorageService.set(fields.firstLogin, state);
  }

  static get credits(): IUpdateICreditsResponse {
    return StorageService.get(fields.credits);
  }

  static set credits(credits: IUpdateICreditsResponse) {
    StorageService.set(
      fields.credits,
      credits
        ? {
            ...credits,
            ...StorageService.credits,
          }
        : { ...DEFAULT_CREDITS_DATA },
    );
  }

  static get user(): IUser {
    return StorageService.get(fields.user);
  }

  static set user(user: IUser) {
    StorageService.set(fields.user, user);
  }

  static get hasLists(): boolean {
    return StorageService.get(fields.hasLists);
  }

  static set hasLists(state: boolean) {
    StorageService.set(fields.hasLists, state);
  }

  static get cdlLists(): ICDLLists {
    return StorageService.get(fields.cdlLists);
  }

  static set cdlLists(date: ICDLLists) {
    StorageService.set(fields.cdlLists, date);
  }

  static get isAdmin(): boolean {
    return !!StorageService.get(fields.isAdmin);
  }

  static set isBroker(state: boolean) {
    StorageService.set(fields.isBroker, state);
  }

  static get isBroker(): boolean {
    return !!StorageService.get(fields.isBroker);
  }

  static set isBrokerLicenseAccepted(state: boolean) {
    StorageService.set(fields.isBrokerLicenseAccepted, state);
  }

  static get isBrokerLicenseAccepted(): boolean {
    return StorageService.get(fields.isBrokerLicenseAccepted);
  }

  static set isAdmin(isAdmin: boolean) {
    StorageService.set(fields.isAdmin, isAdmin);
  }

  static get isAdminsAccount(): boolean {
    return !!StorageService.get(fields.isAdminsAccount);
  }

  static set isAdminsAccount(isAdminsAccount: boolean) {
    StorageService.set(fields.isAdminsAccount, isAdminsAccount);
  }

  static get isUnlimited(): boolean {
    return !!StorageService.get(fields.isUnlimited);
  }

  static set isUnlimited(isUnlimited: boolean) {
    StorageService.set(fields.isUnlimited, isUnlimited);
  }

  static get doNotShowDeleteSegmentPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowDeleteSegmentPopUp);
  }

  static set doNotShowDeleteSegmentPopUp(state: boolean) {
    StorageService.set(fields.doNotShowDeleteSegmentPopUp, state);
  }

  static get doNotShowClearTargetingPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowClearTargetingPopUp);
  }

  static set doNotShowClearTargetingPopUp(state: boolean) {
    StorageService.set(fields.doNotShowClearTargetingPopUp, state);
  }

  static get doNotShowDeleteListPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowDeleteListPopUp);
  }

  static set doNotShowDeleteListPopUp(state: boolean) {
    StorageService.set(fields.doNotShowDeleteListPopUp, state);
  }

  static get doNotShowDeleteCustomListPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowDeleteCustomListPopUp);
  }

  static set doNotShowDeleteCustomListPopUp(state: boolean) {
    StorageService.set(fields.doNotShowDeleteCustomListPopUp, state);
  }

  static get doNotShowDeleteCDLListPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowDeleteCDLListPopUp);
  }

  static set doNotShowDeleteCDLListPopUp(state: boolean) {
    StorageService.set(fields.doNotShowDeleteCDLListPopUp, state);
  }

  static get doNotShowRefreshListPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowRefreshListPopUp);
  }

  static set doNotShowRefreshListPopUp(state: boolean) {
    StorageService.set(fields.doNotShowRefreshListPopUp, state);
  }

  static get doNotShowConfirmListPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowConfirmListPopUp);
  }

  static set doNotShowConfirmListPopUp(state: boolean) {
    StorageService.set(fields.doNotShowConfirmListPopUp, state);
  }

  static get doNotShowConfirmPricingCardPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowConfirmPricingCardPopUp);
  }

  static set doNotShowConfirmPricingCardPopUp(state: boolean) {
    StorageService.set(fields.doNotShowConfirmPricingCardPopUp, state);
  }

  static set doNotShowSegmentByConfirmationPopUp(state: boolean) {
    StorageService.set(fields.doNotShowSegmentByConfirmationPopUp, state);
  }

  static get doNotShowSegmentByConfirmationPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowSegmentByConfirmationPopUp);
  }

  static get doNotShowDisabledButtonsTooltips(): boolean {
    return !!StorageService.get(fields.doNotShowDisabledTooltips);
  }

  static set doNotShowDisabledButtonsTooltips(state: boolean) {
    StorageService.set(fields.doNotShowDisabledTooltips, state);
  }

  static get doNotShowDisabledCategoriesButtonsTooltips(): boolean {
    return !!StorageService.get(fields.doNotShowDisabledCategoriesTooltips);
  }

  static set doNotShowDisabledCategoriesButtonsTooltips(state: boolean) {
    StorageService.set(fields.doNotShowDisabledCategoriesTooltips, state);
  }

  static get doNotShowDetailsTargetingWarningMsg(): boolean {
    return !!StorageService.get(fields.doNotShowDetailsTargetingWarningMsg);
  }

  static set doNotShowDetailsTargetingWarningMsg(state: boolean) {
    StorageService.set(fields.doNotShowDetailsTargetingWarningMsg, state);
  }

  static get doNotShowSliderTooltip(): boolean {
    return !!StorageService.get(fields.doNotShowSliderTooltip);
  }

  static set doNotShowSliderTooltip(state: boolean) {
    StorageService.set(fields.doNotShowSliderTooltip, state);
  }

  static get doNotShowGeoSuggestTooltip(): boolean {
    return !!StorageService.get(fields.doNotShowGeoSuggestTooltip);
  }

  static set doNotShowGeoSuggestTooltip(state: boolean) {
    StorageService.set(fields.doNotShowGeoSuggestTooltip, state);
  }

  static get doNotShowWelcomePopUp(): boolean {
    return !!StorageService.get(fields.doNotShowWelcomePopUp);
  }

  static set doNotShowWelcomePopUp(state: boolean) {
    StorageService.set(fields.doNotShowWelcomePopUp, state);
  }

  static get doNotShowAssignStatePopUp(): boolean {
    return !!StorageService.get(fields.doNotShowAssignStatePopUp);
  }

  static set doNotShowAssignStatePopUp(state: boolean) {
    StorageService.set(fields.doNotShowAssignStatePopUp, state);
  }

  static get doNotShowAssignAddOnPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowAssignAddOnPopUp);
  }

  static set doNotShowAssignAddOnPopUp(state: boolean) {
    StorageService.set(fields.doNotShowAssignAddOnPopUp, state);
  }

  static get doNotShowEditSelfPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowEditSelfPopUp);
  }

  static set doNotShowEditSelfPopUp(state: boolean) {
    StorageService.set(fields.doNotShowEditSelfPopUp, state);
  }

  static get doNotShowWelcomeWithMarketViewPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowWelcomeWithMarketViewPopUp);
  }

  static set doNotShowWelcomeWithMarketViewPopUp(state: boolean) {
    StorageService.set(fields.doNotShowWelcomeWithMarketViewPopUp, state);
  }

  static get doNotShowUnsupportedBrowserPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowUnsupportedBrowserPopUp);
  }

  static set doNotShowUnsupportedBrowserPopUp(state: boolean) {
    StorageService.set(fields.doNotShowUnsupportedBrowserPopUp, state);
  }

  static get doNotShowViewResultsTablePopUp(): boolean {
    return !!StorageService.get(fields.doNotShowViewResultsTablePopUp);
  }

  static set doNotShowViewResultsTablePopUp(state: boolean) {
    StorageService.set(fields.doNotShowViewResultsTablePopUp, state);
  }

  static get doNotShowPersonnelEntityPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowPersonnelEntityPopUp);
  }

  static set doNotShowPersonnelEntityPopUp(state: boolean) {
    StorageService.set(fields.doNotShowPersonnelEntityPopUp, state);
  }

  static get doNotShowStateDepartmentPersonnelPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowDepartmentPersonnelPopUp);
  }

  static set doNotShowStateDepartmentPersonnelPopUp(state: boolean) {
    StorageService.set(fields.doNotShowDepartmentPersonnelPopUp, state);
  }

  static get doNotShowCollegePersonnelPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowCollegePersonnelPopUp);
  }

  static set doNotShowCollegePersonnelPopUp(state: boolean) {
    StorageService.set(fields.doNotShowCollegePersonnelPopUp, state);
  }

  static get doNotShowCollegeOfficePersonnelPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowCollegeOfficePersonnelPopUp);
  }

  static set doNotShowCollegeOfficePersonnelPopUp(state: boolean) {
    StorageService.set(fields.doNotShowCollegeOfficePersonnelPopUp, state);
  }

  static get doNotShowCmoPersonnelPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowCmoPersonnelPopUp);
  }

  static set doNotShowCmoPersonnelPopUp(state: boolean) {
    StorageService.set(fields.doNotShowCmoPersonnelPopUp, state);
  }

  static get doNotShowBidPersonnelPopUp(): boolean {
    return !!StorageService.get(fields.doNotShowBidPersonnelPopUp);
  }

  static set doNotShowBidPersonnelPopUp(state: boolean) {
    StorageService.set(fields.doNotShowBidPersonnelPopUp, state);
  }

  static set doNotShowCustomizeResetDataPopUp(state: boolean) {
    StorageService.set(fields.doNotCustomizeResetDataPopUp, state);
  }

  static get doNotShowCustomizeResetDataPopUp(): boolean {
    return !!StorageService.get(fields.doNotCustomizeResetDataPopUp);
  }

  static get eCommerceCart(): IPrice[] {
    return StorageService.get(fields.eCommerceCart);
  }

  static set eCommerceCart(cart: IPrice[]) {
    StorageService.set(fields.eCommerceCart, cart);
  }

  static get role(): IRole {
    return StorageService.get(fields.role);
  }

  static set role(role: IRole) {
    StorageService.set(fields.role, role);
  }

  static get doNotShowDeleteViewPopUp(): boolean {
    return StorageService.get(fields.doNotShowDeleteViewPopUp);
  }

  static set doNotShowDeleteViewPopUp(role: boolean) {
    StorageService.set(fields.doNotShowDeleteViewPopUp, role);
  }

  static get permissions(): string[] {
    return StorageService.get(fields.permissions) || [];
  }

  static set permissions(permissions: string[]) {
    StorageService.set(fields.permissions, permissions);
  }

  static get accountAvailablePermissions(): string[] {
    return StorageService.get(fields.accountAvailablePermissions) || [];
  }

  static set accountAvailablePermissions(
    _accountAvailablePermissions: string[],
  ) {
    StorageService.set(
      fields.accountAvailablePermissions,
      _accountAvailablePermissions,
    );
  }

  static set refreshCreditsTooltipWasShowed(state: boolean) {
    StorageService.set(fields.refreshCreditsTooltipWasShowed, state);
  }

  static get refreshCreditsTooltipWasShowed(): boolean {
    return !!StorageService.get(fields.refreshCreditsTooltipWasShowed);
  }

  static get doNotShowPersonnelDaycarePopUp(): boolean {
    return !!StorageService.get(fields.doNotShowPersonnelDaycarePopUp);
  }

  static set doNotShowPersonnelDaycarePopUp(state: boolean) {
    StorageService.set(fields.doNotShowPersonnelDaycarePopUp, state);
  }

  static get trialSeatExpirationDate(): number {
    const date: Date = StorageService.get(fields.trialSeatExpirationDate);
    return date ? Number(date) : null;
  }

  static set trialSeatExpirationDate(date: number) {
    StorageService.set(fields.trialSeatExpirationDate, date);
  }

  static set isFirstAssignAvailable(state: boolean) {
    StorageService.set(fields.isFirstAssignAvailable, state);
  }

  static get isFirstAssignAvailable(): boolean {
    return !!StorageService.get(fields.isFirstAssignAvailable);
  }

  static set activeDashboardTab(state: string) {
    StorageService.set(fields.activeDashboardTab, state);
  }

  static get activeDashboardTab(): string {
    return StorageService.get(fields.activeDashboardTab);
  }

  static set activeCDLTab(state: number) {
    StorageService.set(fields.activeCDLTab, state);
  }

  static get activeCDLTab(): number {
    return StorageService.get(fields.activeCDLTab);
  }

  static get parentInstitutionIncludeChildren(): boolean {
    return StorageService.get(fields.parentInstitutionIncludeChildren);
  }

  static set parentInstitutionIncludeChildren(state: boolean) {
    StorageService.set(fields.parentInstitutionIncludeChildren, state);
  }

  static get parentInstitutionIncludeChildrenAll(): boolean {
    return StorageService.get(fields.parentInstitutionIncludeChildrenAll);
  }

  static set parentInstitutionIncludeChildrenAll(state: boolean) {
    StorageService.set(fields.parentInstitutionIncludeChildrenAll, state);
  }

  static get childInstitutionIncludeParents(): IChildInstitutionOnlyResult {
    return StorageService.get(fields.childInstitutionIncludeParents);
  }

  static set childInstitutionIncludeParents(
    state: IChildInstitutionOnlyResult,
  ) {
    StorageService.set(fields.childInstitutionIncludeParents, state);
  }

  static get childInstitutionIncludeParentsAll(): boolean {
    return StorageService.get(fields.childInstitutionIncludeParentsAll);
  }

  static set childInstitutionIncludeParentsAll(state: boolean) {
    StorageService.set(fields.childInstitutionIncludeParentsAll, state);
  }

  static get removeParentPIDChildren(): boolean {
    return StorageService.get(fields.removeParentPIDChildren);
  }

  static set removeParentPIDChildren(state: boolean) {
    StorageService.set(fields.removeParentPIDChildren, state);
  }

  static get removeParentPIDChildrenAll(): boolean {
    return StorageService.get(fields.removeParentPIDChildrenAll);
  }

  static set removeParentPIDChildrenAll(state: boolean) {
    StorageService.set(fields.removeParentPIDChildrenAll, state);
  }

  static get popUpNotifications(): IPopUp[] {
    return StorageService.get(fields.popUpNotifications);
  }

  static set popUpNotifications(notifications: IPopUp[]) {
    StorageService.set(fields.popUpNotifications, notifications);
  }

  static get doNotShowPremiumCheckboxPopUp(): boolean {
    return StorageService.get(fields.doNotShowPremiumCheckboxPopUp);
  }

  static set doNotShowPremiumCheckboxPopUp(state: boolean) {
    StorageService.set(fields.doNotShowPremiumCheckboxPopUp, state);
  }

  static get doNotShowPremiumRadioPopUp(): boolean {
    return StorageService.get(fields.doNotShowPremiumRadioPopUp);
  }

  static set doNotShowPremiumRadioPopUp(state: boolean) {
    StorageService.set(fields.doNotShowPremiumRadioPopUp, state);
  }

  static get doNotShowPremiumRadioButtonPopUp(): boolean {
    return StorageService.get(fields.doNotShowPremiumRadioButtonPopUp);
  }

  static set doNotShowPremiumRadioButtonPopUp(state: boolean) {
    StorageService.set(fields.doNotShowPremiumRadioButtonPopUp, state);
  }

  static get doNotShowPremiumScrollerPopUp(): boolean {
    return StorageService.get(fields.doNotShowPremiumScrollerPopUp);
  }

  static set doNotShowPremiumScrollerPopUp(state: boolean) {
    StorageService.set(fields.doNotShowPremiumScrollerPopUp, state);
  }

  static get doNotShowPremiumCustomDropDownPopUp(): boolean {
    return StorageService.get(fields.doNotShowPremiumCustomDropDownPopUp);
  }

  static set doNotShowPremiumCustomDropDownPopUp(state: boolean) {
    StorageService.set(fields.doNotShowPremiumCustomDropDownPopUp, state);
  }

  static get doNotShowMVRenewalPopUp(): boolean {
    return StorageService.get(fields.doNotShowMVRenewalPopUp);
  }

  static set doNotShowMVRenewalPopUp(state: boolean) {
    StorageService.set(fields.doNotShowMVRenewalPopUp, state);
  }

  static get doNotShowIndefinitelyMVRenewalPopUp(): boolean {
    return StorageService.get(fields.doNotShowIndefinitelyMVRenewalPopUp);
  }

  static set doNotShowIndefinitelyMVRenewalPopUp(state: boolean) {
    StorageService.set(fields.doNotShowIndefinitelyMVRenewalPopUp, state);
  }

  static get cloudSyncForProducts(): boolean {
    return StorageService.get(fields.cloudSyncForProducts);
  }

  static set cloudSyncForProducts(state: boolean) {
    StorageService.set(fields.cloudSyncForProducts, state);
  }

  static get verifyStatus(): boolean {
    return StorageService.get(fields.verifyStatus);
  }

  static set verifyStatus(verifyStatus: boolean) {
    StorageService.set(fields.verifyStatus, verifyStatus);
  }

  static clearStorage(fieldDoNotRemove?: string[]): void {
    Object.values(fields).forEach((name: string) => {
      if (!fieldDoNotRemove.includes(name)) {
        StorageService.remove(name);
      }
    });
  }
}
