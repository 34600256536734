import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import * as editUserAccount from "../reducers/edit-user-account.reducer";

import {
  IAccountUser,
  IAccountUsers,
  IEditAccountUser,
  IEditUserPopUpPayload,
  ISelectedAddOnIds,
} from "@modules/profile/interfaces/marketview";
import { IControlOptions } from "@shared/interfaces/forms";
import { IServerError } from "@shared/interfaces/server-error";

import { getAccountUsers } from "./account-users.selector";
import {
  getAvailableStates,
  getBaseSeats,
  getOpenAddOns,
  getOpenStates,
  getOpenStateIdsAvailable,
  getStates,
} from "./account.selector";
import { selectEditUserState } from "./index";
import {
  getCDLListPermissions,
  getIsAdmin,
  getProfileUserId,
  getRoleKey,
} from "./profile.selector";

export const getUser: MemoizedSelector<CoreState, IEditAccountUser> =
  createSelector(selectEditUserState, editUserAccount.user);
export const getSeatId: MemoizedSelector<CoreState, number> = createSelector(
  getUser,
  editUserAccount.seatId,
);
export const getUserStatus: MemoizedSelector<CoreState, number> =
  createSelector(getUser, editUserAccount.userStatus);

export const getUserId: MemoizedSelector<CoreState, number> = createSelector(
  getUser,
  editUserAccount.userId,
);
export const getRoleId: MemoizedSelector<CoreState, number> = createSelector(
  getUser,
  editUserAccount.roleId,
);

export const getUsersWithoutEditingUserId: MemoizedSelector<
  CoreState,
  IAccountUsers
> = createSelector(
  getAccountUsers,
  getUserId,
  editUserAccount.usersWithoutEditingUserId,
);
export const getUsersWithoutEditingUserAndInvited: MemoizedSelector<
  CoreState,
  IAccountUsers
> = createSelector(
  getAccountUsers,
  getUserId,
  editUserAccount.usersWithoutEditingUserAndInvited,
);
export const getCurrentUserOpened: MemoizedSelector<CoreState, IAccountUser> =
  createSelector(getAccountUsers, getUserId, editUserAccount.currentUserOpened);

export const getIsEditSelf: MemoizedSelector<CoreState, boolean> =
  createSelector(getUserId, getProfileUserId, editUserAccount.isEditSelf);

export const getPending: MemoizedSelector<CoreState, boolean> = createSelector(
  selectEditUserState,
  editUserAccount.pending,
);
export const getError: MemoizedSelector<CoreState, IServerError> =
  createSelector(selectEditUserState, editUserAccount.error);

export const getPendingInvitation: MemoizedSelector<CoreState, boolean> =
  createSelector(selectEditUserState, editUserAccount.pendingInvitation);

export const getSelectedAllStateIds: MemoizedSelector<CoreState, number[]> =
  createSelector(selectEditUserState, editUserAccount.selectedAllStateIds);
export const getSelectedAddOnsIds: MemoizedSelector<
  CoreState,
  ISelectedAddOnIds
> = createSelector(selectEditUserState, editUserAccount.selectedAddOnsIds);
export const getIsShouldShowAvailableStates: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(
  selectEditUserState,
  editUserAccount.isShouldShowAvailableSeatStates,
);
export const getUncheckedStatesOrSeatStates: MemoizedSelector<
  CoreState,
  IControlOptions
> = createSelector(
  selectEditUserState,
  getStates,
  getAvailableStates,
  getOpenStates,
  getSelectedAllStateIds,
  editUserAccount.availableUncheckedStates,
);

export const getIsHasStates: MemoizedSelector<CoreState, boolean> =
  createSelector(getStates, editUserAccount.isHasStates);
export const getIsShowAvailableStates: MemoizedSelector<CoreState, boolean> =
  createSelector(
    selectEditUserState,
    getOpenStateIdsAvailable,
    editUserAccount.isShowAvailableStates,
  );

export const getCanCreateNewState: MemoizedSelector<CoreState, boolean> =
  createSelector(
    selectEditUserState,
    getOpenStates,
    editUserAccount.canCreateNewState,
  );
export const getIsShowAddState: MemoizedSelector<CoreState, boolean> =
  createSelector(
    selectEditUserState,
    getOpenStates,
    getSelectedAllStateIds,
    editUserAccount.isShowAddState,
  );

export const getUserSelectedSeat: MemoizedSelector<CoreState, number> =
  createSelector(getCurrentUserOpened, editUserAccount.userSelectedSeat);

export const getBaseSeatsOptions: MemoizedSelector<CoreState, IControlOptions> =
  createSelector(
    getBaseSeats,
    getUserSelectedSeat,
    editUserAccount.baseSeatsOptions,
  );

export const getCanDeleteUser: MemoizedSelector<CoreState, boolean> =
  createSelector(getRoleKey, getRoleId, editUserAccount.canRemoveUsers);
export const getCanChangePermissions: MemoizedSelector<CoreState, boolean> =
  createSelector(getRoleKey, getRoleId, editUserAccount.canChangePermissions);

export const getIsDisableDeleteBtn: MemoizedSelector<CoreState, boolean> =
  createSelector(
    getUserStatus,
    getCanDeleteUser,
    editUserAccount.isDisableDeleteBtn,
  );

export const getEditUserPopUpPayload: MemoizedSelector<
  CoreState,
  IEditUserPopUpPayload
> = createSelector(
  getIsEditSelf,
  getStates,
  getOpenStates,
  getOpenAddOns,
  getCDLListPermissions,
  getIsAdmin,
  editUserAccount.editUserPopUpPayload,
);
