// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/sidenav/components/side-nav-content/side-nav-content.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AACF","sourcesContent":[":host {\n  display: block;\n  height: 100%;\n  overflow-y: auto;\n  overflow-x: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
