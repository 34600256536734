import { ITargetingType } from "@shared/interfaces/list";

import { GeoEntitiesTypes } from "@modules/segment/constants/geography";
import {
  TARGETING_TYPES_DESCRIPTION,
  TARGETING_TYPES_DETAILS,
  TARGETING_TYPES_ID,
  TARGETING_TYPES_ID_CODE,
} from "@shared/constants/data/list-types";

export const TARGETING_TYPES: ITargetingType[] = [
  {
    id: TARGETING_TYPES_ID.GEOGRAPHY,
    code: TARGETING_TYPES_ID_CODE.GEOGRAPHY,
    description: TARGETING_TYPES_DESCRIPTION.GEOGRAPHY,
    details: TARGETING_TYPES_DETAILS.GEOGRAPHY,
    inList: true,
    inView: true,
  },
  {
    id: TARGETING_TYPES_ID.INSTITUTION,
    code: TARGETING_TYPES_ID_CODE.INSTITUTION,
    description: TARGETING_TYPES_DESCRIPTION.INSTITUTION,
    details: TARGETING_TYPES_DETAILS.INSTITUTION,
    inList: true,
    inView: true,
  },
  {
    id: TARGETING_TYPES_ID.PERSONNEL,
    code: TARGETING_TYPES_ID_CODE.PERSONNEL,
    description: TARGETING_TYPES_DESCRIPTION.PERSONNEL,
    details: TARGETING_TYPES_DETAILS.PERSONNEL,
    inList: true,
    inView: true,
  }, // Uncomment if needed
  //   {
  //   id: TARGETING_TYPES_ID.DEMOGRAPHICS,
  //   code: TARGETING_TYPES_ID_CODE.DEMOGRAPHICS,
  //   description: 'Demographics',
  //   details: 'Affluence Indicator, Household Income, etc.',
  //   inList: false,
  //   inView: false,
  // },
  {
    id: TARGETING_TYPES_ID.RFP_IFB,
    code: TARGETING_TYPES_ID_CODE.RFP_IFB,
    description: TARGETING_TYPES_DESCRIPTION.RFP_IFB,
    details: TARGETING_TYPES_DETAILS.RFP_IFB,
    inList: false,
    inView: true,
  },
  {
    id: TARGETING_TYPES_ID.GRANT_DETAILS,
    code: TARGETING_TYPES_ID_CODE.GRANT_DETAILS,
    description: TARGETING_TYPES_DESCRIPTION.GRANT_DETAILS,
    details: TARGETING_TYPES_DETAILS.GRANT_DETAILS,
    inList: false,
    inView: true,
  },
  {
    id: TARGETING_TYPES_ID.PIDs,
    code: TARGETING_TYPES_ID_CODE.PIDs,
    description: TARGETING_TYPES_DESCRIPTION.PIDs,
    details: TARGETING_TYPES_DETAILS.PIDs,
    inList: true,
    inView: true,
  },
  {
    id: TARGETING_TYPES_ID.CUSTOM_PIDs,
    code: TARGETING_TYPES_ID_CODE.CUSTOM_PIDs,
    description: TARGETING_TYPES_DESCRIPTION.PIDs,
    details: TARGETING_TYPES_DETAILS.PIDs,
    inList: true,
    inView: false,
  },
  {
    id: TARGETING_TYPES_ID.CUSTOM_PID_NID,
    code: TARGETING_TYPES_ID_CODE.CUSTOM_PID_NID,
    description: TARGETING_TYPES_DESCRIPTION.PIDs,
    details: TARGETING_TYPES_DETAILS.PIDs,
    inList: true,
    inView: false,
  },
  {
    id: TARGETING_TYPES_ID.CANADIAN,
    code: TARGETING_TYPES_ID_CODE.CANADIAN,
    description: TARGETING_TYPES_DESCRIPTION.GEOGRAPHY,
    details: TARGETING_TYPES_DETAILS.GEOGRAPHY,
    preset: [
      {
        type: GeoEntitiesTypes.Country,
        locationName: "All Canadian States",
        include: true,
      },
    ],
    inList: true,
    inView: false,
  },
];

export const LIST_TARGETING_TYPES: ITargetingType[] = TARGETING_TYPES.filter(
  ({ inList }: ITargetingType) => inList,
);
export const VIEW_TARGETING_TYPES: ITargetingType[] = TARGETING_TYPES.filter(
  ({ inView }: ITargetingType) => inView,
);
