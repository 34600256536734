import { Pipe, PipeTransform } from "@angular/core";

import { IAccountPermissionsForList } from "@modules/profile/interfaces/profile";
import { IListStatuses } from "@shared/interfaces/list";

import { cdlListStatusesMap } from "@shared/constants/data/cdl-list-statuses";
import { CUSTOM_LIST_STATUSES_MAP } from "@shared/constants/data/custom-list-statuses";
import { PRODUCT_ENTITY_TYPES } from "@shared/constants/data/entity";
import {
  LIST_STATUSES_MAP,
  LIST_STATUSES_MAP_FOR_ADMIN,
} from "@shared/constants/data/list-statuses";

@Pipe({
  name: "listConfig",
})
export class ListConfigPipe implements PipeTransform {
  transform(
    entityType: PRODUCT_ENTITY_TYPES,
    entityPermissions: IAccountPermissionsForList,
  ): IListStatuses {
    switch (entityType) {
      case PRODUCT_ENTITY_TYPES.LIST: {
        return entityPermissions.isAdminAccount
          ? LIST_STATUSES_MAP_FOR_ADMIN
          : LIST_STATUSES_MAP;
      }
      case PRODUCT_ENTITY_TYPES.CUSTOM_LIST: {
        return CUSTOM_LIST_STATUSES_MAP;
      }
      case PRODUCT_ENTITY_TYPES.CDL: {
        return cdlListStatusesMap(
          entityPermissions.asAdmin,
          entityPermissions.cdlPermissions,
        );
      }
      default: {
        return [];
      }
    }
  }
}
