import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IQuickSearchGeoSuggestions, IQuickSearchPids } from "../../interfaces";

import { MIN_LENGTH_FOR_SEARCH } from "../../../segment/constants/autocomplete";
import { MIN_LENGTH_FOR_SEARCH_BY_PIDS } from "../../constants/quick-search";

export interface IQuickSearchState {
  keywords: string[];
  error: IServerError | null;
  shouldResetKeywords: boolean;

  pids: IQuickSearchPids;
  shouldResetPids: boolean;

  geoSuggestions: IQuickSearchGeoSuggestions;
  geoSuggestionsPending: boolean;

  creating: boolean;
}

const initialState: IQuickSearchState = {
  keywords: [],
  shouldResetKeywords: false,

  pids: [],
  shouldResetPids: false,

  geoSuggestions: [],
  geoSuggestionsPending: false,

  error: null,

  creating: false,
};

const searchByKeywords: OnReducer<
  IQuickSearchState,
  ActionType<Payload<any>>
> = (state: IQuickSearchState, { payload }: Payload<any>) => ({
  ...state,
  error: null,
  keywords: payload.length >= MIN_LENGTH_FOR_SEARCH ? [...state.keywords] : [],
  shouldResetKeywords: payload.length < MIN_LENGTH_FOR_SEARCH,
});

const searchByKeywordsError: OnReducer<
  IQuickSearchState,
  ActionType<Payload<IServerError>>
> = (state: IQuickSearchState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  shouldResetKeywords: false,
});

const searchByKeywordsSuccess: OnReducer<
  IQuickSearchState,
  ActionType<Payload<any>>
> = (state: IQuickSearchState, { payload }: Payload<any>) => ({
  ...state,
  keywords: state.shouldResetKeywords ? [] : [...payload],
  shouldResetKeywords: false,
});

const searchByKeywordsReset: OnReducer<IQuickSearchState, ActionType<any>> = (
  state: IQuickSearchState,
) => ({
  ...state,
  error: null,
  keywords: [],
  shouldResetKeywords: false,
});

// by pids
const searchByPids: OnReducer<IQuickSearchState, ActionType<Payload<any>>> = (
  state: IQuickSearchState,
  { payload }: Payload<any>,
) => ({
  ...state,
  error: null,
  pids: payload.length >= MIN_LENGTH_FOR_SEARCH_BY_PIDS ? [...state.pids] : [],
  shouldResetPids: payload.length < MIN_LENGTH_FOR_SEARCH_BY_PIDS,
});

const searchByPidsError: OnReducer<
  IQuickSearchState,
  ActionType<Payload<IServerError>>
> = (state: IQuickSearchState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  shouldResetPids: false,
});

const searchByPidsSuccess: OnReducer<
  IQuickSearchState,
  ActionType<Payload<any>>
> = (state: IQuickSearchState, { payload }: Payload<any>) => ({
  ...state,
  pids: state.shouldResetPids ? [] : [...payload],
  shouldResetPids: false,
});

const searchByPidsReset: OnReducer<IQuickSearchState, ActionType<any>> = (
  state: IQuickSearchState,
) => ({
  ...state,
  error: null,
  pids: [],
  shouldResetPids: false,
});

// by geo
const searchByGeo: OnReducer<IQuickSearchState, ActionType<any>> = (
  state: IQuickSearchState,
) => ({
  ...state,
  geoSuggestions: [],
  geoSuggestionsPending: true,
});

const searchByGeoError: OnReducer<
  IQuickSearchState,
  ActionType<Payload<IServerError>>
> = (state: IQuickSearchState, { payload }: Payload<IServerError>) => ({
  ...state,
  geoSuggestionsPending: false,
  error: { ...payload },
});

const searchByGeoSuccess: OnReducer<
  IQuickSearchState,
  ActionType<Payload<any>>
> = (state: IQuickSearchState, { payload }: Payload<any>) => ({
  ...state,
  geoSuggestions: [...payload],
  geoSuggestionsPending: false,
  error: null,
});

const searchByGeoReset: OnReducer<IQuickSearchState, ActionType<any>> = (
  state: IQuickSearchState,
) => ({
  ...state,
  geoSuggestions: [],
  geoSuggestionsPending: false,
  error: null,
});

const createViewFromSearch: OnReducer<IQuickSearchState, ActionType<any>> = (
  state: IQuickSearchState,
) => ({
  ...state,
  creating: true,
});

const createViewFromSearchReset: OnReducer<
  IQuickSearchState,
  ActionType<any>
> = (state: IQuickSearchState) => ({
  ...state,
  creating: false,
});

const resetState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchState> =
  createReducer<IQuickSearchState>(
    initialState,

    on(actions.searchByKeywordsAction, searchByKeywords),
    on(actions.searchByKeywordsErrorAction, searchByKeywordsError),
    on(actions.searchByKeywordsSuccessAction, searchByKeywordsSuccess),
    on(actions.searchByKeywordsResetAction, searchByKeywordsReset),

    on(actions.searchByPidsAction, searchByPids),
    on(actions.searchByPidsErrorAction, searchByPidsError),
    on(actions.searchByPidsSuccessAction, searchByPidsSuccess),
    on(actions.searchByPidsResetAction, searchByPidsReset),

    on(actions.searchByGeoAction, searchByGeo),
    on(actions.searchByGeoErrorAction, searchByGeoError),
    on(actions.searchByGeoSuccessAction, searchByGeoSuccess),
    on(actions.searchByGeoResetAction, searchByGeoReset),

    on(actions.createViewFromSearchAction, createViewFromSearch),
    on(actions.createViewFromSearchErrorAction, createViewFromSearchReset),
    on(actions.createViewFromSearchSuccessAction, createViewFromSearchReset),

    on(actions.resetStateAction, resetState),
  );

export function quickSearchReducer(
  state: IQuickSearchState,
  action: Action,
): IQuickSearchState {
  return reducer(state, action);
}

export const keywords: GetFromState<string[], IQuickSearchState> = (
  state: IQuickSearchState,
): string[] => state.keywords;
export const keywordsMap: GetFromState<{ text: string }[], string[]> = (
  _suggestions: string[],
): { text: string }[] =>
  _suggestions.map((suggestion: string) => ({ text: suggestion }));

export const pids: GetFromState<IQuickSearchPids, IQuickSearchState> = (
  state: IQuickSearchState,
): IQuickSearchPids => state.pids;

export const suggestions: GetFromState<
  string[] | IQuickSearchPids,
  string[],
  IQuickSearchPids
> = (
  _keywords: string[],
  _pids: IQuickSearchPids,
): string[] | IQuickSearchPids =>
  _keywords && !!_keywords.length ? _keywords : _pids;
export const suggestionsMap: GetFromState<
  Array<{ text: string }> | IQuickSearchPids,
  Array<{ text: string }>,
  IQuickSearchPids
> = (
  _keywordsMap: Array<{ text: string }>,
  _pids: IQuickSearchPids,
): Array<{ text: string }> | IQuickSearchPids => {
  return _keywordsMap && !!_keywordsMap.length ? _keywordsMap : _pids;
};

export const viewCreating: GetFromState<boolean, IQuickSearchState> = (
  state: IQuickSearchState,
): boolean => state.creating;

export const geoSuggestions: GetFromState<
  IQuickSearchGeoSuggestions,
  IQuickSearchState
> = (state: IQuickSearchState): IQuickSearchGeoSuggestions =>
  state.geoSuggestions;
export const geoSuggestionsPending: GetFromState<boolean, IQuickSearchState> = (
  state: IQuickSearchState,
): boolean => state.geoSuggestionsPending;
