import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/personnel-codes.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IJob } from "../../../segment/interfaces/jobs";

import {
  IMultiCodesRequest,
  MultiCodesItems,
} from "../../../segment/models/codes";

import {
  DEFAULT_CODES_LIMIT,
  DEFAULT_CODES_OFFSET,
} from "../../../segment/constants/codes";

export interface IPersonnelCodesState {
  codes: MultiCodesItems<IJob>;
  total: number;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IPersonnelCodesState = {
  codes: [],
  total: 0,
  pending: false,
  error: null,
};

const getPersonnelCodes: OnReducer<IPersonnelCodesState, ActionType<any>> = (
  state: IPersonnelCodesState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getPersonnelCodesError: OnReducer<
  IPersonnelCodesState,
  ActionType<Payload<IServerError>>
> = (state: IPersonnelCodesState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getPersonnelCodesSuccess: OnReducer<
  IPersonnelCodesState,
  ActionType<Payload<any>>
> = (
  state: IPersonnelCodesState,
  {
    payload: {
      response: { items, total },
    },
  }: Payload<any>,
) => ({
  ...state,
  pending: false,
  codes: items,
  total,
});

const resetPersonnelCodesState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IPersonnelCodesState> =
  createReducer<IPersonnelCodesState>(
    initialState,

    on(actions.getPersonnelCodesAction, getPersonnelCodes),
    on(actions.getPersonnelCodesErrorAction, getPersonnelCodesError),
    on(actions.getPersonnelCodesSuccessAction, getPersonnelCodesSuccess),

    on(actions.resetPersonnelCodesStateAction, resetPersonnelCodesState),
  );

export function geoCodesReducer(
  state: IPersonnelCodesState,
  action: Action,
): IPersonnelCodesState {
  return reducer(state, action);
}

export const personnelCodes: GetFromState<
  MultiCodesItems<IJob>,
  IPersonnelCodesState
> = (state: IPersonnelCodesState): MultiCodesItems<IJob> => state.codes;
export const personnelCodesTotalCount: GetFromState<
  number,
  IPersonnelCodesState
> = (state: IPersonnelCodesState): number => state.total;
export const personnelCodesPendingState: GetFromState<
  boolean,
  IPersonnelCodesState
> = (state: IPersonnelCodesState): boolean => state.pending;

export const personnelCodesCurrentPage: GetFromState<
  number,
  IMultiCodesRequest
> = ({
  offset = DEFAULT_CODES_OFFSET,
  limit = DEFAULT_CODES_LIMIT,
}: IMultiCodesRequest): number => Math.ceil(offset / limit) + 1;

export const personnelCodesSearchParams: GetFromState<
  IMultiCodesRequest,
  IMultiCodesRequest
> = ({
  segmentCriteriaId,
  offset = DEFAULT_CODES_OFFSET,
  limit = DEFAULT_CODES_LIMIT,
  searchQuery,
}: IMultiCodesRequest): IMultiCodesRequest => ({
  segmentCriteriaId,
  offset,
  limit,
  searchQuery,
});

export const hasInternalAccess: GetFromState<boolean, boolean, boolean> = (
  isNeedToCheckInternalUser: boolean,
  isAdmin: boolean,
): boolean => isNeedToCheckInternalUser && isAdmin;
