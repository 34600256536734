import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  cdlListAppendsReducer,
  ICDLListAppendsState,
} from "./cdl-list-appends.reducer";
import {
  cdlListPurchaseReducer,
  ICDLListPurchaseState,
} from "./cdl-list-purchase.reducer";
import {
  cdlListSummaryReducer,
  ICDLListSummaryState,
} from "./cdl-list-summary.reducer";
import { cdlListReducer, ICDLListState } from "./cdl-list.reducer";

export interface ICDLListModuleState {
  cdlList: ICDLListState;
  cdlListPurchase: ICDLListPurchaseState;
  cdlListSummary: ICDLListSummaryState;
  cdlListAppends: ICDLListAppendsState;
}

const cdlListModuleReducer: ActionReducerMap<ICDLListModuleState> = {
  cdlList: cdlListReducer,
  cdlListPurchase: cdlListPurchaseReducer,
  cdlListSummary: cdlListSummaryReducer,
  cdlListAppends: cdlListAppendsReducer,
};

// eslint-disable-next-line
export const CDL_LIST_STATE_NAME = 'cdlListModule';
export const CDL_LIST_STATE: InjectionToken<
  ActionReducerMap<ICDLListModuleState>
> = new InjectionToken<ActionReducerMap<ICDLListModuleState>>(
  CDL_LIST_STATE_NAME,
  {
    factory: () => cdlListModuleReducer,
  },
);
