import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { Observable } from "rxjs";
import { pluck } from "rxjs/operators";

import { errorHandler } from "@shared/utils/response-formater";
import { sortByAlphabetAsync } from "@shared/utils/sort-by-alphabet-async";

import { IServerError } from "@shared/interfaces/server-error";
import {
  ICountries,
  ICountry,
  ICountryCode,
  ICountryState,
  ICountryStates,
} from "../interfaces";

@Injectable({
  providedIn: "root",
})
export class CountriesService {
  private readonly _countriesUrl: string = `${environment.api.countries.main}`;
  private readonly _statesUrl: string = `${environment.api.countries.states}`;

  constructor(private http: HttpClient) {}

  getCountries(): Observable<ICountries | IServerError> {
    return this.http
      .get(`${this._countriesUrl}`)
      .pipe(pluck("data"), sortByAlphabetAsync<ICountry>("name"), errorHandler);
  }

  getStatesByCountry(
    countryCode: ICountryCode,
  ): Observable<ICountryStates | IServerError> {
    return this.http
      .get(`${this._countriesUrl}/${countryCode}/${this._statesUrl}`)
      .pipe(
        pluck("data"),
        sortByAlphabetAsync<ICountryState>("name"),
        errorHandler,
      );
  }
}
