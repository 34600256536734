import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-views-sorting.action";

import {
  DASHBOARD_ENTITY_TYPES,
  DASHBOARD_SORT_BY,
  DASHBOARD_SORT_DIRECTION,
} from "../../../dashboard/constants/dashboard";

export interface IDashboardViewsSortingState {
  sortDirection: string;
  sortBy: string;
  entityType: DASHBOARD_ENTITY_TYPES.VIEWS;

  isOpen: boolean;
}

const initialState: IDashboardViewsSortingState = {
  sortDirection: DASHBOARD_SORT_DIRECTION.DESC,
  sortBy: DASHBOARD_SORT_BY.CREATE_AT,
  entityType: DASHBOARD_ENTITY_TYPES.VIEWS,

  isOpen: false,
};

const changeDashboardViewsSortDirection: OnReducer<
  IDashboardViewsSortingState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsSortingState, { payload }: Payload<any>) => ({
  ...state,
  sortDirection: payload,
});

const changeDashboardViewsSortBy: OnReducer<
  IDashboardViewsSortingState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsSortingState, { payload }: Payload<any>) => ({
  ...state,
  sortBy: payload,
  isOpen: false,
});

const setSortIsOpenState: OnReducer<
  IDashboardViewsSortingState,
  ActionType<Payload<any>>
> = (state: IDashboardViewsSortingState, { payload }: Payload<any>) => ({
  ...state,
  isOpen: payload,
});

const resetDashboardViewsSorting: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDashboardViewsSortingState> =
  createReducer<IDashboardViewsSortingState>(
    initialState,

    on(
      actions.changeDashboardViewsSortDirectionAction,
      changeDashboardViewsSortDirection,
    ),
    on(actions.changeDashboardViewsSortByAction, changeDashboardViewsSortBy),
    on(actions.setSortIsOpenStateAction, setSortIsOpenState),

    on(actions.resetDashboardViewsSortingAction, resetDashboardViewsSorting),
  );

export const direction: GetFromState<string, IDashboardViewsSortingState> = (
  state: IDashboardViewsSortingState,
): string => state.sortDirection;
export const by: GetFromState<string, IDashboardViewsSortingState> = (
  state: IDashboardViewsSortingState,
): string => state.sortBy;
export const isOpen: GetFromState<boolean, IDashboardViewsSortingState> = (
  state: IDashboardViewsSortingState,
): boolean => state.isOpen;

export function dashboardViewsSortingReducer(
  state: IDashboardViewsSortingState,
  action: Action,
): IDashboardViewsSortingState {
  return reducer(state, action);
}
