import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/geo.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IGeoSuggestionBackEnd } from "../../interfaces/geography";

import { Suggestion } from "../../models/suggestion";

import { GEO_MIN_LENGTH_FOR_SEARCH } from "../../constants/autocomplete";

export interface IGeoState {
  suggestions: IGeoSuggestionBackEnd[];
  error: IServerError | null;
  addPlaceError: IServerError | null;
  include: boolean;
  shouldReset: boolean;
}

const initialState: IGeoState = {
  suggestions: [],
  error: null,
  addPlaceError: null,
  include: true,
  shouldReset: false,
};

const loadGeoSuggestions: OnReducer<IGeoState, ActionType<Payload<any>>> = (
  state: IGeoState,
  { payload }: Payload<any>,
) => ({
  ...state,
  error: null,
  addPlaceError: null,
  suggestions:
    payload.length >= GEO_MIN_LENGTH_FOR_SEARCH ? [...state.suggestions] : [],
  shouldReset: payload.length < GEO_MIN_LENGTH_FOR_SEARCH,
});

const loadGeoSuggestionsError: OnReducer<
  IGeoState,
  ActionType<Payload<IServerError>>
> = (state: IGeoState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  shouldReset: false,
});

const loadGeoSuggestionsSuccess: OnReducer<
  IGeoState,
  ActionType<Payload<any>>
> = (state: IGeoState, { payload }: Payload<any>) => ({
  ...state,
  suggestions: state.shouldReset ? [] : [...payload],
  shouldReset: false,
});

const loadGeoSuggestionsReset: OnReducer<IGeoState, ActionType<any>> = (
  state: IGeoState,
) => ({
  ...state,
  error: null,
  addPlaceError: null,
  suggestions: [],
  shouldReset: true,
});

const toggleIncludeGeo: OnReducer<IGeoState, ActionType<any>> = (
  state: IGeoState,
) => ({
  ...state,
  include: !state.include,
});

const addPlaceError: OnReducer<IGeoState, ActionType<Payload<IServerError>>> = (
  state: IGeoState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  addPlaceError: { ...payload },
});

const resetAddPlaceError: OnReducer<IGeoState, ActionType<any>> = (
  state: IGeoState,
) => ({
  ...state,
  addPlaceError: null,
});

const geoResetState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IGeoState> = createReducer<IGeoState>(
  initialState,

  on(actions.loadGeoSuggestionsAction, loadGeoSuggestions),
  on(actions.loadGeoSuggestionsErrorAction, loadGeoSuggestionsError),
  on(actions.loadGeoSuggestionsSuccessAction, loadGeoSuggestionsSuccess),
  on(actions.loadGeoSuggestionsResetAction, loadGeoSuggestionsReset),
  on(actions.toggleIncludeGeoAction, toggleIncludeGeo),

  on(actions.addPlaceErrorAction, addPlaceError),
  on(actions.resetAddPlaceErrorAction, resetAddPlaceError),

  on(actions.geoResetState, geoResetState),
);

export function geoReducer(state: IGeoState, action: Action): IGeoState {
  return reducer(state, action);
}

export const suggestions: GetFromState<IGeoSuggestionBackEnd[], IGeoState> = (
  state: IGeoState,
): IGeoSuggestionBackEnd[] => state.suggestions;
export const suggestionsMap: GetFromState<
  Suggestion[],
  IGeoSuggestionBackEnd[]
> = (_suggestions: IGeoSuggestionBackEnd[]): Suggestion[] =>
  _suggestions.map((item: IGeoSuggestionBackEnd) => new Suggestion(item));

export const include: GetFromState<boolean, IGeoState> = (
  state: IGeoState,
): boolean => state.include;

export const addGeoPlaceError: GetFromState<IServerError | null, IGeoState> = (
  state: IGeoState,
): IServerError | null => state.addPlaceError;
