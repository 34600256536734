import { AbstractControl, ValidatorFn } from "@angular/forms";

import { FORM_VALIDATION_ERRORS } from "../constants/validators/forms-validations-errors";

export default function cardExpiredDate(): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value.length < 10) {
      return { invalidDateFormat: FORM_VALIDATION_ERRORS.expiredDate.invalid };
    }

    const date: any = control.value.split("-");
    const month: any = date[1];

    if (+month > 12) {
      return { invalidMonth: FORM_VALIDATION_ERRORS.expiredDate.invalidMonth };
    }

    const cardDate: Date = new Date(control.value);

    if (
      new Date(cardDate.getFullYear(), cardDate.getMonth() + 1) < new Date()
    ) {
      return { invalidDate: FORM_VALIDATION_ERRORS.expiredDate.lessDate };
    }

    return null;
  };
}
