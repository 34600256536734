import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { ITableHeaders } from "@ui/tables/components/table/table.component";

import { accountUserToEditUser } from "../../utils/map-forms";

import {
  IAccountUser,
  IAccountUserAddOn,
  IAccountUserAddOns,
  IAccountUserSeat,
  IEditAccountUser,
  SEATS,
} from "../../interfaces/marketview";

import { SORT_DIRECTION } from "@shared/constants/sort";
import {
  ACCOUNT_USERS_TABLE_COMPUTED_PROPERTIES,
  ACCOUNT_USERS_TABLE_HEADERS,
} from "../../constants/marketview";

@Component({
  selector: "bl-account-users",
  templateUrl: "./account-users.component.html",
  styleUrls: ["./account-users.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountUsersComponent {
  @Input() tableSearch: string = "";
  @Input() searchableKeys: string[] = [];
  @Input() users: IAccountUser[];

  @Output() accountUsersEditUser: EventEmitter<IEditAccountUser> =
    new EventEmitter<IEditAccountUser>();

  readonly accountUsersTableHeaders: ITableHeaders =
    ACCOUNT_USERS_TABLE_HEADERS;
  readonly computedProperties: typeof ACCOUNT_USERS_TABLE_COMPUTED_PROPERTIES =
    ACCOUNT_USERS_TABLE_COMPUTED_PROPERTIES;
  readonly seatStatePro: SEATS.State = SEATS.State;

  sortDirection: SORT_DIRECTION = SORT_DIRECTION.ASC;

  editUser(user: IAccountUser): void {
    const editUserData: IEditAccountUser = accountUserToEditUser(user);
    this.accountUsersEditUser.emit(editUserData);
  }

  isCheckedAddOn(_addOns: IAccountUserAddOns = [], _stateId: number): boolean {
    const found: IAccountUserAddOn = _addOns.find(
      (item: IAccountUserAddOn) => item.stateId === _stateId,
    );
    return !!found;
  }

  trackByFn(index: number, seat: IAccountUserSeat): number {
    return seat.id;
  }
}
