import { IListData } from "@shared/interfaces/list";
import {
  IAttachedListing,
  IAttachedListings,
} from "../../custom-list/interfaces";
import { IPIDCustomList, IPIDCustomLists } from "../interfaces/targeting-forms";

export class PidCustomList {
  id: number;
  name: string;
  isAttached: boolean;
  targetingCriteriaCustomListId: number | null;
  include?: boolean;

  constructor(
    customList: IListData,
    listId: number,
    pidCustomList: IPIDCustomLists,
  ) {
    this.id = customList.id;
    this.name = customList.name;
    this.isAttached = isAttached(
      customList.attachedListings,
      pidCustomList,
      listId,
      customList.id,
    );
    this.targetingCriteriaCustomListId = criteriaCustomListId(
      pidCustomList,
      customList.id,
    );
    this.include = isIncludeOrExcludeCustomList(pidCustomList, customList.id);
  }
}

export const isAttached: (
  attachedItems: IAttachedListings,
  customLists: IPIDCustomLists,
  listId: number,
  customListId: number,
) => boolean = (
  attachedItems: IAttachedListings = [],
  customLists: IPIDCustomLists = [],
  listId: number,
  customListId: number,
): boolean => {
  return (
    !!attachedItems.find(
      (item: IAttachedListing): boolean => item.id === listId,
    ) &&
    !!customLists.find(
      (item: IPIDCustomList): boolean => item.customListId === customListId,
    )
  );
};

export const criteriaCustomListId: (
  customLists: IPIDCustomLists,
  customListId: number,
) => number | null = (
  customLists: IPIDCustomLists,
  customListId: number,
): number | null => {
  const find: IPIDCustomList = customLists.find(
    (item: IPIDCustomList): boolean => item.customListId === customListId,
  );
  return (find && find.targetingCriteriaCustomListId) || null;
};

export const isIncludeOrExcludeCustomList: (
  customLists: IPIDCustomLists,
  customListId: number,
) => boolean = (
  customLists: IPIDCustomLists,
  customListId: number,
): boolean => {
  const find: IPIDCustomList = customLists.find(
    (item: IPIDCustomList): boolean => item.customListId === customListId,
  );
  return find && find.include;
};
