import { ListLargeDownloadEffect } from "@core/store/effects/list-large-download.effect";
import { ListPurchaseEffect } from "@core/store/effects/list-purchase.effect";
import { AuthEffects } from "@modules/auth/store/effects/auth.effect";
import { InvitationEffect } from "@modules/auth/store/effects/invitation.effect";
import { SignUpByInviteEffect } from "@modules/auth/store/effects/sign-up-by-invite.effect";
import { ECommerceCartEffect } from "@modules/e-commerce/store/effects/cart.effect";

import { BrokersTouEffect } from "./brokers-tou.effect";
import { CloudSyncAuthEffect } from "./cloud-sync-auth.effect";
import { CommonErrorsEffects } from "./common-errors.effect";
import { CommonPagesEffects } from "./common-pages.effect";
import { DownloadEffects } from "./download.effect";
import { ListenersEffect } from "./listeners.effect";
import { RouterHistoryEffects } from "./router-history.effect";
import { ShowEmailEffect } from "./show-email.effect";
import { TosEffects } from "./tos.effect";

export type ICoreEffects = any[];
export const coreEffects: ICoreEffects = [
  AuthEffects,
  SignUpByInviteEffect,
  TosEffects,
  RouterHistoryEffects,
  CommonPagesEffects,
  CommonErrorsEffects,
  DownloadEffects,
  ECommerceCartEffect,
  ShowEmailEffect,
  InvitationEffect,
  CloudSyncAuthEffect,
  BrokersTouEffect,
  ListenersEffect,
  ListLargeDownloadEffect,
  ListPurchaseEffect,
];
