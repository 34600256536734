import {
  forwardRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "bl-custom-checkbox",
  templateUrl: "./custom-checkbox.component.html",
  styleUrls: ["./custom-checkbox.component.scss"],
  exportAs: "checkbox",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomCheckboxComponent),
      multi: true,
    },
  ],
})
export class CustomCheckboxComponent implements ControlValueAccessor {
  @Output() changeCustomCheckBox: EventEmitter<boolean> = new EventEmitter();

  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Input() tooltipText: string;
  @Input() tooltipIsCalPosition: boolean;

  get value(): boolean {
    return this.checked;
  }

  propagateChange: any = () => {};

  writeValue(state: boolean): void {
    this.checked = state;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnTouched(fn: () => void): void {}

  onChange(event: any): void {
    event.stopPropagation();

    const {
      target: { checked },
    }: any = event;

    this.checked = checked;

    this.propagateChange(checked);
    this.changeCustomCheckBox.emit(checked);
  }
}
