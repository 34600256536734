import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-grants.action";

import { IGrantsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { GRANTS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsGrantsState {
  grants: IGrantsRecord[];
  count: number;

  pending: boolean;
  error: IServerError;
}

const initialState: IQuickSearchResultsGrantsState = {
  grants: [],
  count: 0,

  pending: false,
  error: null,
};

const quickSearchGrants: OnReducer<
  IQuickSearchResultsGrantsState,
  ActionType<any>
> = (state: IQuickSearchResultsGrantsState) => ({
  ...state,
  pending: true,
  error: null,
});

const quickSearchGrantsError: OnReducer<
  IQuickSearchResultsGrantsState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsGrantsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const quickSearchGrantsSuccess: OnReducer<
  IQuickSearchResultsGrantsState,
  ActionType<Payload<any>>
> = (state: IQuickSearchResultsGrantsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  grants: [...payload.items],
  count: payload.count,
});

const setQuickSearchResultsGrantsSpinnerState: OnReducer<
  IQuickSearchResultsGrantsState,
  ActionType<Payload<any>>
> = (state: IQuickSearchResultsGrantsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetQuickSearchResultsGrantsState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsGrantsState> =
  createReducer<IQuickSearchResultsGrantsState>(
    initialState,

    on(actions.getQuickSearchGrantsAction, quickSearchGrants),
    on(actions.getQuickSearchGrantsErrorAction, quickSearchGrantsError),
    on(actions.getQuickSearchGrantsSuccessAction, quickSearchGrantsSuccess),

    on(actions.changeQuickSearchGrantsPageAction, quickSearchGrants),
    on(actions.changeQuickSearchGrantsQueryAction, quickSearchGrants),

    on(
      actions.setQuickSearchResultsGrantsSpinnerStateAction,
      setQuickSearchResultsGrantsSpinnerState,
    ),

    on(
      actions.resetQuickSearchResultsGrantsStateAction,
      resetQuickSearchResultsGrantsState,
    ),
  );

export function quickSearchResultsGrantsReducer(
  state: IQuickSearchResultsGrantsState,
  action: Action,
): IQuickSearchResultsGrantsState {
  return reducer(state, action);
}

export const grants: GetFromState<
  IGrantsRecord[],
  IQuickSearchResultsGrantsState
> = (state: IQuickSearchResultsGrantsState): IGrantsRecord[] => state.grants;
export const grantsPending: GetFromState<
  boolean,
  IQuickSearchResultsGrantsState
> = (state: IQuickSearchResultsGrantsState): boolean => state.pending;
export const grantsError: GetFromState<
  IServerError,
  IQuickSearchResultsGrantsState
> = (state: IQuickSearchResultsGrantsState): IServerError => state.error;

export const grantsCount: GetFromState<
  number,
  IQuickSearchResultsGrantsState
> = (state: IQuickSearchResultsGrantsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  const { sortBy }: Params = queryParams;

  return {
    ...GRANTS_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    sortBy: sortBy || "year",
    filterBy: "grants",
  };
};
