import { IObjectKeysByString } from "@shared/interfaces";
import { IPermissionsMsg } from "../interfaces/permissions";

export enum PermissionsKeys {
  ManageQuickSearch = "quick_search",
  ManageViews = "manage_views",
  ManageGrants = "grant_target",
  ManageBids = "bid_target",
  EmailAlerts = "email_alerts",
  PidDisplay = "pid_display",
}

export const ERRORS_DESCRIPTIONS: IObjectKeysByString = Object.freeze({
  TOS: "Tos is not accepted",
  BROKER_TOS: "Terms of use is not accepted",
  ACCESS_SECTION: "You do not have access to this section.",
  SEATS: "You do not have required seat",
  BIDS: "You must have bid seat",
  GRANTS: "You must have grant seat",
  INTERNAL: "Only internal user can perform this action",
});

export const PERMISSIONS_TOS_ERRORS: string[] = [ERRORS_DESCRIPTIONS.TOS];
export const BROKER_PERMISSIONS_TOS_ERRORS: string[] = [
  ERRORS_DESCRIPTIONS.BROKER_TOS,
];
export const PERMISSIONS_ACCESS_SECTION_ERRORS: string[] = [
  ERRORS_DESCRIPTIONS.ACCESS_SECTION,
];
export const PERMISSIONS_SEATS_ERRORS: string[] = [
  ERRORS_DESCRIPTIONS.SEATS,
  ERRORS_DESCRIPTIONS.BIDS,
  ERRORS_DESCRIPTIONS.GRANTS,
];
export const PERMISSIONS_ONLY_FOR_INTERNAL_USERS: string[] = [
  ERRORS_DESCRIPTIONS.INTERNAL,
];

export const PERMISSIONS_MSG: IPermissionsMsg = Object.freeze({
  ADMIN_CHANGE_PERMISSIONS:
    "Heads up, a fellow admin has removed your admin permissions for this account",
  CHANGED_PERMISSIONS:
    "Heads up, a fellow admin has changed your permissions set for this account",
  DO_NOT_HAVE_SEAT: "You don't have permission to access this territory.",
  TRIAL_DATE: (days: number) =>
    `Welcome to ConnectED Data. You have ${days} days left on your free MarketView trial.`,
});

export const getCanNotManageGrantsDescription: (text?: string) => string = (
  text: string = '<span class="text--bold">Grants</span>',
) => {
  return `In order to access your ${text} subscription,
          an admin needs to assign you the add-on in the Products & Users tab under My Account.` as string;
};

export const getCanNotManageBidsDescription: (text?: string) => string = (
  text: string = '<span class="text--bold">RFP & IFBs</span>',
) => {
  return `In order to access your ${text} subscription,
          an admin needs to assign you the add-on in the Products & Users tab under My Account.`;
};

const _marketViewName: string = '<span class="text--bold">Marketview</span>';
const _grantName: string = '<span class="text--bold">Grants</span>';
const _bidName: string = '<span class="text--bold">RFP & IFBs</span>';

// market view
export const marketViewNotAvailable: (isUser: boolean) => string = (
  isUser: boolean = false,
) => {
  const _admin: string = "assign yourself";
  const _user: string = "an admin needs to assign you";

  return `In order to access your ${_marketViewName} subscription,
   ${isUser ? _user : _admin} a seat in the Products & Users tab under My Account.`;
};

export const marketViewNotSubscription: () => string = () => {
  return `Subscribe to ${_marketViewName} to define target criteria, save your searches, view premium data, and receive alerts.`;
};

// grants
export const grantsNotAvailable: (isUser: boolean) => string = (
  isUser: boolean = false,
) => {
  const _admin: string = "assign yourself";
  const _user: string = "an admin needs to assign you";

  return `In order to access your ${_grantName} subscription,
   ${isUser ? _user : _admin} the add-on in the Products & Users tab under My Account.`;
};

export const grantsNotSubscription: () => string = () => {
  return `Subscribe to our ${_grantName} Add-on to instantly view all of the grants and funding activity in your market.`;
};

// bids
export const bidsNotAvailable: (isUser: boolean) => string = (
  isUser: boolean = false,
) => {
  const _admin: string = "assign yourself";
  const _user: string = "an admin needs to assign you";

  return `In order to access your ${_bidName} subscription,
   ${isUser ? _user : _admin} the add-on in the Products & Users tab under My Account.`;
};

export const bidsNotSubscription: () => string = () => {
  return `Subscribe to our ${_bidName} Add-on to instantly view Requests for Bids and Proposals in your market.`;
};

export const verifyUserNotifications = {
  IN_MERGE:
    "Your Account is currently in the merge process, you will receive a notification when the process is finished.",
  INVITATION_SENT:
    "An invitation was sent to this email to add you to an account. Please click the link to Accept the invitation and gain access.",
};
