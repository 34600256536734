import { IPagination, IPaginationTable } from "@shared/interfaces/list";

export const DEFAULT_PAGINATION: IPagination = {
  moreAvailable: true,
  offset: 0,
  limit: 24,
  total: 0,
};

export const PAGINATION_TABLE: IPaginationTable = Object.freeze({
  perPage: 24,
});
