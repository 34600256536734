import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
} from "@angular/router";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { mapTo, take, tap, withLatestFrom } from "rxjs/operators";

import { getInvitationExistedUser } from "@modules/auth/store/selectors/invitation.selector";
import { getProfileAction } from "@modules/profile/store/actions/profile.action";
import { getIsLoggedInAndUser } from "@modules/profile/store/selectors/profile.selector";
import { CoreState } from "../store/reducers";

import { IInvitationUser } from "@modules/auth/interfaces/invitation";
import { ILoggedInAndUser } from "@modules/profile/interfaces/profile";

@Injectable()
export class CheckUserDataExistGuard {
  constructor(private _store: Store<CoreState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.checkData();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.checkData();
  }

  canLoad(route: Route): Observable<boolean> {
    return this.checkData();
  }

  private checkData(): Observable<boolean> {
    return this._store.pipe(
      select(getIsLoggedInAndUser),
      take(1),
      withLatestFrom(this._store.pipe(select(getInvitationExistedUser))),
      tap(
        ([{ isLoggedIn, user }, invitation]: [
          ILoggedInAndUser,
          IInvitationUser,
        ]) => {
          if (!invitation) {
            this._store.dispatch(getProfileAction());
          }
        },
      ),
      mapTo(true),
    );
  }
}
