import { ITableHeader } from "@ui/tables/components/table/table.component";

export const DEFAULT_LIST_HISTORY_OFFSET: number = 0;
export const DEFAULT_LIST_HISTORY_LIMIT: number = 30;

export const CDL_LIST_HISTORY_SORT_DIRECTION: { DESC: string } = {
  DESC: "DESC",
};
export const CDL_LIST_HISTORY_SORT_BY: { DATE: string } = {
  DATE: "created_at",
};

export const CDL_LIST_HISTORY_HEADERS: ITableHeader[] = [
  {
    key: "created_at",
    title: "Date",
    sortable: true,
  },
  {
    key: "name",
    title: "Name",
    sortable: true,
  },
  {
    key: "action",
    title: "Action",
    sortable: true,
  },
  {
    key: "details",
    title: "Detail",
    sortable: true,
  },
  {
    key: "comment",
    title: "Comment",
    sortable: true,
  },
  {
    key: "",
    title: "",
  },
];

export const HISTORY_PAGE_EMPTY_DESCRIPTION: string =
  "Create or edit the list to view its history";
export const UPDATE_HISTORY_ITEM_COMMENT_SUCCESS_MESSAGE: string =
  "Comment successfully edited";
export const HISTORY_COMMENT_LENGTH_LIMIT: number = 150;
