import {
  IAccountUser,
  ICreateAccountUser,
  ICreateAccountUserForRequest,
  IEditAccountUser,
  IEditUserForm,
  IEditUserFormForRequest,
  INationalAddOns,
  ISeatState,
  Roles,
  SEATS,
} from "../interfaces/marketview";

import {
  ACCESS_CDL_LISTS,
  MANAGE_ACCOUNT_LISTS,
} from "@shared/constants/data/manage-account-lists";
import { USER_STATUS_IS_MERGE } from "../constants/marketview";
import { RolesByKey } from "../constants/roles";

import {
  createAddOns,
  createBidStateIds,
  createGrantStateIds,
  createStateIds,
} from "./products";

export const parseAddOns: (
  _bidStateIds: number[],
  _grantStateIds: number[],
) => any[] = (_bidStateIds: number[], _grantStateIds: number[]): any[] => {
  let res: any[] = [];

  if (_bidStateIds.length) {
    res = [...res, SEATS.BidsStateAddOn];
  }

  if (_grantStateIds.length) {
    res = [...res, SEATS.GrantsStateAddOn];
  }

  return res;
};

export const parseNationalAddOns: (nationalAddOns: INationalAddOns) => any[] = (
  nationalAddOns: INationalAddOns,
): any[] => {
  let res: any[] = [];

  if (nationalAddOns && nationalAddOns.isNationalBidAddOn) {
    res = [...res, SEATS.BidsAddOn];
  }

  if (nationalAddOns && nationalAddOns.isNationalGrantAddOn) {
    res = [...res, SEATS.GrantsAddOn];
  }

  return res;
};

export class EditForm {
  id: number;
  stateIds: number[];
  addOns?: SEATS[];
  grantStateIds?: number[];
  bidStateIds?: number[];
  seatId: SEATS;
  roleId: Roles;
  showAccountLists: MANAGE_ACCOUNT_LISTS;
  accessCDLLists: ACCESS_CDL_LISTS;
  allocatedCredits?: number;

  constructor(form: IEditUserForm) {
    const {
      id,
      stateIds,
      seatId,
      roleId,
      nationalAddOns,
      showAccountLists,
      accessCDLLists,
      isAllocated,
      allocatedCredits,
    }: IEditUserForm = form;

    if (isAllocated) {
      this.allocatedCredits = allocatedCredits;
    }

    this.id = id;
    this.roleId = roleId;
    this.seatId = seatId;
    this.showAccountLists = showAccountLists;
    this.accessCDLLists = accessCDLLists;
    this.stateIds =
      (stateIds &&
        stateIds.filter((state: ISeatState): number => state.stateId) &&
        stateIds.map((state: ISeatState): number => state.stateId)) ||
      [];
    this.bidStateIds =
      (stateIds &&
        stateIds
          .filter((state: ISeatState): boolean => state.isBidStateId)
          .map((state: ISeatState): number => state.stateId)) ||
      [];
    this.grantStateIds =
      (stateIds &&
        stateIds
          .filter((state: ISeatState): boolean => state.isGrantStateId)
          .map((state: ISeatState): number => state.stateId)) ||
      [];

    this.addOns =
      this.seatId === SEATS.State
        ? parseAddOns(this.bidStateIds, this.grantStateIds)
        : this.seatId === SEATS.National
          ? parseNationalAddOns(nationalAddOns)
          : [];
  }
}

export class AddUserForm {
  email: string;
  stateIds: number[];
  addOns?: SEATS[];
  allocatedCredits?: number;
  grantStateIds?: number[];
  bidStateIds?: number[];
  seatId: SEATS;
  roleId: Roles;
  showAccountLists: MANAGE_ACCOUNT_LISTS;
  accessCDLLists: ACCESS_CDL_LISTS;

  constructor(form: ICreateAccountUser) {
    const {
      email,
      stateIds,
      seatId,
      roleId,
      showAccountLists,
      accessCDLLists,
      nationalAddOns,
      isAllocated,
      allocatedCredits,
    }: ICreateAccountUser = form;

    if (isAllocated) {
      this.allocatedCredits = allocatedCredits;
    }

    this.email = email;
    this.roleId = roleId;
    this.showAccountLists = showAccountLists;
    this.accessCDLLists = accessCDLLists;
    this.seatId = seatId;
    this.stateIds =
      (stateIds &&
        stateIds.filter((state: ISeatState): number => state.stateId) &&
        stateIds.map((state: ISeatState): number => state.stateId)) ||
      [];
    this.bidStateIds =
      (stateIds &&
        stateIds
          .filter((state: ISeatState): boolean => state.isBidStateId)
          .map((state: ISeatState): number => state.stateId)) ||
      [];
    this.grantStateIds =
      (stateIds &&
        stateIds
          .filter((state: ISeatState): boolean => state.isGrantStateId)
          .map((state: ISeatState): number => state.stateId)) ||
      [];

    this.addOns =
      this.seatId === SEATS.State
        ? parseAddOns(this.bidStateIds, this.grantStateIds)
        : this.seatId === SEATS.National
          ? parseNationalAddOns(nationalAddOns)
          : [];
  }
}

export const removeEmptyFields: (result: any) => any = (result: any): any => {
  if (result.stateIds && !result.stateIds.length) {
    delete result.stateIds;
  }

  if (result.addOns && !result.addOns.length) {
    delete result.addOns;
  }

  if (result.bidStateIds && !result.bidStateIds.length) {
    delete result.bidStateIds;
  }

  if (result.grantStateIds && !result.grantStateIds.length) {
    delete result.grantStateIds;
  }

  return result;
};

export const parseEditUserFormForSend: (
  form: IEditUserForm,
) => IEditUserFormForRequest = (form: IEditUserForm): IEditUserFormForRequest =>
  removeEmptyFields(new EditForm(form));
export const parseAddUserFormForSend: (
  form: ICreateAccountUser,
) => ICreateAccountUserForRequest = (
  form: ICreateAccountUser,
): ICreateAccountUserForRequest => removeEmptyFields(new AddUserForm(form));

export const accountUserToEditUser: (user: IAccountUser) => IEditAccountUser = (
  user: IAccountUser,
): IEditAccountUser => {
  const {
    firstName,
    lastName,
    id,
    email,
    seats,
    role,
    isInvited,
    status,
    accessLists,
    accessCDLLists,
    addOns,
    dataCount,
    allocatedCredits,
  }: IAccountUser = user;

  return {
    firstName,
    lastName,
    id,
    email,
    isInvited,
    status,
    seatId: seats && seats.length && seats[0].id,
    roleId: RolesByKey[role.key],
    accessLists,
    accessCDLLists,
    addOns: createAddOns(addOns),
    stateIds: createStateIds(seats),
    bidStateIds: createBidStateIds(addOns),
    grantStateIds: createGrantStateIds(addOns),
    dataCount,
    isDisabledUser: isInvited || USER_STATUS_IS_MERGE.includes(status),
    allocatedCredits,
  } as IEditAccountUser;
};
