import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { getIsShowAssignCloudSyncProduct } from "@core/store/selectors/cloud-sync-auth.selector";
import { getShowRefreshCreditsTooltipOnInit } from "@ui/tooltip/store/selectors/tooltips.selector";
import { CREDIT_EXPIRED_TEXT } from "@shared/constants/tooltips/tooltips-text";
import { getCreditsAction } from "../../../credits/store/actions";
import {
  getCredits,
  getCreditExpirationDate,
  isCreditsPending,
} from "../../../credits/store/selectors/credits.selector";
import {
  getIsBroker,
  getIsUnlimited,
  getProfileSubscription,
} from "../../../profile/store/selectors/profile.selector";

@Component({
  selector: "bl-credit-meter-container",
  templateUrl: "./credit-meter-container.component.html",
  styleUrls: ["./credit-meter-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditMeterContainerComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean = false;

  @Output() goTo: EventEmitter<any> = new EventEmitter();

  isBroker$: Observable<boolean> = this._store.pipe(select(getIsBroker));

  isUnlimitedUser$: Observable<boolean> = this._store.pipe(
    select(getIsUnlimited),
  );
  userCredits$: Observable<number> = this._store.pipe(select(getCredits));
  userCreditExpirationDate$: Observable<string> = this._store.pipe(
    select(getCreditExpirationDate),
  );
  isCreditsPending$: Observable<boolean> = this._store.pipe(
    select(isCreditsPending),
  );
  showTooltipOnInit$: Observable<boolean> = this._store.pipe(
    select(getShowRefreshCreditsTooltipOnInit),
  );
  IsShowOwnerCloudSyncProduct$: Observable<boolean> = this._store.pipe(
    select(getIsShowAssignCloudSyncProduct),
  );
  profileSubscription$: Observable<boolean> = this._store.pipe(
    select(getProfileSubscription),
  );

  isShowAssignCloudSyncPopUp: boolean;
  userCreditExpirationToolTip: string = null;
  creditExpiredText: string = CREDIT_EXPIRED_TEXT;

  private _destroyer$: Subject<any> = new Subject();

  constructor(
    private _store: Store<CoreState>,
    private _date: DatePipe,
  ) {}

  ngOnInit(): void {
    this.IsShowOwnerCloudSyncProduct$.pipe(
      takeUntil(this._destroyer$),
    ).subscribe(
      (_isShow: boolean): boolean =>
        (this.isShowAssignCloudSyncPopUp = _isShow),
    );

    this.userCreditExpirationDate$.subscribe((date: string | null) => {
      if (date) {
        this.userCreditExpirationToolTip =
          this.creditExpiredText + this._date.transform(date, "mediumDate");
      }
    });
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  // user credits
  refreshUserCredits(): void {
    this._store.dispatch(getCreditsAction({ showUpdateMsg: true }));

    /*
    TODO: hidden by ticket CCP-2035
    if (this.isShowAssignCloudSyncPopUp) {
      this._store.dispatch(openCloudSyncOwnerAssignProductPopUp());
    }*/
  }
}
