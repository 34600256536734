import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  geoCodesReducer,
  INameAndPidCodesState,
} from "./name-and-pid-codes.reducer";

export interface INameAndPidCodesModuleState {
  nameAndPidCodes: INameAndPidCodesState;
}

const NAME_AND_PID_CODES_MODULE_STATE_REDUCER: ActionReducerMap<INameAndPidCodesModuleState> =
  {
    nameAndPidCodes: geoCodesReducer,
  };

// eslint-disable-next-line
export const NAME_AND_PID_CODES_MODULE_STATE_NAME = 'nameAndPidCodesModule';
export const NAME_AND_PID_CODES_MODULE_STATE: InjectionToken<
  ActionReducerMap<INameAndPidCodesModuleState>
> = new InjectionToken<ActionReducerMap<INameAndPidCodesModuleState>>(
  NAME_AND_PID_CODES_MODULE_STATE_NAME,
  { factory: () => NAME_AND_PID_CODES_MODULE_STATE_REDUCER },
);
