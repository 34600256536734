import { throwError, Observable } from "rxjs";
import { catchError, map, pluck } from "rxjs/operators";

import {
  ICreditCardInfo,
  IUserBillingInfoData,
} from "@modules/e-commerce/interfaces/e-commerce";
import { IServerError } from "../interfaces/server-error";
import { IViewData } from "../interfaces/view";

import { catchErrorWithErrorType } from "./error-handlers";

export const errorHandler: (observer: Observable<any>) => Observable<any> = (
  observer: Observable<any>,
): Observable<any> =>
  observer.pipe(
    catchError((error: any) => throwError(error.error as IServerError)),
  );

export const pluckAndCatch: (observer: Observable<any>) => Observable<any> = (
  observer: Observable<any>,
): Observable<any> =>
  observer.pipe(
    pluck("data"),
    catchError((error: any) => throwError(error.error)),
  );

export const billingInfoMp: (
  observer: Observable<any>,
) => Observable<IUserBillingInfoData> = (
  observer: Observable<any>,
): Observable<IUserBillingInfoData> =>
  observer.pipe(
    pluck("data"),
    map((data: IUserBillingInfoData) => {
      const { user, credit_card_details }: IUserBillingInfoData = data;
      return {
        credit_card_details: credit_card_details.map(
          (card: ICreditCardInfo) => {
            return {
              ...card,
              address: {
                ...card.address,
                zip_code: data.user.personal_address.zip_code
                  .toString()
                  .substr(0, 5),
              },
            };
          },
        ),
        user: {
          ...user,
          personal_address: {
            ...data.user.personal_address,
            zip_code: data.user.personal_address.zip_code
              .toString()
              .substr(0, 5),
          },
        },
      };
    }),
    catchErrorWithErrorType,
  );

// TODO make it simple
export const mapViewResponse: (
  observer: Observable<IViewData>,
) => Observable<IViewData> = (
  observer: Observable<IViewData>,
): Observable<IViewData> =>
  observer.pipe(
    pluck("data"),
    map((view: IViewData) => {
      return {
        ...view,
        dateCreated: view.dateCreated * 1000,
        lastModified: view.lastModified * 1000,
        // TODO Remove next line when API will be updated;
        segments: view.segments.map(
          ({ targetingCriteria: ISegmentTargetingCriteria, ...segment }: any) =>
            segment,
        ),
      };
    }),
    errorHandler,
  );

export const mapInstitutionProfileResponse: (
  observer: Observable<any>,
) => Observable<any> = (observer: Observable<any>): Observable<any> =>
  observer.pipe(
    pluck("data"),
    pluck("data"),
    catchError((error: any) => throwError(error.error)),
  );
