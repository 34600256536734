// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `li {
  list-style-type: none;
}

.listStyle {
  padding-bottom: 1px;
}

.listChildStyle {
  font-weight: bold !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/profile/containers/user-history-container/user-history-container.component.scss"],"names":[],"mappings":"AAEA;EACI,qBAAA;AADJ;;AAIA;EACE,mBAAA;AADF;;AAGA;EACE,4BAAA;AAAF","sourcesContent":["\n\nli {\n    list-style-type: none;\n  }\n\n.listStyle{\n  padding-bottom: 1px;\n}\n.listChildStyle{\n  font-weight: bold !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
