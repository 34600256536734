import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IFilters, IFiltersResult } from "../../../dashboard/interfaces";

import { MANAGE_ACCOUNT_LISTS } from "@shared/constants/data/manage-account-lists";

enum ACTION_TYPES {
  GET_FILTER = "[DASHBOARD VIEWS]: Get filters",
  GET_FILTER_ERROR = "[DASHBOARD VIEWS]: Get filters error",
  GET_FILTER_SUCCESS = "[DASHBOARD VIEWS]: Get filters success",

  UPDATE_CHECKED_FILTER = "[DASHBOARD VIEWS]: Update filter",
  UPDATE_CHECKED_FILTER_SUCCESS = "[DASHBOARD VIEWS]: Update filter success",
  RESET_CHECKED_FILTERS = "[DASHBOARD VIEWS]: Reset checked filters",

  RESET_FILTERS = "[DASHBOARD VIEWS]: Reset filters",

  RESET_STATE = "[DASHBOARD VIEWS]: Reset state",
}

export const getDashboardViewsFilterAction: CreateActionType<
  ACTION_TYPES.GET_FILTER,
  MANAGE_ACCOUNT_LISTS
> = createAction(
  ACTION_TYPES.GET_FILTER,
  createPayload<MANAGE_ACCOUNT_LISTS>(),
);
export const getDashboardViewsFilterErrorAction: CreateActionType<
  ACTION_TYPES.GET_FILTER_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_FILTER_ERROR, createPayload<IServerError>());
export const getDashboardViewsFilterSuccessAction: CreateActionType<
  ACTION_TYPES.GET_FILTER_SUCCESS,
  IFilters
> = createAction(ACTION_TYPES.GET_FILTER_SUCCESS, createPayload<IFilters>());

export const updateDashboardViewsFilterAction: CreateActionType<
  ACTION_TYPES.UPDATE_CHECKED_FILTER,
  IFiltersResult
> = createAction(
  ACTION_TYPES.UPDATE_CHECKED_FILTER,
  createPayload<IFiltersResult>(),
);
export const updateDashboardViewFilterSuccessAction: ActionCreator<
  ACTION_TYPES.UPDATE_CHECKED_FILTER_SUCCESS,
  () => TypedAction<ACTION_TYPES.UPDATE_CHECKED_FILTER_SUCCESS>
> = createAction(ACTION_TYPES.UPDATE_CHECKED_FILTER_SUCCESS);
export const resetDashboardViewsCheckedFilterAction: ActionCreator<
  ACTION_TYPES.RESET_CHECKED_FILTERS,
  () => TypedAction<ACTION_TYPES.RESET_CHECKED_FILTERS>
> = createAction(ACTION_TYPES.RESET_CHECKED_FILTERS);

export const resetDashboardViewsFilterAction: ActionCreator<
  ACTION_TYPES.RESET_FILTERS,
  () => TypedAction<ACTION_TYPES.RESET_FILTERS>
> = createAction(ACTION_TYPES.RESET_FILTERS);

export const resetDashboardViewsStateAction: ActionCreator<
  ACTION_TYPES.RESET_STATE,
  () => TypedAction<ACTION_TYPES.RESET_STATE>
> = createAction(ACTION_TYPES.RESET_STATE);
