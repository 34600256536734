import { Pipe, PipeTransform } from "@angular/core";

import {
  ISegmentByItems,
  SegmentByItemsCollection,
} from "@modules/segment/models/segment-by";

@Pipe({
  name: "blIsSegmentByAvailable",
})
export class IsSegmentByAvailablePipe implements PipeTransform {
  transform(value: SegmentByItemsCollection): boolean {
    if (!value || typeof value !== "object") {
      return false;
    }

    return !!Object.values(value).reduce(
      (prev: ISegmentByItems, next: ISegmentByItems) => [...prev, ...next],
      [],
    ).length;
  }
}
