import {
  IAccessAccountCDLLists,
  IManageAccountLists,
} from "@shared/interfaces/manage-account-lists";
import {
  IAccountUser,
  IAccountUserAddOn,
  IAccountUserAddOns,
  IAccountUserDataCount,
  IAccountUserRole,
  IAccountUserSeats,
} from "../interfaces/marketview";

import {
  SEAT_NATIONAL_AND_STATE_ADDONS_BID,
  SEAT_NATIONAL_AND_STATE_ADDONS_GRANT,
} from "../constants/products";

export class MapAccountUser {
  firstName: string;
  lastName: string;
  id: number;
  email: string;
  seats: IAccountUserSeats;
  role: IAccountUserRole;
  isInvited: boolean;
  status: number;
  dataCount: IAccountUserDataCount;
  accessLists: IManageAccountLists;
  accessCDLLists: IAccessAccountCDLLists;
  addOns: IAccountUserAddOns;
  addOnsBids?: IAccountUserAddOns;
  addOnsGrants?: IAccountUserAddOns;
  allocatedCredits?: number;

  constructor(user: IAccountUser) {
    const {
      firstName,
      lastName,
      id,
      email,
      seats,
      role,
      isInvited,
      status,
      accessLists,
      accessCDLLists,
      addOns,
      dataCount,
      allocatedCredits,
    }: IAccountUser = user;

    if (typeof allocatedCredits === "number") {
      this.allocatedCredits = allocatedCredits;
    }

    this.firstName = firstName;
    this.lastName = lastName;
    this.id = id;
    this.email = email;
    this.seats = seats;
    this.role = role;
    this.isInvited = isInvited;
    this.status = status;
    this.dataCount = dataCount;
    this.accessLists = accessLists;
    this.accessCDLLists = accessCDLLists;
    this.addOns = addOns;
    this.addOnsBids = Array.from(
      new Set(
        addOns.filter((item: IAccountUserAddOn) => {
          return SEAT_NATIONAL_AND_STATE_ADDONS_BID.includes(item.id);
        }),
      ),
    );
    this.addOnsGrants = Array.from(
      new Set(
        addOns.filter((item: IAccountUserAddOn) => {
          return SEAT_NATIONAL_AND_STATE_ADDONS_GRANT.includes(item.id);
        }),
      ),
    );
  }
}
