import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-lists-sorting.action";

import {
  DASHBOARD_ENTITY_TYPES,
  DASHBOARD_SORT_BY,
  DASHBOARD_SORT_DIRECTION,
  DEFAULT_FILTER_QUERY_PARAMS,
} from "../../../dashboard/constants/dashboard";

export interface IDashboardListsSortingState {
  sortDirection: string;
  sortBy: string;
  entityType: DASHBOARD_ENTITY_TYPES[];

  isOpen: boolean;
}

const initialState: IDashboardListsSortingState = {
  sortDirection: DASHBOARD_SORT_DIRECTION.DESC,
  sortBy: DASHBOARD_SORT_BY.CREATE_AT,
  entityType: DEFAULT_FILTER_QUERY_PARAMS.entityType,

  isOpen: false,
};

const changeDashboardListsSortDirection: OnReducer<
  IDashboardListsSortingState,
  ActionType<Payload<any>>
> = (state: IDashboardListsSortingState, { payload }: Payload<any>) => ({
  ...state,
  sortDirection: payload,
});

const changeDashboardListsSortBy: OnReducer<
  IDashboardListsSortingState,
  ActionType<Payload<any>>
> = (state: IDashboardListsSortingState, { payload }: Payload<any>) => ({
  ...state,
  sortBy: payload,
  isOpen: false,
});

const setSortIsOpenState: OnReducer<
  IDashboardListsSortingState,
  ActionType<Payload<any>>
> = (state: IDashboardListsSortingState, { payload }: Payload<any>) => ({
  ...state,
  isOpen: payload,
});

const resetDashboardListsSorting: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDashboardListsSortingState> =
  createReducer<IDashboardListsSortingState>(
    initialState,

    on(
      actions.changeDashboardListsSortDirectionAction,
      changeDashboardListsSortDirection,
    ),
    on(actions.changeDashboardListsSortByAction, changeDashboardListsSortBy),
    on(actions.setSortIsOpenStateAction, setSortIsOpenState),

    on(actions.resetDashboardListsSortingAction, resetDashboardListsSorting),
  );

export function dashboardListsSortingReducer(
  state: IDashboardListsSortingState,
  action: Action,
): IDashboardListsSortingState {
  return reducer(state, action);
}

export const direction: GetFromState<string, IDashboardListsSortingState> = (
  state: IDashboardListsSortingState,
): string => state.sortDirection;
export const by: GetFromState<string, IDashboardListsSortingState> = (
  state: IDashboardListsSortingState,
): string => state.sortBy;
export const isOpen: GetFromState<boolean, IDashboardListsSortingState> = (
  state: IDashboardListsSortingState,
): boolean => state.isOpen;
