import { Pipe, PipeTransform } from "@angular/core";

import { IControlOption } from "@shared/interfaces/forms";

@Pipe({
  name: "itemsCount",
  pure: true,
})
export class ItemsCountPipe implements PipeTransform {
  transform(items: IControlOption[]): number {
    return (
      (items &&
        items.filter((item: IControlOption) => item && item.value).length -
          1) ||
      0
    );
  }
}
