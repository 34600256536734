import { ITableHeaders } from "@ui/tables/components/table/table.component";

export const QUICK_SEARCH_PERSONNEL_RESULTS_TABLE_HEADERS: ITableHeaders = [
  {
    key: "firstName",
    title: "First Name",
    sortable: true,
  },
  {
    key: "lastName",
    title: "Last Name",
    sortable: true,
  },
  {
    key: "jobTitle",
    title: "Job Function",
    sortable: true,
  },
  {
    key: "institution",
    title: "Institution",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
];

export const QUICK_SEARCH_ALL_INSTITUTIONS_RESULTS_TABLE_HEADERS: ITableHeaders =
  [
    {
      key: "name",
      title: "Name",
      sortable: true,
    },
    {
      key: "type",
      title: "Type",
      sortable: true,
    },
    {
      key: "city",
      title: "City",
      sortable: true,
    },
    {
      key: "state",
      title: "State",
      sortable: true,
    },
    {
      key: "enrollment",
      title: "Enrollment",
      sortable: true,
    },
    {
      key: "grants",
      title: "Grants",
      sortable: true,
    },
  ];

export const QUICK_SEARCH_ALL_INSTITUTIONS_RESULTS_TABLE_HEADERS_MOBILE: ITableHeaders =
  [
    {
      key: "name",
      title: "Name",
      sortable: true,
    },
    {
      key: "type",
      title: "Type",
      sortable: true,
    },
    {
      key: "city",
      title: "City",
      sortable: true,
    },
    {
      key: "state",
      title: "State",
      sortable: true,
    },
  ];

export const QUICK_SEARCH_INSTITUTIONS_RESULTS_TABLE_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "Name",
    sortable: true,
  },
  {
    key: "city",
    title: "City",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "enrollment",
    title: "Enrollment",
    sortable: true,
  },
  {
    key: "grants",
    title: "Grants",
    sortable: true,
  },
];

export const MIN_LENGTH_FOR_SEARCH_BY_PIDS: number = 3;
