import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "sortByField",
})
export class SortByFieldPipe implements PipeTransform {
  transform(values: any[], field: string): any {
    if (!Array.isArray(values)) {
      return values;
    }

    return [...values].sort((a: any, b: any) => {
      if (a[field] === b[field]) {
        return 0;
      }

      if (a[field]) {
        return -1;
      }

      if (b[field]) {
        return 1;
      }
    });
  }
}
