import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { MultiDownloadPopUpData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";
import { MultiDownloadPopUpResult } from "../../models/pop-up-result";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-multi-download-pop-up-content",
  templateUrl: "./multi-download-pop-up-content.component.html",
  styleUrls: ["./multi-download-pop-up-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiDownloadPopUpContentComponent {
  constructor(
    @Inject(POP_UP_DATA) public data: MultiDownloadPopUpData,
    private popUpRef: PopUpRef<
      MultiDownloadPopUpContentComponent,
      MultiDownloadPopUpResult
    >,
  ) {}

  onClose(): void {
    this.popUpRef.close({ answer: false });
  }

  onConfirm(event: MouseEvent): void {
    this.popUpRef.close({ answer: true, event });
  }
}
