import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { mapTo, take, tap, withLatestFrom } from "rxjs/operators";

import { getCreditsAction } from "@modules/credits/store/actions";
import { getIsSkipUpdateCredits } from "@modules/credits/store/selectors/credits.selector";
import { resetECommerceCartAction } from "@modules/e-commerce/store/actions/cart.action";
import {
  getIsAdminsAccount,
  getIsUnlimited,
} from "@modules/profile/store/selectors/profile.selector";
import { setRefreshCreditsTooltipStateAction } from "@ui/tooltip/store/actions/tooltips.action";
import { CoreState } from "../store/reducers";

import { SuccessPopUpContentComponent } from "@ui/pop-up/components/success-pop-up-content/success-pop-up-content.component";

import { PopUpService } from "@ui/pop-up/services/pop-up/pop-up.service";
import { StorageService } from "../services/storage.service";

import { CORE_PATHS } from "../constants/core-paths";

@Injectable({
  providedIn: "root",
})
export class DashboardShowNotificationGuard {
  constructor(
    private _store: Store<CoreState>,
    private _location: Location,
    private _popUpService: PopUpService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this._store.pipe(
      select(getIsUnlimited),
      take(1),
      withLatestFrom(
        this._store.pipe(select(getIsAdminsAccount)),
        this._store.pipe(select(getIsSkipUpdateCredits)),
      ),
      tap(
        ([isUnlimited, isAdminsAccount, isSkipUpdateCredits]: [
          boolean,
          boolean,
          boolean,
        ]) => {
          if (!isUnlimited && !isAdminsAccount && !isSkipUpdateCredits) {
            this._store.dispatch(getCreditsAction()); // added by task BL-3325
          }
        },
      ),
      tap(() => {
        if (route.queryParams["reasonCode"] === "100") {
          this._location.replaceState(
            `${CORE_PATHS.DASHBOARD}/${StorageService.activeDashboardTab}`,
          );
          if (!StorageService.refreshCreditsTooltipWasShowed) {
            this._store.dispatch(setRefreshCreditsTooltipStateAction(true));
          }

          this._store.dispatch(resetECommerceCartAction());
          this._popUpService.open(SuccessPopUpContentComponent, {
            showCloseBtn: false,
            timer: 3000,
            data: {
              title: "Purchase Successful!",
              iconClass: "icon-success",
            },
          });
        }
      }),
      mapTo(true),
    );
  }
}
