import { ComponentRef } from "@angular/core";

import { OverlayInfoComponent } from "../components/overlay-info/overlay-info.component";

export class OverlayInfoRef<C, D, A> {
  constructor(
    public componentRef: ComponentRef<OverlayInfoComponent<C, D, A>>,
    public show: () => void,
    public hide: () => void,
  ) {
    return componentRef ? this : null;
  }
}
