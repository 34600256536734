import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { map, take, withLatestFrom } from "rxjs/operators";

import { getIsBroker } from "@modules/profile/store/selectors/profile.selector";
import { go } from "../store/actions/router-history.action";
import { CoreState } from "../store/reducers";
import { getIsBrokerLicenseAccepted } from "../store/selectors/tos.selector";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { CORE_PATHS } from "../constants/core-paths";

@Injectable({
  providedIn: "root",
})
export class UnacceptedGeneralTermsByBrokerGuard {
  constructor(private _store: Store<CoreState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this._store.pipe(
      select(getIsBroker),
      take(1),
      withLatestFrom(this._store.pipe(select(getIsBrokerLicenseAccepted))),
      map(([isBroker, isBrokerLicenseAccepted]: [boolean, boolean]) => {
        if (isBroker && !isBrokerLicenseAccepted) {
          this._store.dispatch(
            go(["/" + CORE_PATHS.COMMON, CORE_PATHS.TERMS_OF_USE]),
          );
          return isBrokerLicenseAccepted;
        }

        return true;
      }),
      catchErrorWithErrorType,
    );
  }
}
