import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

@Directive({
  selector: "[blBtnSpinner]",
})
export class BtnSpinnerDirective implements AfterViewInit, OnChanges {
  @Input() disabled: boolean;
  @Input() clear: boolean = true;
  loader: any;

  @Input()
  set blBtnSpinner(loading: boolean) {
    this.loading = loading;

    if (this.elementRef && this.rect && this.innerHtml) {
      this.loading ? this.setLoadingState() : this.setDefaultState();
    }
  }

  private loading: boolean;
  private innerHtml: any;
  private rect: ClientRect;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    this.innerHtml = this.elementRef.nativeElement.innerHTML;
    this.rect = this.elementRef.nativeElement.getBoundingClientRect();

    this.blBtnSpinner = this.loading;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["disabled"]) {
      this.setDisabled(this.disabled);
    }
  }

  @HostListener("click", ["$event"])
  onClick(event: Event): void {
    if (this.loading) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  setLoadingState(): void {
    this.renderer.addClass(this.elementRef.nativeElement, "_loading");

    if (this.clear) {
      this.elementRef.nativeElement.innerHTML = "";
    }

    if (this.rect.width && this.rect.height) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        "width",
        `${this.rect.width}px`,
      );
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        "height",
        `${this.rect.height}px`,
      );
      this.renderer.setAttribute(
        this.elementRef.nativeElement,
        "disabled",
        "disabled",
      );
    }
  }

  setDefaultState(): void {
    this.renderer.removeClass(this.elementRef.nativeElement, "_loading");

    if (this.clear) {
      this.elementRef.nativeElement.innerHTML = this.innerHtml;
    }

    this.renderer.removeStyle(this.elementRef.nativeElement, "width");
    this.renderer.removeStyle(this.elementRef.nativeElement, "height");

    this.setDisabled(this.disabled);
  }

  private setDisabled(state: boolean): void {
    state
      ? this.renderer.setAttribute(
          this.elementRef.nativeElement,
          "disabled",
          "disabled",
        )
      : this.renderer.removeAttribute(
          this.elementRef.nativeElement,
          "disabled",
        );
  }
}
