import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FilterObjectByKeysPipe } from "./pipes/filter-by-keys.pipe";
import { ObjectValuesPipe } from "./pipes/object-values.pipe";
import { OrderByPipe } from "./pipes/order-by.pipe";
import { PaginationPipe } from "./pipes/pagination.pipe";
import { SortByFieldPipe } from "./pipes/sort-by-field.pipe";

const _pipes: any = [
  OrderByPipe,
  SortByFieldPipe,
  FilterObjectByKeysPipe,
  ObjectValuesPipe,
  PaginationPipe,
];

@NgModule({
  declarations: [..._pipes],
  imports: [CommonModule],
  exports: [..._pipes],
})
export class FiltersPipesModule {}
