import {
  IAccessAccountCDLLists,
  IManageAccountLists,
} from "../../interfaces/manage-account-lists";

export enum MANAGE_ACCOUNT_LISTS {
  ALL_PRODUCTS = 1,
  ONLY_ME_PRODUCTS,
  MY_AND_ACTIVE_PRODUCTS = 3,
}

export enum MANAGE_ACCOUNT_LISTS_DESCRIPTION {
  ONLY_ME_PRODUCTS = "Access to their lists only",
  ALL_PRODUCTS = "Access to all account lists",
  MY_AND_ACTIVE_PRODUCTS = "Access to their lists and Active account lists",
}

export const SWITCH_ACCOUNT_LISTS: IManageAccountLists = [
  {
    name: MANAGE_ACCOUNT_LISTS_DESCRIPTION.MY_AND_ACTIVE_PRODUCTS,
    value: MANAGE_ACCOUNT_LISTS.MY_AND_ACTIVE_PRODUCTS,
  },
  {
    name: MANAGE_ACCOUNT_LISTS_DESCRIPTION.ALL_PRODUCTS,
    value: MANAGE_ACCOUNT_LISTS.ALL_PRODUCTS,
  },
  {
    name: MANAGE_ACCOUNT_LISTS_DESCRIPTION.ONLY_ME_PRODUCTS,
    value: MANAGE_ACCOUNT_LISTS.ONLY_ME_PRODUCTS,
  },
];

export enum ACCESS_CDL_LISTS {
  NONE,
  VIEW_AND_CREATE_LIST_ONLY,
  ALL_ACTIONS,
}

export enum ACCESS_CDL_LISTS_DESCRIPTION {
  NONE = "None",
  VIEW_AND_CREATE_LIST_ONLY = "View and create lists only",
  ALL_ACTIONS = "Download, view, and create lists",
}

export const SWITCH_ACCESS_CDL_LISTS: IAccessAccountCDLLists = [
  {
    name: ACCESS_CDL_LISTS_DESCRIPTION.NONE,
    value: ACCESS_CDL_LISTS.NONE,
  },
  {
    name: ACCESS_CDL_LISTS_DESCRIPTION.VIEW_AND_CREATE_LIST_ONLY,
    value: ACCESS_CDL_LISTS.VIEW_AND_CREATE_LIST_ONLY,
  },
  {
    name: ACCESS_CDL_LISTS_DESCRIPTION.ALL_ACTIONS,
    value: ACCESS_CDL_LISTS.ALL_ACTIONS,
  },
];
