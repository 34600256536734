import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  bidsEntityReducer,
  IBidsEntityState,
} from "@shared/modules/bids-entity/store/reducers/bids-entity.reducer";

export interface IBidsEntityModuleState {
  bidsEntity: IBidsEntityState;
}

const downloadEntityModuleReducer: ActionReducerMap<IBidsEntityModuleState> = {
  bidsEntity: bidsEntityReducer,
};

// eslint-disable-next-line
export const BIDS_ENTITY_STATE_NAME = 'bidsEntityModule';
export const BIDS_ENTITY_STATE: InjectionToken<
  ActionReducerMap<IBidsEntityModuleState>
> = new InjectionToken<ActionReducerMap<IBidsEntityModuleState>>(
  BIDS_ENTITY_STATE_NAME,
  {
    factory: () => downloadEntityModuleReducer,
  },
);
