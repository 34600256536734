import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from "@angular/router";
import { NOTIFICATION_TYPES } from "@core/constants/notifications";
import { go } from "@core/store/actions/router-history.action";
import { CoreState } from "@core/store/reducers";
import { showNotificationAction } from "@modules/notifications/store/actions/notification.action";
import { select, Store } from "@ngrx/store";
import { Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap, take, tap } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { signUpByInviteUserNameErrorAction } from "../store/actions/sign-up-by-invite.action";
import { getInviteDataByUrl } from "../store/selectors/sign-up-by-invite.selector";

@Injectable()
export class checkUserNameBeforeInviationAcceptGuard {
  constructor(
    private store: Store<CoreState>,
    private authService: AuthService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkData();
  }

  private checkData(): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(
      select(getInviteDataByUrl),
      take(1),
      switchMap((data) => {
        return this.authService.checkUserName(data).pipe(
          map((_user) => {
            if (_user["responseCode"] === 2) {
              this.store.dispatch(go(["/auth/login"]));
              return false;
            }
            return true;
          }),
          catchError((serverError) => {
            return throwError(serverError);
          }),
        );
      }),
      catchError((serverError) => {
        if (
          serverError &&
          serverError.errors &&
          serverError.errors.token &&
          serverError.errors.token[0]
        ) {
          // uncomment below if error to be shown just Above form
          // this.store.dispatch(signUpByInviteUserNameErrorAction(serverError));
          this.store.dispatch(
            showNotificationAction({
              message: serverError.errors.token[0],
              type: NOTIFICATION_TYPES.ERROR,
              timeout: 6000,
              canClose: true,
            }),
          );
          return of(true);
        }
        return of(false);
      }),
    );
  }
}
