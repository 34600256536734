import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IInstitutionsRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { IToggleInstitutionRecordPayload } from "../../interfaces";

enum ACTION_TYPES {
  GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES = "[VIEW RESULTS INSTITUTIONS COLLEGES] Get institution colleges",
  GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_ERROR = "[VIEW RESULTS INSTITUTIONS COLLEGES] Get institution colleges error",
  GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_SUCCESS = "[VIEW RESULTS INSTITUTIONS COLLEGES] Get institution colleges success",

  TOGGLE_VIEW_RESULTS_INSTITUTIONS_COLLEGE = "[VIEW RESULTS INSTITUTIONS COLLEGES] Toggle",

  SET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_SPINNER_STATE = "[VIEW RESULTS INSTITUTIONS COLLEGES] Set spinner state",

  CHANGE_VIEW_RESULTS_INSTITUTIONS_COLLEGES_PAGE = "[VIEW RESULTS INSTITUTIONS COLLEGES] Change page",
  CHANGE_VIEW_RESULTS_INSTITUTIONS_COLLEGES_QUERY = "[VIEW RESULTS INSTITUTIONS COLLEGES] Change query",

  RESET_SELECTED = "[VIEW RESULTS INSTITUTIONS COLLEGES] Reset selected institution colleges",

  RESET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_PAGE_STATE = "[VIEW RESULTS INSTITUTIONS COLLEGES] Reset state",
}

export const getViewResultsInstitutionCollegesAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES,
  () => TypedAction<ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES>
> = createAction(ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES);
export const getViewResultsInstitutionCollegesErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_ERROR,
  createPayload<IServerError>(),
);
export const getViewResultsInstitutionCollegesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_SUCCESS,
  IProductResultsResponse<IInstitutionsRecords>
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_SUCCESS,
  createPayload<IProductResultsResponse<IInstitutionsRecords>>(),
);

export const toggleViewResultsInstitutionCollegeAction: CreateActionType<
  ACTION_TYPES.TOGGLE_VIEW_RESULTS_INSTITUTIONS_COLLEGE,
  IToggleInstitutionRecordPayload
> = createAction(
  ACTION_TYPES.TOGGLE_VIEW_RESULTS_INSTITUTIONS_COLLEGE,
  createPayload<IToggleInstitutionRecordPayload>(),
);

export const setViewResultsInstitutionCollegesSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeViewResultsInstitutionCollegesPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTIONS_COLLEGES_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTIONS_COLLEGES_PAGE,
  createPayload<number>(),
);

export const changeViewResultsInstitutionCollegesQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTIONS_COLLEGES_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTIONS_COLLEGES_QUERY,
  createPayload<Params>(),
);

export const resetViewResultsInstitutionCollegesStateAction: ActionCreator<
  ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_PAGE_STATE,
  () => TypedAction<ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_PAGE_STATE>
> = createAction(
  ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTIONS_COLLEGES_PAGE_STATE,
);

export const resetSelectedInstitutionCollegesAction: ActionCreator<
  ACTION_TYPES.RESET_SELECTED,
  () => TypedAction<ACTION_TYPES.RESET_SELECTED>
> = createAction(ACTION_TYPES.RESET_SELECTED);
