import { Params } from "@angular/router";
import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { getRouterQueryParams } from "@core/store/selectors/router.selector";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import {
  pending,
  signUpError,
  ISignUpByInviteState,
  verifyInvite,
  signUpCheckUserError,
} from "../reducers/sign-up-by-invite.reducer";

import { IServerError } from "@shared/interfaces/server-error";

export const signUpByInviteState: GetFromStateInSelectors<
  CoreState,
  ISignUpByInviteState
> = (state: CoreState): ISignUpByInviteState => state.signUpByInvite;

export const getSignUpByInviteError: MemoizedSelector<CoreState, IServerError> =
  createSelector(signUpByInviteState, signUpError);
export const getSignUpByInvitePending: MemoizedSelector<CoreState, boolean> =
  createSelector(signUpByInviteState, pending);
export const getVerifyInvite: MemoizedSelector<CoreState, boolean> =
  createSelector(signUpByInviteState, verifyInvite);
export const getSignUpCheckUserError: MemoizedSelector<
  CoreState,
  IServerError
> = createSelector(signUpByInviteState, signUpCheckUserError);

export const getAutoLoginUrl: MemoizedSelector<CoreState, string> =
  createSelector(getRouterQueryParams, (params: Params = {}) => {
    let email: string = params["email"];
    return email;
  });

export const getInviteDataByUrl: MemoizedSelector<CoreState, Params> =
  createSelector(getRouterQueryParams, (params: Params = {}) => {
    const { accountName, ...rest }: Params = params;

    return rest;
  });
