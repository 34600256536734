import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
} from "@angular/core";

import { IControlOption, IControlOptions } from "@shared/interfaces/forms";

@Component({
  selector: "bl-flat-drop-down",
  templateUrl: "./flat-drop-down.component.html",
  styleUrls: ["./flat-drop-down.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlatDropDownComponent implements OnChanges {
  @Input() items: IControlOptions;
  @Input() placeholder: string = "Refresh Schedule";
  @Input() dropDownTargetTemplate: TemplateRef<any>;
  @Input() menuItemsTemplate: TemplateRef<any>;

  @Output() selectFlatDropDownItem: EventEmitter<IControlOption> =
    new EventEmitter<IControlOption>();

  selectedOption: IControlOption = null;

  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this.selectedOption = this.resetToFirstAvailableSelected();
  }

  trackByFn(index: number, item: IControlOption): number {
    return item.id;
  }

  selectItem(item: IControlOption, value: boolean): void {
    this._cdr.detectChanges();
    this.selectFlatDropDownItem.emit({ ...item, value });
    this.selectedOption = value ? item : this.resetToFirstAvailableSelected();
  }

  resetToFirstAvailableSelected(): IControlOption {
    if (this.items && this.items.length > 0) {
      return this.items.find((item: IControlOption) => item.value === true);
    }
    return null;
  }
}
