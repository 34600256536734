import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  OnDestroy,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

import { fromEvent, Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";

import { WindowRef } from "@core/refs/window-ref.service";

import { IOverlayInfoParams } from "../../interfaces";

import {
  OVERLAY_INFO_COMPONENT,
  OVERLAY_INFO_COMPONENT_PARAMS,
  OVERLAY_INFO_TARGET,
  POSITION_X,
  POSITION_Y,
} from "../../models";

@Component({
  selector: "bl-overlay-info",
  templateUrl: "./overlay-info.component.html",
  styleUrls: ["./overlay-info.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayInfoComponent<C, D, A> implements AfterViewInit, OnDestroy {
  public isOpen: boolean;
  private destroyer$: Subject<void> = new Subject();

  @ViewChild("container", { read: ViewContainerRef })
  viewContainer: ViewContainerRef;

  constructor(
    private _renderer: Renderer2,
    private _elementRef: ElementRef<OverlayInfoComponent<C, D, A>>,
    private _windowRef: WindowRef,
    private _changeDetectorRef: ChangeDetectorRef,
    @Inject(OVERLAY_INFO_TARGET) public target: ElementRef<any>,
    @Inject(OVERLAY_INFO_COMPONENT) public component: ComponentRef<C>,
    @Inject(OVERLAY_INFO_COMPONENT_PARAMS)
    public params: IOverlayInfoParams<C, D, A>,
  ) {}

  ngAfterViewInit(): void {
    this._position();
    this._attachInfoComponent();

    fromEvent(this._windowRef.nativeElement, "resize")
      .pipe(
        takeUntil(this.destroyer$),
        debounceTime(1000),
        filter(() => this.isOpen),
      )
      .subscribe(() => this._position());
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  private _attachInfoComponent(): void {
    this.viewContainer.insert(this.component.hostView);
    this._changeDetectorRef.detectChanges();
  }

  private _position(): void {
    const { bottom, left, right, width }: ClientRect =
      this.target.nativeElement.getBoundingClientRect();

    if (this.params.positionY === POSITION_Y.BOTTOM) {
      this._renderer.setStyle(
        this._elementRef.nativeElement,
        "top",
        bottom + 30 + "px",
      );
    }

    if (this.params.positionX === POSITION_X.CENTER) {
      const value: number = left + width / 2;
      this._renderer.setStyle(
        this._elementRef.nativeElement,
        "left",
        value + "px",
      );
      this._renderer.addClass(this._elementRef.nativeElement, "center");
    }

    if (this.params.positionX === POSITION_X.RIGHT) {
      const value: number = this._windowRef.nativeElement.innerWidth - right;
      this._renderer.setStyle(
        this._elementRef.nativeElement,
        "right",
        value + "px",
      );
      this._renderer.addClass(this._elementRef.nativeElement, "right");
    }

    if (this.params.positionX === POSITION_X.LEFT) {
      this._renderer.setStyle(
        this._elementRef.nativeElement,
        "left",
        left + "px",
      );
      this._renderer.addClass(this._elementRef.nativeElement, "left");
    }
  }
}
