import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";

import { ISignUpByInvitePayload } from "@modules/auth/interfaces/formsActionsData";
import { IServerError } from "@shared/interfaces/server-error";

import { CORE_PATHS } from "@core/constants/core-paths";
import { FLAT_INPUT_THEME } from "@shared/constants/flat-input";
import { PASSWORD_TIP } from "@shared/constants/tooltips/tooltips-text";
import { PhoneMaskPipe } from "@shared/modules/pipes/shared-pipes/pipes/phone-mask.pipe";

@Component({
  selector: "bl-sign-up-by-invite-form",
  templateUrl: "./sign-up-by-invite-form.component.html",
  styleUrls: ["./sign-up-by-invite-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class SignUpByInviteFormComponent implements OnChanges {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  @Input() isReCaptcha: boolean = false;
  @Input() form: UntypedFormGroup;
  @Input() serverError: IServerError;
  @Input() pending: boolean;

  @Output() submitSignUpByInviteForm: EventEmitter<ISignUpByInvitePayload> =
    new EventEmitter();

  readonly passwordTip: string = PASSWORD_TIP;
  phoneMask: PhoneMaskPipe = new PhoneMaskPipe();

  get tokenError(): string {
    return (
      this.serverError &&
      this.serverError.errors &&
      this.serverError.errors.token &&
      this.serverError.errors.token[0]
    );
  }

  get userExistsError(): string[] {
    if (
      this.serverError &&
      this.serverError.errors &&
      this.serverError.errors.userExists
    ) {
      return this.serverError.errors.userExists;
    }
  }

  submit(): void {
    this.submitSignUpByInviteForm.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const errors: SimpleChange = changes.serverError;

    if (errors && errors.currentValue) {
      Object.entries(this.serverError.errors).forEach(
        ([fieldName, message]: [string, string[]]) => {
          const control: AbstractControl = this.form.get(fieldName);
          if (control && fieldName !== "token") {
            control.setErrors({ invalid: message[0] });
          }
        },
      );

      this._resetFieldRecaptcha();
    }
  }

  private _resetFieldRecaptcha(): void {
    this.form.patchValue({ gRecaptchaResponse: "" });
  }
}
