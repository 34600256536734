import { Directive, ElementRef, HostListener } from "@angular/core";

@Directive({
  selector: "[blPositiveInteger]",
})
export class OnlyPositiveIntegerDirective {
  public text: string;

  private regex: RegExp = new RegExp(/^[1-9]\d*$/g);
  private specialKeys: string[] = ["Backspace", "Tab"];

  constructor(private _elementRef: ElementRef) {}

  @HostListener("keypress", ["$event"])
  onKeyDown(event: KeyboardEvent): void {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    const current: string = this._elementRef.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}
