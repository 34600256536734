import { IAssignUserDataPopUpData } from "../components/assign-user-data-pop-up-content/assign-user-data-pop-up-content.component";

import { StorageService } from "@core/services/storage.service";

import { IRemoveCreditInfo } from "@modules/profile/interfaces/pop-ups";
import { IControlOption } from "@shared/interfaces/forms";
import {
  ICloudSyncAuthPopUpData,
  IWelcomeWithMarketViewPopUp,
} from "../interfaces";

import {
  ConfirmOrNotPopUpData,
  ConfirmPopUpData,
  FirstSignUpPopUpData,
  InfoPopUpData,
  PopUpText,
  PremiumPopUpData,
  UserCreditsInfoPopUp,
} from "../models/pop-up-data";

import { TARGETING_DATA_CONTROL_TYPES } from "@shared/modules/targeting-criteria-controls/data";
import {
  getTextWithCredits,
  getTextWithCreditsAndEmails,
  getTextWithEmails,
  INFO_DATA,
} from "./info-pop-up";

export enum POP_UP_TYPES {
  MV_RENEWAL = 1,
  STATE_SEATS_ASSIGN,
}

export const CLEAR_TARGETING_POP_UP: ConfirmPopUpData = {
  title: "Clear targeting criteria?",
  body: "By accepting, you will clear the criteria in the corresponding targeting container. This cannot be undone.",
  iconClass: "icon-delete",
  buttonText: "Yes, clear",
};

export const DELETE_SEGMENT_POP_UP: ConfirmPopUpData = {
  title: "Delete this segment?",
  body: "Deleting your segment means you will no longer have access to this segment or any of its data. This cannot be undone.",
  iconClass: "icon-delete",
  buttonText: "Yes, delete",
};

export const DELETE_LIST_POP_UP: ConfirmPopUpData = {
  title: "Delete this list?",
  body: "Deleting your list means you will no longer have access to this list or any of its data. This cannot be undone.",
  iconClass: "icon-delete",
  buttonText: "Yes, delete",
};

export const DELETE_CUSTOM_LIST_POP_UP: ConfirmPopUpData = {
  title: "Delete this custom list?",
  body: "Deleting your custom list means you will no longer have access to this custom list or any of its data. This cannot be undone.",
  iconClass: "icon-delete",
  buttonText: "Yes, delete",
};

export const CUSTOM_LIST_APPLY_POP_UP: ConfirmPopUpData = {
  title: "Refresh Required",
  body: `The lists using this Custom List need to be refreshed in order for your changes to be available for export.
   We will send you an email when they are ready to be refreshed. <br /><br />
   If you choose not to refresh in the short term, your lists will be ready for export after our next database
   update (less than 14 days).`,
  hideDoNotShowCheckbox: true,
  iconClass: "exclamation-point",
  buttonText: "Got, it",
};

export const deleteCustomListDataPopUp: (
  listNames: string,
) => ConfirmPopUpData = (listNames: string): ConfirmPopUpData => ({
  title: "This Custom list is in use!",
  body: `This Custom list is currently being used by the following lists: ${listNames}.
   You canot remove this Custom as long as the associated lists exist.`,
  iconClass: "icon-custom-list",
  buttonText: "Got it",
  hideDoNotShowCheckbox: true,
});

export const editCustomListData: (
  listNames: string,
) => ConfirmOrNotPopUpData = (listNames: string): ConfirmOrNotPopUpData => ({
  title: "This Custom list is in use!",
  body: `This Custom list is currently being used by the following lists: ${listNames}. Any edits you make will be
   applied to these lists.`,
  iconClass: "icon-custom-list",
  acceptText: "Edit Custom",
  declineText: "Cancel",
});

export const DELETE_VIEW_POP_UP: ConfirmPopUpData = {
  title: "Delete this View?",
  body: "Deleting this View will remove it and all its criteria from your dashboard. Any lists you created from this View will remain.",
  iconClass: "icon-delete",
  buttonText: "Yes, delete",
};

export const MULTIPLY_CHECKBOXES_POP_UP: ConfirmPopUpData = {
  title: "This person has more than one job title",
  body: "No worries, you will only get charged once for this record if you build a list.",
  iconClass: "success-img",
  buttonText: "Got it",
};

export const EMAIL_DOWNLOAD_INFO_POP_UP: InfoPopUpData = {
  icon: "icon-clock-large",
  body: "A link to your list will be emailed once it is done processing.",
};

export const CONFIRM_LIST: ConfirmPopUpData = {
  title: "Activate this list?",
  body: `Activating a list allows you to download and use its records. The corresponding amount of credits will
   be deducted from your account.`,
  iconClass: "success-img",
  buttonText: "Yes, activate",
};

export const SMALL_TIME_LIST_TEXT: PopUpText = {
  body: "A link to your list will be emailed once it is done processing- this may take up to 1 minute",
};

export const DEFAULT_TIME_TEXT: PopUpText = {
  body: "A link to your list will be emailed once it is done processing - this may take up to 20 minutes.",
};

export const SHORTEST_TIME_TEXT: PopUpText = {
  body: "Your refresh will be completed shortly and will be available for download once completed.",
};

export const FIRST_SIGN_UP_DATA: any = {
  body: "Welcome to the MDR ConnectED Cloud!",
  message:
    "Here’s the Top 3 ways to get started using the most powerful platform in the education market.",
};

export const FRAME_SIGN_UP_SUCCESS: PopUpText = {
  body: "Thank you for signing up for ConnectED Data!",
};

export const RE_DOWNLOAD_POP_UP: PopUpText = {
  title: "Re-download?",
  body: "Your previous download link has already been used. Navigate to your Dashboard to download again.",
};

export const PRICING_CARD_CALL_TO_SUPPORT: ConfirmPopUpData = {
  title: "That's a lot of credits!",
  body: "We recommend engaging with one of our data experts. Call now for one-on-one support!",
  iconClass: "success-img",
  buttonText: "Call now",
  shouldCallLink: true,
};

export const SELECT_ALL_CRITERIAS: ConfirmPopUpData = {
  title: "Reset Detailed Targeting?",
  iconClass: "success-img",
  body: 'If you "Select/Deselect All", then you will remove your Detailed Targeting criteria.',
  buttonText: "Yes, confirm",
  updateStorage: (checkBoxValue: boolean) =>
    (StorageService.doNotShowDetailsTargetingWarningMsg = checkBoxValue),
};

export const PID_ONLY_POP_UP: ConfirmOrNotPopUpData = {
  title: "Remove Geography targeting?",
  body: "By targeting Names and PIDs only you will be permanently removing your current targeting in the Geography Type categories.",
  declineText: "Cancel",
  acceptText: "Target Names and PIDs only",
};

export const REMOVE_USER_WITHOUT_DATA: ConfirmOrNotPopUpData = {
  title: "Delete this user?",
  body: `Deleting this user will remove them from your account and cannot be undone.`,
  acceptText: "Yes, delete",
  declineText: "Cancel",
};

export const ADD_OR_NOT_PHONE_APPENDS: ConfirmOrNotPopUpData = {
  title: "Add phone number to file?",
  // TODO change message for feature selects with appends;
  body: (
    cost: number,
  ) => `You have selected Phone Number Available, would you also like to append phone numbers to
   your file for ${cost} credits?`,
  acceptText: "Yes, add phone numbers",
  declineText: "No, thanks",
  iconClass: "icon-phone",
};

export const REFRESH_CUSTOM_OBJECT_CONFIRMATION_POP_UP_DATA: ConfirmOrNotPopUpData =
  {
    showDoNotShowPopUpCheckbox: true,
    title: "This may take a minute",
    body: `This process may take ten minutes or more. You will be redirected to the Dashboard while we're processing. We will let you know as soon as the process is complete.`,
    acceptText: "Got it",
    declineText: "Cancel",
    iconClass: "icon-clock-large",
  };

export const MV_STATE_SEATS_ASSIGN_POP_UP_DATA: ConfirmOrNotPopUpData = {
  title: "Assign Your State Seats!",
  body: `Welcome back to MarketView! Your State Seats have been unassigned to enable you to change your targeted territories for the year. Go to Products & Users in your account to reassign State Seats for your team.`,
  acceptText: "Assign State Seats",
  declineText: "Later",
  iconClass: "icon-view",
};

export const getRemoveUserWithDataPopUp: (
  firstName: string,
  lists: number,
  views: number,
) => ConfirmOrNotPopUpData = (
  firstName: string,
  lists: number,
  views: number,
) => ({
  title: "How would you like to delete this user?",
  body: `${firstName} has ${lists} lists and ${views} Views. You can delete the user and their data, or delete the user and
   assign their data to another user. Deleting data cannot be undone.`,
  acceptText: "Delete User & Assign Data",
  declineText: "Delete User & Data",
});

export const SELECT_PERSONNEL_DAYCARE: ConfirmPopUpData = {
  title: "Have you selected your Institution Type?",
  iconClass: "targeting-type__icon--1",
  body: `These job titles can be included in Daycares, Districts, or Schools. To narrow your targeting, go to Institution Type and
   select your targeted institutions.`,
  buttonText: "Got it, thanks!",
};

export const SEGMENT_BY_COUNT_EXCEEDED_POP_UP_DATA: ConfirmPopUpData = {
  title: "Segment Max Exceeded",
  iconClass: "icon-exclamation-point",
  body: "A list cannot exceed 100 segments. Consider grouping your criteria into more broadly-targeted segments.",
  buttonText: "Got it",
  hideDoNotShowCheckbox: true,
};

export const SEGMENT_BY_CONFIRM_POP_UP_DATA: ConfirmOrNotPopUpData = {
  acceptText: "Segment",
  declineText: "Cancel",
  additionalText:
    "You are about to separate your current segment into multiple segments. This cannot be undone.",
  iconClass: " ",
};

export const CDL_CHANGE_RECORD_TYPE_POP_UP_DATA: ConfirmOrNotPopUpData = {
  acceptText: "Continue",
  declineText: "Cancel",
  title: "Update your data",
  iconClass: "icon-exclamation-point",
};

export const CDL_LIST_HISTORY_EDIT_COMMENT_POP_UP: ConfirmOrNotPopUpData = {
  title: "Edit comment",
  body: "Write comment below:",
  acceptText: "Edit comment",
  declineText: "Cancel",
};

export const CHANGE_TO_INSTITUTION_AND_PERSONNEL_TEXT: string = `If you change your File Type, you will need to add targeting
 in the Personnel tab, as well as check your add-ons and file customizations to make sure they are all updated`;

export const CHANGE_TO_INSTITUTION_TEXT: string = `Changing your File Type will delete any data relating to your personnel targeting,
 add-ons, and output customization. This cannot be undone.`;

export const getUserCreditsInfoPopUpData: (
  infoData: IRemoveCreditInfo,
) => UserCreditsInfoPopUp = (
  infoData: IRemoveCreditInfo,
): UserCreditsInfoPopUp => {
  const textData: UserCreditsInfoPopUp = {
    header: INFO_DATA.HEADER,
    buttonText: INFO_DATA.BUTTON_TEXT,
    title: INFO_DATA.TITLE,
    body: INFO_DATA.BODY,
  };

  if (infoData && infoData.credits && !infoData.emails) {
    textData.title = INFO_DATA.TITLE_WITH_CREDITS;
    textData.body = getTextWithCredits(infoData.credits);
  }

  if (infoData && !infoData.credits && infoData.emails) {
    textData.title = INFO_DATA.TITLE_WITH_EMAILS;
    textData.body = getTextWithEmails(infoData.emails);
  }

  if (infoData && infoData.credits && infoData.emails) {
    textData.title = INFO_DATA.TITLE_WITH_CREDITS_AND_EMAILS;
    textData.body = getTextWithCreditsAndEmails(infoData);
  }

  return textData;
};

export const ASSIGN_DATA_POP_UP: IAssignUserDataPopUpData = {
  title: "Who should receive this data?",
  body: "Once assigned, the new user will need to have the same Seat(s) and Add-Ons in order to view all related data.",
  acceptText: "Assign Data",
  iconClass: "icon-delete",
};

export const DELETE_USER_AND_ASSIGN_POP_UP: IAssignUserDataPopUpData = {
  title: "Who should receive this data?",
  body: "Once assigned, the new user will need to have the same Seat(s) and Add-Ons in order to view all related data.",
  acceptText: "Delete User & Assign Data",
  iconClass: "icon-delete",
};

export const ASSIGN_OWNER_POP_UP: IAssignUserDataPopUpData = {
  title: "Assign new Account Owner?",
  body:
    "Assigning a new Account Owner requires some technical magic at MDR and may take up to three business days. " +
    "The new Account Owner will receive all of your lists and Views. Once the new Owner is officially assigned you will " +
    'receive an email asking you to sign back up. To initiate the process, select a new Owner below and click "Assign New Owner."',
  acceptText: "Assign New Owner",
  iconClass: "icon-people",
};

export const ASSIGN_OWNER_BY_INVITATION_POP_UP: IAssignUserDataPopUpData = {
  title: "Who should be the new Account Owner?",
  body: "In order to leave this account you need to designate a new Account Owner from the list below.",
  acceptText: "Assign Account Owner",
  iconClass: "icon-people",
};

export const ASSIGN_OWNER_SUCCESS_INFO_POP_UP: ConfirmPopUpData = {
  title: "Your new Account Owner has been successfully assigned",
  body: "You will receive another invitation email shortly. Use this email to change your accounts.",
  iconClass: "icon-people",
  buttonText: "Ok, thanks",
  hideDoNotShowCheckbox: true,
};

export const ACTIVE_LIST_APPEND_DATA_NO_SET_CAP: ConfirmPopUpData = {
  title: "Append Data",
  body: `To append data to your file, we need to update your existing records. There will be an additional charge for any new
   records as well as for your newly appended data.`,
  iconClass: "icon-plus modal",
  buttonText: "Got it",
  hideDoNotShowCheckbox: true,
};

export const ACTIVE_LIST_APPEND_DATA_WITH_SET_CAP: ConfirmPopUpData = {
  title: "Append Data",
  body: `To add data to your file, we need to update your existing records. There will be an additional charge for any new
   records as well as for your newly appended data. Because your list has special restrictions (e.g., Set Cap) it will take
    some time to calculate your counts. We will notify you once your list is ready to add appends.`,
  iconClass: "icon-plus modal",
  buttonText: "Got it",
  hideDoNotShowCheckbox: true,
};

export const getWelcomePopUpData: (userName: string) => FirstSignUpPopUpData = (
  userName: string,
): FirstSignUpPopUpData => ({
  title: `Hi ${userName},<br />Welcome to the MDR ConnectED Cloud!`,
  ...FIRST_SIGN_UP_DATA,
  actions: null,
});

export const getAssignOwnerMergePopUpData: () => {
  additionalText: string;
  acceptText: string;
  title: string;
  body: string;
  declineText: string;
} = () => ({
  title: "Your new Account Owner has been successfully assigned",
  body: `By joining this new account you will be leaving your current account.
   In order to join this new account, you need to merge your products to your current account.
   Click \\‘Merge Products\\’ below to start the process. Merging can take up to three business days, and cannot be undone.`,
  additionalText:
    "Once your merge is complete you will receive a separate email which will allow you to accept the invitation.",
  acceptText: "Merge Products",
  declineText: "Cancel",
});

export const WELCOME_WITH_MARKETVIEW_POP_UP: IWelcomeWithMarketViewPopUp = {
  header: "Welcome to ConnectED Data + MarketView!",
  title: "To give yourself and your team seats and add-ons:",
  body: [
    "Click on your name in the upper right corner of the screen",
    'Click on "Products & Users"',
    'Click on "Add" User',
  ],
  buttonConfirm: "Take me there",
  buttonCancel: "I'll assign seats later",
};

export const PREMIUM_POP_UP_DATA: { [key: string]: PremiumPopUpData } = {
  [TARGETING_DATA_CONTROL_TYPES.CHECKBOX]: {
    title: `You've got premium data!`,
    body: `Criteria with a green underline are premium.
      Each premium criterion selected in a segment adds 0.15 credits per record in that segment upon activating a list.`,
    checkboxText: `Do not show this message again`,
    updateStorage: (checkBoxValue: boolean) =>
      (StorageService.doNotShowPremiumCheckboxPopUp = checkBoxValue),
  },
  [TARGETING_DATA_CONTROL_TYPES.RADIO]: {
    title: `You've got premium data!`,
    body: `Criteria with a green underline are premium.
      Each premium criterion selected in a segment adds 0.15 credits per record in that segment upon activating a list.`,
    checkboxText: `Do not show this message again`,
    updateStorage: (checkBoxValue: boolean) =>
      (StorageService.doNotShowPremiumRadioPopUp = checkBoxValue),
  },
  [TARGETING_DATA_CONTROL_TYPES.RADIO_BUTTON]: {
    title: `You've got premium data!`,
    body: `Criteria with a green underline are premium.
      Each premium criterion selected in a segment adds 0.15 credits per record in that segment upon activating a list.`,
    checkboxText: `Do not show this message again`,
    updateStorage: (checkBoxValue: boolean) =>
      (StorageService.doNotShowPremiumRadioButtonPopUp = checkBoxValue),
  },
  [TARGETING_DATA_CONTROL_TYPES.SLIDER]: {
    title: `You've got premium data!`,
    body: `Criteria with a green underline are premium.
      Each premium criterion selected in a segment adds 0.15 credits per record in that segment upon activating a list.`,
    checkboxText: `Do not show this message again`,
    updateStorage: (checkBoxValue: boolean) =>
      (StorageService.doNotShowPremiumScrollerPopUp = checkBoxValue),
  },
  [TARGETING_DATA_CONTROL_TYPES.CUSTOM_DROPDOWN]: {
    title: `You've got premium data!`,
    body: `Criteria with a green underline are premium.
      Each premium criterion selected in a segment adds 0.15 credits per record in that segment upon activating a list.`,
    checkboxText: `Do not show this message again`,
    updateStorage: (checkBoxValue: boolean) =>
      (StorageService.doNotShowPremiumCustomDropDownPopUp = checkBoxValue),
  },
};

export const getCloudSyncAuthPopUpData: (
  _platformName: string,
  _sandbox: boolean,
) => ICloudSyncAuthPopUpData = (
  _platformName: string,
  _sandbox: boolean,
): ICloudSyncAuthPopUpData => ({
  title: "Authorize MDR CloudSync",
  subTitle: `Provide the ${_platformName}™ credentials of the account you would like to sync to.`,
  submitText: "Authorize",
  sandbox: _sandbox,
});

export const getOmsAuthPopUpData: (
  _platformName: string,
  sandbox: boolean,
) => ICloudSyncAuthPopUpData = (
  _platformName: string,
  sandbox: boolean,
): ICloudSyncAuthPopUpData => ({
  title: `Provide ${_platformName} Credentials`,
  subTitle: `We use MDR CloudSync to connect and synchronize with data in your ${_platformName} account. First, select
   whether you are providing credentials for your Sandbox or Production environment. Then, please enter the credentials
    below allow MDR CloudSync to connect to your ${_platformName} organization.`,
  submitText: "Authorize",
  sandbox,
});

export const CLOUD_SYNC_ENVIRONMENTS: IControlOption[] = [
  {
    label: "SANDBOX",
    value: true,
  },
  {
    label: "PRODUCTION",
    value: false,
  },
];

export const REFRESH_BLOCKED_POP_UP: ConfirmPopUpData = {
  iconClass: "exclamation-point",
  body: `Refresh is temporarily disabled, as we are currently running a data update on ConnectED Cloud.
    When the data update is complete, you will recieve an email letting you know that the list refresh
    is ready - giving you the most current data available.`,
  buttonText: "Got it!",
  hideDoNotShowCheckbox: true,
};

export const CHECK__BROWSER_IE_POP_UP: ConfirmPopUpData = {
  iconClass: "exclamation-point",
  title: "Unsupported Browser",
  body: `You're using a web browser we do not fully support.
  While you can continue to use the site, there may be rendering issues. Try using Chrome, Safari, or Firefox for a better experience.`,
  buttonText: "Got it",
  hideDoNotShowCheckbox: true,
};

export const CDL_ACTIVATE_POP_UP_DATA: ConfirmOrNotPopUpData = {
  title: "Activate Strategic Data?",
  body: `Activating this Strategic Data will enable all users on this account with the appropriate permissions to see and interact
   with the data on their dashboard. This includes the ability to download and sync.`,
  acceptText: "Yes, Activate",
  declineText: "Cancel",
  iconClass: "icon-exclamation-point",
  dataTestIdForAcceptButton: "yes-activate-btn",
};

export const CDL_DEACTIVATE_POP_UP_DATA: ConfirmOrNotPopUpData = {
  title: "Deactivate Strategic Data?",
  body: "Deactivating this Strategic Data will remove this data from this Account. It can be reactivated at anytime.",
  acceptText: "Yes, Deactivate",
  declineText: "Cancel",
  iconClass: "icon-exclamation-point",
};

export const CUSTOMIZE_RESET_POP_UP_DATA: ConfirmOrNotPopUpData = {
  title: "",
  body: `Your Layout Customization will be reverted to default columns for the selected output.`,
  acceptText: "Proceed",
  declineText: "Cancel",
  iconClass: "icon-exclamation-point",
  showDoNotShowPopUpCheckbox: true,
};
