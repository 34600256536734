export const SET_CAP_TOOLTIP: string =
  "Limit and randomize the distribution of your list.";
export const DEDUPE_TOOLTIP: string = `Checking Dedupe Institutions will remove the institutions from this segment that
 are present in your previous segments.`;

export const MAX_SEGMENT_BY_ERROR_MSG: string =
  "Maximum segments limit reached";
export const MAX_SEGMENTS_COUNT: number = 100;

export const DUPLICATE_SEGMENTS_PROGRESS: string =
  "Segments duplication in progress";
export const DUPLICATE_SEGMENTS_READY: string = "Segments duplication complete";
