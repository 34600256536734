import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

import {
  groupAddOnAvailableDisabled,
  groupAddOnAvailableEnabled,
  groupAddOnDisabled,
  groupAddOnEnabled,
  groupAddOnOnUserDisabled,
  groupAddOnOnUserEnabled,
} from "../utils/add-ons-form";

import { IOpenSeatAddOns, ISelectedAddOnIds } from "../interfaces/marketview";

import { ADD_ON_FIELD } from "../constants/add-ons";

@Injectable({ providedIn: "root" })
export class AddOnsService {
  checkAvailableAddOnsControl(
    _openAddOns: IOpenSeatAddOns,
    _userBidAddOnIds: number[] = [],
    _userGrantAddOnIds: number[] = [],
    _selectedAddOnsIds: ISelectedAddOnIds,
    _selectedStateId: number,
    _bidControl: AbstractControl,
    _grantControl: AbstractControl,
  ): void {
    const {
      bidStateUnassigned,
      bidStateAvailable,
      grantStateUnassigned,
      grantStateAvailable,
    }: IOpenSeatAddOns = _openAddOns;

    if (
      _selectedAddOnsIds.bidAddOnIds.length < bidStateUnassigned ||
      bidStateAvailable.includes(_selectedStateId) ||
      _userBidAddOnIds.includes(_selectedStateId)
    ) {
      _bidControl.enable();
    } else {
      _bidControl.reset();
      _bidControl.disable();
    }

    if (
      _selectedAddOnsIds.grantAddOnIds.length < grantStateUnassigned ||
      (grantStateAvailable.includes(_selectedStateId) &&
        _userGrantAddOnIds.includes(_selectedStateId))
    ) {
      _grantControl.enable();
    } else {
      _grantControl.reset();
      _grantControl.disable();
    }

    if (_bidControl && _userBidAddOnIds.includes(_selectedStateId)) {
      _bidControl.patchValue(true);
      _bidControl.enable();
    }

    if (_grantControl && _userGrantAddOnIds.includes(_selectedStateId)) {
      _grantControl.patchValue(true);
      _grantControl.enable();
    }
  }

  updateAvailableAddOnsControls(
    _stateControls: AbstractControl[] = [],
    _isChecked: boolean,
    _openAddOns: IOpenSeatAddOns,
    _userBidAddOnIds: number[] = [],
    _userGrantAddOnIds: number[] = [],
    _selectedAddOnsIds: ISelectedAddOnIds,
  ): void {
    if (_isChecked) {
      // need to disable not available add-ons
      this.disableBidAddOns(
        _stateControls,
        _openAddOns,
        _userBidAddOnIds,
        _selectedAddOnsIds,
      );
      this.disableGrantAddOns(
        _stateControls,
        _openAddOns,
        _userGrantAddOnIds,
        _selectedAddOnsIds,
      );
    } else {
      // need to enable available add-ons
      this.enableBidAddOns(
        _stateControls,
        _openAddOns,
        _userBidAddOnIds,
        _selectedAddOnsIds,
      );
      this.enableGrantAddOns(
        _stateControls,
        _openAddOns,
        _userGrantAddOnIds,
        _selectedAddOnsIds,
      );
    }
  }

  disableBidAddOns(
    _stateControls: AbstractControl[] = [],
    _openAddOns: IOpenSeatAddOns,
    _userBidAddOnIds: number[] = [],
    _selectedAddOnsIds: ISelectedAddOnIds,
  ): void {
    const { bidStateUnassigned, bidStateAvailable }: IOpenSeatAddOns =
      _openAddOns;
    const _bidGroupEnabled: AbstractControl[] = groupAddOnEnabled(
      _stateControls,
      bidStateAvailable,
      _userBidAddOnIds,
    );
    const _bidGroupOnUserEnabled: AbstractControl[] = groupAddOnOnUserEnabled(
      _stateControls,
      bidStateAvailable,
      _userBidAddOnIds,
    );
    const _bidGroupAvailableEnabled: AbstractControl[] =
      groupAddOnAvailableEnabled(_stateControls, bidStateAvailable);

    if (_selectedAddOnsIds.bidAddOnIds.length >= bidStateUnassigned) {
      _bidGroupEnabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.BID).disable(),
      );
    }

    if (
      _selectedAddOnsIds.bidAddOnIdsOnUser.length >= _userBidAddOnIds.length
    ) {
      _bidGroupOnUserEnabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.BID).disable(),
      );
    }

    if (_selectedAddOnsIds.bidAvailableIds.length >= bidStateAvailable.length) {
      _bidGroupAvailableEnabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.BID).disable(),
      );
    }
  }

  enableBidAddOns(
    _stateControls: AbstractControl[] = [],
    _openAddOns: IOpenSeatAddOns,
    _userBidAddOnIds: number[] = [],
    _selectedAddOnsIds: ISelectedAddOnIds,
  ): void {
    const { bidStateUnassigned, bidStateAvailable }: IOpenSeatAddOns =
      _openAddOns;
    const _bidGroupDisabled: AbstractControl[] = groupAddOnDisabled(
      _stateControls,
      bidStateAvailable,
      _userBidAddOnIds,
    );
    const _bidGroupOnUserDisabled: AbstractControl[] = groupAddOnOnUserDisabled(
      _stateControls,
      bidStateAvailable,
      _userBidAddOnIds,
    );
    const _bidGroupAvailableDisabled: AbstractControl[] =
      groupAddOnAvailableDisabled(_stateControls, bidStateAvailable);

    if (_selectedAddOnsIds.bidAddOnIds.length < bidStateUnassigned) {
      _bidGroupDisabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.BID).enable(),
      );
    }

    if (_selectedAddOnsIds.bidAddOnIdsOnUser.length < _userBidAddOnIds.length) {
      _bidGroupOnUserDisabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.BID).enable(),
      );
    }

    if (_selectedAddOnsIds.bidAvailableIds.length < bidStateAvailable.length) {
      _bidGroupAvailableDisabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.BID).enable(),
      );
    }
  }

  disableGrantAddOns(
    _stateControls: AbstractControl[] = [],
    _openAddOns: IOpenSeatAddOns,
    _userGrantAddOnIds: number[] = [],
    _selectedAddOnsIds: ISelectedAddOnIds,
  ): void {
    const { grantStateUnassigned, grantStateAvailable }: IOpenSeatAddOns =
      _openAddOns;
    const _grantGroupEnabled: AbstractControl[] = groupAddOnEnabled(
      _stateControls,
      grantStateAvailable,
      _userGrantAddOnIds,
      ADD_ON_FIELD.GRANT,
    );
    const _grantGroupOnUserEnabled: AbstractControl[] = groupAddOnOnUserEnabled(
      _stateControls,
      grantStateAvailable,
      _userGrantAddOnIds,
      ADD_ON_FIELD.GRANT,
    );
    const _grantGroupAvailableEnabled: AbstractControl[] =
      groupAddOnAvailableEnabled(
        _stateControls,
        grantStateAvailable,
        ADD_ON_FIELD.GRANT,
      );

    if (_selectedAddOnsIds.grantAddOnIds.length >= grantStateUnassigned) {
      _grantGroupEnabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.GRANT).disable(),
      );
    }

    if (
      _selectedAddOnsIds.grantAddOnIdsOnUser.length >= _userGrantAddOnIds.length
    ) {
      _grantGroupOnUserEnabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.GRANT).disable(),
      );
    }

    if (
      _selectedAddOnsIds.grantAvailableIds.length >= grantStateAvailable.length
    ) {
      _grantGroupAvailableEnabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.GRANT).disable(),
      );
    }
  }

  enableGrantAddOns(
    _stateControls: AbstractControl[] = [],
    _openAddOns: IOpenSeatAddOns,
    _userGrantAddOnIds: number[] = [],
    _selectedAddOnsIds: ISelectedAddOnIds,
  ): void {
    const { grantStateUnassigned, grantStateAvailable }: IOpenSeatAddOns =
      _openAddOns;
    const _grantGroupDisabled: AbstractControl[] = groupAddOnDisabled(
      _stateControls,
      grantStateAvailable,
      _userGrantAddOnIds,
      ADD_ON_FIELD.GRANT,
    );
    const _grantGroupOnUserDisabled: AbstractControl[] =
      groupAddOnOnUserDisabled(
        _stateControls,
        grantStateAvailable,
        _userGrantAddOnIds,
        ADD_ON_FIELD.GRANT,
      );
    const _grantGroupAvailableDisabled: AbstractControl[] =
      groupAddOnAvailableDisabled(
        _stateControls,
        grantStateAvailable,
        ADD_ON_FIELD.GRANT,
      );

    if (_selectedAddOnsIds.grantAddOnIds.length < grantStateUnassigned) {
      _grantGroupDisabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.GRANT).enable(),
      );
    }

    if (
      _selectedAddOnsIds.grantAddOnIdsOnUser.length < _userGrantAddOnIds.length
    ) {
      _grantGroupOnUserDisabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.GRANT).enable(),
      );
    }

    if (
      _selectedAddOnsIds.grantAvailableIds.length < grantStateAvailable.length
    ) {
      _grantGroupAvailableDisabled.forEach((group: AbstractControl): void =>
        group.get(ADD_ON_FIELD.GRANT).enable(),
      );
    }
  }
}
