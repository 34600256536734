import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from "@angular/core";

import { fromEvent, merge, of, timer, Observable, Subject } from "rxjs";
import { concatMap, filter, map, takeUntil } from "rxjs/operators";

import { WindowRef } from "@core/refs/window-ref.service";

@Component({
  selector: "bl-custom-scroll-wrapper",
  templateUrl: "./custom-scroll-wrapper.component.html",
  styleUrls: ["./custom-scroll-wrapper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomScrollWrapperComponent
  implements OnInit, AfterContentInit, OnDestroy
{
  @ViewChild("content", { read: ViewContainerRef, static: true })
  contentContainer: ViewContainerRef;
  @ViewChild("container", { static: true }) containerEl: ElementRef;
  @ViewChild("thumb", { static: true }) thumbEl: ElementRef;
  @ViewChild("scroll", { static: true }) scrollEl: ElementRef;

  @Input()
  set template(template: TemplateRef<any>) {
    timer(0).subscribe(() => {
      this.contentContainer.createEmbeddedView(template);
    });
  }

  @Input()
  set itemsLength(itemsLength: number) {
    this.setScrollBar();
  }

  @Input()
  @HostBinding("class.y-scroll")
  yScroll: boolean = false;

  @Input()
  @HostBinding("class.x-scroll")
  xScroll: boolean = false;

  @Input() enabledXWheelScroll: boolean = false;

  private destroyer$: Subject<void> = new Subject();

  containerElWidth: number;
  containerElHeight: number;
  scrollElTop: number;
  scrollHeight: number;
  startPosition: number;
  position: number;
  shouldHandleScrollEvent: boolean = true;
  isScroll: boolean;

  constructor(
    private renderer: Renderer2,
    private window: WindowRef,
  ) {}

  @Output() changeScrollStatus: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.setScrollSize();
  }

  ngAfterContentInit(): void {
    this.setScrollBar();
    this.setupEvents();
    this.setupScrollEvents();
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  isScrollActive(position: number): boolean {
    return position >= 0 && position < this.containerElHeight;
  }

  private setThumblePosition(
    position: number = 0,
    isOnScroll: boolean = false,
  ): void {
    if (!isOnScroll) {
      const { height }: any = this.thumbEl.nativeElement.offsetHeight;
      const containerElHeight: number =
        this.containerEl.nativeElement.offsetHeight;
      const newPosition: number = position - height / 2;

      if (newPosition >= 0 && newPosition <= containerElHeight - height) {
        this.renderer.setStyle(
          this.thumbEl.nativeElement,
          "top",
          `${newPosition}px`,
        );
      } else if (newPosition < 0) {
        this.renderer.setStyle(this.thumbEl.nativeElement, "top", `${0}px`);
      } else {
        this.renderer.setStyle(
          this.thumbEl.nativeElement,
          "top",
          `${containerElHeight - height}px`,
        );
      }
    } else {
      this.renderer.setStyle(
        this.thumbEl.nativeElement,
        "top",
        `${(this.containerEl.nativeElement.scrollTop / this.containerEl.nativeElement.scrollHeight) * 100}%`,
      );
    }
  }

  private setupScrollEvents(): void {
    const onWheel$: Observable<unknown> = fromEvent(
      this.containerEl.nativeElement,
      "wheel",
    );
    const onSwipe$: Observable<unknown> = fromEvent(
      this.containerEl.nativeElement,
      "swipe",
    );
    const onScroll$: Observable<unknown> = fromEvent(
      this.containerEl.nativeElement,
      "scroll",
    );
    const onResize$: Observable<unknown> = fromEvent(
      this.window.nativeElement,
      "resize",
    );

    merge(onScroll$, onWheel$, onSwipe$)
      .pipe(
        takeUntil(this.destroyer$),
        filter(() => this.shouldHandleScrollEvent),
      )
      .subscribe((event: WheelEvent) => {
        this.setThumblePosition(this.position, true);
        if (event instanceof WheelEvent && this.enabledXWheelScroll) {
          this.scrollContentX(event);
        }
      });

    onResize$.pipe(takeUntil(this.destroyer$)).subscribe(() => {
      this.setScrollBar();
    });
  }

  scrollContentX(event: WheelEvent): void {
    if (event.deltaY > 0) {
      this.containerEl.nativeElement.scrollLeft += 25;
    } else {
      this.containerEl.nativeElement.scrollLeft -= 25;
    }
    event.preventDefault();
  }

  private setupEvents(): void {
    const { starts, drags, ends }: any = this.createEvents(
      this.scrollEl.nativeElement,
    );

    starts
      .pipe(
        takeUntil(this.destroyer$),
        map((position: number) => position - this.scrollElTop),
      )
      .subscribe((startPosition: number) => {
        this.startPosition = startPosition;
        this.shouldHandleScrollEvent = false;
      });

    drags
      .pipe(
        takeUntil(this.destroyer$),
        map((position: number) => position - this.scrollElTop),
      )
      .subscribe((position: number) => {
        if (this.isScrollActive(position)) {
          this.setThumblePosition(position);
          this.scrollContent(position);
        }
      });

    ends.subscribe(() => {
      this.shouldHandleScrollEvent = true;
    });
  }

  scrollContent(position: number): void {
    const height: number = this.thumbEl.nativeElement.offsetHeight;
    const thumbPosition: number =
      (position - height / 2) / this.containerElHeight;
    this.containerEl.nativeElement.scrollTop =
      this.scrollHeight * thumbPosition;
  }

  setScrollBar(): void {
    this.scrollElTop = this.scrollEl.nativeElement.getBoundingClientRect().top;
    this.setScrollSize();
    this.setThumbHeight();
  }

  setScrollSize(): void {
    timer(0).subscribe(() => {
      this.containerElHeight = this.containerEl.nativeElement.offsetHeight;
      this.containerElWidth = this.containerEl.nativeElement.offsetWidth;
      this.scrollHeight = this.containerEl.nativeElement.scrollHeight;

      this.setThumbHeight();
      this.setScrollVisibility();
    });
  }

  setThumbHeight(): void {
    this.renderer.setStyle(
      this.thumbEl.nativeElement,
      "height",
      `${(this.containerElHeight / this.scrollHeight) * 100}%`,
    );
  }

  private createEvents(htmlElement: HTMLElement): {
    starts: Observable<number>;
    drags: Observable<number>;
    ends: Observable<number>;
  } {
    // Touch Event
    const touchStarts: Observable<number> = fromEvent(
      htmlElement,
      "touchstart",
    ).pipe(
      map((touchEvent: TouchEvent) => touchEvent.changedTouches[0].clientY),
    );
    const touchMoves: Observable<number> = fromEvent(
      htmlElement,
      "touchmove",
    ).pipe(
      map((touchEvent: TouchEvent) => touchEvent.changedTouches[0].clientY),
    );
    const touchEnds: Observable<number> = fromEvent(
      htmlElement,
      "touchend",
    ).pipe(
      map((touchEvent: TouchEvent) => touchEvent.changedTouches[0].clientY),
    );

    // Mouse events
    const mouseDowns: Observable<number> = fromEvent(
      htmlElement,
      "mousedown",
    ).pipe(
      filter((event: MouseEvent) => event.button !== 2),
      map((event: MouseEvent) => event.clientY),
    );
    const mouseMoves: Observable<number> = fromEvent(
      this.window.nativeElement,
      "mousemove",
    ).pipe(map((event: MouseEvent) => event.clientY));
    const mouseUps: Observable<number> = fromEvent(
      this.window.nativeElement,
      "mouseup",
    ).pipe(map((event: MouseEvent) => event.clientY));

    // Synthetic touch-mouse events
    const starts: Observable<number> = merge(mouseDowns, touchStarts).pipe(
      map((posY: number) => posY),
    );
    const moves: Observable<number> = merge(mouseMoves, touchMoves).pipe(
      map((posY: number) => posY),
    );
    const ends: Observable<number> = merge(touchEnds, mouseUps).pipe(
      map((posY: number) => posY),
    );

    const drags: Observable<number> = starts.pipe(
      concatMap((startPosition: number) =>
        merge(moves, of(startPosition)).pipe(takeUntil(ends)),
      ),
    );

    return { starts, drags, ends };
  }

  setScrollVisibility(): void {
    if (this.scrollHeight <= this.containerElHeight) {
      this.renderer.setStyle(this.scrollEl.nativeElement, "display", "none");
      this.isScroll = false;
    } else {
      this.renderer.setStyle(this.scrollEl.nativeElement, "display", "block");
      this.isScroll = true;
    }
    this.changeScrollStatus.emit(this.isScroll);
  }
}
