import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  dashboardViewsFilterReducer,
  IDashboardViewsFilterState,
} from "./dashboard-views-filter.reducer";
import {
  dashboardViewsSearchReducer,
  IDashboardViewsSearchState,
} from "./dashboard-views-search.reducer";
import {
  dashboardViewsSortingReducer,
  IDashboardViewsSortingState,
} from "./dashboard-views-sorting.reducer";
import {
  dashboardViewsViewTypeReducer,
  IDashboardViewsViewType,
} from "./dashboard-views-view.reducer";
import {
  dashboardViewsReducer,
  IDashboardViewsState,
} from "./dashboard-views.reducer";

export interface IDashboardViewsModuleState {
  dashboardViews: IDashboardViewsState;
  dashboardViewsFilter: IDashboardViewsFilterState;
  dashboardViewsQuery: IDashboardViewsSearchState;
  dashboardViewsSorting: IDashboardViewsSortingState;
  dashboardViewsViewType: IDashboardViewsViewType;
}

const DashboardViewsModuleState: ActionReducerMap<IDashboardViewsModuleState> =
  {
    dashboardViews: dashboardViewsReducer,
    dashboardViewsFilter: dashboardViewsFilterReducer,
    dashboardViewsQuery: dashboardViewsSearchReducer,
    dashboardViewsSorting: dashboardViewsSortingReducer,
    dashboardViewsViewType: dashboardViewsViewTypeReducer,
  };
// eslint-disable-next-line
export const DASHBOARD_VIEWS_STATE_NAME = 'DashboardViewsModule';
export const DASHBOARD_VIEWS_STATE: InjectionToken<
  ActionReducerMap<IDashboardViewsModuleState>
> = new InjectionToken<ActionReducerMap<IDashboardViewsModuleState>>(
  DASHBOARD_VIEWS_STATE_NAME,
  {
    factory: () => DashboardViewsModuleState,
  },
);
