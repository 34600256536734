import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterByKeys",
})
export class FilterObjectByKeysPipe implements PipeTransform {
  transform(
    value: Array<{ [key: string]: any }>,
    filterKeys: string[],
    filterBy: any,
  ): any {
    if (filterBy) {
      const foundValues: any[] = [];
      const normalizeFinedBy: string = filterBy.toLowerCase();

      value.forEach((item: { [key: string]: any }) => {
        let found: boolean = false;
        let count: number = 0;

        while (!found && count < filterKeys.length) {
          const field: any = item[filterKeys[count]];

          if (field) {
            found = field.toLowerCase().includes(normalizeFinedBy);
          }

          if (found) {
            foundValues.push(item);
          }

          count++;
        }
      });

      return foundValues;
    }

    return value;
  }
}
