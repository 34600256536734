import { ElementRef, InjectionToken, Type } from "@angular/core";

import { IOverlayInfoParams } from "../interfaces";

export enum POSITION_X {
  RIGHT,
  LEFT,
  CENTER,
}

export enum POSITION_Y {
  TOP,
  BOTTOM,
}

export const DEFAULT_OVERLAY_INFO_COMPONENT_PARAMS: IOverlayInfoParams<
  any,
  any,
  any
> = {
  positionY: POSITION_Y.BOTTOM,
  positionX: POSITION_X.RIGHT,
};

export const INFO_COMPONENT_DATA: InjectionToken<{ [key: string]: any }> =
  new InjectionToken<{ [key: string]: any }>("INFO_COMPONENT_DATA");
export const INFO_COMPONENT_ACTIONS: InjectionToken<{
  [keys: string]: () => void;
}> = new InjectionToken<{ [keys: string]: () => void }>(
  "INFO_COMPONENT_ACTIONS",
);

export const OVERLAY_INFO_TARGET: InjectionToken<ElementRef<any>> =
  new InjectionToken<ElementRef<any>>("OVERLAY_INFO_TARGET");
export const OVERLAY_INFO_COMPONENT: InjectionToken<Type<any>> =
  new InjectionToken<Type<any>>("OVERLAY_INFO_COMPONENT");
export const OVERLAY_INFO_COMPONENT_PARAMS: InjectionToken<
  IOverlayInfoParams<any, any, any>
> = new InjectionToken<IOverlayInfoParams<any, any, any>>(
  "OVERLAY_INFO_COMPONENT_PARAMS",
);
