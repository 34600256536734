import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "longExpDate",
})
export class LongExpDateMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    const hasYear: boolean = value.includes("-");

    if (hasYear) {
      const [year, month]: any = value.split("-");

      if (+month <= 0) {
        return `01/${year}`;
      }
      return `${month}/${year}`;
    } else {
      return value;
    }
  }

  parse(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value
      .trim()
      .replace(/\D+/g, "")
      .replace(/^(\d{1,6}).*$/, "$1")
      .replace(
        /^(\d{2})(\d{1,4})?$/,
        (substring: string, month: any, year: any) => {
          const _month: string = month <= 0 ? "01" : month;
          const dateParts: string[] = [year, _month];

          if (year && month && month.length === 2) {
            dateParts.push("01");
          }

          return dateParts.filter((match: string) => !!match).join("-");
        },
      );
  }
}
