import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/countries.action";

import { IControlOptions } from "@shared/interfaces/forms";
import { IServerError } from "@shared/interfaces/server-error";
import {
  ICountries,
  ICountry,
  ICountryState,
  ICountryStates,
} from "../../interfaces";

export interface ICountriesAndStatesState {
  countries: ICountries;
  isCountriesFetching: boolean;
  countriesError: IServerError;

  states: ICountryStates;
  isStatesFetching: boolean;
  statesError: IServerError;
}

const initialState: ICountriesAndStatesState = {
  countries: [],
  isCountriesFetching: false,
  countriesError: null,

  states: [],
  isStatesFetching: false,
  statesError: null,
};

const getCountriesHandler: OnReducer<
  ICountriesAndStatesState,
  ActionType<any>
> = (state: ICountriesAndStatesState) => ({
  ...state,
  states: [],
  isCountriesFetching: true,
  countriesError: null,
});

const getCountriesSuccessHandler: OnReducer<
  ICountriesAndStatesState,
  ActionType<Payload<any>>
> = (state: ICountriesAndStatesState, { payload }: Payload<any>) => ({
  ...state,
  isCountriesFetching: false,
  countries: [...payload],
  countriesError: null,
});

const getCountriesErrorHandler: OnReducer<
  ICountriesAndStatesState,
  ActionType<Payload<any>>
> = (state: ICountriesAndStatesState, { payload }: Payload<any>) => ({
  ...state,
  isCountriesFetching: false,
  countriesError: { ...payload },
});

const getStatesByCountryHandler: OnReducer<
  ICountriesAndStatesState,
  ActionType<any>
> = (state: ICountriesAndStatesState) => ({
  ...state,
  isStatesFetching: true,
  statesError: null,
});

const getStatesByCountrySuccessHandler: OnReducer<
  ICountriesAndStatesState,
  ActionType<Payload<any>>
> = (state: ICountriesAndStatesState, { payload }: Payload<any>) => ({
  ...state,
  states: [...payload],
  isStatesFetching: false,
  statesError: null,
});

const getStatesByCountryErrorHandler: OnReducer<
  ICountriesAndStatesState,
  ActionType<Payload<any>>
> = (state: ICountriesAndStatesState, { payload }: Payload<any>) => ({
  ...state,
  isStatesFetching: false,
  statesError: { ...payload },
});

const resetCountriesStateHandler: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICountriesAndStatesState> =
  createReducer<ICountriesAndStatesState>(
    initialState,

    on(actions.getCountriesAction, getCountriesHandler),
    on(actions.getCountriesSuccessAction, getCountriesSuccessHandler),
    on(actions.getCountriesErrorAction, getCountriesErrorHandler),

    on(actions.getStatesByCountryAction, getStatesByCountryHandler),
    on(
      actions.getStatesByCountrySuccessAction,
      getStatesByCountrySuccessHandler,
    ),
    on(actions.getStatesByCountryErrorAction, getStatesByCountryErrorHandler),

    on(actions.resetCountriesStateAction, resetCountriesStateHandler),
  );

export function countriesAndStatesReducer(
  state: ICountriesAndStatesState,
  action: Action,
): ICountriesAndStatesState {
  return reducer(state, action);
}

export const countries: GetFromState<ICountries, ICountriesAndStatesState> = (
  state: ICountriesAndStatesState,
): ICountries => (state && state.countries) || [];
export const countryStates: GetFromState<
  ICountryStates,
  ICountriesAndStatesState
> = (state: ICountriesAndStatesState): ICountryStates =>
  (state && state.states && state.states) || [];
export const isCountryStatesFetching: GetFromState<
  boolean,
  ICountriesAndStatesState
> = (state: ICountriesAndStatesState): boolean =>
  state && state.isStatesFetching;

export const userCountryCode: GetFromState<
  string,
  ICountriesAndStatesState,
  string
> = (
  state: ICountriesAndStatesState,
  countryCode: string | null,
): string | null => (state && !state.states.length && countryCode) || null;

export const countriesControlOptions: GetFromState<
  IControlOptions,
  ICountries
> = (_countries: ICountries): IControlOptions => {
  return _countries.map((country: ICountry) => ({
    label: country.name,
    value: country.code,
  }));
};
export const countryStatesAsControlOptions: GetFromState<
  IControlOptions,
  ICountryStates
> = (_states: ICountryStates): IControlOptions => {
  return _states.map((country: ICountryState) => ({
    label: country.name,
    value: country.code,
  }));
};
