import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";

import {
  IMultiCodesRequest,
  IPersonnelCodesSuccess,
} from "../../../segment/models/codes";

enum ACTION_TYPES {
  GET = "[PERSONNEL CODES] Get",
  GET_ERROR = "[PERSONNEL CODES] Get Error",
  GET_SUCCESS = "[PERSONNEL CODES] Get Success",

  RESET = "[PERSONNEL CODES] Reset State",
}

export const getPersonnelCodesAction: CreateActionType<
  ACTION_TYPES.GET,
  Partial<IMultiCodesRequest>
> = createAction(
  ACTION_TYPES.GET,
  createPayload<Partial<IMultiCodesRequest>>(),
);
export const getPersonnelCodesErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getPersonnelCodesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IPersonnelCodesSuccess
> = createAction(
  ACTION_TYPES.GET_SUCCESS,
  createPayload<IPersonnelCodesSuccess>(),
);

export const resetPersonnelCodesStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
