import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "e2e",
})
export class E2ePipe implements PipeTransform {
  transform(value: any): any {
    if (value === void 0 || value === null) {
      return "";
    }

    return value.toString().toLowerCase().replace(/\s+/g, "_");
  }
}
