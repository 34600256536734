import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "phoneMask",
})
export class PhoneMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value
      .trim()
      .replace(/\D+/g, "")
      .replace(
        /^(\d{3})(\d{3})?(\d{1,4})?$/g,
        (substring: string, first: string, second: string, third: string) => {
          return [first, second, third]
            .filter((match: string) => !!match)
            .join("-");
        },
      );
  }

  parse(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value
      .trim()
      .replace(/\D+/g, "")
      .replace(/^(\d{1,10}).*$/, "$1");
  }
}
