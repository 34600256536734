import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

import { NotFoundPageComponent } from "@core/pages/not-found-page/not-found-page.component";
import { ListLargeDownloadPageComponent } from "@modules/list/pages/list-large-download-page/list-large-download-page.component";

import { TermsOfServiceDataGuard } from "@core/guards/terms-of-service-data.guard";

import { AdminAccountGuard } from "@core/guards/admin-account.guard";
import { BidsPermissionsGuard } from "@core/guards/bids-permissions.guard";
import { BrokersTermsOfUseDataGuard } from "@core/guards/brokers-terms-of-use-data.guard";
import { CheckUserDataExistGuard } from "@core/guards/check-user-data.guard";
import { DashboardShowNotificationGuard } from "@core/guards/dashboard-show-notification.guard";
import { GrantsPermissionsGuard } from "@core/guards/grants-permissions.guard";
import { IsNotBrokerGuard } from "@core/guards/is-not-broker.guard";
import { OrderDataGuard } from "@core/guards/order-data.guard";
import { PrivacyPolicyDataGuard } from "@core/guards/privacy-policy-data.guard";
import { QuickSearchPermissionsGuard } from "@core/guards/quick-search-permissions.guard";
import { TermsOfUseDataGuard } from "@core/guards/terms-of-use-data.guard";
import { TosGuard } from "@core/guards/tos.guard";
import { UnacceptedGeneralTermsByBrokerGuard } from "@core/guards/unaccepted-general-terms-by-broker.guard";
import { AuthGuard } from "@modules/auth/guards/auth.guard";
import { CreditsGuard } from "@modules/credits/guards/credits.guard";

import { CORE_PATHS } from "@core/constants/core-paths";
import { OktaAuthGuard, OktaCallbackComponent } from "@okta/okta-angular";
import { AUTH_PATHS } from "@modules/auth/constants/auth-paths";
import { verifyInvitationGuard } from "@core/guards/verify-invitation.guard";

const authGuardRedirect: string = `/${CORE_PATHS.AUTH}`;
const tosGuardRedirect: string = `/${CORE_PATHS.TOS}`;

const appRoutes: Routes = [
  {
    path: AUTH_PATHS.OKTA_LOGIN,
    component: OktaCallbackComponent,
  },
  {
    path: CORE_PATHS.EMPTY,
    canActivate: [AuthGuard],
    children: [],
  },
  {
    path: CORE_PATHS.AUTH,
    loadChildren: () =>
      import("@modules/auth-pages/auth-pages.module").then(
        (m) => m.AuthPagesModule,
      ),
  },
  {
    path: CORE_PATHS.ACCOUNT,
    loadChildren: () =>
      import("@modules/profile/profile.module").then((m) => m.ProfileModule),
    canLoad: [AuthGuard],
    canActivate: [TosGuard, CreditsGuard, UnacceptedGeneralTermsByBrokerGuard],
  },
  {
    path: CORE_PATHS.DASHBOARD,
    canLoad: [AuthGuard, verifyInvitationGuard],
    canActivate: [
      CheckUserDataExistGuard,
      TosGuard,
      DashboardShowNotificationGuard,
      UnacceptedGeneralTermsByBrokerGuard,
    ],
    loadChildren: () =>
      import("@modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule,
      ),
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.LIST_CREATE,
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    loadChildren: () =>
      import("@modules/list-create/list-create.module").then(
        (m) => m.ListCreateModule,
      ),
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.LIST,
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    loadChildren: () =>
      import("@modules/list/list.module").then((m) => m.ListModule),
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.CUSTOM_LIST,
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    loadChildren: () =>
      import("@modules/custom-list/custom-list.module").then(
        (m) => m.CustomListModule,
      ),
  },
  {
    path: CORE_PATHS.CDL_LIST,
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    loadChildren: () =>
      import("./modules/cdl/cdl-list/cdl-list.module").then(
        (m) => m.CdlListModule,
      ),
  },
  {
    path: CORE_PATHS.VIEW,
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    loadChildren: () =>
      import("@modules/view/view.module").then((m) => m.ViewModule),
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.QUICK_SEARCH,
    canLoad: [AuthGuard, CheckUserDataExistGuard, QuickSearchPermissionsGuard],
    canActivate: [
      TosGuard,
      QuickSearchPermissionsGuard,
      UnacceptedGeneralTermsByBrokerGuard,
    ],
    loadChildren: () =>
      import("@modules/quick-search/quick-search.module").then(
        (m) => m.QuickSearchModule,
      ),
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.SCHOOL,
    loadChildren: () =>
      import("@modules/school/school.module").then((m) => m.SchoolModule),
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.DISTRICT,
    loadChildren: () =>
      import("@modules/district/district.module").then((m) => m.DistrictModule),
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.COLLEGE,
    loadChildren: () =>
      import("@modules/college/college.module").then((m) => m.CollegeModule),
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    data: { authGuardRedirect },
  },
  {
    path: CORE_PATHS.COLLEGE_OFFICE,
    loadChildren: () =>
      import("@modules/college-office/college-office.module").then(
        (m) => m.CollegeOfficeModule,
      ),
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    data: { authGuardRedirect },
  },
  {
    path: CORE_PATHS.CMO,
    loadChildren: () =>
      import("@modules/institution-cmo/cmo.module").then((m) => m.CmoModule),
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.STATE_DEPARTMENT,
    loadChildren: () =>
      import(
        "@modules/institution-state-department/state-department.module"
      ).then((m) => m.StateDepartmentModule),
    canLoad: [AuthGuard, CheckUserDataExistGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    data: { authGuardRedirect, tosGuardRedirect },
  },
  {
    path: CORE_PATHS.GRANT,
    loadChildren: () =>
      import("@modules/grant/grant.module").then((m) => m.GrantModule),
    canLoad: [AuthGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    data: { authGuardRedirect },
  },
  {
    path: CORE_PATHS.BID,
    loadChildren: () =>
      import("@modules/bid/bid.module").then((m) => m.BidModule),
    canLoad: [AuthGuard],
    canActivate: [TosGuard, UnacceptedGeneralTermsByBrokerGuard],
    data: { authGuardRedirect },
  },
  {
    path: CORE_PATHS.ECOMM,
    canLoad: [AuthGuard],
    canActivate: [IsNotBrokerGuard],
    loadChildren: () =>
      import("@modules/e-commerce/e-commerce.module").then(
        (m) => m.ECommerceModule,
      ),
  },
  {
    path: CORE_PATHS.COMMON,
    canActivate: [CheckUserDataExistGuard],
    loadChildren: () =>
      import("@modules/common-pages/common-pages.module").then(
        (m) => m.CommonPagesModule,
      ),
  },
  {
    path: CORE_PATHS.DOWNLOAD_FROM_EMAIL,
    component: ListLargeDownloadPageComponent,
  },
  {
    path: CORE_PATHS.NOT_FOUND,
    component: NotFoundPageComponent,
    canActivate: [CheckUserDataExistGuard],
  },
  {
    path: CORE_PATHS.OTHER,
    component: NotFoundPageComponent,
    canActivate: [CheckUserDataExistGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [
    CheckUserDataExistGuard,
    TermsOfServiceDataGuard,
    BrokersTermsOfUseDataGuard,
    TermsOfUseDataGuard,
    PrivacyPolicyDataGuard,
    OrderDataGuard,
    AdminAccountGuard,
    TosGuard,
    QuickSearchPermissionsGuard,
    GrantsPermissionsGuard,
    BidsPermissionsGuard,
    IsNotBrokerGuard,
    UnacceptedGeneralTermsByBrokerGuard,
  ],
})
export class AppRoutingModule {}
