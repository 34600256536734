import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";

import {
  signOutAction,
  signOutAndGoToAdminPanelAction,
} from "@modules/auth/store/actions/auth.action";
import { getIsLoggedIn } from "@modules/auth/store/selectors/auth.selector";
import {
  getIsAdmin,
  getIsAdminsAccount,
  getIsBroker,
  getIsOwnerOfAdminsAccount,
  getProfileSubscription,
  getProfileUser,
  getUserIsHasAddress,
  isTokenExpired,
} from "@modules/profile/store/selectors/profile.selector";
import { IUser } from "@modules/auth/interfaces/user";
import { openMenuMobileAction } from "../../store/actions/common-pages.action";
import { CoreState } from "../../store/reducers";
import { getCommonError } from "../../store/selectors/common-errors.selector";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";

@Component({
  selector: "bl-header-container",
  templateUrl: "./header-container.component.html",
  styleUrls: ["./header-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderContainerComponent {
  user$: Observable<IUser> = this._store.pipe(select(getProfileUser));
  isTokenExpired$: Observable<boolean> = this._store.pipe(
    select(isTokenExpired),
  );
  globalError$: Observable<string> = this._store.pipe(select(getCommonError));
  userIsHasAddress$: Observable<boolean> = this._store.pipe(
    select(getUserIsHasAddress),
  );
  isBroker$: Observable<boolean> = this._store.pipe(select(getIsBroker));
  isLoggedIn$: Observable<boolean> = this._store.pipe(select(getIsLoggedIn));
  isOwnerOfAdminsAccount$: Observable<boolean> = this._store.pipe(
    select(getIsOwnerOfAdminsAccount),
  );
  isAdminsAccount$: Observable<boolean> = this._store.pipe(
    select(getIsAdminsAccount),
  );
  isAdmin$: Observable<boolean> = this._store.pipe(select(getIsAdmin));
  profileSubscription$: Observable<boolean> = this._store.pipe(
    select(getProfileSubscription),
  );

  constructor(private _store: Store<CoreState>) {}

  logout(): void {
    this._store.dispatch(signOutAction());
  }

  goToAdminPanel(): void {
    this._store.dispatch(signOutAndGoToAdminPanelAction());
  }

  openMenuMobile(): void {
    this._store.dispatch(openMenuMobileAction());
  }
}
