import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";

import { environment } from "@env/environment";

import { defer, of, Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

import { downloadFileByLinkAction } from "@core/store/actions/download.action";
import * as actions from "@core/store/actions/list-large-download.actions";
import { go } from "@core/store/actions/router-history.action";
import { CoreState } from "@core/store/reducers";
import { ActionWithPayload } from "@shared/interfaces/store";

import { ListDownloadService } from "@core/services/list-download.service";
import { ListService } from "@core/services/list.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";
import { ServerErrorException } from "@shared/utils/server-error-exception";

import { IListTokenData } from "@core/interfaces";
import { IServerError } from "@shared/interfaces/server-error";

import { CORE_PATHS } from "@core/constants/core-paths";
import { LIST_TOKEN_ERROR } from "@shared/constants/error-message";

@Injectable()
export class ListLargeDownloadEffect {
  constructor(
    private store: Store<CoreState>,
    private actions$: Actions,
    private listDownloadService: ListDownloadService,
    private listService: ListService,
  ) {}

  checkFileByToken$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.checkDownloadListByTokenAction),
      switchMap(({ payload: { token } }: ActionWithPayload<IListTokenData>) =>
        this.listService.isDownloadAvailableByToken(token).pipe(
          map(({ fileAvailable }: { fileAvailable: boolean }) => {
            return fileAvailable
              ? actions.startDownloadLargeListByTokenAction({ token })
              : actions.downloadLargeListErrorAction(
                  new ServerErrorException(LIST_TOKEN_ERROR, null, 404),
                );
          }),
          catchError((error: IServerError) =>
            of(actions.downloadLargeListErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  startDownload$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.startDownloadLargeListByTokenAction),
      tap(() => this.listDownloadService.largeFilePopUp()),
      switchMap(({ payload: { token } }: ActionWithPayload<IListTokenData>) => [
        downloadFileByLinkAction({
          link: `${environment.api.dashboard.largeDownload}?token=${token}`,
        }),
        go(["/", CORE_PATHS.DASHBOARD]),
      ]),
      catchError((error: IServerError) =>
        of(actions.downloadLargeListErrorAction(error)),
      ),
    ),
  );

  error$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this.actions$.pipe(
          ofType(actions.downloadLargeListErrorAction),
          tap(() => this.listDownloadService.largeFilePopUpError()),
        ),
      ),
    { dispatch: false },
  );
}
