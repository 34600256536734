import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { of, Observable } from "rxjs";
import { catchError, mapTo, take, tap } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { getStatesByCountryAction } from "../store/actions/countries.action";
import { getUserCountryCode } from "../store/selectors/countries.selector";

import { CountriesService } from "../services/countries.service";

@Injectable({
  providedIn: "root",
})
export class StatesByCountriesExistGuard {
  constructor(
    private _store: Store<CoreState>,
    private _service: CountriesService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._store.pipe(
      select(getUserCountryCode),
      take(1),
      tap((countryCode: string) => {
        if (countryCode) {
          this._store.dispatch(getStatesByCountryAction(countryCode));
        }
      }),
      mapTo(true),
      catchError(() => of(false)),
    );
  }
}
