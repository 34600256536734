import { CreditsEffect } from "../../../credits/store/effects/credits.effect";

import { AccountUsersPopUpsEffect } from "./account-pop-ups.effect";
import { AccountUsersEffect } from "./account-users.effect";
import { AccountEffect } from "./account.effect";
import { AddUserAccountEffect } from "./add-user-account.effect";
import { EditUserAccountEffect } from "./edit-user-account.effect";
import { ProfileEffects } from "./profile.effect";

export const profileEffects: any = [
  ProfileEffects,
  CreditsEffect,
  AccountEffect,
  AccountUsersPopUpsEffect,
  AccountUsersEffect,
  AddUserAccountEffect,
  EditUserAccountEffect,
];
