/* eslint-disable @angular-eslint/no-output-native */
import { DOCUMENT } from "@angular/common";
import {
  forwardRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { fromEvent, merge, Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { DocumentRef } from "@core/refs/document-ref.service";

import { matchKey } from "@shared/utils/keyboard";

import { IControlOption } from "@shared/interfaces/forms";

import { BaseSelect } from "@shared/models/base-select";

import { KEYS } from "@shared/constants/keyboard";
import { SECURITY_TYPE } from "@shared/constants/log-rocket-config";

@Component({
  selector: "bl-flat-select",
  templateUrl: "./flat-select.component.html",
  styleUrls: ["./flat-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatSelectComponent),
      multi: true,
    },
  ],
})
export class FlatSelectComponent
  extends BaseSelect
  implements OnInit, AfterViewInit, OnChanges, OnDestroy, ControlValueAccessor
{
  @ViewChild("targetEl") targetEl: ElementRef;
  @ViewChild("list") listEl: ElementRef;
  @ViewChildren("listItem") listItems: QueryList<ElementRef>;

  @Input() options: IControlOption[];
  @Input() theme: string = "default";
  @Input() placeholder: string;
  @Input() formControlName: string;
  @Input() name: string;

  @Input() set dropdownWidth(state: number) {
    this._dropdownWidth = state;
  }

  get dropdownWidth(): number {
    return this._dropdownWidth;
  }

  @Input() optionsOrderName: string = null;

  @Input()
  set errors(errors: { [key: string]: any }) {
    this.setError(errors);
  }

  @Output() select: EventEmitter<string> = new EventEmitter();

  error: string;

  readonly securityType: typeof SECURITY_TYPE = SECURITY_TYPE;

  private _writeValue: any;
  isDisabled: boolean = false;
  isOpened: boolean = false;
  private _dropdownWidth: number;
  private isTargetClicked: boolean = false;
  protected destroyer$: Subject<void> = new Subject();

  constructor(
    @Inject(DOCUMENT) _document: any,
    private zone: NgZone,
    private documentRef: DocumentRef,
    private cdr: ChangeDetectorRef,
  ) {
    super(_document);
  }

  ngOnInit(): void {
    this.listenKeyboard();
  }

  ngAfterViewInit(): void {
    this.dropdownWidth = this.targetEl.nativeElement.clientWidth;

    this.zone.runOutsideAngular(() => {
      const onDocumentTouch: Observable<Event> = fromEvent(
        this.documentRef.nativeElement,
        "touch",
      );
      const onDocumentClick: Observable<Event> = fromEvent(
        this.documentRef.nativeElement,
        "click",
      );

      merge(onDocumentTouch, onDocumentClick)
        .pipe(
          takeUntil(this.destroyer$),
          filter(() => this.isOpened),
        )
        .subscribe(() => {
          if (!this.isTargetClicked) {
            this.zone.run(() => {
              this.closeDropDown();
              this.cdr.markForCheck();
            });
          }
          this.isTargetClicked = false;
        });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.options &&
      !changes.options.firstChange &&
      changes.options.currentValue.length &&
      this._writeValue
    ) {
      this.findAndSelect(this._writeValue);
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  filterKeyDown = () => this.isOpened && !!this.options.length;
  close = ($event: any) => {
    if ($event && matchKey($event, KEYS.TAB)) {
      return this.closeDropDown();
    }

    this.closeDropDown();
  };

  private setError(errors: { [key: string]: any }): void {
    if (errors) {
      this.error = errors.required ? errors.required : Object.values(errors)[0];
    }
  }

  selectOption(event: Event, option: IControlOption): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    this._writeValue = option.value;
    this.selectedOption = option;
    this.propagateChange(this.selectedOption.value);
    this.select.emit(this.selectedOption.value);

    this.closeDropDown();
  }

  writeValue(value: any): void {
    if (value !== void 0) {
      if (value === null) {
        this.selectedOption = null;
        return;
      }
      this.findAndSelect(value);
    }
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.propagateTouch = fn;
  }

  onClose($event: KeyboardEvent): void {
    if (matchKey($event, KEYS.TAB)) {
      this.closeDropDown();
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  findAndSelect(value: any): void {
    this._writeValue = value;
    if (typeof value === "object" && value.label && value.value) {
      this.selectedOption = value;
    } else {
      if (this.options && this.options.length) {
        const option: IControlOption = this.options.find(
          (_option: IControlOption) =>
            _option.value === value || _option.label === value,
        );
        this.selectedOption = option ? option : null;
      } else {
        this.selectedOption = null;
      }
    }
  }

  openDropDown(): void {
    if (this.isDisabled) {
      return;
    }
    this.isOpened = true;
  }

  closeDropDown(): void {
    this.isOpened = false;
  }

  toggleDropdown(): void {
    this.isTargetClicked = true;
    this.isOpened ? this.closeDropDown() : this.openDropDown();
  }

  onFocus(): void {
    this.openDropDown();
  }

  onBlur(): void {
    this.closeDropDown();
  }
}
