import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "cardFourDigitsMask",
})
export class CardFourDigitsMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value
      .replace(/\D+/g, "")
      .replace(/(^\d{1,4}).*$/, "**** **** **** $1");
  }

  parse(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value.replace(/\D+/g, "").replace(/(^\d{1,4}).*$/, "$1");
  }
}
