import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-all-personnel.action";

import { IPersonnelRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { PERSONNEL_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsAllPersonnelState {
  personnel: IPersonnelRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IQuickSearchResultsAllPersonnelState = {
  personnel: [],
  pending: false,
  error: null,

  count: 0,
};

const quickSearchAllPersonnel: OnReducer<
  IQuickSearchResultsAllPersonnelState,
  ActionType<any>
> = (state: IQuickSearchResultsAllPersonnelState) => ({
  ...state,
  pending: true,
  error: null,
});

const getQuickSearchAllPersonnelError: OnReducer<
  IQuickSearchResultsAllPersonnelState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsAllPersonnelState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getQuickSearchAllPersonnelSuccess: OnReducer<
  IQuickSearchResultsAllPersonnelState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsAllPersonnelState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  personnel: [...payload.items],
  count: payload.count,
});

const resetQuickSearchAllPersonnelState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsAllPersonnelState> =
  createReducer<IQuickSearchResultsAllPersonnelState>(
    initialState,

    on(actions.getQuickSearchAllPersonnelAction, quickSearchAllPersonnel),
    on(
      actions.changeQuickSearchAllPersonnelPageAction,
      quickSearchAllPersonnel,
    ),
    on(
      actions.changeQuickSearchAllPersonnelQueryAction,
      quickSearchAllPersonnel,
    ),

    on(
      actions.getQuickSearchAllPersonnelErrorAction,
      getQuickSearchAllPersonnelError,
    ),
    on(
      actions.getQuickSearchAllPersonnelSuccessAction,
      getQuickSearchAllPersonnelSuccess,
    ),

    on(
      actions.resetQuickSearchAllPersonnelStateAction,
      resetQuickSearchAllPersonnelState,
    ),
  );

export function quickSearchResultsAllPersonnelReducer(
  state: IQuickSearchResultsAllPersonnelState,
  action: Action,
): IQuickSearchResultsAllPersonnelState {
  return reducer(state, action);
}

export const allPersonnel: GetFromState<
  IPersonnelRecord[],
  IQuickSearchResultsAllPersonnelState
> = (state: IQuickSearchResultsAllPersonnelState): IPersonnelRecord[] =>
  state.personnel;
export const allPersonnelPending: GetFromState<
  boolean,
  IQuickSearchResultsAllPersonnelState
> = (state: IQuickSearchResultsAllPersonnelState): boolean => state.pending;
export const allPersonnelError: GetFromState<
  IServerError,
  IQuickSearchResultsAllPersonnelState
> = (state: IQuickSearchResultsAllPersonnelState): IServerError => state.error;
export const allPersonnelCount: GetFromState<
  number,
  IQuickSearchResultsAllPersonnelState
> = (state: IQuickSearchResultsAllPersonnelState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  const { sortBy }: Params = queryParams;

  return {
    ...PERSONNEL_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    sortBy: sortBy || "lastName",
    filterBy: "all",
  };
};
