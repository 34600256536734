import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/pids-file.action";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IMultiPIDResponseErrors,
  IUploadPidFileEvent,
} from "../../interfaces/pids";

import { MULTI_PID_ERRORS } from "../../constants/pids";

export interface IPidsFileUploadSTate {
  uploads: IUploadPidFileEvent[];
  pending: boolean;
  error: Array<{ segmentCriteriaId: number; error: IServerError }>;
}

const initialState: IPidsFileUploadSTate = {
  uploads: [],
  pending: false,
  error: [],
};

const pidFileUploadStart: OnReducer<
  IPidsFileUploadSTate,
  ActionType<Payload<any>>
> = (state: IPidsFileUploadSTate, { payload }: Payload<any>) => {
  const clearUploads: IUploadPidFileEvent[] = state.uploads.filter(
    (_upload: IUploadPidFileEvent) => _upload.uploadedPercent !== 100,
  );

  return {
    ...state,
    pending: true,
    error: state.error.filter(
      (err: { segmentCriteriaId: number; error: IServerError }) =>
        err.segmentCriteriaId !== payload.segmentCriteriaId,
    ),
    uploads: [...clearUploads, payload],
  };
};

const pidFileUploadError: OnReducer<
  IPidsFileUploadSTate,
  ActionType<Payload<{ segmentCriteriaId: number; error: IServerError }>>
> = (
  state: IPidsFileUploadSTate,
  { payload }: Payload<{ segmentCriteriaId: number; error: IServerError }>,
) => {
  const currentError: { segmentCriteriaId: number; error: IServerError } =
    state.error.find(
      (err: { segmentCriteriaId: number; error: IServerError }) =>
        err.segmentCriteriaId === payload.segmentCriteriaId,
    );

  if (currentError) {
    return {
      ...state,
      error: state.error.map(
        (err: { segmentCriteriaId: number; error: IServerError }) =>
          err.segmentCriteriaId === currentError.segmentCriteriaId
            ? currentError
            : err,
      ),
    };
  } else {
    return {
      ...state,
      error: [...state.error, { ...payload }],
    };
  }
};

const pidFileUploadSuccess: OnReducer<IPidsFileUploadSTate, ActionType<any>> = (
  state: IPidsFileUploadSTate,
) => ({
  ...state,
  pending: false,
});

const pidsFileUploadProgress: OnReducer<
  IPidsFileUploadSTate,
  ActionType<Payload<any>>
> = (state: IPidsFileUploadSTate, { payload }: Payload<any>) => ({
  ...state,
  uploads: state.uploads.length
    ? state.uploads.map((_upload: IUploadPidFileEvent) =>
        _upload.segmentCriteriaId === payload.segmentCriteriaId
          ? { ...payload }
          : _upload,
      )
    : [payload],
  pending: false,
});

const pidFileUploadResetError: OnReducer<
  IPidsFileUploadSTate,
  ActionType<any>
> = (state: IPidsFileUploadSTate) => ({
  ...state,
  uploads: state.uploads.map((upload: IUploadPidFileEvent) => ({
    ...upload,
    errors: null,
  })),
  errors: [],
  error: [],
});

const pidFileSaveProgressSuccess: OnReducer<
  IPidsFileUploadSTate,
  ActionType<Payload<any>>
> = (state: IPidsFileUploadSTate, { payload }: Payload<any>) => ({
  ...state,
  // uploads: state.uploads
  //   .filter(_upload => _upload.segmentCriteriaId !== payload.segmentCriteriaId
  //     && _upload.fileName !== payload.fileName)
});

const pidFileResetState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IPidsFileUploadSTate> =
  createReducer<IPidsFileUploadSTate>(
    initialState,

    on(actions.pidFileUploadStartAction, pidFileUploadStart),
    on(actions.pidFileUploadErrorAction, pidFileUploadError),
    on(actions.pidFileUploadSuccessAction, pidFileUploadSuccess),

    on(actions.pidFileSaveProgressAction, pidsFileUploadProgress),
    on(actions.pidFileSaveProgressSuccessAction, pidFileSaveProgressSuccess),

    on(actions.pidFileUploadResetErrorAction, pidFileUploadResetError),

    on(actions.pidFileResetStateAction, pidFileResetState),
  );

export function pidsFileUploadReducer(
  state: IPidsFileUploadSTate,
  action: Action,
): IPidsFileUploadSTate {
  return reducer(state, action);
}

export const uploads: GetFromState<
  IUploadPidFileEvent[],
  IPidsFileUploadSTate
> = (state: IPidsFileUploadSTate): IUploadPidFileEvent[] =>
  state && state.uploads;
export const fileError: GetFromState<
  IServerError,
  IPidsFileUploadSTate,
  { segmentCriteriaId: number }
> = (
  state: IPidsFileUploadSTate,
  { segmentCriteriaId }: { segmentCriteriaId: number },
): IServerError => {
  const currentError: { segmentCriteriaId: number; error: IServerError } =
    state &&
    state.error &&
    state.error.find(
      (_er: { segmentCriteriaId: number; error: IServerError }) =>
        _er.segmentCriteriaId === segmentCriteriaId,
    );

  if (currentError) {
    return currentError.error;
  }

  return null;
};

export const uploadsByListId: GetFromState<
  IUploadPidFileEvent[],
  IUploadPidFileEvent[],
  { listId: number }
> = (
  _uploads: IUploadPidFileEvent[],
  { listId }: { listId: number },
): IUploadPidFileEvent[] =>
  _uploads &&
  _uploads.filter(
    (_upload: IUploadPidFileEvent) => _upload.listingId === listId,
  );

export const uploadsBySegmentCriteriaId: GetFromState<
  IUploadPidFileEvent[],
  IUploadPidFileEvent[],
  { segmentCriteriaId: number }
> = (
  _uploads: IUploadPidFileEvent[],
  { segmentCriteriaId }: { segmentCriteriaId: number },
): IUploadPidFileEvent[] =>
  _uploads &&
  _uploads.filter(
    (_upload: IUploadPidFileEvent) =>
      _upload.segmentCriteriaId === segmentCriteriaId,
  );

export const uploadsByListIdPercent: GetFromState<
  number,
  IUploadPidFileEvent[],
  { listId: number }
> = (
  _uploads: IUploadPidFileEvent[],
  { listId }: { listId: number },
): number | null => {
  const currentUploadsPercents: number[] =
    _uploads &&
    _uploads
      .filter((_upload: IUploadPidFileEvent) => _upload.listingId === listId)
      .filter((_upload: IUploadPidFileEvent) => _upload.uploadedPercent !== 100)
      .map((_upload: IUploadPidFileEvent) => _upload.uploadedPercent);

  if (currentUploadsPercents && !currentUploadsPercents.length) {
    return null;
  }

  return +(
    (currentUploadsPercents.reduce((res: number, a: number) => res + a, 0) /
      (currentUploadsPercents.length * 100)) *
    100
  ).toFixed();
};

export const uploadsBySegmentCriteriaIdPercent: GetFromState<
  number,
  IUploadPidFileEvent[],
  { segmentCriteriaId: number }
> = (
  _uploads: IUploadPidFileEvent[],
  { segmentCriteriaId }: { segmentCriteriaId: number },
): number => {
  const currentUploadsPercents: number[] =
    _uploads &&
    _uploads
      .filter(
        (_upload: IUploadPidFileEvent) =>
          _upload.segmentCriteriaId === segmentCriteriaId,
      )
      .filter((_upload: IUploadPidFileEvent) => _upload.uploadedPercent !== 100)
      .map((_upload: IUploadPidFileEvent) => _upload.uploadedPercent);

  return +(
    (currentUploadsPercents.reduce((res: number, a: any) => res + a, 0) /
      (currentUploadsPercents.length * 100)) *
    100
  ).toFixed();
};

export const uploadsBySegmentCriteriaIdErrors: GetFromState<
  string[],
  IUploadPidFileEvent[],
  { segmentCriteriaId: number }
> = (
  _uploads: IUploadPidFileEvent[],
  { segmentCriteriaId }: { segmentCriteriaId: number },
): string[] => {
  const invalidPid: IMultiPIDResponseErrors =
    _uploads &&
    _uploads
      .filter(
        (_upload: IUploadPidFileEvent) =>
          _upload.segmentCriteriaId === segmentCriteriaId,
      )
      .map((_upload: IUploadPidFileEvent) => _upload.errors)
      .reduce(
        (
          res: IMultiPIDResponseErrors,
          item: IMultiPIDResponseErrors,
        ): IMultiPIDResponseErrors => {
          const _res: IMultiPIDResponseErrors = {
            ...res,
          };

          if (item) {
            Object.entries(item).forEach(([key, value]: [string, number[]]) => {
              _res[key] = _res[key] ? [..._res[key], ...value] : [...value];
            });
          }
          return _res;
        },
        {} as IMultiPIDResponseErrors,
      );

  return Object.entries(invalidPid).reduce((res: any, [key, pids]: any) => {
    return [...res, MULTI_PID_ERRORS[key](pids)];
  }, []);
};
