import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "shortNumber",
})
export class ShortNumberPipe implements PipeTransform {
  transform(
    value: number,
    fractionSize?: number,
    isAddWrapTag?: boolean,
  ): string {
    if (typeof value !== "number") {
      return value;
    }

    if (!fractionSize || fractionSize < 0) {
      fractionSize = 1;
    }

    let abs: number = Math.abs(value);
    let key: string = "";
    const rounder: number = Math.pow(10, fractionSize);
    const isNegative: boolean = value < 0;
    const powers: { key: string; value: number }[] = [
      {
        key: "Q",
        value: Math.pow(10, 15),
      },
      {
        key: "T",
        value: Math.pow(10, 12),
      },
      {
        key: "B",
        value: Math.pow(10, 9),
      },
      {
        key: "MM",
        value: Math.pow(10, 6),
      },
      {
        key: "k",
        value: 1000,
      },
    ];

    for (let i: number = 0; i < powers.length; i++) {
      let reduced: number = abs / powers[i].value;

      reduced = Math.round(reduced * rounder) / rounder;

      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }

    return (
      (isNegative ? "-" : "") +
      abs +
      (!isAddWrapTag ? key : `<span class="postfix">${key}</span>`)
    );
  }
}
