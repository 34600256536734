import { ITableHeaders } from "@ui/tables/components/table/table.component";

import { IDashboardProductQueryParams } from "../../dashboard/interfaces";

import {
  CONTENT_POSITION,
  MobileDropDownParams,
} from "@ui/drop-down/mobile/mobile-drop-down/models";

import { LIST_STATUS_ID } from "@shared/constants/data/list-statuses";
import { MANAGE_ACCOUNT_LISTS } from "@shared/constants/data/manage-account-lists";
import { CDL_LIST_PATHS } from "../../cdl/cdl-list/constants/cdl-list-paths";
import {
  DASHBOARD_SORT_BY,
  DASHBOARD_SORT_DIRECTION,
  DEFAULT_FILTER_QUERY_PARAMS,
} from "../../dashboard/constants/dashboard";

export const LISTS_DEFAULT_QUERY_PARAMS: IDashboardProductQueryParams = {
  sortDirection: DASHBOARD_SORT_DIRECTION.DESC,
  sortBy: DASHBOARD_SORT_BY.CREATE_AT,
  entityType: DEFAULT_FILTER_QUERY_PARAMS.entityType,
};

export const MOBILE_DROPDOWN_NEXT_SLIDE_OPEN_PARAMS: MobileDropDownParams = {
  animationPosition: CONTENT_POSITION.RIGHT,
};

export const MOBILE_DROPDOWN_PREV_SLIDE_OPEN_PARAMS: MobileDropDownParams = {
  animationPosition: CONTENT_POSITION.LEFT,
};

export const DASHBOARD_LIST_DEFAULT_PARAMS: MobileDropDownParams = {
  scrollOnToggle: true,
};

export const CAN_NOT_PERMISSIONS_FOR_EDIT_LIST: string =
  "Lists created by other users cannot be changed.";
export const CAN_NOT_PERMISSIONS_FOR_EDIT_SFL_LIST: string = `You cannot edit another user’s Saved for Later list.
 To edit or activate, please duplicate this list.`;

export const URL_ORDER_SUMMARY_PAGE: { [key: string]: CDL_LIST_PATHS } =
  Object.freeze({
    [LIST_STATUS_ID.SAVED_FOR_LATER]: CDL_LIST_PATHS.PURCHASE,
    [LIST_STATUS_ID.ACTIVE]: CDL_LIST_PATHS.SUMMARY,
    [LIST_STATUS_ID.EXPIRED]: CDL_LIST_PATHS.SUMMARY,
  });

export const DASHBOARD_LIST_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "List name",
    sortable: true,
  },
  {
    key: "firstName",
    title: "Created by",
    sortable: false,
  },
  {
    key: "entityType",
    title: "List type",
  },
  {
    key: "count",
    title: "Records",
    sortable: true,
  },
  {
    key: "status",
    title: "Status",
  },
  {
    key: "created_at",
    title: "Creation date",
    sortable: true,
  },
  {
    key: "updated_at",
    title: "Last modified",
    sortable: true,
  },
  {
    key: "",
    title: "",
  },
];

export const CREATE_CUSTOM_LIST_SUCCESS_MESSAGE: string =
  "Your Custom List has been created!";
export const IS_SHOWED_NOT_ALL_PRODUCTS: MANAGE_ACCOUNT_LISTS[] = [
  MANAGE_ACCOUNT_LISTS.ONLY_ME_PRODUCTS,
  MANAGE_ACCOUNT_LISTS.MY_AND_ACTIVE_PRODUCTS,
];
