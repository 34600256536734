import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-personnel-colleges.action";

import { IPersonnelRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { PERSONNEL_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsPersonnelCollegeState {
  colleges: IPersonnelRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IQuickSearchResultsPersonnelCollegeState = {
  colleges: [],
  pending: false,
  error: null,

  count: 0,
};

const quickSearchPersonnelColleges: OnReducer<
  IQuickSearchResultsPersonnelCollegeState,
  ActionType<any>
> = (state: IQuickSearchResultsPersonnelCollegeState) => ({
  ...state,
  pending: true,
  error: null,
});

const quickSearchPersonnelCollegesError: OnReducer<
  IQuickSearchResultsPersonnelCollegeState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsPersonnelCollegeState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const quickSearchPersonnelCollegesSuccess: OnReducer<
  IQuickSearchResultsPersonnelCollegeState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsPersonnelCollegeState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  colleges: [...payload.items],
  count: payload.count,
});

const setQuickSearchPersonnelCollegesSpinnerState: OnReducer<
  IQuickSearchResultsPersonnelCollegeState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsPersonnelCollegeState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetQuickSearchPersonnelCollegesState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsPersonnelCollegeState> =
  createReducer<IQuickSearchResultsPersonnelCollegeState>(
    initialState,

    on(
      actions.getQuickSearchPersonnelCollegesAction,
      quickSearchPersonnelColleges,
    ),
    on(
      actions.getQuickSearchPersonnelCollegesErrorAction,
      quickSearchPersonnelCollegesError,
    ),
    on(
      actions.getQuickSearchPersonnelCollegesSuccessAction,
      quickSearchPersonnelCollegesSuccess,
    ),

    on(
      actions.changeQuickSearchPersonnelCollegesPageAction,
      quickSearchPersonnelColleges,
    ),
    on(
      actions.changeQuickSearchPersonnelCollegesQueryAction,
      quickSearchPersonnelColleges,
    ),

    on(
      actions.setQuickSearchPersonnelCollegesSpinnerStateAction,
      setQuickSearchPersonnelCollegesSpinnerState,
    ),

    on(
      actions.resetQuickSearchPersonnelCollegesStateAction,
      resetQuickSearchPersonnelCollegesState,
    ),
  );

export function quickSearchResultsPersonnelCollegesReducer(
  state: IQuickSearchResultsPersonnelCollegeState,
  action: Action,
): IQuickSearchResultsPersonnelCollegeState {
  return reducer(state, action);
}

export const colleges: GetFromState<
  IPersonnelRecord[],
  IQuickSearchResultsPersonnelCollegeState
> = (state: IQuickSearchResultsPersonnelCollegeState): IPersonnelRecord[] =>
  state.colleges;
export const pending: GetFromState<
  boolean,
  IQuickSearchResultsPersonnelCollegeState
> = (state: IQuickSearchResultsPersonnelCollegeState): boolean => state.pending;
export const collegesError: GetFromState<
  IServerError,
  IQuickSearchResultsPersonnelCollegeState
> = (state: IQuickSearchResultsPersonnelCollegeState): IServerError =>
  state.error;

export const collegesPaginationCount: GetFromState<
  number,
  IQuickSearchResultsPersonnelCollegeState
> = (state: IQuickSearchResultsPersonnelCollegeState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  const { sortBy }: Params = queryParams;

  return {
    ...PERSONNEL_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    sortBy: sortBy || "lastName",
    filterBy: "colleges",
  };
};
