import { AbstractControl, ValidatorFn } from "@angular/forms";

import { FORM_VALIDATION_ERRORS } from "@shared/constants/validators/forms-validations-errors";

export default function confirm(
  msg: string,
  controlNames: string[],
): ValidatorFn {
  return (control: AbstractControl) => {
    let message: string = msg || "Fields do not match";
    const controls: AbstractControl[] = controlNames.map((name: string) =>
      control.root.get(name),
    );

    const isEmailDependent: boolean = controlNames.includes("email");
    const isOldPasswordDependent: boolean =
      controlNames.includes("oldPassword");
    const isTemporaryPasswordDependent: boolean =
      controlNames.includes("temporaryPassword");

    const isEqual: boolean = controls.reduce(
      (result: boolean, secondControl: AbstractControl) => {
        if (!secondControl) {
          return result;
        }
        if (isEmailDependent) {
          if (secondControl.value) {
            let primaryControlValue: string = "";
            if (control.value) {
              primaryControlValue = control.value.toUpperCase();
            }
            const userId: string = secondControl.value
              .split("@")[0]
              .toUpperCase();
            return !primaryControlValue.includes(userId);
          } else {
            message = FORM_VALIDATION_ERRORS.password.dependencyRequired;
          }
        } else if (isOldPasswordDependent) {
          if (secondControl.value) {
            return control.value !== secondControl.value ? result : false;
          } else {
            message = FORM_VALIDATION_ERRORS.currentPassword.required;
          }
        } else if (isTemporaryPasswordDependent) {
          if (secondControl.value) {
            return control.value !== secondControl.value ? result : false;
          } else {
            message = FORM_VALIDATION_ERRORS.temporaryPassword.required;
          }
        } else {
          return control.value === secondControl.value ? result : false;
        }
      },
      true,
    );

    return isEqual ? null : { confirm: message };
  };
}
