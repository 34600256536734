import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cdl-list-history-sorting.action";

import {
  CDL_LIST_HISTORY_SORT_BY,
  CDL_LIST_HISTORY_SORT_DIRECTION,
} from "../../constants";

export interface ICDLListHistorySortingState {
  sortDirection: string;
  sortBy: string;
}

const initialState: ICDLListHistorySortingState = {
  sortDirection: CDL_LIST_HISTORY_SORT_DIRECTION.DESC,
  sortBy: CDL_LIST_HISTORY_SORT_BY.DATE,
};

const changeCDLListHistorySortDirection: OnReducer<
  ICDLListHistorySortingState,
  ActionType<Payload<any>>
> = (state: ICDLListHistorySortingState, { payload }: Payload<any>) => ({
  ...state,
  sortDirection: payload,
});

const changeCDLListHistorySortBy: OnReducer<
  ICDLListHistorySortingState,
  ActionType<Payload<any>>
> = (state: ICDLListHistorySortingState, { payload }: Payload<any>) => ({
  ...state,
  sortBy: payload,
});

const resetCDLListHistorySorting: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLListHistorySortingState> =
  createReducer<ICDLListHistorySortingState>(
    initialState,

    on(
      actions.changeCDLListHistorySortDirectionAction,
      changeCDLListHistorySortDirection,
    ),
    on(actions.changeCDLListHistorySortByAction, changeCDLListHistorySortBy),

    on(actions.resetCDLListHistorySortingAction, resetCDLListHistorySorting),
  );

export function cdlListHistorySortingReducer(
  state: ICDLListHistorySortingState,
  action: Action,
): ICDLListHistorySortingState {
  return reducer(state, action);
}

export const direction: GetFromState<string, ICDLListHistorySortingState> = (
  state: ICDLListHistorySortingState,
): string => state.sortDirection;
export const by: GetFromState<string, ICDLListHistorySortingState> = (
  state: ICDLListHistorySortingState,
): string => state.sortBy;
