import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import {
  IAddress,
  ICreditCardInfo,
  IDefaultBillingInfo,
  INewCreditCartInfo,
} from "../interfaces/e-commerce";

import { DEFAULT_COUNTRY_CODE, DEFAULT_CURRENCY } from "../constants/payment";

import { Validators } from "@shared/validators/validators";

@Injectable({
  providedIn: "root",
})
export class PaymentFormService {
  constructor(private fb: UntypedFormBuilder) {}

  getNewPaymentForm(
    newPaymentMethod: INewCreditCartInfo,
    defaultBillingInfo: IDefaultBillingInfo,
  ): UntypedFormGroup {
    return this.fb.group({
      currency: [DEFAULT_CURRENCY, Validators.required()],

      card_type: [
        (newPaymentMethod && newPaymentMethod.card_type) || "",
        Validators.required(),
      ],
      card_number: [
        (newPaymentMethod && newPaymentMethod.card_number) || null,
        [
          Validators.required(),
          Validators.minLength(null, 14),
          Validators.maxLength(null, 16),
          Validators.cardType("Invalid credit card number."),
        ],
      ],
      card_expiry_date: [
        (newPaymentMethod && newPaymentMethod.card_expiry_date) || "",
        [Validators.required(), Validators.cardExpiredDate()],
      ],
      card_cvn: [
        (newPaymentMethod && newPaymentMethod.card_cvn) || null,
        Validators.required(),
      ],

      bill_to_forename: [
        (newPaymentMethod && newPaymentMethod.bill_to_forename) || "",
        Validators.required(),
      ],
      bill_to_surname: [
        (newPaymentMethod && newPaymentMethod.bill_to_surname) || "",
        Validators.required(),
      ],

      bill_to_email: [defaultBillingInfo.email || "", Validators.required()],
      bill_to_address_line1: [
        (newPaymentMethod && newPaymentMethod.bill_to_address_line1) || "",
        Validators.required(),
      ],
      bill_to_address_line2: [
        (newPaymentMethod && newPaymentMethod.bill_to_address_line2) || "",
      ],
      bill_to_address_state: [
        (newPaymentMethod && newPaymentMethod.bill_to_address_state) || "",
        Validators.required(),
      ],
      bill_to_address_country: [
        (newPaymentMethod && newPaymentMethod.bill_to_address_country) || "",
        Validators.required(),
      ],

      bill_to_address_zip: [
        newPaymentMethod && newPaymentMethod.bill_to_address_zip,
        Validators.required(),
      ],
      bill_to_address_city: [
        newPaymentMethod && newPaymentMethod.bill_to_address_city,
        Validators.required(),
      ],

      bill_to_address_phone: [
        newPaymentMethod && newPaymentMethod.bill_to_address_phone,
      ],

      saveCard: [true],

      usAccountAddress: [false],
    } as { [p: string]: any }); // TODO remove as...
  }

  getDefaultPaymentForm(paymentMethod: ICreditCardInfo): UntypedFormGroup {
    return this.fb.group({
      payment_token_identifier: [
        (paymentMethod && paymentMethod.payment_token_identifier) || "",
        Validators.required(),
      ],
      card_expiration_date: [
        (paymentMethod && paymentMethod.card_expiration_date) || "",
        Validators.required(),
      ],
      card_type_code: [
        (paymentMethod && paymentMethod.card_type_code) || "",
        Validators.required(),
      ],
      card_type_sub_code: [
        (paymentMethod && paymentMethod.card_type_sub_code) || "",
        Validators.required(),
      ],
      last_four_digits: [
        (paymentMethod && paymentMethod.last_four_digits) || "",
        Validators.required(),
      ],

      bill_to_forename: [
        (paymentMethod && paymentMethod.first_name_on_card) || "",
        Validators.required(),
      ],
      bill_to_surname: [
        (paymentMethod && paymentMethod.last_name_on_card) || "",
        Validators.required(),
      ],

      bill_to_address_line1: [
        (paymentMethod &&
          paymentMethod.address &&
          paymentMethod.address.address_line_1) ||
          "",
        Validators.required(),
      ],
      bill_to_address_line2: [
        (paymentMethod &&
          paymentMethod.address &&
          paymentMethod.address.address_line_2) ||
          "",
      ],
      bill_to_address_city: [
        (paymentMethod &&
          paymentMethod.address &&
          paymentMethod.address.city_name) ||
          "",
        Validators.required(),
      ],
      bill_to_address_state: [
        (paymentMethod &&
          paymentMethod.address &&
          paymentMethod.address.state_code) ||
          "",
        Validators.required(),
      ],
      bill_to_address_country: [
        (paymentMethod &&
          paymentMethod.address &&
          paymentMethod.address.country_code) ||
          DEFAULT_COUNTRY_CODE,
        Validators.required(),
      ],

      bill_to_address_zip: [
        (paymentMethod &&
          paymentMethod.address &&
          paymentMethod.address.zip_code) ||
          "",
        Validators.required(),
      ],
      bill_to_address_phone: [
        (paymentMethod &&
          paymentMethod.address &&
          paymentMethod.address.phone_number) ||
          null,
      ],
    });
  }

  updateNewCreditCardAddress(
    formValue: { [key: string]: any },
    defaultBillingInfo: IDefaultBillingInfo,
  ): { [p: string]: any } {
    const { email, first_name, last_name }: IDefaultBillingInfo =
      defaultBillingInfo;
    const {
      address_line_1,
      address_line_2,
      country_code,
      state_code,
      zip_code,
      city_name,
      phone_number,
    }: IAddress = defaultBillingInfo && defaultBillingInfo.personal_address;

    return {
      ...formValue,

      bill_to_forename: first_name || "",
      bill_to_surname: last_name || "",

      bill_to_email: email || "",
      bill_to_address_line1: address_line_1 || "",
      bill_to_address_line2: address_line_2 || "",
      bill_to_address_country: country_code || "",
      bill_to_address_state: state_code || "",

      bill_to_address_zip: (zip_code && `${zip_code}`.substr(0, 5)) || "",
      bill_to_address_city: city_name || "",

      bill_to_address_phone: phone_number || null,
    };
  }

  resetNewCreditCardAddress(
    formValue: { [key: string]: any },
    newCreditCard: INewCreditCartInfo,
    defaultEmail: string,
  ): { [p: string]: any } {
    return {
      ...formValue,
      bill_to_forename: (newCreditCard && newCreditCard.bill_to_forename) || "",
      bill_to_surname: (newCreditCard && newCreditCard.bill_to_surname) || "",
      bill_to_address_line1:
        (newCreditCard && newCreditCard.bill_to_address_line1) || "",
      bill_to_address_line2:
        (newCreditCard && newCreditCard.bill_to_address_line2) || "",
      bill_to_address_state:
        (newCreditCard && newCreditCard.bill_to_address_state) || "",
      bill_to_address_zip:
        (newCreditCard && newCreditCard.bill_to_address_zip) || "",
      bill_to_address_city:
        (newCreditCard && newCreditCard.bill_to_address_city) || "",
      bill_to_address_phone:
        (newCreditCard && newCreditCard.bill_to_address_phone) || "",
      bill_to_email:
        (newCreditCard && newCreditCard.bill_to_email) || defaultEmail || "",
    };
  }
}
