import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { IInfoPopUpData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-info-popup",
  templateUrl: "./info-popup.component.html",
  styleUrls: ["./info-popup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPopupComponent {
  constructor(
    private _popUpRef: PopUpRef<InfoPopupComponent>,
    @Inject(POP_UP_DATA) public data: IInfoPopUpData,
  ) {}

  close(): void {
    this._popUpRef.close();
  }
}
