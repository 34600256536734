import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { DASHBOARD_VIEW_TYPE } from "../../../dashboard/interfaces";

enum ACTION_TYPES {
  CHANGE_VIEW_TYPE = "[DASHBOARD VIEWS VIEW] change view type",
}

export const changeDashboardViewsViewTypeAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_TYPE,
  DASHBOARD_VIEW_TYPE
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_TYPE,
  createPayload<DASHBOARD_VIEW_TYPE>(),
);
