import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { map, switchMap, takeWhile } from "rxjs/operators";

import * as dashboardListActions from "@modules/dashboard-lists/store/actions/dashboard-lists.action";
import * as dashboardViewActions from "@modules/dashboard-views/store/actions/dashboard-views.action";
import { listenRefreshCustomObjectAction } from "@modules/list/store/actions/list-cloud-sync.actions";
import {
  hideNotificationAction,
  showNotificationAction,
} from "@modules/notifications/store/actions/notification.action";
import { listenDuplicateSegmentSocketAction } from "@modules/segment/store/actions/segment.action";
import * as cloudSyncAuthActions from "../actions/cloud-sync-auth.action";
import { CoreState } from "../reducers";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import {
  IDuplicateSegmentListenerSocket,
  IRefreshCustomObjectIsReadySocket,
} from "../../interfaces";

import { REFRESH_CUSTOM_OBJECT_READY } from "@modules/list/constants/cloud-sync";
import { DUPLICATE_SEGMENTS_READY } from "@modules/segment/constants";
import { NOTIFICATION_TYPES } from "../../constants/notifications";
import { SOCKET_EVENTS } from "../../constants/socket-events";

import { WebSocketsProvider } from "@modules/websockets";

@Injectable()
export class ListenersEffect {
  constructor(
    private _actions$: Actions,
    private _store: Store<CoreState>,
    private _ws: WebSocketsProvider,
  ) {}

  duplicateSegmentIsReady$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(listenDuplicateSegmentSocketAction),
      switchMap(() => this._ws.on(SOCKET_EVENTS.DUPLICATE_SEGMENTS_READY)),
      takeWhile((socket: IDuplicateSegmentListenerSocket) => !!socket),
      map(({ listingId }: IDuplicateSegmentListenerSocket) => {
        this._store.dispatch(dashboardListActions.reloadDashboardListsAction());
        this._store.dispatch(dashboardViewActions.reloadDashboardViewsAction());

        const notificationId: string = `duplicate-segments-for-${listingId}-is-ready`;

        return showNotificationAction({
          id: notificationId,
          message: DUPLICATE_SEGMENTS_READY,
          timeout: 5000,
          type: NOTIFICATION_TYPES.SUCCESS,
          canClose: true,
        });
      }),
      catchErrorWithErrorType,
    ),
  );

  refreshCustomObjectIsReady$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(listenRefreshCustomObjectAction),
      switchMap(() => this._ws.on(SOCKET_EVENTS.REFRESH_CUSTOM_OBJECT_READY)),
      takeWhile((socket: IRefreshCustomObjectIsReadySocket) => !!socket),
      map(
        ({
          connectionId,
          sandbox,
          platformId,
          listId,
        }: IRefreshCustomObjectIsReadySocket) => {
          const notificationId: string = `refresh-custom-object-for-${listId}-is-ready`;
          return showNotificationAction({
            id: notificationId,
            message: REFRESH_CUSTOM_OBJECT_READY,
            belowButton: {
              fn: () => {
                this._store.dispatch(
                  cloudSyncAuthActions.showAuthOrSyncToPopUpAction({
                    platform: { id: platformId, connectionId },
                    listId,
                    sandbox,
                    redirectToSearch: true,
                  }),
                );
                this._store.dispatch(hideNotificationAction(notificationId));
              },
              name: "view",
            },
            type: NOTIFICATION_TYPES.SUCCESS,
            canClose: true,
          });
        },
      ),
      catchErrorWithErrorType,
    ),
  );
}
