import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "uppercaseMask",
})
export class UppercaseMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value.toUpperCase();
  }

  parse(value: string): string {
    return this.transform(value);
  }
}
