import { Injectable } from "@angular/core";

import { WindowRef } from "../refs/window-ref.service";

@Injectable({ providedIn: "root" })
export class BrowserDetectorService {
  protected constructor(private _window: WindowRef) {}

  get isBrowserIE(): boolean {
    return /(?:\b(MS)?IE\s+|\bTrident\/7\.0;.*\s+rv:)(\d+)/i.test(
      this._window.nativeElement.navigator.userAgent,
    );
  }
}
