import { AbstractControl, ValidatorFn } from "@angular/forms";

export default function minLength(
  msg: string,
  _minLength: number,
): ValidatorFn {
  return (control: AbstractControl) => {
    const message: string = msg || `Length should be at least ${_minLength}`;

    if (!control.value) {
      return { minLength: message };
    }

    return control.value.length >= _minLength ? null : { minLength: message };
  };
}
