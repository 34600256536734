import { createFeatureSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { E_COMMERCE_STATE_NAME, ECommerceState } from "../reducers";

export const getECommerceState: GetFromStateInSelectors<
  CoreState,
  ECommerceState
> = createFeatureSelector<ECommerceState>(E_COMMERCE_STATE_NAME);
