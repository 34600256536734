import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/list-summary.actions";

import { IDataListSummary } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import {
  ISegmentData,
  ISegmentTargetingCriteria,
} from "../../../segment/interfaces/segment";

import {
  ListActions,
  LIST_STATUSES_MAP,
} from "@shared/constants/data/list-statuses";

export interface IListSummaryState {
  data: IDataListSummary | null;
  segment: ISegmentData | null;
  loaded: boolean;
  loading: boolean;

  removing: boolean;
  removeError: IServerError | null;

  duplicating: boolean;
  duplicateError: IServerError | null;

  error: IServerError | null;
}

export const initialState: IListSummaryState = {
  data: null,
  segment: null,
  loaded: false,
  loading: false,

  removing: false,
  removeError: null,

  duplicating: false,
  duplicateError: null,

  error: null,
};

const getListSummary: OnReducer<IListSummaryState, ActionType<any>> = (
  state: IListSummaryState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getListSummaryError: OnReducer<
  IListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: IListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getListSummarySuccess: OnReducer<
  IListSummaryState,
  ActionType<Payload<any>>
> = (state: IListSummaryState, { payload }: Payload<any>) => ({
  ...state,
  data: { ...payload },
  loading: false,
  loaded: true,
});

export const getSegmentData: OnReducer<IListSummaryState, ActionType<any>> = (
  state: IListSummaryState,
) => ({
  ...state,
  loading: true,
  loaded: false,
  error: null,
});

export const getSegmentDataError: OnReducer<
  IListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: IListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
  segment: null,
});

export const getSegmentDataSuccess: OnReducer<
  IListSummaryState,
  ActionType<Payload<any>>
> = (state: IListSummaryState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  loaded: true,
  segment: { ...payload },
});

const removeSummaryList: OnReducer<IListSummaryState, ActionType<any>> = (
  state: IListSummaryState,
) => ({
  ...state,
  removing: true,
  removeError: null,
});

const removeSummaryListError: OnReducer<
  IListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: IListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  removing: false,
  removeError: { ...payload },
});

const removeSummaryListSuccess: OnReducer<
  IListSummaryState,
  ActionType<any>
> = (state: IListSummaryState) => ({
  ...state,
  removeError: null,
});

const duplicateSummaryList: OnReducer<IListSummaryState, ActionType<any>> = (
  state: IListSummaryState,
) => ({
  ...state,
  duplicating: true,
  duplicateError: null,
});

const duplicateSummaryListError: OnReducer<
  IListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: IListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  duplicating: false,
  duplicateError: { ...payload },
});

const duplicateSummaryListSuccess: OnReducer<
  IListSummaryState,
  ActionType<any>
> = (state: IListSummaryState) => ({
  ...state,
  duplicateError: null,
});

const resetListSummary: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IListSummaryState> =
  createReducer<IListSummaryState>(
    initialState,

    on(actions.getListSummaryAction, getListSummary),
    on(actions.getListSummaryErrorAction, getListSummaryError),
    on(actions.getListSummarySuccessAction, getListSummarySuccess),

    on(actions.getSegmentDataAction, getSegmentData),
    on(actions.getSegmentDataErrorAction, getSegmentDataError),
    on(actions.getSegmentDataSuccessAction, getSegmentDataSuccess),

    on(actions.removeSummaryListAction, removeSummaryList),
    on(actions.removeSummaryListErrorAction, removeSummaryListError),
    on(actions.removeSummaryListSuccessAction, removeSummaryListSuccess),

    on(actions.duplicateSummaryListAction, duplicateSummaryList),
    on(actions.duplicateSummaryListErrorAction, duplicateSummaryListError),
    on(actions.duplicateSummaryListSuccessAction, duplicateSummaryListSuccess),

    on(actions.resetListSummaryAction, resetListSummary),
  );

export function customerListSummaryReducer(
  state: IListSummaryState,
  action: Action,
): IListSummaryState {
  return reducer(state, action);
}

export const listSummary: GetFromState<IDataListSummary, IListSummaryState> = (
  state: IListSummaryState,
): IDataListSummary => state.data;
export const listSummaryStatus: GetFromState<number, IListSummaryState> = (
  state: IListSummaryState,
): number => state.data?.list?.status;

export const activeSegmentId: GetFromState<number, IListSummaryState, any> = (
  state: IListSummaryState,
  { segmentId }: any,
): number => (state && state.segment && state.segment.id) || +segmentId || null;
export const removing: GetFromState<boolean, IListSummaryState> = (
  state: IListSummaryState,
): boolean => state.removing;
export const duplicating: GetFromState<boolean, IListSummaryState> = (
  state: IListSummaryState,
): boolean => state.duplicating;

export const segmentTargetingData: GetFromState<
  ISegmentTargetingCriteria,
  IListSummaryState
> = (state: IListSummaryState): ISegmentTargetingCriteria =>
  state && state.segment && state.segment.targetingCriteria;

export const loading: GetFromState<boolean, IListSummaryState> = (
  state: IListSummaryState,
): boolean => state.loading;

export const ownerForCurrentList: GetFromState<
  boolean,
  IDataListSummary,
  number
> = (data: IDataListSummary, userId: number): boolean =>
  data && data.list && data.list.user.id === userId;
export const canRepurchaseCurrentList: GetFromState<
  boolean,
  IDataListSummary
> = (data: IDataListSummary): boolean =>
  data &&
  data.list &&
  LIST_STATUSES_MAP[data.list.status].availableTo.includes(
    ListActions.Repurchase,
  );
export const canDeleteCurrentList: GetFromState<
  boolean,
  IDataListSummary,
  boolean
> = (data: IDataListSummary, isOwner: boolean): boolean =>
  data &&
  data.list &&
  LIST_STATUSES_MAP[data.list.status].availableTo.includes(
    ListActions.Delete,
  ) &&
  isOwner;
export const canAddAppendCurrentList: GetFromState<
  boolean,
  IDataListSummary,
  boolean
> = (data: IDataListSummary, isOwner: boolean): boolean =>
  data &&
  data.list &&
  LIST_STATUSES_MAP[data.list.status].availableTo.includes(
    ListActions.ActiveAppendData,
  ) &&
  isOwner;
