import {
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  Injectable,
  Injector,
} from "@angular/core";

import { OverlayInfoComponent } from "../components/overlay-info/overlay-info.component";

import { PortalService } from "@core/services/portal.service";

import { IOverlayInfoParams } from "../interfaces";

import {
  DEFAULT_OVERLAY_INFO_COMPONENT_PARAMS,
  INFO_COMPONENT_ACTIONS,
  INFO_COMPONENT_DATA,
  OVERLAY_INFO_COMPONENT,
  OVERLAY_INFO_COMPONENT_PARAMS,
  OVERLAY_INFO_TARGET,
} from "../models";

import { CustomInjector } from "@core/custom-injector";
import { OverlayInfoRef } from "./overlay-info-ref";

@Injectable({ providedIn: "root" })
export class OverlayInfoProvider {
  constructor(
    private _portalService: PortalService,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
  ) {}

  createOverlayInfo<C, D, A>(
    target: ElementRef<any>,
    params: IOverlayInfoParams<C, D, A>,
    destroyOnHide: boolean = true,
  ): OverlayInfoRef<C, D, A> {
    const childInjector: Injector = this._createChildInjector<D, A>(
      params.data,
      params.actions,
    );
    const childComponentRef: ComponentRef<C> =
      this._portalService.createComponent<C>(params.component, childInjector);

    const injector: Injector = this._createInjector<C, D, A>(
      target,
      params,
      childComponentRef,
    );
    const componentRef: ComponentRef<OverlayInfoComponent<C, D, A>> =
      this._portalService.createComponent<OverlayInfoComponent<C, D, A>>(
        OverlayInfoComponent,
        injector,
      );

    return new OverlayInfoRef<C, D, A>(
      componentRef,
      () => this._show<C, D, A>(componentRef),
      () => this._hide<C, D, A>(componentRef, destroyOnHide),
    );
  }

  private _show<C, D, A>(
    componentRef: ComponentRef<OverlayInfoComponent<C, D, A>>,
  ): void {
    this._portalService.show<OverlayInfoComponent<C, D, A>>(componentRef);
    componentRef.instance.isOpen = true;
  }

  private _hide<C, D, A>(
    componentRef: ComponentRef<OverlayInfoComponent<C, D, A>>,
    destroyOnHide: boolean = true,
  ): void {
    this._portalService.hide<OverlayInfoComponent<C, D, A>>(
      componentRef,
      destroyOnHide,
    );

    if (!destroyOnHide) {
      componentRef.instance.isOpen = false;
    }
  }

  private _createChildInjector<D, A>(
    data: D = null,
    actions: A = null,
  ): Injector {
    const tokens: WeakMap<object, any> = new WeakMap();

    tokens.set(INFO_COMPONENT_DATA, data).set(INFO_COMPONENT_ACTIONS, actions);

    return new CustomInjector(this._injector, tokens);
  }

  private _createInjector<C, D, A>(
    target: ElementRef<any>,
    params: IOverlayInfoParams<C, D, A>,
    component: ComponentRef<C>,
  ): Injector {
    const tokens: WeakMap<object, any> = new WeakMap();

    tokens
      .set(OVERLAY_INFO_TARGET, target)
      .set(OVERLAY_INFO_COMPONENT_PARAMS, {
        ...DEFAULT_OVERLAY_INFO_COMPONENT_PARAMS,
        ...params,
      })
      .set(OVERLAY_INFO_COMPONENT, component);

    return new CustomInjector(this._injector, tokens);
  }
}
