import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "cardNumberMask",
})
export class CardNumberMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value
      .replace(/\D+/g, "")
      .replace(/(^\d{1,16}).*$/, "$1")
      .replace(
        /^(\d{4})(\d{4})?(\d{4})?(\d{1,4})?$/,
        (
          substring: string,
          one: string,
          two: string,
          three: string,
          four: string,
        ) => {
          return [one, two, three, four]
            .filter((match: string) => !!match)
            .join(" ");
        },
      );
  }

  parse(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value.replace(/\D+/g, "").replace(/(^\d{1,16}).*$/, "$1");
  }
}
