// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  display: block;
  position: relative;
}
.wrapper .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  height: 90px;
  width: 90px;
  color: white;
  background-size: 100%;
}
.wrapper .spinner .k-loader-primary {
  color: #0078A7;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/spinner/components/custom-spinner/custom-spinner.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;EACA,kBAAA;AADF;AAGE;EACE,kBAAA;EACA,QAAA;EACA,SAAA;EACA,qCAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;EAEA,qBAAA;AAFJ;AAII;EACE,cAAA;AAFN","sourcesContent":["@import '../../../../../assets/styles/variables';\n\n.wrapper {\n  display: block;\n  position: relative;\n\n  .spinner {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate3d(-50%, -50%, 0);\n    height: 90px;\n    width: 90px;\n    color: white;\n    // background: url('../../../../../assets/img/spinner.svg') no-repeat 50% 50%;\n    background-size: 100%;\n    \n    .k-loader-primary {\n      color: #0078A7;\n    }\n  }\n  \n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
