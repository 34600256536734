export const POPUP_TIMER: number = 3000;

export const CALL_INTERVAL: number = 10000;
export const MAX_APPEND_FIELDS_MESSAGE: number = 10;
export const ALLOCATED_CREDITS_MESSAGE: string = `Your credit limit is lower than your list size.
 To increase your limit, speak with an account admin.`;

export const EXPORT_LIST_IN_PROGRESS: string =
  "Exporting your Order Summary! Please wait while we’re processing.";
export const EXPORT_LIST_IS_READY: string =
  "Your Export Order Summary data is ready&nbsp;";
