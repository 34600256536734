import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  cdlListHistorySortingReducer,
  ICDLListHistorySortingState,
} from "./cdl-list-history-sorting.reducer";
import {
  cdlListHistoryReducer,
  ICDLListHistoryState,
} from "./cdl-list-history.reducer";

export interface ICDLListHistoryModuleState {
  cdlListHistory: ICDLListHistoryState;
  cdlListHistorySorting: ICDLListHistorySortingState;
}

const cdlListHistoryModuleReducer: ActionReducerMap<ICDLListHistoryModuleState> =
  {
    cdlListHistory: cdlListHistoryReducer,
    cdlListHistorySorting: cdlListHistorySortingReducer,
  };

// eslint-disable-next-line
export const CDL_LIST_HISTORY_MODULE_STATE_NAME = 'cdlListHistory';
export const CDL_LIST_HISTORY_MODULE_STATE: InjectionToken<
  ActionReducerMap<ICDLListHistoryModuleState>
> = new InjectionToken<ActionReducerMap<ICDLListHistoryModuleState>>(
  CDL_LIST_HISTORY_MODULE_STATE_NAME,
  { factory: () => cdlListHistoryModuleReducer },
);
