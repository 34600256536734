import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IResultsCounter } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[VIEW RESULTS INSTITUTION COUNTERS] Get institution counters",
  GET_ERROR = "[VIEW RESULTS INSTITUTION COUNTERS] Get institution counters error",
  GET_SUCCESS = "[VIEW RESULTS INSTITUTION COUNTERS] Get institution counters success",

  SET_SPINNER_STATE = "[VIEW RESULTS INSTITUTION COUNTERS] Set spinner state",

  RESET = "[VIEW RESULTS INSTITUTION COUNTERS] Reset institution counters",
}

export const getViewResultsInstitutionCountersAction: ActionCreator<
  ACTION_TYPES.GET,
  () => TypedAction<ACTION_TYPES.GET>
> = createAction(ACTION_TYPES.GET);
export const getViewResultsInstitutionCountersErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getViewResultsInstitutionCountersSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IResultsCounter
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IResultsCounter>());

export const setViewResultsInstitutionCountersSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_SPINNER_STATE,
  boolean
> = createAction(ACTION_TYPES.SET_SPINNER_STATE, createPayload<boolean>());

export const resetViewResultsInstitutionCountersAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
