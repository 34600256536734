import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";

import { of, Observable } from "rxjs";
import { catchError, mapTo, tap } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import {
  getCreditsCardsErrorAction,
  getCreditsCardsSuccessAction,
} from "../store/actions/account.action";

import { PaymentService } from "../../e-commerce/services/payment.service";

import { IServerError } from "@shared/interfaces/server-error";
import { IUserBillingInfoData } from "../../e-commerce/interfaces/e-commerce";

@Injectable({ providedIn: "root" })
export class AccountBillingExistGuard {
  constructor(
    private _store: Store<CoreState>,
    private _paymentService: PaymentService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._paymentService.userBillingInfo().pipe(
      tap((_billingInfo: IUserBillingInfoData): void =>
        this._store.dispatch(
          getCreditsCardsSuccessAction(_billingInfo.credit_card_details),
        ),
      ),
      mapTo(true),
      catchError((_error: IServerError): Observable<boolean> => {
        this._store.dispatch(getCreditsCardsErrorAction(_error));
        return of(false);
      }),
    );
  }
}
