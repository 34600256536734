import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cdl-list-summary.actions";

import { IListMainTypes } from "@modules/list-shared/interfaces";
import {
  ISegmentData,
  ISegmentTargetingCriteria,
} from "@modules/segment/interfaces/segment";
import { IDataListSummary, ISummaryList } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

import { PRODUCT_ENTITY_TYPES } from "@shared/constants/data/entity";

export interface ICDLListSummaryState {
  summaryData: IDataListSummary | null;
  segment: ISegmentData | null;

  loading: boolean;

  renaming: boolean;
  renameError: IServerError | null;

  removeError: IServerError | null;

  duplicateError: IServerError | null;

  error: IServerError | null;
}

export const initialState: ICDLListSummaryState = {
  summaryData: null,
  segment: null,

  loading: false,

  renaming: false,
  renameError: null,

  removeError: null,

  duplicateError: null,

  error: null,
};

const getCDLListSummary: OnReducer<ICDLListSummaryState, ActionType<any>> = (
  state: ICDLListSummaryState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getCDLListSummaryError: OnReducer<
  ICDLListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: payload,
});

const getCDLListSummarySuccess: OnReducer<
  ICDLListSummaryState,
  ActionType<Payload<IDataListSummary>>
> = (state: ICDLListSummaryState, { payload }: Payload<IDataListSummary>) => ({
  ...state,
  summaryData: payload,
  loading: false,
});

export const getCDLSegmentData: OnReducer<
  ICDLListSummaryState,
  ActionType<any>
> = (state: ICDLListSummaryState) => ({
  ...state,
  loading: true,
  error: null,
});

export const getCDLSegmentDataError: OnReducer<
  ICDLListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: payload,
  segment: null,
});

export const getCDLSegmentDataSuccess: OnReducer<
  ICDLListSummaryState,
  ActionType<Payload<any>>
> = (state: ICDLListSummaryState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  segment: payload,
});

const renameCDLList: OnReducer<ICDLListSummaryState, ActionType<any>> = (
  state: ICDLListSummaryState,
) => ({
  ...state,
  loading: true,
  renaming: true,
  renameError: null,
});

const renameCDLListError: OnReducer<
  ICDLListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  renaming: false,
  renameError: payload,
});

const renameCDLListSuccess: OnReducer<ICDLListSummaryState, ActionType<any>> = (
  state: ICDLListSummaryState,
) => ({
  ...state,
  loading: false,
  renaming: false,
  renameError: null,
});

const renameCDLListCancel: OnReducer<ICDLListSummaryState, ActionType<any>> = (
  state: ICDLListSummaryState,
) => ({
  ...state,
  renaming: false,
  renameError: null,
});

const removeCDLListSummary: OnReducer<ICDLListSummaryState, ActionType<any>> = (
  state: ICDLListSummaryState,
) => ({
  ...state,
  loading: true,
  removeError: null,
});

const removeCDLListSummaryError: OnReducer<
  ICDLListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  removeError: payload,
});

const removeCDLListSummarySuccess: OnReducer<
  ICDLListSummaryState,
  ActionType<any>
> = (state: ICDLListSummaryState) => ({
  ...state,
  removeError: null,
});

const duplicateCDLListSummary: OnReducer<
  ICDLListSummaryState,
  ActionType<any>
> = (state: ICDLListSummaryState) => ({
  ...state,
  loading: true,
  duplicateError: null,
});

const duplicateCDLListSummaryError: OnReducer<
  ICDLListSummaryState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  duplicateError: payload,
});

const duplicateCDLListSummarySuccess: OnReducer<
  ICDLListSummaryState,
  ActionType<any>
> = (state: ICDLListSummaryState) => ({
  ...state,
  duplicateError: null,
});

const resetCDLListSummary: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLListSummaryState> =
  createReducer<ICDLListSummaryState>(
    initialState,

    on(actions.getCDLListSummaryAction, getCDLListSummary),
    on(actions.getCDLListSummaryErrorAction, getCDLListSummaryError),
    on(actions.getCDLListSummarySuccessAction, getCDLListSummarySuccess),

    on(actions.getCDLSegmentDataAction, getCDLSegmentData),
    on(actions.getCDLSegmentDataErrorAction, getCDLSegmentDataError),
    on(actions.getCDLSegmentDataSuccessAction, getCDLSegmentDataSuccess),

    on(actions.renameCDLListSummaryAction, renameCDLList),
    on(actions.renameCDLListSummaryErrorAction, renameCDLListError),
    on(actions.renameCDLListSummarySuccessAction, renameCDLListSuccess),
    on(actions.renameCDLListSummaryCancelAction, renameCDLListCancel),

    on(actions.removeCDLListSummaryAction, removeCDLListSummary),
    on(actions.removeCDLListSummaryErrorAction, removeCDLListSummaryError),
    on(actions.removeCDLListSummarySuccessAction, removeCDLListSummarySuccess),

    on(actions.duplicateCDLListSummaryAction, duplicateCDLListSummary),
    on(
      actions.duplicateCDLListSummaryErrorAction,
      duplicateCDLListSummaryError,
    ),
    on(
      actions.duplicateCDLListSummarySuccessAction,
      duplicateCDLListSummarySuccess,
    ),

    on(actions.resetCDLListSummaryAction, resetCDLListSummary),
  );

export function cdlListSummaryReducer(
  state: ICDLListSummaryState,
  action: Action,
): ICDLListSummaryState {
  return reducer(state, action);
}

export const cdlListSummary: GetFromState<
  IDataListSummary,
  ICDLListSummaryState
> = (state: ICDLListSummaryState): IDataListSummary => state.summaryData;
export const cdlListSummaryDataList: GetFromState<
  ISummaryList,
  IDataListSummary
> = (state: IDataListSummary): ISummaryList => state && state.list;
export const cdlListSegmentTargeting: GetFromState<
  ISegmentTargetingCriteria,
  ICDLListSummaryState
> = (state: ICDLListSummaryState): ISegmentTargetingCriteria =>
  state && state.segment && state.segment.targetingCriteria;
export const cdlSummaryListId: GetFromState<number, ISummaryList> = (
  list: ISummaryList,
): number => list && list.id;
export const loading: GetFromState<boolean, ICDLListSummaryState> = (
  state: ICDLListSummaryState,
): boolean => state.loading;

export const cdlListMainTypes: GetFromState<
  IListMainTypes,
  ISummaryList,
  PRODUCT_ENTITY_TYPES
> = (
  list: ISummaryList,
  entityType: PRODUCT_ENTITY_TYPES = PRODUCT_ENTITY_TYPES.CDL,
): IListMainTypes => {
  if (list) {
    const { name, recordsType, type }: Partial<ISummaryList> = list;
    return { name, recordType: recordsType.id, listType: type.id, entityType };
  }

  return null;
};
export const renameError: GetFromState<IServerError, ICDLListSummaryState> = (
  state: ICDLListSummaryState,
): IServerError => state && state.renameError;
export const listTooltipDescription: GetFromState<string, ISummaryList> = (
  list: ISummaryList,
): string => (list && `List #${list.id} Customer #${list.user.id}`) || "";
