import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IInstitutionsRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_QUICK_SEARCH_INSTITUTION_COLLEGES = "[QUICK SEARCH INSTITUTION] get colleges",
  GET_QUICK_SEARCH_INSTITUTION_COLLEGES_ERROR = "[QUICK SEARCH INSTITUTION] get colleges error",
  GET_QUICK_SEARCH_INSTITUTION_COLLEGES_SUCCESS = "[QUICK SEARCH INSTITUTION] get colleges success",

  SET_QUICK_SEARCH_INSTITUTION_COLLEGES_SPINNER_STATE = "[QUICK SEARCH INSTITUTION] set spinner state",

  CHANGE_QUICK_SEARCH_INSTITUTION_COLLEGES_PAGE = "[QUICK SEARCH INSTITUTION] Change colleges page",
  CHANGE_QUICK_SEARCH_INSTITUTION_COLLEGES_QUERY = "[QUICK SEARCH INSTITUTION] Change colleges query",

  RESET_QUICK_SEARCH_INSTITUTION_COLLEGES_PAGE_STAGE = "[QUICK SEARCH INSTITUTION] reset colleges page state",
}

export const getQuickSearchInstitutionCollegesAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_COLLEGES,
  () => TypedAction<ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_COLLEGES>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_COLLEGES);
export const getQuickSearchInstitutionCollegesErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_COLLEGES_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_COLLEGES_ERROR,
  createPayload<IServerError>(),
);
export const getQuickSearchInstitutionCollegesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_COLLEGES_SUCCESS,
  IProductResultsResponse<IInstitutionsRecords>
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_INSTITUTION_COLLEGES_SUCCESS,
  createPayload<IProductResultsResponse<IInstitutionsRecords>>(),
);

export const setQuickSearchInstitutionCollegesSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_QUICK_SEARCH_INSTITUTION_COLLEGES_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_QUICK_SEARCH_INSTITUTION_COLLEGES_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeQuickSearchInstitutionCollegesPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_INSTITUTION_COLLEGES_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_INSTITUTION_COLLEGES_PAGE,
  createPayload<number>(),
);

export const changeQuickSearchInstitutionCollegesQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_INSTITUTION_COLLEGES_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_INSTITUTION_COLLEGES_QUERY,
  createPayload<Params>(),
);

export const resetQuickSearchInstitutionCollegesStateAction: ActionCreator<
  ACTION_TYPES.RESET_QUICK_SEARCH_INSTITUTION_COLLEGES_PAGE_STAGE,
  () => TypedAction<ACTION_TYPES.RESET_QUICK_SEARCH_INSTITUTION_COLLEGES_PAGE_STAGE>
> = createAction(
  ACTION_TYPES.RESET_QUICK_SEARCH_INSTITUTION_COLLEGES_PAGE_STAGE,
);
