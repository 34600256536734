import { Routes } from "@angular/router";

import { BillingDetailsPageComponent } from "../pages/billing-details-page/billing-details-page.component";
import { ProductsAndUsersPageComponent } from "../pages/products-and-users-page/products-and-users-page.component";
import { ProfileDetailsPageComponent } from "../pages/profile-details-page/profile-details-page.component";
import { ProfilePageComponent } from "../pages/profile-page/profile-page.component";
import { ProfileSettingsPageComponent } from "../pages/profile-settings-page/profile-settings-page.component";
import { UserHistoryComponent } from "../pages/user-history/user-history.component";

import { CheckUserDataExistGuard } from "@core/guards/check-user-data.guard";
import { IsNotBrokerGuard } from "@core/guards/is-not-broker.guard";
import { ProfileSettingsExistGuard } from "@core/guards/profile-settings-exist.guard";
import { UserDataExistGuard } from "@core/guards/user-data-exist.guard";
import { CountriesExistGuard } from "../../countries/guards/countries-exist.guard";
import { StatesByCountriesExistGuard } from "../../countries/guards/states-by-countries-exist.guard";
import { AccountBillingExistGuard } from "../guards/account-billing-exist.guard";
import { AccountSeatsExistGuard } from "../guards/account-seats-exist-guard";
import { AccountStatesExistGuard } from "../guards/account-states-exist.guard";
import { AccountUsersExistGuard } from "../guards/account-users-exist-guard";
import { AdminAccountRedirectGuard } from "../guards/admin-account-redirect-guard";

import { CORE_PATHS } from "@core/constants/core-paths";

import { PROFILE_PATHS } from "./profile-route-paths";

// constants

// guards

// components

export const PROFILE_ROUTES: Routes = [
  {
    path: CORE_PATHS.EMPTY,
    component: ProfilePageComponent,
    children: [
      {
        path: PROFILE_PATHS.PRODUCTS,
        component: ProductsAndUsersPageComponent,
        canActivate: [
          UserDataExistGuard,
          AccountSeatsExistGuard,
          AccountUsersExistGuard,
          AccountStatesExistGuard,
        ],
      },
      {
        path: PROFILE_PATHS.DETAILS,
        component: ProfileDetailsPageComponent,
        canActivate: [
          AdminAccountRedirectGuard,
          CheckUserDataExistGuard,
          CountriesExistGuard,
          StatesByCountriesExistGuard,
        ],
      },
      {
        path: PROFILE_PATHS.SETTINGS,
        component: ProfileSettingsPageComponent,
        canActivate: [
          AdminAccountRedirectGuard,
          CheckUserDataExistGuard,
          ProfileSettingsExistGuard,
        ],
      },
      {
        path: PROFILE_PATHS.BILLING,
        component: BillingDetailsPageComponent,
        canActivate: [
          AdminAccountRedirectGuard,
          AccountBillingExistGuard,
          IsNotBrokerGuard,
        ],
      },
      {
        path: PROFILE_PATHS.HISTORY,
        component: UserHistoryComponent,
      },
    ],
  },
];
