import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/list-settings.action";

import { IServerError } from "@shared/interfaces/server-error";

import { IDataListSetting, IDataListSettings } from "@shared/interfaces/list";

export interface IListSettingState {
  data: IDataListSettings | null;
  loaded: boolean;
  loading: boolean;
  removing: boolean;
  removeError: IServerError | null;
  error: IServerError | null;
}

export const initialState: IListSettingState = {
  data: null,
  loaded: false,
  loading: false,
  removing: false,
  removeError: null,
  error: null,
};

const getListSettings: OnReducer<IListSettingState, ActionType<any>> = (
  state: IListSettingState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getListSettingsError: OnReducer<
  IListSettingState,
  ActionType<Payload<IServerError>>
> = (state: IListSettingState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getListSettingsSuccess: OnReducer<
  IListSettingState,
  ActionType<Payload<any>>
> = (state: IListSettingState, { payload }: Payload<IDataListSettings>) => ({
  ...state,
  data: [...payload],
  loading: false,
  loaded: true,
});

const updateListSettings: OnReducer<IListSettingState, ActionType<any>> = (
  state: IListSettingState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const updateListSettingsError: OnReducer<
  IListSettingState,
  ActionType<Payload<IServerError>>
> = (state: IListSettingState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const updateListSettingsSuccess: OnReducer<
  IListSettingState,
  ActionType<Payload<any>>
> = (state: IListSettingState, { payload }: Payload<IDataListSettings>) => ({
  ...state,
  data: [...payload],
  loading: false,
  loaded: true,
});

const removeSettingsList: OnReducer<IListSettingState, ActionType<any>> = (
  state: IListSettingState,
) => ({
  ...state,
  removing: true,
  removeError: null,
});

const removeSettingsListError: OnReducer<
  IListSettingState,
  ActionType<Payload<IServerError>>
> = (state: IListSettingState, { payload }: Payload<IServerError>) => ({
  ...state,
  removing: false,
  removeError: { ...payload },
});

const removeSettingsListSuccess: OnReducer<
  IListSettingState,
  ActionType<any>
> = (state: IListSettingState, { payload }) => ({
  ...state,
  data: removeUserFromList(state, payload),
  removeError: null,
});

const reducer: ActionReducer<IListSettingState> =
  createReducer<IListSettingState>(
    initialState,

    on(actions.getListSettingsAction, getListSettings),
    on(actions.getListSettingsErrorAction, getListSettingsError),
    on(actions.getListSettingsSuccessAction, getListSettingsSuccess),

    on(actions.updateListSettingsAction, updateListSettings),
    on(actions.updateListSettingsErrorAction, updateListSettingsError),
    on(actions.updateListSettingsSuccessAction, updateListSettingsSuccess),

    on(actions.removeSettingsListAction, removeSettingsList),
    on(actions.removeSettingsListErrorAction, removeSettingsListError),
    on(actions.removeSettingsListSuccessAction, removeSettingsListSuccess),
  );

export function customerListSettingsReducer(
  state: IListSettingState,
  action: Action,
): IListSettingState {
  return reducer(state, action);
}

export const listSettings: GetFromState<any, IListSettingState> = (
  state: IListSettingState,
): any => {
  if (state.data) {
    return state.data.map((e) => {
      e.description = e.label + " (" + e.email + " )";
      if (e.default) {
        e.value = e.default;
        e.disabled = e.default;
      }
      return e;
    });
  }
  return state.data;
};

export const removeUserFromList: any = (
  state: IListSettingState,
  user: IDataListSetting,
) => {
  let data = state.data.map((e) => {
    if (e.id == user.id) {
      e.value = false;
    }
    return e;
  });
  return data;
};
