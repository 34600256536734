import { InjectionToken } from "@angular/core";

export const POP_UP_DATA: InjectionToken<string> = new InjectionToken<string>(
  "pop_up_data",
);
export const POP_UP_CONFIG: InjectionToken<string> = new InjectionToken<string>(
  "pop_up_config",
);
export const POP_UP_CONTENT_COMPONENT: InjectionToken<string> =
  new InjectionToken<string>("pop_up_content_component");
