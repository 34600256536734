import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { RouterModule } from "@angular/router";

import { ActiveListAppendsSuccessComponent } from "./components/active-list-appends-success/active-list-appends-success.component";
import { RefineListPidsUpdatedComponent } from "./components/refine-list-pids-updated/refine-list-pids-updated.component";
import { SnackBarComponent } from "./components/snack-bar/snack-bar.component";

const _components: any[] = [
  SnackBarComponent,
  ActiveListAppendsSuccessComponent,
  RefineListPidsUpdatedComponent,
];

@NgModule({
  imports: [CommonModule, RouterModule, MatSnackBarModule],
  declarations: [..._components],
  exports: [..._components],
})
export class SnackBarModule {}
