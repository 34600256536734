import {
  forwardRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "bl-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioComponent),
      multi: true,
    },
  ],
})
export class RadioComponent implements ControlValueAccessor {
  @Input() value: any;
  @Input() checked: boolean;
  @Input() label: string;
  @Input() description: string;
  @Input() theme: "default" | "checkbox" | "radio-tab" = "default";
  @Input() className: string;
  @Input() isDisabled: boolean;
  @Input() isPremium: boolean = false;

  @Input() name: string;
  @Input() formControlName: string;

  @Output() changeRadioValue: EventEmitter<any> = new EventEmitter();
  @Output() premiumDataChanged: EventEmitter<any> = new EventEmitter();

  _initialChecked: boolean;
  _name: string;

  get radioClass(): { [key: string]: boolean } {
    return {
      [this.theme]: true,
      [this.className]: true,
      disabled: this.isDisabled,
    };
  }

  propagateChange: any = () => {};

  writeValue(initValue: any): void {
    this._name = this.name ? this.name : this.formControlName;
    this._initialChecked = this.value === initValue;
  }

  registerOnChange(fn: () => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {}

  onChange(event: any): void {
    event.preventDefault();

    if (event.target.checked && !this.isDisabled) {
      this.propagateChange(this.value);
      this.changeRadioValue.emit(this.value);
      if (this.isPremium) {
        this.premiumDataChanged.emit();
      }
    }
  }
}
