export enum SOCKET_EVENTS {
  PERMISSION_CHANGED = "permissionsChanged",

  UPLOAD_PID_FILE = "fileUploaded",

  LIST_SHARED_CALCULATED = "listShardCalculated",
  LIST_CALCULATED = "listCalculated",

  CONNECTION_PROCESSED = "connectionProcessed",
  OAUTH_URL_CREATED = "oAuthUrlCreated",

  ACTIVE_LIST_APPENDS_CALCULATED = "listAppendsCalculated",

  REFINE_LIST_PIDS_UPDATED = "refineListPidsUpdated",

  EXPORT_LIST_PROCESS = "ExportList",

  DUPLICATE_SEGMENTS_READY = "DuplicateSegment",

  REFRESH_CUSTOM_OBJECT_READY = "metadataResettingCompleted",

  COUNTS_REFRESHED = "CountsRefreshed",
}
