import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  geoCodesReducer,
  IPersonnelCodesState,
} from "./personnel-codes.reducer";

export interface IPersonnelCodesModuleState {
  personnelCodes: IPersonnelCodesState;
}

const PERSONNEL_CODES_MODULE_STATE_REDUCER: ActionReducerMap<IPersonnelCodesModuleState> =
  {
    personnelCodes: geoCodesReducer,
  };

// eslint-disable-next-line
export const PERSONNEL_CODES_MODULE_STATE_NAME = 'PERSONNEL_CODES_STATE';
export const PERSONNEL_CODES_MODULE_STATE: InjectionToken<
  ActionReducerMap<IPersonnelCodesModuleState>
> = new InjectionToken<ActionReducerMap<IPersonnelCodesModuleState>>(
  PERSONNEL_CODES_MODULE_STATE_NAME,
  { factory: () => PERSONNEL_CODES_MODULE_STATE_REDUCER },
);
