import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
  Type,
} from "@angular/core";

import { timer } from "rxjs";
import { filter, switchMapTo } from "rxjs/operators";

import { PopUpPortalComponent } from "../../components/pop-up-portal/pop-up-portal.component";

import { LayoutService } from "@core/services/layout.service";
import { PortalService } from "@core/services/portal.service";

import { PopUpConfig } from "../../models/pop-up-config";
import { PopUpRef } from "../../models/pop-up-ref";

import { CustomInjector } from "@core/custom-injector";
import {
  POP_UP_CONFIG,
  POP_UP_CONTENT_COMPONENT,
  POP_UP_DATA,
} from "../../injection-tokens";

@Injectable()
export class PopUpService {
  private openedPopUp?: PopUpRef;

  constructor(
    private injector: Injector,
    private componentFactoryResolver: ComponentFactoryResolver,
    private portalService: PortalService,
    private layoutService: LayoutService,
  ) {}

  open<T = Type<any>, D = any, R = any>(
    component: Type<T>,
    _config: Partial<PopUpConfig<D>> = new PopUpConfig<D>(),
  ): PopUpRef<T, R> {
    this.close();

    const config: PopUpConfig<D> = { ...new PopUpConfig(), ..._config };
    const popUpRef: PopUpRef<T, R> = new PopUpRef<T, R>();
    const injector: Injector = this.createInjector<T>(
      popUpRef,
      config,
      component,
    );
    const detachFn: () => void = this.attachPortal<T>(injector);

    popUpRef.setDetachFunction(detachFn);

    this.setupRefSubscriptions(popUpRef, config);

    this.openedPopUp = popUpRef;

    return popUpRef;
  }

  close(): void {
    if (this.openedPopUp) {
      this.openedPopUp.close();
    }

    this.openedPopUp = null;
  }

  private createInjector<T>(
    popUpRef: PopUpRef,
    config: PopUpConfig,
    component: Type<T>,
  ): Injector {
    const tokens: WeakMap<object, any> = new WeakMap();

    tokens
      .set(POP_UP_CONFIG, config)
      .set(POP_UP_DATA, config.data)
      .set(PopUpRef, popUpRef)
      .set(POP_UP_CONTENT_COMPONENT, component);

    return new CustomInjector(this.injector, tokens);
  }

  private attachPortal<T>(injector: Injector): () => void {
    const componentRef: ComponentRef<PopUpPortalComponent<T>> =
      this.componentFactoryResolver
        .resolveComponentFactory<PopUpPortalComponent<T>>(PopUpPortalComponent)
        .create(injector);

    return this.portalService.attachComponentRef(componentRef);
  }

  private setupRefSubscriptions(popUpRef: PopUpRef, config: PopUpConfig): void {
    popUpRef.afterOpen.subscribe(() =>
      this.layoutService.disableGlobalScroll(),
    );

    popUpRef.afterOpen
      .pipe(
        filter(() => !!config.timer),
        switchMapTo(timer(config.timer)),
      )
      .subscribe(() => popUpRef.close());

    popUpRef.afterClose.subscribe(() => {
      this.openedPopUp = null;
      this.layoutService.enableGlobalScroll();
    });
  }
}
