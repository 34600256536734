import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { ICDLListHistoryComment, ICDLListHistoryItem } from "../../interfaces";

import { ICDLListHistoryRequest, ICDLListHistorySuccess } from "../../models";

export enum ACTION_TYPES {
  GET = "[CDL LIST HISTORY] Get cdl list history",
  GET_ERROR = "[CDL LIST HISTORY] Get cdl list history error",
  GET_SUCCESS = "[CDL LIST HISTORY] Get cdl list history success",

  UPDATE_LIST_HISTORY_ITEM_COMMENT = "[CDL LIST HISTORY]: Update list history item comment",
  UPDATE_LIST_HISTORY_ITEM_COMMENT_ERROR = "[CDL LIST HISTORY]: Update list history item comment error",
  UPDATE_LIST_HISTORY_ITEM_COMMENT_SUCCESS = "[CDL LIST HISTORY]: Update list history item comment success",

  SHOW_EDIT_COMMENT_POP_UP = "[CDL LIST HISTORY] Show edit comment pop up",

  RESET = "[CDL LIST HISTORY] Reset State",
}

export const getCDLListHistoryAction: CreateActionType<
  ACTION_TYPES.GET,
  Partial<ICDLListHistoryRequest>
> = createAction(
  ACTION_TYPES.GET,
  createPayload<Partial<ICDLListHistoryRequest>>(),
);
export const getCDLListHistoryErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getCDLListHistorySuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  ICDLListHistorySuccess
> = createAction(
  ACTION_TYPES.GET_SUCCESS,
  createPayload<ICDLListHistorySuccess>(),
);

export const updateCDLListHistoryItemCommentAction: CreateActionType<
  ACTION_TYPES.UPDATE_LIST_HISTORY_ITEM_COMMENT,
  ICDLListHistoryComment
> = createAction(
  ACTION_TYPES.UPDATE_LIST_HISTORY_ITEM_COMMENT,
  createPayload<ICDLListHistoryComment>(),
);
export const updateCDLListHistoryItemCommentErrorAction: CreateActionType<
  ACTION_TYPES.UPDATE_LIST_HISTORY_ITEM_COMMENT_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.UPDATE_LIST_HISTORY_ITEM_COMMENT_ERROR,
  createPayload<IServerError>(),
);
export const updateCDLListHistoryItemCommentSuccessAction: ActionCreator<
  ACTION_TYPES.UPDATE_LIST_HISTORY_ITEM_COMMENT_SUCCESS,
  () => TypedAction<ACTION_TYPES.UPDATE_LIST_HISTORY_ITEM_COMMENT_SUCCESS>
> = createAction(ACTION_TYPES.UPDATE_LIST_HISTORY_ITEM_COMMENT_SUCCESS);

export const showCDLListHistoryEditCommentPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_EDIT_COMMENT_POP_UP,
  ICDLListHistoryItem
> = createAction(
  ACTION_TYPES.SHOW_EDIT_COMMENT_POP_UP,
  createPayload<ICDLListHistoryItem>(),
);

export const resetCDLListHistoryStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
