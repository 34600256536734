import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cdl-list.action";

import { StorageService } from "@core/services/storage.service";

import { ISegmentData } from "@modules/segment/interfaces/segment";
import { IListData } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

import {
  LIST_RECORDS_TYPES,
  LIST_RELATE_TO_PERSONNEL_TYPE,
  LIST_TYPES,
  LIST_TYPES_MAP,
} from "@shared/constants/data/list-types";
import { SEGMENT_TYPES } from "../../constants";

export interface ICDLListState {
  list: IListData | null;
  loading: boolean;
  error: IServerError | null;

  renaming: boolean;
  renameError: IServerError | null;

  activeTab: SEGMENT_TYPES;
}

const initialState: ICDLListState = {
  list: null,
  loading: false,
  error: null,

  renaming: false,
  renameError: null,

  activeTab: StorageService.activeCDLTab || SEGMENT_TYPES.INSTITUTIONS,
};

const getCDLList: OnReducer<ICDLListState, ActionType<any>> = (
  state: ICDLListState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getCDLListError: OnReducer<
  ICDLListState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: payload,
});

const getCDLListSuccess: OnReducer<ICDLListState, ActionType<Payload<any>>> = (
  state: ICDLListState,
  { payload }: Payload<any>,
) => ({
  ...state,
  list: payload,
  loading: !!payload.listingCountsCalculating,
});

const reloadCDLList: OnReducer<ICDLListState, ActionType<any>> = (
  state: ICDLListState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const reloadCDLListError: OnReducer<
  ICDLListState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: payload,
});

const reloadCDLListSuccess: OnReducer<
  ICDLListState,
  ActionType<Payload<any>>
> = (state: ICDLListState, { payload }: Payload<any>) => ({
  ...state,
  list: payload,
  loading: false,
});

const changesStart: OnReducer<ICDLListState, ActionType<any>> = (
  state: ICDLListState,
) => ({
  ...state,
  loading: true,
});

const renameCDLList: OnReducer<ICDLListState, ActionType<any>> = (
  state: ICDLListState,
) => ({
  ...state,
  loading: true,
  renaming: true,
  renameError: null,
});

const renameCDLListError: OnReducer<
  ICDLListState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  renaming: false,
  renameError: payload,
});

const renameCDLListSuccess: OnReducer<
  ICDLListState,
  ActionType<Payload<any>>
> = (state: ICDLListState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  list: payload,
  renaming: false,
  renameError: null,
});

const renameCDLListCancel: OnReducer<ICDLListState, ActionType<any>> = (
  state: ICDLListState,
) => ({
  ...state,
  renaming: false,
  renameError: null,
});

const changeCDLListType: OnReducer<ICDLListState, ActionType<any>> = (
  state: ICDLListState,
) => ({
  ...state,
  loading: true,
});

const changeCDLListTypeError: OnReducer<ICDLListState, ActionType<any>> = (
  state: ICDLListState,
) => ({
  ...state,
  loading: false,
});

const changeCDLListTypeSuccess: OnReducer<
  ICDLListState,
  ActionType<Payload<any>>
> = (state: ICDLListState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  list: payload,
});

const setActiveTab: OnReducer<ICDLListState, ActionType<Payload<any>>> = (
  state: ICDLListState,
  { payload }: Payload<any>,
) => ({
  ...state,
  activeTab: payload,
});

const setActiveTabWithoutReload: OnReducer<
  ICDLListState,
  ActionType<Payload<any>>
> = (state: ICDLListState, { payload: { activeTabId } }: Payload<any>) => ({
  ...state,
  activeTab: activeTabId,
});

const updateCDLListState: OnReducer<ICDLListState, ActionType<Payload<any>>> = (
  state: ICDLListState,
  { payload }: Payload<any>,
) => ({
  ...state,
  loaded: true,
  list: { ...state.list, ...payload },
});

const resetState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLListState> = createReducer(
  initialState,

  on(actions.getCDLListAction, getCDLList),
  on(actions.getCDLListErrorAction, getCDLListError),
  on(actions.getCDLListSuccessAction, getCDLListSuccess),

  on(actions.renameCDLListAction, renameCDLList),
  on(actions.renameCDLListErrorAction, renameCDLListError),
  on(actions.renameCDLListSuccessAction, renameCDLListSuccess),
  on(actions.renameCDLListCancelAction, renameCDLListCancel),

  on(actions.changeCDLListTypeAction, changeCDLListType),
  on(actions.changeCDLListTypeErrorAction, changeCDLListTypeError),
  on(actions.changeCDLListTypeSuccessAction, changeCDLListTypeSuccess),

  on(actions.reloadCDLListAction, reloadCDLList),
  on(actions.reloadCDLListWithRedirectAction, reloadCDLList),
  on(actions.reloadCDLListWithRedirectToFirstSegmentAction, reloadCDLList),
  on(actions.reloadCDLListErrorAction, reloadCDLListError),
  on(
    actions.reloadCDLListWithRedirectToFirstSegmentErrorAction,
    reloadCDLListError,
  ),
  on(actions.reloadCDLListSuccessAction, reloadCDLListSuccess),
  on(actions.reloadCDLListWithRedirectSuccessAction, reloadCDLListSuccess),
  on(
    actions.reloadCDLListWithRedirectToFirstSegmentSuccessAction,
    reloadCDLListSuccess,
  ),

  on(actions.cdlChangesStartAction, changesStart),

  on(actions.setCDLActiveTabAction, setActiveTab),
  on(actions.setCDLActiveTabWithoutReloadAction, setActiveTabWithoutReload),

  on(actions.updateCDLListStateAction, updateCDLListState),

  on(actions.resetCDLStateAction, resetState),
);

export function cdlListReducer(
  state: ICDLListState,
  action: Action,
): ICDLListState {
  return reducer(state, action);
}

export const cdlList: GetFromState<IListData, ICDLListState> = (
  state: ICDLListState,
): IListData => state && state.list;
export const cdlListId: GetFromState<number, IListData> = (
  list: IListData,
): number => list && list.id;
export const cdlListRecordTypeId: GetFromState<
  LIST_RECORDS_TYPES,
  IListData
> = (list: IListData): LIST_RECORDS_TYPES =>
  list && list.recordsType && list.recordsType.id;
export const cdlListTypeId: GetFromState<LIST_TYPES, IListData> = (
  list: IListData,
): LIST_TYPES => list && list.type && list.type.id;
export const cdlListName: GetFromState<string, IListData> = (
  list: IListData,
): string => list && list.name;
export const cdlListSegments: GetFromState<
  ISegmentData[],
  IListData,
  SEGMENT_TYPES
> = (list: IListData, _activeTab: SEGMENT_TYPES): ISegmentData[] =>
  list &&
  list.segments &&
  list.segments.filter(
    (segment: ISegmentData) => segment.segmentType === _activeTab,
  );
export const cdlSetCapAvailable: GetFromState<boolean, LIST_TYPES> = (
  listTypeId: LIST_TYPES,
): boolean =>
  LIST_TYPES_MAP[listTypeId] && LIST_TYPES_MAP[listTypeId].canSetCpaToSegment;

export const isRelateToPersonnelListType: GetFromState<
  boolean,
  LIST_RECORDS_TYPES
> = (listRecordTypeId: LIST_RECORDS_TYPES): boolean =>
  LIST_RELATE_TO_PERSONNEL_TYPE.includes(listRecordTypeId);

export const isRelateSegmentToPersonnelType: GetFromState<
  boolean,
  SEGMENT_TYPES
> = (_activeTab: SEGMENT_TYPES): boolean =>
  _activeTab === SEGMENT_TYPES.PERSONNEL;

export const isHasDetailsCount: GetFromState<boolean, IListData> = (
  list: IListData,
): boolean => {
  const count: number =
    list && list.recordsType.id === LIST_RECORDS_TYPES.PEOPLE_ONLY
      ? list.personsCount
      : list.institutionsCount;
  return count > 0;
};
export const loading: GetFromState<boolean, ICDLListState> = (
  state: ICDLListState,
): boolean => state && state.loading;
export const renameError: GetFromState<IServerError, ICDLListState> = (
  state: ICDLListState,
): IServerError => state && state.renameError;

export const isDisableSubmitButton: GetFromState<boolean, IListData> = (
  list: IListData,
): boolean => {
  const count: number =
    list && LIST_RELATE_TO_PERSONNEL_TYPE.includes(list.recordsType.id)
      ? list.personsCount
      : list.institutionsCount;
  return !count || list.listingCountsCalculating;
};

export const activeTab: GetFromState<SEGMENT_TYPES, ICDLListState> = (
  state: ICDLListState,
): SEGMENT_TYPES => state && state.activeTab;
