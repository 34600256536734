import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/alerts-tabs.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IAlertsTabsCounts } from "../../interfaces";

export interface IViewResultsAlertsTabsState {
  counts: IAlertsTabsCounts | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IViewResultsAlertsTabsState = {
  counts: null,
  pending: false,
  error: null,
};

const getViewResultsAlertsTabsHandler: OnReducer<
  IViewResultsAlertsTabsState,
  ActionType<any>
> = (state: IViewResultsAlertsTabsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsAlertsTabsErrorHandler: OnReducer<
  IViewResultsAlertsTabsState,
  ActionType<any>
> = (state: IViewResultsAlertsTabsState, { payload: error }: Payload<any>) => ({
  ...state,
  pending: false,
  error,
});

const getViewResultsAlertsTabsSuccessHandler: OnReducer<
  IViewResultsAlertsTabsState,
  ActionType<any>
> = (
  state: IViewResultsAlertsTabsState,
  { payload: counts }: Payload<any>,
) => ({
  ...state,
  pending: false,
  counts,
});

const setViewResultsAlertsTabsSpinnerState: OnReducer<
  IViewResultsAlertsTabsState,
  ActionType<Payload<any>>
> = (state: IViewResultsAlertsTabsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsAlertsTabsStateHandler: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsAlertsTabsState> =
  createReducer<IViewResultsAlertsTabsState>(
    initialState,

    on(actions.getViewResultsAlertsTabsAction, getViewResultsAlertsTabsHandler),
    on(
      actions.getViewResultsAlertsTabsErrorAction,
      getViewResultsAlertsTabsErrorHandler,
    ),
    on(
      actions.getViewResultsAlertsTabsSuccessAction,
      getViewResultsAlertsTabsSuccessHandler,
    ),

    on(
      actions.setViewResultsAlertsTabsSpinnerStateAction,
      setViewResultsAlertsTabsSpinnerState,
    ),

    on(
      actions.resetViewResultsAlertsTabsStateAction,
      resetViewResultsAlertsTabsStateHandler,
    ),
  );

export function viewResultsAlertsTabsReducer(
  state: IViewResultsAlertsTabsState,
  action: Action,
): IViewResultsAlertsTabsState {
  return reducer(state, action);
}

export const tabsCounts: GetFromState<
  IAlertsTabsCounts,
  IViewResultsAlertsTabsState
> = (state: IViewResultsAlertsTabsState): IAlertsTabsCounts =>
  state && state.counts;
export const tabsCountsError: GetFromState<
  IServerError,
  IViewResultsAlertsTabsState
> = (state: IViewResultsAlertsTabsState): IServerError => state && state.error;
export const tabsCountsPending: GetFromState<
  boolean,
  IViewResultsAlertsTabsState
> = (state: IViewResultsAlertsTabsState): boolean => state && state.pending;

export const institutionPaginationCount: GetFromState<
  number,
  IAlertsTabsCounts
> = (counts: IAlertsTabsCounts): number => counts && counts.institutions;
export const grantsPaginationCount: GetFromState<number, IAlertsTabsCounts> = (
  counts: IAlertsTabsCounts,
): number => counts && counts.grants;
export const bidsPaginationCount: GetFromState<number, IAlertsTabsCounts> = (
  counts: IAlertsTabsCounts,
): number => counts && counts.bids;
export const allPaginationCounts: GetFromState<number, IAlertsTabsCounts> = (
  counts: IAlertsTabsCounts,
): number =>
  counts &&
  Object.values(counts).reduce(
    (per_count: number, count: number) => per_count + count,
  );
