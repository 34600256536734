import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-grants.action";

import { IGrantsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { GRANTS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IViewResultsGrantsState {
  grants: IGrantsRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IViewResultsGrantsState = {
  grants: [],
  pending: false,
  error: null,

  count: 0,
};

const getViewResultsGrants: OnReducer<
  IViewResultsGrantsState,
  ActionType<any>
> = (state: IViewResultsGrantsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsGrantsError: OnReducer<
  IViewResultsGrantsState,
  ActionType<Payload<IServerError>>
> = (state: IViewResultsGrantsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsGrantsSuccess: OnReducer<
  IViewResultsGrantsState,
  ActionType<Payload<any>>
> = (state: IViewResultsGrantsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  grants: [...payload.items],
  count: payload.count,
});

const setViewResultsGrantsSpinnerState: OnReducer<
  IViewResultsGrantsState,
  ActionType<Payload<any>>
> = (state: IViewResultsGrantsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsGrantsState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsGrantsState> =
  createReducer<IViewResultsGrantsState>(
    initialState,

    on(actions.getViewResultsGrantsAction, getViewResultsGrants),
    on(actions.getViewResultsGrantsErrorAction, getViewResultsGrantsError),
    on(actions.getViewResultsGrantsSuccessAction, getViewResultsGrantsSuccess),

    on(
      actions.setViewResultsGrantsSpinnerStateAction,
      setViewResultsGrantsSpinnerState,
    ),

    on(actions.resetViewResultsGrantsStateAction, resetViewResultsGrantsState),
  );

export function viewResultsGrantsReducer(
  state: IViewResultsGrantsState,
  action: Action,
): IViewResultsGrantsState {
  return reducer(state, action);
}

export const grants: GetFromState<IGrantsRecord[], IViewResultsGrantsState> = (
  state: IViewResultsGrantsState,
): IGrantsRecord[] => state.grants;
export const grantsPending: GetFromState<boolean, IViewResultsGrantsState> = (
  state: IViewResultsGrantsState,
): boolean => state.pending;
export const grantsError: GetFromState<
  IServerError,
  IViewResultsGrantsState
> = (state: IViewResultsGrantsState): IServerError => state.error;

export const grantsCount: GetFromState<number, IViewResultsGrantsState> = (
  state: IViewResultsGrantsState,
): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const { sortBy }: Params = queryParams;

  const params: Params = {
    ...GRANTS_DEFAULT_PARAMS,
    ...queryParams,
    sortBy: sortBy || "year",
    viewId,
    filterBy: "grants",
  };

  return segmentId ? { ...params, segmentId } : params;
};
