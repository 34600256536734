import { SEATS } from "../interfaces/marketview";

export const SEAT_IDS: SEATS[] = [
  SEATS.Lite,
  SEATS.National,
  SEATS.State,
  SEATS.Trial,
];
export const SEAT_NATIONAL_ADD_ON_IDS: SEATS[] = [
  SEATS.BidsAddOn,
  SEATS.GrantsAddOn,
];
export const SEAT_STATE_ADD_ON_IDS: SEATS[] = [
  SEATS.BidsStateAddOn,
  SEATS.GrantsStateAddOn,
];
export const SEAT_NATIONAL_OR_STATE: SEATS[] = [SEATS.National, SEATS.State];
export const SEAT_NATIONAL_AND_STATE_ADDONS_BID: SEATS[] = [
  SEATS.BidsAddOn,
  SEATS.BidsStateAddOn,
];
export const SEAT_NATIONAL_AND_STATE_ADDONS_GRANT: SEATS[] = [
  SEATS.GrantsAddOn,
  SEATS.GrantsStateAddOn,
];
export const SEATS_BIDS_AND_GRANTS: SEATS[] = [
  ...SEAT_NATIONAL_AND_STATE_ADDONS_BID,
  ...SEAT_NATIONAL_AND_STATE_ADDONS_GRANT,
];

export const SEAT_NAMES: { [key: string]: string } = Object.freeze({
  [SEATS.Lite]: "Essential Seats",
  [SEATS.National]: "MarketView National Seats",
  [SEATS.State]: "MarketView State Seats",
  [SEATS.BidsAddOn]: "RFPs & IFBs (Add-on)",
  [SEATS.BidsStateAddOn]: "RFPs & IFBs (Add-on)",
  [SEATS.GrantsAddOn]: "Grants (Add-on)",
  [SEATS.GrantsStateAddOn]: "Grants (Add-on)",
  [SEATS.Trial]: "MarketView Trials",
});

export const CHECK_BOX_BID_NAME: string = "RFPs & IFBs (add-on)";
export const CHECK_BOX_GRANT_NAME: string = "Grants (add-on)";

export const DO_NOT_AVAILABLE_SEAT: string = "There are no available seats";
