import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-overview.action";

import { IProductCounter } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import {
  IViewOverviewResult,
  IViewResultsSegment,
} from "../../../view/interfaces";
import { IViewNavigation } from "../../interfaces";

export interface IViewResultsOverviewState {
  overview: IViewOverviewResult | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IViewResultsOverviewState = {
  overview: null,
  pending: false,
  error: null,
};

const getViewOverview: OnReducer<IViewResultsOverviewState, ActionType<any>> = (
  state: IViewResultsOverviewState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewOverviewError: OnReducer<
  IViewResultsOverviewState,
  ActionType<Payload<IServerError>>
> = (state: IViewResultsOverviewState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewOverviewSuccess: OnReducer<
  IViewResultsOverviewState,
  ActionType<Payload<any>>
> = (state: IViewResultsOverviewState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  overview: { ...payload },
});

const restViewResultsState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsOverviewState> =
  createReducer<IViewResultsOverviewState>(
    initialState,

    on(actions.getViewOverviewAction, getViewOverview),
    on(actions.getViewOverviewErrorAction, getViewOverviewError),
    on(actions.getViewOverviewSuccessAction, getViewOverviewSuccess),

    on(actions.resetViewResultsStateAction, restViewResultsState),
  );

export function viewResultsOverviewReducer(
  state: IViewResultsOverviewState,
  action: Action,
): IViewResultsOverviewState {
  return reducer(state, action);
}

export const overview: GetFromState<
  IViewOverviewResult | {},
  IViewResultsOverviewState
> = ({
  overview: _overview,
}: IViewResultsOverviewState): IViewOverviewResult | {} => _overview || {};
export const pending: GetFromState<boolean, IViewResultsOverviewState> = ({
  pending: _pending,
}: IViewResultsOverviewState): boolean => _pending;
export const overviewError: GetFromState<
  IServerError,
  IViewResultsOverviewState
> = ({ error: _error }: IViewResultsOverviewState): IServerError => _error;

export const overviewId: GetFromState<number, IViewOverviewResult> = ({
  id,
}: IViewOverviewResult): number => id;
export const overviewName: GetFromState<string, IViewOverviewResult> = ({
  name,
}: IViewOverviewResult): string => name;
export const navData: GetFromState<IViewNavigation, IViewOverviewResult> = ({
  previousViewId,
  nextViewId,
}: IViewOverviewResult): IViewNavigation => ({ previousViewId, nextViewId });

const ALL_SEGMENT_OPTION: IViewResultsSegment = {
  id: null,
  name: "All Segments",
} as IViewResultsSegment;

export const segmentId: GetFromState<number, Params> = ({
  segmentId: _segmentId,
}: Params): number => (_segmentId ? +_segmentId : null);
export const segments: GetFromState<
  IViewResultsSegment[],
  IViewOverviewResult
> = ({ segments: _segments }: IViewOverviewResult): IViewResultsSegment[] => [
  ALL_SEGMENT_OPTION,
  ..._segments,
];
export const selectedSegment: GetFromState<
  IViewResultsSegment | typeof ALL_SEGMENT_OPTION,
  IViewResultsSegment[],
  number
> = (
  _segments: IViewResultsSegment[],
  _segmentId: number,
): IViewResultsSegment | typeof ALL_SEGMENT_OPTION =>
  _segments.find(({ id }: IViewResultsSegment) => id === _segmentId) ||
  ALL_SEGMENT_OPTION;

export const routeUrl: GetFromState<string, string> = (url: string): string =>
  url;
export const routerParams: GetFromState<Params, Params> = (
  params: Params,
): Params => params;

export const counts: GetFromState<
  IProductCounter,
  IViewOverviewResult,
  IViewResultsSegment
> = (
  { count, grantsCount, institutionsCount, rfpIbfCount }: IViewOverviewResult,
  _selectedSegment: IViewResultsSegment,
): IProductCounter => {
  if (_selectedSegment.name === ALL_SEGMENT_OPTION.name) {
    return {
      count,
      grantsCount,
      institutionsCount,
      rfpIbfCount,
    };
  }
  return {
    count: _selectedSegment.personsCount,
    grantsCount: _selectedSegment.grantsCount,
    institutionsCount: _selectedSegment.institutionsCount,
    rfpIbfCount: _selectedSegment.rfpIbfCount,
  };
};

export const isHasAlerts: GetFromState<boolean, IViewOverviewResult> = (
  _overview: IViewOverviewResult,
): boolean => _overview.hasAlerts;
