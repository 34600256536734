import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from "@angular/core";

import { environment } from "@env/environment";

import { ITab, TABS_LIST_POSITIONING } from "../../interfaces";

import { TabItemComponent } from "../tab-item/tab-item.component";

@Component({
  selector: "bl-tabs-list",
  templateUrl: "./tabs-list.component.html",
  styleUrls: ["./tabs-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsListComponent implements AfterContentInit {
  @ContentChildren(TabItemComponent) public tabs: QueryList<ITab>;
  @Input() justifyContent: TABS_LIST_POSITIONING = TABS_LIST_POSITIONING.START;

  @Output() tabChange: EventEmitter<ITab> = new EventEmitter<ITab>();

  private selectedTabIndex: number;
  selectedTab: ITab;

  ngAfterContentInit(): void {
    // get all active tabs-list
    const activeTabs: ITab[] = this.tabs.filter((tab: ITab, index: number) => {
      tab.index = index;
      return tab.isActive;
    });

    if (activeTabs.length === 0) {
      // if there is no active tab-item set, activate the first
      this.selectTab(this.tabs.first);
    } else if (activeTabs.length === 1) {
      // if there is ine active tab-item set, activate by its index
      this.selectTab(activeTabs[0]);
    } else {
      // in case if few tabs were selected manually
      if (!environment.production) {
        console.error("There are few selected tabs!");
      }
      // deselect all tabs
      this.deselectAllTabs();
      // select first one
      this.selectTab(this.tabs.first);
    }
  }

  selectTab(tab: ITab): void {
    if (tab.isDisabled) {
      return;
    }
    const tabsArr: ITab[] = this.tabs.toArray();

    // deselect selected tab
    if (typeof this.selectedTabIndex !== "undefined") {
      tabsArr[this.selectedTabIndex].isActive = false;
    }

    // select new tab
    tab.isActive = true;
    this.selectedTab = tab;
    this.selectedTabIndex = tab.index;

    this.tabChange.emit(tab);
  }

  deselectAllTabs(): void {
    this.tabs.toArray().forEach((tab: ITab) => (tab.isActive = false));
  }
}
