import {
  animate,
  state,
  style,
  transition,
  trigger,
  AnimationTriggerMetadata,
} from "@angular/animations";

export function slideEffect(duration: number = 200): AnimationTriggerMetadata {
  return trigger("slideEffect", [
    state("initial", style({ transform: "translate3d(0,0,0)" })),

    state("top", style({ transform: "translate3d(0,-100%,0)" })),

    state("right", style({ transform: "translate3d(100%,0,0)" })),

    state("bottom", style({ transform: "translate3d(0,100%,0)" })),

    state("left", style({ transform: "translate3d(-100%,0,0)" })),

    transition("top<=>initial", animate(duration)),
    transition("right<=>initial", animate(duration)),
    transition("bottom<=>initial", animate(duration)),
    transition("left<=>initial", animate(duration)),

    transition(
      "initial => void",
      animate(duration, style({ transform: "translate3d(0,100%,0)" })),
    ),

    transition(
      "top => void",
      animate(duration, style({ transform: "translate3d(0,100%,0)" })),
    ),

    transition(
      "right => void",
      animate(duration, style({ transform: "translate3d(100%,0,0)" })),
    ),

    transition(
      "bottom => void",
      animate(duration, style({ transform: "translate3d(0,-100%,0)" })),
    ),

    transition(
      "left => void",
      animate(duration, style({ transform: "translate3d(-100%,0,0)" })),
    ),
  ]);
}
