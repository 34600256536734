import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IBrowseEntities,
  ILoadBrowseItemByCategoryPayload,
  ILoadBrowseItemByCategorySuccessPayload,
  ILoadBrowseItemByDisciplinePayload,
  ILoadBrowseItemByDisciplineSuccessPayload,
  ILoadBrowseItemByInstitutionPayload,
  ILoadBrowseItemByInstitutionSuccessPayload,
  ILoadBrowseItemBySubjectPayload,
  ILoadBrowseItemBySubjectSuccessPayload,
} from "../../interfaces/browse";
import { IJob } from "../../interfaces/jobs";

enum ACTION_TYPES {
  LOAD_INSTITUTION_TYPES_FOR_CATEGORY = "[Segment] Load Institution types For Category",
  RESET_INSTITUTION_TYPES_FOR_CATEGORY = "[Segment] Reset Institution types For Category",
  LOAD_INSTITUTION_TYPES_FOR_CATEGORY_SUCCESS = "[Segment] Load Institution types For Category Success",

  LOAD_CATEGORIES_BY_INSTITUTION_TYPE = "[Segment] Load Categories By Institution Type",
  RESET_CATEGORIES_BY_INSTITUTION_TYPE = "[Segment] Reset Categories By Institution Type",
  LOAD_CATEGORIES_BY_INSTITUTION_TYPE_SUCCESS = "[Segment] Load Categories By Institution Type Success",

  LOAD_JOBS_BY_CATEGORY = "[Segment] Load Jobs By Category",
  RESET_JOBS_BY_CATEGORY = "[Segment] Reset Jobs By Category",
  LOAD_JOBS_BY_CATEGORY_SUCCESS = "[Segment] Load Jobs By Category Success",

  LOAD_SUBJECTS_BY_DISCIPLINE = "[Segment] Load Subjects By Discipline",
  RESET_SUBJECTS_BY_DISCIPLINE = "[Segment] Reset Subjects By Discipline",
  LOAD_SUBJECTS_BY_DISCIPLINE_SUCCESS = "[Segment] Load Subjects By Discipline Success",

  LOAD_JOBS_BY_SUBJECT = "[Segment] Load Jobs By Subject",
  RESET_JOBS_BY_SUBJECT = "[Segment] Reset Jobs By Subject",
  LOAD_JOBS_BY_SUBJECT_SUCCESS = "[Segment] Load Jobs By Subject Success",

  LOAD_INSTITUTION_TYPES_FOR_ALPHABET = "[Segment] Load Institution types For Alphabet",
  RESET_INSTITUTION_TYPES_FOR_ALPHABET = "[Segment] Reset Institution types For Alphabet",
  LOAD_INSTITUTION_TYPES_FOR_ALPHABET_SUCCESS = "[Segment] Load Institution types For Alphabet Success",

  LOAD_ALPHABET_BY_INSTITUTION_TYPE = "[Segment] Load Alphabet By Institution Type",
  RESET_ALPHABET_BY_INSTITUTION_TYPE = "[Segment] Reset Alphabet By Institution Type",
  LOAD_ALPHABET_BY_INSTITUTION_TYPE_SUCCESS = "[Segment] Load Alphabet By Institution Type Success",

  LOAD_JOBS_BY_ALPHABET = "[Segment] Load Jobs By Alphabet",
  RESET_JOBS_BY_ALPHABET = "[Segment] Reset Jobs By Alphabet",
  LOAD_JOBS_BY_ALPHABET_SUCCESS = "[Segment] Load Jobs By Alphabet Success",

  JOBS_BROWSE_LOADING_ERROR = "[Segment] Jobs Browse Loading Error",

  SHOW_JOB_IN_BROWSE = "[Segment] Show current job in browse",

  RESET_JOBS_BROWSE_STATE = "[Segment] Reset Jobs Browse State",

  TOGGLE_JOBS_BROWSE = "[Segment] Toggle Jobs Browse",
}

export const loadInstitutionTypesForCategoryAction: CreateActionType<
  ACTION_TYPES.LOAD_INSTITUTION_TYPES_FOR_CATEGORY,
  boolean
> = createAction(
  ACTION_TYPES.LOAD_INSTITUTION_TYPES_FOR_CATEGORY,
  createPayload<boolean>(false),
);
export const resetInstitutionTypesForCategoryAction: ActionCreator<
  ACTION_TYPES.RESET_INSTITUTION_TYPES_FOR_CATEGORY,
  () => TypedAction<ACTION_TYPES.RESET_INSTITUTION_TYPES_FOR_CATEGORY>
> = createAction(ACTION_TYPES.RESET_INSTITUTION_TYPES_FOR_CATEGORY);
export const loadInstitutionTypesForCategorySuccessAction: CreateActionType<
  ACTION_TYPES.LOAD_INSTITUTION_TYPES_FOR_CATEGORY_SUCCESS,
  IBrowseEntities
> = createAction(
  ACTION_TYPES.LOAD_INSTITUTION_TYPES_FOR_CATEGORY_SUCCESS,
  createPayload<IBrowseEntities>(),
);

export const loadCategoriesByInstitutionTypeAction: CreateActionType<
  ACTION_TYPES.LOAD_CATEGORIES_BY_INSTITUTION_TYPE,
  ILoadBrowseItemByInstitutionPayload
> = createAction(
  ACTION_TYPES.LOAD_CATEGORIES_BY_INSTITUTION_TYPE,
  createPayload<ILoadBrowseItemByInstitutionPayload>(),
);
export const resetCategoriesByInstitutionTypeAction: ActionCreator<
  ACTION_TYPES.RESET_CATEGORIES_BY_INSTITUTION_TYPE,
  () => TypedAction<ACTION_TYPES.RESET_CATEGORIES_BY_INSTITUTION_TYPE>
> = createAction(ACTION_TYPES.RESET_CATEGORIES_BY_INSTITUTION_TYPE);
export const loadCategoriesByInstitutionTypeSuccessAction: CreateActionType<
  ACTION_TYPES.LOAD_CATEGORIES_BY_INSTITUTION_TYPE_SUCCESS,
  ILoadBrowseItemByInstitutionSuccessPayload
> = createAction(
  ACTION_TYPES.LOAD_CATEGORIES_BY_INSTITUTION_TYPE_SUCCESS,
  createPayload<ILoadBrowseItemByInstitutionSuccessPayload>(),
);

export const loadJobsByCategoryAction: CreateActionType<
  ACTION_TYPES.LOAD_JOBS_BY_CATEGORY,
  ILoadBrowseItemByCategoryPayload
> = createAction(
  ACTION_TYPES.LOAD_JOBS_BY_CATEGORY,
  createPayload<ILoadBrowseItemByCategoryPayload>(),
);
export const resetJobsByCategoryAction: ActionCreator<
  ACTION_TYPES.RESET_JOBS_BY_CATEGORY,
  () => TypedAction<ACTION_TYPES.RESET_JOBS_BY_CATEGORY>
> = createAction(ACTION_TYPES.RESET_JOBS_BY_CATEGORY);
export const loadJobsByCategorySuccessAction: CreateActionType<
  ACTION_TYPES.LOAD_JOBS_BY_CATEGORY_SUCCESS,
  ILoadBrowseItemByCategorySuccessPayload
> = createAction(
  ACTION_TYPES.LOAD_JOBS_BY_CATEGORY_SUCCESS,
  createPayload<ILoadBrowseItemByCategorySuccessPayload>(),
);

export const loadSubjectsByDisciplineAction: CreateActionType<
  ACTION_TYPES.LOAD_SUBJECTS_BY_DISCIPLINE,
  ILoadBrowseItemByDisciplinePayload
> = createAction(
  ACTION_TYPES.LOAD_SUBJECTS_BY_DISCIPLINE,
  createPayload<ILoadBrowseItemByDisciplinePayload>(),
);
export const resetSubjectsByDisciplineAction: ActionCreator<
  ACTION_TYPES.RESET_SUBJECTS_BY_DISCIPLINE,
  () => TypedAction<ACTION_TYPES.RESET_SUBJECTS_BY_DISCIPLINE>
> = createAction(ACTION_TYPES.RESET_SUBJECTS_BY_DISCIPLINE);
export const loadSubjectsByDisciplineSuccessAction: CreateActionType<
  ACTION_TYPES.LOAD_SUBJECTS_BY_DISCIPLINE_SUCCESS,
  ILoadBrowseItemByDisciplineSuccessPayload
> = createAction(
  ACTION_TYPES.LOAD_SUBJECTS_BY_DISCIPLINE_SUCCESS,
  createPayload<ILoadBrowseItemByDisciplineSuccessPayload>(),
);

export const loadJobsBySubjectAction: CreateActionType<
  ACTION_TYPES.LOAD_JOBS_BY_SUBJECT,
  ILoadBrowseItemBySubjectPayload
> = createAction(
  ACTION_TYPES.LOAD_JOBS_BY_SUBJECT,
  createPayload<ILoadBrowseItemBySubjectPayload>(),
);
export const resetJobsBySubjectAction: ActionCreator<
  ACTION_TYPES.RESET_JOBS_BY_SUBJECT,
  () => TypedAction<ACTION_TYPES.RESET_JOBS_BY_SUBJECT>
> = createAction(ACTION_TYPES.RESET_JOBS_BY_SUBJECT);
export const loadJobsBySubjectSuccessAction: CreateActionType<
  ACTION_TYPES.LOAD_JOBS_BY_SUBJECT_SUCCESS,
  ILoadBrowseItemBySubjectSuccessPayload
> = createAction(
  ACTION_TYPES.LOAD_JOBS_BY_SUBJECT_SUCCESS,
  createPayload<ILoadBrowseItemBySubjectSuccessPayload>(),
);

export const loadInstitutionTypesForAlphabetAction: CreateActionType<
  ACTION_TYPES.LOAD_INSTITUTION_TYPES_FOR_ALPHABET,
  { isView: boolean; reset?: boolean }
> = createAction(
  ACTION_TYPES.LOAD_INSTITUTION_TYPES_FOR_ALPHABET,
  createPayload<{ isView: boolean; reset?: boolean }>(),
);
export const resetInstitutionTypesForAlphabetAction: ActionCreator<
  ACTION_TYPES.RESET_INSTITUTION_TYPES_FOR_ALPHABET,
  () => TypedAction<ACTION_TYPES.RESET_INSTITUTION_TYPES_FOR_ALPHABET>
> = createAction(ACTION_TYPES.RESET_INSTITUTION_TYPES_FOR_ALPHABET);
export const loadInstitutionTypesForAlphabetSuccessAction: CreateActionType<
  ACTION_TYPES.LOAD_INSTITUTION_TYPES_FOR_ALPHABET_SUCCESS,
  IBrowseEntities
> = createAction(
  ACTION_TYPES.LOAD_INSTITUTION_TYPES_FOR_ALPHABET_SUCCESS,
  createPayload<IBrowseEntities>(),
);

export const loadAlphabetByInstitutionTypeAction: CreateActionType<
  ACTION_TYPES.LOAD_ALPHABET_BY_INSTITUTION_TYPE,
  { institutionType: string; segmentCriteriaId: number }
> = createAction(
  ACTION_TYPES.LOAD_ALPHABET_BY_INSTITUTION_TYPE,
  createPayload<{ institutionType: string; segmentCriteriaId: number }>(),
);
export const resetAlphabetByInstitutionTypeAction: ActionCreator<
  ACTION_TYPES.RESET_ALPHABET_BY_INSTITUTION_TYPE,
  () => TypedAction<ACTION_TYPES.RESET_ALPHABET_BY_INSTITUTION_TYPE>
> = createAction(ACTION_TYPES.RESET_ALPHABET_BY_INSTITUTION_TYPE);
export const loadAlphabetByInstitutionTypeSuccessAction: CreateActionType<
  ACTION_TYPES.LOAD_ALPHABET_BY_INSTITUTION_TYPE_SUCCESS,
  IBrowseEntities
> = createAction(
  ACTION_TYPES.LOAD_ALPHABET_BY_INSTITUTION_TYPE_SUCCESS,
  createPayload<IBrowseEntities>(),
);

export const loadJobsByAlphabetAction: CreateActionType<
  ACTION_TYPES.LOAD_JOBS_BY_ALPHABET,
  {
    query: string;
    institutionType: string;
    segmentCriteriaId: number;
    reset?: boolean;
  }
> = createAction(
  ACTION_TYPES.LOAD_JOBS_BY_ALPHABET,
  createPayload<{
    query: string;
    institutionType: string;
    segmentCriteriaId: number;
    reset?: boolean;
  }>(),
);
export const resetJobsByAlphabetAction: ActionCreator<
  ACTION_TYPES.RESET_JOBS_BY_ALPHABET,
  () => TypedAction<ACTION_TYPES.RESET_JOBS_BY_ALPHABET>
> = createAction(ACTION_TYPES.RESET_JOBS_BY_ALPHABET);
export const loadJobsByAlphabetSuccessAction: CreateActionType<
  ACTION_TYPES.LOAD_JOBS_BY_ALPHABET_SUCCESS,
  IBrowseEntities
> = createAction(
  ACTION_TYPES.LOAD_JOBS_BY_ALPHABET_SUCCESS,
  createPayload<IBrowseEntities>(),
);

export const showJobInBrowseAction: CreateActionType<
  ACTION_TYPES.SHOW_JOB_IN_BROWSE,
  { segmentCriteriaId: number; job: IJob }
> = createAction(
  ACTION_TYPES.SHOW_JOB_IN_BROWSE,
  createPayload<{ segmentCriteriaId: number; job: IJob }>(),
);

export const jobsBrowseLoadingErrorAction: CreateActionType<
  ACTION_TYPES.JOBS_BROWSE_LOADING_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.JOBS_BROWSE_LOADING_ERROR,
  createPayload<IServerError>(),
);

export const toggleJobsBrowseAction: CreateActionType<
  ACTION_TYPES.TOGGLE_JOBS_BROWSE,
  boolean
> = createAction(ACTION_TYPES.TOGGLE_JOBS_BROWSE, createPayload<boolean>());
export const resetJobsBrowseStateAction: ActionCreator<
  ACTION_TYPES.RESET_JOBS_BROWSE_STATE,
  () => TypedAction<ACTION_TYPES.RESET_JOBS_BROWSE_STATE>
> = createAction(ACTION_TYPES.RESET_JOBS_BROWSE_STATE);
