import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-lists-search.action";

export interface IDashboardListsQueryState {
  query: string;
  isHasQuery: boolean;

  isOpen: boolean;
}

const initialState: IDashboardListsQueryState = {
  query: null,
  isHasQuery: false,

  isOpen: false,
};

const openDashboardListsSearch: OnReducer<
  IDashboardListsQueryState,
  ActionType<any>
> = (state: IDashboardListsQueryState) => ({
  ...state,
  isOpen: true,
});

const closeDashboardListsSearch: OnReducer<
  IDashboardListsQueryState,
  ActionType<any>
> = (state: IDashboardListsQueryState) => ({
  ...state,
  query: null,
  isOpen: false,
});

const updateDashboardListsSearch: OnReducer<
  IDashboardListsQueryState,
  ActionType<Payload<any>>
> = (state: IDashboardListsQueryState, { payload }: Payload<any>) => ({
  ...state,
  query: payload,
});

const updateDashboardListsSearchSuccess: OnReducer<
  IDashboardListsQueryState,
  ActionType<any>
> = (state: IDashboardListsQueryState) => ({
  ...state,
  isHasQuery: !!state.query,
});

const resetDashboardListsSearch: OnReducer<
  IDashboardListsQueryState,
  ActionType<any>
> = (state: IDashboardListsQueryState) => ({
  ...state,
  query: null,
});

const resetDashboardListsSearchWithoutReload: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDashboardListsQueryState> =
  createReducer<IDashboardListsQueryState>(
    initialState,
    on(actions.openDashboardListsSearchAction, openDashboardListsSearch),
    on(actions.closeDashboardListsSearchAction, closeDashboardListsSearch),

    on(actions.updateDashboardListsSearchAction, updateDashboardListsSearch),
    on(
      actions.updateDashboardListsSearchSuccessAction,
      updateDashboardListsSearchSuccess,
    ),

    on(actions.resetDashboardListsSearchAction, resetDashboardListsSearch),

    on(
      actions.resetDashboardListsSearchWithoutReloadAction,
      resetDashboardListsSearchWithoutReload,
    ),
  );

export const query: GetFromState<string, IDashboardListsQueryState> = (
  state: IDashboardListsQueryState,
): string => state.query;
export const isHasQuery: GetFromState<boolean, IDashboardListsQueryState> = (
  state: IDashboardListsQueryState,
): boolean => state.isHasQuery;
export const isOpen: GetFromState<boolean, IDashboardListsQueryState> = (
  state: IDashboardListsQueryState,
): boolean => state.isOpen;

export function dashboardListsSearchReducer(
  state: IDashboardListsQueryState,
  action: Action,
): IDashboardListsQueryState {
  return reducer(state, action);
}
