import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { IWelcomeWithMarketViewPopUp } from "../../interfaces";

import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-welcome-with-market-view-pop-up-content",
  templateUrl: "./welcome-with-market-view-pop-up-content.component.html",
  styleUrls: ["./welcome-with-market-view-pop-up-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WelcomeWithMarketViewPopUpContentComponent {
  constructor(
    private popUpRef: PopUpRef<WelcomeWithMarketViewPopUpContentComponent>,
    @Inject(POP_UP_DATA) public data: IWelcomeWithMarketViewPopUp,
  ) {}

  onConfirm(): void {
    this.popUpRef.close({ answer: true });
  }

  onCancel(): void {
    this.popUpRef.close({ answer: false });
  }
}
