import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { UserPaymentAddressConverter } from "@shared/utils/payment-mapper";
import { pluckAndCatch } from "@shared/utils/response-formater";

import {
  ICheckAddressResponse,
  IUserAddress,
} from "@modules/auth/interfaces/user";
import { INewCreditCardAddress } from "@modules/e-commerce/interfaces/e-commerce";

@Injectable()
export class AsyncValidatorService {
  constructor(private http: HttpClient) {}

  paymentAddress = (
    address: INewCreditCardAddress,
  ): Observable<ICheckAddressResponse | null> => {
    const validateAddress: IUserAddress =
      UserPaymentAddressConverter.convertPaymentToValidationAddress(address);

    return this.address(validateAddress).pipe(
      map(({ valid, errorMessages, suspectAddress }: ICheckAddressResponse) => {
        if (!valid) {
          return {
            valid,
            errorMessages,
            suspectAddress: suspectAddress
              ? UserPaymentAddressConverter.convertValidationAddressToPaymentAddress(
                  suspectAddress,
                )
              : null,
          } as ICheckAddressResponse;
        }

        return null;
      }),
    );
  };

  address = (
    address: IUserAddress,
  ): Observable<ICheckAddressResponse | null> => {
    return this.http
      .post(environment.api.validation.address, address)
      .pipe(pluckAndCatch);
  };
}
