import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-personnel-colleges.action";

import { IPersonnelRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { PERSONNEL_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IViewResultsPersonnelCollegeState {
  colleges: IPersonnelRecord[];
  pending: boolean;
  error: IServerError | null;

  selectedCollegesIds: number[];

  count: number;
}

const initialState: IViewResultsPersonnelCollegeState = {
  colleges: [],
  pending: false,
  error: null,

  selectedCollegesIds: [],

  count: 0,
};

const getViewResultsPersonnelColleges: OnReducer<
  IViewResultsPersonnelCollegeState,
  ActionType<any>
> = (state: IViewResultsPersonnelCollegeState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsPersonnelCollegesError: OnReducer<
  IViewResultsPersonnelCollegeState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsPersonnelCollegeState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsPersonnelCollegesSuccess: OnReducer<
  IViewResultsPersonnelCollegeState,
  ActionType<Payload<any>>
> = (state: IViewResultsPersonnelCollegeState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  colleges: [...payload.items],
  count: payload.count,
});

const toggleViewResultsPersonnelCollege: OnReducer<
  IViewResultsPersonnelCollegeState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsPersonnelCollegeState,
  { payload: { id } }: Payload<any>,
) => {
  const _setArray: Set<number> = new Set(state.selectedCollegesIds);

  _setArray.has(id) ? _setArray.delete(id) : _setArray.add(id);

  return {
    ...state,
    selectedCollegesIds: Array.from(_setArray),
  };
};

const setViewResultsPersonnelCollegesSpinnerState: OnReducer<
  IViewResultsPersonnelCollegeState,
  ActionType<Payload<any>>
> = (state: IViewResultsPersonnelCollegeState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetSelectedPersonnelColleges: OnReducer<
  IViewResultsPersonnelCollegeState,
  ActionType<any>
> = (state: IViewResultsPersonnelCollegeState) => ({
  ...state,
  selectedCollegesIds: [],
});

const resetViewResultsPersonnelCollegesState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsPersonnelCollegeState> =
  createReducer<IViewResultsPersonnelCollegeState>(
    initialState,

    on(
      actions.getViewResultsPersonnelCollegesAction,
      getViewResultsPersonnelColleges,
    ),
    on(
      actions.getViewResultsPersonnelCollegesErrorAction,
      getViewResultsPersonnelCollegesError,
    ),
    on(
      actions.getViewResultsPersonnelCollegesSuccessAction,
      getViewResultsPersonnelCollegesSuccess,
    ),

    on(
      actions.toggleViewResultsPersonnelCollegeAction,
      toggleViewResultsPersonnelCollege,
    ),

    on(
      actions.setViewResultsPersonnelCollegesSpinnerStateAction,
      setViewResultsPersonnelCollegesSpinnerState,
    ),

    on(
      actions.resetSelectedPersonnelCollegesAction,
      resetSelectedPersonnelColleges,
    ),

    on(
      actions.resetViewResultsPersonnelCollegesStateAction,
      resetViewResultsPersonnelCollegesState,
    ),
  );

export function viewResultsPersonnelCollegesReducer(
  state: IViewResultsPersonnelCollegeState,
  action: Action,
): IViewResultsPersonnelCollegeState {
  return reducer(state, action);
}

export const colleges: GetFromState<
  IPersonnelRecord[],
  IViewResultsPersonnelCollegeState
> = (state: IViewResultsPersonnelCollegeState): IPersonnelRecord[] =>
  state.colleges;
export const pending: GetFromState<
  boolean,
  IViewResultsPersonnelCollegeState
> = (state: IViewResultsPersonnelCollegeState): boolean => state.pending;
export const collegesError: GetFromState<
  IServerError,
  IViewResultsPersonnelCollegeState
> = (state: IViewResultsPersonnelCollegeState): IServerError => state.error;

export const collegesPaginationCount: GetFromState<
  number,
  IViewResultsPersonnelCollegeState
> = (state: IViewResultsPersonnelCollegeState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const { sortBy }: Params = queryParams;

  const params: Params = {
    ...PERSONNEL_DEFAULT_PARAMS,
    ...queryParams,
    sortBy: sortBy || "lastName",
    viewId,
    filterBy: "colleges",
  };

  return segmentId ? { ...params, segmentId } : params;
};

export const selectedPersonnelCollegesIds: GetFromState<
  number[],
  IViewResultsPersonnelCollegeState
> = (state: IViewResultsPersonnelCollegeState): number[] =>
  state.selectedCollegesIds;
