import { Pipe, PipeTransform } from "@angular/core";
import { Router } from "@angular/router";

@Pipe({
  name: "blShouldShowMobileFooterPipe",
  pure: true,
})
export class ShouldShowMobileFooterPipePipe implements PipeTransform {
  constructor(private _router: Router) {}

  transform(mobileFooterRoutes: Array<string>): boolean {
    const currentUrl: string = this._router.url;

    return mobileFooterRoutes.reduce(
      (res: boolean, path: string) => currentUrl.includes(path) || res,
      false,
    );
  }
}
