import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/payment-cards.action";
import { getAllBillingInfoSuccessAction } from "../actions/payment.action";

import {
  ICreditCardInfo,
  INewCreditCartInfo,
} from "../../interfaces/e-commerce";

export interface ICreditCardsState {
  creditCards: ICreditCardInfo[];

  selectedCreditCard: ICreditCardInfo | null;

  newCreditCard: INewCreditCartInfo | null;
  newCreditCardSelected: boolean;
}

const initialState: ICreditCardsState = {
  creditCards: [],

  selectedCreditCard: null,

  newCreditCard: null,
  newCreditCardSelected: false,
};

const getCreditCardsSuccess: OnReducer<
  ICreditCardsState,
  ActionType<Payload<any>>
> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  creditCards: [...reOrderedCreditCards(payload)],
  newCreditCardSelected: !payload.length,
});

const addNewCreditCardSuccess: OnReducer<
  ICreditCardsState,
  ActionType<Payload<any>>
> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  newCreditCard: { ...payload },
  selectedCreditCard: null,
  newCreditCardSelected: true,
});

const selectDefaultCreditCard: OnReducer<
  ICreditCardsState,
  ActionType<Payload<any>>
> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  selectedCreditCard: { ...payload },
  newCreditCardSelected: false,
});

const selectNewCreditCard: OnReducer<ICreditCardsState, ActionType<any>> = (
  state: ICreditCardsState,
) => ({
  ...state,
  selectedCreditCard: null,
  newCreditCardSelected: true,
});

const editCreditCardSuccess: OnReducer<
  ICreditCardsState,
  ActionType<Payload<any>>
> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  selectedCreditCard:
    state.selectedCreditCard.payment_token_identifier ===
    payload.payment_token_identifier
      ? payload
      : { ...state.selectedCreditCard },
  creditCards: state.creditCards.map((creditCard: ICreditCardInfo) =>
    creditCard.payment_token_identifier === payload.payment_token_identifier
      ? payload
      : creditCard,
  ),
});

const editNewCreditCardSuccess: OnReducer<
  ICreditCardsState,
  ActionType<Payload<any>>
> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  newCreditCard: { ...payload },
});

const getAllBillingInfoSuccess: OnReducer<
  ICreditCardsState,
  ActionType<Payload<any>>
> = (state: ICreditCardsState, { payload }: Payload<any>) => ({
  ...state,
  creditCards: [...payload.credit_card_details],
  newCreditCardSelected: !payload.credit_card_details.length,
});

const resetCreditCards: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICreditCardsState> =
  createReducer<ICreditCardsState>(
    initialState,

    on(actions.getCreditCardsSuccessAction, getCreditCardsSuccess),
    on(actions.addNewCreditCardSuccessAction, addNewCreditCardSuccess),
    on(actions.selectDefaultCreditCardAction, selectDefaultCreditCard),

    on(actions.selectNewCreditCardAction, selectNewCreditCard),
    on(actions.editCreditCardSuccessAction, editCreditCardSuccess),
    on(actions.editNewCreditCardSuccessAction, editNewCreditCardSuccess),

    on(getAllBillingInfoSuccessAction, getAllBillingInfoSuccess),

    on(actions.resetCreditCardsAction, resetCreditCards),
  );

export function creditCardsReducer(
  state: ICreditCardsState,
  action: Action,
): ICreditCardsState {
  return reducer(state, action);
}

export const creditCards: GetFromState<ICreditCardInfo[], ICreditCardsState> = (
  state: ICreditCardsState,
): ICreditCardInfo[] => {
  return state && state.creditCards;
};

export const selectedCreditCard: GetFromState<
  ICreditCardInfo,
  ICreditCardsState
> = (state: ICreditCardsState): ICreditCardInfo =>
  state && state.selectedCreditCard;

export const newCreditCard: GetFromState<
  INewCreditCartInfo,
  ICreditCardsState
> = (state: ICreditCardsState): INewCreditCartInfo => {
  if (state && state.newCreditCard) {
    // removing date for only month and year are needed to compare
    const date = new Date(new Date().getFullYear(), new Date().getMonth());
    const creditExpirationDate = new Date(state.newCreditCard.card_expiry_date);
    const dateRemovedExpirationDate = new Date(
      creditExpirationDate.getFullYear(),
      creditExpirationDate.getMonth(),
    );
    state.newCreditCard.expired = date > dateRemovedExpirationDate;
    return state && state.newCreditCard;
  }
  return state && state.newCreditCard;
};
export const newCreditCardSelected: GetFromState<boolean, ICreditCardsState> = (
  state: ICreditCardsState,
): boolean => state && state.newCreditCardSelected;

export function reOrderedCreditCards(
  cards: ICreditCardInfo[],
): ICreditCardInfo[] {
  // sorting dates based on expiration Date

  const date = new Date();

  cards.sort((a, b) => {
    const bDate = new Date(b.card_expiration_date);
    const aDate = new Date(a.card_expiration_date);
    return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
  });

  cards.map((creditCard) => {
    const creditExpirationDate = new Date(creditCard.card_expiration_date);
    creditCard.expired = date > creditExpirationDate;
    return creditCard;
  });

  return cards;
}
