import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { IOmsModuleState } from "../reducers";
import { platforms, IOmsState } from "../reducers/oms.reducer";
import * as oms from "../reducers/oms.reducer";

import {
  IOmsFields,
  IOmsOAuthByRedirectionSocketResponse,
  IOmsPlatform,
  IOmsPlatforms,
} from "@modules/oms/interfaces";
import { IServerError } from "@shared/interfaces/server-error";

import { selectOmsModuleState } from "./index";

export const selectOmsState: MemoizedSelector<CoreState, IOmsState> =
  createSelector(selectOmsModuleState, (state: IOmsModuleState) => state.oms);

export const getOmsPlatforms: MemoizedSelector<CoreState, IOmsPlatforms> =
  createSelector(selectOmsState, platforms);
export const getSelectedPlatformId: MemoizedSelector<CoreState, number> =
  createSelector(selectOmsState, oms.selectedPlatformId);
export const getSelectedPlatform: MemoizedSelector<CoreState, IOmsPlatform> =
  createSelector(getOmsPlatforms, getSelectedPlatformId, oms.selectedPlatform);

export const getAvailablePlatformFields: MemoizedSelector<
  CoreState,
  IOmsFields
> = createSelector(selectOmsState, oms.availablePlatformFields);
export const getSandbox: MemoizedSelector<CoreState, boolean> = createSelector(
  selectOmsState,
  oms.sandbox,
);

export const getOmsPlatformAuthLoading: MemoizedSelector<CoreState, boolean> =
  createSelector(selectOmsState, oms.platformAuthLoading);
export const getOmsPlatformAuthError: MemoizedSelector<
  CoreState,
  IServerError
> = createSelector(selectOmsState, oms.platformAuthError);

export const getAuthBySocketLoading: MemoizedSelector<CoreState, boolean> =
  createSelector(selectOmsState, oms.authBySocketLoading);

export const getOAuthByRedirectionData: MemoizedSelector<
  CoreState,
  IOmsOAuthByRedirectionSocketResponse
> = createSelector(selectOmsState, oms.oAuthByRedirectionData);
