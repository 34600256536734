import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { formatExpDate } from "@shared/utils/converters";

import {
  IAddress,
  ICreditCardInfo,
} from "../../../e-commerce/interfaces/e-commerce";

@Component({
  selector: "bl-credit-card-details",
  templateUrl: "./credit-card-details.component.html",
  styleUrls: ["./credit-card-details.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditCardDetailsComponent implements OnInit {
  @Input() cardInfo: ICreditCardInfo;

  form: UntypedFormGroup;

  isOpened: boolean = false;

  get address(): IAddress {
    return this.cardInfo.address;
  }

  get expDate(): string {
    return formatExpDate(this.cardInfo.card_expiration_date);
  }

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      card_number: `**** **** **** ${this.cardInfo.last_four_digits}`,
      card_expiry_date: formatExpDate(
        this.cardInfo.card_expiration_date,
        true,
        true,
      ),
      card_cvn: "***",
    });
  }

  toggle(): void {
    this.isOpened = !this.isOpened;
    this.cdr.detectChanges();
  }
}
