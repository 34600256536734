import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cdl-list-order-summary.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IOrderCounts } from "../../interfaces";

export interface ICDLOrderSummaryState {
  id: number | null;
  orderCounts: IOrderCounts | null;
  loading: boolean;
  error: IServerError | null;
}

const initialState: ICDLOrderSummaryState = {
  id: null,
  orderCounts: null,
  loading: false,
  error: null,
};

const getCDLOrderCounts: OnReducer<ICDLOrderSummaryState, ActionType<any>> = (
  state: ICDLOrderSummaryState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getCDLOrderCountsError: OnReducer<
  ICDLOrderSummaryState,
  ActionType<Payload<IServerError>>
> = (state: ICDLOrderSummaryState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: payload,
});

const getCDLOrderCountsSuccess: OnReducer<
  ICDLOrderSummaryState,
  ActionType<Payload<any>>
> = (
  state: ICDLOrderSummaryState,
  { payload: { data, id } }: Payload<any>,
) => ({
  ...state,
  loading: false,
  orderCounts: data,
  id,
});

const resetState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLOrderSummaryState> =
  createReducer<ICDLOrderSummaryState>(
    initialState,

    on(actions.getCDLOrderCountsAction, getCDLOrderCounts),
    on(actions.getCDLOrderCountsErrorAction, getCDLOrderCountsError),
    on(actions.getCDLOrderCountsSuccessAction, getCDLOrderCountsSuccess),

    on(actions.resetOrderSummaryStateAction, resetState),
  );

export function cdlListOrderSummaryReducer(
  state: ICDLOrderSummaryState,
  action: Action,
): ICDLOrderSummaryState {
  return reducer(state, action);
}

export const listId: GetFromState<number, ICDLOrderSummaryState> = (
  state: ICDLOrderSummaryState,
): number => state && state.id;
export const orderCounts: GetFromState<IOrderCounts, ICDLOrderSummaryState> = (
  state: ICDLOrderSummaryState,
): IOrderCounts => state && state.orderCounts;
export const loading: GetFromState<boolean, ICDLOrderSummaryState> = (
  state: ICDLOrderSummaryState,
): boolean => state && state.loading;
