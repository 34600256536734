import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "oneOrMany",
})
export class OneOrManyPipe implements PipeTransform {
  transform(value: number): string {
    return value > 1 ? "s" : "";
  }
}
