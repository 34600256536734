import { AnimationTriggerMetadata } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
} from "@angular/core";

import { fadeInOut } from "@ui/animations/fadeInOut";

@Component({
  selector: "bl-overlay",
  templateUrl: "./overlay.component.html",
  styleUrls: ["./overlay.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeInOut],
})
export class OverlayComponent {
  @HostBinding("@fadeInOut") animation: AnimationTriggerMetadata;

  constructor(public elementRef: ElementRef) {}
}
