import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/school-profile.action";

import { MVEntityAvailable } from "../../../profile/utils/mv-entity-available";

import { IServerError } from "@shared/interfaces/server-error";
import { IBids } from "@shared/modules/bids-entity/interfaces";
import {
  IGrants,
  IParentProfile,
  IProfileAddress,
} from "@shared/modules/institution-profiles/interfaces/profiles";
import { IMVEntityAvailable } from "../../../profile/interfaces/mv-entity-available";
import { IUserPermissions } from "../../../profile/interfaces/profile";
import {
  IComputers,
  IEthnicity,
  ISchool,
  ISchoolDistrictInfo,
  ISchoolHeader,
  ISchoolInfo,
} from "../../interfaces/school-profile";

import { PermissionsKeys } from "@core/constants/permissions";
import { HIDDEN_BY } from "@shared/modules/institution-profiles/constants/profiles";

export interface ISchoolProfileState {
  school: ISchool | null;
  loading: boolean;
  error: IServerError | null;
}

const initialState: ISchoolProfileState = {
  school: null,
  loading: false,
  error: null,
};

const getSchoolProfile: OnReducer<ISchoolProfileState, ActionType<any>> = (
  state: ISchoolProfileState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getSchoolProfileError: OnReducer<
  ISchoolProfileState,
  ActionType<Payload<IServerError>>
> = (state: ISchoolProfileState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getSchoolProfileSuccess: OnReducer<
  ISchoolProfileState,
  ActionType<Payload<any>>
> = (state: ISchoolProfileState, { payload }: Payload<any>) => ({
  ...state,
  school: { ...payload.data },
  loading: false,
  error: null,
});

const resetSchoolProfile: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ISchoolProfileState> =
  createReducer<ISchoolProfileState>(
    initialState,

    on(actions.getSchoolProfileAction, getSchoolProfile),
    on(actions.getSchoolProfileErrorAction, getSchoolProfileError),
    on(actions.getSchoolProfileSuccessAction, getSchoolProfileSuccess),

    on(actions.resetSchoolProfileAction, resetSchoolProfile),
  );

export function schoolProfileReducer(
  state: ISchoolProfileState,
  action: Action,
): ISchoolProfileState {
  return reducer(state, action);
}

export const schoolProfile: GetFromState<
  ISchool | null,
  ISchoolProfileState
> = (state: ISchoolProfileState): ISchool | null => state.school;
export const loading: GetFromState<boolean, ISchoolProfileState> = (
  state: ISchoolProfileState,
): boolean => state.loading;
export const error: GetFromState<IServerError, ISchoolProfileState> = (
  state: ISchoolProfileState,
): IServerError => state.error;
export const isPersonnel: GetFromState<boolean, ISchool> = (
  school: ISchool,
): boolean => school && school.isPersonnel;

export const isSchoolPidHidden: GetFromState<boolean, ISchoolHeader> = (
  header: ISchoolHeader,
): boolean => header && header.pid.toString() === HIDDEN_BY.value;
export const schoolHeader: GetFromState<ISchoolHeader, ISchool> = (
  school: ISchool,
): ISchoolHeader => (school && school.header) || null;
export const schoolGeneralInfo: GetFromState<ISchoolInfo, ISchool> = (
  school: ISchool,
): ISchoolInfo => (school && school.info) || null;
export const schoolEthnicity: GetFromState<IEthnicity, ISchoolInfo> = (
  school: ISchoolInfo,
): IEthnicity => (school && school.ethnicity) || null;
export const schoolBids: GetFromState<IBids, ISchool> = (
  school: ISchool,
): IBids => (school && school.bids) || null;
export const isHiddenSchoolBids: GetFromState<boolean, IBids> = (
  _bids: IBids,
): boolean =>
  (_bids && _bids.total && _bids.total.toString() === HIDDEN_BY.value) || null;
export const schoolGrands: GetFromState<IGrants, ISchool> = (
  school: ISchool,
): IGrants => (school && school.grants) || null;
export const isHiddenSchoolGrants: GetFromState<boolean, IGrants> = (
  _grants: IGrants,
): boolean => {
  return (
    (_grants &&
      _grants.totalActive &&
      _grants.totalActive.value &&
      _grants.totalActive.value.toString() === HIDDEN_BY.value) ||
    null
  );
};
export const schoolParentProfile: GetFromState<
  IParentProfile,
  ISchoolHeader
> = (header: ISchoolHeader): IParentProfile =>
  (header && header.parent) || null;
export const schoolParentProfileType: GetFromState<string, IParentProfile> = (
  parentProfile: IParentProfile,
): string => (parentProfile && parentProfile.entityType) || null;
export const schoolDistricts: GetFromState<ISchoolDistrictInfo, ISchool> = (
  school: ISchool,
): ISchoolDistrictInfo => (school && school.districtInfo) || null;

export const schoolCoordinates: GetFromState<any, ISchool> = (
  school: ISchool,
): any => {
  if (school && school.header) {
    const { latitude: lat, longitude: lng }: Partial<IProfileAddress> =
      school.header.physicalAddress || school.header.address;

    return { lat, lng };
  }

  return { lat: null, lng: null };
};

export const districtId: GetFromState<number, IParentProfile> = (
  district: IParentProfile,
): number => district && district.entityId;

export const districtInfoAllFieldsIsEmpty: GetFromState<
  boolean,
  ISchoolDistrictInfo
> = (districtInfo: ISchoolDistrictInfo): boolean => {
  return (
    districtInfo !== null &&
    districtInfo.bilingualEducationFunding === 0 &&
    districtInfo.districtSize &&
    districtInfo.districtSize.value === 0 &&
    districtInfo.instructionalSpendingPerStudent === 0 &&
    districtInfo.numberOfSchools === 0 &&
    districtInfo.specialEducationFunding === 0 &&
    districtInfo.titleOneAllocation === 0 &&
    districtInfo.titleOnePerStudentAllocation === 0
  );
};

export const schoolComputers: GetFromState<IComputers, ISchoolInfo> = (
  school: ISchoolInfo,
): IComputers => school && school.computers;

export const marketViewAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageViews, _data, _isHidden);
};

export const grantAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageGrants, _data, _isHidden);
};

export const bidAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (_data: IUserPermissions, _isHidden: boolean): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageBids, _data, _isHidden);
};
