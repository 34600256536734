import { ChangeDetectionStrategy, Component } from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { delay } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { getTooltips } from "../../store/selectors/tooltips.selector";

import { ITooltips } from "@core/interfaces/tooltip";

@Component({
  selector: "bl-tooltip-container",
  templateUrl: "./tooltip-container.component.html",
  styleUrls: ["./tooltip-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipContainerComponent {
  tooltips$: Observable<ITooltips> = this._store.pipe(
    delay(0),
    select(getTooltips),
  );

  constructor(private _store: Store<CoreState>) {}
}
