import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IEditAccountUser,
  IEditUserFormForRequest,
  ISelectedStatesPayload,
  SEATS,
} from "../../interfaces/marketview";

enum ACTION_TYPES {
  OPEN_EDIT_USER_POP_UP = "[EDIT USER ACCOUNT] Open edit user pop-up",
  CLOSE_EDIT_USER_POP_UP = "[EDIT USER ACCOUNT] Close edit user pop-up",

  SET_SHOW_AVAILABLE_STATES = "[EDIT USER ACCOUNT] set show available states",

  EDIT_ACCOUNT_USER = "[EDIT USER ACCOUNT] Edit account user",
  EDIT_ACCOUNT_USER_ERROR = "[EDIT USER ACCOUNT] Edit account user error",
  EDIT_ACCOUNT_USER_SUCCESS = "[EDIT USER ACCOUNT] Edit account user success",

  EDIT_SELF_USER_ACCOUNT = "[EDIT SELF USER ACCOUNT] Edit self user account",
  EDIT_SELF_USER_ACCOUNT_ERROR = "[EDIT SELF USER ACCOUNT] Edit self user error",
  EDIT_SELF_USER_ACCOUNT_SUCCESS = "[EDIT SELF USER ACCOUNT] Edit self user account success",

  RESEND_INVITATION = "[EDIT USER ACCOUNT] Resend invitation",
  RESEND_INVITATION_ERROR = "[EDIT USER ACCOUNT] Resend invitation error",
  RESEND_INVITATION_SUCCESS = "[EDIT USER ACCOUNT] Resend invitation success",

  CHANGE_SELECTED_STATE = "[EDIT USER ACCOUNT] Change current state",

  CHANGE_SEAT_TYPE = "[EDIT USER ACCOUNT] Change seat type",

  RESET_STATE = "[EDIT USER ACCOUNT] Reset state",
}

export const openEditUserPopUpAction: CreateActionType<
  ACTION_TYPES.OPEN_EDIT_USER_POP_UP,
  IEditAccountUser
> = createAction(
  ACTION_TYPES.OPEN_EDIT_USER_POP_UP,
  createPayload<IEditAccountUser>(),
);
export const closeEditUserPopUpAction: ActionCreator<
  ACTION_TYPES.CLOSE_EDIT_USER_POP_UP,
  () => TypedAction<ACTION_TYPES.CLOSE_EDIT_USER_POP_UP>
> = createAction(ACTION_TYPES.CLOSE_EDIT_USER_POP_UP);

export const setShowAvailableStatesAction: CreateActionType<
  ACTION_TYPES.SET_SHOW_AVAILABLE_STATES,
  boolean
> = createAction(
  ACTION_TYPES.SET_SHOW_AVAILABLE_STATES,
  createPayload<boolean>(),
);

export const editAccountUserAction: CreateActionType<
  ACTION_TYPES.EDIT_ACCOUNT_USER,
  IEditUserFormForRequest
> = createAction(
  ACTION_TYPES.EDIT_ACCOUNT_USER,
  createPayload<IEditUserFormForRequest>(),
);
export const editAccountUserErrorAction: CreateActionType<
  ACTION_TYPES.EDIT_ACCOUNT_USER_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.EDIT_ACCOUNT_USER_ERROR,
  createPayload<IServerError>(),
);
export const editAccountUserSuccessAction: ActionCreator<
  ACTION_TYPES.EDIT_ACCOUNT_USER_SUCCESS,
  () => TypedAction<ACTION_TYPES.EDIT_ACCOUNT_USER_SUCCESS>
> = createAction(ACTION_TYPES.EDIT_ACCOUNT_USER_SUCCESS);

export const editSelfUserAccountAction: CreateActionType<
  ACTION_TYPES.EDIT_SELF_USER_ACCOUNT,
  IEditUserFormForRequest
> = createAction(
  ACTION_TYPES.EDIT_SELF_USER_ACCOUNT,
  createPayload<IEditUserFormForRequest>(),
);
export const editSelfUserAccountErrorAction: CreateActionType<
  ACTION_TYPES.EDIT_SELF_USER_ACCOUNT_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.EDIT_SELF_USER_ACCOUNT_ERROR,
  createPayload<IServerError>(),
);
export const editSelfUserAccountSuccessAction: ActionCreator<
  ACTION_TYPES.EDIT_SELF_USER_ACCOUNT_SUCCESS,
  () => TypedAction<ACTION_TYPES.EDIT_SELF_USER_ACCOUNT_SUCCESS>
> = createAction(ACTION_TYPES.EDIT_SELF_USER_ACCOUNT_SUCCESS);

export const resendInvitationAction: CreateActionType<
  ACTION_TYPES.RESEND_INVITATION,
  number
> = createAction(ACTION_TYPES.RESEND_INVITATION, createPayload<number>());
export const resendInvitationErrorAction: CreateActionType<
  ACTION_TYPES.RESEND_INVITATION_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RESEND_INVITATION_ERROR,
  createPayload<IServerError>(),
);
export const resendInvitationSuccessAction: ActionCreator<
  ACTION_TYPES.RESEND_INVITATION_SUCCESS,
  () => TypedAction<ACTION_TYPES.RESEND_INVITATION_SUCCESS>
> = createAction(ACTION_TYPES.RESEND_INVITATION_SUCCESS);

export const changeSelectedStateAction: CreateActionType<
  ACTION_TYPES.CHANGE_SELECTED_STATE,
  ISelectedStatesPayload
> = createAction(
  ACTION_TYPES.CHANGE_SELECTED_STATE,
  createPayload<ISelectedStatesPayload>(),
);

export const changeSeatTypeAction: CreateActionType<
  ACTION_TYPES.CHANGE_SEAT_TYPE,
  SEATS
> = createAction(ACTION_TYPES.CHANGE_SEAT_TYPE, createPayload<SEATS>());

export const resetEditUserStateAction: ActionCreator<
  ACTION_TYPES.RESET_STATE,
  () => TypedAction<ACTION_TYPES.RESET_STATE>
> = createAction(ACTION_TYPES.RESET_STATE);
