import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { IPaymentKeys } from "@env/default-environment";
import { environment } from "@env/environment";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/payment.action";

import { formatCartDate, getUTCDate } from "@shared/utils/payment-help-data";
import {
  OrderCardDetails,
  OrderNewCardDetails,
} from "@shared/utils/payment-mapper";

import { IServerError } from "@shared/interfaces/server-error";
import {
  ICartItemForOrder,
  ICreditCardInfo,
  IDefaultBillingInfo,
  IDefaultTransactionData,
  INewCardTransactionData,
  INewCreditCartInfo,
  ISalesOrderParams,
} from "../../interfaces/e-commerce";
import {
  IPrice,
  ISalesOrderTaxData,
  ITaxDataResponse,
  ITaxItem,
} from "../../interfaces/price";

import {
  CART_OWL_TYPE_CODES,
  CREATE_TRANSACTION_FIELDS,
  CYBER_SOURCE_TYPE_CARD_CODES,
  DEFAULT_CURRENCY,
  PAYMENT_METHOD,
  SALE_TRANSACTION_FIELDS,
  TRANSACTION_TYPE,
} from "../../constants/payment";

export interface IPaymentState {
  defaultBillingInfo: IDefaultBillingInfo | null;

  saleResultData: any;
  salesOrderDataPending: boolean;
  salesOrderDataError: IServerError | null;

  defaultCardSignatureData: IDefaultTransactionData | null;
  newCardSignatureData: INewCardTransactionData | null;

  newCardSignatureLoaded: boolean;
  defaultCardSignatureLoaded: boolean;

  signatureDataPending: boolean;
  signatureDataError: IServerError | null;

  loading: boolean;
  loaded: boolean;

  updating: boolean;
  updatingId: number | null;

  confirmTerms: boolean;

  error: IServerError | null;
}

const initialState: IPaymentState = {
  defaultBillingInfo: null,

  saleResultData: null,
  salesOrderDataPending: false,
  salesOrderDataError: null,

  defaultCardSignatureData: null,
  newCardSignatureData: null,

  newCardSignatureLoaded: false,
  defaultCardSignatureLoaded: false,

  signatureDataPending: false,
  signatureDataError: null,

  loading: false,
  loaded: false,

  updating: false,
  updatingId: null,

  confirmTerms: false,

  error: null,
};

const getBilling: OnReducer<IPaymentState, ActionType<any>> = (
  state: IPaymentState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getBillingError: OnReducer<
  IPaymentState,
  ActionType<Payload<IServerError>>
> = (state: IPaymentState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getBillingSuccess: OnReducer<IPaymentState, ActionType<Payload<any>>> = (
  state: IPaymentState,
  { payload }: Payload<any>,
) => ({
  ...state,
  loading: false,
  defaultBillingInfo: { ...payload },
});

const getAllBillingInfoSuccess: OnReducer<
  IPaymentState,
  ActionType<Payload<any>>
> = (state: IPaymentState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  defaultBillingInfo: { ...payload.user },
});

const getSalesOrder: OnReducer<IPaymentState, ActionType<any>> = (
  state: IPaymentState,
) => ({
  ...state,
  saleResultData: null,
  salesOrderDataError: null,
  salesOrderDataPending: true,
});

const getSalesOrderError: OnReducer<
  IPaymentState,
  ActionType<Payload<IServerError>>
> = (state: IPaymentState, { payload }: Payload<IServerError>) => ({
  ...state,
  saleResultData: null,
  salesOrderDataError: { ...payload },
  salesOrderDataPending: false,
});

const getSalesOrderSuccess: OnReducer<
  IPaymentState,
  ActionType<Payload<any>>
> = (state: IPaymentState, { payload }: Payload<any>) => ({
  ...state,
  saleResultData: { ...payload },
  salesOrderDataError: null,
  salesOrderDataPending: false,
});

const getDefaultCardSignature: OnReducer<IPaymentState, ActionType<any>> = (
  state: IPaymentState,
) => ({
  ...state,
  defaultCardSignatureLoaded: false,
  defaultCardSignatureData: null,
  signatureDataError: null,
  signatureDataPending: true,
});

const getDefaultCardSignatureError: OnReducer<
  IPaymentState,
  ActionType<Payload<IServerError>>
> = (state: IPaymentState, { payload }: Payload<IServerError>) => ({
  ...state,
  defaultCardSignatureLoaded: false,
  defaultCardSignatureData: null,
  signatureDataError: { ...payload },
  signatureDataPending: false,
});

const getDefaultCardSignatureSuccess: OnReducer<
  IPaymentState,
  ActionType<Payload<any>>
> = (state: IPaymentState, { payload }: Payload<any>) => ({
  ...state,
  defaultCardSignatureLoaded: true,
  defaultCardSignatureData: { ...payload },
  signatureDataError: null,
  signatureDataPending: true,
});

const getNewCardSalesOrderSignature: OnReducer<
  IPaymentState,
  ActionType<any>
> = (state: IPaymentState) => ({
  ...state,
  newCardSignatureLoaded: false,
  newCardSignatureData: null,
  signatureDataError: null,
  signatureDataPending: true,
});

const getNewCardSalesOrderSignatureError: OnReducer<
  IPaymentState,
  ActionType<Payload<IServerError>>
> = (state: IPaymentState, { payload }: Payload<IServerError>) => ({
  ...state,
  newCardSignatureLoaded: false,
  newCardSignatureData: null,
  signatureDataError: { ...payload },
  signatureDataPending: false,
});

const getNewCardSalesOrderSignatureSuccess: OnReducer<
  IPaymentState,
  ActionType<Payload<any>>
> = (state: IPaymentState, { payload }: Payload<any>) => ({
  ...state,
  newCardSignatureLoaded: true,
  newCardSignatureData: { ...payload },
  signatureDataPending: false,
});

const toggleConfirmTerms: OnReducer<IPaymentState, ActionType<Payload<any>>> = (
  state: IPaymentState,
  { payload }: Payload<any>,
) => ({
  ...state,
  confirmTerms: payload,
});

const resetPayment: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

export const reducer: ActionReducer<IPaymentState> =
  createReducer<IPaymentState>(
    initialState,

    on(actions.getBillingAction, getBilling),
    on(actions.getBillingErrorAction, getBillingError),
    on(actions.getBillingSuccessAction, getBillingSuccess),
    on(actions.getAllBillingInfoSuccessAction, getAllBillingInfoSuccess),

    on(actions.getSalesOrderAction, getSalesOrder),
    on(actions.getSalesOrderErrorAction, getSalesOrderError),
    on(actions.getSalesOrderSuccessAction, getSalesOrderSuccess),

    on(actions.getDefaultCardSignatureAction, getDefaultCardSignature),
    on(
      actions.getDefaultCardSignatureErrorAction,
      getDefaultCardSignatureError,
    ),
    on(
      actions.getDefaultCardSignatureSuccessAction,
      getDefaultCardSignatureSuccess,
    ),

    on(
      actions.getNewCardSalesOrderSignatureAction,
      getNewCardSalesOrderSignature,
    ),
    on(
      actions.getNewCardSalesOrderSignatureErrorAction,
      getNewCardSalesOrderSignatureError,
    ),
    on(
      actions.getNewCardSalesOrderSignatureSuccessAction,
      getNewCardSalesOrderSignatureSuccess,
    ),

    on(actions.toggleConfirmTermsAction, toggleConfirmTerms),
    on(actions.resetPaymentAction, resetPayment),
  );

export function paymentReducer(
  state: IPaymentState,
  action: Action,
): IPaymentState {
  return reducer(state, action);
}

export const defaultBillingInfo: GetFromState<
  IDefaultBillingInfo | null,
  IPaymentState
> = (state: IPaymentState): IDefaultBillingInfo | null =>
  state.defaultBillingInfo;

export const loading: GetFromState<boolean, IPaymentState> = (
  state: IPaymentState,
): boolean => state.loading;
export const loaded: GetFromState<boolean, IPaymentState> = (
  state: IPaymentState,
): boolean => state.loaded;

export const confirmTerms: GetFromState<boolean, IPaymentState> = (
  state: IPaymentState,
): boolean => state.confirmTerms;

export const defaultCardSignatureLoaded: GetFromState<
  boolean,
  IPaymentState
> = (state: IPaymentState): boolean => state.defaultCardSignatureLoaded;
export const newCardSignatureLoaded: GetFromState<boolean, IPaymentState> = (
  state: IPaymentState,
): boolean => state.newCardSignatureLoaded;

export const salesResultData: GetFromState<any, IPaymentState> = (
  state: IPaymentState,
): any => state.saleResultData;

export const salesOrderAvailable: GetFromState<
  boolean,
  ISalesOrderParams,
  boolean,
  number,
  boolean
> = (
  salesOrder: ISalesOrderParams,
  confirmedTerms: boolean,
  subTotalPrice: number,
  taxPending: boolean,
): boolean =>
  !taxPending && !!salesOrder && confirmedTerms && subTotalPrice > 0;

export const defaultCardSignatureData: GetFromState<
  IDefaultTransactionData | null,
  IPaymentState
> = (state: IPaymentState): IDefaultTransactionData | null =>
  state.defaultCardSignatureData;

export const newCardTransactionData: GetFromState<
  INewCardTransactionData,
  IPaymentState,
  INewCreditCartInfo
> = (
  state: IPaymentState,
  newCreditCard: INewCreditCartInfo,
): INewCardTransactionData => {
  if (!newCreditCard) {
    return null;
  }

  const {
    card_type,
    card_number,
    card_expiry_date,
    card_cvn,
  }: Partial<INewCreditCartInfo> = newCreditCard;

  return {
    ...state.newCardSignatureData,
    card_type:
      (CART_OWL_TYPE_CODES[card_type] &&
        CART_OWL_TYPE_CODES[card_type].cyberSource) ||
      CYBER_SOURCE_TYPE_CARD_CODES.VS,
    card_number,
    card_expiry_date: formatCartDate(card_expiry_date),
    card_cvn,
  } as INewCardTransactionData;
};

export const newPaymentDataForCreatingSignature: any = (
  salesOrderData: any,
  _salesResultData: any,
  total: any,
  userInfo: any,
) => {
  if (!salesOrderData || !_salesResultData || !total || !userInfo) {
    return null;
  }

  const signed_field_names: string = CREATE_TRANSACTION_FIELDS.SIGNED;
  const unsigned_field_names: string = CREATE_TRANSACTION_FIELDS.UNSIGNED;

  const { access_key, profile_id, locale }: Partial<IPaymentKeys> =
    environment.paymentKeys;

  const transaction_uuid: string = _salesResultData.orderId;
  const signed_date_time: string = getUTCDate();
  const transaction_type: string = TRANSACTION_TYPE.CREATE;
  const reference_number: string = _salesResultData.merchantOrderReferenceId;
  const currency: string = DEFAULT_CURRENCY;
  const payment_method: string = PAYMENT_METHOD.CARD;
  const bill_to_forename: string =
    salesOrderData.creditCardDetail.firstNameOnCard;
  const bill_to_surname: string =
    salesOrderData.creditCardDetail.lastNameOnCard;
  const bill_to_email: string = userInfo.email;
  const bill_to_phone: string = userInfo.personal_address.phone_number;
  const bill_to_address_line1: string =
    salesOrderData.creditCardDetail.address.addressLine1;
  const bill_to_address_city: string =
    salesOrderData.creditCardDetail.address.cityName;
  const bill_to_address_state: string =
    salesOrderData.creditCardDetail.address.stateCode;
  const bill_to_address_country: string =
    salesOrderData.creditCardDetail.address.countryCode;
  const bill_to_address_postal_code: string =
    salesOrderData.creditCardDetail.address.zipCode;
  const amount: string = total + "";
  const override_custom_receipt_page: string =
    environment.paymentKeys.override_custom_receipt_page;
  const orderPage_declineResponseURL: string =
    environment.paymentKeys.orderPage_declineResponseURL;
  const listId: string = salesOrderData.listId;
  const segmentId: string = salesOrderData.segmentId;

  return {
    access_key,
    profile_id,
    transaction_uuid,
    signed_field_names,
    unsigned_field_names,
    signed_date_time,
    locale,
    transaction_type,
    reference_number,
    amount,
    currency,
    payment_method,
    bill_to_forename,
    bill_to_surname,
    bill_to_email,
    bill_to_phone,
    bill_to_address_line1,
    bill_to_address_city,
    bill_to_address_state,
    bill_to_address_country,
    bill_to_address_postal_code,
    override_custom_receipt_page:
      listId && segmentId
        ? `${override_custom_receipt_page}?listId=${listId}&segmentId=${segmentId}`
        : override_custom_receipt_page,
    orderPage_declineResponseURL,
  } as INewCardTransactionData;
};

export const defaultPaymentDataForCreatingSignature: GetFromState<
  IDefaultTransactionData,
  any,
  any,
  number
> = (
  salesOrderData: any,
  _salesResultData: any,
  total: number,
): IDefaultTransactionData => {
  if (!salesOrderData || !_salesResultData) {
    return null;
  }

  const signed_field_names: string = SALE_TRANSACTION_FIELDS.SIGNED;
  const unsigned_field_names: string = SALE_TRANSACTION_FIELDS.UNSIGNED;

  const { access_key, profile_id, locale }: Partial<IPaymentKeys> =
    environment.paymentKeys;
  const signed_date_time: string = getUTCDate();

  const reference_number: string = _salesResultData.merchantOrderReferenceId;
  const payment_token: string =
    salesOrderData.creditCardDetail.paymentTokenIdentifier;
  const consumer_id: string = ""; // where this value should i take?
  const transaction_type: string = TRANSACTION_TYPE.SALE;
  const amount: string = total + "";
  const currency: string = DEFAULT_CURRENCY;
  const transaction_uuid: string = _salesResultData.orderId;
  const override_custom_receipt_page: string =
    environment.paymentKeys.override_custom_receipt_page;
  const orderPage_declineResponseURL: string =
    environment.paymentKeys.orderPage_declineResponseURL;
  const listId: string = salesOrderData.listId;
  const segmentId: string = salesOrderData.segmentId;

  return {
    access_key,
    profile_id,
    reference_number,
    payment_token,
    consumer_id,
    transaction_type,
    amount,
    currency,
    locale,
    transaction_uuid,
    signed_date_time,
    signed_field_names,
    unsigned_field_names,
    override_custom_receipt_page:
      listId && segmentId
        ? `${override_custom_receipt_page}?listId=${listId}&segmentId=${segmentId}`
        : override_custom_receipt_page,
    orderPage_declineResponseURL,
  } as IDefaultTransactionData;
};

export const toSalesOrderData: GetFromState<
  ISalesOrderParams,
  ICartItemForOrder[],
  boolean,
  INewCreditCartInfo,
  ICreditCardInfo,
  ISalesOrderTaxData,
  Params
> = (
  items: ICartItemForOrder[],
  isNewCreditCard: boolean,
  newCreditCard: INewCreditCartInfo,
  defaultCreditCard: ICreditCardInfo,
  tax: ISalesOrderTaxData,
  params: Params,
): ISalesOrderParams | null => {
  // TODO ISalesOrderParams

  if (isNewCreditCard && (!newCreditCard || !items || !tax)) {
    return null;
  }

  if (!isNewCreditCard && (!items || !defaultCreditCard || !tax)) {
    return null;
  }

  const data: any = {
    cartItems: [...items],
    creditCardDetail: isNewCreditCard
      ? new OrderNewCardDetails(newCreditCard)
      : new OrderCardDetails(defaultCreditCard),
    totalTax: (tax && tax.taxAmount) || 0,
    taxExempt: "true",
    transactionType: isNewCreditCard
      ? newCreditCard.saveCard
        ? TRANSACTION_TYPE.CREATE
        : TRANSACTION_TYPE.SALE
      : TRANSACTION_TYPE.SALE,
    saveCard: isNewCreditCard ? newCreditCard.saveCard.toString() : "true",
    addressValidated: "false",
    taxRegistrationIdentifier: (tax && tax.taxRegistrationIdentifier) || "",
    merchantId: environment.paymentKeys.merchant_id,
  };

  if (params && params.listId) {
    data["listId"] = params.listId;
  }

  if (params && params.segmentId) {
    data["segmentId"] = params.segmentId;
  }

  return data;
};

export const dataForCalculateTax: GetFromState<
  ITaxDataResponse,
  number,
  IPrice[],
  IDefaultBillingInfo
> = (
  orderAmount: number,
  cartItems: IPrice[] = [],
  _defaultBillingInfo: IDefaultBillingInfo,
): ITaxDataResponse => {
  // changed by Task https://schooldatamdr.atlassian.net/browse/BL-4084
  // if (!newCreditCard && !selectedCreditCard) {
  //   return null;
  // }

  // const paymentAddress = isNewCard
  //   ? new TaxAddressFromNewCard(newCreditCard as INewCreditCartInfo)
  //   : new TaxAddress(selectedCreditCard.address);

  if (!_defaultBillingInfo) {
    return null;
  }

  const {
    personal_address: {
      city_name: city,
      country_code: countryCode,
      state_code: state,
      zip_code: zipCode,
    },
  }: Partial<IDefaultBillingInfo> = _defaultBillingInfo;

  return {
    ...{ city, countryCode, state, zipCode },
    orderAmount,
    cartItems: cartItems.map(
      ({ productId, name, price: itemNetAmount }: IPrice) =>
        ({ productId, name, itemNetAmount }) as ITaxItem,
    ),
  } as ITaxDataResponse;
};
