import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";

import { environment } from "@env/environment";

import { merge, Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { removeTooltipAction } from "../../store/actions/tooltips.action";

import { WindowRef } from "@core/refs/window-ref.service";
import { LayoutService } from "@core/services/layout.service";

import { ITooltip } from "@core/interfaces/tooltip";
import { TooltipPositions } from "@shared/interfaces/tooltipPositions";

import { DEFAULT_TOOLTIP_WIDTH } from "@shared/constants/tooltips/default-values";

import { fadeInOut } from "@ui/animations/fadeInOut";

@Component({
  selector: "bl-tooltip",
  templateUrl: "./tooltip.component.html",
  styleUrls: ["./tooltip.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements AfterViewInit, OnDestroy {
  @ViewChild("container") containerEl: ElementRef;

  @Input() tooltip: ITooltip;

  get maxWidth(): string | number {
    const maxWidth: number = 450;
    const textLength: number = this.tooltip.text.length || 1;
    const factor: number = textLength / 100;

    return factor <= 1
      ? this.defaultMaxWidth
      : factor > 3
        ? `${maxWidth}px`
        : `${this.defaultMaxWidth * factor}px`;
  }

  get tooltipPosition(): string {
    const rect: ClientRect = this.tooltip.clientRect;

    switch (this.tooltip.position) {
      case TooltipPositions.RIGHT:
        return this.isRightFree(rect)
          ? this.tooltip.position
          : this.findFreePlace(rect);

      case TooltipPositions.LEFT:
        return this.isLeftFree(rect)
          ? this.tooltip.position
          : this.findFreePlace(rect);

      case TooltipPositions.BOTTOM:
        return this.isBottomFree(rect)
          ? this.tooltip.position
          : this.findFreePlace(rect);

      case TooltipPositions.TOP:
        return this.isTopFree(rect)
          ? this.tooltip.position
          : this.findFreePlace(rect);
    }
  }

  get shouldShowTooltipFooter(): boolean {
    return !!this.tooltip.dismissable || !!this.tooltip.footerText;
  }

  readonly phoneNumber: string = environment.contactPhoneNumber;

  private destroyer$: Subject<void> = new Subject();

  defaultMaxWidth: number = 150;

  constructor(
    private renderer: Renderer2,
    private window: WindowRef,
    private store: Store<CoreState>,
    private layoutService: LayoutService,
  ) {}

  ngAfterViewInit(): void {
    this.calculatePosition();

    if (this.tooltip.positionFixedAndCalc) {
      merge(
        this.layoutService.isScrollOverlay,
        this.layoutService.layoutHasBeenChanged.pipe(debounceTime(0)),
      )
        .pipe(takeUntil(this.destroyer$))
        .subscribe(() => this.calculatePosition());
    }
  }

  ngOnDestroy(): void {
    if (this.tooltip.onAfterCloseContent) {
      setTimeout(() => this.tooltip.onAfterCloseContent());
    }

    this.destroyer$.next();
    this.destroyer$.complete();
  }

  @HostListener("window:resize")
  calculatePosition(): void {
    if (typeof this.tooltip.text === "string" && !!this.tooltip.text) {
      const targetRect: ClientRect = this.tooltip.clientRect;

      let top: number;
      let left: number;

      if (
        this.tooltipPosition === TooltipPositions.BOTTOM ||
        TooltipPositions.TOP
      ) {
        left = targetRect.left + targetRect.width / 2 + this.tooltip.leftOffset;
      }

      if (this.tooltipPosition === TooltipPositions.TOP) {
        top = targetRect.top + this.tooltip.topOffset;
      }

      if (this.tooltipPosition === TooltipPositions.BOTTOM) {
        top = targetRect.bottom + this.tooltip.topOffset;
      }

      if (
        this.tooltipPosition === TooltipPositions.LEFT ||
        this.tooltipPosition === TooltipPositions.RIGHT
      ) {
        top = targetRect.top + targetRect.height / 2 + this.tooltip.topOffset;
      }

      if (this.tooltipPosition === TooltipPositions.LEFT) {
        left = targetRect.left + this.tooltip.leftOffset;
      }

      if (this.tooltipPosition === TooltipPositions.RIGHT) {
        left = targetRect.right + this.tooltip.leftOffset;
      }

      top = top + this.window.nativeElement.pageYOffset;

      this.setStyles(top, left);
    }
  }

  @HostListener("window:scroll", ["$event"])
  recalculatePosition(): void {
    this.calculatePosition();
  }

  @HostListener("click")
  closeOnClick(): void {
    const { id }: ITooltip = this.tooltip;
    this.store.dispatch(removeTooltipAction(id));
  }

  @HostListener("mouseover")
  showTooltip(): void {
    this.tooltip.isOnHover = true;
  }

  @HostListener("mouseleave")
  notShowTooltip(): void {
    this.tooltip.isOnHover = false;

    if (!this.tooltip.preventClose) {
      const { id }: ITooltip = this.tooltip;
      this.store.dispatch(removeTooltipAction(id));
    }
  }

  @HostListener("document:click", ["$event"])
  closeOnOutsideClick(event: MouseEvent): void {
    if (
      !(<HTMLElement>event.target).classList.contains(this.tooltip.tooltipClass)
    ) {
      const { id }: ITooltip = this.tooltip;
      this.store.dispatch(removeTooltipAction(id));
    }
  }

  setStyles(top: number, left: number): void {
    this.renderer.setStyle(this.containerEl.nativeElement, "top", `${top}px`);
    this.renderer.setStyle(this.containerEl.nativeElement, "left", `${left}px`);
    this.renderer.setStyle(
      this.containerEl.nativeElement,
      "fontSize",
      `${this.tooltip.textSize}px`,
    );
    this.renderer.setStyle(
      this.containerEl.nativeElement,
      "textAlign",
      this.tooltip.textAlign,
    );
  }

  getPositionClass(): { [key: string]: string | boolean } {
    return {
      "tooltip--left": this.tooltipPosition === TooltipPositions.LEFT,
      "tooltip--right": this.tooltipPosition === TooltipPositions.RIGHT,
      "tooltip--top": this.tooltipPosition === TooltipPositions.TOP,
      "tooltip--bottom": this.tooltipPosition === TooltipPositions.BOTTOM,
      [this.tooltip.tooltipClass]: this.tooltip.tooltipClass,
      [this.tooltip.iconClass]: this.tooltip.iconClass,
      tooltip: true,
      "tooltip--fixed": this.tooltip.positionFixedAndCalc,
    };
  }

  findFreePlace(rect: ClientRect): string {
    switch (this.tooltip.position) {
      case TooltipPositions.RIGHT:
        if (this.isLeftFree(rect)) {
          return TooltipPositions.LEFT;
        } else if (this.isBottomFree(rect)) {
          return TooltipPositions.BOTTOM;
        } else if (this.isTopFree(rect)) {
          return TooltipPositions.TOP;
        }
        break;

      case TooltipPositions.LEFT:
        if (this.isRightFree(rect)) {
          return TooltipPositions.LEFT;
        } else if (this.isBottomFree(rect)) {
          return TooltipPositions.BOTTOM;
        } else if (this.isTopFree(rect)) {
          return TooltipPositions.TOP;
        }
        break;

      case TooltipPositions.BOTTOM:
        if (this.isTopFree(rect)) {
          return TooltipPositions.TOP;
        } else if (this.isRightFree(rect)) {
          return TooltipPositions.RIGHT;
        } else if (this.isLeftFree(rect)) {
          return TooltipPositions.LEFT;
        }
        break;

      case TooltipPositions.TOP:
        if (this.isBottomFree(rect)) {
          return TooltipPositions.BOTTOM;
        } else if (this.isRightFree(rect)) {
          return TooltipPositions.RIGHT;
        } else if (this.isLeftFree(rect)) {
          return TooltipPositions.LEFT;
        }
        break;
    }

    return this.tooltip.position;
  }

  isLeftFree(rect: ClientRect): boolean {
    return rect.left - DEFAULT_TOOLTIP_WIDTH - this.tooltip.leftOffset > 0;
  }

  isRightFree(rect: ClientRect): boolean {
    return (
      rect.right + DEFAULT_TOOLTIP_WIDTH + this.tooltip.leftOffset <
      this.window.nativeElement.innerWidth
    );
  }

  isTopFree(rect: ClientRect): boolean {
    return rect.top - DEFAULT_TOOLTIP_WIDTH - this.tooltip.topOffset > 0;
  }

  isBottomFree(rect: ClientRect): boolean {
    return (
      rect.bottom + DEFAULT_TOOLTIP_WIDTH + this.tooltip.topOffset <
      this.window.nativeElement.innerHeight
    );
  }

  onFooterBtnClick(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    if (this.tooltip.onNext) {
      this.tooltip.onNext();
    }
    if (this.tooltip.onDismiss) {
      this.tooltip.onDismiss();
    }
    if (this.tooltip.closeByClickBtn) {
      const { id }: ITooltip = this.tooltip;
      this.store.dispatch(removeTooltipAction(id));
    }
  }

  replaceNoWrap(text: string): string {
    const textElements: string[] = text.split(/\n/g);

    return textElements
      .map((textElem: string) => `<span class="nowrap">${textElem}</span>`)
      .join("<br />");
  }
}
