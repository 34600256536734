import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-institution-districts.action";

import { IInstitutionsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { INSTITUTIONS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchInstitutionDistrictsState {
  districts: IInstitutionsRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IQuickSearchInstitutionDistrictsState = {
  districts: [],
  pending: false,
  error: null,

  count: 0,
};

const quickSearchInstitutionDistricts: OnReducer<
  IQuickSearchInstitutionDistrictsState,
  ActionType<any>
> = (state: IQuickSearchInstitutionDistrictsState) => ({
  ...state,
  pending: true,
  error: null,
});

const quickSearchInstitutionDistrictsError: OnReducer<
  IQuickSearchInstitutionDistrictsState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchInstitutionDistrictsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const quickSearchInstitutionDistrictsSuccess: OnReducer<
  IQuickSearchInstitutionDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchInstitutionDistrictsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  districts: [...payload.items],
  count: payload.count,
});

const setQuickSearchInstitutionDistrictsSpinnerState: OnReducer<
  IQuickSearchInstitutionDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchInstitutionDistrictsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetQuickSearchInstitutionDistrictsState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchInstitutionDistrictsState> =
  createReducer<IQuickSearchInstitutionDistrictsState>(
    initialState,

    on(
      actions.getQuickSearchInstitutionDistrictsAction,
      quickSearchInstitutionDistricts,
    ),
    on(
      actions.getQuickSearchInstitutionDistrictsErrorAction,
      quickSearchInstitutionDistrictsError,
    ),
    on(
      actions.getQuickSearchInstitutionDistrictsSuccessAction,
      quickSearchInstitutionDistrictsSuccess,
    ),

    on(
      actions.changeQuickSearchInstitutionDistrictsPageAction,
      quickSearchInstitutionDistricts,
    ),
    on(
      actions.changeQuickSearchInstitutionDistrictsQueryAction,
      quickSearchInstitutionDistricts,
    ),

    on(
      actions.setQuickSearchInstitutionDistrictsSpinnerStateAction,
      setQuickSearchInstitutionDistrictsSpinnerState,
    ),

    on(
      actions.resetQuickSearchInstitutionDistrictsStateAction,
      resetQuickSearchInstitutionDistrictsState,
    ),
  );

export function quickSearchResultsInstitutionDistrictsReducer(
  state: IQuickSearchInstitutionDistrictsState,
  action: Action,
): IQuickSearchInstitutionDistrictsState {
  return reducer(state, action);
}

export const districts: GetFromState<
  IInstitutionsRecord[],
  IQuickSearchInstitutionDistrictsState
> = (state: IQuickSearchInstitutionDistrictsState): IInstitutionsRecord[] =>
  state.districts;
export const pending: GetFromState<
  boolean,
  IQuickSearchInstitutionDistrictsState
> = (state: IQuickSearchInstitutionDistrictsState): boolean => state.pending;
export const districtsError: GetFromState<
  IServerError,
  IQuickSearchInstitutionDistrictsState
> = (state: IQuickSearchInstitutionDistrictsState): IServerError => state.error;

export const districtsPaginationCount: GetFromState<
  number,
  IQuickSearchInstitutionDistrictsState
> = (state: IQuickSearchInstitutionDistrictsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<any, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): any => {
  return {
    ...INSTITUTIONS_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    filterBy: "districts",
  };
};
