import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "bl-sub-header",
  templateUrl: "./sub-header.component.html",
  styleUrls: ["./sub-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubHeaderComponent {
  @Input() title: string;
  @Input() url: string[] = ["/dashboard"];
  @Input() disabled: boolean = false;
  @Input() externalRedirect: boolean = false;

  @Output() subHeaderRedirect: EventEmitter<void> = new EventEmitter();

  redirectTo(): void {
    this.subHeaderRedirect.emit();
  }
}
