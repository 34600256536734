import { ChangeDetectionStrategy, Component } from "@angular/core";

import { STICKY_TYPES } from "@shared/constants/sticky-type";

@Component({
  selector: "bl-general-footer",
  templateUrl: "./general-footer.component.html",
  styleUrls: ["./general-footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralFooterComponent {
  readonly stickyTypes: typeof STICKY_TYPES = STICKY_TYPES;
}
