import { IManageAccountList } from "@shared/interfaces/manage-account-lists";

import {
  ACCESS_CDL_LISTS,
  MANAGE_ACCOUNT_LISTS,
} from "@shared/constants/data/manage-account-lists";

export const findCheckedValue: (
  items: IManageAccountList<MANAGE_ACCOUNT_LISTS | ACCESS_CDL_LISTS>[],
) => number | null = (
  items: IManageAccountList<MANAGE_ACCOUNT_LISTS | ACCESS_CDL_LISTS>[],
): number | null => {
  const found: IManageAccountList<MANAGE_ACCOUNT_LISTS | ACCESS_CDL_LISTS> =
    items &&
    items.find(
      (
        item: IManageAccountList<MANAGE_ACCOUNT_LISTS | ACCESS_CDL_LISTS>,
      ): boolean => item && item.checked,
    );
  return found && found.value;
};
