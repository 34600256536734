import { Injectable } from "@angular/core";

import { SessionStorageService } from "./session-storage.service";

import {
  ICDLLastPage,
  ICDLLastPages,
} from "@shared/modules/cdl-last-activities/interfaces";

import { CDL_LIST_PATHS } from "@modules/cdl/cdl-list/constants/cdl-list-paths";
import {
  CDL_LIST_PAGES,
  CDL_LIST_ROUTER_PATH_BY_ID,
} from "@shared/modules/cdl-last-activities/constants";

@Injectable()
export class CdlLastActivitiesService {
  setLastPage(id: number, page: CDL_LIST_PAGES): void {
    const lastPages: ICDLLastPages = [
      ...SessionStorageService.cdlLastCompletedPages,
    ];

    const foundIndex: number = lastPages.findIndex(
      (item: ICDLLastPage) => Number(item.id) === Number(id),
    );

    if (foundIndex !== -1) {
      lastPages.splice(foundIndex, 1, { id, page });
      SessionStorageService.cdlLastCompletedPages = [...lastPages];
    } else {
      SessionStorageService.cdlLastCompletedPages = [
        ...lastPages,
        { id, page },
      ];
    }
  }

  removeLastPage(id: number): void {
    SessionStorageService.cdlLastCompletedPages = [
      ...SessionStorageService.cdlLastCompletedPages.filter(
        (item: ICDLLastPage) => Number(item.id) !== id,
      ),
    ];
  }

  getLastPageId(listId: number): CDL_LIST_PAGES | null {
    const found: ICDLLastPage = this._lastPageById(listId);
    return (found && found.page) || null;
  }

  getLastPagePath(listId: number): CDL_LIST_PATHS {
    const found: ICDLLastPage = this._lastPageById(listId);
    return (
      (found && CDL_LIST_ROUTER_PATH_BY_ID[found.page]) ||
      CDL_LIST_PATHS.INSTITUTIONS
    );
  }

  private _lastPageById(listId: number): ICDLLastPage {
    const lastPages: ICDLLastPages =
      SessionStorageService.cdlLastCompletedPages;
    return lastPages.find(
      (item: ICDLLastPage) => Number(item.id) === Number(listId),
    );
  }
}
