import { createAction, Action } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { AddressError, IServerError } from "@shared/interfaces/server-error";
import {
  ICheckAddressSignUpData,
  IFirstLoginFormData,
  IForgotPasswordData,
  ISignInFormData,
  ISignOutData,
  ISignUpFormData,
  ISuccessLoginActionData,
} from "../../interfaces/formsActionsData";
import {
  ICheckUserNameResponse,
  IForgotPasswordResponse,
  ISuccessMessageResponse,
} from "../../interfaces/user";

enum ACTION_TYPES {
  SIGN_IN = "[AUTH] Sign In",
  SIGN_IN_SUCCESS = "[AUTH] Sign In Success",
  SIGN_IN_ERROR = "[AUTH] Sign In Error",

  RESET_SIGN_IN_LOADING = "[AUTH] Reset Sign In Loading",
  RESET_SIGN_UP_LOADING = "[AUTH] Reset Sign Up Loading",

  SIGN_OUT = "[AUTH] Sign Out",
  SIGN_OUT_SUCCESS = "[AUTH] Sign Out Success",

  SIGN_OUT_AND_GO_TO_ADMIN_PANEL = "[AUTH] logout and go to admin panel",

  CHECK_ADDRESS = "[AUTH] Sign Up check Address",
  CHECK_ADDRESS_ERROR = "[AUTH] Sign Up check Address Error",
  CHECK_ADDRESS_SUCCESS = "[AUTH] Sign Up check Address Success",

  SIGN_UP = "[AUTH] Sign Up",
  SIGN_UP_ERROR = "[AUTH] Sign Up Error",
  SIGN_UP_SUCCESS = "[AUTH] Sign Up success",

  SIGN_UP_FRAME = "[AUTH] Sign Up from frame",
  SIGN_UP_FRAME_SUCCESS = "[AUTH] Sign Up from frame success",
  RESET_SIGN_UP_FRAME_LOADING = "[AUTH] Reset Sign Up from frame",

  FIRST_LOGIN = "[AUTH] First Login",

  TOKEN_SIGN_IN = "[AUTH] Token Sign In",

  RESET_PASSWORD = "[AUTH] Reset Password",
  RESET_PASSWORD_SUCCESS = "[AUTH] Reset Password Success",
  RESET_PASSWORD_ERROR = "[AUTH] Reset Password Error",
  RESET_PASSWORD_LOADING = "[AUTH] Reset Password Loading",

  FORGOT_PASSWORD = "[AUTH] Forgot Password",
  FORGOT_PASSWORD_SUCCESS = "[AUTH] Forgot Password Success",
  FORGOT_PASSWORD_ERROR = "[AUTH] Forgot Password Error",
  RESET_FORGOT_PASSWORD_STATUS = "[AUTH] Reset forgot password status",

  CHECK_SHOW_TRAIL_MSG = "[AUTH] Check show trial msg",
  SHOW_TRIAL_MSG = "[AUTH] Show trial msg",
  HIDE_TRIAL_MSG = "[AUTH] Hide trial msg",

  RESET_AUTH_STATE = "[AUTH] Reset Auth State",
  GET_REFRESH_TOKEN = "[AUTH] Get Refresh Token",

  TOKEN_EXPIRED = "[AUTH] Refresh Token Expired",
  IDLE_TIMER_SHOW_POP_UP = "[AUTH] Show Popup on Idle TimeOut",

  CHECK_USER_NAME = "[AUTH] CHECK_USER_NAME",
  CHECK_USER_NAME_SUCCESS = "[AUTH] CHECK_USER_NAME_SUCCESS",
  CHECK_USER_NAME_ERROR = "[AUTH] CHECK_USER_NAME_ERROR",

  INVITATION_API_CALLS = "[AUTH] INVITATION_API_CALLS",
}

// sign in
export const signInAction: CreateActionType<
  ACTION_TYPES.SIGN_IN,
  ISignInFormData
> = createAction(ACTION_TYPES.SIGN_IN, createPayload<ISignInFormData>());
export const signInErrorAction: CreateActionType<
  ACTION_TYPES.SIGN_IN_ERROR,
  IServerError
> = createAction(ACTION_TYPES.SIGN_IN_ERROR, createPayload<IServerError>());
export const signInSuccessAction: CreateActionType<
  ACTION_TYPES.SIGN_IN_SUCCESS,
  ISuccessLoginActionData
> = createAction(
  ACTION_TYPES.SIGN_IN_SUCCESS,
  createPayload<ISuccessLoginActionData>(),
);

export const resetSignInLoadingAction: ActionCreator<
  ACTION_TYPES.RESET_SIGN_IN_LOADING,
  () => TypedAction<ACTION_TYPES.RESET_SIGN_IN_LOADING>
> = createAction(ACTION_TYPES.RESET_SIGN_IN_LOADING);

// sign out
export const signOutAction: CreateActionType<
  ACTION_TYPES.SIGN_OUT,
  ISignOutData
> = createAction(
  ACTION_TYPES.SIGN_OUT,
  createPayload<ISignOutData>({ withoutNavigate: false }),
);
export const signOutSuccessAction: CreateActionType<
  ACTION_TYPES.SIGN_OUT_SUCCESS,
  ISignOutData
> = createAction(
  ACTION_TYPES.SIGN_OUT_SUCCESS,
  createPayload<ISignOutData>({ withoutNavigate: false }),
);

export const signOutAndGoToAdminPanelAction: ActionCreator<
  ACTION_TYPES.SIGN_OUT_AND_GO_TO_ADMIN_PANEL,
  () => TypedAction<ACTION_TYPES.SIGN_OUT_AND_GO_TO_ADMIN_PANEL>
> = createAction(ACTION_TYPES.SIGN_OUT_AND_GO_TO_ADMIN_PANEL);

// check sign up address
export const checkAddressAction: CreateActionType<
  ACTION_TYPES.CHECK_ADDRESS,
  ICheckAddressSignUpData
> = createAction(
  ACTION_TYPES.CHECK_ADDRESS,
  createPayload<ICheckAddressSignUpData>(),
);
export const checkAddressErrorAction: CreateActionType<
  ACTION_TYPES.CHECK_ADDRESS_ERROR,
  AddressError
> = createAction(
  ACTION_TYPES.CHECK_ADDRESS_ERROR,
  createPayload<AddressError>(),
);
export const checkAddressSuccessAction: CreateActionType<
  ACTION_TYPES.CHECK_ADDRESS_SUCCESS,
  Action | null
> = createAction(
  ACTION_TYPES.CHECK_ADDRESS_SUCCESS,
  createPayload<Action | null>(),
);

// sign up
export const signUpAction: CreateActionType<
  ACTION_TYPES.SIGN_UP,
  ISignUpFormData
> = createAction(ACTION_TYPES.SIGN_UP, createPayload<ISignUpFormData>());
export const signUpErrorAction: CreateActionType<
  ACTION_TYPES.SIGN_UP_ERROR,
  IServerError
> = createAction(ACTION_TYPES.SIGN_UP_ERROR, createPayload<IServerError>());
export const signUpSuccessAction: ActionCreator<
  ACTION_TYPES.SIGN_UP_SUCCESS,
  () => TypedAction<ACTION_TYPES.SIGN_UP_SUCCESS>
> = createAction(ACTION_TYPES.SIGN_UP_SUCCESS);

export const resetSignUpLoadingAction: ActionCreator<
  ACTION_TYPES.RESET_SIGN_UP_LOADING,
  () => TypedAction<ACTION_TYPES.RESET_SIGN_UP_LOADING>
> = createAction(ACTION_TYPES.RESET_SIGN_UP_LOADING);

export const signUpByFrameAction: CreateActionType<
  ACTION_TYPES.SIGN_UP_FRAME,
  ISignUpFormData
> = createAction(ACTION_TYPES.SIGN_UP_FRAME, createPayload<ISignUpFormData>());
export const signUpByFrameSuccessAction: CreateActionType<
  ACTION_TYPES.SIGN_UP_FRAME_SUCCESS,
  ISuccessLoginActionData
> = createAction(
  ACTION_TYPES.SIGN_UP_FRAME_SUCCESS,
  createPayload<ISuccessLoginActionData>(),
);

export const resetSignUpByFrameLoadingAction: ActionCreator<
  ACTION_TYPES.RESET_SIGN_UP_FRAME_LOADING,
  () => TypedAction<ACTION_TYPES.RESET_SIGN_UP_FRAME_LOADING>
> = createAction(ACTION_TYPES.RESET_SIGN_UP_FRAME_LOADING);

// first login
export const firstLoginAction: CreateActionType<
  ACTION_TYPES.FIRST_LOGIN,
  IFirstLoginFormData
> = createAction(
  ACTION_TYPES.FIRST_LOGIN,
  createPayload<IFirstLoginFormData>(),
);

// sign in by token
export const tokenSignInAction: CreateActionType<
  ACTION_TYPES.TOKEN_SIGN_IN,
  ISuccessLoginActionData
> = createAction(
  ACTION_TYPES.TOKEN_SIGN_IN,
  createPayload<ISuccessLoginActionData>(),
);

// reset password
export const resetPasswordAction: CreateActionType<
  ACTION_TYPES.RESET_PASSWORD,
  IFirstLoginFormData
> = createAction(
  ACTION_TYPES.RESET_PASSWORD,
  createPayload<IFirstLoginFormData>(),
);
export const resetPasswordErrorAction: CreateActionType<
  ACTION_TYPES.RESET_PASSWORD_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RESET_PASSWORD_ERROR,
  createPayload<IServerError>(),
);
export const resetPasswordSuccessAction: CreateActionType<
  ACTION_TYPES.RESET_PASSWORD_SUCCESS,
  ISuccessMessageResponse
> = createAction(
  ACTION_TYPES.RESET_PASSWORD_SUCCESS,
  createPayload<ISuccessMessageResponse>(),
);

export const resetPasswordLoadingAction: ActionCreator<
  ACTION_TYPES.RESET_PASSWORD_LOADING,
  () => TypedAction<ACTION_TYPES.RESET_PASSWORD_LOADING>
> = createAction(ACTION_TYPES.RESET_PASSWORD_LOADING);

// forgot password
export const forgotPasswordAction: CreateActionType<
  ACTION_TYPES.FORGOT_PASSWORD,
  IForgotPasswordData
> = createAction(
  ACTION_TYPES.FORGOT_PASSWORD,
  createPayload<IForgotPasswordData>(),
);
export const forgotPasswordErrorAction: CreateActionType<
  ACTION_TYPES.FORGOT_PASSWORD_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.FORGOT_PASSWORD_ERROR,
  createPayload<IServerError>(),
);
export const forgotPasswordSuccessAction: CreateActionType<
  ACTION_TYPES.FORGOT_PASSWORD_SUCCESS,
  IForgotPasswordResponse
> = createAction(
  ACTION_TYPES.FORGOT_PASSWORD_SUCCESS,
  createPayload<IForgotPasswordResponse>(),
);

export const resetForgotPasswordStatusAction: ActionCreator<
  ACTION_TYPES.RESET_FORGOT_PASSWORD_STATUS,
  () => TypedAction<ACTION_TYPES.RESET_FORGOT_PASSWORD_STATUS>
> = createAction(ACTION_TYPES.RESET_FORGOT_PASSWORD_STATUS);

// check show trial msg
export const checkShowTrialMsgAction: ActionCreator<
  ACTION_TYPES.CHECK_SHOW_TRAIL_MSG,
  () => TypedAction<ACTION_TYPES.CHECK_SHOW_TRAIL_MSG>
> = createAction(ACTION_TYPES.CHECK_SHOW_TRAIL_MSG);
export const showTrialMsgAction: CreateActionType<
  ACTION_TYPES.SHOW_TRIAL_MSG,
  number
> = createAction(ACTION_TYPES.SHOW_TRIAL_MSG, createPayload<number>());
// trialSeatExpirationDate
export const hideTrialMsgAction: ActionCreator<
  ACTION_TYPES.HIDE_TRIAL_MSG,
  () => TypedAction<ACTION_TYPES.HIDE_TRIAL_MSG>
> = createAction(ACTION_TYPES.HIDE_TRIAL_MSG);

// reset state
export const resetAuthStateAction: ActionCreator<
  ACTION_TYPES.RESET_AUTH_STATE,
  () => TypedAction<ACTION_TYPES.RESET_AUTH_STATE>
> = createAction(ACTION_TYPES.RESET_AUTH_STATE);

// reset Token
export const getRefreshTokenAction: ActionCreator<
  ACTION_TYPES.GET_REFRESH_TOKEN,
  () => TypedAction<ACTION_TYPES.GET_REFRESH_TOKEN>
> = createAction(ACTION_TYPES.GET_REFRESH_TOKEN);

// verify username existence
export const checkUserNameAction: CreateActionType<
  ACTION_TYPES.CHECK_USER_NAME,
  string
> = createAction(ACTION_TYPES.CHECK_USER_NAME, createPayload<string>());

export const checkUserNameErrorAction: CreateActionType<
  ACTION_TYPES.CHECK_USER_NAME_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CHECK_USER_NAME_ERROR,
  createPayload<IServerError>(),
);

export const checkUserNameSuccessAction: CreateActionType<
  ACTION_TYPES.CHECK_USER_NAME_SUCCESS,
  ICheckUserNameResponse
> = createAction(
  ACTION_TYPES.CHECK_USER_NAME_SUCCESS,
  createPayload<ICheckUserNameResponse>(),
);

// reset Token
export const invitationAPICallsAction: ActionCreator<
  ACTION_TYPES.INVITATION_API_CALLS,
  () => TypedAction<ACTION_TYPES.INVITATION_API_CALLS>
> = createAction(ACTION_TYPES.INVITATION_API_CALLS);
