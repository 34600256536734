import { Pipe, PipeTransform } from "@angular/core";

import {
  INSTITUTION_PROFILE_TYPES,
  INSTITUTION_TYPE_DESCRIPTION,
} from "@shared/modules/institution-profiles/interfaces/intitution-profiles";

@Pipe({
  name: "institutionTypes",
})
export class InstitutionTypesPipe implements PipeTransform {
  transform(
    value: string,
    valuesForCheck: string[] = INSTITUTION_PROFILE_TYPES,
  ): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    if (valuesForCheck.includes(value)) {
      return INSTITUTION_TYPE_DESCRIPTION.StateDepartment;
    }

    return value;
  }
}
