import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { IOmsOAuthByRedirectionSocketResponse } from "@modules/oms/interfaces";

import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-oms-auth-redirect-pop-up",
  templateUrl: "./oms-auth-redirect-pop-up.component.html",
  styleUrls: ["./oms-auth-redirect-pop-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmsAuthRedirectPopUpComponent {
  get redirectLink(): string {
    return this._data.url;
  }

  constructor(
    private _ref: PopUpRef<OmsAuthRedirectPopUpComponent>,
    @Inject(POP_UP_DATA) private _data: IOmsOAuthByRedirectionSocketResponse,
  ) {}

  onCloseClick(): void {
    this._ref.close(false);
  }

  onRedirectBtnClick(): void {
    this._ref.close(true);
  }
}
