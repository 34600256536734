import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Action, Store } from "@ngrx/store";

import { defer, of, Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";

import { getCreditsAction } from "@modules/credits/store/actions";
import { showNotificationAction } from "@modules/notifications/store/actions/notification.action";
import { ActionWithPayload } from "@shared/interfaces/store";
import { catchErrorWithErrorType } from "@shared/utils/error-handlers";
import { IServerError } from "@shared/interfaces/server-error";
import { E_COMMERCE_PATHS } from "@modules/e-commerce/constants/e-commerce-paths";
import { go } from "../actions/router-history.action";
import * as actions from "../actions/show-email.action";
import { CoreState } from "../reducers";

import { PaymentOperationsService } from "../../services/payment-operations.service";

import { CORE_PATHS } from "../../constants/core-paths";
import { NOTIFICATION_TYPES } from "../../constants/notifications";

@Injectable()
export class ShowEmailEffect {
  showEmail$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPaymentEmailByPersonnelIdAction),
      switchMap(({ payload: personId }: ActionWithPayload<number>) => {
        return this._service.showEmail(personId).pipe(
          switchMap(({ email }: { email: string }) => [
            getCreditsAction(),
            actions.getPaymentEmailByPersonnelIdSuccessAction({
              email,
              personId,
            }),
          ]),
          catchError((error: IServerError) =>
            of(actions.getPaymentEmailByPersonnelIdErrorAction(error)),
          ),
        );
      }),
      catchErrorWithErrorType,
    ),
  );

  redirectToPricingPageIfError$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.getPaymentEmailByPersonnelIdErrorAction),
          tap(({ payload: error }: ActionWithPayload<IServerError>) => {
            if (error && error.message === "You do not have enough credits.") {
              this._store.dispatch(
                go(["/", CORE_PATHS.ECOMM, E_COMMERCE_PATHS.PRICING], {
                  type: "email",
                }),
              );
            }

            this._store.dispatch(
              showNotificationAction({
                type: NOTIFICATION_TYPES.ERROR,
                message: error && error.message,
                timeout: 3000,
              }),
            );
          }),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _store: Store<CoreState>,
    private _service: PaymentOperationsService,
  ) {}
}
