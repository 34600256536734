import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { IAccountUser } from "@modules/profile/interfaces/marketview";
import { IControlOptions } from "@shared/interfaces/forms";

import { PopUpRef } from "../../models/pop-up-ref";

import { Validators } from "@shared/validators/validators";
import { POP_UP_DATA } from "../../injection-tokens";

export interface IAssignUserDataPopUpData {
  acceptText: string;
  users?: IAccountUser[];
  iconClass?: string;
  title?: string;
  body?: string;
}

@Component({
  selector: "bl-assign-user-data-pop-up-content",
  templateUrl: "./assign-user-data-pop-up-content.component.html",
  styleUrls: ["./assign-user-data-pop-up-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignUserDataPopUpContentComponent {
  form: UntypedFormGroup = this.fb.group({
    userId: [null, Validators.required()],
  });

  get usersControlOptions(): IControlOptions {
    return this.data.users.map(({ firstName, lastName, id }: IAccountUser) => ({
      value: id,
      label: `${firstName} ${lastName}`,
    }));
  }

  constructor(
    private popUpRef: PopUpRef<
      AssignUserDataPopUpContentComponent,
      { answer: boolean; userToAssignId?: number }
    >,
    @Inject(POP_UP_DATA) public data: IAssignUserDataPopUpData,
    private fb: UntypedFormBuilder,
  ) {}

  onClose(): void {
    this.popUpRef.close({ answer: false });
  }

  onConfirm(): void {
    if (!this.form.valid) {
      return;
    }

    this.popUpRef.close({
      answer: true,
      userToAssignId: this.form.value.userId,
    });
  }
}
