import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { select, Action, Store } from "@ngrx/store";

import { defer, Observable } from "rxjs";
import { filter, take, tap, withLatestFrom } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { ActionWithPayload } from "@shared/interfaces/store";
import * as invitationActions from "../../../auth/store/actions/invitation.action";
import * as actions from "../actions/account-pop-ups.action";
import * as accountUsersActions from "../actions/account-users.action";
import {
  getIsHasProductsOrCredits,
  getUsersWithoutSelf,
} from "../selectors/account-users.selector";
import {
  getUsersWithoutEditingUserAndInvited,
  getUsersWithoutEditingUserId,
} from "../selectors/edit-user-account.selector";
import { getUserCompanyName } from "../selectors/profile.selector";

import {
  AssignUserDataPopUpContentComponent,
  IAssignUserDataPopUpData,
} from "@ui/pop-up/components/assign-user-data-pop-up-content/assign-user-data-pop-up-content.component";
import { ConfirmOrNotPopUpComponent } from "@ui/pop-up/components/confirm-or-not-pop-up/confirm-or-not-pop-up.component";
import { ConfirmPopUpContentComponent } from "@ui/pop-up/components/confirm-pop-up-content/confirm-pop-up-content.component";
import { UserCreditsInfoPopUpComponent } from "@ui/pop-up/components/user-credits-info-pop-up/user-credits-info-pop-up.component";

import { PopUpService } from "@ui/pop-up/services/pop-up/pop-up.service";
import { AccountUsersService } from "../../services/account-users.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";
import { sortByField } from "../../utils/sort";

import { IInvitationUser } from "../../../auth/interfaces/invitation";
import {
  IAccountUser,
  IEditAccountUser,
  IRemoveUserFromAccount,
} from "../../interfaces/marketview";
import { IRemoveCreditInfo } from "../../interfaces/pop-ups";

import {
  ConfirmOrNotPopUpData,
  ConfirmPopUpData,
} from "@ui/pop-up/models/pop-up-data";
import { ConfirmOrNotPopUpResult } from "@ui/pop-up/models/pop-up-result";

import {
  getAssignOwnerMergePopUpData,
  getRemoveUserWithDataPopUp,
  getUserCreditsInfoPopUpData,
  ASSIGN_DATA_POP_UP,
  ASSIGN_OWNER_BY_INVITATION_POP_UP,
  ASSIGN_OWNER_POP_UP,
  ASSIGN_OWNER_SUCCESS_INFO_POP_UP,
  DELETE_USER_AND_ASSIGN_POP_UP,
  REMOVE_USER_WITHOUT_DATA,
} from "@ui/pop-up/constants/pop-up-data";

@Injectable()
export class AccountUsersPopUpsEffect {
  constructor(
    private _actions$: Actions,
    private _accountUsersService: AccountUsersService,
    private _popUpService: PopUpService,
    private _store: Store<CoreState>,
  ) {}

  openRemoveUserPopUp$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openRemoveUserPopUpAction),
          tap(
            ({
              payload: { userId },
            }: ActionWithPayload<IRemoveUserFromAccount>) =>
              this._popUpService
                .open<ConfirmOrNotPopUpComponent, ConfirmOrNotPopUpData>(
                  ConfirmOrNotPopUpComponent,
                  {
                    data: REMOVE_USER_WITHOUT_DATA,
                    withOverlayBackground: true,
                  },
                )
                .afterClose.pipe(take(1))
                .subscribe(({ answer, onDecline }: ConfirmOrNotPopUpResult) => {
                  if (answer) {
                    this._store.dispatch(
                      accountUsersActions.removeUserAction({ userId }),
                    );
                  } else {
                    this._store.dispatch(actions.closeRemoveUserPopUpAction());
                  }
                }),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  openRemoveUserWithDataPopUp$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openRemoveUserWithDataPopUpAction),
          tap(({ payload }: ActionWithPayload<IEditAccountUser>) => {
            const {
              dataCount: { lists, views },
              id,
              firstName,
            }: IEditAccountUser = payload;
            this._popUpService
              .open<ConfirmOrNotPopUpComponent, ConfirmOrNotPopUpData>(
                ConfirmOrNotPopUpComponent,
                {
                  data: getRemoveUserWithDataPopUp(firstName, lists, views),
                  withOverlayBackground: true,
                },
              )
              .afterClose.pipe(take(1))
              .subscribe(({ answer, onDecline }: ConfirmOrNotPopUpResult) => {
                if (onDecline) {
                  this._store.dispatch(
                    accountUsersActions.removeUserAction({ userId: id }),
                  );
                }

                if (answer) {
                  this._store.dispatch(
                    actions.openRemoveAndAssignPopUpAction(payload),
                  );
                }
              });
          }),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  openRemoveAndAssignPopUp$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openRemoveAndAssignPopUpAction),
          withLatestFrom(
            this._store.pipe(select(getUsersWithoutEditingUserId)),
          ),
          tap(
            ([{ payload }, users]: [
              ActionWithPayload<IEditAccountUser>,
              IAccountUser[],
            ]) => {
              const {
                dataCount: { credits, emails },
                id,
              }: IEditAccountUser = payload;
              const sortedUsers: IAccountUser[] = sortByField(
                users,
                "firstName",
              );

              this._popUpService
                .open<
                  AssignUserDataPopUpContentComponent,
                  IAssignUserDataPopUpData
                >(AssignUserDataPopUpContentComponent, {
                  data: {
                    ...DELETE_USER_AND_ASSIGN_POP_UP,
                    users: sortedUsers.filter(
                      (user: IAccountUser) => !user.isInvited,
                    ),
                  },
                  withOverlayBackground: true,
                })
                .afterClose.pipe(take(1))
                .subscribe(
                  ({
                    answer,
                    userToAssignId,
                  }: {
                    answer: boolean;
                    userToAssignId?: number;
                  }) => {
                    if (answer) {
                      this._store.dispatch(
                        accountUsersActions.assignDataAndRemoveUserAction({
                          fromUserId: id,
                          toUserId: userToAssignId,
                        }),
                      );
                    } else {
                      this._store.dispatch(
                        actions.closeRemoveAndAssignPopUpAction(),
                      );
                    }
                  },
                );
            },
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  openAssignDataPopUp$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openAssignDataPopUpAction),
          withLatestFrom(
            this._store.pipe(select(getUsersWithoutEditingUserAndInvited)),
          ),
          tap(
            ([{ payload }, users]: [
              ActionWithPayload<number>,
              IAccountUser[],
            ]) =>
              this._popUpService
                .open<
                  AssignUserDataPopUpContentComponent,
                  IAssignUserDataPopUpData
                >(AssignUserDataPopUpContentComponent, {
                  data: {
                    ...ASSIGN_DATA_POP_UP,
                    users,
                  },
                  withOverlayBackground: true,
                })
                .afterClose.pipe(
                  take(1),
                  filter(
                    ({
                      answer,
                    }: {
                      answer: boolean;
                      userToAssignId?: number;
                    }) => answer,
                  ),
                )
                .subscribe(
                  ({
                    answer,
                    userToAssignId,
                  }: {
                    answer: boolean;
                    userToAssignId?: number;
                  }) =>
                    this._store.dispatch(
                      accountUsersActions.assignDataAction({
                        fromUserId: payload,
                        toUserId: userToAssignId,
                      }),
                    ),
                ),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  addJobSuccess$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openCreditsInfoPopUpAction),
          tap(({ payload: infoData }: ActionWithPayload<IRemoveCreditInfo>) =>
            this._popUpService.open(UserCreditsInfoPopUpComponent, {
              showCloseBtn: true,
              data: getUserCreditsInfoPopUpData(infoData),
              withOverlayBackground: true,
            }),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  openAssignOwnerPopUp$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openAssignOwnerPopUpAction),
          withLatestFrom(
            this._store.pipe(select(getUsersWithoutEditingUserAndInvited)),
          ),
          tap(([action, users]: [Action, IAccountUser[]]) =>
            this._popUpService
              .open<
                AssignUserDataPopUpContentComponent,
                IAssignUserDataPopUpData
              >(AssignUserDataPopUpContentComponent, {
                data: {
                  ...ASSIGN_OWNER_POP_UP,
                  users,
                },
                withOverlayBackground: true,
              })
              .afterClose.pipe(
                take(1),
                filter(
                  ({ answer }: { answer: boolean; userToAssignId?: number }) =>
                    answer,
                ),
              )
              .subscribe(
                ({
                  answer,
                  userToAssignId,
                }: {
                  answer: boolean;
                  userToAssignId?: number;
                }) =>
                  this._store.dispatch(
                    accountUsersActions.assignOwnerAction(userToAssignId),
                  ),
              ),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  openAssignOwnerByInvitationPopUp$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openAssignOwnerByInvitationPopUpAction),
          withLatestFrom(
            this._store.pipe(select(getIsHasProductsOrCredits)),
            this._store.pipe(select(getUsersWithoutSelf)),
            this._store.pipe(select(getUserCompanyName)),
          ),
          tap(
            ([
              { payload: invitation },
              isHasProductsOrCredits,
              users,
              existAccountName,
            ]: [
              ActionWithPayload<IInvitationUser>,
              boolean,
              IAccountUser[],
              string,
            ]) =>
              this._popUpService
                .open<
                  AssignUserDataPopUpContentComponent,
                  IAssignUserDataPopUpData
                >(AssignUserDataPopUpContentComponent, {
                  data: {
                    ...ASSIGN_OWNER_BY_INVITATION_POP_UP,
                    users,
                  },
                  withOverlayBackground: true,
                })
                .afterClose.pipe(take(1))
                .subscribe((data) => {
                  //   answer: boolean;
                  //   userToAssignId?: number;
                  if (data && data["answer"] !== undefined) {
                    let answer = data["answer"];
                    let userToAssignId = data["answer"];
                    if (answer) {
                      this._store.dispatch(
                        invitationActions.updateInvitationTokenAction({
                          newOwnerId: +userToAssignId,
                        }),
                      );

                      if (isHasProductsOrCredits) {
                        this._store.dispatch(
                          actions.openAssignOwnerMergePopUpAction({
                            accountName: invitation.accountName,
                            existAccountName: existAccountName,
                          }),
                        );
                      } else {
                        this._store.dispatch(
                          invitationActions.invitationAndShowInfoPopUpAction({
                            ...invitation,
                            newOwnerId: +userToAssignId,
                          }),
                        );
                      }
                    } else {
                      this._store.dispatch(
                        invitationActions.removeInvitationTokenAction(),
                      );
                    }
                  }
                }),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  openAssignOwnerInfoPopUp$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openAssignOwnerInfoPopUpAction),
          tap(() =>
            this._popUpService
              .open<ConfirmPopUpContentComponent, ConfirmPopUpData>(
                ConfirmPopUpContentComponent,
                {
                  data: {
                    ...ASSIGN_OWNER_SUCCESS_INFO_POP_UP,
                  },
                  withOverlayBackground: true,
                },
              )
              .afterClose.subscribe(() =>
                this._store.dispatch(
                  invitationActions.removeInvitationTokenAction(),
                ),
              ),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  openAssignOwnerMergePopUp$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openAssignOwnerMergePopUpAction),
          tap(() =>
            this._popUpService
              .open<ConfirmOrNotPopUpComponent, ConfirmOrNotPopUpData>(
                ConfirmOrNotPopUpComponent,
                {
                  data: getAssignOwnerMergePopUpData(),
                  withOverlayBackground: true,
                },
              )
              .afterClose.subscribe(({ answer }: ConfirmOrNotPopUpResult) =>
                this._store.dispatch(
                  answer
                    ? invitationActions.invitationWithoutNotificationAction()
                    : invitationActions.removeInvitationTokenAction(),
                ),
              ),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  closePopUps$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(
            actions.closeRemoveUserPopUpAction,
            actions.closeRemoveUserWithDataPopUpAction,
            actions.closeRemoveAndAssignPopUpAction,
            actions.closeAssignDataPopUpAction,
            actions.closeAssignOwnerPopUpAction,
            actions.closeAssignOwnerByInvitationPopUpAction,
            actions.closeAssignOwnerInfoPopUpAction,
            actions.closeAssignOwnerMergePopUpAction,
          ),
          tap(() => this._popUpService.close()),
        ),
      ),
    { dispatch: false },
  );
}
