import { createFeatureSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import {
  DASHBOARD_LIST_STATE_NAME,
  IDashboardListsModuleState,
} from "../reducers";

export const getDashboardListsModuleState: GetFromStateInSelectors<
  CoreState,
  IDashboardListsModuleState
> = createFeatureSelector<IDashboardListsModuleState>(
  DASHBOARD_LIST_STATE_NAME,
);
