import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { throwError, Observable } from "rxjs";
import { catchError } from "rxjs/operators";

import { StorageService } from "@core/services/storage.service";

import { ERROR_MSG } from "@core/constants/error-page";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  static applyCredentials<T>(
    req: HttpRequest<T>,
    token: string,
    internal: string,
  ): HttpRequest<T> {
    if (token) {
      const headers: { Authorization: string; Internal: string } = {
        Authorization: token || "",
        Internal: internal,
        // 'Content-Type': 'application/json' COMMENTED BT TASK BL-5103
      };

      return req.clone({
        setHeaders: headers,
      });
    }
    return req;
  }

  static isAuthTokenError(error: any): boolean {
    return (
      error instanceof HttpErrorResponse &&
      error.error.message === ERROR_MSG.TOKEN_EXPIRED
    );
  }

  static isAuthError(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 401;
  }

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const token = this.oktaAuth.getAccessToken()
      ? "Bearer " + this.oktaAuth.getAccessToken()
      : StorageService.token;

    const Internal = this.oktaAuth.getAccessToken() ? "false" : "true";

    if (req.url.includes(environment.excludeAuth)) {
      return next.handle(req);
    }

    if (req.url.includes("maps.googleapis")) {
      return next.handle(req);
    }

    return next
      .handle(AuthInterceptor.applyCredentials(req, token, Internal))
      .pipe(
        catchError((error: HttpErrorResponse) => {
          //  if is "Unauthorized" error
          if (AuthInterceptor.isAuthError(error)) {
            if (!environment.production) {
              console.log("wrong or expired token without remember flag");
            }

            return throwError(error);
          }

          // TODO handle status 403

          // else throw error;
          if (!environment.production) {
            console.log("unexpected http error");
          }

          return throwError(error);
        }),
      );
  }
}
