import { ICloudSyncPlatform } from "@core/interfaces/cloud-sync";
import {
  ICreditCardInfo,
  IDefaultBillingInfo,
  INewCreditCartInfo,
} from "@modules/e-commerce/interfaces/e-commerce";
import { IListType } from "@shared/interfaces/list";
import {
  IChildInstitutionOnlyConfig,
  IParentInstitutionOnlyConfig,
} from "../interfaces";

import { IncludeRecord } from "@core/constants/includedListRecords";

export class PopUpText {
  title?: string;
  body?: string | Function;
}

export class MultiDownloadPopUpData {
  link: string;
}

export class InfoPopUpData {
  body: string;
  icon: string;
}

export class ListTypesData {
  listTypes: IListType[];
  includedListRecords: IncludeRecord[];
  listTypesHeading: string[];
}

export class ConfirmPopUpData extends PopUpText {
  iconClass: string;
  buttonText: string;
  shouldCallLink?: boolean;
  hideDoNotShowCheckbox?: boolean;
  updateStorage?: (checkBoxValue: boolean) => void;
}

export class ConfirmOrNotPopUpData extends PopUpText {
  acceptText: string;
  declineText: string;
  iconClass?: string;
  additionalText?: string;
  showDoNotShowPopUpCheckbox?: boolean;
  dataTestIdForAcceptButton?: string;
  buttonWidth?: string;
  justifyContent?: string;
  subtitleMarginBottom?: string;
  mainWidth?: string;
  mainPaddingTop?: string;
  iconPadding?: string;
}

export class EditInfoPopUpData extends PopUpText {
  acceptText: string;
  declineText: string;
  comment?: string;
  lengthLimit?: number;
}

export class NewPaymentMethodPopUpData {
  constructor(
    public title: string,
    public successButtonText: string = "apply",
    public newCreditCard?: INewCreditCartInfo,
    public defaultBillingInfo?: IDefaultBillingInfo,
    public invalidFields?: string[],
    public topErrorMsg?: string,
  ) {}
}

export class DefaultPaymentMethodPopUpData {
  constructor(
    public title: string,
    public successButtonText: string = "apply",
    public defaultCreditCard: ICreditCardInfo,
    public defaultBillingInfo?: IDefaultBillingInfo,
    public invalidFields?: string[],
    public topErrorMsg?: string,
  ) {}
}

export class SuccessPopUpData {
  title: string;
  iconClass: string;
}

export interface IInfoPopUpData {
  icon: string;
  title: string;
  message: string;
  buttonValue: string;
}

export class UserCreditsInfoPopUp {
  title: string;
  header: string;
  body: string;
  buttonText: string;
}

export class FirstSignUpPopUpData {
  title: string;
  body: string;
  message: string;
  actions: { [key: string]: () => any } | null;
}

export class PremiumPopUpData {
  title: string;
  body: string;
  checkboxText: string;
  updateStorage: (checkBoxValue?: boolean) => void;
}

export const UNABLE_TO_DELETE_SEGMENT_POP_UP: IInfoPopUpData = Object.freeze({
  icon: "",
  title: "Unable to delete",
  message: `All Lists and Views must have at lease 1 active segment. To delete this segment, you must add another segment first`,
  buttonValue: "Got it",
});

export const DEFAULT_SHOULD_CALCULATED_DATA: IInfoPopUpData = Object.freeze({
  icon: "",
  title: "List Count Estimate",
  message: `Because you are using the “Max Records in each Building”
   functionality in one or more segments, your list count will only display the maximum number
    of records your list can provide. In order to get the actual count,
     click Calculate Counts in the lower right and we will notify you once it is done processing.`,
  buttonValue: "Got it",
});

export const ADD_PARENT_INSTITUTION_DATA: IParentInstitutionOnlyConfig =
  Object.freeze({
    title: `Add parent institution only, or parent and children institutions?`,
    message: `You've selected a parent organization or district.
  Choose whether you'd like to select the one parent or the parent and all the institutions that report to it.`,
    parentOnlyText: `Parent Only`,
    parentAndChildrenText: `Parent & Children`,
    checkboxMessage: `Apply to all parent institutions in this list `,
  });

export const ADD_MULTI_PARENT_INSTITUTION_DATA: IParentInstitutionOnlyConfig =
  Object.freeze({
    title: "Add parent institutions only, or parent and children institutions?",
    message: `Your upload may contain parent organizations or districts.
  Choose whether you\'d like to include the parents only or the parents with the institutions that report to it.`,
    parentOnlyText: `Parent Only`,
    checkboxMessage: ``,
    parentAndChildrenText: `Parent & Children`,
    withoutCheckbox: true,
  });

export const ADD_FILE_PARENT_INSTITUTION_DATA: IParentInstitutionOnlyConfig =
  Object.freeze({
    title: "Add parent institutions only, or parent and children institutions?",
    message: `Your upload may contain parent organizations or districts. Choose\n
   whether you'd like to include the parents only or the parents with the\n
    institutions that report to it.`,
    parentOnlyText: `Parent Only`,
    checkboxMessage: ``,
    parentAndChildrenText: `Parent & Children`,
    withoutCheckbox: true,
  });

export const REMOVE_CHILD_INSTITUTION_DATA: IParentInstitutionOnlyConfig =
  Object.freeze({
    title: "Keep parent only?",
    message:
      "Would you like to remove all the institutions that report to this parent institution?",
    parentOnlyText: "Yes, Parent Only",
    parentAndChildrenText: "No, Keep Children",
    checkboxMessage: "Apply to all parent institution in this list",
  });

export const MV_TO_LIST_PARENT: IParentInstitutionOnlyConfig = Object.freeze({
  title: "Add parent institutions only, or parents and children institutions?",
  message: `You\'ve selected parent organization(s) or district(s). Choose whether you\'d like to select just the
  parent(s) or the parent(s) and all the institutions that report to it.`,
  parentOnlyText: "Parent(s) Only",
  parentAndChildrenText: "Parent(s) & Children",
  checkboxMessage: "Apply to all parent institution in this list",
});

export const ADD_CHILD_INSTITUTION_DATA: IChildInstitutionOnlyConfig =
  Object.freeze({
    title: "Add child institution only, or child and parent?",
    message: `You\'ve selected a child organization or district. Choose whether you\'d like to select the one child
  or the child, its parent, and its sibling institutions.`,
    childOnlyText: "Child Only",
    childAndParentText: "Child & Parent",
    parentAndAllChildrenText: "Parent & All Children",
    checkboxMessage: "Apply to all children institutions in this list",
    withoutCheckbox: false,
  });

export const MV_TO_LIST_CHILDREN: IChildInstitutionOnlyConfig = Object.freeze({
  title: "Add children institution(s) only, or children and parent(s) ?",
  message: `You've selected children organization(s) or district(s). Choose whether you'd like to select just children
  or the children, their parent(s), and their sibling institutions.`,
  childOnlyText: "Children Only",
  childAndParentText: "Children & Parent(s)",
  parentAndAllChildrenText: "Parent & All Siblings",
  checkboxMessage: "Apply to all children institutions in this list",
  withoutCheckbox: false,
});

export class CloudSyncToPopUpData {
  platforms: ICloudSyncPlatform[];
}

export class ICloudSyncToResult {
  onCancel: boolean;
  platform: ICloudSyncPlatform | null;
}
