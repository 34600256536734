import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-personnel-all.action";

import {
  IPersonnelRecord,
  IPersonnelRecords,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { PERSONNEL_DEFAULT_PARAMS } from "@shared/constants/data/product-results";
import { CREATE_VIEW_LIST_TYPE } from "../../constants";

export interface IViewResultsAllPersonnelState {
  personnel: IPersonnelRecord[];
  pending: boolean;
  error: IServerError | null;
  count: number;
}

const initialState: IViewResultsAllPersonnelState = {
  personnel: [],
  pending: false,
  error: null,
  count: 0,
};

const getViewResultsAllPersonnelAll: OnReducer<
  IViewResultsAllPersonnelState,
  ActionType<any>
> = (state: IViewResultsAllPersonnelState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsAllPersonnelAllError: OnReducer<
  IViewResultsAllPersonnelState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsAllPersonnelState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsAllPersonnelAllSuccess: OnReducer<
  IViewResultsAllPersonnelState,
  ActionType<Payload<any>>
> = (state: IViewResultsAllPersonnelState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  personnel: [...payload.items],
  count: payload.count,
});

const setViewResultsPersonnelAllSpinnerState: OnReducer<
  IViewResultsAllPersonnelState,
  ActionType<Payload<any>>
> = (state: IViewResultsAllPersonnelState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsAllPersonnelState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsAllPersonnelState> =
  createReducer<IViewResultsAllPersonnelState>(
    initialState,

    on(
      actions.getViewResultsAllPersonnelAllAction,
      getViewResultsAllPersonnelAll,
    ),
    on(
      actions.getViewResultsAllPersonnelAllErrorAction,
      getViewResultsAllPersonnelAllError,
    ),
    on(
      actions.getViewResultsAllPersonnelAllSuccessAction,
      getViewResultsAllPersonnelAllSuccess,
    ),

    on(
      actions.setViewResultsPersonnelAllSpinnerStateAction,
      setViewResultsPersonnelAllSpinnerState,
    ),

    on(
      actions.resetViewResultsAllPersonnelStateAction,
      resetViewResultsAllPersonnelState,
    ),
  );

export function viewResultsAllPersonnelReducer(
  state: IViewResultsAllPersonnelState,
  action: Action,
): IViewResultsAllPersonnelState {
  return reducer(state, action);
}

export const allPersonnel: GetFromState<
  IPersonnelRecord[],
  IViewResultsAllPersonnelState
> = (state: IViewResultsAllPersonnelState): IPersonnelRecord[] =>
  state.personnel;
export const allPersonnelError: GetFromState<
  IServerError,
  IViewResultsAllPersonnelState
> = (state: IViewResultsAllPersonnelState): IServerError => state.error;
export const allPersonnelPending: GetFromState<
  boolean,
  IViewResultsAllPersonnelState
> = (state: IViewResultsAllPersonnelState): boolean => state.pending;
export const allPersonnelCount: GetFromState<
  number,
  IViewResultsAllPersonnelState
> = (state: IViewResultsAllPersonnelState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const { sortBy }: Params = queryParams;

  const params: Params = {
    ...PERSONNEL_DEFAULT_PARAMS,
    ...queryParams,
    sortBy: sortBy || "lastName",
    viewId,
    filterBy: "all",
  };

  return segmentId ? { ...params, segmentId } : params;
};

export const selectedAllPersonnelIds: GetFromState<
  number[],
  number[],
  number[],
  number[]
> = (districts: number[], schools: number[], colleges: number[]): number[] => {
  return [...districts, ...schools, ...colleges];
};

export const currentRecordOrAllRecords: GetFromState<
  IPersonnelRecords,
  IViewResultsAllPersonnelState,
  IPersonnelRecords,
  CREATE_VIEW_LIST_TYPE
> = (
  state: IViewResultsAllPersonnelState,
  currentRecords: IPersonnelRecords,
  type: CREATE_VIEW_LIST_TYPE,
): IPersonnelRecords => {
  return type === CREATE_VIEW_LIST_TYPE.PERSONNEL_ALL
    ? state.personnel
    : currentRecords;
};
