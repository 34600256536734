import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/institution-alerts.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewResultsAlerts } from "../../interfaces";

import { getOrganizedAlerts } from "./all-alerts.reducer";

export interface IViewResultsInstitutionAlertsState {
  alerts: IViewResultsAlerts | null;
  count: number;
  query: string;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IViewResultsInstitutionAlertsState = {
  alerts: null,
  count: 0,
  query: null,
  pending: false,
  error: null,
};

const getViewResultsInstitutionAlertsHandler: OnReducer<
  IViewResultsInstitutionAlertsState,
  ActionType<any>
> = (state: IViewResultsInstitutionAlertsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsInstitutionAlertsErrorHandler: OnReducer<
  IViewResultsInstitutionAlertsState,
  ActionType<any>
> = (
  state: IViewResultsInstitutionAlertsState,
  { payload: error }: Payload<any>,
) => ({
  ...state,
  pending: false,
  error,
});

const getViewResultsInstitutionAlertsSuccessHandler: OnReducer<
  IViewResultsInstitutionAlertsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionAlertsState,
  { payload: { items, count } }: Payload<any>,
) => ({
  ...state,
  count,
  pending: false,
  alerts: items,
});
const changeViewResultInstitutionAlertsQueryHandler: OnReducer<
  IViewResultsInstitutionAlertsState,
  ActionType<Payload<any>>
> = (state: IViewResultsInstitutionAlertsState, { payload }: Payload<any>) => ({
  ...state,
  query: payload,
});

const setViewResultsInstitutionAlertsSpinnerState: OnReducer<
  IViewResultsInstitutionAlertsState,
  ActionType<Payload<any>>
> = (state: IViewResultsInstitutionAlertsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsInstitutionAlertsStateHandler: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsInstitutionAlertsState> =
  createReducer<IViewResultsInstitutionAlertsState>(
    initialState,

    on(
      actions.getViewResultsInstitutionAlertsAction,
      getViewResultsInstitutionAlertsHandler,
    ),
    on(
      actions.getViewResultsInstitutionAlertsErrorAction,
      getViewResultsInstitutionAlertsErrorHandler,
    ),
    on(
      actions.getViewResultsInstitutionAlertsSuccessAction,
      getViewResultsInstitutionAlertsSuccessHandler,
    ),

    on(
      actions.changeViewResultInstitutionAlertsQueryAction,
      changeViewResultInstitutionAlertsQueryHandler,
    ),

    on(
      actions.setViewResultsInstitutionAlertsSpinnerStateAction,
      setViewResultsInstitutionAlertsSpinnerState,
    ),

    on(
      actions.resetViewResultsInstitutionAlertsStateAction,
      resetViewResultsInstitutionAlertsStateHandler,
    ),
  );

export function viewResultsInstitutionAlertsReducer(
  state: IViewResultsInstitutionAlertsState,
  action: Action,
): IViewResultsInstitutionAlertsState {
  return reducer(state, action);
}

export const alerts: GetFromState<
  IViewResultsAlerts,
  IViewResultsInstitutionAlertsState
> = (state: IViewResultsInstitutionAlertsState): IViewResultsAlerts =>
  state && state.alerts;
export const alertsPending: GetFromState<
  boolean,
  IViewResultsInstitutionAlertsState
> = (state: IViewResultsInstitutionAlertsState): boolean =>
  state && state.pending;
export const alertsError: GetFromState<
  IServerError,
  IViewResultsInstitutionAlertsState
> = (state: IViewResultsInstitutionAlertsState): IServerError =>
  state && state.error;

export const alertsCount: GetFromState<
  number,
  IViewResultsInstitutionAlertsState
> = (state: IViewResultsInstitutionAlertsState): number => state && state.count;

export const organizedAlerts: GetFromState<
  { [key: string]: IViewResultsAlerts },
  IViewResultsInstitutionAlertsState
> = (
  state: IViewResultsInstitutionAlertsState,
): { [key: string]: IViewResultsAlerts } => getOrganizedAlerts(state.alerts);
