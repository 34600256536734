import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "bl-custom-select",
  templateUrl: "./custom-select.component.html",
  styleUrls: ["./custom-select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSelectComponent {
  @Input() align: "center" | "left" | "right" = "left";
  @Input() isOpen: boolean;
  @Input() options: any[];
  @Input() selectedOption: any;
  @Input() selectedOptionTpl: TemplateRef<any>;
  @Input() optionTpl: TemplateRef<any>;

  @Output() customSelectOutsideClick: EventEmitter<any> = new EventEmitter();

  @HostListener("document:click", ["$event"])
  outSideClick(event: Event): void {
    if (this.isOpen && !this.element.nativeElement.contains(event.target)) {
      this.customSelectOutsideClick.emit(this.selectedOption);
    }
  }

  constructor(private element: ElementRef) {}
}
