import { ITableHeader } from "@ui/tables/components/table/table.component";

export enum GRANTS_ENTITY_SORTING {
  TITLE = "title",
  YEAR = "createdAt",
}

export const GRANTS_ENTITY_TABLE_HEADERS: ITableHeader[] = [
  {
    key: "title",
    title: "Grant Title",
    sortable: true,
  },
  {
    key: "description",
    title: "Description",
    sortable: true,
  },
  {
    key: "createdAt",
    title: "Year",
    sortable: true,
  },
  {
    key: "totalFunds",
    title: "Amount",
    sortable: true,
  },
];

export const GRANTS_ENTITY_TABLE_HEADERS_MOBILE: ITableHeader[] = [
  {
    key: "title",
    title: "Grant Name",
    sortable: true,
  },
  {
    key: "createdAt",
    title: "Fiscal Year",
    sortable: true,
  },
  {
    key: "totalFunds",
    title: "Total Funds",
    sortable: true,
  },
];

export const GRANTS_ENTITY_TABLE_DESCRIPTION_MAX_LENGTH: number = 280;
export const MAX_GRANTS_ENTITY_CACHE: number = 180;
