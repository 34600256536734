import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import * as actions from "@shared/modules/bids-entity/store/actions/bids-entity.action";
import { GetFromState } from "@shared/store/types/reducer.types";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IBidsEntity,
  IBidsEntityResponse,
  IGetBidsEntity,
  ISortBidsEntity,
} from "@shared/modules/bids-entity/interfaces";

import { SORT_DIRECTION } from "@shared/constants/sort";
import { BIDS_ENTITY_SORTING } from "@shared/modules/bids-entity/constants";

export interface IBidsEntityState {
  bids: IBidsEntity[];
  pending: boolean;
  loaded: boolean;
  bidsIsOpen: boolean;
  error: IServerError | null;

  count: number;

  limit: number;
  offset: number;
  sortBy: BIDS_ENTITY_SORTING;
  orderBy: SORT_DIRECTION;
}

const initialState: IBidsEntityState = {
  bids: [],
  pending: false,
  loaded: false,
  bidsIsOpen: false,
  error: null,

  count: 0,

  limit: 10,
  offset: 0,
  sortBy: BIDS_ENTITY_SORTING.SUBMITTAL,
  orderBy: SORT_DIRECTION.DESC,
};

const getBidsEntity: OnReducer<
  IBidsEntityState,
  ActionType<Payload<IGetBidsEntity>>
> = (state: IBidsEntityState, { payload }: Payload<IGetBidsEntity>) => ({
  ...state,
  pending: true,
  error: null,
  offset:
    typeof payload.page === "number" ? (payload.page - 1) * state.limit : 0,
});

const getBidsEntityError: OnReducer<
  IBidsEntityState,
  ActionType<Payload<IServerError>>
> = (state: IBidsEntityState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getBidsEntitySuccess: OnReducer<
  IBidsEntityState,
  ActionType<Payload<IBidsEntityResponse>>
> = (state: IBidsEntityState, { payload }: Payload<IBidsEntityResponse>) => ({
  ...state,
  pending: false,
  loaded: true,
  bids: [...payload.items],
  count: payload.count,
  bidsIsOpen: !![...state.bids, ...payload.items].length,
});

const sortBidsEntity: OnReducer<
  IBidsEntityState,
  ActionType<Payload<ISortBidsEntity>>
> = (state: IBidsEntityState, { payload }: Payload<ISortBidsEntity>) => ({
  ...state,
  ...payload.params,
  pending: true,
  error: null,
});

const sortBidsEntityError: OnReducer<
  IBidsEntityState,
  ActionType<Payload<IServerError>>
> = (state: IBidsEntityState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const sortBidsEntitySuccess: OnReducer<
  IBidsEntityState,
  ActionType<Payload<IBidsEntityResponse>>
> = (state: IBidsEntityState, { payload }: Payload<IBidsEntityResponse>) => ({
  ...state,
  pending: false,
  bids: [...payload.items],
  count: payload.count,
});

const toggleBidsEntitySuccess: OnReducer<IBidsEntityState, ActionType<void>> = (
  state: IBidsEntityState,
) => ({
  ...state,
  bidsIsOpen: !state.bidsIsOpen,
});

const resetBidsEntity: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IBidsEntityState> =
  createReducer<IBidsEntityState>(
    initialState,

    on(actions.getBidsEntityAction, getBidsEntity),
    on(actions.getBidsEntityErrorAction, getBidsEntityError),
    on(actions.getBidsEntitySuccessAction, getBidsEntitySuccess),

    on(actions.sortBidsEntityAction, sortBidsEntity),
    on(actions.sortBidsEntityErrorAction, sortBidsEntityError),
    on(actions.sortBidsEntitySuccessAction, sortBidsEntitySuccess),

    on(actions.toggleBidsEntitySuccessAction, toggleBidsEntitySuccess),
    on(actions.resetBidsEntityAction, resetBidsEntity),
  );

export function bidsEntityReducer(
  state: IBidsEntityState,
  action: Action,
): IBidsEntityState {
  return reducer(state, action);
}

export const bids: GetFromState<IBidsEntity[], IBidsEntityState> = (
  state: IBidsEntityState,
): IBidsEntity[] => state.bids;
export const pending: GetFromState<boolean, IBidsEntityState> = (
  state: IBidsEntityState,
): boolean => state.pending;
export const loaded: GetFromState<boolean, IBidsEntityState> = (
  state: IBidsEntityState,
): boolean => state.loaded;
export const bidsIsOpen: GetFromState<boolean, IBidsEntityState> = (
  state: IBidsEntityState,
): boolean => state.bidsIsOpen;
export const count: GetFromState<number, IBidsEntityState> = (
  state: IBidsEntityState,
): number => state.count;
export const getOrderBy: GetFromState<SORT_DIRECTION, IBidsEntityState> = (
  state: IBidsEntityState,
): SORT_DIRECTION => state.orderBy;
export const getSortBy: GetFromState<BIDS_ENTITY_SORTING, IBidsEntityState> = (
  state: IBidsEntityState,
): BIDS_ENTITY_SORTING => state.sortBy;

export const bidsEntityError: GetFromState<IServerError, IBidsEntityState> = (
  state: IBidsEntityState,
): IServerError => state.error;

export const bidsEntityQueryParams: GetFromState<
  Partial<IBidsEntityState>,
  IBidsEntityState
> = (state: IBidsEntityState): Partial<IBidsEntityState> => {
  const { limit, offset, sortBy, orderBy }: Partial<IBidsEntityState> = state;
  return { limit, offset, sortBy, orderBy };
};

export const bidsParamsWithEntityProfileId: GetFromState<
  Params,
  number,
  Params
> = (id: number, params: Params): Params => {
  return {
    id,
    ...params,
  };
};

export const currentPageByParams: GetFromState<number, IBidsEntityState> = (
  state: IBidsEntityState,
): number => {
  const { offset, limit }: Partial<IBidsEntityState> = state;

  return Math.ceil(offset / limit) + 1;
};
