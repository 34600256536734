import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import {
  IChildInstitutionOnlyConfig,
  IChildInstitutionOnlyResult,
} from "../../interfaces";

import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-child-institution-only",
  templateUrl: "./child-institution-only.component.html",
  styleUrls: ["./child-institution-only.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildInstitutionOnlyComponent {
  forAll: boolean = false;

  constructor(
    private _ref: PopUpRef,
    @Inject(POP_UP_DATA) public config: IChildInstitutionOnlyConfig,
  ) {}

  onCloseClick(): void {
    this.close(null);
  }

  onChildOnlyClick(): void {
    this.close({
      ...(!this.config.withoutCheckbox &&
        !!this.forAll && { includeParentAll: true }),
    });
  }

  onChildAndParentClick(): void {
    this.close({
      includeOnlyParent: true,
      ...(!this.config.withoutCheckbox &&
        !!this.forAll && { includeParentAll: true }),
    });
  }

  onParentAndAllChildrenClick(): void {
    this.close({
      includeFullParent: true,
      ...(!this.config.withoutCheckbox &&
        !!this.forAll && { includeParentAll: true }),
    });
  }

  close(resultData: IChildInstitutionOnlyResult): void {
    this._ref.close(resultData);
  }
}
