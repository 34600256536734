import { apiPaths } from "@core/constants/api-paths";

interface IWebSocketsEnvironment {
  url: string;
  reconnectInterval: number;
  reconnectAttempts: number;
}

export interface IPaymentKeys {
  merchant_id: string;
  locale: string;
  access_key: string;
  profile_id: string;
  override_custom_receipt_page: string;
  orderPage_declineResponseURL: string;
}

export interface IEnvironment {
  contactPhoneNumber: string;
  workTime: string;
  contactMail: string;
  feedbackMail: string;
  MDRSite: string;
  checkCreditsInterval: number;
  excludeAuth: string;

  googleApiKey: string;
  captchaKey: string;
  mathPixelSignUpPage: string;
  mathPixelSignUpButton: string;
  matchPixelAllPages: string;
  matchVLPixel: string;

  production?: boolean;
  envName?: string;
  adminAppUrl?: string;
  helpCenterURL: string;
  frameRegSuccessUrl?: string;
  enableCaptcha?: boolean;

  webSockets?: IWebSocketsEnvironment;
  api?: any;

  paymentKeys?: IPaymentKeys;
  oktaConfig?: IOktaConfig;
}

export const defaultEnvironment: IEnvironment = {
  contactPhoneNumber: "8003338802",
  workTime: "Monday - Friday, 9 a.m. - 5 p.m. EST",
  contactMail: "support@mdreducation.com",
  feedbackMail: "Mdrfeedback@dnb.com",
  MDRSite: "https://mdreducation.com/",
  checkCreditsInterval: 1800000,
  excludeAuth: "testsecureacceptance.cybersource.com",

  googleApiKey: "AIzaSyDA61RxLdiTCxy1phBcPk-NTQg9ccbTn9Q",
  captchaKey: "6LcQx4sUAAAAABRm6_eupuPVNPf9qhUbgoLiXSOY",
  mathPixelSignUpPage:
    "//pixel.mathtag.com/event/js?mt_id=1322295&mt_adid=205497&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=",
  mathPixelSignUpButton:
    "//pixel.mathtag.com/event/js?mt_id=1322296&mt_adid=205497&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=",
  matchPixelAllPages:
    "//pixel.mathtag.com/event/js?mt_id=1365357&mt_adid=205497&mt_exem=&mt_excl=&v1=&v2=&v3=&s1=&s2=&s3=",
  matchVLPixel: "//vid1374.d41.co/sync/img?req=vid1374&cust=94&p10=916298",
  helpCenterURL:
    "https://mdreducation.com/appResources/ConnectED_Cloud_FAQ.htm ",

  api: { ...apiPaths() },
};

export interface IOktaConfig {
  clientId: string;
  token_endpoint: string;
  token_endpoint_id: string;
  domain: string;
  scopes: string[];
  pkce: boolean;
}
