import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { StringPipesModule } from "@shared/modules/pipes/string-pipes/string-pipes.module";

import { ServerErrorsCommonComponent } from "./components/server-errors-common/server-errors-common.component";
import { ServerErrorsComponent } from "./components/server-errors/server-errors.component";

const imports: any[] = [CommonModule, StringPipesModule];

const declarations: any[] = [
  ServerErrorsComponent,
  ServerErrorsCommonComponent,
];

const exports: any[] = [...declarations];

@NgModule({
  imports,
  declarations,
  exports,
})
export class ErrorsModule {}
