import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { ISyncHistoryResponse } from "../../../list-cloud-sync-history/interfaces";

enum ACTION_TYPES {
  GET_LIST_SYNC_HISTORY = "[LIST CLOUD SYNC HISTORY] get list cloud sync history",
  GET_LIST_SYNC_HISTORY_SUCCESS = "[LIST CLOUD SYNC HISTORY] get list cloud sync history success",
  GET_LIST_SYNC_HISTORY_ERROR = "[LIST CLOUD SYNC HISTORY] get list cloud sync history success error",

  CHANGE_HISTORY_PAGE = "[LIST CLOUD SYNC HISTORY] change history page",
  CHANGE_HISTORY_QUERY = "[LIST CLOUD SYNC HISTORY] change history query params",

  RESET = "[LIST CLOUD SYNC HISTORY] reset state",
}

export const changeHistoryQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_HISTORY_QUERY,
  Params
> = createAction(ACTION_TYPES.CHANGE_HISTORY_QUERY, createPayload<Params>());
export const changeHistoryPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_HISTORY_PAGE,
  number
> = createAction(ACTION_TYPES.CHANGE_HISTORY_PAGE, createPayload<number>());

export const getListSyncHistoryAction: CreateActionType<
  ACTION_TYPES.GET_LIST_SYNC_HISTORY,
  number
> = createAction(ACTION_TYPES.GET_LIST_SYNC_HISTORY, createPayload<number>()); // listId
export const getListSyncHistorySuccessAction: CreateActionType<
  ACTION_TYPES.GET_LIST_SYNC_HISTORY_SUCCESS,
  ISyncHistoryResponse
> = createAction(
  ACTION_TYPES.GET_LIST_SYNC_HISTORY_SUCCESS,
  createPayload<ISyncHistoryResponse>(),
);
export const getListSyncHistoryErrorAction: CreateActionType<
  ACTION_TYPES.GET_LIST_SYNC_HISTORY_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_LIST_SYNC_HISTORY_ERROR,
  createPayload<IServerError>(),
);

export const resetListSyncHistoryAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
