import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import * as actions from "@shared/modules/grants-entity/store/actions/grants-entity.action";
import { GetFromState } from "@shared/store/types/reducer.types";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IGetGrantsEntity,
  IGrantsEntities,
  IGrantsEntityResponse,
  ISortGrantsEntity,
} from "@shared/modules/grants-entity/interfaces";
import { IGrantsEntity } from "@shared/modules/grants-entity/interfaces";

import { SORT_DIRECTION } from "@shared/constants/sort";
import { GRANTS_ENTITY_SORTING } from "@shared/modules/grants-entity/constants";

export interface IGrantsEntityState {
  grants: IGrantsEntity[];

  pending: boolean;
  loaded: boolean;
  grantsIsOpen: boolean;

  error: IServerError | null;

  count: number;

  limit: number;
  offset: number;
  sortBy: GRANTS_ENTITY_SORTING;
  orderBy: SORT_DIRECTION;
}

const initialState: IGrantsEntityState = {
  grants: [],

  pending: false,
  loaded: false,
  grantsIsOpen: false,

  error: null,

  count: 0,

  limit: 10,
  offset: 0,
  sortBy: GRANTS_ENTITY_SORTING.YEAR,
  orderBy: SORT_DIRECTION.DESC,
};

const getGrantsEntity: OnReducer<
  IGrantsEntityState,
  ActionType<Payload<IGetGrantsEntity>>
> = (state: IGrantsEntityState, { payload }: Payload<IGetGrantsEntity>) => ({
  ...state,
  pending: true,
  error: null,
  offset:
    typeof payload.page === "number" ? (payload.page - 1) * state.limit : 0,
});

const getGrantsEntityError: OnReducer<
  IGrantsEntityState,
  ActionType<Payload<IServerError>>
> = (state: IGrantsEntityState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getGrantsEntitySuccess: OnReducer<
  IGrantsEntityState,
  ActionType<Payload<IGrantsEntityResponse>>
> = (
  state: IGrantsEntityState,
  { payload }: Payload<IGrantsEntityResponse>,
) => ({
  ...state,
  pending: false,
  loaded: true,
  grants: [...payload.items],
  count: payload.count,
  grantsIsOpen: !![...state.grants, ...payload.items].length,
});

const sortGrantsEntity: OnReducer<
  IGrantsEntityState,
  ActionType<Payload<ISortGrantsEntity>>
> = (state: IGrantsEntityState, { payload }: Payload<ISortGrantsEntity>) => ({
  ...state,
  ...payload.params,
  pending: true,
  error: null,
});

const sortGrantsEntityError: OnReducer<
  IGrantsEntityState,
  ActionType<Payload<IServerError>>
> = (state: IGrantsEntityState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const sortGrantsEntitySuccess: OnReducer<
  IGrantsEntityState,
  ActionType<Payload<IGrantsEntityResponse>>
> = (
  state: IGrantsEntityState,
  { payload }: Payload<IGrantsEntityResponse>,
) => ({
  ...state,
  pending: false,
  grants: [...payload.items],
  count: payload.count,
});

const toggleGrantsEntitySuccess: OnReducer<
  IGrantsEntityState,
  ActionType<void>
> = (state: IGrantsEntityState) => ({
  ...state,
  grantsIsOpen: !state.grantsIsOpen,
});

const resetGrantsEntity: OnReducer<
  IGrantsEntityState,
  ActionType<void>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IGrantsEntityState> =
  createReducer<IGrantsEntityState>(
    initialState,

    on(actions.getGrantsEntityAction, getGrantsEntity),
    on(actions.getGrantsEntityErrorAction, getGrantsEntityError),
    on(actions.getGrantsEntitySuccessAction, getGrantsEntitySuccess),

    on(actions.sortGrantsEntityAction, sortGrantsEntity),
    on(actions.sortGrantsEntityErrorAction, sortGrantsEntityError),
    on(actions.sortGrantsEntitySuccessAction, sortGrantsEntitySuccess),

    on(actions.toggleGrantsEntitySuccessAction, toggleGrantsEntitySuccess),
    on(actions.resetGrantsEntityAction, resetGrantsEntity),
  );

export function grantsEntityReducer(
  state: IGrantsEntityState,
  action: Action,
): IGrantsEntityState {
  return reducer(state, action);
}

export const grants: GetFromState<IGrantsEntities, IGrantsEntityState> = (
  state: IGrantsEntityState,
): IGrantsEntities => state.grants;
export const pending: GetFromState<boolean, IGrantsEntityState> = (
  state: IGrantsEntityState,
): boolean => state.pending;
export const loaded: GetFromState<boolean, IGrantsEntityState> = (
  state: IGrantsEntityState,
): boolean => state.loaded;
export const grantsIsOpen: GetFromState<boolean, IGrantsEntityState> = (
  state: IGrantsEntityState,
): boolean => state.grantsIsOpen;
export const count: GetFromState<number, IGrantsEntityState> = (
  state: IGrantsEntityState,
): number => state.count;
export const orderBy: GetFromState<SORT_DIRECTION, IGrantsEntityState> = (
  state: IGrantsEntityState,
): SORT_DIRECTION => state.orderBy;
export const sortBy: GetFromState<GRANTS_ENTITY_SORTING, IGrantsEntityState> = (
  state: IGrantsEntityState,
): GRANTS_ENTITY_SORTING => state.sortBy;

export const grantsEntityError: GetFromState<
  IServerError,
  IGrantsEntityState
> = (state: IGrantsEntityState): IServerError => state.error;

export const grantsEntityQueryParams: GetFromState<
  Partial<IGrantsEntityState>,
  IGrantsEntityState
> = (state: IGrantsEntityState): Partial<IGrantsEntityState> => {
  const { limit, offset, sortBy, orderBy }: Partial<IGrantsEntityState> = state;
  return { limit, offset, sortBy, orderBy };
};

export const grantsParamsWithEntityProfileId: GetFromState<
  Params,
  number,
  Params
> = (id: number, params: Params): Params => {
  return {
    id,
    ...params,
  };
};

export const currentPageByParams: GetFromState<number, IGrantsEntityState> = (
  state: IGrantsEntityState,
): number => {
  const { offset, limit }: Partial<IGrantsEntityState> = state;

  return Math.ceil(offset / limit) + 1;
};
