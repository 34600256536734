import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/personnel-multi-codes.action";

import { IMultiCodesError } from "@shared/interfaces/server-error";

export interface IPersonnelMultiCodesState {
  pending: boolean;
  error: IMultiCodesError | null;
}

const initialState: IPersonnelMultiCodesState = {
  pending: false,
  error: null,
};

const addPersonnelMultiCodes: OnReducer<
  IPersonnelMultiCodesState,
  ActionType<any>
> = (state: IPersonnelMultiCodesState) => ({
  ...state,
  error: null,
  pending: true,
});

const addPersonnelMultiCodesError: OnReducer<
  IPersonnelMultiCodesState,
  ActionType<Payload<IMultiCodesError>>
> = (
  state: IPersonnelMultiCodesState,
  { payload }: Payload<IMultiCodesError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const resetAddPersonnelMultiCodesError: OnReducer<
  IPersonnelMultiCodesState,
  ActionType<any>
> = (state: IPersonnelMultiCodesState) => ({
  ...state,
  error: null,
});

const addPersonnelMultiCodesSuccess: OnReducer<
  IPersonnelMultiCodesState,
  ActionType<any>
> = (state: IPersonnelMultiCodesState) => ({
  ...state,
  pending: false,
});

const resetPersonnelMultiCodes: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IPersonnelMultiCodesState> =
  createReducer<IPersonnelMultiCodesState>(
    initialState,

    on(actions.addPersonnelMultiCodesAction, addPersonnelMultiCodes),
    on(actions.addPersonnelMultiCodesErrorAction, addPersonnelMultiCodesError),
    on(
      actions.resetAddPersonnelMultiCodesErrorAction,
      resetAddPersonnelMultiCodesError,
    ),
    on(
      actions.addPersonnelMultiCodesSuccessAction,
      addPersonnelMultiCodesSuccess,
    ),

    on(actions.resetPersonnelMultiCodesAction, resetPersonnelMultiCodes),
  );

export function personnelMultiCodesReducer(
  state: IPersonnelMultiCodesState,
  action: Action,
): IPersonnelMultiCodesState {
  return reducer(state, action);
}

export const personnelMultiCodesError: GetFromState<
  IMultiCodesError,
  IPersonnelMultiCodesState
> = (state: IPersonnelMultiCodesState): IMultiCodesError => state.error;
export const personnelMultiCodesGeneralError: GetFromState<
  string,
  IMultiCodesError
> = (error: IMultiCodesError): string | null =>
  (error && Array.isArray(error.errors) && error.message) || null;
