import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { AutofocusInputComponent } from "./components/autofocus-input/autofocus-input.component";

import { AutofocusDirective } from "./directives/autofocus.directive";

const imports: any[] = [CommonModule, FormsModule, ReactiveFormsModule];

const components: any[] = [AutofocusInputComponent];

const directives: any[] = [AutofocusDirective];

const declarations: any[] = [...components, ...directives];

const exports: any[] = [...declarations];

@NgModule({
  imports,
  declarations,
  exports,
})
export class AutofocusModule {}
