import { APP_BASE_HREF } from "@angular/common";
import { Inject, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { CoreModule } from "@core/core.module";
import { NavModule } from "@modules/nav/nav.module";
import { UtilsPipesModule } from "@shared/modules/pipes/utils-pipes/utils-pipes.module";
import { SpinnerModule } from "@ui/spinner/spinner.module";
import { TooltipModule } from "@ui/tooltip/tooltip.module";
import { ViewPointsModule } from "@ui/view-points/view-points.module";

import { LayoutService } from "@core/services/layout.service";

import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { GridModule } from "@progress/kendo-angular-grid";
import { PopupModule } from "@progress/kendo-angular-popup";
import { AppService } from "./app.service";
import { AppComponent } from "./app.component";
import "hammerjs";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { OktaAuthModule, OktaConfig } from "@okta/okta-angular";
import { OktaAuth } from "@okta/okta-auth-js";
import { environment } from "@env/environment";
import {
  WebSocketsProvider,
  WEB_SOCKET_CONFIG_TOKEN,
} from "@modules/websockets";

const authConfig = {
  issuer: `https://${environment.oktaConfig.domain}/oauth2/${environment.oktaConfig.token_endpoint_id}`,
  clientId: `${environment.oktaConfig.clientId}`,
  redirectUri: "login/callback",
  // offline_acces scope imp for refresh token
  scopes: ["openid", "offline_access"],
  // SPA uses only pkce
  pkce: true,
};

const oktaAuth = new OktaAuth(authConfig);
const moduleConfig: OktaConfig = { oktaAuth };

oktaAuth.tokenManager.on("expired", function (key, expiredToken) {
  // find a better way to deal with ws disconnect
  new WebSocketsProvider({ ...environment.webSockets }).disconnect();
});

oktaAuth.tokenManager.on("error", function (error) {
  // find a better way to deal with ws disconnect
  console.log("token refresh failed::: disconnecting sockets");
  new WebSocketsProvider({ ...environment.webSockets }).disconnect();
});

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    CoreModule,
    ViewPointsModule,
    BrowserAnimationsModule,
    NavModule,
    TooltipModule,
    SpinnerModule,
    UtilsPipesModule,
    GridModule,
    DateInputsModule,
    PopupModule,
    IndicatorsModule,
    OktaAuthModule.forRoot(moduleConfig),
  ],
  providers: [
    AppService,
    LayoutService,
    {
      provide: APP_BASE_HREF,
      useValue: "./",
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
