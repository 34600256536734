// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  padding: 12px 0 10px 0;
  border-top: 1px solid #6B6D6F;
  display: flex;
  justify-content: center;
}

.btn {
  padding: 6px 21px 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/drop-down/components/drop-down-close-footer/drop-down-close-footer.component.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,6BAAA;EACA,aAAA;EACA,uBAAA;AADF;;AAIA;EACE,qBAAA;AADF","sourcesContent":["@import '../../../../../assets/styles/variables';\n\n.footer {\n  padding: 12px 0 10px 0;\n  border-top: 1px solid $gray;\n  display: flex;\n  justify-content: center;\n}\n\n.btn {\n  padding: 6px 21px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
