import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-alerts.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IAlert, IAlertsList } from "../../interfaces";

export interface IViewAlertsState {
  alerts: IAlertsList[];
  pending: boolean;
  error: IServerError | null;
  isChanged: boolean;
}

const initialState: IViewAlertsState = {
  alerts: [],
  pending: false,
  error: null,
  isChanged: false,
};

const getViewAlerts: OnReducer<IViewAlertsState, ActionType<Payload<any>>> = (
  state: IViewAlertsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewAlertsError: OnReducer<
  IViewAlertsState,
  ActionType<Payload<IServerError>>
> = (state: IViewAlertsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewAlertsSuccess: OnReducer<
  IViewAlertsState,
  ActionType<Payload<any>>
> = (state: IViewAlertsState, { payload }: Payload<any>) => ({
  ...state,
  alerts: [...payload],
  pending: false,
});

const toggleViewAlert: OnReducer<IViewAlertsState, ActionType<any>> = (
  state: IViewAlertsState,
) => ({
  ...state,
  pending: true,
  isChanged: true,
});

const toggleViewAlertError: OnReducer<
  IViewAlertsState,
  ActionType<Payload<IServerError>>
> = (state: IViewAlertsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const toggleViewAlertSuccess: OnReducer<
  IViewAlertsState,
  ActionType<Payload<any>>
> = (state: IViewAlertsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  alerts: state.alerts.map((group: IAlertsList) => ({
    ...group,
    alerts: group.alerts.map((alert: IAlert) =>
      alert.id === payload.id ? payload : alert,
    ),
  })),
});

const reducer: ActionReducer<IViewAlertsState> =
  createReducer<IViewAlertsState>(
    initialState,

    on(actions.getViewAlertsAction, getViewAlerts),
    on(actions.getViewAlertsErrorAction, getViewAlertsError),
    on(actions.getViewAlertsSuccessAction, getViewAlertsSuccess),

    on(actions.toggleViewAlertAction, toggleViewAlert),
    on(actions.toggleViewAlertErrorAction, toggleViewAlertError),
    on(actions.toggleViewAlertSuccessAction, toggleViewAlertSuccess),
  );

export function viewAlertsReducer(
  state: IViewAlertsState,
  action: Action,
): IViewAlertsState {
  return reducer(state, action);
}

export const viewAlertsId: GetFromState<number, Params> = (
  data: Params,
): number => data.id;
export const alerts: GetFromState<IAlertsList[], IViewAlertsState> = (
  state: IViewAlertsState,
): IAlertsList[] => state.alerts;
export const alertsPending: GetFromState<boolean, IViewAlertsState> = (
  state: IViewAlertsState,
): boolean => state.pending;
export const alertsError: GetFromState<IServerError, IViewAlertsState> = (
  state: IViewAlertsState,
): IServerError => state.error;
export const isChanged: GetFromState<boolean, IViewAlertsState> = (
  state: IViewAlertsState,
): boolean => state.isChanged;
