import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import * as actions from "@core/store/actions/active-list-append-data.action";
import { Payload } from "@shared/interfaces/store";

import { ISummaryOrDashboardList } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

export interface IActiveListAppendDataState {
  list: ISummaryOrDashboardList;
  pending: boolean;
  error: IServerError;
  listeningSocket: boolean;
}

const initialState: IActiveListAppendDataState = {
  list: null,
  pending: false,
  error: null,
  listeningSocket: false,
};

const startActiveListAppendData: OnReducer<
  IActiveListAppendDataState,
  ActionType<Payload<any>>
> = (state: IActiveListAppendDataState, { payload }: Payload<any>) => ({
  ...state,
  list: { ...payload },
  error: null,
});

const calculateActiveListAppends: OnReducer<
  IActiveListAppendDataState,
  ActionType<any>
> = (state: IActiveListAppendDataState) => ({
  ...state,
  pending: true,
  error: null,
});

const calculateActiveListAppendsSuccess: OnReducer<
  IActiveListAppendDataState,
  ActionType<any>
> = (state: IActiveListAppendDataState) => ({
  ...state,
  pending: false,
  error: null,
});

const calculateActiveListAppendsError: OnReducer<
  IActiveListAppendDataState,
  ActionType<Payload<IServerError>>
> = (
  state: IActiveListAppendDataState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const listenActiveListAppendsCalculatedSocket: OnReducer<
  IActiveListAppendDataState,
  ActionType<any>
> = (state: IActiveListAppendDataState) => ({
  ...state,
  listeningSocket: true,
});

const activeListAppendsCalculated: OnReducer<
  IActiveListAppendDataState,
  ActionType<any>
> = (state: IActiveListAppendDataState) => ({
  ...state,
  listeningSocket: false,
});

const reducer: ActionReducer<IActiveListAppendDataState> =
  createReducer<IActiveListAppendDataState>(
    initialState,

    on(actions.startActiveListAppendDataAction, startActiveListAppendData),

    on(actions.calculateActiveListAppendsAction, calculateActiveListAppends),
    on(
      actions.calculateActiveListAppendsSuccessAction,
      calculateActiveListAppendsSuccess,
    ),
    on(
      actions.calculateActiveListAppendsErrorAction,
      calculateActiveListAppendsError,
    ),

    on(
      actions.listenActiveListAppendsCalculatedSocketAction,
      listenActiveListAppendsCalculatedSocket,
    ),
    on(actions.activeListAppendsCalculatedAction, activeListAppendsCalculated),
  );

export function activeListAppendDataReducer(
  state: IActiveListAppendDataState,
  action: Action,
): IActiveListAppendDataState {
  return reducer(state, action);
}
