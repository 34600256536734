import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-lists-filter.action";

import { SessionStorageService } from "@core/services/session-storage.service";

import { IServerError } from "@shared/interfaces/server-error";
import { IFilters, IFiltersResult } from "../../../dashboard/interfaces";

import { MANAGE_ACCOUNT_LISTS } from "@shared/constants/data/manage-account-lists";

export interface IDashboardListsFilterState {
  filters: IFilters;
  checkedFilters: IFiltersResult | null;
  isHasCheckedFilters: boolean;
  showAccountLists: MANAGE_ACCOUNT_LISTS | null;
  loaded: boolean;
  loading: boolean;
  error: IServerError;
}

const initialState: IDashboardListsFilterState = {
  filters: null,
  checkedFilters: SessionStorageService.dashboardListsFiltersChecked || null,
  isHasCheckedFilters: false,
  showAccountLists: SessionStorageService.showAccountLists || null,
  loaded: false,
  loading: false,
  error: null,
};

const getDashboardListsFilter: OnReducer<
  IDashboardListsFilterState,
  ActionType<any>
> = (state: IDashboardListsFilterState) => ({
  ...state,
  loading: true,
  error: null,
});

const getDashboardListsFilterError: OnReducer<
  IDashboardListsFilterState,
  ActionType<Payload<IServerError>>
> = (
  state: IDashboardListsFilterState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getDashboardListsFilterSuccess: OnReducer<
  IDashboardListsFilterState,
  ActionType<Payload<any>>
> = (state: IDashboardListsFilterState, { payload }: Payload<any>) => {
  const canManageAccountList: boolean =
    payload && payload.showAccountLists && !!payload.showAccountLists.length;

  return {
    ...state,
    loading: false,
    loaded: true,
    filters: { ...payload },
    showAccountLists: canManageAccountList
      ? state.showAccountLists || MANAGE_ACCOUNT_LISTS.ALL_PRODUCTS
      : null,
    error: null,
  };
};

const updateDashboardListsFilter: OnReducer<
  IDashboardListsFilterState,
  ActionType<Payload<any>>
> = (state: IDashboardListsFilterState, { payload }: Payload<any>) => ({
  ...state,
  checkedFilters: payload ? { ...payload } : null,
  error: null,
});

const updateDashboardListsFilterSuccess: OnReducer<
  IDashboardListsFilterState,
  ActionType<any>
> = (state: IDashboardListsFilterState) => ({
  ...state,
  isHasCheckedFilters: !!state.checkedFilters,
});

const dashboardListsSwitchFiltersWithReload: OnReducer<
  IDashboardListsFilterState,
  ActionType<Payload<any>>
> = (state: IDashboardListsFilterState, { payload }: Payload<any>) => ({
  ...state,
  showAccountLists: payload,
  checkedFilters: null,
  error: null,
});

const resetSwitchFilters: OnReducer<
  IDashboardListsFilterState,
  ActionType<any>
> = (state: IDashboardListsFilterState) => ({
  ...state,
  showAccountLists: null,
  error: null,
});

const resetDashboardListsFilter: OnReducer<
  IDashboardListsFilterState,
  ActionType<any>
> = (state: IDashboardListsFilterState) => ({
  ...state,
  loaded: false,
  filters: null,
  showAccountLists: null,
  error: null,
});

const resetDashboardListsCheckedFilter: OnReducer<
  IDashboardListsFilterState,
  ActionType<any>
> = (state: IDashboardListsFilterState) => ({
  ...state,
  checkedFilters: null,
  error: null,
});

const resetDashboardListsState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDashboardListsFilterState> =
  createReducer<IDashboardListsFilterState>(
    initialState,

    on(actions.getDashboardListsFilterAction, getDashboardListsFilter),
    on(
      actions.getDashboardListsFilterErrorAction,
      getDashboardListsFilterError,
    ),
    on(
      actions.getDashboardListsFilterSuccessAction,
      getDashboardListsFilterSuccess,
    ),

    on(actions.updateDashboardListsFilterAction, updateDashboardListsFilter),
    on(
      actions.updateDashboardListsFilterSuccessAction,
      updateDashboardListsFilterSuccess,
    ),
    on(
      actions.dashboardListsSwitchFiltersWithReloadAction,
      dashboardListsSwitchFiltersWithReload,
    ),

    on(actions.resetSwitchFiltersAction, resetSwitchFilters),
    on(actions.resetDashboardListsFilterAction, resetDashboardListsFilter),
    on(
      actions.resetDashboardListsCheckedFilterAction,
      resetDashboardListsCheckedFilter,
    ),

    on(actions.resetDashboardListsStateAction, resetDashboardListsState),
  );

export function dashboardListsFilterReducer(
  state: IDashboardListsFilterState,
  action: Action,
): IDashboardListsFilterState {
  return reducer(state, action);
}

export const filters: GetFromState<IFilters, IDashboardListsFilterState> = (
  state: IDashboardListsFilterState,
): IFilters => state.filters;

export const checkedFilters: GetFromState<
  IFiltersResult,
  IDashboardListsFilterState
> = (state: IDashboardListsFilterState): IFiltersResult => state.checkedFilters;
export const isHasCheckedFilters: GetFromState<
  boolean,
  IDashboardListsFilterState
> = (state: IDashboardListsFilterState): boolean => state.isHasCheckedFilters;

export const showAccountLists: GetFromState<
  MANAGE_ACCOUNT_LISTS,
  IDashboardListsFilterState
> = (state: IDashboardListsFilterState): MANAGE_ACCOUNT_LISTS =>
  state.showAccountLists;
