import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/geo-codes.action";

import { IServerError } from "@shared/interfaces/server-error";

import {
  IMultiCodesRequest,
  MultiCodesItems,
} from "../../../segment/models/codes";
import { Place } from "../../../segment/models/place";

import {
  DEFAULT_CODES_LIMIT,
  DEFAULT_CODES_OFFSET,
} from "../../../segment/constants/codes";

export interface IGeographyCodesState {
  codes: MultiCodesItems<Place>;
  total: number;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IGeographyCodesState = {
  codes: [],
  total: 0,
  pending: false,
  error: null,
};

const getGeographyCodes: OnReducer<IGeographyCodesState, ActionType<any>> = (
  state: IGeographyCodesState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getGeographyCodesError: OnReducer<
  IGeographyCodesState,
  ActionType<Payload<IServerError>>
> = (state: IGeographyCodesState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getGeographyCodesSuccess: OnReducer<
  IGeographyCodesState,
  ActionType<Payload<any>>
> = (
  state: IGeographyCodesState,
  {
    payload: {
      response: { items, total },
    },
  }: Payload<any>,
) => ({
  ...state,
  pending: false,
  codes: items,
  total,
});

const resetGeographyCodesState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IGeographyCodesState> =
  createReducer<IGeographyCodesState>(
    initialState,

    on(actions.getGeographyCodesAction, getGeographyCodes),
    on(actions.getGeographyCodesErrorAction, getGeographyCodesError),
    on(actions.getGeographyCodesSuccessAction, getGeographyCodesSuccess),

    on(actions.resetGeographyCodesStateAction, resetGeographyCodesState),
  );

export function geoCodesReducer(
  state: IGeographyCodesState,
  action: Action,
): IGeographyCodesState {
  return reducer(state, action);
}

export const geographyCodes: GetFromState<
  MultiCodesItems<Place>,
  IGeographyCodesState
> = (state: IGeographyCodesState): MultiCodesItems<Place> => state.codes;
export const geographyCodesTotalCount: GetFromState<
  number,
  IGeographyCodesState
> = (state: IGeographyCodesState): number => state.total;
export const geographyCodesPendingState: GetFromState<
  boolean,
  IGeographyCodesState
> = (state: IGeographyCodesState): boolean => state.pending;

export const geographyCodesCurrentPage: GetFromState<
  number,
  IMultiCodesRequest
> = ({
  offset = DEFAULT_CODES_OFFSET,
  limit = DEFAULT_CODES_LIMIT,
}: IMultiCodesRequest): number => Math.ceil(offset / limit) + 1;

export const geographyCodesSearchParams: GetFromState<
  IMultiCodesRequest,
  IMultiCodesRequest
> = ({
  segmentCriteriaId,
  offset = DEFAULT_CODES_OFFSET,
  limit = DEFAULT_CODES_LIMIT,
  searchQuery,
}: IMultiCodesRequest): IMultiCodesRequest => ({
  segmentCriteriaId,
  offset,
  limit,
  searchQuery,
});
