import { RouterNavigationAction } from "@ngrx/router-store";
import { RouterNavigationPayload } from "@ngrx/router-store/src/actions";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/router-history.action";

import { ROUTER_HISTORY_LENGTH } from "../../constants/navigation";

export interface RouterHistoryState {
  history: string[];
  skipNext: boolean;
}

const initialState: RouterHistoryState = {
  history: [],
  skipNext: false,
};

const customNavigateAction: OnReducer<
  RouterHistoryState,
  ActionType<RouterNavigationAction>
> = (
  state: RouterHistoryState,
  { payload }: RouterNavigationAction,
): RouterHistoryState => {
  const {
    routerState: { url },
  }: RouterNavigationPayload = payload;
  const shouldSkip: boolean =
    state.skipNext || url === state.history[state.history.length - 1];
  const history: string[] = shouldSkip
    ? [...state.history]
    : [...state.history, url];

  if (history.length > ROUTER_HISTORY_LENGTH) {
    history.shift();
  }

  return {
    ...state,
    history,
    skipNext: false,
  };
};

const navigateAction: OnReducer<
  RouterHistoryState,
  ActionType<Payload<any>>
> = (state: RouterHistoryState, { payload }: Payload<any>) => ({
  ...state,
  skipNext: !!payload.doNotSave,
});

const navigateBackAction: OnReducer<RouterHistoryState, ActionType<any>> = (
  state: RouterHistoryState,
) => ({
  ...state,
  skipNext: true,
});

const navigateBackSuccessAction: OnReducer<
  RouterHistoryState,
  ActionType<any>
> = (state: RouterHistoryState) => ({
  ...state,
  history: state.history.filter(
    (item: string, index: number) => index !== state.history.length - 1,
  ),
});

const reducer: ActionReducer<RouterHistoryState> =
  createReducer<RouterHistoryState>(
    initialState,
    on(actions.customNavigateAction, customNavigateAction),
    on(actions.navigateAction, navigateAction),
    on(actions.navigateBackAction, navigateBackAction),
    on(actions.navigateBackSuccessAction, navigateBackSuccessAction),
  );

export const routerHistory: GetFromState<string[], RouterHistoryState> = (
  state: RouterHistoryState,
): string[] => state.history;

export function routerHistoryReducer(
  state: RouterHistoryState,
  action: Action,
): RouterHistoryState {
  return reducer(state, action);
}
