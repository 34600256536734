import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "isFindWord",
})
export class IsFindWordPipe implements PipeTransform {
  transform(input: string, word: string): string {
    if (typeof input !== "string" || typeof word !== "string") {
      return input;
    }

    const wordForCompare: string = word.trim().toLowerCase();

    const firstCharIndex: number = input.toLowerCase().indexOf(wordForCompare);
    if (firstCharIndex === -1) {
      return input;
    }

    const lastCharIndex: number = firstCharIndex + wordForCompare.length;

    let value: string = `${input.substring(0, firstCharIndex)}`;
    value += `<span class="found-compared-word">${input.substring(firstCharIndex, lastCharIndex)}</span>`;
    value += `${input.substring(lastCharIndex, input.length)}`;

    return value;
  }
}
