import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from "@angular/core";
import { Store } from "@ngrx/store";

import { environment } from "@env/environment";

import { Subject } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";

import { DropDownComponent } from "@ui/drop-down/components/drop-down/drop-down.component";
import { BaseOverlayInfoComponent } from "@ui/overlay-info/components/base-overlay-info/base-overlay-info.component";

import { OnBoardProvider } from "@ui/on-board/providers/on-board-provider.service";

import { OnBoardRef } from "@ui/on-board/providers/on-board-ref";

import { IUser } from "@modules/auth/interfaces/user";
import { WINDOW_POINTS } from "@ui/view-points/constants/view-points";
import {
  IProfileRoadActions,
  IProfileRoadData,
} from "../../interfaces/on-board";

import { CustomEmitter } from "../../services/custom-emitter";
import { CoreState } from "../../store/reducers";
import { CORE_PATHS } from "../../constants/core-paths";
import {
  FIRST_SIGN_UP_ROAD,
  PROFILE_BUTTON_DATA,
  PROFILE_BUTTON_DATA_PARAMS,
} from "../../constants/on-board";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";

@Component({
  selector: "bl-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild("dropDown") dropDown: DropDownComponent;
  @ViewChild("onBoardLink", { read: ElementRef }) onBoardLink: ElementRef;

  @Input() globalError: string;
  @Input() user: IUser;
  @Input() subscribed: boolean;
  @Input() isShowBillingDetails: boolean;
  @Input() isTokenExpired: boolean;
  @Input() isLoggedIn: boolean;
  @Input() isOwnerOfAdminsAccount: boolean;
  @Input() isAdminsAccount: boolean;
  @Input() isAdmin: boolean;
  @Input() isBroker: boolean;
  isOktaLoggedIn: string;

  @Output() headerLogout: EventEmitter<void> = new EventEmitter();
  @Output() headerGoToAdminPanel: EventEmitter<void> = new EventEmitter();
  @Output() headerOpenMenuMobile: EventEmitter<void> = new EventEmitter();

  readonly windowPoints: typeof WINDOW_POINTS = WINDOW_POINTS;
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;

  private _destroyer$: Subject<void> = new Subject<void>();
  private _onBoardRef: OnBoardRef;

  phoneNumber: string = environment.contactPhoneNumber;
  email: string = environment.contactMail;

  constructor(
    private _onBoardProvider: OnBoardProvider,
    private _emitter: CustomEmitter,
    private _store: Store<CoreState>,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {}

  ngAfterViewInit(): void {
    this.subscribeToRoad();
  }

  ngOnChanges() {
    this.isOktaLoggedIn = this.oktaAuth.getAccessToken();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
    if (this._onBoardRef) {
      this._onBoardRef.destroy();
    }
  }

  get roadActions(): IProfileRoadActions {
    return {
      prev: () => {
        this._emitter.emit(FIRST_SIGN_UP_ROAD.HIDE_PROFILE_BUTTON);
        this._emitter.emit(FIRST_SIGN_UP_ROAD.SHOW_HELLO_POP_UP);
      },
      next: () => {
        this._emitter.emit(FIRST_SIGN_UP_ROAD.HIDE_PROFILE_BUTTON);
        this._emitter.emit(FIRST_SIGN_UP_ROAD.SHOW_MARKET_VIEW_LINK);
      },
      close: () => {
        this._emitter.emit(FIRST_SIGN_UP_ROAD.HIDE_PROFILE_BUTTON);
        this._onBoardRef.destroy();
      },
    };
  }

  subscribeToRoad(): void {
    this._emitter
      .on(FIRST_SIGN_UP_ROAD.HIDE_PROFILE_BUTTON)
      .pipe(
        takeUntil(this._destroyer$),
        filter(() => !!this._onBoardRef),
        tap(() => this._onBoardRef.close()),
      )
      .subscribe();

    this._emitter
      .on(FIRST_SIGN_UP_ROAD.SHOW_PROFILE_BUTTON)
      .pipe(
        takeUntil(this._destroyer$),
        tap(() => {
          if (!this._onBoardRef) {
            this._onBoardRef = this._onBoardProvider.createOnBoardRef<
              BaseOverlayInfoComponent,
              IProfileRoadData,
              IProfileRoadActions
            >(
              {
                target: {
                  target: this.onBoardLink,
                  params: PROFILE_BUTTON_DATA_PARAMS,
                },
                info: {
                  component: BaseOverlayInfoComponent,
                  data: PROFILE_BUTTON_DATA,
                  actions: this.roadActions,
                },
              },
              false,
            );
          }
          this._onBoardRef.show();
        }),
      )
      .subscribe();
  }

  logout(): void {
    this.dropDown.close();
    this.headerLogout.emit();
  }

  goToAdminPanel(): void {
    localStorage.clear();
    this.headerGoToAdminPanel.emit();
  }
}
