import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { RouterModule } from "@angular/router";

import { DirectivesModule } from "@shared/modules/directives/directives.module";
import { SidenavModule } from "@ui/sidenav/sidenav.module";
import { TooltipModule } from "@ui/tooltip/tooltip.module";

import { CreditMeterContainerComponent } from "./containers/credit-meter-container/credit-meter-container.component";
import { NavContentContainerComponent } from "./containers/nav-content-container/nav-content-container.component";
import { NavMobileContainerComponent } from "./containers/nav-mobile-container/nav-mobile-container.component";

import { AccountLinksComponent } from "./components/account-links/account-links.component";
import { CreditMeterComponent } from "./components/credit-meter/credit-meter.component";
import { FooterCopyrightComponent } from "./components/footer-copyright/footer-copyright.component";
import { FooterComponent } from "./components/footer/footer.component";
import { HelpCenterComponent } from "./components/help-center/help-center.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatRippleModule,
    SidenavModule,
    DirectivesModule,
    TooltipModule,
  ],
  declarations: [
    NavContentContainerComponent,
    NavMobileContainerComponent,
    AccountLinksComponent,
    CreditMeterContainerComponent,
    CreditMeterComponent,
    FooterComponent,
    FooterCopyrightComponent,
    HelpCenterComponent,
  ],
  exports: [
    NavContentContainerComponent,
    NavMobileContainerComponent,
    FooterComponent,
    AccountLinksComponent,
    HelpCenterComponent,
    CreditMeterContainerComponent,
  ],
})
export class NavModule {}
