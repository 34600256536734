import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IPersonnelRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_QUICK_SEARCH_PERSONNEL_COLLEGES = "[QUICK SEARCH PERSONNEL] get colleges",
  GET_QUICK_SEARCH_PERSONNEL_COLLEGES_ERROR = "[QUICK SEARCH PERSONNEL] get colleges error",
  GET_QUICK_SEARCH_PERSONNEL_COLLEGES_SUCCESS = "[QUICK SEARCH PERSONNEL] get colleges success",

  SET_QUICK_SEARCH_PERSONNEL_COLLEGES_SPINNER_STATE = "[QUICK SEARCH PERSONNEL] set spinner state",

  CHANGE_QUICK_SEARCH_PERSONNEL_COLLEGES_PAGE = "[QUICK SEARCH PERSONNEL] Change colleges page",
  CHANGE_QUICK_SEARCH_PERSONNEL_COLLEGES_QUERY = "[QUICK SEARCH PERSONNEL] Change colleges query",

  RESET_QUICK_SEARCH_PERSONNEL_COLLEGES_PAGE_STAGE = "[QUICK SEARCH PERSONNEL] reset colleges page state",
}

export const getQuickSearchPersonnelCollegesAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_COLLEGES,
  () => TypedAction<ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_COLLEGES>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_COLLEGES);
export const getQuickSearchPersonnelCollegesErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_COLLEGES_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_COLLEGES_ERROR,
  createPayload<IServerError>(),
);
export const getQuickSearchPersonnelCollegesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_COLLEGES_SUCCESS,
  IProductResultsResponse<IPersonnelRecords>
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_PERSONNEL_COLLEGES_SUCCESS,
  createPayload<IProductResultsResponse<IPersonnelRecords>>(),
);

export const setQuickSearchPersonnelCollegesSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_QUICK_SEARCH_PERSONNEL_COLLEGES_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_QUICK_SEARCH_PERSONNEL_COLLEGES_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeQuickSearchPersonnelCollegesPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_PERSONNEL_COLLEGES_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_PERSONNEL_COLLEGES_PAGE,
  createPayload<number>(),
);

export const changeQuickSearchPersonnelCollegesQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_QUICK_SEARCH_PERSONNEL_COLLEGES_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_QUICK_SEARCH_PERSONNEL_COLLEGES_QUERY,
  createPayload<Params>(),
);

export const resetQuickSearchPersonnelCollegesStateAction: ActionCreator<
  ACTION_TYPES.RESET_QUICK_SEARCH_PERSONNEL_COLLEGES_PAGE_STAGE,
  () => TypedAction<ACTION_TYPES.RESET_QUICK_SEARCH_PERSONNEL_COLLEGES_PAGE_STAGE>
> = createAction(ACTION_TYPES.RESET_QUICK_SEARCH_PERSONNEL_COLLEGES_PAGE_STAGE);
