// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.menu-content {
  min-width: 169px;
}

.theme--mobile .action {
  text-align: right;
  border-bottom: none;
  padding: 11px 20px;
}
.theme--mobile .action-item:last-child .action {
  border-top: 1px solid #D4D4D4;
  border-bottom: 1px solid #D4D4D4;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/nav/components/account-links/account-links.component.scss"],"names":[],"mappings":"AAEA;EACE,cAAA;AADF;;AAIA;EACE,gBAAA;AADF;;AAMI;EACE,iBAAA;EACA,mBAAA;EACA,kBAAA;AAHN;AAMI;EACE,6BAAA;EACA,gCAAA;AAJN","sourcesContent":["@import '../../../../../assets/styles/variables';\n\n:host {\n  display: block;\n}\n\n.menu-content {\n  min-width: 169px;\n}\n\n.theme {\n  &--mobile {\n    .action {\n      text-align: right;\n      border-bottom: none;\n      padding: 11px 20px;\n    }\n\n    .action-item:last-child .action {\n      border-top: 1px solid $gray-light;\n      border-bottom: 1px solid $gray-light;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
