import { ITableHeaders } from "@ui/tables/components/table/table.component";

import { IGetRecipientsQueryParams } from "../interfaces/recipients";

import { SORT_DIRECTION } from "@shared/constants/sort";

export enum RECIPIENTS_SORT {
  DATE = "date",
  NAME = "name",
}

export const RECIPIENTS_DEFAULT_OFFSET: number = 0;
export const RECIPIENTS_DEFAULT_LIMIT: number = 20;

export const DEFAULT_GET_RECIPIENTS_PARAMS: IGetRecipientsQueryParams = {
  offset: RECIPIENTS_DEFAULT_OFFSET,
  limit: RECIPIENTS_DEFAULT_LIMIT,
  sortBy: RECIPIENTS_SORT.NAME,
  orderBy: SORT_DIRECTION.ASC,
};

export const RECIPIENTS_TABLE_HEADERS: ITableHeaders = [
  {
    key: "",
    title: "",
    checkbox: true,
  },
  {
    key: "name",
    title: "Recipients",
    sortable: true,
  },
  {
    key: "amount",
    title: "Award Amount",
    sortable: true,
  },
];

export const MAX_GRANTS_RECIPIENTS_CACHE: number = 180;
