import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { IDashboardListsModuleState } from "../reducers";
import {
  checkedFilters,
  filters,
  isHasCheckedFilters,
  showAccountLists,
  IDashboardListsFilterState,
} from "../reducers/dashboard-lists-filter.reducer";

import { IFilters, IFiltersResult } from "@modules/dashboard/interfaces";

import { MANAGE_ACCOUNT_LISTS } from "@shared/constants/data/manage-account-lists";

import { getDashboardListsModuleState } from "./index";

export const getDashboardListsFiltersState: GetFromStateInSelectors<
  CoreState,
  IDashboardListsFilterState
> = createSelector(
  getDashboardListsModuleState,
  (state: IDashboardListsModuleState): IDashboardListsFilterState =>
    state.dashboardListsFilter,
);

export const getDashboardListsFilters: MemoizedSelector<CoreState, IFilters> =
  createSelector(getDashboardListsFiltersState, filters);

export const getCheckedFilters: MemoizedSelector<CoreState, IFiltersResult> =
  createSelector(getDashboardListsFiltersState, checkedFilters);
export const getIsHasCheckedFilters: MemoizedSelector<CoreState, boolean> =
  createSelector(getDashboardListsFiltersState, isHasCheckedFilters);

export const getShowAccountLists: MemoizedSelector<
  CoreState,
  MANAGE_ACCOUNT_LISTS
> = createSelector(getDashboardListsFiltersState, showAccountLists);
