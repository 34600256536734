import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { CORE_PATHS } from "@core/constants/core-paths";
import { PROFILE_PATHS } from "../../../profile/constants/profile-route-paths";

@Component({
  selector: "bl-account-links",
  templateUrl: "./account-links.component.html",
  styleUrls: ["./account-links.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountLinksComponent {
  readonly appPaths: typeof CORE_PATHS = CORE_PATHS;
  readonly profilePaths: typeof PROFILE_PATHS = PROFILE_PATHS;

  @Input() theme: "default" | "mobile" = "default";
  @Input() isShowBillingDetails: boolean = true;
  @Input() isOwnerOfAdminsAccount: boolean = false;
  @Input() isAdminsAccount: boolean = false;

  @Output() logout: EventEmitter<any> = new EventEmitter();
  @Output() accountLinksRedirect: EventEmitter<any> = new EventEmitter();
}
