import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { CORE_PATHS, HELPCENTER } from "@core/constants/core-paths";
import { E_COMMERCE_PATHS } from "../../../e-commerce/constants/e-commerce-paths";

@Component({
  selector: "bl-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() isMobile: boolean;
  @Input() isBroker: boolean;
  @Input() isAdminsAccount: boolean;
  @Input() isLoggedIn: boolean;
  @Input() profileSubscription: boolean;

  @Output() footerRedirect: EventEmitter<any> = new EventEmitter();

  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly eCommercePaths: typeof E_COMMERCE_PATHS = E_COMMERCE_PATHS;
  readonly helpCenter: string = HELPCENTER;
}
