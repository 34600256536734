import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { getIsAdminsAccount } from "@modules/profile/store/selectors/profile.selector";
import { CoreState } from "../store/reducers";

@Injectable()
export class AdminAccountGuard {
  constructor(private _store: Store<CoreState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this._store.pipe(
      select(getIsAdminsAccount),
      map((isAdminsAccount: boolean) => !isAdminsAccount),
    );
  }
}
