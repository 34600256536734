import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  ViewChild,
} from "@angular/core";

import { fromEvent, Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";

import { WindowRef } from "@core/refs/window-ref.service";

@Component({
  selector: "bl-custom-horizontal-scroll",
  templateUrl: "./custom-horizontal-scroll.component.html",
  styleUrls: ["./custom-horizontal-scroll.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHorizontalScrollComponent
  implements AfterViewInit, AfterContentInit, AfterContentChecked, OnDestroy
{
  // height is important, you need to pass the exact value of content height, 'cause custom scroller uses absolute positioning
  @Input() height: number = 45;
  // padding-left just for desktop, from mobile it's 15px !important, that was easiest solution according to design
  // for all pages (that's design baby)
  @Input() paddingLeft: number = 0;
  @Input() wheelScrollSpeed: number = 30;
  @Input() showHorizontalDivider: boolean = false;

  @ViewChild("hsWrapper", { read: ElementRef, static: true })
  hsWrapper?: ElementRef<HTMLElement>;
  @ViewChild("hsScroller", { read: ElementRef, static: true })
  hsScroller?: ElementRef<HTMLElement>;
  @ViewChild("hsContent", { read: ElementRef, static: true })
  hsContent?: ElementRef<HTMLElement>;

  isInlineListWidthBigger: boolean = false;
  isHovered: boolean = false;

  private _destroyer$: Subject<void> = new Subject<void>();
  private _content: string;

  constructor(
    private _window: WindowRef,
    private _cdr: ChangeDetectorRef,
    private _zone: NgZone,
  ) {}

  ngAfterViewInit(): void {
    if (!this.hsWrapper || !this.hsScroller || !this.hsContent) {
      return;
    }
    // make initial check
    this.makeWidthCheck();
    // make same check on resize
    fromEvent(this._window.nativeElement, "resize")
      .pipe(debounceTime(200), takeUntil(this._destroyer$))
      .subscribe(() => this.makeWidthCheck());

    // custom logic on mouse wheel event, left/right scrolling instead top/bottom
    fromEvent(this.hsWrapper.nativeElement, "wheel")
      .pipe(takeUntil(this._destroyer$))
      .subscribe((event: WheelEvent) => {
        event.preventDefault();
        event.deltaY > 0
          ? (this.hsScroller.nativeElement.scrollLeft += this.wheelScrollSpeed)
          : (this.hsScroller.nativeElement.scrollLeft -= this.wheelScrollSpeed);
      });

    // show/hide scroller on hover if scroller is needed
    fromEvent(this.hsWrapper.nativeElement, "mouseenter")
      .pipe(
        filter(() => this.isInlineListWidthBigger),
        takeUntil(this._destroyer$),
      )
      .subscribe(() => {
        this.isHovered = true;
        this._cdr.markForCheck();
      });
    fromEvent(this.hsWrapper.nativeElement, "mouseleave")
      .pipe(
        filter(() => this.isInlineListWidthBigger),
        takeUntil(this._destroyer$),
      )
      .subscribe(() => {
        this.isHovered = false;
        this._cdr.markForCheck();
      });
  }

  // compare content changes
  ngAfterContentInit(): void {
    this._content = this.hsContent.nativeElement.innerHTML;
  }

  ngAfterContentChecked(): void {
    const c: string = this.hsContent.nativeElement.innerHTML;
    if (c !== this._content) {
      this._content = c;
      this.makeWidthCheck();
    }
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  // compare total and visible width
  // total - vsContent width should be the same as ng-content width (content should be inline)
  // visible - vsWrapper and vsScroller width depends on host width
  makeWidthCheck(): void {
    const temp: boolean =
      this.hsContent.nativeElement.offsetWidth >
      this.hsScroller.nativeElement.offsetWidth;
    if (temp !== this.isInlineListWidthBigger) {
      this._zone.runOutsideAngular(() => {
        let timeout: number = setTimeout(() => {
          this._zone.run(() => {
            this.isInlineListWidthBigger = temp;
            this._cdr.markForCheck();
          });
          clearTimeout(timeout);
          timeout = null;
        }, 0);
      });
    }
  }
}
