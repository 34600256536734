import { ITableHeader } from "@ui/tables/components/table/table.component";

export enum DISTRICT_SCHOOLS_SORTING {
  NAME = "name",
}

export const DISTRICT_SCHOOLS_TABLE_HEADERS: ITableHeader[] = [
  {
    key: "isDone",
    title: "",
    checkbox: true,
    disabled: true,
    tooltipText: "Coming Soon!", // TODO: remove disabled and tooltipText when will implement create list from bids/grants profile
  },
  {
    key: "name",
    title: "Name",
    sortable: true,
  },
  {
    key: "city",
    title: "City",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "enrollment",
    title: "Enrollment",
    sortable: true,
  },
  {
    key: "grants",
    title: "Grants",
    sortable: true,
  },
  {
    key: "schoolType",
    title: "School Type",
    sortable: true,
  },
];

export const MAX_SCHOOL_DISTRICT_SCHOOLS_CACHE: number = 180;
