import { ActivatedRouteSnapshot, UrlSegment } from "@angular/router";

export const getUrlFromActivatedRoute: (
  aRoute: ActivatedRouteSnapshot,
) => string = (routeSnapshots: ActivatedRouteSnapshot): string =>
  routeSnapshots.pathFromRoot
    .map((routeSnapshot: ActivatedRouteSnapshot) =>
      routeSnapshot.url
        .reduce((res: string[], url: UrlSegment) => [...res, url.path], [])
        .join("/"),
    )
    .join("/");
