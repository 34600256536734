import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { bidProfileReducer, IBidProfileState } from "./bid-profile.reducer";

export interface IBidProfileModuleState {
  profile: IBidProfileState;
}

const bidProfileModuleState: ActionReducerMap<IBidProfileModuleState> = {
  profile: bidProfileReducer,
};

// eslint-disable-next-line
export const BID_PROFILE_STATE_NAME = 'BidProfile';
export const BID_PROFILE_STATE: InjectionToken<
  ActionReducerMap<IBidProfileModuleState>
> = new InjectionToken<ActionReducerMap<IBidProfileModuleState>>(
  BID_PROFILE_STATE_NAME,
  { factory: () => bidProfileModuleState },
);
