export enum SyncHistoryStatusTypes {
  CompletedSuccessfully = "CompletedSuccessfully",
  CompletedWithErrors = "CompletedWithErrors",
  FatalError = "FatalError",
}

export const INDICATOR_STATUS_CLASSES: { [key: string]: string } = {
  [SyncHistoryStatusTypes.CompletedSuccessfully]: "status-success",
  [SyncHistoryStatusTypes.CompletedWithErrors]: "status-warning",
  [SyncHistoryStatusTypes.FatalError]: "status-error",
};

export const REFRESH_CUSTOM_OBJECT_PROGRESS: string =
  "Your CloudSync cache is being refreshed!";
export const REFRESH_CUSTOM_OBJECT_READY: string =
  "Your CloudSync process is complete!";
