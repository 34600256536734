import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { of, throwError, Observable } from "rxjs";
import {
  catchError,
  mapTo,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { IServerError } from "@shared/interfaces/server-error";
import { getCreditCardsSuccessAction } from "../store/actions/payment-cards.action";
import {
  getBillingErrorAction,
  getBillingSuccessAction,
} from "../store/actions/payment.action";
import { getCreditCards } from "../store/selectors/payment-cards.selector";
import { getDefaultBillingInfo } from "../store/selectors/payment.selector";

import { PaymentService } from "../services/payment.service";

import {
  ICreditCardInfo,
  IDefaultBillingInfo,
  IUserBillingInfoData,
} from "../interfaces/e-commerce";

@Injectable()
export class BillingInfoExistGuard {
  constructor(
    private _store: Store<CoreState>,
    private _paymentService: PaymentService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._store.pipe(
      select(getDefaultBillingInfo),
      withLatestFrom(this._store.pipe(select(getCreditCards))),
      take(1),
      switchMap(
        ([user, credit_card_details]: [
          IDefaultBillingInfo,
          ICreditCardInfo[],
        ]) =>
          user && credit_card_details
            ? of({ user, credit_card_details } as IUserBillingInfoData)
            : this._paymentService.userBillingInfo(),
      ),
      tap(({ user, credit_card_details }: IUserBillingInfoData) => {
        this._store.dispatch(getBillingSuccessAction(user));
        this._store.dispatch(getCreditCardsSuccessAction(credit_card_details));
      }),
      mapTo(true),
      catchError((error: IServerError) => {
        this._store.dispatch(getBillingErrorAction(error));
        return throwError(error);
      }),
    );
  }
}
