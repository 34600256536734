import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { environment } from "@env/environment";

import { UserCreditsInfoPopUp } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";
import { UserCreditsInfoResult } from "../../models/pop-up-result";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-user-credits-info-pop-up",
  templateUrl: "./user-credits-info-pop-up.component.html",
  styleUrls: ["./user-credits-info-pop-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserCreditsInfoPopUpComponent {
  readonly phoneNumber: string = environment.contactPhoneNumber;

  constructor(
    private popUpRef: PopUpRef<
      UserCreditsInfoPopUpComponent,
      UserCreditsInfoResult
    >,
    @Inject(POP_UP_DATA) public data: UserCreditsInfoPopUp,
  ) {}

  onClose(): void {
    this.popUpRef.close({ answer: false });
  }

  onConfirm(): void {
    this.popUpRef.close({ answer: true });
  }
}
