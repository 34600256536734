import {
  IAddonFieldItem,
  IAddonFieldItems,
  IColumn,
  IOutputColumns,
} from "@modules/cdl/cdl-list-customize/interfaces";

export const availableAddOns: (
  items: IOutputColumns,
  selectedAddons: IAddonFieldItems,
) => IAddonFieldItems = (
  items: IOutputColumns,
  selectedAddons: IAddonFieldItems,
): IAddonFieldItems => {
  const results: IOutputColumns = items.filter(
    (item: IColumn) => item.fieldName,
  );

  return results.map((item: IColumn) => ({
    id: item.id,
    fieldName: item.fieldName,
    checked: !!selectedAddons.find(
      (currentItem: IAddonFieldItem) => currentItem.id === item.id,
    )?.checked,
  }));
};

export const changeCheckedAvailableAddOns: (
  items: IAddonFieldItems,
  currentItem: IAddonFieldItem,
) => IAddonFieldItems = (
  items: IAddonFieldItems,
  currentItem: IAddonFieldItem,
): IAddonFieldItems => {
  return items.map((item: IAddonFieldItem) =>
    item.fieldName === currentItem.fieldName
      ? { ...item, checked: currentItem.checked }
      : item,
  );
};

export const resetCheckedAvailableAddOns: (
  items: IAddonFieldItems,
) => IAddonFieldItems = (items: IAddonFieldItems): IAddonFieldItems =>
  items.map((item: IAddonFieldItem) => ({ ...item, checked: false }));

export const filterColumnsByAddOns: (
  items: IOutputColumns,
  _availableAddOns: IAddonFieldItems,
) => IOutputColumns = (
  items: IOutputColumns,
  _availableAddOns: IAddonFieldItems,
): IOutputColumns => {
  const checkedAddOnsNames: string[] = _availableAddOns
    .filter((item: IAddonFieldItem) => item.checked)
    .map((item: IAddonFieldItem) => item.fieldName);

  return items.filter((item: IColumn) =>
    checkedAddOnsNames.includes(item.fieldName),
  );
};

export const isCheckedAddOns: (items: IAddonFieldItems) => boolean = (
  items: IAddonFieldItems,
): boolean => !!items.find((item: IAddonFieldItem) => item.checked);

export const uniqueAddOns: (items: IAddonFieldItems) => IAddonFieldItem[] = (
  items: IAddonFieldItems,
) => [
  ...new Map(
    items.map((item: IAddonFieldItem) => [item.fieldName, item]),
  ).values(),
];
