import { createSelector, MemoizedSelector } from "@ngrx/store";

import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { CoreState } from "../reducers";
import {
  routerHistory,
  RouterHistoryState,
} from "../reducers/router-history.reducer";

export const getRouterHistoryState: GetFromStateInSelectors<
  CoreState,
  RouterHistoryState
> = (state: CoreState) => state.routerHistory;
export const getRouterHistory: MemoizedSelector<CoreState, string[]> =
  createSelector(getRouterHistoryState, routerHistory);
