import { createSelector, MemoizedSelector } from "@ngrx/store";

import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { CoreState } from "../reducers";
import {
  commonError,
  ICommonErrorsState,
} from "../reducers/common-errors.reducer";

export const getCommonErrorsState: GetFromStateInSelectors<
  CoreState,
  ICommonErrorsState
> = (state: CoreState) => state.commonErrors;
export const getCommonError: MemoizedSelector<CoreState, string> =
  createSelector(getCommonErrorsState, commonError);
