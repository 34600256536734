import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { CustomHorizontalScrollComponent } from "./components/custom-horizontal-scroll/custom-horizontal-scroll.component";
import { CustomScrollWrapperComponent } from "./components/custom-scroll-wrapper/custom-scroll-wrapper.component";

import { AnchorScrollDirective } from "./directives/anchor-scroll.directive";
import { CustomScrollDirective } from "./directives/custom-scroll.directive";
import { InfinityScrollPaginationDirective } from "./directives/infinity-scroll-pagination.directive";
import { ScrollMeasureDirective } from "./directives/scroll-measure.directive";
import { WindowAutoScrollDirective } from "./directives/window-autoscroll.directive";

const imports: any[] = [CommonModule];

const declarations: any[] = [
  AnchorScrollDirective,
  CustomScrollDirective,
  ScrollMeasureDirective,
  WindowAutoScrollDirective,
  InfinityScrollPaginationDirective,
  CustomHorizontalScrollComponent,
  CustomScrollWrapperComponent,
];

const exports: any[] = [...declarations];

@NgModule({
  imports,
  declarations,
  exports,
})
export class CustomScrollsModule {}
