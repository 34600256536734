import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/college-office.action";

import { MVEntityAvailable } from "../../../profile/utils/mv-entity-available";

import { IServerError } from "@shared/interfaces/server-error";
import { IBids } from "@shared/modules/bids-entity/interfaces";
import {
  IGeneralAdditional,
  IGrants,
  IProfileAddress,
} from "@shared/modules/institution-profiles/interfaces/profiles";
import { IMVEntityAvailable } from "../../../profile/interfaces/mv-entity-available";
import { IUserPermissions } from "../../../profile/interfaces/profile";
import { ICoordinates } from "../../../segment/interfaces/geography";
import {
  ICollegeOffice,
  ICollegeOfficeHeader,
  ICollegeOfficeInfo,
  ICollegeOfficeOverview,
} from "../../interfaces";

import { PermissionsKeys } from "@core/constants/permissions";
import { HIDDEN_BY } from "@shared/modules/institution-profiles/constants/profiles";

export interface ICollegeOfficeState {
  profile: ICollegeOffice | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: ICollegeOfficeState = {
  profile: null,
  pending: false,
  error: null,
};

const getCollegeOffice: OnReducer<ICollegeOfficeState, ActionType<any>> = (
  state: ICollegeOfficeState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getCollegeOfficeError: OnReducer<ICollegeOfficeState, ActionType<any>> = (
  state: ICollegeOfficeState,
  { payload: error }: Payload<any>,
) => ({
  ...state,
  error,
  pending: false,
});

const getCollegeOfficeSuccess: OnReducer<
  ICollegeOfficeState,
  ActionType<any>
> = (state: ICollegeOfficeState, { payload: profile }: Payload<any>) => ({
  ...state,
  profile,
  pending: false,
});

const resetCollegeOfficeState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICollegeOfficeState> =
  createReducer<ICollegeOfficeState>(
    initialState,
    on(actions.getCollegeOfficeAction, getCollegeOffice),
    on(actions.getCollegeOfficeErrorAction, getCollegeOfficeError),
    on(actions.getCollegeOfficeSuccessAction, getCollegeOfficeSuccess),

    on(actions.resetCollegeOfficeStateAction, resetCollegeOfficeState),
  );

export function collegeOfficeReducer(
  state: ICollegeOfficeState,
  action: Action,
): ICollegeOfficeState {
  return reducer(state, action);
}

export const collegeOffice: GetFromState<
  ICollegeOffice,
  ICollegeOfficeState
> = (state: ICollegeOfficeState): ICollegeOffice => state.profile;
export const collegeOfficePending: GetFromState<
  boolean,
  ICollegeOfficeState
> = (state: ICollegeOfficeState): boolean => state.pending;
export const collegeOfficeError: GetFromState<
  IServerError,
  ICollegeOfficeState
> = (state: ICollegeOfficeState): IServerError => state.error;
export const isPersonnel: GetFromState<boolean, ICollegeOffice> = (
  profile: ICollegeOffice,
): boolean => profile && profile.isPersonnel;

export const collegeOfficeInfo: GetFromState<
  ICollegeOfficeInfo,
  ICollegeOffice
> = (profile: ICollegeOffice): ICollegeOfficeInfo => profile && profile.info;

export const collegeOfficeInfoOverview: GetFromState<
  ICollegeOfficeOverview,
  ICollegeOfficeInfo
> = (info: ICollegeOfficeInfo): ICollegeOfficeOverview => info && info.overview;
export const collegeOfficeInfoAdditional: GetFromState<
  IGeneralAdditional,
  ICollegeOfficeInfo
> = (info: ICollegeOfficeInfo): IGeneralAdditional => info && info.additional;

export const collegeOfficeBids: GetFromState<IBids, ICollegeOffice> = (
  profile: ICollegeOffice,
): IBids => profile && profile.bids;
export const isHiddenBids: GetFromState<boolean, IBids> = (
  _bids: IBids,
): boolean =>
  (_bids && _bids.total && _bids.total.toString() === HIDDEN_BY.value) || null;
export const collegeOfficeGrants: GetFromState<IGrants, ICollegeOffice> = (
  profile: ICollegeOffice,
): IGrants => profile && profile.grants;
export const isHiddenGrants: GetFromState<boolean, IGrants> = (
  _grants: IGrants,
): boolean => {
  return (
    (_grants &&
      _grants.totalActive &&
      _grants.totalActive.value &&
      _grants.totalActive.value.toString() === HIDDEN_BY.value) ||
    null
  );
};
export const collegeOfficeHeader: GetFromState<
  ICollegeOfficeHeader,
  ICollegeOffice
> = (profile: ICollegeOffice): ICollegeOfficeHeader =>
  profile && profile.header;

export const isCollegeOfficePidHidden: GetFromState<
  boolean,
  ICollegeOfficeHeader
> = (header: ICollegeOfficeHeader): boolean =>
  header && header.pid.toString() === HIDDEN_BY.value;
export const collegeOfficeCoordinates: GetFromState<
  ICoordinates,
  ICollegeOfficeHeader
> = (header: ICollegeOfficeHeader): ICoordinates => {
  if (header) {
    const { latitude: lat, longitude: lng }: Partial<IProfileAddress> =
      header.physicalAddress || header.address;
    return { lat, lng };
  }

  return { lat: null, lng: null };
};

export const marketViewAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageViews, _data, _isHidden);
};

export const grantAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageGrants, _data, _isHidden);
};

export const bidAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageBids, _data, _isHidden);
};
