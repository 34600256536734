import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export function sortByAlphabetAsync<T>(
  fieldName: string,
): (observer: Observable<T[]>) => Observable<T[]> {
  return function (observer: Observable<T[]>): Observable<T[]> {
    return observer.pipe(
      map((array: any[]) =>
        array.sort((itemA: any, itemB: any) =>
          itemA[fieldName] > itemB[fieldName] ? 1 : -1,
        ),
      ),
    );
  };
}
