import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { IDashboardListsModuleState } from "../reducers";
import {
  isHasQuery,
  isOpen,
  query,
  IDashboardListsQueryState,
} from "../reducers/dashboard-lists-search.reducer";

import { getDashboardListsModuleState } from ".";

export const dashboardListsSearchState: GetFromStateInSelectors<
  CoreState,
  IDashboardListsQueryState
> = createSelector(
  getDashboardListsModuleState,
  (state: IDashboardListsModuleState): IDashboardListsQueryState =>
    state.dashboardListsQuery,
);

export const getDashboardListsQuery: MemoizedSelector<CoreState, string> =
  createSelector(dashboardListsSearchState, query);
export const getIsHasSearch: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsSearchState, isHasQuery);
export const getDashboardListsQueryIsOpen: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(dashboardListsSearchState, isOpen);
