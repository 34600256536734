import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

import { CORE_PATHS } from "@core/constants/core-paths";
import {
  CREDIT_METER_TEXT,
  REFRESH_CREDITS_BTN_TOOLTIP_TEXT,
  UNLIMITED_CREDITS_MSG,
} from "@shared/constants/tooltips/tooltips-text";
import { E_COMMERCE_PATHS } from "../../../e-commerce/constants/e-commerce-paths";

@Component({
  selector: "bl-credit-meter",
  templateUrl: "./credit-meter.component.html",
  styleUrls: ["./credit-meter.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreditMeterComponent {
  @Input() isMobile: boolean;
  @Input() isOpenedFist: boolean;
  @Input() showTooltipOnInit: boolean;
  @Input() isUnlimitedUser: boolean;
  @Input() userCredits: number;
  @Input() pending: boolean;
  @Input() isBroker: boolean;
  @Input() userCreditExpirationToolTip: string;
  @Input() subscriptionStatus: boolean;

  @Output() creditMeterRefresh: EventEmitter<any> = new EventEmitter();
  @Output() creditMeterGoTo: EventEmitter<any> = new EventEmitter();
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly eCommercePaths: typeof E_COMMERCE_PATHS = E_COMMERCE_PATHS;
  readonly tooltipText: string = CREDIT_METER_TEXT;
  readonly unlimitedCreditsMsg: string = UNLIMITED_CREDITS_MSG;
  readonly refreshBtnTooltipText: string = REFRESH_CREDITS_BTN_TOOLTIP_TEXT;

  constructor() {}

  refresh(): void {
    this.creditMeterRefresh.emit();
  }

  goTo(): void {
    this.creditMeterGoTo.emit();
  }
}
