import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IDataListSetting, IDataListSettings } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_LIST_SETTINGS_DATA = "[LIST SETTINGS]: get list settings data",
  GET_LIST_SETTINGS_DATA_ERROR = "[LIST SETTINGS]: get list settings data error",
  GET_LIST_SETTINGS_DATA_SUCCESS = "[LIST SETTINGS]: get list settings data success",

  UPDATE_LIST_SETTINGS_DATA = "[LIST SETTINGS]: update list settings data",
  UPDATE_LIST_SETTINGS_DATA_ERROR = "[LIST SETTINGS]: update list settings data error",
  UPDATE_LIST_SETTINGS_DATA_SUCCESS = "[LIST SETTINGS]: update list settings data success",

  DELETE_LIST_SETTINGS_DATA = "[LIST SETTINGS]: delete list settings data",
  DELETE_LIST_SETTINGS_DATA_ERROR = "[LIST SETTINGS]: delete list settings data error",
  DELETE_LIST_SETTINGS_DATA_SUCCESS = "[LIST SETTINGS]: delete list settings data success",
}

export const getListSettingsAction: CreateActionType<
  ACTION_TYPES.GET_LIST_SETTINGS_DATA,
  number
> = createAction(ACTION_TYPES.GET_LIST_SETTINGS_DATA, createPayload<number>());
export const getListSettingsErrorAction: CreateActionType<
  ACTION_TYPES.GET_LIST_SETTINGS_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_LIST_SETTINGS_DATA_ERROR,
  createPayload<IServerError>(),
);
export const getListSettingsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_LIST_SETTINGS_DATA_SUCCESS,
  IDataListSettings
> = createAction(
  ACTION_TYPES.GET_LIST_SETTINGS_DATA_SUCCESS,
  createPayload<IDataListSettings>(),
);

export const updateListSettingsAction: CreateActionType<
  ACTION_TYPES.UPDATE_LIST_SETTINGS_DATA,
  IDataListSetting
> = createAction(
  ACTION_TYPES.UPDATE_LIST_SETTINGS_DATA,
  createPayload<IDataListSetting>(),
);
export const updateListSettingsErrorAction: CreateActionType<
  ACTION_TYPES.UPDATE_LIST_SETTINGS_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.UPDATE_LIST_SETTINGS_DATA_ERROR,
  createPayload<IServerError>(),
);
export const updateListSettingsSuccessAction: CreateActionType<
  ACTION_TYPES.UPDATE_LIST_SETTINGS_DATA_SUCCESS,
  IDataListSettings
> = createAction(
  ACTION_TYPES.UPDATE_LIST_SETTINGS_DATA_SUCCESS,
  createPayload<IDataListSettings>(),
);

export const removeSettingsListAction: CreateActionType<
  ACTION_TYPES.DELETE_LIST_SETTINGS_DATA,
  IDataListSetting
> = createAction(
  ACTION_TYPES.DELETE_LIST_SETTINGS_DATA,
  createPayload<IDataListSetting>(),
);
export const removeSettingsListErrorAction: CreateActionType<
  ACTION_TYPES.DELETE_LIST_SETTINGS_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.DELETE_LIST_SETTINGS_DATA_ERROR,
  createPayload<IServerError>(),
);
export const removeSettingsListSuccessAction: CreateActionType<
  ACTION_TYPES.DELETE_LIST_SETTINGS_DATA_SUCCESS,
  IDataListSetting
> = createAction(
  ACTION_TYPES.DELETE_LIST_SETTINGS_DATA_SUCCESS,
  createPayload<IDataListSetting>(),
);
