import { ModuleWithProviders, NgModule } from "@angular/core";
import { TrustArcConsentService } from "./services/trust-arc-consent.service";
import { TRUST_ARC_CONSENT_STATE } from "./constants/trust-arc-consent";

@NgModule({
  providers: [TrustArcConsentService],
})
export class TrustArcConsentModule {
  static forRoot(state: boolean): ModuleWithProviders<TrustArcConsentModule> {
    return {
      ngModule: TrustArcConsentModule,
      providers: [
        {
          provide: TRUST_ARC_CONSENT_STATE,
          useValue: state,
        },
      ],
    };
  }
}
