import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import {
  ICreditCardInfo,
  INewCreditCartInfo,
} from "@modules/e-commerce/interfaces/e-commerce";
import { ECommerceState } from "../reducers";
import {
  creditCards,
  newCreditCard,
  newCreditCardSelected,
  selectedCreditCard,
  ICreditCardsState,
} from "../reducers/payment-cards.reducer";

import { getECommerceState } from "./";

export const getCreditCardsState: GetFromStateInSelectors<
  CoreState,
  ICreditCardsState
> = createSelector(
  getECommerceState,
  (state: ECommerceState) => state && state.eCommercePaymentCards,
);

export const getCreditCards: MemoizedSelector<CoreState, ICreditCardInfo[]> =
  createSelector(getCreditCardsState, creditCards);

export const isNewCreditCardSelected: MemoizedSelector<CoreState, boolean> =
  createSelector(getCreditCardsState, newCreditCardSelected);
export const getNewCreditCard: MemoizedSelector<CoreState, INewCreditCartInfo> =
  createSelector(getCreditCardsState, newCreditCard);
export const getSelectedCreditCard: MemoizedSelector<
  CoreState,
  ICreditCardInfo
> = createSelector(getCreditCardsState, selectedCreditCard);
