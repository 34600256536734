import { Injectable } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";

import { findCheckedValue } from "../utils/profile";

import {
  IEditAccountUser,
  IOpenSeatAddOns,
  ISelectedUserData,
  SEATS,
} from "../interfaces/marketview";

import { FORM_VALIDATION_ERRORS } from "@shared/constants/validators/forms-validations-errors";

import { Validators } from "@shared/validators/validators";

@Injectable()
export class ProfileFormsService {
  constructor(private _fb: UntypedFormBuilder) {}

  createUserForm(
    user: IEditAccountUser,
    selectedUserData: ISelectedUserData,
  ): UntypedFormGroup {
    const {
      seatId,
      stateIds,
      bidStateIds,
      grantStateIds,
      roleId,
      addOns,
      id,
      accessLists,
      accessCDLLists,
      allocatedCredits,
    }: IEditAccountUser = user;

    const {
      openAddOns,
      selectedBidStateAddOnIds,
      selectedGrantStateAddOnIds,
      isDisabledUser,
      creditsInfo,
    }: ISelectedUserData = selectedUserData;

    const showAccountLists: number | null = findCheckedValue(accessLists);
    const accessCDLListsValue: number | null = findCheckedValue(accessCDLLists);

    const newForm: UntypedFormGroup = this._fb.group({
      seatId: [
        seatId,
        [Validators.required(FORM_VALIDATION_ERRORS.seat.required)],
      ],
      roleId: [roleId, Validators.required()],
      id: [id, Validators.required()],
      isAllocated: [typeof allocatedCredits === "number"],
      allocatedCredits: [
        typeof allocatedCredits === "number"
          ? allocatedCredits
          : creditsInfo.accountCredits,
      ],
    });

    if (typeof showAccountLists === "number") {
      newForm.addControl(
        "showAccountLists",
        this._fb.control(showAccountLists),
      );
    }

    if (typeof accessCDLListsValue === "number") {
      newForm.addControl(
        "accessCDLLists",
        this._fb.control(accessCDLListsValue),
      );
    }

    if (seatId === SEATS.State && this.checkAllValues(stateIds)) {
      newForm.addControl(
        "stateIds",
        this._fb.array([
          ...stateIds.map((_stateId: number) =>
            this.createStateFormGroup(
              _stateId,
              openAddOns,
              selectedBidStateAddOnIds,
              selectedGrantStateAddOnIds,
              isDisabledUser,
              bidStateIds,
              grantStateIds,
            ),
          ),
        ]),
      );
    }

    // if user has national addOns
    if (seatId === SEATS.National) {
      newForm.addControl(
        "nationalAddOns",
        this.createNationalAddOnGroup(addOns, openAddOns, isDisabledUser),
      );
    }
    return newForm;
  }

  createStateFormGroup(
    stateId: number,
    openAddOns: IOpenSeatAddOns,
    selectedBidAddOnIds: number[] = [],
    selectedGrantAddOnIds: number[] = [],
    isDisabled: boolean = false,
    bidStateIds: number[] = [],
    grantStateIds: number[] = [],
  ): UntypedFormGroup {
    const {
      bidStateAvailable,
      bidStateUnassigned,
      grantStateAvailable,
      grantStateUnassigned,
    }: IOpenSeatAddOns = openAddOns;

    return this._fb.group({
      stateId: [stateId ? stateId : null, Validators.required()],
      isBidStateId: [
        {
          value: bidStateIds.includes(stateId) || null,
          disabled:
            (!bidStateIds.includes(stateId) &&
              !bidStateAvailable.includes(stateId) &&
              selectedBidAddOnIds.length >= bidStateUnassigned) ||
            isDisabled,
        },
      ],
      isGrantStateId: [
        {
          value: grantStateIds.includes(stateId) || null,
          disabled:
            (!grantStateIds.includes(stateId) &&
              !grantStateAvailable.includes(stateId) &&
              selectedGrantAddOnIds.length >= grantStateUnassigned) ||
            isDisabled,
        },
      ],
    });
  }

  createNationalAddOnGroup(
    values: number[] = [],
    openAddOns: IOpenSeatAddOns,
    isDisabled: boolean = false,
  ): UntypedFormGroup {
    return this._fb.group({
      isNationalBidAddOn: [
        {
          value: values.includes(SEATS.BidsAddOn) || null,
          disabled:
            (!values.includes(SEATS.BidsAddOn) &&
              openAddOns.bidNational <= 0) ||
            isDisabled,
        },
      ],
      isNationalGrantAddOn: [
        {
          value: values.includes(SEATS.GrantsAddOn) || null,
          disabled:
            (!values.includes(SEATS.GrantsAddOn) &&
              openAddOns.grantNational <= 0) ||
            isDisabled,
        },
      ],
    });
  }

  private checkAllValues(values: number[]): boolean {
    return (
      values &&
      values.length &&
      values.every((item: number): boolean => typeof item === "number")
    );
  }
}
