import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import * as actions from "@core/store/actions/list-create-from-selected.action";
import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";

import { fullCurrentTime } from "@shared/utils/converters";

import { IListCreatePayload } from "@modules/list-create/interfaces/list-create";
import { IServerError } from "@shared/interfaces/server-error";

export interface IListCreateFromSelectedState {
  creating: boolean;
  creatingError: IServerError | null;
}

const initialState: IListCreateFromSelectedState = {
  creating: false,
  creatingError: null,
};

const createListFromSelectedHandler: OnReducer<
  IListCreateFromSelectedState,
  ActionType<any>
> = (state: IListCreateFromSelectedState) => ({
  ...state,
  creating: true,
  creatingError: null,
});

const createListFromSelectedSuccessHandler: OnReducer<
  IListCreateFromSelectedState,
  ActionType<any>
> = (state: IListCreateFromSelectedState) => ({
  ...state,
  creating: false,
  creatingError: null,
});

const createListFromSelectedErrorHandler: OnReducer<
  IListCreateFromSelectedState,
  ActionType<Payload<any>>
> = (state: IListCreateFromSelectedState, { payload }: Payload<any>) => ({
  ...state,
  creating: false,
  creatingError: { ...payload },
});

const resetCreateListBySelectedState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IListCreateFromSelectedState> =
  createReducer<IListCreateFromSelectedState>(
    initialState,

    on(actions.listCreateFromSelectedAction, createListFromSelectedHandler),
    on(
      actions.createListFromSelectedSuccessAction,
      createListFromSelectedSuccessHandler,
    ),
    on(
      actions.createListFromSelectedErrorAction,
      createListFromSelectedErrorHandler,
    ),

    on(actions.resetCreateListBySelectedState, resetCreateListBySelectedState),
  );

export function listCreateFromSelectedReducer(
  state: IListCreateFromSelectedState,
  action: Action,
): IListCreateFromSelectedState {
  return reducer(state, action);
}

export const creating: GetFromState<boolean, IListCreateFromSelectedState> = (
  state: IListCreateFromSelectedState,
): boolean => state && state.creating;
export const creatingError: GetFromState<
  IServerError | null,
  IListCreateFromSelectedState
> = (state: IListCreateFromSelectedState): IServerError | null =>
  state && state.creatingError;

export const selectedRecordsType: GetFromState<number, Params> = ({
  recordsType,
}: Params): number => parseInt(recordsType, 10) || null;
export const selectedViewId: GetFromState<number, Params> = ({
  viewId,
}: Params): number => parseInt(viewId, 10) || null;

export const selectedSchoolsDistrictPid: GetFromState<number, Params> = ({
  schoolsDistrictPid,
}: Params): number => parseInt(schoolsDistrictPid, 10) || null;

export const selectedGrantId: GetFromState<number, Params> = ({
  grantId,
}: Params): number => parseInt(grantId, 10) || null;

export const selectedPersonsIds: GetFromState<number[], Params> = ({
  personIds,
}: Params): number[] =>
  personIds ? (personIds as string).split("-").map((id: string) => +id) : null;

export const selectedPids: GetFromState<number[], Params> = ({
  pids,
}: Params): number[] =>
  pids ? (pids as string).split("-").map((pid: string) => +pid) : null;

export const listName: GetFromState<string, Params> = ({
  name,
}: Params): string =>
  name ? `${name.slice(0, 55)} ${fullCurrentTime(new Date())}` : "";

export const createData: GetFromState<
  IListCreatePayload,
  string,
  number,
  number[],
  number,
  number[],
  number
> = (
  name: string,
  viewId: number,
  pids: number[],
  schoolsDistrictPid: number,
  personIds: number[],
  grantId: number,
): IListCreatePayload => {
  let params: { [key: string]: any } = {
    name,
    viewId,
  };

  if (pids) {
    params = { ...params, pids };
  }

  if (personIds) {
    params = { ...params, personIds };
  }

  if (schoolsDistrictPid) {
    params = { ...params, schoolsDistrictPid };
  }

  if (grantId) {
    params = { ...params, grantId };
  }

  return Object.entries(params).reduce(
    (_params: {}, [key, value]: any) =>
      value ? { ..._params, [key]: value } : _params,
    {},
  ) as IListCreatePayload;
};
