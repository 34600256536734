import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "bl-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @ViewChild("bar", { static: true, read: ElementRef }) bar: ElementRef<any>;

  @Input() set percent(value: number) {
    this._percent = value || 0;
    this._renderer.setStyle(this.bar.nativeElement, "width", `${100 - value}%`);
  }

  get percent(): number {
    return this._percent;
  }

  private _percent: number;

  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
  ) {}
}
