import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewResultsAlertsResponse } from "../../interfaces";

import { SORT_DIRECTION } from "@shared/constants/sort";
import { REQUEST_VIEW_ALERTS_SORT } from "../../constants";

enum ACTION_TYPES {
  GET = "[VIEW RESULTS ALERTS INSTITUTION] Get institution alerts",
  GET_ERROR = "[VIEW RESULTS ALERTS INSTITUTION] Get institution alerts error",
  GET_SUCCESS = "[VIEW RESULTS ALERTS INSTITUTION] Get institution alerts success",

  SORT = "[VIEW RESULTS ALERTS INSTITUTION] Sort institution",
  ORDER = "[VIEW RESULTS ALERTS INSTITUTION] Order institution",
  UPDATE_PAGE = "[VIEW RESULTS ALERTS INSTITUTION] Update institution page",

  SET_SPINNER_STATE = "[VIEW RESULTS ALERTS INSTITUTION] Set spinner state",

  UPDATE_QUERY = "[VIEW RESULTS ALERTS INSTITUTION] Update institution page query",

  RESET = "[VIEW RESULTS ALERTS INSTITUTION] Reset institution state",
}

export const getViewResultsInstitutionAlertsAction: CreateActionType<
  ACTION_TYPES.GET,
  any
> = createAction(ACTION_TYPES.GET, createPayload<any>());
export const getViewResultsInstitutionAlertsErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getViewResultsInstitutionAlertsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IViewResultsAlertsResponse
> = createAction(
  ACTION_TYPES.GET_SUCCESS,
  createPayload<IViewResultsAlertsResponse>(),
);

export const sortViewResultsInstitutionAlertsAction: CreateActionType<
  ACTION_TYPES.SORT,
  REQUEST_VIEW_ALERTS_SORT
> = createAction(ACTION_TYPES.SORT, createPayload<REQUEST_VIEW_ALERTS_SORT>());
export const orderViewResultsInstitutionAlertsAction: CreateActionType<
  ACTION_TYPES.ORDER,
  SORT_DIRECTION
> = createAction(ACTION_TYPES.ORDER, createPayload<SORT_DIRECTION>());
export const updateViewResultsInstitutionAlertsPageAction: CreateActionType<
  ACTION_TYPES.UPDATE_PAGE,
  number
> = createAction(ACTION_TYPES.UPDATE_PAGE, createPayload<number>());

export const changeViewResultInstitutionAlertsQueryAction: CreateActionType<
  ACTION_TYPES.UPDATE_QUERY,
  Params
> = createAction(ACTION_TYPES.UPDATE_QUERY, createPayload<Params>());

export const setViewResultsInstitutionAlertsSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_SPINNER_STATE,
  boolean
> = createAction(ACTION_TYPES.SET_SPINNER_STATE, createPayload<boolean>());

export const resetViewResultsInstitutionAlertsStateAction: CreateActionType<
  ACTION_TYPES.RESET,
  any
> = createAction(ACTION_TYPES.RESET, createPayload<any>());
