import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from "@angular/forms";
import { select, Store } from "@ngrx/store";

import { Observable, Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { getStatesByCountryAction } from "@modules/countries/store/actions/countries.action";
import {
  getCountriesAsControlOptions,
  getCountryStatesAsControlOptions,
} from "@modules/countries/store/selectors/countries.selector";

import { AsyncValidatorService } from "@core/services/async-validator.service";
import { LayoutService } from "@core/services/layout.service";
import { PaymentFormService } from "@modules/e-commerce/utils/payment-forms.service";

import { CardNumberMaskPipe } from "@shared/modules/pipes/card-pipes/pipes/card-number-mask.pipe";
import { CvvMaskPipe } from "@shared/modules/pipes/card-pipes/pipes/cvv-mask.pipe";
import { LongExpDateMaskPipe } from "@shared/modules/pipes/card-pipes/pipes/long-exp-date-mask.pipe";

import { getCardType } from "@shared/utils/get-card-type";

import { ICheckAddressResponse } from "@modules/auth/interfaces/user";
import {
  IDefaultBillingInfo,
  INewCreditCardAddress,
  INewCreditCartInfo,
} from "@modules/e-commerce/interfaces/e-commerce";
import { IControlOption, IControlOptions } from "@shared/interfaces/forms";

import { NewPaymentMethodPopUpData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";
import { NewCreditCardResult } from "../../models/pop-up-result";

import { TRANSACTION_FIELD_ERRORS } from "@modules/e-commerce/constants/payment";
import { FLAT_INPUT_THEME } from "@shared/constants/flat-input";
import { SECURITY_TYPE } from "@shared/constants/log-rocket-config";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-new-payment-method-pop-up-content",
  templateUrl: "./new-payment-method-pop-up-content.component.html",
  styleUrls: ["./new-payment-method-pop-up-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NewPaymentMethodPopUpContentComponent
  implements OnInit, OnDestroy
{
  private _destroyer$: Subject<void> = new Subject();
  readonly securityType: typeof SECURITY_TYPE = SECURITY_TYPE;

  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  countriesOptions$: Observable<IControlOption[]> = this._store
    .pipe(select(getCountriesAsControlOptions))
    .pipe(
      map((countries: IControlOptions) =>
        countries.filter((country: IControlOption) => country.value === "US"),
      ),
    );
  countryStatesOptions$: Observable<IControlOption[]> = this._store.pipe(
    select(getCountryStatesAsControlOptions),
  );

  longExpDateMask: LongExpDateMaskPipe = new LongExpDateMaskPipe();
  cvvMask: CvvMaskPipe = new CvvMaskPipe();
  cardNumberMask: CardNumberMaskPipe = new CardNumberMaskPipe();

  topErrorMsg: string | null;
  invalidFields: string[];
  defaultBillingInfo: IDefaultBillingInfo;
  newCreditCard: INewCreditCartInfo;
  form: UntypedFormGroup;

  get address(): UntypedFormGroup {
    return this.form.get("address") as UntypedFormGroup;
  }

  get usAccountAddress(): AbstractControl {
    return this.form.controls["usAccountAddress"] as UntypedFormControl;
  }

  get cardNumber(): UntypedFormControl {
    return this.form.controls["card_number"] as UntypedFormControl;
  }

  get cardType(): UntypedFormControl {
    return this.form.controls["card_type"] as UntypedFormControl;
  }

  get addressState(): UntypedFormControl {
    return this.form.controls["bill_to_address_state"] as UntypedFormControl;
  }

  get addressCountry(): UntypedFormControl {
    return this.form.controls["bill_to_address_country"] as UntypedFormControl;
  }

  constructor(
    @Inject(POP_UP_DATA) public data: NewPaymentMethodPopUpData,
    private _popUpRef: PopUpRef<
      NewPaymentMethodPopUpContentComponent,
      NewCreditCardResult
    >,
    private _service: AsyncValidatorService,
    private _layoutService: LayoutService,
    private _paymentFormService: PaymentFormService,
    private _store: Store<CoreState>,
  ) {}

  ngOnInit(): void {
    const {
      topErrorMsg,
      invalidFields,
      newCreditCard,
      defaultBillingInfo,
    }: NewPaymentMethodPopUpData = this.data;

    this.topErrorMsg = topErrorMsg || null;
    this.invalidFields = invalidFields || [];
    this.defaultBillingInfo = defaultBillingInfo || null;
    this.newCreditCard = newCreditCard;

    this.form = this._paymentFormService.getNewPaymentForm(
      this.newCreditCard,
      defaultBillingInfo,
    );

    // disable state if no country
    if (!newCreditCard || !newCreditCard.bill_to_address_country) {
      this.addressState.disable();
    }

    this.invalidFields.forEach((field: string) => {
      const control: AbstractControl = this.form.get(field);

      if (control) {
        control.setErrors({
          transactionError: TRANSACTION_FIELD_ERRORS[field] as string,
        });
      }
    });

    this.updateCardTypeOnCardNumberChange();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  submitted(): void {
    this.form.enable();
    if (!this.form.valid) {
      return;
    }

    const { usAccountAddress, ...value }: any = this.form.value;

    const {
      bill_to_forename,
      bill_to_surname,
      card_cvn,
      card_expiry_date,
      card_number,
      card_type,
      currency,
      saveCard,
      bill_to_email,
      bill_to_address_phone,
      ...paymentAddress
    }: any = value;

    this._service
      .paymentAddress(paymentAddress as INewCreditCardAddress)
      .subscribe((errorResponse: ICheckAddressResponse) => {
        if (errorResponse) {
          const { suspectAddress, errorMessages }: ICheckAddressResponse =
            errorResponse;

          if (suspectAddress) {
            this.form = this._paymentFormService.getNewPaymentForm(
              this.newCreditCard,
              this.data.defaultBillingInfo,
            );

            this.form.patchValue({
              bill_to_email,
              bill_to_forename,
              bill_to_surname,
              card_cvn,
              card_expiry_date,
              card_number,
              card_type,
              currency,
              saveCard,
              bill_to_address_phone,
              usAccountAddress,
              ...suspectAddress,
            });
          } else {
            this.form.get("usAccountAddress").setValue(false);
            this.form.setErrors({
              address: errorMessages.join(". "),
            } as ValidationErrors);
          }
        } else {
          this._popUpRef.close({
            answer: true,
            editedNewCreditCard: this.form.value as INewCreditCartInfo,
          });
        }
      });
  }

  canceled(): void {
    this._popUpRef.close({
      answer: false,
    });
  }

  switchAddressForm(state?: boolean): void {
    if (state && this.defaultBillingInfo) {
      this.form.patchValue(
        this._paymentFormService.updateNewCreditCardAddress(
          this.form.value,
          this.defaultBillingInfo,
        ),
      );
    } else {
      const existingCardInfo: INewCreditCartInfo = {
        card_expiry_date: this.form.controls["card_expiry_date"].value,
        card_number: this.form.controls["card_number"].value,
        card_cvn: this.form.controls["card_cvn"].value,
        card_type: this.form.controls["card_type"].value,
        currency: this.form.controls["currency"].value,
        bill_to_forename: "",
        bill_to_surname: "",
        bill_to_email: "",
        bill_to_address_line1: "",
        bill_to_address_line2: "",
        bill_to_address_state: "",
        bill_to_address_country: "",
        bill_to_address_phone: "",
      };
      this.form = this._paymentFormService.getNewPaymentForm(
        existingCardInfo,
        this.defaultBillingInfo,
      );
      if (!this.newCreditCard || !this.newCreditCard.bill_to_address_country) {
        this.addressState.disable();
      }
    }
  }

  private updateCardTypeOnCardNumberChange(): void {
    this.cardNumber.valueChanges
      .pipe(takeUntil(this._destroyer$))
      .subscribe((cardNumber: number | string) =>
        this.cardType.setValue(getCardType(cardNumber), {
          onlySelf: true,
          emitEvent: false,
        }),
      );
  }

  getInvalidTransactionFieldError(field: string): string {
    return TRANSACTION_FIELD_ERRORS[field] as string;
  }

  onCountryChange(): void {
    this._store.dispatch(getStatesByCountryAction(this.addressCountry.value));
    this.form.patchValue({ bill_to_address_state: "" });
    this.addressState.enable();
  }
}
