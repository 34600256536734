import { Pipe, PipeTransform } from "@angular/core";

import { HIDDEN_BY } from "@shared/modules/institution-profiles/constants/profiles";

@Pipe({
  name: "sign",
})
export class SignPipe implements PipeTransform {
  transform(
    value: string | number,
    sign: string = "%",
    isBefore: boolean = false,
  ): string {
    if (
      (typeof value !== "string" && typeof value !== "number") ||
      value === HIDDEN_BY.value
    ) {
      return value;
    }

    return isBefore ? `${sign}${value}` : `${value}${sign}`;
  }
}
