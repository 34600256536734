import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { select, Action, Store } from "@ngrx/store";

import { defer, of, Observable } from "rxjs";
import {
  catchError,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { showNotificationAction } from "@modules/notifications/store/actions/notification.action";
import { getProfileAction } from "@modules/profile/store/actions/profile.action";
import { ActionWithPayload } from "@shared/interfaces/store";
import * as actions from "../actions/cloud-sync-auth.action";
import { platformAssignToAccountAction } from "../actions/cloud-sync-auth.action";
import { go } from "../actions/router-history.action";
import { CoreState } from "../reducers";
import {
  getCurrentListId,
  getIsCanBuyForCredits,
  getPlatformsAvailable,
  getPlatformAssignType,
  getPlatformFields,
  getSandbox,
  getSelectedConnectionId,
  getSelectedPlatform,
  getSelectedPlatformId,
} from "../selectors/cloud-sync-auth.selector";

import { CloudSyncAssignProductPopUpComponent } from "@ui/pop-up/components/cloud-sync-assign-product-pop-up/cloud-sync-assign-product-pop-up.component";
import { CloudSyncAuthPopUpComponent } from "@ui/pop-up/components/cloud-sync-auth-pop-up/cloud-sync-auth-pop-up.component";
import { CloudSyncToPopUpComponent } from "@ui/pop-up/components/cloud-sync-to-pop-up/cloud-sync-to-pop-up.component";

import { PopUpService } from "@ui/pop-up/services/pop-up/pop-up.service";
import { CloudSyncAuthService } from "../../services/cloud-sync-auth.service";
import { StorageService } from "../../services/storage.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IServerError } from "@shared/interfaces/server-error";
import {
  ICloudSyncAssignProductPopUpData,
  ICloudSyncAuthPopUpData,
} from "@ui/pop-up/interfaces";
import {
  ICheckAuth,
  ICheckAuthPayload,
  ICheckAuthResponse,
  ICloudSyncAuthFields,
  ICloudSyncAuthResponse,
  ICloudSyncListInfo,
  ICloudSyncPlatform,
  ICloudSyncPlatforms,
  ICloudSyncPlatformAuthPayload,
  IPlatformAssignPayload,
  IPlatformAssignResponse,
  IShowCloudSyncPopUpPayload,
} from "../../interfaces/cloud-sync";

import {
  CloudSyncToPopUpData,
  ICloudSyncToResult,
} from "@ui/pop-up/models/pop-up-data";

import { E_COMMERCE_PATHS } from "@modules/e-commerce/constants/e-commerce-paths";
import { LIST_CLOUD_SYNC_PATHS } from "@modules/list-cloud-sync/constants/list-cloud-sync-paths";
import { LIST_PATHS } from "@modules/list-common-store/constants/list-paths";
import { LIST_SUMMARY_PATHS } from "@modules/list-summary/constants/list-summary-paths";
import { getCloudSyncAuthPopUpData } from "@ui/pop-up/constants/pop-up-data";
import {
  ASSIGN_PLATFORM_TYPE,
  CLOUD_SYNC_AUTH_ERROR,
  ICloudSyncAuthError,
  PLATFORM_INSTANCE,
} from "../../constants/cloud-sync";
import { CORE_PATHS } from "../../constants/core-paths";
import { NOTIFICATION_TYPES } from "../../constants/notifications";

@Injectable()
export class CloudSyncAuthEffect {
  constructor(
    private _actions$: Actions,
    private _service: CloudSyncAuthService,
    private _store: Store<CoreState>,
    private _popUpService: PopUpService,
  ) {}

  // platforms
  getPlatforms$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPlatformsAction),
      switchMap(() =>
        this._service.getPlatforms().pipe(
          map((_platforms: ICloudSyncPlatforms) =>
            actions.getPlatformsSuccessAction(_platforms),
          ),
          catchError((error: IServerError) =>
            of(actions.getPlatformsErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  // fields of platforms
  getPlatformFields$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPlatformFieldsAction),
      map(({ payload }: ActionWithPayload<number>) => payload),
      switchMap((id: number) =>
        this._service.getPlatformFields(id).pipe(
          map((_fields: ICloudSyncAuthFields) =>
            actions.getPlatformFieldsSuccessAction(_fields),
          ),
          catchError((error: IServerError) =>
            of(actions.getPlatformFieldsErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  getPlatformFieldsSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.getPlatformFieldsSuccessAction),
      map(() => actions.openCloudSyncAuthPopUpAction()),
      catchErrorWithErrorType,
    ),
  );

  // platform assign to account
  platformAssignToAccount$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.platformAssignToAccountAction),
      switchMap(
        ({
          payload: { platformId, type },
        }: ActionWithPayload<IPlatformAssignPayload>) =>
          this._service.platformAssignToAccount(platformId, type).pipe(
            map((response: IPlatformAssignResponse) =>
              actions.platformAssignToAccountSuccessAction(response),
            ),
            catchError((error: HttpErrorResponse) => {
              if (error && error.status === 400) {
                this._store.dispatch(actions.getPlatformsAction());
              }

              return of(
                actions.platformAssignToAccountErrorAction(error.error),
              );
            }),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  platformAssignToAccountSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.platformAssignToAccountSuccessAction),
      withLatestFrom(
        this._store.pipe(select(getPlatformAssignType)),
        this._store.pipe(select(getSelectedPlatformId)),
      ),
      switchMap(
        ([
          {
            payload: { connectionId },
          },
          type,
          platformId,
        ]: [
          ActionWithPayload<IPlatformAssignResponse>,
          ASSIGN_PLATFORM_TYPE,
          number,
        ]) => {
          let _actions: Action[] = [];

          if (type === ASSIGN_PLATFORM_TYPE.CREDITS) {
            StorageService.cloudSyncForProducts = false;
            _actions = [
              ..._actions,
              actions.getPlatformFieldsAction(platformId),
            ];
          }

          if (type === ASSIGN_PLATFORM_TYPE.PRODUCT) {
            StorageService.cloudSyncForProducts = true;
            _actions = [
              ..._actions,
              actions.getPlatformsAction(),
              getProfileAction(),
            ];
          }

          return [..._actions];
        },
      ),
      catchErrorWithErrorType,
    ),
  );

  // platform assign to account by credits
  platformAssignToByCredits$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.platformAssignByCreditsAction),
      switchMap(
        ({
          payload: { platformId, type },
        }: ActionWithPayload<IPlatformAssignPayload>) =>
          this._service.platformAssignToAccount(platformId, type).pipe(
            map((response: IPlatformAssignResponse) =>
              actions.platformAssignByCreditsSuccessAction(response),
            ),
            catchError((error: HttpErrorResponse) => {
              if (error && error.status === 400) {
                this._store.dispatch(actions.getPlatformsAction());
              }

              return of(
                actions.platformAssignByCreditsByCreditsErrorAction(
                  error.error,
                ),
              );
            }),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  platformAssignToByCreditsSuccess$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.platformAssignByCreditsSuccessAction),
          tap(() => (StorageService.cloudSyncForProducts = false)),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  platformAssignToAccountError$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(
        actions.platformAssignToAccountErrorAction,
        actions.platformAssignByCreditsByCreditsErrorAction,
      ),
      filter(
        ({ payload: error }: ActionWithPayload<IServerError>) =>
          error && error.message && !!error.message.length,
      ),
      map(({ payload: error }: ActionWithPayload<IServerError>) =>
        showNotificationAction({
          type: NOTIFICATION_TYPES.ERROR,
          message: error && error.message,
          timeout: 3000,
        }),
      ),
    ),
  );

  // platform auth-pages
  platformAuthAction$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.platformAuthAction),
      withLatestFrom(
        this._store.pipe(select(getSelectedPlatformId)),
        this._store.pipe(select(getSelectedConnectionId)),
      ),
      switchMap(
        ([{ payload }, platformId, connectionId]: [
          ActionWithPayload<ICloudSyncPlatformAuthPayload>,
          number,
          number,
        ]) =>
          this._service.platformSignIn(platformId, payload, connectionId).pipe(
            map((response: ICloudSyncAuthResponse) =>
              response.isValid
                ? actions.platformAuthSuccessAction(response)
                : actions.platformAuthErrorAction(
                    new ICloudSyncAuthError(CLOUD_SYNC_AUTH_ERROR, null),
                  ),
            ),
            catchError((error: IServerError) =>
              of(actions.platformAuthErrorAction(error)),
            ),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  platformAuthSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.platformAuthSuccessAction),
      withLatestFrom(
        this._store.pipe(select(getCurrentListId)),
        this._store.pipe(select(getSelectedPlatformId)),
        this._store.pipe(select(getSandbox)),
      ),
      filter(
        ([
          {
            payload: { isValid },
          },
          listId,
          platformId,
          sandbox,
        ]: [
          ActionWithPayload<ICloudSyncAuthResponse>,
          number,
          number,
          boolean,
        ]) => isValid,
      ),
      map(
        ([
          {
            payload: { isValid },
          },
          listId,
          platformId,
          sandbox,
        ]: [
          ActionWithPayload<ICloudSyncAuthResponse>,
          number,
          number,
          boolean,
        ]) =>
          go(
            [
              CORE_PATHS.LIST,
              listId,
              LIST_PATHS.SUMMARY,
              LIST_SUMMARY_PATHS.SYNC,
              platformId,
            ],
            {
              instance: sandbox
                ? PLATFORM_INSTANCE.SANDBOX
                : PLATFORM_INSTANCE.PROD,
            },
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  // check auth-pages
  checkAuth$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.checkAuthAction),
      switchMap(
        ({
          payload: { checkAuthData, successAction, errorAction },
        }: ActionWithPayload<ICheckAuthPayload>) =>
          this._service
            .checkAuth(checkAuthData.connectionId, checkAuthData.sandbox)
            .pipe(
              map(({ isValid }: ICheckAuthResponse) =>
                actions.checkAuthSuccessAction({
                  isValid,
                  successAction,
                  errorAction,
                }),
              ),
              catchError((error: IServerError) =>
                of(actions.checkAuthErrorAction(error)),
              ),
            ),
      ),
      catchErrorWithErrorType,
    ),
  );

  checkAuthSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.checkAuthSuccessAction),
      map(
        ({
          payload: { isValid, successAction, errorAction },
        }: ActionWithPayload<ICheckAuthResponse>) =>
          isValid ? successAction : errorAction,
      ),
      catchErrorWithErrorType,
    ),
  );

  // show auth-pages or sync to pop up
  showAuthOrSyncToPopUp$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.showAuthOrSyncToPopUpAction),
      map(
        ({ payload }: ActionWithPayload<IShowCloudSyncPopUpPayload>) => payload,
      ),
      map(
        ({
          platform,
          records,
          listId,
          sandbox,
          redirectToSearch,
        }: IShowCloudSyncPopUpPayload) => {
          if (platform.connectionId) {
            const checkAuthData: ICheckAuth = {
              connectionId: platform.connectionId,
              sandbox,
            };
            // is success go to sync page
            const successAction: Action = go(
              [
                CORE_PATHS.LIST,
                listId,
                LIST_PATHS.SUMMARY,
                LIST_SUMMARY_PATHS.SYNC,
                platform.id,
                redirectToSearch
                  ? LIST_CLOUD_SYNC_PATHS.SEARCH
                  : LIST_CLOUD_SYNC_PATHS.INFO,
              ],
              {
                instance: sandbox
                  ? PLATFORM_INSTANCE.SANDBOX
                  : PLATFORM_INSTANCE.PROD,
              },
            );
            // if error open auth-pages pop up
            const errorAction: Action = actions.getPlatformFieldsAction(
              platform.id,
            );

            StorageService.cloudSyncForProducts = true;

            return actions.checkAuthAction({
              checkAuthData,
              successAction,
              errorAction,
            });
          }

          // open cloud 'sync to' pop up
          return actions.showCloudSyncToPopUp({ records, listId });
        },
      ),
      catchErrorWithErrorType,
    ),
  );

  // cloud sync auth-pages pop up
  openCloudSyncAuthPopUp$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.openCloudSyncAuthPopUpAction),
          withLatestFrom(
            this._store.pipe(select(getSelectedPlatform)),
            this._store.pipe(select(getPlatformFields)),
            this._store.pipe(select(getSandbox)),
          ),
          tap(
            ([action, _selectedPlatform, fields, sandbox]: [
              Action,
              ICloudSyncPlatform,
              ICloudSyncAuthFields,
              boolean,
            ]) => {
              this._popUpService.open<
                CloudSyncAuthPopUpComponent,
                ICloudSyncAuthPopUpData
              >(CloudSyncAuthPopUpComponent, {
                data: {
                  ...getCloudSyncAuthPopUpData(_selectedPlatform.name, sandbox),
                  fields,
                },
                withOverlayBackground: true,
                closeOnOverlay: true,
              });
            },
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  showCloudSyncToPopUp$: Observable<unknown> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(actions.showCloudSyncToPopUp),
        withLatestFrom(
          this._store.pipe(select(getPlatformsAvailable)),
          this._store.pipe(select(getIsCanBuyForCredits)),
        ),
        tap(
          ([
            {
              payload: { listId },
            },
            platforms,
            isCanBuyForCredits,
          ]: [
            ActionWithPayload<ICloudSyncListInfo>,
            ICloudSyncPlatform[],
            boolean,
          ]) => {
            this._popUpService
              .open<CloudSyncToPopUpComponent, CloudSyncToPopUpData>(
                CloudSyncToPopUpComponent,
                {
                  showCloseBtn: false,
                  closeOnOverlay: false,
                  data: { platforms },
                },
              )
              .afterClose.pipe(take(1))
              .subscribe(({ onCancel, platform }: ICloudSyncToResult) => {
                if (onCancel) {
                  return;
                }

                if (platform && !isCanBuyForCredits) {
                  this._store.dispatch(
                    go([CORE_PATHS.ECOMM, E_COMMERCE_PATHS.PRICING], {
                      listId,
                      isCloud: true,
                    }),
                  );
                  return;
                }

                if (platform && platform.id && isCanBuyForCredits) {
                  this._store.dispatch(
                    platformAssignToAccountAction({
                      platformId: platform.id,
                      type: ASSIGN_PLATFORM_TYPE.CREDITS,
                    }),
                  );
                }
              });
          },
        ),
        catchErrorWithErrorType,
      ),
    { dispatch: false },
  );

  openCloudSyncAssignProductPopUp$: Observable<unknown> = createEffect(
    () =>
      this._actions$.pipe(
        ofType(actions.openCloudSyncOwnerAssignProductPopUp),
        withLatestFrom(this._store.pipe(select(getPlatformsAvailable))),
        tap(([payload, platforms]: [any, ICloudSyncPlatforms]) => {
          // TODO: add interfaces when will be api
          this._popUpService
            .open<
              CloudSyncAssignProductPopUpComponent,
              ICloudSyncAssignProductPopUpData
            >(CloudSyncAssignProductPopUpComponent, {
              showCloseBtn: false,
              closeOnOverlay: false,
              data: { platforms },
            })
            .afterClose.pipe(take(1))
            .subscribe((platform: ICloudSyncPlatform) => {
              if (platform && platform.id) {
                this._store.dispatch(
                  platformAssignToAccountAction({
                    platformId: platform.id,
                    type: ASSIGN_PLATFORM_TYPE.PRODUCT,
                  }),
                );
              }
            });
        }),
        catchErrorWithErrorType,
      ),
    { dispatch: false },
  );

  closePopUps$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.platformAuthSuccessAction),
          tap(() => this._popUpService.close()),
        ),
      ),
    { dispatch: false },
  );

  syncLimitedError$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.syncLimitedErrorAction),
      filter(
        ({ payload }: ActionWithPayload<string>) => payload && !!payload.length,
      ),
      map(() => go(`/${CORE_PATHS.DASHBOARD}`)),
      catchErrorWithErrorType,
    ),
  );
}
