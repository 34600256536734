import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewData } from "@shared/interfaces/view";
import { IQuickSearchGeoSuggestions, IQuickSearchPids } from "../../interfaces";

enum ACTION_TYPES {
  SEARCH_BY_KEYWORDS = "[QUICK SEARCH]: Search by keywords",
  SEARCH_BY_KEYWORDS_ERROR = "[QUICK SEARCH]: Search by keywords error",
  SEARCH_BY_KEYWORDS_SUCCESS = "[QUICK SEARCH]: Search by keywords success",
  SEARCH_BY_KEYWORDS_RESET = "[QUICK SEARCH]: Search by keywords reset",

  SEARCH_BY_PIDS = "[QUICK SEARCH]: Search by pids",
  SEARCH_BY_PIDS_ERROR = "[QUICK SEARCH]: Search by pids error",
  SEARCH_BY_PIDS_SUCCESS = "[QUICK SEARCH]: Search by pids success",
  SEARCH_BY_PIDS_RESET = "[QUICK SEARCH]: Search by pids reset",

  SEARCH_BY_GEO = "[QUICK SEARCH]: Search by geo",
  SEARCH_BY_GEO_ERROR = "[QUICK SEARCH]: Search by geo error",
  SEARCH_BY_GEO_SUCCESS = "[QUICK SEARCH]: Search by geo success",
  SEARCH_BY_GEO_RESET = "[QUICK SEARCH]: Search by geo reset",

  CREATE_VIEW_FROM_SEARCH = "[QUICK SEARCH] Create View from search",
  CREATE_VIEW_FROM_SEARCH_ERROR = "[QUICK SEARCH] Create View from search error",
  CREATE_VIEW_FROM_SEARCH_SUCCESS = "[QUICK SEARCH] Create View from search success",

  RESET = "[QUICK SEARCH]: Reset state",
}

export const searchByKeywordsAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_KEYWORDS,
  string
> = createAction(ACTION_TYPES.SEARCH_BY_KEYWORDS, createPayload<string>());
export const searchByKeywordsErrorAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_KEYWORDS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SEARCH_BY_KEYWORDS_ERROR,
  createPayload<IServerError>(),
);
export const searchByKeywordsSuccessAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_KEYWORDS_SUCCESS,
  string[]
> = createAction(
  ACTION_TYPES.SEARCH_BY_KEYWORDS_SUCCESS,
  createPayload<string[]>(),
);
export const searchByKeywordsResetAction: ActionCreator<
  ACTION_TYPES.SEARCH_BY_KEYWORDS_RESET,
  () => TypedAction<ACTION_TYPES.SEARCH_BY_KEYWORDS_RESET>
> = createAction(ACTION_TYPES.SEARCH_BY_KEYWORDS_RESET);

export const searchByPidsAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_PIDS,
  string
> = createAction(ACTION_TYPES.SEARCH_BY_PIDS, createPayload<string>());
export const searchByPidsErrorAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_PIDS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SEARCH_BY_PIDS_ERROR,
  createPayload<IServerError>(),
);
export const searchByPidsSuccessAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_PIDS_SUCCESS,
  IQuickSearchPids
> = createAction(
  ACTION_TYPES.SEARCH_BY_PIDS_SUCCESS,
  createPayload<IQuickSearchPids>(),
);
export const searchByPidsResetAction: ActionCreator<
  ACTION_TYPES.SEARCH_BY_PIDS_RESET,
  () => TypedAction<ACTION_TYPES.SEARCH_BY_PIDS_RESET>
> = createAction(ACTION_TYPES.SEARCH_BY_PIDS_RESET);

export const searchByGeoAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_GEO,
  string
> = createAction(ACTION_TYPES.SEARCH_BY_GEO, createPayload<string>());
export const searchByGeoErrorAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_GEO_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SEARCH_BY_GEO_ERROR,
  createPayload<IServerError>(),
);
export const searchByGeoSuccessAction: CreateActionType<
  ACTION_TYPES.SEARCH_BY_GEO_SUCCESS,
  IQuickSearchGeoSuggestions
> = createAction(
  ACTION_TYPES.SEARCH_BY_GEO_SUCCESS,
  createPayload<IQuickSearchGeoSuggestions>(),
);
export const searchByGeoResetAction: ActionCreator<
  ACTION_TYPES.SEARCH_BY_GEO_RESET,
  () => TypedAction<ACTION_TYPES.SEARCH_BY_GEO_RESET>
> = createAction(ACTION_TYPES.SEARCH_BY_GEO_RESET);

export const createViewFromSearchAction: ActionCreator<
  ACTION_TYPES.CREATE_VIEW_FROM_SEARCH,
  () => TypedAction<ACTION_TYPES.CREATE_VIEW_FROM_SEARCH>
> = createAction(ACTION_TYPES.CREATE_VIEW_FROM_SEARCH);
export const createViewFromSearchErrorAction: CreateActionType<
  ACTION_TYPES.CREATE_VIEW_FROM_SEARCH_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CREATE_VIEW_FROM_SEARCH_ERROR,
  createPayload<IServerError>(),
);
export const createViewFromSearchSuccessAction: CreateActionType<
  ACTION_TYPES.CREATE_VIEW_FROM_SEARCH_SUCCESS,
  IViewData
> = createAction(
  ACTION_TYPES.CREATE_VIEW_FROM_SEARCH_SUCCESS,
  createPayload<IViewData>(),
);

export const resetStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
