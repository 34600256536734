import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import {
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from "@angular/router";
import { select, Store } from "@ngrx/store";

import { environment } from "@env/environment";

import { fromEvent, Observable, Subject } from "rxjs";
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";

import { resetCommonErrorsAction } from "@core/store/actions/common-errors.action";
import * as purchaseActions from "@core/store/actions/list-purchase.actions";
import { go } from "@core/store/actions/router-history.action";
import { CoreState } from "@core/store/reducers";
import { getCommonError } from "@core/store/selectors/common-errors.selector";
import {
  getRefreshTokenAction,
  signOutAction,
} from "@modules/auth/store/actions/auth.action";
import { getIsLoggedIn } from "@modules/auth/store/selectors/auth.selector";
import * as listCloudSyncActions from "@modules/list/store/actions/list-cloud-sync.actions";
import { showNotificationAction } from "@modules/notifications/store/actions/notification.action";
import {
  changeProfilePermissionAction,
  updateProfileRoleAction,
} from "@modules/profile/store/actions/profile.action";
import * as actions from "@modules/segment/store/actions/pids-file.action";
import * as segmentActions from "@modules/segment/store/actions/segment.action";
import { viewChangesResetWithCache } from "@modules/view/store/actions/view.action";

import { ConfirmPopUpContentComponent } from "@ui/pop-up/components/confirm-pop-up-content/confirm-pop-up-content.component";

import { DocumentRef } from "@core/refs/document-ref.service";
import { WindowRef } from "@core/refs/window-ref.service";
import { BrowserDetectorService } from "@core/services/browser-detector.service";
import { LayoutService } from "@core/services/layout.service";
import { SessionStorageService } from "@core/services/session-storage.service";
import { StorageService } from "@core/services/storage.service";
import { AuthService } from "@modules/auth/services/auth.service";
import { PopUpService } from "@ui/pop-up/services/pop-up/pop-up.service";

import { appendExternalScriptToBody } from "@shared/utils/append-external-script-to-body";
import { autoscroll as scrollToTop } from "@shared/utils/autoscroll";
import { isIOSDevice } from "@shared/utils/deviceChecks";
import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IUpdatePermissionPayload } from "@modules/auth/interfaces/user";

import { ConfirmPopUpData } from "@ui/pop-up/models/pop-up-data";

import { CAPTCHA_URL } from "@core/constants/captcha";
import { CORE_PATHS } from "@core/constants/core-paths";
import { NOTIFICATION_TYPES } from "@core/constants/notifications";
import { PERMISSIONS_MSG } from "@core/constants/permissions";
import { SOCKET_EVENTS } from "@core/constants/socket-events";
import { CHECK__BROWSER_IE_POP_UP } from "@ui/pop-up/constants/pop-up-data";

import { WebSocketsProvider } from "@modules/websockets";
import { GainSightAnalyticsTagService } from "@modules/gain-sight-analytics/services/gain-sight-analytics-tag.service";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";
import { TrustArcConsentService } from "@modules/trust-arc-consent/services/trust-arc-consent.service";

type IScriptParamsTypes = string | boolean;

@Injectable()
export class AppService {
  isRootElementFixed: boolean;
  changeDetectionTrigger: Subject<void> = new Subject<void>();
  destroyer$: Subject<void> = new Subject<void>();
  isLoggedIn$: Observable<boolean> = this._store.pipe(
    select(getIsLoggedIn),
    shareReplay(),
  );
  commonError$: Observable<string> = this._store.pipe(select(getCommonError));
  private _renderer: Renderer2;
  private lastNavigationEvent: NavigationEnd;
  constructor(
    private _ws: WebSocketsProvider,
    private _store: Store<CoreState>,
    private _document: DocumentRef,
    private _window: WindowRef,
    private _rendererFactory: RendererFactory2,
    private _browserDetectorService: BrowserDetectorService,
    private _popUpService: PopUpService,
    private _layoutService: LayoutService,
    private _router: Router,
    private _gainSightService: GainSightAnalyticsTagService,
    private _trustArcConsentService: TrustArcConsentService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  appendExternalServices(): void {
    // TODO move to sign-in and sign-up page
    const reCaptchaScriptParams: Iterable<
      readonly [string, IScriptParamsTypes]
    > = [
      ["type", "text/javascript"],
      ["src", CAPTCHA_URL],
    ];
    appendExternalScriptToBody(this._document, reCaptchaScriptParams);
    this.appendTrustArcService();
  }

  appendTrustArcService(): void {
    this._trustArcConsentService.initTrustArcConsentTag();
  }

  checkUnsupportedBrowserAndNotify(): void {
    this.isLoggedIn$
      .pipe(
        takeUntil(this.destroyer$),
        filter((isLoggedIn: boolean) => {
          return (
            !StorageService.doNotShowUnsupportedBrowserPopUp &&
            isLoggedIn &&
            this._browserDetectorService.isBrowserIE
          );
        }),
        switchMap(() =>
          this._popUpService
            .open<ConfirmPopUpContentComponent, ConfirmPopUpData>(
              ConfirmPopUpContentComponent,
              {
                data: CHECK__BROWSER_IE_POP_UP,
              },
            )
            .afterClose.pipe(take(1)),
        ),
      )
      .subscribe(() => {
        StorageService.doNotShowUnsupportedBrowserPopUp = true;
      });
  }

  /* Method that checks if Root element is Fixed, then prevents ability to scroll on touch on IOS devices */
  setRootElementFixedOnIOS(): void {
    if (isIOSDevice()) {
      this._layoutService.isRootElementFixed
        .pipe(
          takeUntil(this.destroyer$),
          tap(() => scrollToTop()),
        )
        .subscribe((value: boolean) => (this.isRootElementFixed = value));

      fromEvent(this._window.nativeElement, "touchmove")
        .pipe(
          takeUntil(this.destroyer$),
          filter(() => this.isRootElementFixed),
          map((touchEvent: TouchEvent) => touchEvent.changedTouches[0].clientX),
        )
        .subscribe((value: number) => {
          this._renderer.setStyle(
            this._document.nativeElement.body,
            "top",
            `-${value}px`,
          );
        });
    }
  }

  updateWSConfig() {
    let storedToken =
      StorageService.token && StorageService.token.replace("Bearer ", "");
    let oktaToken = localStorage.getItem("okta-token-storage");
    let accessToken =
      !storedToken &&
      !!oktaToken &&
      JSON.parse(oktaToken)["accessToken"]["accessToken"];
    // temporary fix
    storedToken = !!storedToken ? storedToken + "*Internal*" : accessToken;
    // temporary fix
    return this._ws.updateConfigValues({
      token: storedToken,
      reconnectInterval: environment.webSockets.reconnectInterval,
    });
  }

  runWs(): void {
    // TODO Made WS action provider;

    this._store
      .pipe(
        select(getIsLoggedIn),
        takeUntil(this.destroyer$),
        filter((state: boolean) => {
          return state || !!this.oktaAuth.getAccessToken();
        }),
        tap(() => {
          return this.updateWSConfig();
        }),
        tap(() => {
          this._store.dispatch(actions.listenUploadFileSocketAction());
          this._store.dispatch(purchaseActions.listenExportListSocketAction());
          this._store.dispatch(
            segmentActions.listenDuplicateSegmentSocketAction(),
          );
          this._store.dispatch(
            listCloudSyncActions.listenRefreshCustomObjectAction(),
          );
        }),
        catchErrorWithErrorType,
      )
      .subscribe();

    this._ws.status
      .pipe(
        takeUntil(this.destroyer$),
        filter((state: boolean) => state),
        switchMap(() =>
          this._ws.on<IUpdatePermissionPayload>(
            SOCKET_EVENTS.PERMISSION_CHANGED,
          ),
        ),
        tap(
          ({
            permissions,
            role,
            accountAvailablePermissions,
          }: IUpdatePermissionPayload) => {
            this._store.dispatch(
              changeProfilePermissionAction({
                permissions,
                accountAvailablePermissions,
              }),
            );
            this._store.dispatch(updateProfileRoleAction(role));
            this._store.dispatch(viewChangesResetWithCache());

            this._store.dispatch(go(["/", CORE_PATHS.DASHBOARD]));

            this._store.dispatch(
              showNotificationAction({
                message: PERMISSIONS_MSG.CHANGED_PERMISSIONS,
                type: NOTIFICATION_TYPES.SUCCESS,
                timeout: 3000,
                canClose: true,
              }),
            );

            SessionStorageService.cdlLastCompletedPages = [];
          },
        ),
        catchErrorWithErrorType,
      )
      .subscribe();
  }

  subscribeToRouterEvents(): void {
    this._router.events
      .pipe(
        filter((event: any) => event instanceof NavigationEnd),
        tap(() => this.changeDetectionTrigger.next()),
        filter((event: NavigationEnd) => this._isUrlChanged(event)),
      )
      .subscribe((event: NavigationEnd) => {
        this.lastNavigationEvent = event;
        this._store.dispatch(resetCommonErrorsAction());
        scrollToTop(0, "auto");
        this._layoutService.enableGlobalScroll();
      });
  }

  observableOfNavigation(): Observable<RouterEvent> {
    return this._router.events.pipe(
      filter((event: any) => event instanceof NavigationStart),
    );
  }

  detectNavigationEvents(): void {
    this.observableOfNavigation().subscribe((event: NavigationStart) => {
      const isAuth: boolean = event.url.includes(CORE_PATHS.AUTH);
      if (!isAuth && !StorageService.fromAdmin) {
        const currentDateTime: number = new Date().getTime();
        const refreshTokenCreatedDate: number = new Date(
          StorageService.refreshTokenCreatedDate,
        ).getTime();
        const timeDifference: number =
          currentDateTime - refreshTokenCreatedDate;
        if (!this._router.navigated) {
          this.detectBrowserRefresh(timeDifference);
        }
      }
    });
  }

  detectBrowserRefresh(timeDifference: number): void {
    // this.stopAllTimers();
    if (!(StorageService.isAdmin && StorageService.isAdminsAccount)) {
      this._gainSightService.callGainSight(StorageService.user, StorageService);
    }
  }

  subscribeToErrors(): void {
    this.commonError$
      .pipe(
        takeUntil(this.destroyer$),
        filter((error: string) => !!error),
      )
      .subscribe(() => scrollToTop());
  }

  /* Helper method that removes from URL all query params and match the difference between prev / next url to send it to Google Analytics */
  private _isUrlChanged(event: NavigationEnd): boolean {
    if (!this.lastNavigationEvent) {
      return true;
    }

    const noQueryUrl: string = this.removeQuery(event.url);
    const noQueryLastUrl: string = this.removeQuery(
      this.lastNavigationEvent.url,
    );

    return noQueryUrl !== noQueryLastUrl;
  }

  private removeQuery = (url: string): string => url.split("?")[0];
}
