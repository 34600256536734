interface IImageFormats {
  PDF: "pdf";
  XLSX: "xlsx";
  XLS: "xls";
  PNG: "png";
  JPG: "jpg";
  PPTX: "pptx";
  DOCX: "docx";
  DOC: "doc";
  TXT: "txt";
  ZIP: "zip";
}

export interface IDocumentImage {
  extension: string[];
  img: string;
}

export const IMG_FORMATS: IImageFormats = Object.freeze({
  PDF: "pdf",
  XLSX: "xlsx",
  XLS: "xls",
  PNG: "png",
  JPG: "jpg",
  PPTX: "pptx",
  DOCX: "docx",
  DOC: "doc",
  TXT: "txt",
  ZIP: "zip",
});

export enum IMG_FILE_NAMES {
  PDF = "document-adobe.png",
  XLSX = "document-exel.png",
  PNG = "document-img.png",
  PPTX = "document-post.png",
  DOCX = "document-word.png",
  TXT = "document-txt.svg",
  ZIP = "document-zip.svg",
  DEFAULT = "document-default.svg",
}

export const DOCUMENTS_IMG: IDocumentImage[] = [
  {
    extension: [IMG_FORMATS.PDF],
    img: IMG_FILE_NAMES.PDF,
  },
  {
    extension: [IMG_FORMATS.XLSX, IMG_FORMATS.XLS],
    img: IMG_FILE_NAMES.XLSX,
  },
  {
    extension: [IMG_FORMATS.PNG, IMG_FORMATS.JPG],
    img: IMG_FILE_NAMES.PNG,
  },
  {
    extension: [IMG_FORMATS.PPTX],
    img: IMG_FILE_NAMES.PPTX,
  },
  {
    extension: [IMG_FORMATS.DOCX, IMG_FORMATS.DOC],
    img: IMG_FILE_NAMES.DOCX,
  },
  {
    extension: [IMG_FORMATS.TXT],
    img: IMG_FILE_NAMES.TXT,
  },
  {
    extension: [IMG_FORMATS.ZIP],
    img: IMG_FILE_NAMES.ZIP,
  },
];

export const DOCUMENT_IMG_DEFAULT: IMG_FILE_NAMES = IMG_FILE_NAMES.DEFAULT;
export const DOWNLOAD_FILES_OPEN_IN_NEW_WINDOW: string[] = [IMG_FORMATS.PDF];
