export const VALIDATION_REG_EXP: { [key: string]: RegExp } = {
  // eslint-disable  max-len
  email:
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
  password: /^(?=.*[A-Z])(?=.*\d)[\x00-\x7F]{10,}$/,
  country: /^[A-Z]{2}?$/,
  // USA POSTAL CODE VALIDATOR ONLY
  postalCode: /^\d{5}$|^\d{5}-\d{4}$/,
  noOnlySpaces: /^(\s+\S+\s*)*(?!\s).*$/,
  onlyDigits: /[0-9]+/,
  onlyUpperCase: /[A-Z]+/,
  onlyLowerCase: /[a-z]+/,
  onlySymbols: /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]+/,
};
