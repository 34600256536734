import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IProductCounter } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_QUICK_SEARCH_OVERVIEW = "[QUICK SEARCH RESULTS]: Get overview",
  GET_QUICK_SEARCH_OVERVIEW_SUCCESS = "[QUICK SEARCH RESULTS]: Get overview success",
  GET_QUICK_SEARCH_OVERVIEW_ERROR = "[QUICK SEARCH RESULTS]: Get overview error",
}

export const getQuickSearchOverviewAction: ActionCreator<
  ACTION_TYPES.GET_QUICK_SEARCH_OVERVIEW,
  () => TypedAction<ACTION_TYPES.GET_QUICK_SEARCH_OVERVIEW>
> = createAction(ACTION_TYPES.GET_QUICK_SEARCH_OVERVIEW);
export const getQuickSearchOverviewSuccessAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_OVERVIEW_SUCCESS,
  IProductCounter
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_OVERVIEW_SUCCESS,
  createPayload<IProductCounter>(),
);
export const getQuickSearchOverviewErrorAction: CreateActionType<
  ACTION_TYPES.GET_QUICK_SEARCH_OVERVIEW_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_QUICK_SEARCH_OVERVIEW_ERROR,
  createPayload<IServerError>(),
);
