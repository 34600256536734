import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-institution-districts.action";

import { IInstitutionsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { INSTITUTIONS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IViewResultsInstitutionDistrictsState {
  districts: IInstitutionsRecord[];
  pending: boolean;
  error: IServerError | null;

  selectedDistrictIds: number[];

  count: number;
}

const initialState: IViewResultsInstitutionDistrictsState = {
  districts: [],
  pending: false,
  error: null,

  selectedDistrictIds: [],

  count: 0,
};

const getViewResultsInstitutionDistricts: OnReducer<
  IViewResultsInstitutionDistrictsState,
  ActionType<any>
> = (state: IViewResultsInstitutionDistrictsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsInstitutionDistrictsError: OnReducer<
  IViewResultsInstitutionDistrictsState,
  ActionType<Payload<IServerError>>
> = (
  state: IViewResultsInstitutionDistrictsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsInstitutionDistrictsSuccess: OnReducer<
  IViewResultsInstitutionDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionDistrictsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  districts: [...payload.items],
  count: payload.count,
});

const toggleViewResultsInstitutionDistricts: OnReducer<
  IViewResultsInstitutionDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionDistrictsState,
  { payload: { pid } }: Payload<any>,
) => {
  const _setArray: Set<number> = new Set(state.selectedDistrictIds);

  _setArray.has(pid) ? _setArray.delete(pid) : _setArray.add(pid);

  return {
    ...state,
    selectedDistrictIds: Array.from(_setArray),
  };
};

const setViewResultsInstitutionDistrictsSpinnerState: OnReducer<
  IViewResultsInstitutionDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IViewResultsInstitutionDistrictsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetSelectedInstitutionDistricts: OnReducer<
  IViewResultsInstitutionDistrictsState,
  ActionType<any>
> = (state: IViewResultsInstitutionDistrictsState) => ({
  ...state,
  selectedDistrictIds: [],
});

const resetViewResultsInstitutionDistrictsState: OnReducer<
  IViewResultsInstitutionDistrictsState,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsInstitutionDistrictsState> =
  createReducer<IViewResultsInstitutionDistrictsState>(
    initialState,

    on(
      actions.getViewResultsInstitutionDistrictsAction,
      getViewResultsInstitutionDistricts,
    ),
    on(
      actions.getViewResultsInstitutionDistrictsErrorAction,
      getViewResultsInstitutionDistrictsError,
    ),
    on(
      actions.getViewResultsInstitutionDistrictsSuccessAction,
      getViewResultsInstitutionDistrictsSuccess,
    ),

    on(
      actions.toggleViewResultsInstitutionDistrictsAction,
      toggleViewResultsInstitutionDistricts,
    ),

    on(
      actions.setViewResultsInstitutionDistrictsSpinnerStateAction,
      setViewResultsInstitutionDistrictsSpinnerState,
    ),

    on(
      actions.resetSelectedInstitutionDistrictsAction,
      resetSelectedInstitutionDistricts,
    ),

    on(
      actions.resetViewResultsInstitutionDistrictsStateAction,
      resetViewResultsInstitutionDistrictsState,
    ),
  );

export function viewResultsInstitutionDistrictsReducer(
  state: IViewResultsInstitutionDistrictsState,
  action: Action,
): IViewResultsInstitutionDistrictsState {
  return reducer(state, action);
}

export const districts: GetFromState<
  IInstitutionsRecord[],
  IViewResultsInstitutionDistrictsState
> = (state: IViewResultsInstitutionDistrictsState): IInstitutionsRecord[] =>
  state.districts;
export const pending: GetFromState<
  boolean,
  IViewResultsInstitutionDistrictsState
> = (state: IViewResultsInstitutionDistrictsState): boolean => state.pending;

export const districtsPaginationCount: GetFromState<
  number,
  IViewResultsInstitutionDistrictsState
> = (state: IViewResultsInstitutionDistrictsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const params: Params = {
    ...INSTITUTIONS_DEFAULT_PARAMS,
    ...queryParams,
    viewId,
    filterBy: "districts",
  };

  return segmentId ? { ...params, segmentId } : params;
};

export const selectedInstitutionDistrictIds: GetFromState<
  number[],
  IViewResultsInstitutionDistrictsState
> = (state: IViewResultsInstitutionDistrictsState): number[] =>
  state.selectedDistrictIds;
