import { Pipe, PipeTransform } from "@angular/core";

import { capitalizeAll } from "@shared/utils/format-text";

@Pipe({
  name: "capitalizeAll",
})
export class CapitalizeAllPipe implements PipeTransform {
  transform(value: string, args?: any): any {
    return capitalizeAll(value);
  }
}
