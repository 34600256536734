import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IResultsCounter } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[QUICK SEARCH COUNTERS] Get Personnel",
  GET_ERROR = "[QUICK SEARCH COUNTERS] Get Personnel Error",
  GET_SUCCESS = "[QUICK SEARCH COUNTERS] Get Personnel Success",

  RESET = "[QUICK SEARCH COUNTERS] Reset Personnel",
}

export const getQuickSearchPersonnelCountersAction: ActionCreator<
  ACTION_TYPES.GET,
  () => TypedAction<ACTION_TYPES.GET>
> = createAction(ACTION_TYPES.GET);
export const getQuickSearchPersonnelCountersErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getQuickSearchPersonnelCountersSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IResultsCounter
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IResultsCounter>());

export const resetQuickSearchPersonnelCountersAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
