import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "pagination",
})
export class PaginationPipe implements PipeTransform {
  transform(pages: any[], currentPage: number, itemsPerPage: number): any {
    if (!pages || !pages.length) {
      return [];
    }

    const startPage: number =
      currentPage - itemsPerPage <= 1 ? 0 : currentPage - itemsPerPage;
    const endPage: number =
      currentPage + itemsPerPage >= pages.length
        ? pages.length
        : currentPage + itemsPerPage;

    if (typeof startPage === "number" && typeof endPage === "number") {
      return pages.slice(startPage, endPage);
    }
  }
}
