import { defaultEnvironment, IEnvironment } from "@env/default-environment";

export const environment: IEnvironment = {
  ...defaultEnvironment,
  production: false,
  envName: "development",
  adminAppUrl: "https://cms.build-a-list-test.com/main/customers/list",
  frameRegSuccessUrl: "https://build-a-list-test.com/dashboard",
  enableCaptcha: false,
  webSockets: {
    url: "wss://ocba87f8s0.execute-api.us-east-1.amazonaws.com/dev",
    reconnectInterval: 2500,
    reconnectAttempts: 100,
  },
  paymentKeys: {
    merchant_id: "mdr_phx",
    locale: "en",
    access_key: "7e4f5694a44d3dfb8fe882b3e6909d95",
    profile_id: "84B773B6-706B-4DDB-82AC-2A386B9118F4",
    override_custom_receipt_page:
      "https://build-a-list-test.com/cybersource-redirect-url",
    orderPage_declineResponseURL:
      "https://build-a-list-test.com/cybersource-redirect-url",
  },
  oktaConfig: {
    clientId: "0oaeaakwzjB4wszXn1d7",
    token_endpoint:
      "https://qa.sso.dnb.com/oauth2/ausg86rerPKZ500yO1d6/v1/token",
    token_endpoint_id: "ausg86rerPKZ500yO1d6",
    domain: "qa.sso.dnb.com",
    scopes: ["openid"],
    pkce: true,
  },
};
