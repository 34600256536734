import {
  DATE_TYPE,
  ITargetingCriteriaDataItemDatesDataItem,
} from "../../targeting-criteria-controls/data";

export function getMinDate(
  currentDateControl: ITargetingCriteriaDataItemDatesDataItem,
  requiredControl?: ITargetingCriteriaDataItemDatesDataItem,
): number {
  if (currentDateControl.title === DATE_TYPE.FROM || !requiredControl) {
    return currentDateControl.min;
  }

  if (currentDateControl.title === DATE_TYPE.TO) {
    return requiredControl.current >= currentDateControl.min
      ? requiredControl.current
      : currentDateControl.min;
  }
}

export function getMaxDate(
  currentDateControl: ITargetingCriteriaDataItemDatesDataItem,
  requiredControl?: ITargetingCriteriaDataItemDatesDataItem,
): number {
  if (currentDateControl.title === DATE_TYPE.TO) {
    return currentDateControl.max;
  }

  if (!requiredControl) {
    return currentDateControl.max;
  }

  if (currentDateControl.title === DATE_TYPE.FROM) {
    return currentDateControl.max >= requiredControl.current
      ? requiredControl.current
      : currentDateControl.max;
  }
}
