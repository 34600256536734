// store
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/oms.action";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IOmsField,
  IOmsFields,
  IOmsOAuthByRedirectionSocketResponse,
  IOmsPlatform,
  IOmsPlatforms,
} from "../../interfaces";

// interfaces

export interface IOmsState {
  platforms: IOmsPlatforms;
  platformsLoading: boolean;
  platformsError: IServerError | null;

  sandbox: boolean;
  selectedPlatformId: number;

  relatedToFieldValue: string;

  fields: IOmsFields;
  availableFields: IOmsFields;
  fieldsLoading: boolean;
  fieldsError: IServerError | null;

  platformAuthLoading: boolean;
  platformAuthError: IServerError | null;

  authBySocketLoading: boolean;
  authBySocketError: IServerError | null;

  authByRedirectSocketLoading: boolean;
  authByRedirectSocketResult: IOmsOAuthByRedirectionSocketResponse;
}

const initialState: IOmsState = {
  platforms: [],
  platformsLoading: false,
  platformsError: null,

  sandbox: true,
  selectedPlatformId: null,

  relatedToFieldValue: null,

  fields: [],
  availableFields: [],
  fieldsLoading: false,
  fieldsError: null,

  platformAuthLoading: false,
  platformAuthError: null,

  authBySocketLoading: false,
  authBySocketError: null,

  authByRedirectSocketLoading: false,
  authByRedirectSocketResult: null,
};

// get platforms
const getPlatformsHandler: OnReducer<IOmsState, ActionType<any>> = (
  state: IOmsState,
) => ({
  ...state,
  platformsLoading: true,
  platformsError: null,
});

const getPlatformsErrorHandler: OnReducer<
  IOmsState,
  ActionType<Payload<any>>
> = (state: IOmsState, { payload }: Payload<any>) => ({
  ...state,
  platformsLoading: false,
  platformsError: { ...payload },
});

const getPlatformsSuccessHandler: OnReducer<
  IOmsState,
  ActionType<Payload<any>>
> = (state: IOmsState, { payload }: Payload<any>) => ({
  ...state,
  platformsLoading: false,
  platformsError: null,
  platforms: [...payload],
});

// platform fields
const getFieldsHandler: OnReducer<IOmsState, ActionType<Payload<any>>> = (
  state: IOmsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  fieldsLoading: true,
  fieldsError: null,
  selectedPlatformId: payload,
});

const getFieldsErrorHandler: OnReducer<IOmsState, ActionType<Payload<any>>> = (
  state: IOmsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  fieldsLoading: false,
  fieldsError: { ...payload },
});

const getFieldsSuccessHandler: OnReducer<
  IOmsState,
  ActionType<Payload<any>>
> = (state: IOmsState, { payload }: Payload<any>) => ({
  ...state,
  fieldsLoading: false,
  fieldsError: null,
  fields: [...payload],
  availableFields: [
    ...payload.filter((item: IOmsField) => item && !item.relatedTo),
  ],
});

// change auth-pages fields type
const changeRelatedToFieldsHandler: OnReducer<
  IOmsState,
  ActionType<Payload<any>>
> = (state: IOmsState, { payload }: Payload<any>) => ({
  ...state,
  relatedToFieldValue: payload,
  availableFields: [
    ...state.fields.filter((item: IOmsField) => {
      return (
        (item && !item.relatedTo) ||
        (item && item.relatedTo && item.relatedTo.value === payload)
      );
    }),
  ],
});

// platform auth-pages fields
const platformAuthHandler: OnReducer<IOmsState, ActionType<Payload<any>>> = (
  state: IOmsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  platformAuthLoading: true,
  platformAuthError: null,
  sandbox: payload.sandbox,
});

const platformAuthErrorHandler: OnReducer<
  IOmsState,
  ActionType<Payload<any>>
> = (state: IOmsState, { payload }: Payload<any>) => ({
  ...state,
  platformAuthLoading: false,
  platformAuthError: { ...payload },
});

const platformAuthSuccessHandler: OnReducer<IOmsState, ActionType<any>> = (
  state: IOmsState,
) => ({
  ...state,
  platformAuthLoading: false,
  platformAuthError: null,
});

// listen auth-pages response by socket
const listenAuthBySocketActionHandler: OnReducer<IOmsState, ActionType<any>> = (
  state: IOmsState,
) => ({
  ...state,
  authBySocketLoading: true,
  authBySocketError: null,
});

const listenAuthBySocketErrorActionHandler: OnReducer<
  IOmsState,
  ActionType<Payload<any>>
> = (state: IOmsState, { payload }: Payload<any>) => ({
  ...state,
  authBySocketLoading: false,
  authBySocketError: { ...payload },
});

const listenAuthBySocketSuccessActionHandler: OnReducer<
  IOmsState,
  ActionType<any>
> = (state: IOmsState) => ({
  ...state,
  authBySocketLoading: false,
  authBySocketError: null,
});

// listen auth-pages redirect response by socket
const listenOAuthByRedirectionSocket: OnReducer<IOmsState, ActionType<any>> = (
  state: IOmsState,
) => ({
  ...state,
  authByRedirectSocketLoading: true,
  authByRedirectSocketResult: null,
});

const listenOAuthByRedirectionSocketFired: OnReducer<
  IOmsState,
  ActionType<Payload<any>>
> = (state: IOmsState, { payload }: Payload<any>) => ({
  ...state,
  authByRedirectSocketLoading: false,
  authByRedirectSocketResult: payload,
});

// reset data of auth-pages pop up
const resetOmsAuthPopUpActionHandler: OnReducer<IOmsState, ActionType<any>> = (
  state: IOmsState,
) => ({
  ...state,
  selectedPlatformId: null,
  platformAuthLoading: false,
  platformAuthError: null,
  authBySocketError: null,
  sandbox: true,
});

// reset state
const resetStateHandler: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IOmsState> = createReducer<IOmsState>(
  initialState,

  on(actions.getPlatformsAction, getPlatformsHandler),
  on(actions.getPlatformsErrorAction, getPlatformsErrorHandler),
  on(actions.getPlatformsSuccessAction, getPlatformsSuccessHandler),

  on(actions.getFieldsAction, getFieldsHandler),
  on(actions.getFieldsErrorAction, getFieldsErrorHandler),
  on(actions.getFieldsSuccessAction, getFieldsSuccessHandler),

  on(actions.changeRelatedToFieldsAction, changeRelatedToFieldsHandler),

  on(actions.platformAuthAction, platformAuthHandler),
  on(actions.platformAuthErrorAction, platformAuthErrorHandler),
  on(actions.platformAuthSuccessAction, platformAuthSuccessHandler),

  on(actions.closeOmsAuthPopUpAction, resetOmsAuthPopUpActionHandler),

  on(actions.listenAuthBySocketAction, listenAuthBySocketActionHandler),
  on(
    actions.listenAuthBySocketErrorAction,
    listenAuthBySocketErrorActionHandler,
  ),
  on(
    actions.listenAuthBySocketSuccessAction,
    listenAuthBySocketSuccessActionHandler,
  ),

  on(
    actions.listenOAuthByRedirectionSocketAction,
    listenOAuthByRedirectionSocket,
  ),
  on(
    actions.listenOAuthByRedirectionSocketFiredAction,
    listenOAuthByRedirectionSocketFired,
  ),

  on(actions.resetOmsStateAction, resetStateHandler),
);

export function omsReducer(state: IOmsState, action: Action): IOmsState {
  return reducer(state, action);
}

export const platforms: GetFromState<IOmsPlatforms, IOmsState> = (
  state: IOmsState,
): IOmsPlatforms => state.platforms;
export const selectedPlatformId: GetFromState<number, IOmsState> = (
  state: IOmsState,
): number => state.selectedPlatformId;
export const selectedPlatform: GetFromState<
  IOmsPlatform,
  IOmsPlatforms,
  number
> = (_platforms: IOmsPlatforms, _selectedPlatformId: number): IOmsPlatform => {
  return (
    _platforms &&
    _platforms.find((item: IOmsPlatform) => item.id === _selectedPlatformId)
  );
};

export const availablePlatformFields: GetFromState<IOmsFields, IOmsState> = (
  state: IOmsState,
): IOmsFields => state && state.availableFields;
export const sandbox: GetFromState<boolean, IOmsState> = (
  state: IOmsState,
): boolean => state.sandbox;
export const platformAuthLoading: GetFromState<boolean, IOmsState> = (
  state: IOmsState,
): boolean => state.platformAuthLoading;
export const platformAuthError: GetFromState<IServerError | null, IOmsState> = (
  state: IOmsState,
): IServerError | null => state.platformAuthError;

export const authBySocketLoading: GetFromState<boolean, IOmsState> = (
  state: IOmsState,
): boolean => state.authBySocketLoading;

export const oAuthByRedirectionData: GetFromState<
  IOmsOAuthByRedirectionSocketResponse,
  IOmsState
> = (state: IOmsState): IOmsOAuthByRedirectionSocketResponse =>
  state.authByRedirectSocketResult;
