import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/pid-custom-lists.action";

import { IListData } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import { IPidCustomLists } from "../../interfaces/pid-custom-lists";
import {
  IPIDAvailableCustomLists,
  IPIDCustomLists,
} from "../../interfaces/targeting-forms";

import { PidCustomList } from "../../models/pid-custom-list";

export interface IPidCustomListsState {
  customLists: IPidCustomLists;
  error: IServerError | null;
  loading: boolean;
}

const initialState: IPidCustomListsState = {
  customLists: [],
  error: null,
  loading: false,
};

// get all lists
const getCustomLists: OnReducer<IPidCustomListsState, ActionType<any>> = (
  state: IPidCustomListsState,
) => ({
  ...state,
  error: null,
  loading: true,
});

const getCustomListsError: OnReducer<
  IPidCustomListsState,
  ActionType<Payload<IServerError>>
> = (state: IPidCustomListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  loading: false,
});

const getCustomListsSuccess: OnReducer<
  IPidCustomListsState,
  ActionType<Payload<any>>
> = (state: IPidCustomListsState, { payload }: Payload<any>) => ({
  ...state,
  error: null,
  loading: false,
  customLists: [...payload],
});

// attach list
const attachCustomList: OnReducer<IPidCustomListsState, ActionType<any>> = (
  state: IPidCustomListsState,
) => ({
  ...state,
  error: null,
  loading: true,
});

const attachCustomListError: OnReducer<
  IPidCustomListsState,
  ActionType<Payload<IServerError>>
> = (state: IPidCustomListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  loading: false,
});

const attachCustomListSuccess: OnReducer<
  IPidCustomListsState,
  ActionType<any>
> = (state: IPidCustomListsState) => ({
  ...state,
  error: null,
  loading: false,
});

// detach list
const detachCustomList: OnReducer<IPidCustomListsState, ActionType<any>> = (
  state: IPidCustomListsState,
) => ({
  ...state,
  error: null,
  loading: true,
});

const detachCustomListError: OnReducer<
  IPidCustomListsState,
  ActionType<Payload<IServerError>>
> = (state: IPidCustomListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  loading: false,
});

const detachCustomListSuccess: OnReducer<
  IPidCustomListsState,
  ActionType<any>
> = (state: IPidCustomListsState) => ({
  ...state,
  error: null,
  loading: false,
});

// update list
const updateCustomList: OnReducer<IPidCustomListsState, ActionType<any>> = (
  state: IPidCustomListsState,
) => ({
  ...state,
  error: null,
  loading: true,
});

const updateCustomListError: OnReducer<
  IPidCustomListsState,
  ActionType<Payload<IServerError>>
> = (state: IPidCustomListsState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  loading: false,
});

const updateCustomListSuccess: OnReducer<
  IPidCustomListsState,
  ActionType<any>
> = (state: IPidCustomListsState) => ({
  ...state,
  error: null,
  loading: false,
});

const resetPidCustomListsState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IPidCustomListsState> =
  createReducer<IPidCustomListsState>(
    initialState,

    on(actions.getCustomListsAction, getCustomLists),
    on(actions.getCustomListsErrorAction, getCustomListsError),
    on(actions.getCustomListsSuccessAction, getCustomListsSuccess),

    on(actions.attachCustomListAction, attachCustomList),
    on(actions.attachCustomListErrorAction, attachCustomListError),
    on(actions.attachCustomListSuccessAction, attachCustomListSuccess),

    on(actions.detachCustomListAction, detachCustomList),
    on(actions.detachCustomListErrorAction, detachCustomListError),
    on(actions.detachCustomListSuccessAction, detachCustomListSuccess),

    on(actions.updateCustomListAction, updateCustomList),
    on(actions.updateCustomListErrorAction, updateCustomListError),
    on(actions.updateCustomListSuccessAction, updateCustomListSuccess),

    on(actions.resetPidCustomListsStateAction, resetPidCustomListsState),
  );

export function pidCustomListsReducer(
  state: IPidCustomListsState,
  action: Action,
): IPidCustomListsState {
  return reducer(state, action);
}

export const pidAllCustomLists: GetFromState<
  IPidCustomLists,
  IPidCustomListsState
> = (state: IPidCustomListsState): IPidCustomLists =>
  state && state.customLists;

export const availableCustomList: GetFromState<
  IPIDAvailableCustomLists,
  IPidCustomLists,
  number,
  IPIDCustomLists
> = (
  customLists: IPidCustomLists,
  listId: number,
  pidCustomLists: IPIDCustomLists,
): IPIDAvailableCustomLists => {
  return (
    (customLists &&
      customLists.map(
        (item: IListData) => new PidCustomList(item, listId, pidCustomLists),
      )) ||
    []
  );
};
