import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

enum ACTION_TYPES {
  SET_SPINNER = "[SPINNER]: set spinner",
}

export const setSpinnerAction: CreateActionType<
  ACTION_TYPES.SET_SPINNER,
  boolean
> = createAction(ACTION_TYPES.SET_SPINNER, createPayload<boolean>());
