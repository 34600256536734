import { AnimationTriggerMetadata } from "@angular/animations";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from "@angular/core";

import { fromEvent, Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";

import { WindowRef } from "@core/refs/window-ref.service";

import { IOverlayTargetParams } from "../../interfaces";

import { OVERLAY_TARGET, OVERLAY_TARGET_PARAMS } from "../../models";

import { fadeInOut } from "@ui/animations/fadeInOut";

@Component({
  selector: "bl-overlay-target",
  templateUrl: "./overlay-target.component.html",
  styleUrls: ["./overlay-target.component.scss"],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverlayTargetComponent implements OnInit, OnDestroy {
  private destroyer$: Subject<void> = new Subject();
  public isOpen: boolean;

  @HostBinding("@fadeInOut") animation: AnimationTriggerMetadata;

  @HostBinding("style.backgroundColor") get color(): string {
    return this.params.targetBg;
  }

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _windowRef: WindowRef,
    @Inject(OVERLAY_TARGET) public target: ElementRef<any>,
    @Inject(OVERLAY_TARGET_PARAMS) public params: IOverlayTargetParams,
  ) {}

  ngOnInit(): void {
    this.position();

    fromEvent(this._windowRef.nativeElement, "resize")
      .pipe(
        takeUntil(this.destroyer$),
        debounceTime(2000),
        filter(() => this.isOpen),
      )
      .subscribe(() => this.position());
  }

  ngOnDestroy(): void {
    this.setTargetIndex(null);
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  setTargetIndex(index: number | string): void {
    if (index) {
      this._renderer.setStyle(this.target.nativeElement, "z-index", index);
    } else {
      this._renderer.removeStyle(this.target.nativeElement, "z-index");
    }
  }

  position(): void {
    const { top, bottom, right, left }: ClientRect =
      this.target.nativeElement.getBoundingClientRect();

    this._renderer.setStyle(
      this._elementRef.nativeElement,
      "top",
      top - this.params.padding[0] + "px",
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      "left",
      (this.params.padding[3] ? left - this.params.padding[3] : left) + "px",
    );

    this._renderer.setStyle(
      this._elementRef.nativeElement,
      "width",
      right - left + "px",
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      "height",
      bottom - top + "px",
    );
    this._renderer.setStyle(
      this._elementRef.nativeElement,
      "padding",
      this.params.padding.join("px ") + "px",
    );
  }
}
