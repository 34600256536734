import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-all-institution.action";

import { IInstitutionsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { INSTITUTIONS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsAllInstitutionState {
  institution: IInstitutionsRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IQuickSearchResultsAllInstitutionState = {
  institution: [],
  pending: false,
  error: null,

  count: 0,
};

const getQuickSearchInstitution: OnReducer<
  IQuickSearchResultsAllInstitutionState,
  ActionType<any>
> = (state: IQuickSearchResultsAllInstitutionState) => ({
  ...state,
  pending: true,
  error: null,
});

const getQuickSearchAllInstitutionError: OnReducer<
  IQuickSearchResultsAllInstitutionState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsAllInstitutionState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getQuickSearchAllInstitutionSuccess: OnReducer<
  IQuickSearchResultsAllInstitutionState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsAllInstitutionState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  institution: [...payload.items],
  count: payload.count,
});

const resetQuickSearchAllInstitutionState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsAllInstitutionState> =
  createReducer<IQuickSearchResultsAllInstitutionState>(
    initialState,

    on(actions.getQuickSearchAllInstitutionAction, getQuickSearchInstitution),
    on(
      actions.getQuickSearchAllInstitutionErrorAction,
      getQuickSearchInstitution,
    ),
    on(
      actions.changeQuickSearchAllInstitutionPageAction,
      getQuickSearchInstitution,
    ),

    on(
      actions.getQuickSearchAllInstitutionErrorAction,
      getQuickSearchAllInstitutionError,
    ),
    on(
      actions.getQuickSearchAllInstitutionSuccessAction,
      getQuickSearchAllInstitutionSuccess,
    ),

    on(
      actions.resetQuickSearchAllInstitutionStateAction,
      resetQuickSearchAllInstitutionState,
    ),
  );

export function quickSearchResultsAllInstitutionReducer(
  state: IQuickSearchResultsAllInstitutionState,
  action: Action,
): IQuickSearchResultsAllInstitutionState {
  return reducer(state, action);
}

export const allInstitution: GetFromState<
  IInstitutionsRecord[],
  IQuickSearchResultsAllInstitutionState
> = (state: IQuickSearchResultsAllInstitutionState): IInstitutionsRecord[] =>
  state.institution;
export const allInstitutionPending: GetFromState<
  boolean,
  IQuickSearchResultsAllInstitutionState
> = (state: IQuickSearchResultsAllInstitutionState): boolean => state.pending;
export const allInstitutionError: GetFromState<
  IServerError,
  IQuickSearchResultsAllInstitutionState
> = (state: IQuickSearchResultsAllInstitutionState): IServerError =>
  state.error;
export const allInstitutionCount: GetFromState<
  number,
  IQuickSearchResultsAllInstitutionState
> = (state: IQuickSearchResultsAllInstitutionState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  return {
    ...INSTITUTIONS_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    filterBy: "all",
  };
};
