import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  listCloudSyncHistoryReducer,
  IListCloudSyncHistoryState,
} from "./list-cloud-sync-history.reducer";
import {
  listCloudSyncReducer,
  IListCloudSyncState,
} from "./list-cloud-sync.reducer";
import {
  customerListSegmentsReducer,
  CustomerListSegmentsState,
} from "./list-segments.reducer";
import {
  customerListSettingsReducer,
  IListSettingState,
} from "./list-setting.reducer";
import {
  customerListSummaryReducer,
  IListSummaryState,
} from "./list-summary.reducer";

export interface IListModuleState {
  customerListSegments: CustomerListSegmentsState;
  customerListSummary: IListSummaryState;
  customerListSetting: IListSettingState;
  customerListCloudSync: IListCloudSyncState;
  customerListCloudSyncHistory: IListCloudSyncHistoryState;
}

const listModuleReducer: ActionReducerMap<IListModuleState> = {
  customerListSegments: customerListSegmentsReducer,
  customerListSummary: customerListSummaryReducer,
  customerListSetting: customerListSettingsReducer,
  customerListCloudSync: listCloudSyncReducer,
  customerListCloudSyncHistory: listCloudSyncHistoryReducer,
};

// eslint-disable-next-line
export const LIST_STATE_NAME = 'listModule';
export const LIST_STATE: InjectionToken<ActionReducerMap<IListModuleState>> =
  new InjectionToken<ActionReducerMap<IListModuleState>>(LIST_STATE_NAME, {
    factory: () => listModuleReducer,
  });
