export function queryParamsToObject(query: string): any {
  if (query === "" || typeof query !== "string") {
    return {};
  }

  const queryParams: string[] = query.slice(query.indexOf("?") + 1).split("&");

  return queryParams.reduce((params: any, value: string) => {
    const [key, val]: string[] = value.split("=");
    return Object.assign(params, { [key]: val || null });
  }, {});
}
