import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";
import { errorHandler, pluckAndCatch } from "@shared/utils/response-formater";

import {
  IAccountUser,
  IAccountUserHistories,
  IAccountUserHistory,
  ICreateAccountUserForRequest,
  IEditUserFormForRequest,
} from "../interfaces/marketview";

@Injectable()
export class AccountUsersService {
  constructor(private http: HttpClient) {}

  getUsers(): Observable<IAccountUser[]> {
    return this.http
      .get(environment.api.marketView.account.users)
      .pipe(pluckAndCatch);
  }

  addUser(newUser: ICreateAccountUserForRequest): Observable<any> {
    return this.http
      .patch(environment.api.marketView.account.addUser, newUser)
      .pipe(errorHandler);
  }

  editUser(user: IEditUserFormForRequest): Observable<any> {
    return this.http
      .patch(environment.api.marketView.account.editUser + `/${user.id}`, user)
      .pipe(errorHandler);
  }

  getUserHistory(): Observable<IAccountUserHistories> {
    return this.http.get(environment.api.marketView.account.userHistory).pipe(
      pluckAndCatch,
      map((histories: IAccountUserHistories) => {
        const modifiedHIstory: IAccountUserHistories = histories.map(
          (history: IAccountUserHistory) => {
            history.date = new Date(history.date);
            return history;
          },
        );
        return modifiedHIstory;
      }),
      catchErrorWithErrorType,
    );
  }

  removeUser(userId: number): Observable<void> {
    return this.http
      .delete(`${environment.api.marketView.account.users}/${userId}`)
      .pipe(errorHandler);
  }

  resendInvitation(id: number): Observable<void> {
    return this.http
      .post(
        `${environment.api.marketView.account.users}/${id}/${environment.api.marketView.account.resendInvitation}`,
        null,
      )
      .pipe(pluckAndCatch);
  }

  assignUserData(fromUserId: number, toUserId: number): Observable<void> {
    return this.http
      .patch(
        `${environment.api.marketView.account.users}/${toUserId}/${environment.api.marketView.account.assignData}`,
        { from: fromUserId },
      )
      .pipe(errorHandler);
  }

  assignOwner(id: number): Observable<void> {
    return this.http
      .patch(environment.api.marketView.account.assignOwner + `/${id}`, {})
      .pipe(errorHandler);
  }
}
