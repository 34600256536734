import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/district-profile.action";

import { MVEntityAvailable } from "../../../profile/utils/mv-entity-available";

import { IServerError } from "@shared/interfaces/server-error";
import { IBids } from "@shared/modules/bids-entity/interfaces";
import {
  IGrants,
  IProfileAddress,
} from "@shared/modules/institution-profiles/interfaces/profiles";
import { IMVEntityAvailable } from "../../../profile/interfaces/mv-entity-available";
import { IUserPermissions } from "../../../profile/interfaces/profile";
import {
  IConstructionalPlans,
  IDistrict,
  IDistrictHeader,
  IDistrictInfo,
  IEthnicity,
  IExpenditures,
  IFunding,
  IStudentInfo,
} from "../../interfaces/district-profile";

import { PermissionsKeys } from "@core/constants/permissions";
import { HIDDEN_BY } from "@shared/modules/institution-profiles/constants/profiles";

export interface IDistrictProfileState {
  district: IDistrict | null;
  loading: boolean;
  error: IServerError | null;
}

const initialState: IDistrictProfileState = {
  district: null,
  loading: false,
  error: null,
};

const getDistrictProfile: OnReducer<IDistrictProfileState, ActionType<any>> = (
  state: IDistrictProfileState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getDistrictProfileError: OnReducer<
  IDistrictProfileState,
  ActionType<Payload<IServerError>>
> = (state: IDistrictProfileState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getDistrictProfileSuccess: OnReducer<
  IDistrictProfileState,
  ActionType<Payload<any>>
> = (state: IDistrictProfileState, { payload }: Payload<any>) => ({
  ...state,
  district: { ...payload.data },
  loading: false,
  error: null,
});

const resetProfileDistrict: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IDistrictProfileState> =
  createReducer<IDistrictProfileState>(
    initialState,

    on(actions.getDistrictProfileAction, getDistrictProfile),
    on(actions.getDistrictProfileErrorAction, getDistrictProfileError),
    on(actions.getDistrictProfileSuccessAction, getDistrictProfileSuccess),

    on(actions.resetProfileDistrictAction, resetProfileDistrict),
  );

export function districtProfileReducer(
  state: IDistrictProfileState,
  action: Action,
): IDistrictProfileState {
  return reducer(state, action);
}

export const districtProfile: GetFromState<
  IDistrict | null,
  IDistrictProfileState
> = (state: IDistrictProfileState): IDistrict | null => state.district;
export const loading: GetFromState<boolean, IDistrictProfileState> = (
  state: IDistrictProfileState,
): boolean => state.loading;
export const error: GetFromState<IServerError, IDistrictProfileState> = (
  state: IDistrictProfileState,
): IServerError => state.error;
export const isPersonnel: GetFromState<boolean, IDistrict> = (
  district: IDistrict,
): boolean => district && district.isPersonnel;

export const districtProfileId: GetFromState<number, IDistrict> = (
  district: IDistrict,
): number => (district && district.header && district.header.id) || null;
export const districtPid: GetFromState<number, IDistrictHeader> = (
  header: IDistrictHeader,
): number => (header && header.pid) || null;
export const isDistrictPidHidden: GetFromState<boolean, IDistrictHeader> = (
  header: IDistrictHeader,
): boolean => header && header.pid.toString() === HIDDEN_BY.value;
export const districtHeader: GetFromState<IDistrictHeader, IDistrict> = (
  district: IDistrict,
): IDistrictHeader => (district && district.header) || null;
export const districtGeneralInfo: GetFromState<IDistrictInfo, IDistrict> = (
  district: IDistrict,
): IDistrictInfo => (district && district.info) || null;
export const districtStudentInfo: GetFromState<IStudentInfo, IDistrictInfo> = (
  districtInfo: IDistrictInfo,
): IStudentInfo => (districtInfo && districtInfo.studentInfo) || null;
export const districtEthnicity: GetFromState<IEthnicity, IDistrictInfo> = (
  district: IDistrictInfo,
): IEthnicity => (district && district.ethnicity) || null;
export const districtBids: GetFromState<IBids, IDistrict> = (
  district: IDistrict,
): IBids => district && district.bids;
export const isHiddenBids: GetFromState<boolean, IBids> = (
  _bids: IBids,
): boolean =>
  (_bids && _bids.total && _bids.total.toString() === HIDDEN_BY.value) || null;
export const districtGrands: GetFromState<IGrants, IDistrict> = (
  district: IDistrict,
): IGrants => district && district.grants;
export const isHiddenGrants: GetFromState<boolean, IGrants> = (
  _grants: IGrants,
): boolean => {
  return (
    (_grants &&
      _grants.totalActive &&
      _grants.totalActive.value &&
      _grants.totalActive.value.toString() === HIDDEN_BY.value) ||
    null
  );
};
export const constructionalPlans: GetFromState<
  IConstructionalPlans,
  IDistrict
> = (district: IDistrict): IConstructionalPlans =>
  district && district.constructionalPlans;
export const funding: GetFromState<IFunding, IDistrict> = (
  district: IDistrict,
): IFunding => district && district.funding;
export const expenditures: GetFromState<IExpenditures, IDistrict> = (
  district: IDistrict,
): IExpenditures => district.expenditures;

export const districtCoordinates: GetFromState<any, IDistrict> = (
  district: IDistrict,
): any => {
  if (district && district.header) {
    const { latitude: lat, longitude: lng }: IProfileAddress =
      district.header.physicalAddress || district.header.address;

    return { lat, lng };
  }

  return { lat: null, lng: null };
};

export const districtStudentInfoAllFieldsIsEmpty: GetFromState<
  boolean,
  IStudentInfo
> = (info: IStudentInfo): boolean => {
  return (
    info !== null &&
    info.collegeBoundStudents === 0 &&
    info.lepStudents === 0 &&
    info.numberOfSchools === 0 &&
    info.numberOfTeachers === 0 &&
    info.specialEDStudents === 0
  );
};

export const marketViewAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageViews, _data, _isHidden);
};

export const grantAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageGrants, _data, _isHidden);
};

export const bidAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageBids, _data, _isHidden);
};
