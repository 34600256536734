import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { SubHeaderComponent } from "./components/sub-header/sub-header.component";

const declarations: any[] = [SubHeaderComponent];

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [...declarations],
  exports: [...declarations],
})
export class HeadersModule {}
