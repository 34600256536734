import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  quickSearchResultsAllInstitutionReducer,
  IQuickSearchResultsAllInstitutionState,
} from "./quick-search-results-all-institution.reducer";
import {
  quickSearchResultsAllPersonnelReducer,
  IQuickSearchResultsAllPersonnelState,
} from "./quick-search-results-all-personnel.reducer";
import {
  quickSearchResultsBidsReducer,
  IQuickSearchResultsBidsState,
} from "./quick-search-results-bids.reducer";
import {
  quickSearchResultsGrantsReducer,
  IQuickSearchResultsGrantsState,
} from "./quick-search-results-grants.reducer";
import {
  quickSearchInstitutionCountersReducer,
  IQuickSearchInstitutionCountersState,
} from "./quick-search-results-institution-counter.reducer";
import {
  quickSearchResultsInstitutionDistrictsReducer,
  IQuickSearchInstitutionDistrictsState,
} from "./quick-search-results-institution-districts.reducer";
import {
  quickSearchResultsInstitutionSchoolsReducer,
  IQuickSearchResultsInstitutionSchoolsState,
} from "./quick-search-results-institution-schools.reducer";
import {
  quickSearchResultsInstitutionCollegesReducer,
  IQuickSearchResultsInstitutionCollegesState,
} from "./quick-search-results-institutuin-colleges.reducer";
import {
  quickSearchResultsPersonnelCollegesReducer,
  IQuickSearchResultsPersonnelCollegeState,
} from "./quick-search-results-personnel-colleges.reducer";
import {
  quickSearchPersonnelCountersReducer,
  IQuickSearchPersonnelCountersState,
} from "./quick-search-results-personnel-counter.reducer";
import {
  quickSearchResultsPersonnelDistrictReducer,
  IQuickSearchResultsPersonnelDistrictsState,
} from "./quick-search-results-personnel-districts.reducer";
import {
  quickSearchResultsPersonnelSchoolsReducer,
  IQuickSearchResultsPersonnelSchoolsState,
} from "./quick-search-results-personnel-schools.reducer";
import {
  quickSearchResultsReducer,
  IQuickSearchResultsState,
} from "./quick-search-results.reducer";
import { quickSearchReducer, IQuickSearchState } from "./quick-search.reducer";

export interface IQuickSearchModuleState {
  quickSearch: IQuickSearchState;

  quickSearchResults: IQuickSearchResultsState;

  quickSearchResultsBids: IQuickSearchResultsBidsState;
  quickSearchResultsGrants: IQuickSearchResultsGrantsState;

  quickSearchResultsInstitutionAll: IQuickSearchResultsAllInstitutionState;
  quickSearchResultsPersonnelAll: IQuickSearchResultsAllPersonnelState;

  quickSearchResultsInstitutionDistricts: IQuickSearchInstitutionDistrictsState;
  quickSearchResultsInstitutionSchools: IQuickSearchResultsInstitutionSchoolsState;
  quickSearchResultsInstitutionColleges: IQuickSearchResultsInstitutionCollegesState;

  quickSearchResultsPersonnelColleges: IQuickSearchResultsPersonnelCollegeState;
  quickSearchResultsPersonnelDistrict: IQuickSearchResultsPersonnelDistrictsState;
  quickSearchResultsPersonnelSchools: IQuickSearchResultsPersonnelSchoolsState;

  quickSearchInstitutionCounters: IQuickSearchInstitutionCountersState;
  quickSearchPersonnelCounters: IQuickSearchPersonnelCountersState;
}

const quickSearchModuleReducer: ActionReducerMap<IQuickSearchModuleState> = {
  quickSearch: quickSearchReducer,

  quickSearchResults: quickSearchResultsReducer,

  quickSearchResultsBids: quickSearchResultsBidsReducer,
  quickSearchResultsGrants: quickSearchResultsGrantsReducer,

  quickSearchResultsInstitutionAll: quickSearchResultsAllInstitutionReducer,
  quickSearchResultsPersonnelAll: quickSearchResultsAllPersonnelReducer,

  quickSearchResultsInstitutionDistricts:
    quickSearchResultsInstitutionDistrictsReducer,
  quickSearchResultsInstitutionSchools:
    quickSearchResultsInstitutionSchoolsReducer,
  quickSearchResultsInstitutionColleges:
    quickSearchResultsInstitutionCollegesReducer,

  quickSearchResultsPersonnelColleges:
    quickSearchResultsPersonnelCollegesReducer,
  quickSearchResultsPersonnelDistrict:
    quickSearchResultsPersonnelDistrictReducer,
  quickSearchResultsPersonnelSchools: quickSearchResultsPersonnelSchoolsReducer,

  quickSearchInstitutionCounters: quickSearchInstitutionCountersReducer,
  quickSearchPersonnelCounters: quickSearchPersonnelCountersReducer,
};

// eslint-disable-next-line
export const QUICK_SEARCH_STATE_NAME = 'quickSearchModule';
export const QUICK_SEARCH_STATE: InjectionToken<
  ActionReducerMap<IQuickSearchModuleState>
> = new InjectionToken<ActionReducerMap<IQuickSearchModuleState>>(
  QUICK_SEARCH_STATE_NAME,
  {
    factory: () => quickSearchModuleReducer,
  },
);
