import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { CustomEmitter } from "@core/services/custom-emitter";

import { ISuggestion } from "@shared/interfaces/autocomplete-keywords";
import { KEY_CODES } from "@shared/interfaces/keyboard";

import { MOBILE_KEYWORD_EVENTS } from "@shared/constants/scroll";

@Component({
  selector: "bl-autocomplete-keywords",
  templateUrl: "./autocomplete-keywords.component.html",
  styleUrls: ["./autocomplete-keywords.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteKeywordsComponent {
  @Input() suggestions: ISuggestion[] = [];
  @Input() isAutoFocus: boolean = false;
  @Input() shouldTrim: boolean = true;
  @Input() placeholder: string = "";

  @Output() autoCompleteKeywordsEnter: EventEmitter<string> =
    new EventEmitter();
  @Output() autoCompleteKeywordsInput: EventEmitter<string> =
    new EventEmitter();
  @Output() resetSuggestions: EventEmitter<any> = new EventEmitter();
  @Output() inputFocusInOut: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild("input") inputEl: ElementRef;
  @ViewChild("list") listEl: ElementRef;

  value: string = "";
  counter: any = null;
  isEdit: boolean = false;

  @HostListener("document:click", ["$event"])
  onClick(event: Event): void {
    if (this.listEl && !this.listEl.nativeElement.contains(event.target)) {
      this._resetValue();
    }
  }

  constructor(private _customEmitter: CustomEmitter) {}

  onByEnter(isCombine: boolean = false): void {
    if (!this.isEdit && !isCombine && !!this.value.trim()) {
      this.autoCompleteKeywordsEnter.emit(this.valueToEmit);
      this.value = "";
    } else {
      // when selected from dropdown
      if (this.suggestions && this.suggestions.length) {
        this.value = this._combineValue(this.suggestions[this.counter].text);
      }
    }

    this._resetValue();
    this.inputEl.nativeElement.focus();
  }

  onByInput(): void {
    this.autoCompleteKeywordsInput.emit(this.valueToEmit);
  }

  selectSuggestionIndex($event: Event, newCounter: number): void {
    $event.stopPropagation();
    $event.preventDefault();

    this.counter = newCounter;
  }

  onKeyboardEvents($event: KeyboardEvent): void {
    if (
      $event.code !== KEY_CODES.ArrowUp &&
      $event.code !== KEY_CODES.ArrowDown
    ) {
      return;
    }

    $event.stopPropagation();
    $event.preventDefault();

    this.isEdit = true;

    if (this.counter === null) {
      this.counter = 0;
      return;
    }

    switch ($event.code) {
      case KEY_CODES.ArrowDown: {
        this.counter =
          this.counter < this.suggestions.length - 1
            ? ++this.counter
            : this.counter;
        break;
      }

      case KEY_CODES.ArrowUp: {
        this.counter = this.counter > 0 ? --this.counter : this.counter;
        break;
      }
    }
  }

  private _combineValue(value: string): string {
    if (!value) {
      return "";
    }

    const commaIndex: number = this.value.lastIndexOf(",");
    const valueBeforeComma: string =
      commaIndex !== -1 ? this.value.substring(-commaIndex).trim() : "";

    return `${valueBeforeComma} ${value}`.trim();
  }

  private _resetValue(): void {
    this.isEdit = false;
    this.counter = null;
    this.resetSuggestions.emit();
  }

  onInputFocus(e: Event): void {
    this.inputFocusInOut.emit(true);
    this._customEmitter.emit<boolean>(
      MOBILE_KEYWORD_EVENTS.MOBILE_KEYWORD_TRIGGER,
      true,
    );
  }

  onInputFocusOut(e: Event): void {
    this.inputFocusInOut.emit(false);
    this._customEmitter.emit<boolean>(
      MOBILE_KEYWORD_EVENTS.MOBILE_KEYWORD_TRIGGER,
      false,
    );
  }

  get valueToEmit(): string {
    return this.shouldTrim
      ? this.value.trim()
      : this.value.replace(/^\s+/g, "").replace(/  +/g, " ");
  }
}
