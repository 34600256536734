/*
 https://www.regular-expressions.info/creditcard.html
 https://rgxdb.com/r/8JU9PAK

 AMEX: /^34|37/
 VS: /^4/
 DN: /^3[689]|5[45]|30[0-5]/
*/

export const CARD_TYPES: {
  AMEX: RegExp;
  MC: RegExp;
  DN: RegExp;
  VS: RegExp;
  DS: RegExp;
} = {
  AMEX: /^3[47][0-9]{13}$/,
  MC: /^5[0-5]|5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390/,
  VS: /^4[0-9]{12}(?:[0-9]{3})?$/,
  DS: /^6(011(0[0-9]|[2-4]\d|74|7[7-9]|8[6-9]|9[0-9])|4[4-9]\d{3}|5\d{4})/,
  DN: /^(6011|65|64[4-9]|622)/,
};

export function getCardType(cardNumber: number | string): string {
  for (const [type, pattern] of Object.entries(CARD_TYPES)) {
    if (pattern.test(`${cardNumber}`)) {
      if (type === "DN") {
        return "DS";
      }

      return type;
    }
  }

  return "";
}
