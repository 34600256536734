import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  districtProfileReducer,
  IDistrictProfileState,
} from "./district-profile.reducer";
import {
  districtSchoolsReducer,
  IDistrictSchoolsState,
} from "./district-schools.reducer";

export interface IDistrictProfileReducers {
  districtProfile: IDistrictProfileState;
  districtSchools: IDistrictSchoolsState;
}

const DistrictProfileReducers: ActionReducerMap<IDistrictProfileReducers> = {
  districtProfile: districtProfileReducer,
  districtSchools: districtSchoolsReducer,
};

// eslint-disable-next-line
export const DISTRICT_PROFILE_STATE_NAME = 'district';

export const DISTRICT_PROFILE_STATE: InjectionToken<
  ActionReducerMap<IDistrictProfileReducers>
> = new InjectionToken<ActionReducerMap<IDistrictProfileReducers>>(
  DISTRICT_PROFILE_STATE_NAME,
  {
    factory: () => DistrictProfileReducers,
  },
);
