import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { getUserHasCountryCode } from "../../../profile/store/selectors/profile.selector";
import { ICountriesModuleState } from "../reducers";
import * as countries from "../reducers/countries.reducer";
import { ICountriesAndStatesState } from "../reducers/countries.reducer";

import { ICountries, ICountryStates } from "@modules/countries/interfaces";
import { IControlOptions } from "@shared/interfaces/forms";

import { getCountriesModuleState } from "./index";

export const countriesState: GetFromStateInSelectors<
  CoreState,
  ICountriesAndStatesState
> = createSelector(
  getCountriesModuleState,
  (state: ICountriesModuleState) => state && state.countriesAndStates,
);

export const getCountries: MemoizedSelector<CoreState, ICountries> =
  createSelector(countriesState, countries.countries);
export const getCountryStates: MemoizedSelector<CoreState, ICountryStates> =
  createSelector(countriesState, countries.countryStates);
export const getIsCountryStatesFetching: MemoizedSelector<CoreState, boolean> =
  createSelector(countriesState, countries.isCountryStatesFetching);
export const getUserCountryCode: MemoizedSelector<CoreState, string> =
  createSelector(
    countriesState,
    getUserHasCountryCode,
    countries.userCountryCode,
  );

export const getCountriesAsControlOptions: MemoizedSelector<
  CoreState,
  IControlOptions
> = createSelector(getCountries, countries.countriesControlOptions);
export const getCountryStatesAsControlOptions: MemoizedSelector<
  CoreState,
  IControlOptions
> = createSelector(getCountryStates, countries.countryStatesAsControlOptions);
