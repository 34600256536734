import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/name-and-pid-codes.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IPID } from "../../../segment/interfaces/pids";

import {
  IMultiCodesRequest,
  MultiCodesItems,
} from "../../../segment/models/codes";

import {
  DEFAULT_CODES_LIMIT,
  DEFAULT_CODES_OFFSET,
} from "../../../segment/constants/codes";

export interface INameAndPidCodesState {
  codes: MultiCodesItems<IPID>;
  total: number;
  onlyPids: boolean;
  pending: boolean;
  error: IServerError | null;
}

const initialState: INameAndPidCodesState = {
  codes: [],
  total: 0,
  onlyPids: false,
  pending: false,
  error: null,
};

const getNameAndPidCodes: OnReducer<INameAndPidCodesState, ActionType<any>> = (
  state: INameAndPidCodesState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getNameAndPidCodesError: OnReducer<
  INameAndPidCodesState,
  ActionType<Payload<IServerError>>
> = (state: INameAndPidCodesState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getNameAndPidCodesSuccess: OnReducer<
  INameAndPidCodesState,
  ActionType<Payload<any>>
> = (
  state: INameAndPidCodesState,
  {
    payload: {
      response: { items, total, onlyPids },
    },
  }: Payload<any>,
) => ({
  ...state,
  pending: false,
  codes: items,
  total,
  onlyPids,
});

const resetNameAndPidCodesState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<INameAndPidCodesState> =
  createReducer<INameAndPidCodesState>(
    initialState,

    on(actions.getNameAndPidCodesAction, getNameAndPidCodes),
    on(actions.getNameAndPidCodesErrorAction, getNameAndPidCodesError),
    on(actions.getNameAndPidCodesSuccessAction, getNameAndPidCodesSuccess),

    on(actions.resetNameAndPidCodesStateAction, resetNameAndPidCodesState),
  );

export function geoCodesReducer(
  state: INameAndPidCodesState,
  action: Action,
): INameAndPidCodesState {
  return reducer(state, action);
}

export const nameAndPidCodes: GetFromState<
  MultiCodesItems<IPID>,
  INameAndPidCodesState
> = (state: INameAndPidCodesState): MultiCodesItems<IPID> => state.codes;
export const nameAndPidCodesOnlyPids: GetFromState<
  boolean,
  INameAndPidCodesState
> = (state: INameAndPidCodesState): boolean => state.onlyPids;
export const nameAndPidCodesTotalCount: GetFromState<
  number,
  INameAndPidCodesState
> = (state: INameAndPidCodesState): number => state.total;
export const nameAndPidCodesPendingState: GetFromState<
  boolean,
  INameAndPidCodesState
> = (state: INameAndPidCodesState): boolean => state.pending;

export const nameAndPidCodesCurrentPage: GetFromState<
  number,
  IMultiCodesRequest
> = ({
  offset = DEFAULT_CODES_OFFSET,
  limit = DEFAULT_CODES_LIMIT,
}: IMultiCodesRequest): number => Math.ceil(offset / limit) + 1;

export const mameAndPidCodesSearchParams: GetFromState<
  IMultiCodesRequest,
  IMultiCodesRequest
> = ({
  segmentCriteriaId,
  offset = DEFAULT_CODES_OFFSET,
  limit = DEFAULT_CODES_LIMIT,
  searchQuery,
  recordsType,
}: IMultiCodesRequest): IMultiCodesRequest => ({
  segmentCriteriaId,
  offset,
  limit,
  searchQuery,
  recordsType: recordsType ? Number(recordsType) : null,
});
