import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { environment } from "@env/environment";

import { ConfirmPopUpData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";
import { ConfirmPopUpResult } from "../../models/pop-up-result";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-confirm-pop-up-content",
  templateUrl: "./confirm-pop-up-content.component.html",
  styleUrls: ["./confirm-pop-up-content.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmPopUpContentComponent {
  doNotShow: boolean = false;
  phoneNumber: string = environment.contactPhoneNumber;

  constructor(
    private popUpRef: PopUpRef<
      ConfirmPopUpContentComponent,
      ConfirmPopUpResult
    >,
    @Inject(POP_UP_DATA) public data: ConfirmPopUpData,
  ) {}

  onClose(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.popUpRef.close({ answer: false, doNotShow: this.doNotShow });
  }

  onConfirm(event: Event): void {
    event.stopPropagation();
    if (this.data.updateStorage) {
      this.data.updateStorage(this.doNotShow);
    }
    this.popUpRef.close({ answer: true, doNotShow: this.doNotShow });
  }
}
