import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

// directives
import { HidePointDirective } from "./directives/hide-point.directive";

@NgModule({
  imports: [CommonModule],
  declarations: [HidePointDirective],
  exports: [HidePointDirective],
})
export class ViewPointsModule {}
