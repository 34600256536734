import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-personnel-schools.action";

import { IPersonnelRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { PERSONNEL_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsPersonnelSchoolsState {
  schools: IPersonnelRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IQuickSearchResultsPersonnelSchoolsState = {
  schools: [],
  pending: false,
  error: null,

  count: 0,
};

const quickSearchPersonnelSchools: OnReducer<
  IQuickSearchResultsPersonnelSchoolsState,
  ActionType<any>
> = (state: IQuickSearchResultsPersonnelSchoolsState) => ({
  ...state,
  pending: true,
  error: null,
});

const quickSearchPersonnelSchoolsError: OnReducer<
  IQuickSearchResultsPersonnelSchoolsState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsPersonnelSchoolsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const quickSearchPersonnelSchoolsSuccess: OnReducer<
  IQuickSearchResultsPersonnelSchoolsState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsPersonnelSchoolsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  schools: [...payload.items],
  count: payload.count,
});

const setQuickSearchPersonnelSchoolsSpinnerState: OnReducer<
  IQuickSearchResultsPersonnelSchoolsState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsPersonnelSchoolsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetQuickSearchPersonnelSchoolsState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsPersonnelSchoolsState> =
  createReducer<IQuickSearchResultsPersonnelSchoolsState>(
    initialState,

    on(
      actions.getQuickSearchPersonnelSchoolsAction,
      quickSearchPersonnelSchools,
    ),
    on(
      actions.getQuickSearchPersonnelSchoolsErrorAction,
      quickSearchPersonnelSchoolsError,
    ),
    on(
      actions.getQuickSearchPersonnelSchoolsSuccessAction,
      quickSearchPersonnelSchoolsSuccess,
    ),

    on(
      actions.changeQuickSearchPersonnelSchoolsPageAction,
      quickSearchPersonnelSchools,
    ),
    on(
      actions.changeQuickSearchPersonnelSchoolsQueryAction,
      quickSearchPersonnelSchools,
    ),

    on(
      actions.setQuickSearchPersonnelSchoolsSpinnerStateAction,
      setQuickSearchPersonnelSchoolsSpinnerState,
    ),

    on(
      actions.resetQuickSearchPersonnelSchoolsStateAction,
      resetQuickSearchPersonnelSchoolsState,
    ),
  );

export function quickSearchResultsPersonnelSchoolsReducer(
  state: IQuickSearchResultsPersonnelSchoolsState,
  action: Action,
): IQuickSearchResultsPersonnelSchoolsState {
  return reducer(state, action);
}

export const schools: GetFromState<
  IPersonnelRecord[],
  IQuickSearchResultsPersonnelSchoolsState
> = (state: IQuickSearchResultsPersonnelSchoolsState): IPersonnelRecord[] =>
  state.schools;
export const pending: GetFromState<
  boolean,
  IQuickSearchResultsPersonnelSchoolsState
> = (state: IQuickSearchResultsPersonnelSchoolsState): boolean => state.pending;

export const schoolsPaginationCount: GetFromState<
  number,
  IQuickSearchResultsPersonnelSchoolsState
> = (state: IQuickSearchResultsPersonnelSchoolsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  const { sortBy }: Params = queryParams;

  return {
    ...PERSONNEL_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    sortBy: sortBy || "lastName",
    filterBy: "schools",
  };
};
