import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { ICollegeCampusesResponse } from "../../interfaces/college-campuses";

enum ACTION_TYPES {
  GET_COLLEGE_CAMPUSES = "[COLLEGE CAMPUSES] get campuses",
  GET_COLLEGE_CAMPUSES_ERROR = "[COLLEGE CAMPUSES] get campuses error",
  GET_COLLEGE_CAMPUSES_SUCCESS = "[COLLEGE CAMPUSES] get campuses success",

  SORT_COLLEGE_CAMPUSES = "[COLLEGE CAMPUSES] sort campuses",
  SORT_COLLEGE_CAMPUSES_ERROR = "[COLLEGE CAMPUSES] sort campuses error",
  SORT_COLLEGE_CAMPUSES_SUCCESS = "[COLLEGE CAMPUSES] sort campuses success",

  TOGGLE_COLLEGE_CAMPUSES = "[COLLEGE CAMPUSES] toggle campuses",
  TOGGLE_COLLEGE_CAMPUSES_SUCCESS = "[COLLEGE CAMPUSES] toggle campuses success",
  RESET_TOGGLE_COLLEGE_CAMPUSES = "[COLLEGE CAMPUSES] reset toggle campuses success",

  RESET_STATE = "[COLLEGE CAMPUSES] reset campuses state",
}

export const getCollegeCampusesAction: CreateActionType<
  ACTION_TYPES.GET_COLLEGE_CAMPUSES,
  number
> = createAction(ACTION_TYPES.GET_COLLEGE_CAMPUSES, createPayload<number>());
export const getCollegeCampusesErrorAction: CreateActionType<
  ACTION_TYPES.GET_COLLEGE_CAMPUSES_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_COLLEGE_CAMPUSES_ERROR,
  createPayload<IServerError>(),
);
export const getCollegeCampusesSuccessAction: CreateActionType<
  ACTION_TYPES.GET_COLLEGE_CAMPUSES_SUCCESS,
  ICollegeCampusesResponse
> = createAction(
  ACTION_TYPES.GET_COLLEGE_CAMPUSES_SUCCESS,
  createPayload<ICollegeCampusesResponse>(),
);

export const sortCollegeCampusesAction: CreateActionType<
  ACTION_TYPES.SORT_COLLEGE_CAMPUSES,
  Params
> = createAction(ACTION_TYPES.SORT_COLLEGE_CAMPUSES, createPayload<Params>());
export const sortCollegeCampusesErrorAction: CreateActionType<
  ACTION_TYPES.SORT_COLLEGE_CAMPUSES_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SORT_COLLEGE_CAMPUSES_ERROR,
  createPayload<IServerError>(),
);
export const sortCollegeCampusesSuccessAction: CreateActionType<
  ACTION_TYPES.SORT_COLLEGE_CAMPUSES_SUCCESS,
  ICollegeCampusesResponse
> = createAction(
  ACTION_TYPES.SORT_COLLEGE_CAMPUSES_SUCCESS,
  createPayload<ICollegeCampusesResponse>(),
);

export const toggleCollegeCampusesAction: ActionCreator<
  ACTION_TYPES.TOGGLE_COLLEGE_CAMPUSES,
  () => TypedAction<ACTION_TYPES.TOGGLE_COLLEGE_CAMPUSES>
> = createAction(ACTION_TYPES.TOGGLE_COLLEGE_CAMPUSES);
export const toggleCollegeCampusesSuccessAction: ActionCreator<
  ACTION_TYPES.TOGGLE_COLLEGE_CAMPUSES_SUCCESS,
  () => TypedAction<ACTION_TYPES.TOGGLE_COLLEGE_CAMPUSES_SUCCESS>
> = createAction(ACTION_TYPES.TOGGLE_COLLEGE_CAMPUSES_SUCCESS);
export const resetToggleCollegeCampusesAction: ActionCreator<
  ACTION_TYPES.RESET_TOGGLE_COLLEGE_CAMPUSES,
  () => TypedAction<ACTION_TYPES.RESET_TOGGLE_COLLEGE_CAMPUSES>
> = createAction(ACTION_TYPES.RESET_TOGGLE_COLLEGE_CAMPUSES);

export const resetCollegeCampusesAction: ActionCreator<
  ACTION_TYPES.RESET_STATE,
  () => TypedAction<ACTION_TYPES.RESET_STATE>
> = createAction(ACTION_TYPES.RESET_STATE);
