import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  TemplateRef,
  Type,
} from "@angular/core";

import { DocumentRef } from "../refs/document-ref.service";

@Injectable()
export class PortalService {
  constructor(
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _appRef: ApplicationRef,
    private _injector: Injector,
    private _document: DocumentRef,
  ) {}

  attachComponent<C>(component: Type<C>): () => void {
    const componentRef: ComponentRef<C> = this.createComponent<C>(component);
    return this.attachComponentRef(componentRef);
  }

  attachComponentRef<T>(componentRef: ComponentRef<T>): () => void {
    this._appRef.attachView(componentRef.hostView);

    const domElement: HTMLElement = (
      componentRef.hostView as EmbeddedViewRef<T>
    ).rootNodes[0];

    this._document.nativeElement.body.appendChild(domElement);

    return () => {
      this.detach(componentRef);
      componentRef.destroy();
    };
  }

  attachTemplate<T = any>(template: TemplateRef<T>, context?: T): () => void {
    const embeddedViewRef: EmbeddedViewRef<T> =
      template.createEmbeddedView(context);
    return this.attachEmbeddedView(embeddedViewRef);
  }

  attachEmbeddedView<T>(embeddedViewRef: EmbeddedViewRef<T>): () => void {
    this._appRef.attachView(embeddedViewRef);

    const domElement: HTMLElement = (embeddedViewRef as EmbeddedViewRef<T>)
      .rootNodes[0];

    this._document.nativeElement.body.appendChild(domElement);

    return () => {
      this._appRef.detachView(embeddedViewRef);
    };
  }

  resolveComponentFactory<C>(component: Type<C>): ComponentFactory<C> {
    return this._componentFactoryResolver.resolveComponentFactory(component);
  }

  createComponent<C>(
    component: Type<C>,
    injector: Injector = this._injector,
  ): ComponentRef<C> {
    return this.resolveComponentFactory(component).create(injector);
  }

  show<C>(componentRef: ComponentRef<C>): void {
    componentRef.changeDetectorRef.reattach();
    this.attach(componentRef);
  }

  hide<C>(componentRef: ComponentRef<C>, destroy: boolean = true): void {
    if (destroy) {
      componentRef.destroy();
    } else {
      this.detach<C>(componentRef);
      componentRef.changeDetectorRef.detach();
    }
  }

  attach<C>(componentRef: ComponentRef<C>, target?: ElementRef<any>): void {
    this._appRef.attachView(componentRef.hostView);
    const domElement: HTMLElement = (
      componentRef.hostView as EmbeddedViewRef<C>
    ).rootNodes[0];
    if (target) {
      target.nativeElement.appendChild(domElement);
    } else {
      this._document.nativeElement.body.appendChild(domElement);
    }
  }

  detach<C>(componentRef: ComponentRef<C>): void {
    this._appRef.detachView(componentRef.hostView);
  }
}
