import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";

import { AuthService } from "@modules/auth/services/auth.service";

import { ISignInFormData } from "@modules/auth/interfaces/formsActionsData";

import { CORE_PATHS } from "@core/constants/core-paths";
import { AUTH_PATHS } from "@modules/auth/constants/auth-paths";
import { INPUT_AUTOCOMPLETE } from "@shared/constants/flat-input";

@Component({
  selector: "bl-sign-in-form",
  templateUrl: "./sign-in-form.component.html",
  styleUrls: ["./sign-in-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignInFormComponent implements OnInit, OnChanges {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly authPaths: typeof AUTH_PATHS = AUTH_PATHS;

  @Input() loading: boolean = false;
  @Input() serverError: { [key: string]: string[] };
  @Input() isShowFooter: boolean = true;
  @Input() email: string;
  @Input() autocompleteOff: INPUT_AUTOCOMPLETE = INPUT_AUTOCOMPLETE.OFF;

  @Output() submitSignInForm: EventEmitter<ISignInFormData> =
    new EventEmitter();
  @Output() checkUserName: EventEmitter<string> = new EventEmitter();

  form: UntypedFormGroup;

  constructor(
    private authService: AuthService,
    private chdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.form = this.authService.sigInForm(this.email);

    this.chdRef.markForCheck();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const errors: any = changes.serverError && changes.serverError.currentValue;
    if (errors) {
      Object.entries(errors).forEach(
        ([fieldName, message]: [string, string[]]) => {
          const control: AbstractControl = this.credentials.get(fieldName);
          if (control) {
            control.setErrors(message);
          }
        },
      );
    }

    this.chdRef.markForCheck();
  }

  submit(): void {
    if (!this.form.valid) {
      /* TODO button 'Sign in' will be enable always, the validation will go by click
          because the browser doesn't allow check the values before first click */
      this.credentials.controls["email"].markAsTouched();
      return;
    }

    // this.submitSignInForm.emit(this.form.value);
    this.emitUserName();
  }

  emitUserName() {
    let email = this.credentials.get("email");
    if (email.value && !email.errors) {
      this.checkUserName.emit(email.value);
    }
  }

  get credentials(): UntypedFormGroup {
    return this.form.controls["credentials"] as UntypedFormGroup;
  }
}
