import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import { Params } from "@angular/router";

@Component({
  selector: "bl-tab",
  templateUrl: "./tab.component.html",
  styleUrls: ["./tab.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent implements OnInit {
  @Input() label: string;
  @Input() small: boolean;
  @Input() description: string;
  @Input() disabled: boolean;
  @Input() theme: string = "";
  @Input() showDescription: boolean = true;

  // Add more routerLink inputs if needed
  @Input() url: string[];
  @Input() queryParams: Params;
  @Input() activeOptionsExact: boolean = false;
  @Input() isActive: boolean = false;

  @Output() navigate: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private _renderer: Renderer2,
    private _el: ElementRef,
  ) {}

  ngOnInit(): void {
    this._renderer.addClass(this._el.nativeElement, `theme--${this.theme}`);
  }
}
