import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { select, Action, Store } from "@ngrx/store";

import { defer, of, Observable } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { ActionWithPayload } from "@shared/interfaces/store";
import * as authActions from "../../../auth/store/actions/auth.action";
import * as cartActions from "../actions/cart.action";
import { resetCreditCardsAction } from "../actions/payment-cards.action";
import {
  getCartItemsForValidation,
  getStorageCartItems,
} from "../selectors/cart.selector";

import { StorageService } from "@core/services/storage.service";
import { CartService } from "../../services/cart.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IPromotionDetailsData,
  IPromotionParams,
  IValidatedCartItems,
  IValidateProducts,
} from "../../interfaces/e-commerce";
import {
  IPrice,
  IPrices,
  ITaxData,
  ITaxDataResponse,
} from "../../interfaces/price";

@Injectable()
export class ECommerceCartEffect {
  constructor(
    private _actions$: Actions,
    private _store: Store<CoreState>,
    private _cartService: CartService,
  ) {}

  addItemToCart$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(cartActions.addItemToCartAction),
          withLatestFrom(this._store.pipe(select(getStorageCartItems))),
          tap(
            ([action, eCommerceCart]: [Action, IPrice[]]) =>
              (StorageService.eCommerceCart = eCommerceCart),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  removeItemFromCart$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(
            cartActions.removeItemFromCartAction,
            cartActions.removeTaxedItemFromCardAction,
          ),
          withLatestFrom(this._store.pipe(select(getStorageCartItems))),
          tap(
            ([action, eCommerceCart]: [Action, IPrices]) =>
              (StorageService.eCommerceCart = eCommerceCart),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  updatePromoCodeItem$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(cartActions.updatePromoCodeAction),
      switchMap(({ payload }: ActionWithPayload<IPromotionParams>) =>
        this._cartService
          .promotionDetails({
            promoCode: payload.promoCode,
            productId: payload.productId,
          })
          .pipe(
            map((promoData: IPromotionDetailsData) => {
              const { productId, index }: IPromotionParams = payload;
              return cartActions.updatePromoCodeSuccessAction({
                promoDetails: promoData,
                productId,
                index,
              });
            }),
            catchError((error: IServerError) => {
              const { productId, index }: IPromotionParams = payload;
              return of(
                cartActions.updatePromoCodeErrorAction({
                  error: error,
                  productId,
                  index,
                }),
              );
            }),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  resetAfterLogout$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(authActions.signOutSuccessAction),
      switchMap(() => [
        cartActions.resetECommerceCartAction(),
        resetCreditCardsAction(),
      ]),
      catchErrorWithErrorType,
    ),
  );

  validateCart$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(cartActions.validateCartAction),
      withLatestFrom(this._store.pipe(select(getCartItemsForValidation))),
      switchMap(([action, eCommerceCart]: [Action, IValidateProducts]) =>
        this._cartService.validateCart(eCommerceCart).pipe(
          map((validatedCartItems: IValidatedCartItems) =>
            cartActions.validateCartSuccessAction(validatedCartItems),
          ),
          catchError((error: IServerError) =>
            of(cartActions.validateCartErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  getTax$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(cartActions.getTaxAction),
      switchMap(({ payload }: ActionWithPayload<ITaxDataResponse>) =>
        this._cartService.tax(payload).pipe(
          map((data: ITaxData) => cartActions.getTaxSuccessAction(data)),
          catchError((error: IServerError) =>
            of(cartActions.getTaxErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  resetCart$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(cartActions.resetECommerceCartAction),
          tap(() => (StorageService.eCommerceCart = [])),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );
}
