import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../../store/actions/cdl-list-refresh-schedule.action";

import { IControlOption, IControlOptions } from "@shared/interfaces/forms";
import { IServerError } from "@shared/interfaces/server-error";
import {
  ICDLRecipient,
  ICDLRecipients,
  IRefreshSchedules,
} from "../../interfaces";

export interface ICDLRefreshScheduleState {
  refreshSchedules: IRefreshSchedules | null;
  recipients: ICDLRecipients;
  pending: boolean;
  error: IServerError | null;
}

const initialState: ICDLRefreshScheduleState = {
  refreshSchedules: null,
  recipients: [],
  pending: false,
  error: null,
};

const getCDLRefreshSchedules: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<any>
> = (state: ICDLRefreshScheduleState) => ({
  ...state,
  pending: true,
  error: null,
});

const getCDLRefreshSchedulesError: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<Payload<IServerError>>
> = (state: ICDLRefreshScheduleState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: payload,
});

const getCDLRefreshSchedulesSuccess: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<Payload<any>>
> = (state: ICDLRefreshScheduleState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  refreshSchedules: payload,
});

const updating: OnReducer<ICDLRefreshScheduleState, ActionType<any>> = (
  state: ICDLRefreshScheduleState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const updatingError: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<Payload<any>>
> = (state: ICDLRefreshScheduleState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: payload,
});

const updatingSuccess: OnReducer<ICDLRefreshScheduleState, ActionType<any>> = (
  state: ICDLRefreshScheduleState,
) => ({
  ...state,
  pending: false,
});

const getCDLRecipients: OnReducer<ICDLRefreshScheduleState, ActionType<any>> = (
  state: ICDLRefreshScheduleState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getCDLRecipientsError: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<Payload<IServerError>>
> = (state: ICDLRefreshScheduleState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: payload,
});

const getCDLRecipientsSuccess: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<Payload<any>>
> = (state: ICDLRefreshScheduleState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  recipients: payload,
});

const syncRecipients: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<Payload<any>>
> = (state: ICDLRefreshScheduleState, { payload }: Payload<any>) => ({
  ...state,
  pending: true,
  error: null,
  recipients: state.recipients.map((item: ICDLRecipient) =>
    item.id === payload.id
      ? {
          ...item,
          synced: payload.value,
        }
      : item,
  ),
});

const syncRecipientsError: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<Payload<IServerError>>
> = (state: ICDLRefreshScheduleState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: payload,
});

const syncRecipientsSuccess: OnReducer<
  ICDLRefreshScheduleState,
  ActionType<any>
> = (state: ICDLRefreshScheduleState) => ({
  ...state,
  pending: false,
});

const resetRefreshSchedulesState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLRefreshScheduleState> =
  createReducer<ICDLRefreshScheduleState>(
    initialState,

    on(actions.getCDLRefreshSchedulesAction, getCDLRefreshSchedules),
    on(actions.getCDLRefreshSchedulesErrorAction, getCDLRefreshSchedulesError),
    on(
      actions.getCDLRefreshSchedulesSuccessAction,
      getCDLRefreshSchedulesSuccess,
    ),

    on(
      actions.addCDLRefreshScheduleAction,
      actions.removeCDLRefreshScheduleAction,
      actions.updateCDLRefreshScheduleAction,
      updating,
    ),
    on(
      actions.addCDLRefreshScheduleErrorAction,
      actions.removeCDLRefreshScheduleAction,
      actions.updateCDLRefreshScheduleErrorAction,
      updatingError,
    ),

    on(
      actions.addCDLRefreshScheduleSuccessAction,
      actions.removeCDLRefreshScheduleSuccessAction,
      actions.updateCDLRefreshScheduleSuccessAction,
      updatingSuccess,
    ),

    on(actions.getCDLRecipientsAction, getCDLRecipients),
    on(actions.getCDLRecipientsErrorAction, getCDLRecipientsError),
    on(actions.getCDLRecipientsSuccessAction, getCDLRecipientsSuccess),

    on(actions.syncRecipientsAction, syncRecipients),
    on(actions.syncRecipientsErrorAction, syncRecipientsError),
    on(actions.syncRecipientsSuccessAction, syncRecipientsSuccess),

    on(actions.resetRefreshSchedulesStateAction, resetRefreshSchedulesState),
  );

export function cdlListRefreshScheduleReducer(
  state: ICDLRefreshScheduleState,
  action: Action,
): ICDLRefreshScheduleState {
  return reducer(state, action);
}

export const refreshSchedules: GetFromState<
  IRefreshSchedules,
  ICDLRefreshScheduleState
> = (state: ICDLRefreshScheduleState): IRefreshSchedules =>
  state && state.refreshSchedules;
export const refreshSelectedTimePoints: GetFromState<
  IControlOptions,
  IRefreshSchedules
> = (data: IRefreshSchedules): IControlOptions =>
  data && data.timePoints.filter((item: IControlOption) => item.value);

export const recipients: GetFromState<
  ICDLRecipients,
  ICDLRefreshScheduleState
> = (state: ICDLRefreshScheduleState): ICDLRecipients =>
  state && state.recipients;
export const recipientsPayload: GetFromState<number[], ICDLRecipients> = (
  _recipients: ICDLRecipients,
): number[] =>
  _recipients &&
  _recipients
    .filter((item: ICDLRecipient) => item.synced)
    .map((item: ICDLRecipient) => item.id);
export const recipientsMap: GetFromState<
  IControlOptions,
  ICDLRefreshScheduleState
> = (state: ICDLRefreshScheduleState): IControlOptions =>
  state &&
  state.recipients.map((item: ICDLRecipient) => ({
    label: `${item.firstName} ${item.lastName}`,
    value: item.synced,
    id: item.id,
  }));

export const pending: GetFromState<boolean, ICDLRefreshScheduleState> = (
  state: ICDLRefreshScheduleState,
): boolean => state && state.pending;
