import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "isBoolean",
})
export class IsBooleanPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (typeof value === "boolean") {
      return value ? "Yes" : "No";
    }

    return value;
  }
}
