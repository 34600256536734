import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IListIdWithActiveTab } from "@modules/cdl/cdl-list/interfaces";
import {
  IChangeListRecordsType,
  IChangeListType,
  IListData,
  IListRequest,
} from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";

import { SEGMENT_TYPES } from "../../constants";

enum ACTION_TYPES {
  GET_CDL_LIST = "[CDL LIST]: Get list",
  GET_CDL_LIST_ERROR = "[CDL LIST]: Get list error",
  GET_CDL_LIST_SUCCESS = "[CDL LIST]: Get list success",

  RENAME_CDL_LIST = "[CDL LIST]: list rename list",
  RENAME_CDL_LIST_ERROR = "[CDL LIST]: list rename list error",
  RENAME_CDL_LIST_SUCCESS = "[CDL LIST]: list rename list success",
  RENAME_CDL_LIST_CANCEL = "[CDL LIST]: list rename list cancel",

  CHANGE_CDL_LIST_TYPE = "[CDL LIST]: Change list type",
  CHANGE_CDL_LIST_TYPE_ERROR = "[CDL LIST]: Change list type error",
  CHANGE_CDL_LIST_TYPE_SUCCESS = "[CDL LIST]: Change list type success",

  SHOW_POP_UP_CHANGE_RECORDS_TYPE = "[CDL LIST]: show pop up change record type",
  CHANGE_CDL_LIST_RECORDS_TYPE = "[CDL LIST]: Change list records type",
  CHANGE_CDL_LIST_RECORDS_TYPE_ERROR = "[CDL LIST]: Change list records type error",
  CHANGE_CDL_LIST_RECORDS_TYPE_SUCCESS = "[CDL LIST]: Change list records type success",

  RELOAD_CDL_LIST = "[CDL LIST]: Reload CDL list",
  RELOAD_CDL_LIST_ERROR = "[CDL LIST]: Reload CDL list error",
  RELOAD_CDL_LIST_SUCCESS = "[CDL LIST]: Reload CDL list success",

  RELOAD_CDL_LIST_WITH_REDIRECT = "[CDL LIST] Reload list with redirect to current segment",
  RELOAD_CDL_LIST_WITH_REDIRECT_SUCCESS = "[CDL LIST] Reload list with redirect to current segment success",

  RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT = "[CDL LIST] Reload list with redirect to first segment",
  RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT_ERROR = "[CDL LIST] Reload list with redirect to first segment error",
  RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT_SUCCESS = "[CDL LIST] Reload list with redirect to first segment success",

  CHANGES_START = "[CDL LIST]: Changes start",
  CHANGES_END = "[CDL LIST]: Changes end",

  CALCULATE_CDL_LIST = "[CDL LIST] Calculate",
  CALCULATE_CDL_LIST_ERROR = "[CDL LIST] Calculate Error",
  CALCULATE_CDL_LIST_SUCCESS = "[CDL LIST] Calculate Success",

  SET_ACTIVE_TAB = "[CDL LIST] set active tab",
  SET_ACTIVE_TAB_WITHOUT_RELOAD = "[CDL LIST] set active tab without reload",

  UPDATE_CDL_LIST = "[CDL LIST]: Update CDL list",

  SOCKET_CDL_LIST_COUNTS_REFRESHED = "[CDL LIST]: Socket counts refreshed",

  RESET = "[CDL LIST]: reset store",
}

export const getCDLListAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST,
  IListRequest
> = createAction(ACTION_TYPES.GET_CDL_LIST, createPayload<IListRequest>());
export const getCDLListErrorAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const getCDLListSuccessAction: CreateActionType<
  ACTION_TYPES.GET_CDL_LIST_SUCCESS,
  IListData
> = createAction(ACTION_TYPES.GET_CDL_LIST_SUCCESS, createPayload<IListData>());

export const renameCDLListAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST,
  string
> = createAction(ACTION_TYPES.RENAME_CDL_LIST, createPayload<string>()); // list name
export const renameCDLListErrorAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RENAME_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const renameCDLListSuccessAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.RENAME_CDL_LIST_SUCCESS,
  createPayload<IListData>(),
);
export const renameCDLListCancelAction: ActionCreator<
  ACTION_TYPES.RENAME_CDL_LIST_CANCEL,
  () => TypedAction<ACTION_TYPES.RENAME_CDL_LIST_CANCEL>
> = createAction(ACTION_TYPES.RENAME_CDL_LIST_CANCEL);

export const changeCDLListTypeAction: CreateActionType<
  ACTION_TYPES.CHANGE_CDL_LIST_TYPE,
  IChangeListType
> = createAction(
  ACTION_TYPES.CHANGE_CDL_LIST_TYPE,
  createPayload<IChangeListType>(),
);
export const changeCDLListTypeErrorAction: CreateActionType<
  ACTION_TYPES.CHANGE_CDL_LIST_TYPE_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CHANGE_CDL_LIST_TYPE_ERROR,
  createPayload<IServerError>(),
);
export const changeCDLListTypeSuccessAction: CreateActionType<
  ACTION_TYPES.CHANGE_CDL_LIST_TYPE_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.CHANGE_CDL_LIST_TYPE_SUCCESS,
  createPayload<IListData>(),
);

export const showPopUpChangeRecordTypeAction: CreateActionType<
  ACTION_TYPES.SHOW_POP_UP_CHANGE_RECORDS_TYPE,
  IChangeListRecordsType
> = createAction(
  ACTION_TYPES.SHOW_POP_UP_CHANGE_RECORDS_TYPE,
  createPayload<IChangeListRecordsType>(),
);
export const changeCDLListRecordsTypeAction: CreateActionType<
  ACTION_TYPES.CHANGE_CDL_LIST_RECORDS_TYPE,
  IChangeListRecordsType
> = createAction(
  ACTION_TYPES.CHANGE_CDL_LIST_RECORDS_TYPE,
  createPayload<IChangeListRecordsType>(),
);
export const changeCDLListRecordsTypeErrorAction: CreateActionType<
  ACTION_TYPES.CHANGE_CDL_LIST_RECORDS_TYPE_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CHANGE_CDL_LIST_RECORDS_TYPE_ERROR,
  createPayload<IServerError>(),
);
export const changeCDLListRecordsTypeSuccessAction: CreateActionType<
  ACTION_TYPES.CHANGE_CDL_LIST_RECORDS_TYPE_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.CHANGE_CDL_LIST_RECORDS_TYPE_SUCCESS,
  createPayload<IListData>(),
);

export const reloadCDLListAction: ActionCreator<
  ACTION_TYPES.RELOAD_CDL_LIST,
  () => TypedAction<ACTION_TYPES.RELOAD_CDL_LIST>
> = createAction(ACTION_TYPES.RELOAD_CDL_LIST);
export const reloadCDLListErrorAction: CreateActionType<
  ACTION_TYPES.RELOAD_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RELOAD_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const reloadCDLListSuccessAction: CreateActionType<
  ACTION_TYPES.RELOAD_CDL_LIST_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.RELOAD_CDL_LIST_SUCCESS,
  createPayload<IListData>(),
);

export const reloadCDLListWithRedirectAction: ActionCreator<
  ACTION_TYPES.RELOAD_CDL_LIST_WITH_REDIRECT,
  () => TypedAction<ACTION_TYPES.RELOAD_CDL_LIST_WITH_REDIRECT>
> = createAction(ACTION_TYPES.RELOAD_CDL_LIST_WITH_REDIRECT);
export const reloadCDLListWithRedirectSuccessAction: CreateActionType<
  ACTION_TYPES.RELOAD_CDL_LIST_WITH_REDIRECT_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.RELOAD_CDL_LIST_WITH_REDIRECT_SUCCESS,
  createPayload<IListData>(),
);

export const reloadCDLListWithRedirectToFirstSegmentAction: ActionCreator<
  ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT,
  () => TypedAction<ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT>
> = createAction(ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT);
export const reloadCDLListWithRedirectToFirstSegmentErrorAction: CreateActionType<
  ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT_ERROR,
  createPayload<IServerError>(),
);
export const reloadCDLListWithRedirectToFirstSegmentSuccessAction: CreateActionType<
  ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.RELOAD_LIST_WITH_REDIRECT_TO_FIRST_SEGMENT_SUCCESS,
  createPayload<IListData>(),
);

export const cdlChangesStartAction: ActionCreator<
  ACTION_TYPES.CHANGES_START,
  () => TypedAction<ACTION_TYPES.CHANGES_START>
> = createAction(ACTION_TYPES.CHANGES_START);
export const cdlChangesEndAction: ActionCreator<
  ACTION_TYPES.CHANGES_END,
  () => TypedAction<ACTION_TYPES.CHANGES_END>
> = createAction(ACTION_TYPES.CHANGES_END);

export const calculateCDLListAction: ActionCreator<
  ACTION_TYPES.CALCULATE_CDL_LIST,
  () => TypedAction<ACTION_TYPES.CALCULATE_CDL_LIST>
> = createAction(ACTION_TYPES.CALCULATE_CDL_LIST);
export const calculateCDLListErrorAction: CreateActionType<
  ACTION_TYPES.CALCULATE_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CALCULATE_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const calculateCDLListSuccessAction: ActionCreator<
  ACTION_TYPES.CALCULATE_CDL_LIST_SUCCESS,
  () => TypedAction<ACTION_TYPES.CALCULATE_CDL_LIST_SUCCESS>
> = createAction(ACTION_TYPES.CALCULATE_CDL_LIST_SUCCESS);

export const setCDLActiveTabAction: CreateActionType<
  ACTION_TYPES.SET_ACTIVE_TAB,
  SEGMENT_TYPES
> = createAction(ACTION_TYPES.SET_ACTIVE_TAB, createPayload<SEGMENT_TYPES>());
export const setCDLActiveTabWithoutReloadAction: CreateActionType<
  ACTION_TYPES.SET_ACTIVE_TAB_WITHOUT_RELOAD,
  IListIdWithActiveTab
> = createAction(
  ACTION_TYPES.SET_ACTIVE_TAB_WITHOUT_RELOAD,
  createPayload<IListIdWithActiveTab>(),
);

export const updateCDLListStateAction: CreateActionType<
  ACTION_TYPES.UPDATE_CDL_LIST,
  Partial<IListData> | IListData
> = createAction(
  ACTION_TYPES.UPDATE_CDL_LIST,
  createPayload<Partial<IListData> | IListData>(),
);

export const socketCDLListCountsRefreshedAction: ActionCreator<
  ACTION_TYPES.SOCKET_CDL_LIST_COUNTS_REFRESHED,
  () => TypedAction<ACTION_TYPES.SOCKET_CDL_LIST_COUNTS_REFRESHED>
> = createAction(ACTION_TYPES.SOCKET_CDL_LIST_COUNTS_REFRESHED);

export const resetCDLStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
