import { HttpParams } from "@angular/common/http";

export function objectToQueryParams(obj: object): HttpParams {
  return Object.entries(obj).reduce(
    (_params: HttpParams, [key, value]: [string, any]) => {
      return value !== "" ? _params.set(key, value) : _params;
    },
    new HttpParams(),
  );
}
