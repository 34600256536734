import { IBidDocument } from "../interfaces/bid-profile";

import {
  DOCUMENT_IMG_DEFAULT,
  DOCUMENTS_IMG,
  IDocumentImage,
} from "../constants/bid-documents";

export class MapDocument {
  extension: string;
  filename: string;
  url: string;
  img: string | null;

  constructor(document: IBidDocument) {
    const { extension, filename, url }: IBidDocument = document;

    this.extension = extension;
    this.filename = filename;
    this.url = url;
    this.img = getDocumentImgByExtension(extension);
  }
}

export const getDocumentImgByExtension: (extension: string) => string = (
  extension: string,
) => {
  const found: IDocumentImage = DOCUMENTS_IMG.find((item: any) =>
    item.extension.includes(extension),
  );
  return (found && found.img) || DOCUMENT_IMG_DEFAULT;
};
