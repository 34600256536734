import {
  IAccountSeat,
  IAccountSeats,
  IAccountSeatState,
  IAccountSeatStates,
  IAccountUserAddOn,
  IAccountUserAddOns,
  IAccountUserSeat,
  IAccountUserSeats,
  IOpenSeatAddOns,
  SEATS,
} from "../interfaces/marketview";

import {
  DO_NOT_AVAILABLE_SEAT,
  SEAT_NAMES,
  SEAT_NATIONAL_ADD_ON_IDS,
  SEAT_STATE_ADD_ON_IDS,
  SEATS_BIDS_AND_GRANTS,
} from "../constants/products";

export class MapSeat {
  value: SEATS;
  label: string;
  disabled: boolean;
  description: string;

  constructor(seat: IAccountSeat, selectedSeat: SEATS = null) {
    const { name, id }: IAccountSeat = seat;

    this.value = id;
    this.label = name;
    this.disabled = checkSeatIsDisable(seat, selectedSeat);
    this.description = this.disabled ? DO_NOT_AVAILABLE_SEAT : "";
  }
}

export const isDisabledSeat: (seat: IAccountSeat) => boolean = (
  seat: IAccountSeat,
): boolean => {
  const { assigned, total, id }: IAccountSeat = seat;
  return (
    id !== SEATS.Lite &&
    (!total || assigned >= total) &&
    !SEAT_NATIONAL_ADD_ON_IDS.includes(id) &&
    !SEAT_STATE_ADD_ON_IDS.includes(id)
  );
};

export function checkSeatIsDisable(
  seat: IAccountSeat,
  selectedSeat: SEATS,
): boolean {
  const { id }: IAccountSeat = seat;

  if (selectedSeat) {
    return id !== selectedSeat && isDisabledSeat(seat);
  }

  return isDisabledSeat(seat);
}

export const calcSeatStates: (states: IAccountSeatStates) => number = (
  states: IAccountSeatStates,
): number =>
  states.reduce(
    (acc: number, item: IAccountSeatState): number => (acc += item.count),
    0 || 0,
  );

export const calcUnassignedSeats: (
  total: number,
  assigned: number,
  states: IAccountSeatStates,
) => number = (
  total: number,
  assigned: number,
  states: IAccountSeatStates,
): number =>
  total -
  assigned -
  (states.reduce(
    (acc: number, item: IAccountSeatState): number => (acc += item.count),
    0,
  ) || 0);

export const mapStateAvailable: (
  _seatStates: IAccountSeatStates,
) => number[] = (_seatStates: IAccountSeatStates): number[] => {
  let result: number[] = [];

  _seatStates.forEach((item: IAccountSeatState): void => {
    const arr: number[] = new Array(item.count);
    result = [...result, ...Array.from(arr, (): number => item.id)];
  });

  return result;
};

export const countOpenedAddOns: (seats: IAccountSeats) => IOpenSeatAddOns = (
  seats: IAccountSeats,
): IOpenSeatAddOns => {
  const bidNational: IAccountSeat = seats.find(
    (seat: IAccountSeat): boolean => seat.id === SEATS.BidsAddOn,
  );
  const grantNational: IAccountSeat = seats.find(
    (seat: IAccountSeat): boolean => seat.id === SEATS.GrantsAddOn,
  );
  const bidStateAvailable: IAccountSeat = seats.find(
    (seat: IAccountSeat): boolean => seat.id === SEATS.BidsStateAddOn,
  );
  const grantStateAvailable: IAccountSeat = seats.find(
    (seat: IAccountSeat): boolean => seat.id === SEATS.GrantsStateAddOn,
  );

  return {
    bidNational:
      (bidNational &&
        bidNational.total - bidNational.assigned > 0 &&
        bidNational.total - bidNational.assigned) ||
      0,
    grantNational:
      (grantNational &&
        grantNational.total - grantNational.assigned > 0 &&
        grantNational.total - grantNational.assigned) ||
      0,
    bidStateAvailable:
      (bidStateAvailable && mapStateAvailable(bidStateAvailable.states)) || [],
    grantStateAvailable:
      (grantStateAvailable && mapStateAvailable(grantStateAvailable.states)) ||
      [],
    bidStateUnassigned: calcUnassignedSeats(
      bidStateAvailable.total,
      bidStateAvailable.assigned,
      bidStateAvailable.states,
    ),
    grantStateUnassigned: calcUnassignedSeats(
      grantStateAvailable.total,
      grantStateAvailable.assigned,
      grantStateAvailable.states,
    ),
  };
};

const INIT_VALUES_BIDS: IAccountSeat = {
  name: SEAT_NAMES[SEATS.BidsAddOn],
  typeNational: SEATS.BidsAddOn,
  id: SEATS.BidsStateAddOn,
  total: 0,
  assigned: 0,
  unassigned: 0,
  states: [],
  statesNational: [],
  openNational: 0,
};

const INIT_VALUES_GRANTS: IAccountSeat = {
  name: SEAT_NAMES[SEATS.GrantsAddOn],
  typeNational: SEATS.GrantsAddOn,
  id: SEATS.GrantsStateAddOn,
  total: 0,
  assigned: 0,
  unassigned: 0,
  states: [],
  statesNational: [],
  openNational: 0,
};

export const getCompareAddons: (
  seats: IAccountSeat[],
  seatTypeNational: SEATS,
  seatTypeState: SEATS,
  initValue: IAccountSeat,
) => IAccountSeat = (
  seats: IAccountSeat[],
  seatTypeNational: SEATS,
  seatTypeState: SEATS,
  initValue: IAccountSeat,
): IAccountSeat =>
  seats
    .filter(
      (seat: IAccountSeat): boolean =>
        seat.id === seatTypeNational || seat.id === seatTypeState,
    )
    .reduce((prev: IAccountSeat, current: IAccountSeat): IAccountSeat => {
      const addOn: IAccountSeat = {
        ...prev,
        total: prev.total + current.total,
        assigned: prev.assigned + current.assigned,
      };

      if (seatTypeNational === current.id) {
        addOn.openNational = current.total - current.assigned;
        addOn.statesNational = [...current.states];
      }

      if (seatTypeState === current.id) {
        addOn.unassigned = calcUnassignedSeats(
          current.total,
          current.assigned,
          current.states,
        );
        addOn.states = [...current.states];
      }

      return addOn;
    }, initValue);

export const mapSeatItemsAndCompareAddons: (
  seats: IAccountSeat[],
) => IAccountSeat[] = (seats: IAccountSeat[]): IAccountSeat[] => {
  if (seats.length) {
    const seatsBase: IAccountSeat[] = [
      ...seats.filter(
        (seat: IAccountSeat): boolean =>
          seat.id !== SEATS.State && !SEATS_BIDS_AND_GRANTS.includes(seat.id),
      ),
    ];

    const state: IAccountSeat[] = seats
      .filter((seat: IAccountSeat): boolean => seat.id === SEATS.State)
      .map(
        (seat: IAccountSeat): IAccountSeat => ({
          ...seat,
          unassigned: calcUnassignedSeats(
            seat.total,
            seat.assigned,
            seat.states,
          ),
        }),
      );

    const bids: IAccountSeat = getCompareAddons(
      seats,
      SEATS.BidsAddOn,
      SEATS.BidsStateAddOn,
      INIT_VALUES_BIDS,
    );
    const grants: IAccountSeat = getCompareAddons(
      seats,
      SEATS.GrantsAddOn,
      SEATS.GrantsStateAddOn,
      INIT_VALUES_GRANTS,
    );

    return [...seatsBase, ...state, bids, grants];
  }

  return [];
};

export const createAddOns: (addOns: IAccountUserAddOns) => number[] = (
  addOns: IAccountUserAddOns,
): number[] =>
  Array.from(new Set(addOns.map((item: IAccountUserAddOn): number => item.id)));

export const createStateIds: (seats: IAccountUserSeats) => number[] = (
  seats: IAccountUserSeats,
): number[] =>
  Array.from(
    new Set(
      seats
        .filter(
          (item: IAccountUserSeat): boolean => typeof item.stateId === "number",
        )
        .map((item: IAccountUserSeat): number => item.stateId),
    ),
  );

export const createBidStateIds: (addOns: IAccountUserAddOns) => number[] = (
  addOns: IAccountUserAddOns,
): number[] =>
  Array.from(
    new Set(
      addOns
        .filter(
          (item: IAccountUserAddOn): boolean =>
            SEATS.BidsStateAddOn === item.id,
        )
        .map((item: IAccountUserAddOn): number => item.stateId),
    ),
  );

export const createGrantStateIds: (addOns: IAccountUserAddOns) => number[] = (
  addOns: IAccountUserAddOns,
): number[] =>
  Array.from(
    new Set(
      addOns
        .filter(
          (item: IAccountUserAddOn): boolean =>
            SEATS.GrantsStateAddOn === item.id,
        )
        .map((item: IAccountUserAddOn): number => item.stateId),
    ),
  );
