import { ModuleWithProviders, NgModule } from "@angular/core";
import { GAIN_SIGHT_ANALYTICS_STATE } from "./constants/gainsight-analytics";
import { GainSightAnalyticsTagService } from "./services/gain-sight-analytics-tag.service";

@NgModule({
  providers: [GainSightAnalyticsTagService],
})
export class GainSightAnalyticsModule {
  static forRoot(
    state: boolean,
  ): ModuleWithProviders<GainSightAnalyticsModule> {
    return {
      ngModule: GainSightAnalyticsModule,
      providers: [
        {
          provide: GAIN_SIGHT_ANALYTICS_STATE,
          useValue: state,
        },
      ],
    };
  }
}
