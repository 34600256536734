import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { Observable } from "rxjs";

import {
  errorHandler,
  mapViewResponse,
  pluckAndCatch,
} from "@shared/utils/response-formater";

import { IServerError } from "@shared/interfaces/server-error";
import { ICreateViewData, IViewData } from "@shared/interfaces/view";

@Injectable()
export class ViewService {
  constructor(private http: HttpClient) {}

  create(data: ICreateViewData): Observable<IViewData | IServerError> {
    return this.http
      .post(environment.api.view.views, data)
      .pipe(mapViewResponse);
  }

  getView(id: number): Observable<IViewData | IServerError> {
    return this.http
      .get(`${environment.api.view.views}/${id}`)
      .pipe(mapViewResponse);
  }

  deleteView(viewId: number): Observable<any> {
    return this.http
      .delete(`${environment.api.view.views}/${viewId}`)
      .pipe(errorHandler);
  }

  updateView(
    viewId: number,
    updateData: { [key: string]: any },
  ): Observable<any> {
    return this.http
      .patch(`${environment.api.view.views}/${viewId}`, updateData)
      .pipe(pluckAndCatch);
  }

  duplicate(viewId: number): Observable<void> {
    return this.http
      .post(
        `${environment.api.view.views}/${viewId}/${environment.api.view.duplicate}`,
        null,
      )
      .pipe(errorHandler);
  }
}
