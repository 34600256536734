import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { ICollegeOffice } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[COLLEGE OFFICE] Get",
  GET_ERROR = "[COLLEGE OFFICE] Get error",
  GET_SUCCESS = "[COLLEGE OFFICE] Get success",

  RESET = "[COLLEGE OFFICE] Reset state",
}

export const getCollegeOfficeAction: CreateActionType<
  ACTION_TYPES.GET,
  number
> = createAction(ACTION_TYPES.GET, createPayload<number>());
export const getCollegeOfficeErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getCollegeOfficeSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  ICollegeOffice
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<ICollegeOffice>());

export const resetCollegeOfficeStateAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
