import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

enum ACTION_TYPES {
  OPEN = "[DASHBOARD LISTS]: Open search",
  CLOSE = "[DASHBOARD LISTS]: Close search",

  UPDATE = "[DASHBOARD LISTS]: update search",
  UPDATE_SUCCESS = "[DASHBOARD LISTS]: update search success",
  RESET = "[DASHBOARD LISTS]: reset query",

  RESET_STATE_WITHOUT_RELOAD = "[DASHBOARD LISTS]: reset state without reload",
}

export const openDashboardListsSearchAction: ActionCreator<
  ACTION_TYPES.OPEN,
  () => TypedAction<ACTION_TYPES.OPEN>
> = createAction(ACTION_TYPES.OPEN);
export const closeDashboardListsSearchAction: ActionCreator<
  ACTION_TYPES.CLOSE,
  () => TypedAction<ACTION_TYPES.CLOSE>
> = createAction(ACTION_TYPES.CLOSE);

export const updateDashboardListsSearchAction: CreateActionType<
  ACTION_TYPES.UPDATE,
  string
> = createAction(ACTION_TYPES.UPDATE, createPayload<string>());
export const updateDashboardListsSearchSuccessAction: ActionCreator<
  ACTION_TYPES.UPDATE_SUCCESS,
  () => TypedAction<ACTION_TYPES.UPDATE_SUCCESS>
> = createAction(ACTION_TYPES.UPDATE_SUCCESS);

export const resetDashboardListsSearchAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);

export const resetDashboardListsSearchWithoutReloadAction: ActionCreator<
  ACTION_TYPES.RESET_STATE_WITHOUT_RELOAD,
  () => TypedAction<ACTION_TYPES.RESET_STATE_WITHOUT_RELOAD>
> = createAction(ACTION_TYPES.RESET_STATE_WITHOUT_RELOAD);
