import { ModuleWithProviders, NgModule } from "@angular/core";

import { ProgressBarComponent } from "./components/progress-bar/progress-bar.component";
import { ProgressStatusComponent } from "./components/progress-status/progress-status.component";

import { UploadDirective } from "./directives/upload.directive";

import { UploadResolver } from "./services/upload-resolver";
import { UploadService } from "./services/upload.service";

import { UPLOAD_URL } from "./provider/token";

const declarations: any[] = [
  ProgressBarComponent,
  ProgressStatusComponent,
  UploadDirective,
];

@NgModule({
  imports: [],
  declarations: [...declarations],
  providers: [UploadService, UploadResolver],
  exports: [...declarations],
})
export class UploadModule {
  static forRoot(uploadUrl: string = ""): ModuleWithProviders<UploadModule> {
    return {
      ngModule: UploadModule,
      providers: [
        {
          provide: UPLOAD_URL,
          useValue: uploadUrl,
        },
      ],
    };
  }
}
