import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ArrayToStringPipe } from "./pipes/array-to-string.pipe";
import { CapitalizeAllPipe } from "./pipes/capitalize-all.pipe";
import { LineSlicePipe } from "./pipes/line-slice.pipe";
import { LowerCaseBySeparatorPipe } from "./pipes/lower-case-by-separator.pipe";
import { UppercaseByValuesPipe } from "./pipes/uppercase-by-values.pipe";
import { UppercaseMaskPipe } from "./pipes/uppercase-mask.pipe";

const _pipes: any = [
  ArrayToStringPipe,
  CapitalizeAllPipe,
  LineSlicePipe,
  UppercaseByValuesPipe,
  UppercaseMaskPipe,
  LowerCaseBySeparatorPipe,
];

@NgModule({
  declarations: [..._pipes],
  imports: [CommonModule],
  exports: [..._pipes],
})
export class StringPipesModule {}
