import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  collegeOfficeReducer,
  ICollegeOfficeState,
} from "./college-office.reducer";

export interface ICollegeOfficeModuleState {
  profile: ICollegeOfficeState;
}

const collegeOfficeModuleReducers: ActionReducerMap<ICollegeOfficeModuleState> =
  {
    profile: collegeOfficeReducer,
  };

// eslint-disable-next-line
export const COLLEGE_OFFICE_MODULE_STATE_NAME = 'CollegeOffice';
export const COLLEGE_OFFICE_MODULE_STATE: InjectionToken<
  ActionReducerMap<ICollegeOfficeModuleState>
> = new InjectionToken<ActionReducerMap<ICollegeOfficeModuleState>>(
  COLLEGE_OFFICE_MODULE_STATE_NAME,
  {
    factory: () => collegeOfficeModuleReducers,
  },
);
