import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Route,
  RouterStateSnapshot,
} from "@angular/router";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { take, tap } from "rxjs/operators";

import { getCanManageGrants } from "@modules/profile/store/selectors/profile.selector";
import { go } from "../store/actions/router-history.action";
import { CoreState } from "../store/reducers";

@Injectable()
export class GrantsPermissionsGuard {
  private _next(): Observable<boolean> {
    return this.store.pipe(
      select(getCanManageGrants),
      take(1),
      tap((canManageGrants: boolean) => {
        if (!canManageGrants) {
          this.store.dispatch(go("/"));
        }
      }),
    );
  }

  constructor(private store: Store<CoreState>) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._next();
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    return this._next();
  }
}
