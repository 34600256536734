import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-personnel-districts.action";

import { IPersonnelRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { PERSONNEL_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsPersonnelDistrictsState {
  districts: IPersonnelRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IQuickSearchResultsPersonnelDistrictsState = {
  districts: [],
  pending: false,
  error: null,

  count: 0,
};

const quickSearchPersonnelDistricts: OnReducer<
  IQuickSearchResultsPersonnelDistrictsState,
  ActionType<any>
> = (state: IQuickSearchResultsPersonnelDistrictsState) => ({
  ...state,
  pending: true,
  error: null,
});

const quickSearchPersonnelDistrictsError: OnReducer<
  IQuickSearchResultsPersonnelDistrictsState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsPersonnelDistrictsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const quickSearchPersonnelDistrictsSuccess: OnReducer<
  IQuickSearchResultsPersonnelDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsPersonnelDistrictsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  districts: [...payload.items],
  pending: false,
  count: payload.count,
});

const setQuickSearchPersonnelDistrictsSpinnerState: OnReducer<
  IQuickSearchResultsPersonnelDistrictsState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsPersonnelDistrictsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetQuickSearchPersonnelDistrictsState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsPersonnelDistrictsState> =
  createReducer<IQuickSearchResultsPersonnelDistrictsState>(
    initialState,

    on(
      actions.getQuickSearchPersonnelDistrictsAction,
      quickSearchPersonnelDistricts,
    ),
    on(
      actions.getQuickSearchPersonnelDistrictsErrorAction,
      quickSearchPersonnelDistrictsError,
    ),
    on(
      actions.getQuickSearchPersonnelDistrictsSuccessAction,
      quickSearchPersonnelDistrictsSuccess,
    ),

    on(
      actions.changeQuickSearchPersonnelDistrictsPageAction,
      quickSearchPersonnelDistricts,
    ),
    on(
      actions.changeQuickSearchPersonnelDistrictsQueryAction,
      quickSearchPersonnelDistricts,
    ),

    on(
      actions.setQuickSearchPersonnelDistrictsSpinnerStateAction,
      setQuickSearchPersonnelDistrictsSpinnerState,
    ),

    on(
      actions.resetQuickSearchPersonnelDistrictsStateAction,
      resetQuickSearchPersonnelDistrictsState,
    ),
  );

export function quickSearchResultsPersonnelDistrictReducer(
  state: IQuickSearchResultsPersonnelDistrictsState,
  action: Action,
): IQuickSearchResultsPersonnelDistrictsState {
  return reducer(state, action);
}

export const districts: GetFromState<
  IPersonnelRecord[],
  IQuickSearchResultsPersonnelDistrictsState
> = (state: IQuickSearchResultsPersonnelDistrictsState): IPersonnelRecord[] =>
  state.districts;
export const pending: GetFromState<
  boolean,
  IQuickSearchResultsPersonnelDistrictsState
> = (state: IQuickSearchResultsPersonnelDistrictsState): boolean =>
  state.pending;

export const districtsPaginationCount: GetFromState<
  number,
  IQuickSearchResultsPersonnelDistrictsState
> = (state: IQuickSearchResultsPersonnelDistrictsState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  const { sortBy }: Params = queryParams;

  return {
    ...PERSONNEL_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    sortBy: sortBy || "lastName",
    filterBy: "districts",
  };
};
