import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  collegeCampusesReducer,
  ICollegeCampusesState,
} from "./college-campuses.reducer";
import {
  collegeProfileReducer,
  ICollegeProfileState,
} from "./college-profile.reducer";

export interface ICollegeModuleState {
  profile: ICollegeProfileState;
  campuses: ICollegeCampusesState;
}

const collegeModuleReducers: ActionReducerMap<ICollegeModuleState> = {
  profile: collegeProfileReducer,
  campuses: collegeCampusesReducer,
};

// eslint-disable-next-line
export const COLLEGE_STATE_NAME = 'College';
export const COLLEGE_STATE: InjectionToken<
  ActionReducerMap<ICollegeModuleState>
> = new InjectionToken<ActionReducerMap<ICollegeModuleState>>(
  COLLEGE_STATE_NAME,
  { factory: () => collegeModuleReducers },
);
