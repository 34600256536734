import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
} from "@angular/forms";
import { SafeHtml } from "@angular/platform-browser";
import { select, Store } from "@ngrx/store";

import { Observable, Subject } from "rxjs";
import { filter, takeUntil } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import {
  changeRelatedToFieldsAction,
  platformAuthAction,
} from "@modules/oms/store/actions/oms.action";
import {
  getAvailablePlatformFields,
  getOmsPlatformAuthError,
  getOmsPlatformAuthLoading,
} from "@modules/oms/store/selectors/oms.selector";

import { OmsService } from "@modules/oms/services/oms.service";

import {
  IItemsData,
  IItemData,
  IOmsAuthPayload,
  IOmsAuthPopUpData,
  IOmsField,
  IOmsFields,
} from "@modules/oms/interfaces";
import { IControlOption, IControlOptions } from "@shared/interfaces/forms";
import { IServerError } from "@shared/interfaces/server-error";

import { PopUpRef } from "../../models/pop-up-ref";

import { OMS_FIELD_TYPES, RELATED_TO_FIELDS } from "@modules/oms/constants";
import { FLAT_INPUT_THEME } from "@shared/constants/flat-input";
import { CLOUD_SYNC_ENVIRONMENTS } from "../../constants/pop-up-data";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-oms-auth-pop-up",
  templateUrl: "./oms-auth-pop-up.component.html",
  styleUrls: ["./oms-auth-pop-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OmsAuthPopUpComponent implements OnInit, OnDestroy {
  readonly platformEnvironment: IControlOption[] = CLOUD_SYNC_ENVIRONMENTS;
  readonly fieldType: typeof OMS_FIELD_TYPES = OMS_FIELD_TYPES;
  readonly relatedToFields: string[] = RELATED_TO_FIELDS;
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  private _destroyer$: Subject<void> = new Subject<void>();

  platformFields$: Observable<IOmsFields> = this._store.pipe(
    select(getAvailablePlatformFields),
  );
  authError$: Observable<IServerError | null> = this._store.pipe(
    select(getOmsPlatformAuthError),
  );
  authLoading$: Observable<boolean> = this._store.pipe(
    select(getOmsPlatformAuthLoading),
  );

  isShowHelpContent: boolean = false;
  heldContent: SafeHtml;
  platformFields: IOmsFields = [];
  form: UntypedFormGroup;

  constructor(
    @Inject(POP_UP_DATA) public data: IOmsAuthPopUpData,
    private _popUpRef: PopUpRef<OmsAuthPopUpComponent>,
    private _fb: UntypedFormBuilder,
    private _store: Store<CoreState>,
    private _service: OmsService,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.platformFields$
      .pipe(takeUntil(this._destroyer$))
      .subscribe((_fields: IOmsFields) => {
        this.platformFields = _fields;
        this.form = this._service.createOmsAuthForm(
          _fields,
          this.data.sandbox,
          this.form ? this.form.value : null,
        );
        this._cdr.markForCheck();
      });

    this.authError$
      .pipe(
        takeUntil(this._destroyer$),
        filter((error: IServerError) => error && !!error.errors),
      )
      .subscribe((error: IServerError) => this._setErrors(error));
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  onShowHelpContent(content: SafeHtml): void {
    this.isShowHelpContent = true;
    this.heldContent = content;
    this._cdr.detectChanges();
  }

  onClose(): void {
    if (this.isShowHelpContent) {
      this.isShowHelpContent = false;
      this._cdr.detectChanges();
      return;
    }

    this._popUpRef.close();
  }

  onChangeRelatedTo(selectedValue: string): void {
    this._store.dispatch(changeRelatedToFieldsAction(selectedValue));
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    const { sandbox, accountNumber, ...form }: IOmsAuthPayload =
      this.form.value;

    for (const field in form) {
      if (!form[field]) {
        delete form[field];
      }
    }

    this._store.dispatch(
      platformAuthAction({
        sandbox,
        accountNumber: +accountNumber,
        credentials: form,
      }),
    );
  }

  parseItemsData(items: IItemsData): IControlOptions {
    return items.map(({ name, value }: IItemData) => ({ label: name, value }));
  }

  onSelect(selectedValue: string, item: IOmsField): void {
    if (this.relatedToFields.indexOf(item.name) !== -1) {
      this.onChangeRelatedTo(selectedValue);
    }
  }

  private _setErrors(error: IServerError): void {
    Object.entries(error.errors).forEach(([key, value]: [string, string[]]) => {
      const control: AbstractControl = this.form.controls[key];

      if (control) {
        control.setErrors({ serverError: value });
        control.markAsTouched();
      }
    });
  }
}
