import { Pipe, PipeTransform } from "@angular/core";

import { HIDDEN_BY } from "@shared/modules/institution-profiles/constants/profiles";

@Pipe({
  name: "isHidden",
})
export class IsHiddenPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value !== HIDDEN_BY.value) {
      return value;
    }

    return `<span class="hidden-value">${value}</span>`;
  }
}
