import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { IListAppendsState } from "@modules/list-common-store/store/reducers/list-appends.reducer";
import {
  list,
  ICustomerListState,
} from "@modules/list-common-store/store/reducers/list.reducer";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { IListCommonModuleState, LIST_COMMON_STATE_NAME } from "../reducers";

import { IListData } from "@shared/interfaces/list";

export const getListCommonModuleState: GetFromStateInSelectors<
  CoreState,
  IListCommonModuleState
> = createFeatureSelector<IListCommonModuleState>(LIST_COMMON_STATE_NAME);

export const selectListAppendsState: GetFromStateInSelectors<
  CoreState,
  IListAppendsState
> = createSelector(
  getListCommonModuleState,
  (state: IListCommonModuleState) => state && state.customerListAppends,
);

export const getCustomerListState: GetFromStateInSelectors<
  CoreState,
  ICustomerListState
> = createSelector(
  getListCommonModuleState,
  (state: IListCommonModuleState) => state && state.customerList,
);

export const getList: MemoizedSelector<CoreState, IListData> = createSelector(
  getCustomerListState,
  list,
);
