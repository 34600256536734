import { Pipe, PipeTransform } from "@angular/core";

import { IListType } from "@shared/interfaces/list";

import { LIST_TYPES_DESC } from "@shared/constants/data/list-types";

@Pipe({
  name: "listType",
})
export class ListTypePipe implements PipeTransform {
  transform(id: number): string {
    const currentType: IListType = LIST_TYPES_DESC.find(
      (type: IListType) => type.id === id,
    );
    return (currentType && currentType.name) || "";
  }
}
