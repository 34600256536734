import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IOverview } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[VIEW ALERTS OVERVIEW] get overview",
  GET_ERROR = "[VIEW ALERTS OVERVIEW] get overview error",
  GET_SUCCESS = "[VIEW ALERTS OVERVIEW] get overview success",
}

export const getViewAlertsOverviewAction: CreateActionType<
  ACTION_TYPES.GET,
  number
> = createAction(ACTION_TYPES.GET, createPayload<number>());
export const getViewAlertsOverviewErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getViewAlertsOverviewSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IOverview
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IOverview>());
