import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { Observable } from "rxjs";

import { objectToQueryParams } from "@shared/utils/object-to-query-params";
import { pluckAndCatch } from "@shared/utils/response-formater";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IPromotionDetailsData,
  IPromotionParams,
  IValidatedCartItems,
  IValidateProduct,
} from "../interfaces/e-commerce";
import { ITaxData, ITaxDataResponse } from "../interfaces/price";

@Injectable({
  providedIn: "root",
})
export class CartService {
  constructor(private http: HttpClient) {}

  validateCart(
    cartItems: IValidateProduct[],
  ): Observable<IValidatedCartItems | IServerError> {
    return this.http
      .post(environment.api.eCommerce.cartValidate, { cartItems })
      .pipe(pluckAndCatch);
  }

  promotionDetails(
    promoParams: IPromotionParams,
  ): Observable<IPromotionDetailsData | IServerError> {
    return this.http
      .get(`${environment.api.eCommerce.promoDetails}`, {
        params: objectToQueryParams(promoParams),
      })
      .pipe(pluckAndCatch);
  }

  tax(params: ITaxDataResponse): Observable<ITaxData | IServerError> {
    return this.http
      .post(environment.api.eCommerce.tax, { ...params })
      .pipe(pluckAndCatch);
  }
}
