import { Roles } from "../interfaces/marketview";
import { IRoleConfig } from "../interfaces/roles";

export const ROLES_CONFIG: IRoleConfig = {
  "account-owner": {
    profileTabTitle: "Products & Users",
  },
  "account-admin": {
    profileTabTitle: "Products & Users",
  },
  "account-user": {
    profileTabTitle: "Products",
  },
};

export enum RolesKeys {
  Owner = "account-owner",
  Admin = "account-admin",
  User = "account-user",
}

export enum RolesByKey {
  "account-admin" = Roles.Admin,
  "account-user" = Roles.User,
  "account-owner" = Roles.Owner,
}
