import { Pipe, PipeTransform } from "@angular/core";

import { Mask } from "@shared/interfaces/mask";

@Pipe({
  name: "cvvMask",
})
export class CvvMaskPipe implements PipeTransform, Mask {
  transform(value: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    return value.replace(/(.)/g, "*");
  }

  parse(value: string, oldValue: string): string {
    if (!value || typeof value !== "string") {
      return "";
    }

    const valueLength: number = value.length;
    const valueForReplace: string =
      oldValue.length > valueLength
        ? oldValue.substr(0, valueLength)
        : oldValue + value[valueLength - 1];

    return valueForReplace.replace(/\D+/, "").replace(/(\d{1,4}).*/, "$1");
  }
}
