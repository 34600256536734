import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-institution-counters.action";

import { IServerError } from "@shared/interfaces/server-error";

export interface IQuickSearchInstitutionCountersState {
  colleges: number | null;
  districts: number | null;
  schools: number | null;

  pending: boolean;
  error: IServerError | null;
}

const initialState: IQuickSearchInstitutionCountersState = {
  colleges: null,
  districts: null,
  schools: null,

  pending: false,
  error: null,
};

const getQuickSearchInstitutionCounters: OnReducer<
  IQuickSearchInstitutionCountersState,
  ActionType<any>
> = (state: IQuickSearchInstitutionCountersState) => ({
  ...state,
  pending: true,
  error: null,
});

const getQuickSearchInstitutionCountersError: OnReducer<
  IQuickSearchInstitutionCountersState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchInstitutionCountersState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getQuickSearchInstitutionCountersSuccess: OnReducer<
  IQuickSearchInstitutionCountersState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchInstitutionCountersState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  ...payload,
});

const resetQuickSearchInstitutionCounters: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchInstitutionCountersState> =
  createReducer<IQuickSearchInstitutionCountersState>(
    initialState,

    on(
      actions.getQuickSearchInstitutionCountersAction,
      getQuickSearchInstitutionCounters,
    ),
    on(
      actions.getQuickSearchInstitutionCountersErrorAction,
      getQuickSearchInstitutionCountersError,
    ),
    on(
      actions.getQuickSearchInstitutionCountersSuccessAction,
      getQuickSearchInstitutionCountersSuccess,
    ),

    on(
      actions.resetQuickSearchInstitutionCountersAction,
      resetQuickSearchInstitutionCounters,
    ),
  );

export function quickSearchInstitutionCountersReducer(
  state: IQuickSearchInstitutionCountersState,
  action: Action,
): IQuickSearchInstitutionCountersState {
  return reducer(state, action);
}

export const colleges: GetFromState<
  number,
  IQuickSearchInstitutionCountersState
> = ({ colleges: _colleges }: IQuickSearchInstitutionCountersState): number =>
  _colleges;
export const districts: GetFromState<
  number,
  IQuickSearchInstitutionCountersState
> = ({ districts: _districts }: IQuickSearchInstitutionCountersState): number =>
  _districts;
export const schools: GetFromState<
  number,
  IQuickSearchInstitutionCountersState
> = ({ schools: _schools }: IQuickSearchInstitutionCountersState): number =>
  _schools;

export const pending: GetFromState<
  boolean,
  IQuickSearchInstitutionCountersState
> = ({ pending: _pending }: IQuickSearchInstitutionCountersState): boolean =>
  _pending;
export const error: GetFromState<
  IServerError,
  IQuickSearchInstitutionCountersState
> = ({ error: _error }: IQuickSearchInstitutionCountersState): IServerError =>
  _error;

export const isEmptyResults: (...counters: number[]) => boolean = (
  ...counters: number[]
): boolean =>
  !counters.filter((count: number) => typeof count === "number").length ||
  counters.filter((count: number) => count === 0).length === counters.length;
