import { createAction } from "@ngrx/store";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IAlert, IAlertsLists } from "../../interfaces";

enum ACTION_TYPES {
  GET = "[VIEW ALERTS] get alerts",
  GET_ERROR = "[VIEW ALERTS] get alerts error",
  GET_SUCCESS = "[VIEW ALERTS] get alerts success",

  TOGGLE = "[VIEW ALERTS] toggle alert",
  TOGGLE_ERROR = "[VIEW ALERTS] toggle alert error",
  TOGGLE_SUCCESS = "[VIEW ALERTS] toggle alert success",
}

export const getViewAlertsAction: CreateActionType<ACTION_TYPES.GET, number> =
  createAction(ACTION_TYPES.GET, createPayload<number>());
export const getViewAlertsErrorAction: CreateActionType<
  ACTION_TYPES.GET_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_ERROR, createPayload<IServerError>());
export const getViewAlertsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SUCCESS,
  IAlertsLists
> = createAction(ACTION_TYPES.GET_SUCCESS, createPayload<IAlertsLists>());

export const toggleViewAlertAction: CreateActionType<
  ACTION_TYPES.TOGGLE,
  IAlert
> = createAction(ACTION_TYPES.TOGGLE, createPayload<IAlert>());
export const toggleViewAlertErrorAction: CreateActionType<
  ACTION_TYPES.TOGGLE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.TOGGLE_ERROR, createPayload<IServerError>());
export const toggleViewAlertSuccessAction: CreateActionType<
  ACTION_TYPES.TOGGLE_SUCCESS,
  IAlert
> = createAction(ACTION_TYPES.TOGGLE_SUCCESS, createPayload<IAlert>());
