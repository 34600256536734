import { SORT_DIRECTION } from "@shared/constants/sort";

interface IHistoryParams {
  offset: number;
  limit: number;
  orderBy: SORT_DIRECTION;
  sortBy?: string;
}

export const HISTORY_DEFAULT_OFFSET: number = 0;
export const HISTORY_DEFAULT_LIMIT: number = 25;
export const HISTORY_SORT_BY: string = "syncDate";

export const HISTORY_DEFAULT_PARAMS: IHistoryParams = {
  limit: HISTORY_DEFAULT_LIMIT,
  offset: HISTORY_DEFAULT_OFFSET,
  orderBy: SORT_DIRECTION.ASC,
};

export const HISTORY_DEFAULT_QUERY_PARAMS: IHistoryParams = {
  ...HISTORY_DEFAULT_PARAMS,
  sortBy: HISTORY_SORT_BY,
};
