import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-lists-view.action";

import { SessionStorageService } from "@core/services/session-storage.service";

import { DASHBOARD_VIEW_TYPE } from "../../../dashboard/interfaces";

export interface IDashboardListsViewType {
  viewType: DASHBOARD_VIEW_TYPE;
}

const initialState: IDashboardListsViewType = {
  viewType:
    SessionStorageService.dashboardListsViewType || DASHBOARD_VIEW_TYPE.PRODUCT,
};

const changeDashboardListsViewType: OnReducer<
  IDashboardListsViewType,
  ActionType<Payload<any>>
> = (state: IDashboardListsViewType, { payload }: Payload<any>) => ({
  ...state,
  viewType: payload,
});

export const reducer: ActionReducer<IDashboardListsViewType> =
  createReducer<IDashboardListsViewType>(
    initialState,

    on(
      actions.changeDashboardListsViewTypeAction,
      changeDashboardListsViewType,
    ),
  );

export function dashboardListsViewTypeReducer(
  state: IDashboardListsViewType,
  action: Action,
): IDashboardListsViewType {
  return reducer(state, action);
}

export const dashboardListsViewType: GetFromState<
  DASHBOARD_VIEW_TYPE,
  IDashboardListsViewType
> = (state: IDashboardListsViewType): DASHBOARD_VIEW_TYPE => state.viewType;
