import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view-results-bids.action";

import { IBidsRecords } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { BIDS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IViewResultsBidsState {
  bids: IBidsRecords;
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IViewResultsBidsState = {
  bids: [],
  pending: false,
  error: null,

  count: 0,
};

const getViewResultsBids: OnReducer<IViewResultsBidsState, ActionType<any>> = (
  state: IViewResultsBidsState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getViewResultsBidsError: OnReducer<
  IViewResultsBidsState,
  ActionType<Payload<IServerError>>
> = (state: IViewResultsBidsState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewResultsBidsSuccess: OnReducer<
  IViewResultsBidsState,
  ActionType<Payload<any>>
> = (state: IViewResultsBidsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  bids: [...payload.items],
  count: payload.count,
});

const setViewResultsBidsSpinnerState: OnReducer<
  IViewResultsBidsState,
  ActionType<Payload<any>>
> = (state: IViewResultsBidsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetViewResultsBidsState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IViewResultsBidsState> =
  createReducer<IViewResultsBidsState>(
    initialState,

    on(actions.getViewResultsBidsAction, getViewResultsBids),
    on(actions.getViewResultsBidsErrorAction, getViewResultsBidsError),
    on(actions.getViewResultsBidsSuccessAction, getViewResultsBidsSuccess),

    on(
      actions.setViewResultsBidsSpinnerStateAction,
      setViewResultsBidsSpinnerState,
    ),

    on(actions.resetViewResultsBidsStateAction, resetViewResultsBidsState),
  );

export function viewResultsBidsReducer(
  state: IViewResultsBidsState,
  action: Action,
): IViewResultsBidsState {
  return reducer(state, action);
}

export const bids: GetFromState<IBidsRecords, IViewResultsBidsState> = (
  state: IViewResultsBidsState,
): IBidsRecords => state.bids;
export const bidsPending: GetFromState<boolean, IViewResultsBidsState> = (
  state: IViewResultsBidsState,
): boolean => state.pending;
export const bidsError: GetFromState<IServerError, IViewResultsBidsState> = (
  state: IViewResultsBidsState,
): IServerError => state.error;

export const bidsCount: GetFromState<number, IViewResultsBidsState> = (
  state: IViewResultsBidsState,
): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, number, number> = (
  queryParams: Params,
  viewId: number,
  segmentId: number,
): Params => {
  const { sortBy }: Params = queryParams;

  const params: Params = {
    ...BIDS_DEFAULT_PARAMS,
    ...queryParams,
    sortBy: sortBy || "submittal",
    viewId,
    filterBy: "bids",
  };

  return segmentId ? { ...params, segmentId } : params;
};
