import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";

import { IMarketViewRenewalPopUpData } from "../../interfaces";

import { PopUpRef } from "../../models/pop-up-ref";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-mv-renewal-pop-up",
  templateUrl: "./mv-renewal-pop-up.component.html",
  styleUrls: ["./mv-renewal-pop-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MvRenewalPopUpComponent {
  constructor(
    private ref: PopUpRef<MvRenewalPopUpComponent>,
    @Inject(POP_UP_DATA) public data: IMarketViewRenewalPopUpData,
  ) {}

  onBtnClick(e: Event, checkBoxValue: boolean): void {
    this.ref.close(checkBoxValue);
  }
}
