export const getOmsAuthProcessingMsg: (_platformName: string) => string = (
  _platformName: string,
): string => `Authorization to ${_platformName} platform in processing`;
export const getOmsAuthSuccessMsg: (_platformName: string) => string = (
  _platformName: string,
): string => `Authorization to ${_platformName} success.`;
export const getOmsAuthErrorMsg: (_platformName: string) => string = (
  _platformName: string,
): string => `Credentials for ${_platformName} are not valid.`;

export enum OMS_NOTIFICATIONS_ID {
  AUTH_ID = "auth_id",
}

export enum OMS_FIELD_TYPES {
  EMAIL = "email",
  TEXT = "text",
  PASSWORD = "password",
  DROP_DOWN = "drop-down",
  CHECKBOX = "checkbox",
}

export const RELATED_TO_FIELDS: string[] = ["AuthType", "DeploymentType"]; // 1st one from NetSuite modal, 2nd from Dynamics 365
