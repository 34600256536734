import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { customListReducer, ICustomListState } from "./custom-list.reducer";

export interface ICustomListModuleState {
  customList: ICustomListState;
}

const customListModuleReducer: ActionReducerMap<ICustomListModuleState> = {
  customList: customListReducer,
};

// eslint-disable-next-line
export const CUSTOM_LIST_STATE_NAME = 'customListModule';
export const CUSTOM_LIST_STATE: InjectionToken<
  ActionReducerMap<ICustomListModuleState>
> = new InjectionToken<ActionReducerMap<ICustomListModuleState>>(
  CUSTOM_LIST_STATE_NAME,
  {
    factory: () => customListModuleReducer,
  },
);
