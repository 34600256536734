import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { IDistrictSchoolsResponse } from "@shared/modules/institution-profiles/interfaces/district-school";

enum ACTION_TYPES {
  GET_SCHOOLS = "[SCHOOL DISTRICT SCHOOLS] get district schools",
  GET_SCHOOLS_ERROR = "[SCHOOL DISTRICT SCHOOLS] get district schools Error",
  GET_SCHOOLS_SUCCESS = "[SCHOOL DISTRICT SCHOOLS] get district schools Success",

  SORT_SCHOOLS = "[SCHOOL DISTRICT SCHOOLS] sort district schools",
  SORT_SCHOOLS_ERROR = "[SCHOOL DISTRICT SCHOOLS] sort district schools error",
  SORT_SCHOOLS_SUCCESS = "[SCHOOL DISTRICT SCHOOLS] sort district schools success",

  TOGGLE_SCHOOLS = "[SCHOOL DISTRICT SCHOOLS] toggle district schools",
  TOGGLE_SCHOOLS_SUCCESS = "[SCHOOL DISTRICT SCHOOLS] toggle district schools success",

  SET_CHECKED_SCHOOL = "[SCHOOL DISTRICT SCHOOL] set district checked schools",
  SET_ALL_SCHOOLS_CHECKED = "[SCHOOL DISTRICT SCHOOLS] set all district checked schools",
  RESET_CHECKED_SCHOOLS = "[SCHOOL DISTRICT SCHOOLS] reset checked schools",

  RESET_SCHOOLS_STATE = "[SCHOOL DISTRICT SCHOOLS] reset district schools state",
}

export const getSchoolsAction: CreateActionType<
  ACTION_TYPES.GET_SCHOOLS,
  number
> = createAction(ACTION_TYPES.GET_SCHOOLS, createPayload<number>());
export const getSchoolsErrorAction: CreateActionType<
  ACTION_TYPES.GET_SCHOOLS_ERROR,
  IServerError
> = createAction(ACTION_TYPES.GET_SCHOOLS_ERROR, createPayload<IServerError>());
export const getSchoolsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SCHOOLS_SUCCESS,
  IDistrictSchoolsResponse
> = createAction(
  ACTION_TYPES.GET_SCHOOLS_SUCCESS,
  createPayload<IDistrictSchoolsResponse>(),
);

export const sortSchoolsAction: CreateActionType<
  ACTION_TYPES.SORT_SCHOOLS,
  Params
> = createAction(ACTION_TYPES.SORT_SCHOOLS, createPayload<Params>());
export const sortSchoolsErrorAction: CreateActionType<
  ACTION_TYPES.SORT_SCHOOLS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.SORT_SCHOOLS_ERROR,
  createPayload<IServerError>(),
);
export const sortSchoolsSuccessAction: CreateActionType<
  ACTION_TYPES.SORT_SCHOOLS_SUCCESS,
  IDistrictSchoolsResponse
> = createAction(
  ACTION_TYPES.SORT_SCHOOLS_SUCCESS,
  createPayload<IDistrictSchoolsResponse>(),
);

export const toggleSchoolsAction: ActionCreator<
  ACTION_TYPES.TOGGLE_SCHOOLS,
  () => TypedAction<ACTION_TYPES.TOGGLE_SCHOOLS>
> = createAction(ACTION_TYPES.TOGGLE_SCHOOLS);
export const toggleSchoolsSuccessAction: ActionCreator<
  ACTION_TYPES.TOGGLE_SCHOOLS_SUCCESS,
  () => TypedAction<ACTION_TYPES.TOGGLE_SCHOOLS_SUCCESS>
> = createAction(ACTION_TYPES.TOGGLE_SCHOOLS_SUCCESS);

export const resetCheckedSchoolsAction: ActionCreator<
  ACTION_TYPES.RESET_CHECKED_SCHOOLS,
  () => TypedAction<ACTION_TYPES.RESET_CHECKED_SCHOOLS>
> = createAction(ACTION_TYPES.RESET_CHECKED_SCHOOLS);

export const setCheckedSchoolActions: CreateActionType<
  ACTION_TYPES.SET_CHECKED_SCHOOL,
  number
> = createAction(ACTION_TYPES.SET_CHECKED_SCHOOL, createPayload<number>());

export const checkAllSchoolsAction: CreateActionType<
  ACTION_TYPES.SET_ALL_SCHOOLS_CHECKED,
  boolean
> = createAction(
  ACTION_TYPES.SET_ALL_SCHOOLS_CHECKED,
  createPayload<boolean>(),
);

export const resetSchoolsAction: ActionCreator<
  ACTION_TYPES.RESET_SCHOOLS_STATE,
  () => TypedAction<ACTION_TYPES.RESET_SCHOOLS_STATE>
> = createAction(ACTION_TYPES.RESET_SCHOOLS_STATE);
