import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { ISegmentData } from "@modules/segment/interfaces/segment";
import { IDataListSummary, IListData } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import { ICDLListChangeStatusPayload } from "../../../../dashboard-lists/interfaces";

enum ACTION_TYPES {
  GET_LIST_DATA = "[CDL LIST SUMMARY] get data",
  GET_LIST_DATA_ERROR = "[CDL LIST SUMMARY] get data error",
  GET_LIST_DATA_SUCCESS = "[CDL LIST SUMMARY] get data success",

  GET_SEGMENT_DATA = "[CDL LIST SUMMARY] load segment data",
  GET_SEGMENT_DATA_ERROR = "[CDL LIST SUMMARY] load segment data error",
  GET_SEGMENT_DATA_SUCCESS = "[CDL LIST SUMMARY] load segment data success",

  RENAME_CDL_LIST = "[CDL LIST SUMMARY] rename cdl list",
  RENAME_CDL_LIST_ERROR = "[CDL LIST SUMMARY] rename cdl list error",
  RENAME_CDL_LIST_SUCCESS = "[CDL LIST SUMMARY] rename cdl list success",
  RENAME_CDL_LIST_CANCEL = "[CDL LIST SUMMARY] rename cdl list cancel",

  SHOW_DELETE_CDL_LIST_POP_UP = "[CDL LIST SUMMARY] show delete cdl list pop up",

  DELETE = "[CDL LIST SUMMARY] remove list",
  DELETE_ERROR = "[CDL LIST SUMMARY] remove list error",
  DELETE_SUCCESS = "[CDL LIST SUMMARY] remove list success",

  DUPLICATE = "[CDL LIST SUMMARY] duplicate list",
  DUPLICATE_ERROR = "[CDL LIST SUMMARY] duplicate list error",
  DUPLICATE_SUCCESS = "[CDL LIST SUMMARY] duplicate list success",

  SHOW_CDL_LIST_CHANGE_STATUS_POP_UP = "[CDL LIST SUMMARY] show cdl list change status pop up",

  CDL_LIST_CHANGE_STATUS = "[CDL LIST SUMMARY] cdl list change status",
  CDL_LIST_CHANGE_STATUS_ERROR = "[CDL LIST SUMMARY] cdl list change status error",
  CDL_LIST_CHANGE_STATUS_SUCCESS = "[CDL LIST SUMMARY] cdl list change status success",

  REFRESH = "[CDL LIST REFRESH] cdl list refresh",
  REFRESH_ERROR = "[CDL LIST REFRESH] cdl list refresh error",
  REFRESH_SUCCESS = "[CDL LIST REFRESH] cdl list refresh success",

  RESET = "[CDL LIST SUMMARY] Reset SUMMARY",
}

export const getCDLListSummaryAction: CreateActionType<
  ACTION_TYPES.GET_LIST_DATA,
  number
> = createAction(ACTION_TYPES.GET_LIST_DATA, createPayload<number>()); // listId
export const getCDLListSummaryErrorAction: CreateActionType<
  ACTION_TYPES.GET_LIST_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_LIST_DATA_ERROR,
  createPayload<IServerError>(),
);
export const getCDLListSummarySuccessAction: CreateActionType<
  ACTION_TYPES.GET_LIST_DATA_SUCCESS,
  IDataListSummary
> = createAction(
  ACTION_TYPES.GET_LIST_DATA_SUCCESS,
  createPayload<IDataListSummary>(),
);

export const getCDLSegmentDataAction: CreateActionType<
  ACTION_TYPES.GET_SEGMENT_DATA,
  number
> = createAction(ACTION_TYPES.GET_SEGMENT_DATA, createPayload<number>());
export const getCDLSegmentDataErrorAction: CreateActionType<
  ACTION_TYPES.GET_SEGMENT_DATA_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_SEGMENT_DATA_ERROR,
  createPayload<IServerError>(),
);
export const getCDLSegmentDataSuccessAction: CreateActionType<
  ACTION_TYPES.GET_SEGMENT_DATA_SUCCESS,
  ISegmentData
> = createAction(
  ACTION_TYPES.GET_SEGMENT_DATA_SUCCESS,
  createPayload<ISegmentData>(),
);

export const renameCDLListSummaryAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST,
  string
> = createAction(ACTION_TYPES.RENAME_CDL_LIST, createPayload<string>()); // list name
export const renameCDLListSummaryErrorAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.RENAME_CDL_LIST_ERROR,
  createPayload<IServerError>(),
);
export const renameCDLListSummarySuccessAction: CreateActionType<
  ACTION_TYPES.RENAME_CDL_LIST_SUCCESS,
  IListData
> = createAction(
  ACTION_TYPES.RENAME_CDL_LIST_SUCCESS,
  createPayload<IListData>(),
);
export const renameCDLListSummaryCancelAction: ActionCreator<
  ACTION_TYPES.RENAME_CDL_LIST_CANCEL,
  () => TypedAction<ACTION_TYPES.RENAME_CDL_LIST_CANCEL>
> = createAction(ACTION_TYPES.RENAME_CDL_LIST_CANCEL);

export const showDeleteCDLListPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_DELETE_CDL_LIST_POP_UP,
  number
> = createAction(
  ACTION_TYPES.SHOW_DELETE_CDL_LIST_POP_UP,
  createPayload<number>(),
);

export const removeCDLListSummaryAction: CreateActionType<
  ACTION_TYPES.DELETE,
  number
> = createAction(ACTION_TYPES.DELETE, createPayload<number>());
export const removeCDLListSummaryErrorAction: CreateActionType<
  ACTION_TYPES.DELETE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.DELETE_ERROR, createPayload<IServerError>());
export const removeCDLListSummarySuccessAction: ActionCreator<
  ACTION_TYPES.DELETE_SUCCESS,
  () => TypedAction<ACTION_TYPES.DELETE_SUCCESS>
> = createAction(ACTION_TYPES.DELETE_SUCCESS);

export const duplicateCDLListSummaryAction: CreateActionType<
  ACTION_TYPES.DUPLICATE,
  number
> = createAction(ACTION_TYPES.DUPLICATE, createPayload<number>());
export const duplicateCDLListSummaryErrorAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_ERROR,
  IServerError
> = createAction(ACTION_TYPES.DUPLICATE_ERROR, createPayload<IServerError>());
export const duplicateCDLListSummarySuccessAction: CreateActionType<
  ACTION_TYPES.DUPLICATE_SUCCESS,
  number
> = createAction(ACTION_TYPES.DUPLICATE_SUCCESS, createPayload<number>()); // listId

export const showCDLListChangeStatusPopUpAction: CreateActionType<
  ACTION_TYPES.SHOW_CDL_LIST_CHANGE_STATUS_POP_UP,
  ICDLListChangeStatusPayload
> = createAction(
  ACTION_TYPES.SHOW_CDL_LIST_CHANGE_STATUS_POP_UP,
  createPayload<ICDLListChangeStatusPayload>(),
);

export const changeCDLListStatusAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS,
  number
> = createAction(ACTION_TYPES.CDL_LIST_CHANGE_STATUS, createPayload<number>());
export const changeCDLListStatusErrorAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_ERROR,
  createPayload<IServerError>(),
);
export const changeCDLListStatusSuccessAction: CreateActionType<
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_SUCCESS,
  number
> = createAction(
  ACTION_TYPES.CDL_LIST_CHANGE_STATUS_SUCCESS,
  createPayload<number>(),
);

export const refreshCDLListSummaryAction: CreateActionType<
  ACTION_TYPES.REFRESH,
  number
> = createAction(ACTION_TYPES.REFRESH, createPayload<number>());
export const refreshCDLListSummaryErrorAction: CreateActionType<
  ACTION_TYPES.REFRESH_ERROR,
  IServerError
> = createAction(ACTION_TYPES.REFRESH_ERROR, createPayload<IServerError>());
export const refreshCDLListSummarySuccessAction: ActionCreator<
  ACTION_TYPES.REFRESH_SUCCESS,
  () => TypedAction<ACTION_TYPES.REFRESH_SUCCESS>
> = createAction(ACTION_TYPES.REFRESH_SUCCESS);

export const resetCDLListSummaryAction: ActionCreator<
  ACTION_TYPES.RESET,
  () => TypedAction<ACTION_TYPES.RESET>
> = createAction(ACTION_TYPES.RESET);
