import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";

import { FiltersPipesModule } from "@shared/modules/pipes/filters-pipes/filters-pipes.module";
import { DropDownModule } from "../../drop-down/drop-down.module";

import { MobileTablePaginationComponent } from "./components/mobile-table-pagination/mobile-table-pagination.component";
import { MobileTablePerPageComponent } from "./components/mobile-table-per-page/mobile-table-per-page.component";
import { MobileTableRowComponent } from "./components/mobile-table-row/mobile-table-row.component";
import { MobileTableComponent } from "./components/mobile-table/mobile-table.component";

const declarations: any[] = [
  MobileTableComponent,
  MobileTableRowComponent,
  MobileTablePaginationComponent,
  MobileTablePerPageComponent,
];

@NgModule({
  declarations,
  imports: [CommonModule, MatRippleModule, DropDownModule, FiltersPipesModule],
  exports: [...declarations],
})
export class MobileTableModule {}
