import { ITableHeaders } from "@ui/tables/components/table/table.component";

import { SORT_DIRECTION } from "@shared/constants/sort";

export const PRODUCT_RESULTS_DEFAULT_SORT_BY: { [key: string]: string } = {
  personnel: "firstName",
  institutions: "name",
  bids: "name",
  grants: "name",
};

export const PRODUCT_RESULTS_DEFAULT_ORDER_BY: SORT_DIRECTION.ASC =
  SORT_DIRECTION.ASC;

export enum ProductResultsFilterBy {
  Districts = "districts",
  Schools = "schools",
  Colleges = "colleges",
}

export const PERSONNEL_DEFAULT_FILTER_BY: ProductResultsFilterBy.Districts =
  ProductResultsFilterBy.Districts;
export const INSTITUTIONS_DEFAULT_FILTER_BY: ProductResultsFilterBy.Districts =
  ProductResultsFilterBy.Districts;

export const PRODUCT_RESULTS_DEFAULT_LIMIT: number = 25;
export const PRODUCT_RESULTS_DEFAULT_OFFSET: number = 0;

export const PRODUCT_RESULTS_DEFAULT_PARAMS: {
  [key: string]: string | number;
} = {
  limit: PRODUCT_RESULTS_DEFAULT_LIMIT,
  offset: PRODUCT_RESULTS_DEFAULT_OFFSET,
  orderBy: PRODUCT_RESULTS_DEFAULT_ORDER_BY,
};

export const PERSONNEL_DEFAULT_PARAMS: {
  [key: string]: string | number;
  sortBy: string;
  filterBy: ProductResultsFilterBy.Districts;
} = {
  ...PRODUCT_RESULTS_DEFAULT_PARAMS,
  sortBy: PRODUCT_RESULTS_DEFAULT_SORT_BY.personnel,
  filterBy: PERSONNEL_DEFAULT_FILTER_BY,
};

export const INSTITUTIONS_DEFAULT_PARAMS: {
  [key: string]: string | number;
  sortBy: string;
  filterBy: ProductResultsFilterBy.Districts;
} = {
  ...PRODUCT_RESULTS_DEFAULT_PARAMS,
  sortBy: PRODUCT_RESULTS_DEFAULT_SORT_BY.institutions,
  filterBy: INSTITUTIONS_DEFAULT_FILTER_BY,
};

export const BIDS_DEFAULT_PARAMS: {
  [key: string]: string | number;
  orderBy: SORT_DIRECTION;
  sortBy: string;
} = {
  ...PRODUCT_RESULTS_DEFAULT_PARAMS,
  orderBy: SORT_DIRECTION.DESC,
  sortBy: PRODUCT_RESULTS_DEFAULT_SORT_BY.bids,
};

export const GRANTS_DEFAULT_PARAMS: {
  [key: string]: string | number;
  orderBy: SORT_DIRECTION;
  sortBy: string;
} = {
  ...PRODUCT_RESULTS_DEFAULT_PARAMS,
  orderBy: SORT_DIRECTION.DESC,
  sortBy: PRODUCT_RESULTS_DEFAULT_SORT_BY.grants,
};

export const PERSONNEL_RESULTS_TABLE_HEADERS: ITableHeaders = [
  {
    key: "",
    title: "",
    sortable: false,
  },
  {
    key: "firstName",
    title: "First Name",
    sortable: true,
  },
  {
    key: "lastName",
    title: "Last Name",
    sortable: true,
  },
  {
    key: "jobTitle",
    title: "Job Function",
    sortable: true,
  },
  {
    key: "institution",
    title: "Institution",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
];

export const INSTITUTIONS_RESULTS_TABLE_HEADERS: ITableHeaders = [
  {
    key: "",
    title: "",
    sortable: false,
  },
  {
    key: "name",
    title: "Name",
    sortable: true,
  },
  {
    key: "type",
    title: "Type",
    sortable: true,
  },
  {
    key: "city",
    title: "City",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "enrollment",
    title: "Enrollment",
    sortable: true,
  },
  {
    key: "grants",
    title: "Grants",
    sortable: true,
  },
  {
    key: "bidCount",
    title: "RFPs & IFBs",
    sortable: false,
  },
];

export const ALL_INSTITUTIONS_RESULTS_TABLE_HEADERS: ITableHeaders = [
  {
    key: "",
    title: "",
    sortable: false,
  },
  {
    key: "name",
    title: "Name",
    sortable: true,
  },
  {
    key: "type",
    title: "Type",
    sortable: true,
  },
  {
    key: "city",
    title: "City",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "enrollment",
    title: "Enrollment",
    sortable: true,
  },
  {
    key: "grants",
    title: "Grants",
    sortable: true,
  },
  {
    key: "bidCount",
    title: "RFPs & IFBs",
    sortable: false,
  },
];

export const INSTITUTIONS_RESULTS_TABLE_HEADERS_MOBILE: ITableHeaders = [
  {
    key: "",
    title: "",
    sortable: false,
  },
  {
    key: "name",
    title: "Name",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "type",
    title: "Type",
    sortable: true,
  },
];

export const PERSONNEL_RESULTS_TABLE_HEADERS_MOBILE: ITableHeaders = [
  {
    key: "",
    title: "",
    sortable: false,
  },
  {
    key: "lastName",
    title: "Last Name",
    sortable: true,
  },
  {
    key: "jobTitle",
    title: "Job Function",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
];

export const BIDS_RESULTS_TABLE_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "Project Name",
    sortable: true,
  },
  {
    key: "type",
    title: "Type",
    sortable: true,
  },
  {
    key: "location",
    title: "Location",
    sortable: true,
  },
  {
    key: "publish",
    title: "Publish",
    sortable: true,
  },
  {
    key: "submittal",
    title: "Submittal",
    sortable: true,
  },
];

export const BIDS_RESULTS_TABLE_HEADERS_MOBILE: ITableHeaders = [
  {
    key: "name",
    title: "Project Name",
    sortable: true,
  },
  {
    key: "type",
    title: "Type",
    sortable: true,
  },
  {
    key: "location",
    title: "State",
    sortable: true,
  },
  {
    key: "submittal",
    title: "Submittal",
    sortable: true,
  },
];

export const GRANTS_RESULTS_TABLE_HEADERS: ITableHeaders = [
  {
    key: "name",
    title: "Grant Name",
    sortable: true,
  },
  {
    key: "recipient",
    title: "Recipients",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "year",
    title: "Fiscal Year",
    sortable: true,
  },
  {
    key: "amount",
    title: "Amount",
    sortable: true,
  },
];

export const GRANTS_RESULTS_TABLE_HEADERS_MOBILE: ITableHeaders = [
  {
    key: "name",
    title: "Grant Name",
    sortable: true,
  },
  {
    key: "state",
    title: "State",
    sortable: true,
  },
  {
    key: "amount",
    title: "Total Funds",
    sortable: true,
  },
  {
    key: "year",
    title: "Fiscal Year",
    sortable: true,
  },
];

export const CLOUD_SYNC_HISTORY_TABLE_HEADERS: ITableHeaders = [
  {
    key: "syncDate",
    title: "Sync Date",
    sortable: true,
  },
  {
    key: "status",
    title: "Status",
    sortable: false,
  },
  {
    key: "platform",
    title: "Platform",
    sortable: false,
  },
  {
    key: "object",
    title: "Object",
    sortable: false,
  },
  {
    key: "recordsProcessed",
    title: "Processed Records",
    sortable: false,
  },
  {
    key: "recordsFailed",
    title: "Failed Records",
    sortable: false,
  },
];
