import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "bl-progress-status",
  templateUrl: "./progress-status.component.html",
  styleUrls: ["./progress-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressStatusComponent {
  @Input() pending: boolean = false;
}
