import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  downloadEntityReducer,
  IDownloadEntityState,
} from "./download-entity.reducer";

export interface IDownloadEntityModuleState {
  downloadEntity: IDownloadEntityState;
}

const downloadEntityModuleReducer: ActionReducerMap<IDownloadEntityModuleState> =
  {
    downloadEntity: downloadEntityReducer,
  };

// eslint-disable-next-line
export const DOWNLOAD_ENTITY_STATE_NAME = 'downloadEntityModule';
export const DOWNLOAD_ENTITY_STATE: InjectionToken<
  ActionReducerMap<IDownloadEntityModuleState>
> = new InjectionToken<ActionReducerMap<IDownloadEntityModuleState>>(
  DOWNLOAD_ENTITY_STATE_NAME,
  {
    factory: () => downloadEntityModuleReducer,
  },
);
