import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { Observable } from "rxjs";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";
import { pluckAndCatch } from "@shared/utils/response-formater";

import { IChangePasswordData } from "@modules/auth/interfaces/formsActionsData";
import {
  ISuccessMessageResponse,
  IUser,
  IUserData,
} from "@modules/auth/interfaces/user";
import { IProfileSetting } from "@modules/profile/interfaces/profile-settings";
import { IServerError } from "@shared/interfaces/server-error";
import { StorageService } from "./storage.service";

@Injectable()
export class ProfileService {
  constructor(private http: HttpClient) {}

  static setUserData(userData: IUserData): void {
    const {
      user,
      isAdmin,
      isUnlimited,
      isAdminsAccount,
      hasLists,
      role,
      permissions,
      accountAvailablePermissions,
      trialSeatExpirationDate,
      isFirstAssignAvailable,
      notifications,
      isBroker,
      cdlLists,
    }: IUserData = userData;

    StorageService.user = user;
    StorageService.isAdminsAccount = !!isAdminsAccount;
    StorageService.isUnlimited = !!isUnlimited;
    StorageService.isAdmin = !!isAdmin;
    StorageService.isBroker = !!isBroker;
    StorageService.isFirstAssignAvailable = !!isFirstAssignAvailable;
    StorageService.popUpNotifications =
      typeof notifications !== "undefined"
        ? notifications
        : StorageService.popUpNotifications;

    StorageService.hasLists =
      typeof hasLists !== "undefined" ? hasLists : StorageService.hasLists;
    StorageService.cdlLists =
      typeof cdlLists !== "undefined" ? cdlLists : StorageService.cdlLists;
    StorageService.role =
      typeof role !== "undefined" ? role : StorageService.role;
    StorageService.permissions =
      typeof permissions !== "undefined"
        ? permissions
        : StorageService.permissions;
    StorageService.accountAvailablePermissions =
      typeof accountAvailablePermissions !== "undefined"
        ? accountAvailablePermissions
        : StorageService.accountAvailablePermissions;

    StorageService.trialSeatExpirationDate = trialSeatExpirationDate
      ? trialSeatExpirationDate * 1000
      : null;
  }

  loadUser(): Observable<IUserData> {
    return this.http.get(environment.api.user.data).pipe(pluckAndCatch);
  }

  updateUser(user: IUser): Observable<IUserData | IServerError> {
    return this.http.patch(environment.api.user.data, user).pipe(pluckAndCatch);
  }

  changePassword(
    changePasswordData: IChangePasswordData,
  ): Observable<ISuccessMessageResponse | IServerError> {
    return this.http
      .post(`${environment.api.auth.changePassword}`, changePasswordData)
      .pipe(pluckAndCatch);
  }

  getProfileSettings(): Observable<IProfileSetting[]> {
    return this.http
      .get(`${environment.api.user.settings}`)
      .pipe(pluckAndCatch);
  }

  updateProfileSettings(id: number, value: boolean): Observable<any> {
    return this.http
      .patch(`${environment.api.user.settings}/${id}`, { value })
      .pipe(catchErrorWithErrorType);
  }
}
