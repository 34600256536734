import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import * as actions from "@core/store/actions/list-create.actions";
import { GetFromState } from "@shared/store/types/reducer.types";

import { IServerError } from "@shared/interfaces/server-error";

export interface IListCreateState {
  creating: boolean;
  createError: IServerError | null;
}

const initialState: IListCreateState = {
  creating: false,
  createError: null,
};

const createList: OnReducer<IListCreateState, ActionType<any>> = (
  state: IListCreateState,
) => ({
  ...state,
  creating: true,
  createError: null,
});

const createListError: OnReducer<IListCreateState, ActionType<any>> = (
  state: IListCreateState,
  { payload }: ActionType<any>,
) => ({
  ...state,
  creating: false,
  createError: { ...payload },
});

const createListSuccess: OnReducer<IListCreateState, ActionType<any>> = (
  state: IListCreateState,
) => ({
  ...state,
  creating: false,
  createError: null,
});

const resetState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IListCreateState> =
  createReducer<IListCreateState>(
    initialState,

    on(actions.createListAction, createList),
    on(actions.createListErrorAction, createListError),
    on(actions.createListSuccessAction, createListSuccess),

    on(actions.resetStateAction, resetState),
  );

export function listCreateReducer(
  state: IListCreateState,
  action: Action,
): IListCreateState {
  return reducer(state, action);
}

export const creating: GetFromState<boolean, IListCreateState> = (
  state: IListCreateState,
): boolean => state && state.creating;
export const createError: GetFromState<IServerError, IListCreateState> = (
  state: IListCreateState,
): IServerError => state && state.createError;
