import { Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { DocumentRef } from "@core/refs/document-ref.service";
import {
  appendExternalScriptTo,
  appendExternalScriptToBody,
  IScriptParamsTypes,
} from "@shared/utils/append-external-script-to-body";
import { TRUST_ARC_CONSENT_STATE } from "../constants/trust-arc-consent";
import { consentURL, TrustArcKey } from "../constants/trust-arc-keys";
import { getTrustArcCookieIconGen } from "../utils/trust-arc-consent-script";

@Injectable({
  providedIn: "root",
})
export class TrustArcConsentService {
  private _renderer: Renderer2;

  constructor(
    @Inject(TRUST_ARC_CONSENT_STATE) private _state: boolean,
    private _rendererFactory: RendererFactory2,
    private _documentRef: DocumentRef,
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  async initTrustArcConsentTag(): Promise<void> {
    if (
      !this._state ||
      !!this._documentRef.nativeElement.getElementById("trustarc-wrapper")
    ) {
      return;
    }

    const trustArcScriptParams: Iterable<
      readonly [string, IScriptParamsTypes]
    > = [
      ["type", "text/javascript"],
      ["src", `${consentURL.NOTICE_URL}${TrustArcKey}`],
    ];

    const trustArcCookieScriptParams: Iterable<
      readonly [string, IScriptParamsTypes]
    > = [["innerHTML", getTrustArcCookieIconGen()]];

    // creates div with trustarc-wrapper and includes required script tag
    await appendExternalScriptTo(
      this._documentRef,
      "trustarc-wrapper",
      appendExternalScriptToBody(this._documentRef, trustArcScriptParams),
    );

    // create script tag that creates cookie icon tag and more
    await appendExternalScriptToBody(
      this._documentRef,
      trustArcCookieScriptParams,
    );
  }
}
