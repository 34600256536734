import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, OnDestroy } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { Store } from "@ngrx/store";

import { environment } from "@env/environment";

import { throwError, Observable } from "rxjs";
import { catchError, map, pluck } from "rxjs/operators";

import { go } from "@core/store/actions/router-history.action";
import { CoreState } from "@core/store/reducers";

import { SessionStorageService } from "@core/services/session-storage.service";
import { StorageService } from "@core/services/storage.service";
import { PopUpService } from "@ui/pop-up/services/pop-up/pop-up.service";

import { IObjectKeysStringAny } from "@shared/interfaces";
import { IServerError } from "@shared/interfaces/server-error";

import { CORE_PATHS } from "@core/constants/core-paths";
import { LOCAL_STORAGE_FIELDS } from "@shared/constants/local-storage-fields";
import { FORM_VALIDATION_ERRORS } from "@shared/constants/validators/forms-validations-errors";
import { Validators } from "@shared/validators/validators";
import { AUTH_PATHS } from "../constants/auth-paths";

import {
  ICheckUserNameResponse,
  IForgotPasswordResponse,
  ILoginResponse,
  INewUser,
  IRefreshTokenResponse,
  ISuccessMessageResponse,
  IUser,
} from "../interfaces/user";
import {
  ICredentials,
  IFirstLoginFormData,
  IForgotPasswordData,
} from "../interfaces/formsActionsData";
import { resetAccountSeatsAction } from "../../profile/store/actions/account.action";
import { resetAccountUsersAction } from "../../profile/store/actions/account-users.action";
import { resetDashboardViewsStateAction } from "../../dashboard-views/store/actions/dashboard-views-filter.action";
import {
  resetDashboardListsCheckedFilterAction,
  resetDashboardListsStateAction,
} from "../../dashboard-lists/store/actions/dashboard-lists-filter.action";

@Injectable()
export class AuthService {
  constructor(
    private _store: Store<CoreState>,
    private _fb: UntypedFormBuilder,
    private _http: HttpClient,
    private _popUpService: PopUpService,
  ) {}

  // Redirect outside
  static toAdminPanel(): void {
    window.location.href = `${environment.adminAppUrl}`;
  }

  // HTTP requests
  login(credentials: ICredentials): Observable<ILoginResponse> {
    return this._http.post(environment.api.auth.login, credentials).pipe(
      pluck("data"),
      map((response: ILoginResponse) => ({
        ...response,
        token: `Bearer ${response.token}`,
      })),
      catchError((error: HttpErrorResponse) =>
        throwError(error.error as IServerError),
      ),
    );
  }

  checkUserName(user): Observable<ICheckUserNameResponse> {
    return this._http.post(environment.api.auth.checkUserName, user).pipe(
      pluck("data"),
      map((response: ICheckUserNameResponse) => response),
      catchError((error: HttpErrorResponse) =>
        throwError(error.error as IServerError),
      ),
    );
  }

  signUp(newUser: INewUser): Observable<ILoginResponse> {
    return this._http.post(environment.api.auth.signUp, newUser).pipe(
      pluck("data"),
      map((response: ILoginResponse) => ({
        ...response,
        token: `Bearer ${response.token}`,
      })),
      catchError((error: HttpErrorResponse) => {
        const serverError: IServerError = error.error;
        const { general, ...rest }: { [key: string]: string[] } =
          serverError.errors;
        const _error: IServerError = {
          message: general ? general[0] : serverError.message,
          errors: rest,
        };

        return throwError(_error as IServerError);
      }),
    );
  }

  firstLogin(data: IFirstLoginFormData): Observable<ILoginResponse> {
    return this._http.post(`${environment.api.auth.firstLogin}`, data).pipe(
      pluck("data"),
      map((response: ILoginResponse) => ({
        ...response,
        token: `Bearer ${response.token}`,
      })),
      catchError((error: HttpErrorResponse) =>
        throwError(error.error as IServerError),
      ),
    );
  }

  getRefreshToken(): Observable<IRefreshTokenResponse> {
    return this._http.get(environment.api.auth.refreshToken).pipe(
      pluck("data"),
      map((response: IRefreshTokenResponse) => ({
        ...response,
        token: `Bearer ${response.token}`,
      })),
      catchError((error: HttpErrorResponse) =>
        throwError(error.error as IServerError),
      ),
    );
  }

  resetPassword(
    resetData: IForgotPasswordData,
  ): Observable<IForgotPasswordResponse> {
    return this._http
      .post(`${environment.api.auth.resetPassword}`, resetData)
      .pipe(
        pluck<{ data: IForgotPasswordResponse }, IForgotPasswordResponse>(
          "data",
        ),
        catchError((error: HttpErrorResponse) =>
          throwError(error.error as IServerError),
        ),
      );
  }

  changeTemporaryPassword(
    changePasswordData: IFirstLoginFormData,
  ): Observable<ISuccessMessageResponse> {
    return this._http
      .post(
        `${environment.api.auth.changePasswordAfterReset}`,
        changePasswordData,
      )
      .pipe(
        pluck<{ data: ISuccessMessageResponse }, ISuccessMessageResponse>(
          "data",
        ),
        catchError((error: HttpErrorResponse) =>
          throwError(error.error as IServerError),
        ),
      );
  }
  // storage service setup during login/signin
  storageServiceSetUpOnSignIn(
    data: ILoginResponse,
    remember: boolean,
    fromAdmin: boolean,
  ): void {
    StorageService.token = data.token;
    if (data.expiryDuration) {
      StorageService.expiryDuration = parseInt(data.expiryDuration, 10);
    }

    if (data.refreshToken) {
      StorageService.refreshToken = `Bearer ${data.refreshToken}`;
      StorageService.loggedInTime = new Date();
      StorageService.refreshTokenCreatedDate = new Date();
    }
    StorageService.remember = remember;
    StorageService.fromAdmin = fromAdmin;
    StorageService.isAdminsAccount = !!data.isAdminsAccount;

    if (data.user && StorageService.id !== data.user.id) {
      StorageService.doNotShowWelcomeWithMarketViewPopUp = false;
      StorageService.doNotShowEditSelfPopUp = false;
      StorageService.id = data.user.id;
    }
  }

  // Handle success actions
  onSignIn(
    data: ILoginResponse,
    remember: boolean = false,
    firstLogin: boolean = false,
    withoutRedirect: boolean = false,
    fromAdmin: boolean = false,
  ): void {
    this.storageServiceSetUpOnSignIn(data, remember, fromAdmin);

    if (firstLogin) {
      StorageService.firstSignUp = true;
      if (!withoutRedirect) {
        this._store.dispatch(
          go(["/", CORE_PATHS.DASHBOARD], { isFirstLogin: true }),
        );
      }
    } else {
      StorageService.firstSignUp = false;
      if (!withoutRedirect) {
        this._store.dispatch(go(["/", CORE_PATHS.DASHBOARD]));
      }
    }
  }

  onSignOut(withoutNavigate?: boolean): void {
    if (StorageService.idleTimerPopUp) {
      // close active idletimerpopup before logout
      this._popUpService.close();
      StorageService.idleTimerPopUp = false;
    }

    StorageService.clearStorage([
      LOCAL_STORAGE_FIELDS.doNotShowAssignStatePopUp,
      LOCAL_STORAGE_FIELDS.doNotShowAssignAddOnPopUp,
      LOCAL_STORAGE_FIELDS.doNotShowEditSelfPopUp,
      LOCAL_STORAGE_FIELDS.doNotShowWelcomeWithMarketViewPopUp,
      LOCAL_STORAGE_FIELDS.id,
      LOCAL_STORAGE_FIELDS.doNotShowIndefinitelyMVRenewalPopUp,
    ]);

    SessionStorageService.clear();

    this._store.dispatch(resetAccountSeatsAction());
    this._store.dispatch(resetAccountUsersAction());
    this._store.dispatch(resetDashboardListsCheckedFilterAction());
    this._store.dispatch(resetDashboardListsStateAction());
    this._store.dispatch(resetDashboardViewsStateAction());

    if (!withoutNavigate) {
      this._store.dispatch(go(["/", CORE_PATHS.AUTH, AUTH_PATHS.LOGIN]));
    }
  }

  onResetTemporaryPassword(): void {
    this._store.dispatch(go(["/", CORE_PATHS.AUTH, AUTH_PATHS.LOGIN]));
  }

  sigInForm(email?: string): UntypedFormGroup {
    return this._fb.group({
      credentials: this._fb.group({
        email: [
          email || "",
          [
            Validators.required(FORM_VALIDATION_ERRORS.email.required),
            Validators.email(FORM_VALIDATION_ERRORS.email.invalid),
          ],
        ],
        // password: [
        //   "",
        //   [Validators.required(FORM_VALIDATION_ERRORS.password.required)],
        // ],
      }),
      remember: [false],
    });
  }

  profileForm(
    withPassword: boolean = false,
    withRecaptcha: boolean = false,
    profile?: IUser,
  ): UntypedFormGroup {
    const {
      email,
      password,
      confirmNewPassword,
      firstName,
      lastName,
      telephoneNumber,
      address1,
      city,
      stateProvince,
      country,
      company,
      prefix,
      zipPostalCode,
    }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    const passwordControls: IObjectKeysStringAny = {
      password: [
        "",
        [
          Validators.password(password.invalid),
          Validators.validateDependent(""),
          Validators.required(password.required),
          Validators.validateDependent("confirmPassword"),
        ],
      ],
      confirmPassword: [
        "",
        [
          Validators.required(confirmNewPassword.required),
          Validators.confirm(confirmNewPassword.invalid, ["password"]),
        ],
      ],
    };

    const gRecaptchaControl: IObjectKeysStringAny = {
      gRecaptchaResponse: ["", Validators.required()],
    };

    const defaultControls: IObjectKeysStringAny = {
      email: [
        (profile && profile.email) || "",
        [Validators.email(email.invalid), Validators.required(email.required)],
      ],
      prefix: ["Ms"],
      firstName: [
        (profile && profile.firstName) || "",
        [
          Validators.required(firstName.required),
          Validators.maxLength(firstName.invalid, 75),
        ],
      ],
      lastName: [
        (profile && profile.lastName) || "",
        [
          Validators.required(lastName.required),
          Validators.maxLength(lastName.invalid, 75),
        ],
      ],
      company: [
        (profile && profile.company) || "",
        Validators.required(company.required),
      ],
      phone: [
        (profile && profile.phone) || "",
        [
          Validators.required(telephoneNumber.required),
          Validators.minLength(telephoneNumber.invalid, 10),
          Validators.maxLength(telephoneNumber.invalid, 10),
        ],
      ],
      address: this._fb.group({
        addressFirst: [
          (profile && profile.addressFirst) || "",
          Validators.required(address1.required),
        ],
        addressSecond: [(profile && profile.addressSecond) || ""],
        city: [
          (profile && profile.city) || "",
          [
            Validators.required(city.required),
            Validators.maxLength(city.invalid, 64),
          ],
        ],
        stateCode: [
          (profile && profile.stateCode) || "",
          [Validators.required(stateProvince.required)],
        ],
        countryCode: [
          (profile && profile.countryCode) || "",
          [Validators.required(country.required)],
        ],
        postalCode: [
          (profile && profile.postalCode) || "",
          [
            Validators.required(zipPostalCode.required),
            Validators.minLength(zipPostalCode.minLength, 5),
            Validators.maxLength(zipPostalCode.maxLength, 10),
          ],
        ],
      }),
    };

    let finaleFormConfig: IObjectKeysStringAny = {
      ...defaultControls,
    };

    if (withRecaptcha) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...gRecaptchaControl,
      };
    }

    if (withPassword) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...passwordControls,
      };
    }

    return this._fb.group(finaleFormConfig as IObjectKeysStringAny);
  }

  partialSignUpForm(
    withRecaptcha: boolean = false,
    profile?: IUser,
  ): UntypedFormGroup {
    const {
      email,
      firstName,
      lastName,
      country,
      company,
      prefix,
      telephoneNumber,
    }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    const gRecaptchaControl: IObjectKeysStringAny = {
      gRecaptchaResponse: ["", Validators.required()],
    };

    const defaultControls: IObjectKeysStringAny = {
      email: [
        (profile && profile.email) || "",
        [
          Validators.validateDependent("password"),
          Validators.email(email.invalid),
          Validators.required(email.required),
        ],
      ],
      prefix: ["Ms", Validators.required(prefix.required)],
      firstName: [
        (profile && profile.firstName) || "",
        [
          Validators.required(firstName.required),
          Validators.maxLength(firstName.invalid, 75),
        ],
      ],
      lastName: [
        (profile && profile.lastName) || "",
        [
          Validators.required(lastName.required),
          Validators.maxLength(lastName.invalid, 75),
        ],
      ],
      company: [
        (profile && profile.company) || "",
        Validators.required(company.required),
      ],
      countryCode: [
        profile && profile.countryCode,
        Validators.required(country.required),
      ],
      phone: [
        (profile && profile.phone) || "",
        [
          Validators.required(telephoneNumber.required),
          Validators.minLength(telephoneNumber.invalid, 10),
          Validators.maxLength(telephoneNumber.invalid, 10),
        ],
      ],
    };

    let finaleFormConfig = withRecaptcha
      ? { ...defaultControls, ...gRecaptchaControl }
      : defaultControls;

    return this._fb.group(finaleFormConfig as { [key: string]: any });
  }

  signUpForm(
    withPassword: boolean = false,
    withRecaptcha: boolean = false,
    profile?: IUser,
  ): UntypedFormGroup {
    const {
      email,
      firstName,
      lastName,
      country,
      company,
      prefix,
      telephoneNumber,
      // stateProvince,
      // zipPostalCode,
      // city,
      // address1,
    }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    const gRecaptchaControl: IObjectKeysStringAny = {
      gRecaptchaResponse: ["", Validators.required()],
    };

    const defaultControls: IObjectKeysStringAny = {
      email: [
        (profile && profile.email) || "",
        [
          Validators.validateDependent("password"),
          Validators.email(email.invalid),
          Validators.required(email.required),
        ],
      ],
      prefix: ["Ms", Validators.required(prefix.required)],
      firstName: [
        (profile && profile.firstName) || "",
        [
          Validators.required(firstName.required),
          Validators.maxLength(firstName.invalid, 75),
        ],
      ],
      lastName: [
        (profile && profile.lastName) || "",
        [
          Validators.required(lastName.required),
          Validators.maxLength(lastName.invalid, 75),
        ],
      ],
      company: [
        (profile && profile.company) || "",
        Validators.required(company.required),
      ],
      countryCode: ["US", Validators.required(country.required)],
      // stateCode: [
      //   (profile && profile.stateCode) || "",
      //   [Validators.required(stateProvince.required)],
      // ],
      phone: [
        (profile && profile.phone) || "",
        [
          Validators.required(telephoneNumber.required),
          Validators.minLength(telephoneNumber.invalid, 10),
          Validators.maxLength(telephoneNumber.invalid, 10),
        ],
      ],
      // postalCode: [
      //   (profile && profile.postalCode) || "",
      //   [
      //     Validators.required(zipPostalCode.required),
      //     Validators.minLength(zipPostalCode.minLength, 5),
      //     Validators.maxLength(zipPostalCode.maxLength, 10),
      //   ],
      // ],
      // addressFirst: [
      //   (profile && profile.addressFirst) || "",
      //   Validators.required(address1.required),
      // ],
      // addressSecond: [(profile && profile.addressSecond) || ""],
      // city: [
      //   (profile && profile.city) || "",
      //   [
      //     Validators.required(city.required),
      //     Validators.maxLength(city.invalid, 64),
      //   ],
      // ],
    };

    let finaleFormConfig: { [key: string]: any } = {
      ...defaultControls,
    };

    if (withRecaptcha) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...gRecaptchaControl,
      };
    }

    if (withPassword) {
      finaleFormConfig = {
        ...finaleFormConfig,
        // ...passwordControls,
      };
    }

    return this._fb.group(finaleFormConfig as { [key: string]: any });
  }

  getInviteSignUpForm(
    userEmail: string,
    token: string,
    withRecaptcha: boolean = false,
  ): UntypedFormGroup {
    const {
      email,
      firstName,
      lastName,
      prefix,
      country,
      telephoneNumber,
    }: typeof FORM_VALIDATION_ERRORS = FORM_VALIDATION_ERRORS;

    const defaultControls: IObjectKeysStringAny = {
      token: [token || "", Validators.required()],
      email: [
        userEmail || "",
        [
          Validators.email(email.invalid),
          Validators.required(email.required),
          // Validators.validateDependent("password"),
        ],
      ],
      prefix: ["Ms", Validators.required(prefix.required)],
      firstName: [
        "",
        [
          Validators.required(firstName.required),
          Validators.maxLength(firstName.invalid, 75),
        ],
      ],
      lastName: [
        "",
        [
          Validators.required(lastName.required),
          Validators.maxLength(lastName.invalid, 75),
        ],
      ],
      remember: [false],
      phone: [
        "",
        [
          Validators.required(telephoneNumber.required),
          Validators.minLength(telephoneNumber.invalid, 10),
          Validators.maxLength(telephoneNumber.invalid, 10),
        ],
      ],
      // CCP-2648 API request don't need country code set static/at all
      // countryCode: ['US', Validators.required(country.required)],
    };

    const gRecaptchaControl: IObjectKeysStringAny = {
      gRecaptchaResponse: ["", Validators.required()],
    };

    let finaleFormConfig: { [key: string]: any } = {
      ...defaultControls,
    };

    if (withRecaptcha) {
      finaleFormConfig = {
        ...finaleFormConfig,
        ...gRecaptchaControl,
      };
    }

    return this._fb.group(finaleFormConfig as IObjectKeysStringAny);
  }
}
