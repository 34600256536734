import { SafeHtml } from "@angular/platform-browser";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/brokers-tou.actions";

import { IServerError } from "@shared/interfaces/server-error";
import { ITermsOfUsePageData } from "../../interfaces/terms-of-servise";

export interface IBrokersTOUSTate {
  pageData: ITermsOfUsePageData<SafeHtml> | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: IBrokersTOUSTate = {
  pageData: null,
  pending: false,
  error: null,
};

const getBrokerTermsOfUse: OnReducer<IBrokersTOUSTate, ActionType<any>> = (
  state: IBrokersTOUSTate,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getBrokerTermsOfUseError: OnReducer<
  IBrokersTOUSTate,
  ActionType<Payload<IServerError>>
> = (state: IBrokersTOUSTate, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getBrokerTermsOfUseSuccess: OnReducer<
  IBrokersTOUSTate,
  ActionType<Payload<any>>
> = (state: IBrokersTOUSTate, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  pageData: { ...payload },
});

const reducer: ActionReducer<IBrokersTOUSTate> =
  createReducer<IBrokersTOUSTate>(
    initialState,

    on(actions.getBrokerTermsOfUseAction, getBrokerTermsOfUse),
    on(actions.getBrokerTermsOfUseErrorAction, getBrokerTermsOfUseError),
    on(actions.getBrokerTermsOfUseSuccessAction, getBrokerTermsOfUseSuccess),
  );

export function brokersTOUReducer(
  state: IBrokersTOUSTate,
  action: Action,
): IBrokersTOUSTate {
  return reducer(state, action);
}

export const pageData: GetFromState<
  ITermsOfUsePageData<SafeHtml> | null,
  IBrokersTOUSTate
> = (state: IBrokersTOUSTate): ITermsOfUsePageData<SafeHtml> | null =>
  state.pageData;
