import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/college-profile.action";

import { MVEntityAvailable } from "../../../profile/utils/mv-entity-available";

import { IServerError } from "@shared/interfaces/server-error";
import { IBids } from "@shared/modules/bids-entity/interfaces";
import {
  IGrants,
  IHiddenValue,
  IProfileAddress,
} from "@shared/modules/institution-profiles/interfaces/profiles";
import { IMVEntityAvailable } from "../../../profile/interfaces/mv-entity-available";
import { IUserPermissions } from "../../../profile/interfaces/profile";
import { ICoordinates } from "../../../segment/interfaces/geography";
import {
  ICollege,
  ICollegeDisciplines,
  ICollegeDisciplineItems,
  ICollegeEthnicity,
  ICollegeHeader,
  ICollegeInfo,
} from "../../interfaces";

import { PermissionsKeys } from "@core/constants/permissions";
import { HIDDEN_BY } from "@shared/modules/institution-profiles/constants/profiles";

export interface ICollegeProfileState {
  profile: ICollege | null;
  pending: boolean;
  error: IServerError | null;
}

const initialState: ICollegeProfileState = {
  profile: null,
  pending: false,
  error: null,
};

const getCollegeProfile: OnReducer<ICollegeProfileState, ActionType<any>> = (
  state: ICollegeProfileState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const getCollegeProfileError: OnReducer<
  ICollegeProfileState,
  ActionType<any>
> = (state: ICollegeProfileState, { payload: error }: Payload<any>) => ({
  ...state,
  error,
  pending: false,
});

const getCollegeProfileSuccess: OnReducer<
  ICollegeProfileState,
  ActionType<any>
> = (state: ICollegeProfileState, { payload: profile }: Payload<any>) => ({
  ...state,
  profile,
  pending: false,
});

const resetCollegeProfileState: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICollegeProfileState> =
  createReducer<ICollegeProfileState>(
    initialState,

    on(actions.getCollegeProfileAction, getCollegeProfile),
    on(actions.getCollegeProfileErrorAction, getCollegeProfileError),
    on(actions.getCollegeProfileSuccessAction, getCollegeProfileSuccess),

    on(actions.resetCollegeProfileStateAction, resetCollegeProfileState),
  );

export const collegeProfile: GetFromState<ICollege, ICollegeProfileState> = (
  state: ICollegeProfileState,
): ICollege => state.profile;
export const collegeProfilePending: GetFromState<
  boolean,
  ICollegeProfileState
> = (state: ICollegeProfileState): boolean => state.pending;
export const collegeProfileError: GetFromState<
  IServerError,
  ICollegeProfileState
> = (state: ICollegeProfileState): IServerError => state.error;
export const isPersonnel: GetFromState<boolean, ICollege> = (
  profile: ICollege,
): boolean => profile && profile.isPersonnel;

export const collegeInfo: GetFromState<ICollegeInfo, ICollege> = (
  profile: ICollege,
): ICollegeInfo => profile && profile.info;
export const officeId: GetFromState<number, ICollegeInfo> = (
  info: ICollegeInfo,
): number => (info && info.office && info.office.entityId) || null;

export const collegeProfileBids: GetFromState<IBids, ICollege> = (
  profile: ICollege,
): IBids => profile && profile.bids;
export const isHiddenCollegeBids: GetFromState<boolean, IBids> = (
  _bids: IBids,
): boolean =>
  (_bids && _bids.total && _bids.total.toString() === HIDDEN_BY.value) || null;
export const collegeProfileGrants: GetFromState<IGrants, ICollege> = (
  profile: ICollege,
): IGrants => profile && profile.grants;
export const isHiddenCollegeGrants: GetFromState<boolean, IGrants> = (
  _grants: IGrants,
): boolean => {
  return (
    (_grants &&
      _grants.totalActive &&
      _grants.totalActive.value &&
      _grants.totalActive.value.toString() === HIDDEN_BY.value) ||
    null
  );
};
export const collegeProfileHeader: GetFromState<ICollegeHeader, ICollege> = (
  profile: ICollege,
): ICollegeHeader => profile && profile.header;
export const collegeDisciplines: GetFromState<
  ICollegeDisciplineItems,
  ICollege
> = (profile: ICollege): ICollegeDisciplineItems | IHiddenValue => {
  if (
    profile &&
    profile.disciplines &&
    profile.disciplines !== HIDDEN_BY.value
  ) {
    return Object.entries(profile.disciplines as ICollegeDisciplines).map(
      ([key, value]: [string, number]) => ({ title: key, value }),
    );
  }

  if (
    profile &&
    profile.disciplines &&
    profile.disciplines === HIDDEN_BY.value
  ) {
    return profile.disciplines as IHiddenValue;
  }

  return [];
};

export const collegeEthnicity: GetFromState<ICollegeEthnicity, ICollegeInfo> = (
  info: ICollegeInfo,
): ICollegeEthnicity => info && info.ethnicity;

export const collegeProfilePid: GetFromState<number, ICollegeHeader> = (
  header: ICollegeHeader,
): number => header && header.pid;
export const isCollegePidHidden: GetFromState<boolean, ICollegeHeader> = (
  header: ICollegeHeader,
): boolean => header && header.pid.toString() === HIDDEN_BY.value;
export const collegeProfileId: GetFromState<number, ICollegeHeader> = (
  header: ICollegeHeader,
): number => header && header.id;
export const collegeProfileCoordinates: GetFromState<
  ICoordinates,
  ICollegeHeader
> = (header: ICollegeHeader): ICoordinates => {
  if (header) {
    const { latitude: lat, longitude: lng }: Partial<IProfileAddress> =
      header.physicalAddress || header.address;
    return { lat, lng };
  }

  return { lat: null, lng: null };
};

export const marketViewAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageViews, _data, _isHidden);
};

export const grantAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageGrants, _data, _isHidden);
};

export const bidAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  boolean
> = (
  _data: IUserPermissions,
  _isHidden: boolean = false,
): IMVEntityAvailable => {
  return new MVEntityAvailable(PermissionsKeys.ManageBids, _data, _isHidden);
};

export function collegeProfileReducer(
  state: ICollegeProfileState = initialState,
  action: Action,
): ICollegeProfileState {
  return reducer(state, action);
}
