import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Observable, Subject } from "rxjs";
import { shareReplay, takeUntil } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { getIsLoggedIn } from "@modules/auth/store/selectors/auth.selector";
import {
  getIsAdminsAccount,
  getIsBroker,
} from "@modules/profile/store/selectors/profile.selector";

import { LayoutService } from "@core/services/layout.service";
import { ViewPointsService } from "@ui/view-points/services/view-points.service";
import { AppService } from "./app.service";

import {
  ROUTER_TO_SHOW_MOBILE_FOOTER,
  ROUTES_TO_HIDE_LAYOUT,
} from "@core/constants/emails";
import { WINDOW_POINTS } from "@ui/view-points/constants/view-points";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";

// TODO SPLIT COMPONENT LOGIC TO SERVICES;
@Component({
  selector: "bl-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly windowPoints: typeof WINDOW_POINTS = WINDOW_POINTS;
  readonly mobileFooterRoutes: Array<string> = ROUTER_TO_SHOW_MOBILE_FOOTER;
  readonly routesToHideLayout: Array<string> = ROUTES_TO_HIDE_LAYOUT;
  isRootElementFixed: boolean;
  shouldShowOverlay$: Observable<boolean> =
    this._layoutService.shouldShowOverlay;
  isBroker$: Observable<boolean> = this._store.pipe(
    select(getIsBroker),
    shareReplay(),
  );
  isAdminsAccount$: Observable<boolean> = this._store.pipe(
    select(getIsAdminsAccount),
    shareReplay(),
  );
  isLoggedIn$: Observable<boolean> = this._store.pipe(
    select(getIsLoggedIn),
    shareReplay(),
  );
  private _destroyer$: Subject<void> = new Subject<void>();

  constructor(
    private _store: Store<CoreState>,
    private _layoutService: LayoutService,
    private _viewPointsService: ViewPointsService,
    private _appService: AppService,
    private _chdRef: ChangeDetectorRef,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {}

  @HostListener("window:resize", ["$event"])
  setWidthWindow(): void {
    this._viewPointsService.checkViewPoints();
  }

  ngOnInit(): void {
    this._appService.changeDetectionTrigger
      .pipe(takeUntil(this._destroyer$))
      .subscribe(() => {
        this._chdRef.detectChanges();
      });

    this._viewPointsService.checkViewPoints();
    this._appService.subscribeToRouterEvents();
    // this._appService.detectBrowserRefresh();
    this._appService.subscribeToErrors();
    this.autoRefreshWebSocket();
    this._appService.appendExternalServices();
    this._appService.detectNavigationEvents();
  }

  ngAfterViewInit(): void {
    this._appService.setRootElementFixedOnIOS();
    this._appService.checkUnsupportedBrowserAndNotify();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();

    this._appService.destroyer$.next();
    this._appService.destroyer$.complete();
  }

  autoRefreshWebSocket() {
    if (this.oktaAuth && this.oktaAuth.authStateManager) {
      this.oktaAuth.authStateManager.subscribe(({ accessToken }) =>
        this._appService.runWs(),
      );
    } else {
      this._appService.runWs();
    }
  }

  emptyClick(): void {
    /*
     * It just does nothing...
     * This workaround is needed to make a whole container clickable to enable mouseleave events on an iOS devices working properly.
     * Bug - https://schooldatamdr.atlassian.net/browse/BL-822
     * Reference - https://www.codediesel.com/javascript/making-mouseover-event-work-on-an-ipad/
     * */
  }
}
