import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-bids.action";

import { IBidsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { BIDS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsBidsState {
  bids: IBidsRecord[];
  count: number;

  pending: boolean;
  error: IServerError | null;
}

const initialState: IQuickSearchResultsBidsState = {
  bids: [],

  count: 0,

  pending: false,
  error: null,
};

const quickSearchBids: OnReducer<
  IQuickSearchResultsBidsState,
  ActionType<any>
> = (state: IQuickSearchResultsBidsState) => ({
  ...state,
  pending: true,
  error: null,
});

const getQuickSearchBidsError: OnReducer<
  IQuickSearchResultsBidsState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsBidsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getQuickSearchBidsSuccess: OnReducer<
  IQuickSearchResultsBidsState,
  ActionType<Payload<any>>
> = (state: IQuickSearchResultsBidsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  bids: [...payload.items],
  count: payload.count,
});

const setQuickSearchResultsBidsSpinnerState: OnReducer<
  IQuickSearchResultsBidsState,
  ActionType<Payload<any>>
> = (state: IQuickSearchResultsBidsState, { payload }: Payload<any>) => ({
  ...state,
  pending: payload,
});

const resetQuickSearchResultsBidsState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsBidsState> =
  createReducer<IQuickSearchResultsBidsState>(
    initialState,

    on(actions.getQuickSearchBidsAction, quickSearchBids),
    on(actions.getQuickSearchBidsErrorAction, getQuickSearchBidsError),
    on(actions.getQuickSearchBidsSuccessAction, getQuickSearchBidsSuccess),

    on(actions.changeQuickSearchBidsQueryAction, quickSearchBids),
    on(actions.changeQuickSearchBidsPageAction, quickSearchBids),

    on(
      actions.setQuickSearchResultsBidsSpinnerStateAction,
      setQuickSearchResultsBidsSpinnerState,
    ),

    on(
      actions.resetQuickSearchResultsBidsStateAction,
      resetQuickSearchResultsBidsState,
    ),
  );

export function quickSearchResultsBidsReducer(
  state: IQuickSearchResultsBidsState,
  action: Action,
): IQuickSearchResultsBidsState {
  return reducer(state, action);
}

export const bids: GetFromState<IBidsRecord[], IQuickSearchResultsBidsState> = (
  state: IQuickSearchResultsBidsState,
): IBidsRecord[] => state.bids;
export const bidsPending: GetFromState<
  boolean,
  IQuickSearchResultsBidsState
> = (state: IQuickSearchResultsBidsState): boolean => state.pending;
export const bidsError: GetFromState<
  IServerError,
  IQuickSearchResultsBidsState
> = (state: IQuickSearchResultsBidsState): IServerError => state.error;

export const bidsCount: GetFromState<number, IQuickSearchResultsBidsState> = (
  state: IQuickSearchResultsBidsState,
): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  const { sortBy }: Params = queryParams;

  return {
    ...BIDS_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    sortBy: sortBy || "submittal",
    filterBy: "bids",
  };
};
