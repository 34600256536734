import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "bl-list-cloud-sync-meta",
  templateUrl: "./list-cloud-sync-meta.component.html",
  styleUrls: ["./list-cloud-sync-meta.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListCloudSyncMetaComponent {
  @Input() listId: number;
  @Input() isProcessing: boolean;
}
