import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cart.action";

import { StorageService } from "@core/services/storage.service";

import { IServerError } from "@shared/interfaces/server-error";
import {
  ICartItemForOrder,
  IProductUpdate,
  IPromoCodeError,
  IValidateProduct,
} from "../../interfaces/e-commerce";
import {
  IPrice,
  ISalesOrderTaxData,
  ISalesOrderTaxItem,
  ITaxDataItem,
} from "../../interfaces/price";

import { PROMO_TYPES } from "@shared/constants/promo-types";
import { DEFAULT_TAX_DETAILS } from "../../constants/payment";

export interface ICartState {
  items: IPrice[];

  itemsIsUpdating: boolean;
  isOpenCartItem: boolean;
  updatingProduct: IProductUpdate | null;

  isValidatingCart: boolean;
  cartValidationError: IServerError | null;
  cartValidationStatus: boolean;

  taxData: ISalesOrderTaxData;
  taxError: IServerError | null;
  taxPending: boolean;
}

const initialState: ICartState = {
  items: StorageService.eCommerceCart || [],

  itemsIsUpdating: false,
  isOpenCartItem: false,
  updatingProduct: null,

  isValidatingCart: null,
  cartValidationError: null,
  cartValidationStatus: false,

  taxData: null,
  taxError: null,
  taxPending: false,
};

const addItemToCart: OnReducer<ICartState, ActionType<Payload<any>>> = (
  state: ICartState,
  { payload }: Payload<any>,
) => ({
  ...state,
  items: [...state.items, payload] as IPrice[],
  cartValidationError: null,
});

const removeItemFromCart: OnReducer<ICartState, ActionType<Payload<any>>> = (
  state: ICartState,
  { payload }: Payload<any>,
) => ({
  ...state,
  items: state.items.filter(
    (item: IPrice, itemIndex: number) => itemIndex !== payload,
  ),
  cartValidationError: null,
});

const updatePromoCode: OnReducer<ICartState, ActionType<Payload<any>>> = (
  state: ICartState,
  { payload }: Payload<any>,
) => ({
  ...state,
  itemsIsUpdating: true,
  updatingProduct: {
    productId: payload.productId,
    index: payload.index,
  },
});

const updatePromoCodeError: OnReducer<
  ICartState,
  ActionType<Payload<IPromoCodeError>>
> = (state: ICartState, { payload }: Payload<IPromoCodeError>) => {
  const { productId, error, index }: IPromoCodeError = payload;
  const errorMsg: string[] = error
    ? error.errors.promoCode || [error.message]
    : null;

  return {
    ...state,
    itemsIsUpdating: false,
    items: state.items.map((item: IPrice, itemIndex: number) => {
      if (item.productId === productId && itemIndex === index) {
        return {
          ...item,
          promo: null,
          error: errorMsg,
        };
      }
      return item;
    }),
  };
};

const updatePromoCodeSuccess: OnReducer<
  ICartState,
  ActionType<Payload<any>>
> = (state: ICartState, { payload }: Payload<any>) => {
  const { productId, promoDetails, index }: any = payload;

  return {
    ...state,
    itemsIsUpdating: false,
    updatingProduct: null,
    items: state.items.map((item: IPrice, itemIndex: number) => {
      if (item.productId === productId && itemIndex === index) {
        return {
          ...item,
          promo: promoDetails,
          error: null,
        };
      }
      return item;
    }),
  };
};

const resetProductPromoCodeError: OnReducer<
  ICartState,
  ActionType<Payload<number>>
> = (state: ICartState, { payload }: Payload<number>) => ({
  ...state,
  items: state.items.map((item: IPrice) => {
    if (item.productId === payload) {
      return {
        ...item,
        promo: null,
        error: null,
      };
    }
    return item;
  }),
});

const validateCart: OnReducer<ICartState, ActionType<any>> = (
  state: ICartState,
) => ({
  ...state,
  cartValidationError: null,
  isValidatingCart: true,
});

const validateCartError: OnReducer<
  ICartState,
  ActionType<Payload<IServerError>>
> = (state: ICartState, { payload }: Payload<IServerError>) => ({
  ...state,
  isValidatingCart: false,
  cartValidationError: { ...payload },
  cartValidationStatus: false,
});

const validateCartSuccess: OnReducer<ICartState, ActionType<Payload<any>>> = (
  state: ICartState,
  { payload }: Payload<any>,
) => ({
  ...state,
  isValidatingCart: false,
  cartValidationError: null,
  cartValidationStatus: payload.cartValidationStatus,
});

const resetStatusValidateCart: OnReducer<ICartState, ActionType<any>> = (
  state: ICartState,
) => ({
  ...state,
  cartValidationStatus: false,
});

const getTax: OnReducer<ICartState, ActionType<any>> = (state: ICartState) => ({
  ...state,
  taxPending: true,
  taxError: null,
});

const getTaxError: OnReducer<ICartState, ActionType<Payload<IServerError>>> = (
  state: ICartState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  taxPending: false,
  taxError: { ...payload },
});

const getTaxSuccess: OnReducer<ICartState, ActionType<Payload<any>>> = (
  state: ICartState,
  { payload }: Payload<any>,
) => ({
  ...state,
  taxPending: false,
  taxData: {
    ...payload,
    cartItems: [...payload.cartItems].map(
      (taxItem: ITaxDataItem) =>
        ({
          productId: taxItem.catalogId,
          name: taxItem.catalogCode,
          taxRate: (taxItem.itemTaxAmount * 100) / taxItem.itemNetAmount,
          taxAmount: taxItem.itemTaxAmount,
        }) as ISalesOrderTaxItem,
    ),
  },
});

const resetTax: OnReducer<ICartState, ActionType<any>> = (
  state: ICartState,
) => ({
  ...state,
  taxPending: false,
  taxError: null,
  taxData: null,
});

const resetECommerceCart: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
  items: [],
});

const reducer: ActionReducer<ICartState> = createReducer<ICartState>(
  initialState,

  on(actions.addItemToCartAction, addItemToCart),
  on(actions.removeItemFromCartAction, removeItemFromCart),
  on(actions.removeTaxedItemFromCardAction, removeItemFromCart),

  on(actions.updatePromoCodeAction, updatePromoCode),
  on(actions.updatePromoCodeErrorAction, updatePromoCodeError),
  on(actions.updatePromoCodeSuccessAction, updatePromoCodeSuccess),
  on(actions.resetProductPromoCodeErrorAction, resetProductPromoCodeError),

  on(actions.validateCartAction, validateCart),
  on(actions.validateCartErrorAction, validateCartError),
  on(actions.validateCartSuccessAction, validateCartSuccess),

  on(actions.resetStatusValidateCartAction, resetStatusValidateCart),

  on(actions.getTaxAction, getTax),
  on(actions.getTaxErrorAction, getTaxError),
  on(actions.getTaxSuccessAction, getTaxSuccess),
  on(actions.resetTaxAction, resetTax),

  on(actions.resetECommerceCartAction, resetECommerceCart),
);

export function cartReducer(state: ICartState, action: Action): ICartState {
  return reducer(state, action);
}

export const cartItems: GetFromState<IPrice[], ICartState> = (
  state: ICartState,
): IPrice[] => state.items;

export const itemsIsUpdating: GetFromState<boolean, ICartState> = (
  state: ICartState,
): boolean => state.itemsIsUpdating;
export const updatingProduct: GetFromState<
  IProductUpdate | null,
  ICartState
> = (state: ICartState): IProductUpdate | null => state.updatingProduct;
export const cartValidationError: GetFromState<
  IServerError | null,
  ICartState
> = (state: ICartState): IServerError | null => state.cartValidationError;

export const tax: GetFromState<ISalesOrderTaxData, ICartState> = (
  state: ICartState,
): ISalesOrderTaxData => state.taxData;
export const taxPending: GetFromState<boolean, ICartState> = (
  state: ICartState,
): boolean => state.taxPending;
export const taxAmount: GetFromState<number, ISalesOrderTaxData> = (
  taxData: ISalesOrderTaxData,
): number => (taxData && taxData.taxAmount) || 0;

export const cartItemsQuantity: GetFromState<number, any[]> = (
  items: any[],
): number => items.length;

export const cartItemsForValidation: GetFromState<
  IValidateProduct[],
  ICartState
> = (state: ICartState): IValidateProduct[] =>
  state.items.map((cart: IPrice): IValidateProduct => {
    const { productId, priceId }: Partial<IPrice> = cart;
    return { productId, priceId } as IValidateProduct;
  });

export const sortedCartItems: GetFromState<any, IPrice[]> = (
  items: IPrice[],
): any => {
  return items.map((item: IPrice) => {
    const storageItem: IPrice = { ...item };
    delete storageItem.promo;
    return storageItem;
  });
};

export const validateCartItemsForOrder: GetFromState<
  ICartItemForOrder[],
  IPrice[],
  ISalesOrderTaxData
> = (items: IPrice[], taxData: ISalesOrderTaxData): ICartItemForOrder[] =>
  items.map((item: IPrice) => {
    const { productId, priceId }: Partial<IPrice> = item;
    const taxItem: ISalesOrderTaxItem =
      taxData &&
      taxData.cartItems.find(
        (_item: ISalesOrderTaxItem) => _item.productId === productId,
      );

    return {
      productId: productId,
      priceId: priceId,
      countryCode: "US",
      taxDetail:
        taxData && taxItem
          ? {
              taxRate: taxItem.taxRate,
              taxCode: taxItem.name,
              taxAmount: taxItem.taxAmount,
            }
          : DEFAULT_TAX_DETAILS,
    };
  });

export const cartSubTotalPrice: GetFromState<number, any[]> = (
  items: any[],
): number =>
  items.reduce((acc: any, item: any) => {
    if (item.promo && item.promo.applicable_product_catalog_ids.length) {
      if (item.promo.promotion_subtype_code === PROMO_TYPES.value) {
        return (acc += item.price - item.promo.promotion_subtype_value);
      } else if (item.promo.promotion_subtype_code === PROMO_TYPES.percent) {
        return (acc +=
          item.price - (item.price / 100) * item.promo.promotion_subtype_value);
      }
    }

    return (acc += item.price);
  }, 0);

export const cartTotalPriceWithTax: GetFromState<number, IPrice[], number> = (
  items: IPrice[],
  taxAmountValue: number,
): number => {
  return (
    items.reduce((acc: number, item: IPrice) => (acc += item.price), 0) +
    taxAmountValue
  );
};

export const cartGrandTotal: GetFromState<number, number, number> = (
  taxAmountData: number,
  subTotal: number,
): number => {
  return Math.round((taxAmountData + subTotal) * 100) / 100;
};

export const canCheckout: GetFromState<boolean, boolean, number> = (
  isLoggedIn: boolean,
  quantity: number,
): boolean => isLoggedIn && quantity >= 0;
export const cartTotalCredits: GetFromState<number, IPrice[]> = (
  items: IPrice[],
): number =>
  items.reduce((acc: number, item: IPrice) => (acc += item.number), 0);
