import { IOwlCardCodes, ITaxDetail } from "../interfaces/e-commerce";

// TRASACTION FIELDS
export enum SALE_TRANSACTION_FIELDS {
  // eslint-disable-next-line
  SIGNED = 'access_key,profile_id,reference_number,payment_token,consumer_id,transaction_type,amount,currency,locale,transaction_uuid,signed_date_time,signed_field_names,unsigned_field_names,override_custom_receipt_page,orderPage_declineResponseURL,customer_ip_address',
  UNSIGNED = "",
}

export enum CREATE_TRANSACTION_FIELDS {
  // eslint-disable-next-line
  SIGNED = 'access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_phone,bill_to_address_line1,bill_to_address_city,bill_to_address_state,bill_to_address_country,bill_to_address_postal_code,override_custom_receipt_page,orderPage_declineResponseURL,customer_ip_address',
  UNSIGNED = "card_type,card_number,card_expiry_date,card_cvn",
}

// TRANSACTION
export enum PAYMENT_METHOD {
  CARD = "card",
}

export enum TRANSACTION_TYPE {
  SALE = "sale",
  CREATE = "sale,create_payment_token",
}

// CREDIT_CARD_TYPES
export enum CARD_TYPE_NAMES {
  VS = "Visa",
  MC = "MasterCard",
  AMEX = "American Express",
  DS = "Discover",
  DN = "Diners",
}

export enum OWL_CARD_TYPE_CODES {
  VS = "VS",
  MC = "MC",
  AMEX = "AMEX",
  DS = "DS",
  DN = "DN",
}

export enum CYBER_SOURCE_TYPE_CARD_CODES {
  VS = "001",
  MC = "002",
  AMEX = "003",
  DS = "004",
  DN = "005",
}

export const CART_OWL_TYPE_CODES: { [key: string]: IOwlCardCodes } = {
  VS: {
    owl: OWL_CARD_TYPE_CODES.VS,
    cyberSource: CYBER_SOURCE_TYPE_CARD_CODES.VS,
    name: CARD_TYPE_NAMES.VS,
  },
  MC: {
    owl: OWL_CARD_TYPE_CODES.MC,
    cyberSource: CYBER_SOURCE_TYPE_CARD_CODES.MC,
    name: CARD_TYPE_NAMES.MC,
  },
  AMEX: {
    owl: OWL_CARD_TYPE_CODES.AMEX,
    cyberSource: CYBER_SOURCE_TYPE_CARD_CODES.AMEX,
    name: CARD_TYPE_NAMES.AMEX,
  },
  DS: {
    owl: OWL_CARD_TYPE_CODES.DS,
    cyberSource: CYBER_SOURCE_TYPE_CARD_CODES.DS,
    name: CARD_TYPE_NAMES.DS,
  },
  DN: {
    owl: OWL_CARD_TYPE_CODES.DN,
    cyberSource: CYBER_SOURCE_TYPE_CARD_CODES.DN,
    name: CARD_TYPE_NAMES.DN,
  },
};

// transaction error codes
export enum TRANSACTION_ERROR_CODES {
  REQUIRED_FIELD = 101,
  INVALID_CREDIT_CARD_INFO = 102,
  EXPIRED_CARD = 202,
  CARD_DECLINE = 203,
  INSUFFICIENT_FUNDS = 204,
  MAX_LIMIT = 210,
  INVALID_CVV = 211,
  NOT_ACCEPTED = 232,
  WRONG_MERCHANT_CONFIG = 234,
  INVALID_CARD_TYPE = 240,
  TIME_OUT = 250,
}

export enum TRANSACTION_ERROR_CODES_DESCRIPTION {
  REQUIRED_FIELD = "Required fields missing.",
  INVALID_CREDIT_CARD_INFO = "Invalid credit card info.",
  EXPIRED_CARD = "Expired card.",
  INVALID_CVV = "Invalid CVV.",
  CARD_DECLINE = "Card declined.",
  INSUFFICIENT_FUNDS = "Insufficient Funds.",
  CARD_NUMBER = "Invalid Credit Card number.",
  LAST_FOUR_DIGITS = "Invalid Credit Card number.",
  MAX_LIMIT = "The card has reached the credit limit.",
  NOT_ACCEPTED = "The card type is not accepted by the payment processor.",
  // eslint-disable-next-line
  WRONG_MERCHANT_CONFIG = 'There is a problem with your CyberSource merchant configuration. Contact Customer Support to correct the configuration problem.',
  INVALID_CARD_TYPE = "The card type sent is invalid or does not correlate with the card number.",
  TIME_OUT = "The request was received, but there was a timeout at the payment processor.",
}

export const TRANSACTION_ERROR_CODES_NSG: {
  [key: string]: TRANSACTION_ERROR_CODES_DESCRIPTION;
} = {
  [TRANSACTION_ERROR_CODES.REQUIRED_FIELD]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.REQUIRED_FIELD,
  [TRANSACTION_ERROR_CODES.INVALID_CREDIT_CARD_INFO]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.INVALID_CREDIT_CARD_INFO,
  [TRANSACTION_ERROR_CODES.EXPIRED_CARD]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.EXPIRED_CARD,
  [TRANSACTION_ERROR_CODES.INVALID_CVV]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.INVALID_CVV,
  [TRANSACTION_ERROR_CODES.CARD_DECLINE]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.CARD_DECLINE,
  [TRANSACTION_ERROR_CODES.MAX_LIMIT]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.MAX_LIMIT,
  [TRANSACTION_ERROR_CODES.INSUFFICIENT_FUNDS]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.INSUFFICIENT_FUNDS,
  [TRANSACTION_ERROR_CODES.NOT_ACCEPTED]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.NOT_ACCEPTED,
  [TRANSACTION_ERROR_CODES.WRONG_MERCHANT_CONFIG]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.WRONG_MERCHANT_CONFIG,
  [TRANSACTION_ERROR_CODES.INVALID_CARD_TYPE]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.INVALID_CARD_TYPE,
  [TRANSACTION_ERROR_CODES.TIME_OUT]:
    TRANSACTION_ERROR_CODES_DESCRIPTION.TIME_OUT,
};

export enum TRANSACTION_FIELD_ERRORS {
  card_number = TRANSACTION_ERROR_CODES_DESCRIPTION.CARD_NUMBER,
  card_cvn = TRANSACTION_ERROR_CODES_DESCRIPTION.INVALID_CVV,
  card_expiry_date = TRANSACTION_ERROR_CODES_DESCRIPTION.EXPIRED_CARD,
  card_expiration_date = TRANSACTION_ERROR_CODES_DESCRIPTION.EXPIRED_CARD,
  last_four_digits = TRANSACTION_ERROR_CODES_DESCRIPTION.LAST_FOUR_DIGITS,
}

// default data
export const DEFAULT_COUNTRY_NAME: string = "United States of America";
export const INVALID_DATA_MSG: string = "Invalid card info. Please try again.";
export const DEFAULT_COUNTRY_CODE: string = "US";
export const DEFAULT_CURRENCY: string = "USD";

export const DEFAULT_TAX_DETAILS: ITaxDetail = {
  taxRate: 0,
  taxCode: "",
  taxAmount: 0,
};

// Add more if will be needed
export enum PAYMENT_TYPES {
  EMAIL = "email",
}

export const DEFAULT_EMAIL_CREDITS_PRICE: number = 1;

export const TRANSACTION_FIELDS: string[] = [
  "access_key",
  "profile_id",
  "transaction_uuid",
  "signed_field_names",
  "unsigned_field_names",
  "signed_date_time",
  "locale",
  "transaction_type",
  "reference_number",
  "amount",
  "currency",
  "payment_method",
  "bill_to_forename",
  "bill_to_surname",
  "bill_to_email",
  "bill_to_phone",
  "bill_to_address_line1",
  "bill_to_address_city",
  "bill_to_address_state",
  "bill_to_address_country",
  "bill_to_address_postal_code",
  "signature",
  "override_custom_receipt_page",
  "orderPage_declineResponseURL",
  "customer_ip_address",
  "card_type",
  "card_number",
  "card_expiry_date",
  "card_cvn",
];
