import {
  IChangeTargetingCriteria,
  IChangeTargetingCriteriaData,
  IOnSegmentUpdateDataControls,
  IOnSegmentUpdateDetailedDataControls,
  IOnUpdateDataControls,
  ISwitchingCheckboxes,
  ITargetingCriteriaControls,
  ITargetingCriteriaDataItemCheckboxesData,
  ITargetingCriteriaDataItemCheckboxesDataItem,
  ITargetingCriteriaDataItemControlDataItem,
  ITargetingCriteriaDataItemDatesDataItem,
  ITargetingCriteriaDataItemRadioButtonsData,
  ITargetingCriteriaDataItemRadioButtonsDataItem,
  ITargetingCriteriaDataItemSlidersData,
  ITargetingCriteriaDataItemSlidersDataItem,
  ITargetingCriteriaDataItemSwitchCheckboxData,
  ITargetingCriteriaDataItemSwitchCheckboxDataItem,
  TARGETING_DATA_CONTROL_TYPES,
} from "../../targeting-criteria-controls/data";
import {
  getOnlySelectedTargetingControls,
  isControlSelected,
  switchCheckboxToCriteria,
} from "./helpers";

export const controlsToOutput: (
  control: ITargetingCriteriaDataItemControlDataItem,
  data: ITargetingCriteriaControls,
  groupType: TARGETING_DATA_CONTROL_TYPES,
) => IChangeTargetingCriteriaData = (
  control: ITargetingCriteriaDataItemControlDataItem,
  data: ITargetingCriteriaControls,
  groupType: TARGETING_DATA_CONTROL_TYPES,
): IChangeTargetingCriteriaData => {
  // CONVERT SELECT ALL CONTROLS TO SEND CRITERIA
  if (!control) {
    if (groupType === TARGETING_DATA_CONTROL_TYPES.SWITCH_CHECKBOX) {
      if (
        data &&
        data.length &&
        data[0].type === TARGETING_DATA_CONTROL_TYPES.SWITCH_CHECKBOX
      ) {
        return {
          switchingCheckboxes: [
            ...(data as ITargetingCriteriaDataItemSwitchCheckboxData).map(
              (controlItem: ITargetingCriteriaDataItemSwitchCheckboxDataItem) =>
                switchCheckboxToCriteria(controlItem, true),
            ),
          ],
        };
      }

      return { switchingCheckboxes: [] };
    }

    return {
      checkboxIds: (data as ITargetingCriteriaDataItemCheckboxesData).map(
        (item: ITargetingCriteriaDataItemCheckboxesDataItem) => item.checkboxId,
      ),
    };
  }

  // CONVERT CHECKBOXES CONTROLS TO SEND CRITERIA
  if (control.type === TARGETING_DATA_CONTROL_TYPES.CHECKBOX) {
    const selectedControls: ITargetingCriteriaControls =
      getOnlySelectedTargetingControls(data, false);
    let checkboxIds: number[] = [];

    if (isControlSelected(control)) {
      checkboxIds = [
        ...(selectedControls as ITargetingCriteriaDataItemCheckboxesData)
          .filter(
            (item: ITargetingCriteriaDataItemCheckboxesDataItem) =>
              item.checkboxId,
          )
          .map(
            (item: ITargetingCriteriaDataItemCheckboxesDataItem) =>
              item.checkboxId,
          ),
        control.checkboxId,
      ];
    } else {
      checkboxIds = (
        selectedControls as ITargetingCriteriaDataItemCheckboxesData
      )
        .filter(
          (item: ITargetingCriteriaDataItemCheckboxesDataItem) =>
            item.checkboxId && item.checkboxId !== control.checkboxId,
        )
        .map(
          (item: ITargetingCriteriaDataItemCheckboxesDataItem) =>
            item.checkboxId,
        );
    }

    return {
      checkboxIds,
    };
  }

  // CONVERT SWITCH CHECKBOXES CONTROLS TO SEND CRITERIA
  if (control.type === TARGETING_DATA_CONTROL_TYPES.SWITCH_CHECKBOX) {
    const selectedControls: ITargetingCriteriaControls =
      getOnlySelectedTargetingControls(data, false);
    const currentOlControl: ITargetingCriteriaDataItemSwitchCheckboxDataItem = (
      selectedControls as ITargetingCriteriaDataItemSwitchCheckboxData
    ).find(
      (item: ITargetingCriteriaDataItemSwitchCheckboxDataItem) =>
        item.checkboxId === control.checkboxId,
    );

    let switchingCheckboxes: ISwitchingCheckboxes = [];

    if (isControlSelected(control)) {
      if (currentOlControl) {
        switchingCheckboxes = [
          ...(
            selectedControls as ITargetingCriteriaDataItemSwitchCheckboxData
          ).map(
            (controlItem: ITargetingCriteriaDataItemSwitchCheckboxDataItem) =>
              switchCheckboxToCriteria(
                controlItem.checkboxId === control.checkboxId
                  ? control
                  : controlItem,
              ),
          ),
        ];
      } else {
        switchingCheckboxes = [
          ...(
            selectedControls as ITargetingCriteriaDataItemSwitchCheckboxData
          ).map(
            (controlItem: ITargetingCriteriaDataItemSwitchCheckboxDataItem) =>
              switchCheckboxToCriteria(controlItem),
          ),
          switchCheckboxToCriteria(control),
        ];
      }
    } else {
      switchingCheckboxes = (
        selectedControls as ITargetingCriteriaDataItemSwitchCheckboxData
      )
        .filter(
          (controlItem: ITargetingCriteriaDataItemSwitchCheckboxDataItem) =>
            controlItem.checkboxId !== control.checkboxId,
        )
        .map((controlItem: ITargetingCriteriaDataItemSwitchCheckboxDataItem) =>
          switchCheckboxToCriteria(controlItem),
        );
    }

    return {
      switchingCheckboxes,
    };
  }

  // CONVERT RADIO CONTROLS TO SEND CRITERIA
  if (control.type === TARGETING_DATA_CONTROL_TYPES.RADIO) {
    return { checkboxIds: control.status ? [control.checkboxId] : [] };
  }

  // CONVERT RADIO BUTTON CONTROLS TO SEND CRITERIA
  if (control.type === TARGETING_DATA_CONTROL_TYPES.RADIO_BUTTON) {
    const selectedRadioButtonId: ITargetingCriteriaDataItemRadioButtonsDataItem =
      (data as ITargetingCriteriaDataItemRadioButtonsData).find(
        (item: ITargetingCriteriaDataItemRadioButtonsDataItem) => item.status,
      );

    return !selectedRadioButtonId ||
      selectedRadioButtonId.radioButtonId !== control.radioButtonId
      ? { radioButtonId: control.radioButtonId }
      : {};
  }

  // CONVERT SLIDERS CONTROLS TO SEND CRITERIA
  if (control.type === TARGETING_DATA_CONTROL_TYPES.SLIDER) {
    const { min, max }: ITargetingCriteriaDataItemSlidersDataItem = control;
    const {
      min: oldMin,
      max: oldMax,
    }: ITargetingCriteriaDataItemSlidersDataItem = (
      data as ITargetingCriteriaDataItemSlidersData
    )[0];

    return min !== oldMin || max !== oldMax
      ? {
          slider: {
            min,
            max,
          },
        }
      : {};
  }

  // CONVERT DATES CONTROLS TO SEND CRITERIA
  if (control.type === TARGETING_DATA_CONTROL_TYPES.DATE) {
    const { current: date, dateId }: ITargetingCriteriaDataItemDatesDataItem =
      control;
    return {
      date,
      dateId,
    };
  }
};

export const controlsChangeToCriteria: (
  payload: IOnSegmentUpdateDataControls | IOnSegmentUpdateDetailedDataControls,
) => IChangeTargetingCriteria = (
  payload: IOnSegmentUpdateDataControls | IOnSegmentUpdateDetailedDataControls,
): IChangeTargetingCriteria => {
  delete (payload.data as IOnUpdateDataControls).detailedData;
  const {
    segmentCriteriaId,
    data: { control, data, groupType, ...rest },
  }: IOnSegmentUpdateDataControls | IOnSegmentUpdateDetailedDataControls =
    payload;
  return {
    segmentCriteriaId,
    data: {
      ...rest,
      ...controlsToOutput(control, data, groupType),
    },
  };
};
