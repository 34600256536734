import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view.action";

import { IServerError } from "@shared/interfaces/server-error";
import { IViewData } from "@shared/interfaces/view";

export interface ICustomerViewState {
  view: IViewData | null;
  pending: boolean;
  error: IServerError | null;

  renaming: boolean;
  renameError: IServerError | null;
}

const initialState: ICustomerViewState = {
  view: null,
  pending: false,
  error: null,

  renaming: false,
  renameError: null,
};

const viewPending: OnReducer<ICustomerViewState, ActionType<any>> = (
  state: ICustomerViewState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const errorView: OnReducer<ICustomerViewState, ActionType<Payload<any>>> = (
  state: ICustomerViewState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getViewSuccess: OnReducer<
  ICustomerViewState,
  ActionType<Payload<any>>
> = (state: ICustomerViewState, { payload }: Payload<any>) => ({
  ...state,
  pending: !!payload.listingCountsCalculating,
  view: { ...payload },
});

const reducer: ActionReducer<ICustomerViewState> =
  createReducer<ICustomerViewState>(
    initialState,

    on(actions.getViewAction, viewPending),
    on(actions.reloadViewAction, viewPending),

    on(actions.getViewSuccessAction, getViewSuccess),
    on(actions.reloadViewSuccessAction, getViewSuccess),

    on(actions.getViewErrorAction, errorView),
    on(actions.reloadViewErrorAction, errorView),
  );

export function commonCustomerViewReducer(
  state: ICustomerViewState,
  action: Action,
): ICustomerViewState {
  return reducer(state, action);
}

export const view: GetFromState<IViewData, ICustomerViewState> = (
  state: ICustomerViewState,
): IViewData => state && state.view;

export const viewId: GetFromState<number, IViewData> = (
  viewData: IViewData,
): number => (viewData ? viewData.id : null);
