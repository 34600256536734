import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import * as actions from "@core/store/actions/list-large-download.actions";
import { Payload } from "@shared/interfaces/store";

import { IServerError } from "@shared/interfaces/server-error";

export interface ListLargeDownloadState {
  token: string;
  available: boolean;

  checking: boolean;
  loading: boolean;

  largeDownloadError: IServerError | null;
}

const initialState: ListLargeDownloadState = {
  token: null,
  available: true,

  checking: false,
  loading: false,

  largeDownloadError: null,
};

const checkDownloadListByToken: OnReducer<
  ListLargeDownloadState,
  ActionType<any>
> = (state: ListLargeDownloadState, { payload: { token } }: Payload<any>) => ({
  ...state,
  checking: true,
  token: token,
  largeDownloadError: null,
});

const startDownloadLargeListByToken: OnReducer<
  ListLargeDownloadState,
  ActionType<any>
> = (state: ListLargeDownloadState) => ({
  ...state,
  checking: false,
  loading: true,
  largeDownloadError: null,
});

const downloadLargeListError: OnReducer<
  ListLargeDownloadState,
  ActionType<any>
> = (
  state: ListLargeDownloadState,
  { payload }: Payload<any>,
): ListLargeDownloadState => ({
  ...state,
  checking: false,
  loading: false,
  largeDownloadError: { ...payload },
});

const reducer: ActionReducer<ListLargeDownloadState> =
  createReducer<ListLargeDownloadState>(
    initialState,

    on(actions.checkDownloadListByTokenAction, checkDownloadListByToken),
    on(
      actions.startDownloadLargeListByTokenAction,
      startDownloadLargeListByToken,
    ),
    on(actions.downloadLargeListErrorAction, downloadLargeListError),
  );

export function customerListLargeDownloadReducer(
  state: ListLargeDownloadState = initialState,
  action: Action,
): ListLargeDownloadState {
  return reducer(state, action);
}
