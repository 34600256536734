import { createFeatureSelector, createSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { ISpinnerState } from "@ui/spinner/store/reducers/spinner.reducer";
import { ISpinnerModuleState, SPINNER_MODULE_FEATURE_NAME } from "../reducers";

export const getSpinnerModuleState: GetFromStateInSelectors<
  CoreState,
  ISpinnerModuleState
> = createFeatureSelector<ISpinnerModuleState>(SPINNER_MODULE_FEATURE_NAME);
export const selectSpinnerState: GetFromStateInSelectors<
  CoreState,
  ISpinnerState
> = createSelector(
  getSpinnerModuleState,
  (state: ISpinnerModuleState) => state && state.spinner,
);
