import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/bids.action";

import { IServerError } from "@shared/interfaces/server-error";

import { MIN_LENGTH_FOR_SEARCH } from "../../constants/autocomplete";

export interface IBidsState {
  keywords: string[];
  keywordsError: IServerError;
  include: boolean;
  shouldReset: boolean;
}

const initialState: IBidsState = {
  keywords: [],
  keywordsError: null,
  include: true,
  shouldReset: false,
};

const getBidsKeywords: OnReducer<IBidsState, ActionType<Payload<any>>> = (
  state: IBidsState,
  { payload }: Payload<any>,
) => ({
  ...state,
  keywordsError: null,
  keywords: payload.length >= MIN_LENGTH_FOR_SEARCH ? [...state.keywords] : [],
  shouldReset: payload.length < MIN_LENGTH_FOR_SEARCH,
});

const getBidsKeywordsError: OnReducer<
  IBidsState,
  ActionType<Payload<IServerError>>
> = (state: IBidsState, { payload }: Payload<IServerError>) => ({
  ...state,
  keywordsError: { ...payload },
  shouldReset: false,
});

const getBidsKeywordsSuccess: OnReducer<
  IBidsState,
  ActionType<Payload<any>>
> = (state: IBidsState, { payload }: Payload<any>) => ({
  ...state,
  keywords: state.shouldReset ? [] : [...payload],
  shouldReset: false,
});

const resetBidsKeywords: OnReducer<IBidsState, ActionType<any>> = (
  state: IBidsState,
) => ({
  ...state,
  keywords: [],
  shouldReset: true,
});

const toggleIncludeBids: OnReducer<IBidsState, ActionType<any>> = (
  state: IBidsState,
) => ({
  ...state,
  include: !state.include,
});

const bidsReset: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IBidsState> = createReducer<IBidsState>(
  initialState,

  on(actions.getBidsKeywordsAction, getBidsKeywords),
  on(actions.getBidsKeywordsErrorAction, getBidsKeywordsError),
  on(actions.getBidsKeywordsSuccessAction, getBidsKeywordsSuccess),

  on(actions.resetBidsKeywordsAction, resetBidsKeywords),
  on(actions.toggleIncludeBidsAction, toggleIncludeBids),

  on(actions.bidsResetAction, bidsReset),
);

export function bidsReducer(state: IBidsState, action: Action): IBidsState {
  return reducer(state, action);
}

export const keywords: GetFromState<string[], IBidsState> = (
  state: IBidsState,
): string[] => state.keywords;
export const include: GetFromState<boolean, IBidsState> = (
  state: IBidsState,
): boolean => state.include;
