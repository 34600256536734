import { AbstractControl, ValidatorFn } from "@angular/forms";

export default function required(msg?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const message: string = msg || "This field is required";
    const value: any = control.value;

    let isEmpty: boolean;

    if (typeof value === "number") {
      isEmpty = Number.isNaN(value);
    } else if (Array.isArray(value)) {
      isEmpty = !value.length;
    } else if (value === null) {
      isEmpty = true;
    } else if (typeof value === "object") {
      isEmpty = !Object.keys(value).length;
    } else {
      isEmpty = !value;
    }

    return isEmpty ? { required: message } : null;
  };
}
