// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.errorLink {
  color: #0078A7;
  white-space: nowrap;
}

.paraClassStyle {
  text-align: left;
  word-break: break-word;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/errors/components/server-errors/server-errors.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,cAAA;EACA,mBAAA;AACF;;AAEA;EACE,gBAAA;EACA,sBAAA;AACF","sourcesContent":[".wrapper {\n  max-width: 800px;\n  margin: 0 auto;\n  text-align: center;\n}\n\n.errorLink {\n  color: #0078A7;\n  white-space: nowrap;\n}\n\n.paraClassStyle {\n  text-align: left;\n  word-break: break-word;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
