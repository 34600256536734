import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";

import { of, Observable } from "rxjs";
import { catchError, map, switchMapTo } from "rxjs/operators";

import * as actions from "../actions/common-pages.action";

import { OrderService } from "../../services/order.service";
import { PrivacyPolicyService } from "../../services/pivacy-policy.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IServerError } from "@shared/interfaces/server-error";
import { IOrderData } from "../../interfaces/order-confirmation";
import { IHtmlPageData } from "../../interfaces/terms-of-servise";

@Injectable()
export class CommonPagesEffects {
  constructor(
    private actions$: Actions,
    private privacyPolicyService: PrivacyPolicyService,
    private orderService: OrderService,
  ) {}

  privacyPolicyData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getPrivacyPolicyDataAction),
      switchMapTo(
        this.privacyPolicyService.loadPrivacyPolicyData().pipe(
          map((pageData: IHtmlPageData<SafeHtml>) =>
            actions.getPrivacyPolicyDataSuccessAction(pageData),
          ),
          catchError((error: IServerError) =>
            of(actions.getPrivacyPolicyDataErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  orderData$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getOrderDataAction),
      switchMapTo(
        this.orderService.loadOrderData().pipe(
          map((orderData: IOrderData) =>
            actions.getOrderDataSuccessAction(orderData),
          ),
          catchError((error: IServerError) =>
            of(actions.getOrderDataErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );
}
