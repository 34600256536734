import {
  forwardRef,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { fromEvent, merge, of, timer, Observable, Subject } from "rxjs";
import {
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  skip,
  take,
  takeUntil,
  tap,
} from "rxjs/operators";

import { DocumentRef } from "@core/refs/document-ref.service";
import { WindowRef } from "@core/refs/window-ref.service";
import { StorageService } from "@core/services/storage.service";

const MIN_DISTANCE: number = 35;
const MIN_POS: number = -15;
const FUNCTIONAL_MAX_AREA_SIZE: number = 20;
const INPUT_PAD: number = 10;
const INPUT_WIDTH_FACTOR: number = 8;
const TOOLTIP_TEXT: string = `Slider bar values are set to encompass the majority of records in their criteria.
 If you prefer a range outside of the preset values, simply click the high or low number to edit them.`;

interface ISliderEvents {
  blurs: Observable<number>;
  starts: Observable<number>;
  drags: Observable<number>;
  moves: Observable<number>;
}

@Component({
  selector: "bl-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: "slider",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true,
    },
  ],
})
export class SliderComponent
  implements ControlValueAccessor, OnDestroy, AfterViewChecked, AfterViewInit
{
  @ViewChild("slider") private sliderEl: ElementRef;
  @ViewChild("minBar") private minBarEl: ElementRef;
  @ViewChild("maxBar") private maxBarEl: ElementRef;
  @ViewChild("labelMin") private labelMinEl: ElementRef;
  @ViewChild("labelMax") private labelMaxEl: ElementRef;
  @ViewChild("minInput") private minInput: ElementRef;
  @ViewChild("maxInput") private maxInput: ElementRef;
  @ViewChild("selectedContainer") private selectedContainerEl: ElementRef;
  @ViewChild("selectedArea") private selectedAreaEl: ElementRef;

  @Input() type: 0 | 1 = 0;
  @Input() units: string = "";
  @Input() unitsBefore: boolean = false;
  @Input() mapValues: { [key: string]: string };

  @Input() hideMinLabel: boolean = false;

  @Input() shouldHideMinLabelPrefix: boolean = false;
  @Input() shouldHideMaxLabelPrefix: boolean = true;

  @Input() shouldReplaceMinValue: boolean = false;

  @Input() canEditMinValue: boolean = true;
  @Input() doNotShowTooltip: boolean = false;
  @Input() minLabelRenderer: (value: number | string) => string =
    this.defaultLabelRenderer;
  @Input() maxLabelRenderer: (value: number | string) => string =
    this.defaultLabelRenderer;
  @Input() noRound: boolean;
  @Input() isPremium: boolean = false;

  @Input() e2eLabel: string;

  @Input() functionalMax: number;

  @Input() isUseTimeout: boolean = true;

  @HostBinding("class.disabled")
  @Input()
  set disable(state: boolean) {
    this.setDisabledState(state);
  }

  get disable(): boolean {
    return this.isDisabled;
  }

  @Input()
  get min(): number {
    return this._min;
  }

  set min(value: number) {
    this._min = value;
    this._startingMinValue = value;

    if (this.currentMin === void 0 || this.currentMin < value) {
      this.currentMin = value;
    }

    if (this.currentMax !== void 0 && this.currentMax < this.currentMin) {
      this.currentMax = this.currentMin;
      this._startingMinValue = this.currentMin;
    }
  }

  @Input()
  get max(): number {
    return this.functionalMax || this._max;
  }

  set max(value: number) {
    this._max = value;
    this._startingMaxValue = value;

    if (this.currentMaxPosition === void 0 || this.currentMin > value) {
      this.currentMax = value;
    }

    if (this.currentMax !== void 0 && this.currentMin > this.currentMax) {
      this.currentMin = this.currentMax;
      this._startingMinValue = this.currentMax;
    }
  }

  // to set value without form
  @Input()
  set value(value: any) {
    if (this.validateValue(value)) {
      this._value = value;
      this.propagateChange(this._value);
      this.changeValue.emit(this._value);
      this.premiumCheck();
    }
  }

  get value(): any {
    return this._value;
  }

  @Output() changeValue: EventEmitter<any> = new EventEmitter();
  @Output() premiumDataChanged: EventEmitter<any> = new EventEmitter();

  activeBar: string;
  isDisabled: boolean;

  set isLabelsMerged(value: boolean) {
    if (this.sliderRect && this.labelMinEl) {
      value
        ? this._renderer.addClass(this.labelMinEl.nativeElement, "merged")
        : this._renderer.removeClass(this.labelMinEl.nativeElement, "merged");
    }
  }

  get step(): number {
    if (this.noRound) {
      return 1;
    }

    const range: number = this.max - this.min;

    if (range <= 150) {
      return 1;
    } else if (range < 250) {
      return Math.round(range / 100);
    } else {
      const accuracy: number = SliderComponent.getAccuracy(range);
      return SliderComponent.roundTo(Math.round(range / 100), accuracy);
    }
  }

  get minLabel(): string {
    const mappedValue: any = this.mapValues
      ? this.mapValues[this.currentMin]
      : this.currentMin;
    const valueWithUnits: string = this.addUnits(mappedValue);
    return this.minLabelRenderer(valueWithUnits).replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      "$1,",
    );
  }

  get maxLabel(): string {
    const mappedValue: any = this.mapValues
      ? this.mapValues[this.currentMax]
      : this.currentMax;
    const valueWithUnits: string = this.addUnits(mappedValue);
    const max: number = this.functionalMax ? this._max : this.max;
    const value: string | number =
      this.currentMax === max
        ? `${valueWithUnits}${!this.shouldHideMaxLabelPrefix ? "+" : ""}`
        : valueWithUnits;
    return this.maxLabelRenderer(value).replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      "$1,",
    );
  }

  get currentMin(): number {
    return this._currentMin;
  }

  @Input()
  set currentMin(value: number) {
    if (value > this.currentMax) {
      this._currentMin = this.currentMax;
    } else {
      this._currentMin = value;
    }

    if (this.sliderRect) {
      this.currentMinPosition = this.toPosition(this._currentMin);
    } else {
      setTimeout(
        () => (this.currentMinPosition = this.toPosition(this._currentMin)),
        0,
      );
    }
  }

  get currentMax(): number {
    return this._currentMax;
  }

  @Input()
  set currentMax(value: number) {
    this._currentMax = value < this.currentMin ? this.currentMin : value;

    if (this.sliderRect) {
      this.currentMaxPosition = this.toPosition(this._currentMax);
    } else {
      setTimeout(
        () => (this.currentMaxPosition = this.toPosition(this._currentMax)),
        0,
      );
    }
  }

  // for getters & setters
  private _min: number;
  private _max: number;
  private _currentMax: number;
  private _currentMin: number;
  private _currentMaxPosition: number;
  private _currentMinPosition: number;
  private _sliderRect: ClientRect;
  premiumActiveState: boolean = false;

  private _destroyer$: Subject<void> = new Subject();

  private _startingMinValue: number;
  private _startingMaxValue: number;

  private get currentMinPosition(): number {
    return this._currentMinPosition;
  }

  private set currentMinPosition(position: number) {
    this._currentMinPosition = position;
    this.setMinStyle(position);
  }

  private get currentMaxPosition(): number {
    return this._currentMaxPosition;
  }

  private set currentMaxPosition(position: number) {
    this._currentMaxPosition = position;
    this.setMaxStyle(position);
  }

  private get sliderRect(): ClientRect {
    return this._sliderRect;
  }

  private set sliderRect(rect: ClientRect) {
    this._sliderRect = rect;
    this.currentMaxPosition = this.toPosition(this.currentMax);
    this.currentMinPosition = this.toPosition(this.currentMin);

    if (this.sliderRect && this.sliderRect.width !== 0) {
      this._renderer.setStyle(
        this.selectedAreaEl.nativeElement,
        "width.px",
        this.sliderRect.width,
      );
      setTimeout(() =>
        this._renderer.setAttribute(
          this.sliderEl.nativeElement,
          "is-rendered",
          "true",
        ),
      );
    }
  }

  private get _value(): any {
    return this.type
      ? {
          min: this.currentMin,
          max: this.currentMax,
        }
      : this.currentMax;
  }

  private set _value(value: any) {
    if (this.validateValue(value)) {
      if (this.type) {
        if (this.functionalMax) {
          this.currentMin = value.min;
          this.currentMax = value.max;
        } else {
          this.currentMin =
            value.min === this.min
              ? value.min
              : SliderComponent.roundTo(value.min, this.step);

          this.currentMax =
            value.max === this.max
              ? value.max
              : SliderComponent.roundTo(value.max, this.step);
        }
      } else {
        if (this.functionalMax) {
          this.currentMax = value;
        } else {
          this.currentMax =
            value === this.max
              ? this.max
              : SliderComponent.roundTo(value, this.step);
        }
      }
      this.premiumCheck(false);
    }
  }

  get tooltipText(): string {
    if (
      !this.functionalMax ||
      this.doNotShowTooltip ||
      StorageService.doNotShowSliderTooltip
    ) {
      return null;
    }

    return TOOLTIP_TEXT;
  }

  constructor(
    private _renderer: Renderer2,
    private _window: WindowRef,
    public document: DocumentRef,
    private _cdr: ChangeDetectorRef,
  ) {}

  static roundTo(value: number, target: number): number {
    return Math.round(value / target) * target;
  }

  static getAccuracy(range: number): number {
    return Math.pow(10, Math.floor(range / 250).toString().length - 1) * 5;
  }

  ngAfterViewChecked(): void {
    if (!this.sliderRect || this.sliderRect.width === 0) {
      this.updateBoundingRect();
    }
  }

  ngAfterViewInit(): void {
    if (this.isUseTimeout) {
      timer(0).subscribe(() => this.updateBoundingRect());
    } else {
      this.updateBoundingRect();
    }

    this.setupEvents();

    this.setMinInputsStyles();
    this.setMaxInputsStyles();

    this._startingMinValue = this.currentMin;
    this._startingMaxValue = this.currentMax;
  }

  private defaultLabelRenderer(value: string | number): string {
    return `${value}`;
  }

  private propagateChange = (value: string) => {};
  private propagateTouch = () => {};

  private addUnits(value: number | string): string {
    return this.unitsBefore ? `${this.units}${value}` : `${value}${this.units}`;
  }

  private setMinStyle(position: number): void {
    const pxPosition: string = `${position}px`;

    if (this.type) {
      this._renderer.setStyle(this.minBarEl.nativeElement, "left", pxPosition);
      this.setLabelsPositions(position, this.currentMaxPosition);
    }

    this._renderer.setStyle(
      this.selectedContainerEl.nativeElement,
      "left",
      pxPosition,
    );
    this._renderer.setStyle(
      this.selectedAreaEl.nativeElement,
      "marginLeft",
      `-${pxPosition}`,
    );

    this.setMinInputsStyles();
  }

  private setMaxStyle(position: number): void {
    const pxPosition: string = `${position}px`;
    const containerRightPos: string = this.sliderRect.width - position + "px";

    this._renderer.setStyle(this.maxBarEl.nativeElement, "left", pxPosition);
    this._renderer.setStyle(
      this.selectedContainerEl.nativeElement,
      "right",
      containerRightPos,
    );
    this.setLabelsPositions(this.currentMinPosition, position);

    this.setMaxInputsStyles();
  }

  private setLabelsPositions(minBarPos: number, maxBarPos: number): void {
    const minLabelWidth: any = this.labelMinEl
      ? this.labelMinEl.nativeElement.getBoundingClientRect().width
      : 0;
    const maxLabelWidth: any =
      this.labelMaxEl.nativeElement.getBoundingClientRect().width;

    const MAX_POS: number = this.sliderRect.width + 15 - maxLabelWidth;

    const normalizedMin: number = minBarPos - minLabelWidth / 2;
    const normalizedMax: number = maxBarPos - maxLabelWidth / 2;
    const allowedMin: number =
      normalizedMin > MIN_POS ? normalizedMin : MIN_POS;
    const allowedMax: number =
      normalizedMax < MAX_POS ? normalizedMax : MAX_POS;

    const distance: number = allowedMax - (allowedMin + minLabelWidth);
    const isMerged: boolean = distance < MIN_DISTANCE;

    if (allowedMin === MIN_POS && isMerged) {
      this.isLabelsMerged = true;
      const maxPos: any = MIN_POS + MIN_DISTANCE + minLabelWidth;
      this.setLabelsStyles(MIN_POS, maxPos);
    }

    if (allowedMax === MAX_POS && isMerged) {
      this.isLabelsMerged = true;
      const minPos: number = MAX_POS - MIN_DISTANCE - minLabelWidth;
      this.setLabelsStyles(minPos, MAX_POS);
    }

    if (!isMerged) {
      this.isLabelsMerged = false;
      this.setLabelsStyles(allowedMin, allowedMax);
    }

    if (allowedMin !== MIN_POS && allowedMax !== MAX_POS && isMerged) {
      this.isLabelsMerged = true;

      const center: number = (minBarPos + maxBarPos) / 2;

      const targetMin: number = center - MIN_DISTANCE / 2 - minLabelWidth;
      const targetMax: number = center + MIN_DISTANCE / 2;
      const allowedTargetMin: number =
        targetMin > MIN_POS ? targetMin : MIN_POS;
      const allowedTargetMax: number =
        targetMax < MAX_POS ? targetMax : MAX_POS;

      if (allowedTargetMin === MIN_POS) {
        const maxPos: any = MIN_POS + MIN_DISTANCE + minLabelWidth;
        this.setLabelsStyles(MIN_POS, maxPos);
      }

      if (allowedTargetMax === MAX_POS) {
        const minPos: number = MAX_POS - MIN_DISTANCE - minLabelWidth;
        this.setLabelsStyles(minPos, MAX_POS);
      }

      if (allowedTargetMin !== MIN_POS && allowedTargetMax !== MAX_POS) {
        this.setLabelsStyles(allowedTargetMin, allowedTargetMax);
      }
    }
  }

  private setLabelsStyles(minPos: number, maxPos: number): void {
    if (this.labelMinEl) {
      this._renderer.setStyle(
        this.labelMinEl.nativeElement,
        "left",
        `${minPos}px`,
      );
    }
    this._renderer.setStyle(
      this.labelMaxEl.nativeElement,
      "left",
      `${maxPos}px`,
    );
  }

  private setMinInputsStyles(): void {
    if (this.functionalMax && this.minInput) {
      const minEl: HTMLInputElement = this.minInput
        .nativeElement as HTMLInputElement;
      const minWidth: number =
        +minEl.value.length * INPUT_WIDTH_FACTOR + INPUT_PAD;

      this._renderer.setStyle(minEl, "width", `${minWidth}px`);
    }
  }

  private setMaxInputsStyles(): void {
    if (this.functionalMax && this.maxInput) {
      const maxEl: HTMLInputElement = this.maxInput
        .nativeElement as HTMLInputElement;
      const maxWidth: number =
        +maxEl.value.length * INPUT_WIDTH_FACTOR + INPUT_PAD;

      this._renderer.setStyle(maxEl, "width", `${maxWidth}px`);
    }
  }

  private setupEvents(): void {
    const { starts, blurs, drags }: ISliderEvents = this.createEvents(
      this.sliderEl.nativeElement,
    );
    const resizes: Observable<Event> = fromEvent(
      this._window.nativeElement,
      "resize",
    );
    const scroll: Observable<number> = fromEvent(
      this._window.nativeElement,
      "scroll",
    ).pipe(
      map(() => this._window.nativeElement.pageXOffset),
      skip(1),
      debounceTime(500),
      distinctUntilChanged(),
    );

    starts
      .pipe(takeUntil(this._destroyer$))
      .subscribe((startPosition: number) => {
        this.setActiveBar(startPosition);
        this.propagateTouch();
      });

    blurs.pipe(takeUntil(this._destroyer$)).subscribe((position: number) => {
      const valueFromPosition: number = this.toValue(position);
      if (
        (this.activeBar === "min" &&
          this._startingMinValue !== valueFromPosition) ||
        (this.activeBar === "max" &&
          this._startingMaxValue !== valueFromPosition)
      ) {
        this.propagateChange(this.value);
        this.changeValue.emit(this.value);
        this.premiumCheck();
      }

      if (this.activeBar === "min") {
        this._startingMinValue = valueFromPosition;
      } else if (this.activeBar === "max") {
        this._startingMaxValue = valueFromPosition;
      }

      this.activeBar = "";
    });

    drags.pipe(takeUntil(this._destroyer$)).subscribe((position: number) => {
      if (this.activeBar === "min") {
        this.currentMin = this.toValue(position);
      } else if (this.activeBar === "max") {
        this.currentMax = this.toValue(position);
      }
      this._cdr.markForCheck();
    });

    merge(resizes, scroll)
      .pipe(takeUntil(this._destroyer$))
      .subscribe(() => {
        this.updateBoundingRect();
      });
  }

  private validateValue(value: any): boolean {
    if (this.type) {
      if (typeof value !== "object" || value === null) {
        return false;
      }

      if (
        !Object.prototype.hasOwnProperty.call(value, "min") ||
        !Object.prototype.hasOwnProperty.call(value, "max")
      ) {
        return false;
      }

      if (typeof value.min !== "number" || typeof value.max !== "number") {
        return false;
      }

      if (
        (!value.min && value.min !== 0) ||
        value.min < this.min ||
        value.min > value.max ||
        (!value.max && value.max !== 0) ||
        value.max > this._max
      ) {
        return false;
      }
    } else {
      if (typeof value !== "number") {
        return false;
      }

      if ((!value && value !== 0) || value < this.min || value > this._max) {
        return false;
      }
    }

    return true;
  }

  private createEvents(htmlElement: HTMLElement): ISliderEvents {
    const filterInputFields: (observer: Observable<any>) => Observable<any> = (
      observer: Observable<any>,
    ): Observable<any> =>
      observer.pipe(
        filter((event: MouseEvent | TouchEvent) => {
          if (!this.functionalMax) {
            return true;
          }

          return !(
            (this.maxInput && event.target === this.maxInput.nativeElement) ||
            (this.minInput && event.target === this.minInput.nativeElement)
          );
        }),
      );

    // Touch events
    const touchStarts: Observable<number> = fromEvent(
      htmlElement,
      "touchstart",
    ).pipe(
      tap((event: Event) => (event.returnValue = false)),
      filterInputFields,
      map((touchEvent: TouchEvent) => touchEvent.changedTouches[0].clientX),
    );

    const touchMoves: Observable<number> = fromEvent(
      this._window.nativeElement,
      "touchmove",
    ).pipe(
      map((touchEvent: TouchEvent) => touchEvent.changedTouches[0].clientX),
    );

    const touchEnds: Observable<number> = fromEvent(
      this._window.nativeElement,
      "touchend",
    ).pipe(
      map((touchEvent: TouchEvent) => touchEvent.changedTouches[0].clientX),
    );

    // Mouse events
    const mouseDowns: Observable<number> = fromEvent(
      htmlElement,
      "mousedown",
    ).pipe(
      filter((event: MouseEvent) => event.button !== 2),
      filterInputFields,
      map((event: MouseEvent) => event.clientX),
    );

    const mouseMoves: Observable<number> = fromEvent(
      this._window.nativeElement,
      "mousemove",
    ).pipe(map((event: MouseEvent) => event.clientX));

    const mouseUps: Observable<number> = fromEvent(
      this._window.nativeElement,
      "mouseup",
    ).pipe(map((event: MouseEvent) => event.clientX));

    // Synthetic touch-mouse events

    const starts: Observable<number> = merge(mouseDowns, touchStarts).pipe(
      filter(() => !this.isDisabled),
      map((posX: number) => posX - this.sliderRect.left),
    );

    const moves: Observable<number> = merge(mouseMoves, touchMoves).pipe(
      filter(() => !this.isDisabled),
      map((posX: number) => posX - this.sliderRect.left),
    );

    const ends: Observable<number> = merge(mouseUps, touchEnds).pipe(
      filter(() => !this.isDisabled),
      map((posX: number) => posX - this.sliderRect.left),
    );

    const blurs: Observable<number> = starts.pipe(
      concatMap(() => ends.pipe(take(1))),
    );

    const drags: Observable<number> = starts.pipe(
      concatMap((startPosition: number) =>
        merge(moves, of(startPosition)).pipe(takeUntil(ends)),
      ),
    );

    return { starts, drags, blurs, moves };
  }

  private setActiveBar(position: number): void {
    let activeBar: "min" | "max";

    if (this.type) {
      const deltaMin: number = Math.abs(position - this.currentMinPosition);
      const deltaMax: number = Math.abs(position - this.currentMaxPosition);

      // to prevent unavailable min bar when bot values are max OR values are equal
      if (
        this.currentMin === this.max ||
        (this.currentMin === this.currentMax &&
          position - this.currentMinPosition < 0) ||
        (this.functionalMax &&
          this.currentMin > this.functionalMax &&
          this.currentMax > this.functionalMax)
      ) {
        activeBar = "min";
      } else {
        activeBar = deltaMin < deltaMax ? "min" : "max";
      }
    } else {
      activeBar = "max";
    }

    this.activeBar = activeBar;
  }

  private toValue(position: number): number {
    const width: number = this.functionalMax
      ? this.sliderRect.width - FUNCTIONAL_MAX_AREA_SIZE
      : this.sliderRect.width;

    if (this.functionalMax && position > width) {
      return this._max;
    }

    const delta: number = this.max - this.min;
    const value: number = (position / width) * delta + this.min;
    const result: number = SliderComponent.roundTo(value, this.step);

    // to set not only rounded values but min and max values too
    if (this.max - value < this.step && this.max - value < value - result) {
      return this.max;
    }

    if (value - this.min < this.step && value - this.min < value - result) {
      return this.min;
    }

    return result;
  }

  private toPosition(value: number): number {
    let position: number;
    const delta: number = this.max - this.min;

    if (!this.functionalMax) {
      position = ((value - this.min) / delta) * this.sliderRect.width;
    } else {
      if (value > this.functionalMax) {
        return this.sliderRect.width;
      } else {
        position =
          ((value - this.min) / delta) *
          (this.sliderRect.width - FUNCTIONAL_MAX_AREA_SIZE);
      }
    }

    return Math.round(position);
  }

  handleInputsChange(event: Event): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    const normalizedValue: string = target.value.trim().replace(/\D+/, "");
    let value: number = Number(normalizedValue);

    if (value > this._max) {
      value = this._max;
    }

    target.value = normalizedValue ? `${value}` : "";

    const width: number = `${value}`.length * INPUT_WIDTH_FACTOR + INPUT_PAD;
    this._renderer.setStyle(target, "width", `${width}px`);
  }

  setValueDirectly(event: Event, type: "min" | "max"): void {
    const target: HTMLInputElement = event.target as HTMLInputElement;

    const normalizedValue: string = target.value.trim().replace(/\D+/, "");
    const value: number = Number(normalizedValue);

    if (
      (type === "min" && value === this.currentMin) ||
      (type === "max" && value === this.currentMax)
    ) {
      target.blur();
      return;
    }

    if (type === "min") {
      this.currentMin = value < this.min ? this.min : value;
      this.setMinInputsStyles();
    } else if (type === "max") {
      this.currentMax = value > this._max ? this._max : value;
      this.setMaxInputsStyles();
    }

    target.blur();
    this.changeValue.emit(this._value);
    this.propagateTouch();
    this.propagateChange(this._value);
    this.premiumCheck();
  }

  dismissTooltip(): void {
    StorageService.doNotShowSliderTooltip = true;
  }

  // ControlValueAccessor methods
  writeValue(value: any): void {
    if (value !== null && typeof value !== "undefined") {
      if (!this.type && typeof value !== "object") {
        this._value = Math.round(+value);
      } else {
        const { min, max }: any = value;

        this._value = {
          min: Math.round(min),
          max: Math.round(max),
        };
      }
    }
    if (!this._cdr["destroyed"]) {
      this._cdr.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  premiumCheck(dispatch: boolean = true): void {
    if (this.isPremium) {
      if (this._max === this.value.max && this._min === this.value.min) {
        this.premiumActiveState = false;
      } else {
        this.premiumActiveState = true;
        if (dispatch) {
          this.premiumDataChanged.emit();
        }
      }
    }
  }

  updateBoundingRect(): void {
    this.sliderRect = this.sliderEl.nativeElement.getBoundingClientRect();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }
}
