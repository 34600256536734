import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Action } from "@ngrx/store";

import { of, Observable } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { ActionWithPayload } from "@shared/interfaces/store";
import * as countriesActions from "../actions/countries.action";

import { CountriesService } from "../../services/countries.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IServerError } from "@shared/interfaces/server-error";
import { ICountries, ICountryCode, ICountryStates } from "../../interfaces";

@Injectable()
export class CountriesEffects {
  constructor(
    private actions$: Actions,
    private countriesService: CountriesService,
  ) {}

  getCountries$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(countriesActions.getCountriesAction),
      switchMap(() =>
        this.countriesService.getCountries().pipe(
          map((data: ICountries) =>
            countriesActions.getCountriesSuccessAction(data),
          ),
          catchError((error: IServerError) =>
            of(countriesActions.getCountriesErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  getStatesByCountry$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(countriesActions.getStatesByCountryAction),
      switchMap(({ payload }: ActionWithPayload<ICountryCode>) =>
        this.countriesService.getStatesByCountry(payload).pipe(
          map((data: ICountryStates) =>
            countriesActions.getStatesByCountrySuccessAction(data),
          ),
          catchError((error: IServerError) =>
            of(countriesActions.getStatesByCountryErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );
}
