// eslint-disable  max-len
export const VIEW_SEGMENTS_TEXT: string =
  "Your View can be organized into segments. Segments have separate counts and de-duplication based on the different criteria in your list.";
export const LIST_SEGMENTS_TEXT: string =
  "Your lists are organized into segments. Segments have separate counts and de-duplication based on the different criteria in your list.";
export const CREDIT_METER_TEXT: string =
  "Lists are purchased with credits. A credit is equivalent to a single contact record (i.e., Lead). Credits can be purchased before or after you create your list.";
export const UNLIMITED_CREDITS_MSG: string = "Unlimited";
export const PASSWORD_TIP: string =
  "Password must be at least seven characters and contain at least one capital letter and one number";
export const SEC_CAP_DISABLED_TEXT: string =
  "Lists with a cap set cannot display building or state counts.";
export const CALCULATOR_TEXT: string =
  "Use this calculator to preview your list cost available credits. All values are shown in credits.";
export const UNAVAILABLE_REFRESH_TEXT: string =
  "Your records are up to date. No need to refresh!";
export const GEO_SUGGEST_TIP: string =
  "To upload multiple ZIP Codes or SCF Codes, enter or paste your codes separated by commas or spaces.";
export const DISABLED_XLSX_TEXT: string =
  "This format is unavailable due to number of records exceeding the format limitations";
export const BUILDINGS_ONLY_TEXT: string =
  "By Changing to Institution-Only, your list will no longer include contact names, and only include Direct Mail building addresses.";
export const REFRESH_CREDITS_BTN_TOOLTIP_TEXT: string =
  "Don't see your credits? Click refresh to update.";
export const TARGETING_CONTAINER_HEADER_PIDS_TEXT: string =
  "Target individual institutions or PIDs. PIDs are MDR’s unique ID number for institutions.";
export const QUICK_SEARCH_PLACEHOLDER: string =
  "Search by City, State, County, SCF, or ZIP code.";
export const GEO_TARGETING_ZIP_CODES_TOOLTIP: string =
  "To upload multiple ZIP Codes or SCF Codes, enter or paste your codes separated by commas or spaces";
export const ACTIVATION_NOT_ALLOWED: string =
  "You cannot activate the CDL list until the CDL product is purchased";
export const CREDIT_EXPIRED_TEXT: string = "Your credits expire on ";
