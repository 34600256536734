import { Inject, Injectable } from "@angular/core";

import { environment } from "@env/environment";

import { interval, BehaviorSubject, Observable } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  share,
  takeWhile,
  tap,
} from "rxjs/operators";
import { WebSocketSubject } from "rxjs/webSocket";

import {
  IWebSocketConfig,
  IWebSocketUpdateConfigValues,
  IWsMessage,
  WEB_SOCKET_CONFIG_TOKEN,
} from "../data";

@Injectable()
export class WebSocketsProvider {
  private _webSocket$: WebSocketSubject<IWsMessage<any>>;
  private _connectedStatus$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  private _reconnectionObservable$: Observable<unknown>;

  get status(): Observable<boolean> {
    return this._connectedStatus$.asObservable().pipe(
      share(),
      distinctUntilChanged(),
      tap((status: boolean) => {
        const localStatus: string = status ? "connected" : "disconnected";
        console.log(
          "%cWS connection status: " + localStatus,
          "background: #222; color: #bada55; padding: 5px;",
        );
      }),
    );
  }

  constructor(
    @Inject(WEB_SOCKET_CONFIG_TOKEN) private _config: IWebSocketConfig,
  ) {}

  connect(): void {
    if (this._webSocket$) {
      this.disconnect();
    }

    this._webSocket$ = new WebSocketSubject({
      url: `${this._config.url}${this._config.token ? "?token=" + this._config.token : ""}`,
      openObserver: {
        next: () => {
          this._connectedStatus$.next(true);
        },
      },
      closeObserver: {
        next: () => {
          this._webSocket$ = null;
          this._connectedStatus$.next(false);
        },
      },
    });

    this._webSocket$.pipe(share(), distinctUntilChanged()).subscribe(
      () => {},
      () => {
        if (!this._webSocket$) {
          /// in case of an error with a loss of connection, we restore it
          this.reconnect();
        }
      },
    );
  }

  /// WebSocket Reconnect handling.
  reconnect(): void {
    this._reconnectionObservable$ = interval(
      this._config.reconnectInterval,
    ).pipe(
      takeWhile((v: unknown, index: number) => {
        return index < this._config.reconnectAttempts && !this._webSocket$;
      }),
    );
    this._reconnectionObservable$.subscribe(
      () => {
        console.log(
          "%cWS connection status: reconnecting",
          "background: #222; color: #bada55; padding: 5px;",
        );
        this.connect();
      },
      null,
      () => {
        /// if the reconnection attempts are failed, then we call complete of our Subject and status
        this._reconnectionObservable$ = null;
        if (!this._webSocket$) {
          this._webSocket$.complete();
          this._connectedStatus$.complete();
        }
      },
    );
  }

  updateConfigValues(newConfigValues: IWebSocketUpdateConfigValues): void {
    this._config = { ...this._config, ...newConfigValues } as IWebSocketConfig;
    this.connect();

    this._connectedStatus$.subscribe((isConnected: boolean) => {
      if (
        !this._reconnectionObservable$ &&
        typeof isConnected === "boolean" &&
        !isConnected
      ) {
        this.reconnect();
      }
    });
  }

  disconnect(): void {
    if (this._webSocket$) {
      this._webSocket$.unsubscribe();
      this._webSocket$.complete();
      this._webSocket$ = null;
      const localStatus: string = "disconnected";
      console.log(
        "%cWS connection status: " + localStatus,
        "background: #222; color: #bada55; padding: 5px;",
      );
    }
  }

  on<T>(event: string): Observable<T> {
    if (!this._webSocket$) {
      this.connect();
    }

    if (!event && !environment.production) {
      console.log(
        "Subscribe Error incorrect event name or connection is failed ",
        event,
      );
    }

    return this._webSocket$.pipe(
      filter((message: IWsMessage<T>) => !!event && message.event === event),
      map((message: IWsMessage<T>) => message.data as T),
    );
  }

  send(event: string, data: any = {}): void {
    if (event && this._webSocket$) {
      this._webSocket$.next(<any>JSON.stringify({ event, data }));
    } else {
      if (!environment.production) {
        console.error("Send Error ", event, data);
      }
    }
  }
}
