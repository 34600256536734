import { Action } from "@ngrx/store";

import {
  ICloudSyncAuthFields,
  ICloudSyncPlatforms,
} from "@core/interfaces/cloud-sync";
import {
  ICreateAccountUser,
  IEditAccountUser,
  IOpenSeatAddOns,
  IOpenSeatStates,
} from "@modules/profile/interfaces/marketview";
import { IPidsChildAndParentPopUpData } from "@modules/segment/interfaces/pids";
import { IControlOptions } from "@shared/interfaces/forms";
import { ICDLLists } from "@shared/interfaces/manage-account-lists";

import { POP_UP_TYPES } from "../constants/pop-up-data";

export interface IPopUpData {
  key: string;
  value: any;
}

export interface IPopUp {
  id: POP_UP_TYPES;
  additionalData: IPopUpData[];
}

export class IWelcomeWithMarketViewPopUp {
  header: string;
  title: string;
  body: string[];
  buttonConfirm: string;
  buttonCancel: string;
}

export interface IEditUserAccountPopUpData {
  title: string;
  user: IEditAccountUser;
  isEditSelf: boolean;
  openStates: IOpenSeatStates;
  openAddOns: IOpenSeatAddOns;
  cdlLists: ICDLLists;
  asAdmin: boolean;
  allStates?: IControlOptions;
}

export interface IAddUserAccountPopUpData {
  title: string;
  openStates: IOpenSeatStates;
  openAddOns: IOpenSeatAddOns;
  cdlLists: ICDLLists;
  asAdmin: boolean;
  user?: ICreateAccountUser;
  allStates?: IControlOptions;
}

export interface ICloudSyncAuthPopUpData {
  title: string;
  subTitle: string;
  submitText: string;
  fields?: ICloudSyncAuthFields;
  sandbox?: boolean;
}

export interface ICloudSyncAssignProductPopUpData {
  platforms: ICloudSyncPlatforms;
}

export interface IMarketViewRenewalPopUpData {
  ownerName: string;
}

export interface IParentInstitutionOnly {
  actionToDispatch: Action;
  modalsResults: IPidsChildAndParentPopUpData;
  parentModalConfig: IParentInstitutionOnlyConfig;
  childModalConfig?: IChildInstitutionOnlyConfig;
}

export interface IParentInstitutionOnlyConfig {
  title: string;
  message: string;
  parentOnlyText: string;
  parentAndChildrenText: string;
  checkboxMessage?: string;
  withoutCheckbox?: boolean;
}

export interface IParentInstitutionOnlyResult {
  includeChildren?: boolean;
  includeChildrenAll?: boolean;
}

export interface IChildInstitutionOnly {
  actionToDispatch: Action;
  modalsResults: IPidsChildAndParentPopUpData;
  childModalConfig: IChildInstitutionOnlyConfig;
}

export interface IChildInstitutionOnlyConfig {
  title: string;
  message: string;
  childOnlyText: string;
  childAndParentText: string;
  parentAndAllChildrenText: string;
  checkboxMessage?: string;
  withoutCheckbox?: boolean;
}

export interface IChildInstitutionOnlyResult {
  includeOnlyParent?: boolean;
  includeFullParent?: boolean;
  includeParentAll?: boolean;
}
