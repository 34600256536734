import { IObjectKeysByNumberString } from "@shared/interfaces";

export const ERROR_PAGE_TEXT: IObjectKeysByNumberString = {
  503: "Our server is temporarily unable to handle your request (503)...",
  502: "The server was acting as a gateway or proxy and received an invalid response from the upstream server (502)...",
};

export enum ERROR_MSG {
  TOKEN_EXPIRED = "tokenExpired",
}
