import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  bidCategoriesReducer,
  IBidCategoriesState,
} from "./bid-categories.reducer";
import { bidsReducer, IBidsState } from "./bids.reducer";
import { geoBrowseReducer, IGeoBrowseState } from "./geo-browse.reducer";
import {
  geoMultiCodesReducer,
  IGeoMultiCodesState,
} from "./geo-multi-codes.reducer";
import { geoReducer, IGeoState } from "./geo.reducer";
import { grantsReducer, IGrantsState } from "./grants.reducer";
import { jobsBrowseReducer, JobsBrowseState } from "./jobs-browse.reducer";
import {
  personnelMultiCodesReducer,
  IPersonnelMultiCodesState,
} from "./personnel-multi-codes.reducer";
import { personnelReducer, IJobsState } from "./personnel.reducer";
import {
  pidCustomListsReducer,
  IPidCustomListsState,
} from "./pid-custom-lists.reducer";
import { pidsTabsReducer, IPidTargetingState } from "./pids-tabs.reducer";
import { pidsReducer, IPidsState } from "./pids.reducer";
import { segmentReducer, ISegmentState } from "./segment.reducer";

export interface ISegmentModuleState {
  segment: ISegmentState;
  geoBrowse: IGeoBrowseState;
  jobsBrowse: JobsBrowseState;
  grants: IGrantsState;
  bids: IBidsState;
  bidCategories: IBidCategoriesState;
  pids: IPidsState;
  pidsTabs: IPidTargetingState;
  pidCustomLists: IPidCustomListsState;
  jobs: IJobsState;
  geo: IGeoState;
  geoMultiCodes: IGeoMultiCodesState;
  personnelMultiCodes: IPersonnelMultiCodesState;
}

const segmentModuleReducers: ActionReducerMap<ISegmentModuleState> = {
  segment: segmentReducer,
  geoBrowse: geoBrowseReducer,
  jobsBrowse: jobsBrowseReducer,
  grants: grantsReducer,
  bids: bidsReducer,
  bidCategories: bidCategoriesReducer,
  pids: pidsReducer,
  pidsTabs: pidsTabsReducer,
  pidCustomLists: pidCustomListsReducer,
  jobs: personnelReducer,
  geo: geoReducer,
  geoMultiCodes: geoMultiCodesReducer,
  personnelMultiCodes: personnelMultiCodesReducer,
};

// eslint-disable-next-line
export const SEGMENT_MODULE_STATE_NAME = 'segmentModule';
export const SEGMENT_MODULE_STATE: InjectionToken<
  ActionReducerMap<ISegmentModuleState>
> = new InjectionToken<ActionReducerMap<ISegmentModuleState>>(
  SEGMENT_MODULE_STATE_NAME,
  {
    factory: () => segmentModuleReducers,
  },
);
