/* eslint-disable @angular-eslint/no-output-native */
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "bl-select",
  templateUrl: "./select.component.html",
  styleUrls: ["./select.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent {
  @Input() trackBy?: string; // required for tracking selected option
  @Input() placeholder: string = "";
  @Input() disabled: boolean;
  @Input() options: any[];
  @Input() theme: "white" | "gray" = "gray";
  @Input() template: TemplateRef<any>;
  @Input() selectedTemplate: TemplateRef<any>;

  @Output() select: EventEmitter<any> = new EventEmitter<any>();

  selectedOption: any;
  showDropdown: boolean = false;

  onDropdownItemClick(e: MouseEvent, option: any): void {
    this.selectedOption = option;
    this.select.emit(this.selectedOption);
    this.toggleDropdown();
  }

  onBoxClick(e: MouseEvent): void {
    this.toggleDropdown();
  }

  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  getContext(): any {
    // deleting id to avoid errors in angular logic
    const selectedOptionObj: any = { ...this.selectedOption };
    if (selectedOptionObj.id) {
      delete selectedOptionObj.id;
    }
    return selectedOptionObj;
  }
}
