import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IPersonnelRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { ITogglePersonnelRecordPayload } from "../../interfaces";

enum ACTION_TYPES {
  GET_VIEW_RESULTS_PERSONNEL_DISTRICTS = "[VIEW RESULTS PERSONNEL DISTRICTS] Get personnel districts",
  GET_VIEW_RESULTS_PERSONNEL_DISTRICTS_ERROR = "[VIEW RESULTS PERSONNEL DISTRICTS] Get personnel districts error",
  GET_VIEW_RESULTS_PERSONNEL_DISTRICTS_SUCCESS = "[VIEW RESULTS PERSONNEL DISTRICTS] Get personnel districts success",

  TOGGLE_PERSONNEL_DISTRICT = "[VIEW RESULTS PERSONNEL DISTRICTS] Toggle",

  SET_VIEW_RESULTS_PERSONNEL_DISTRICTS_SPINNER_STATE = "[VIEW RESULTS PERSONNEL DISTRICTS] Set spinner state",

  CHANGE_VIEW_RESULTS_PERSONNEL_DISTRICTS_PAGE = "[VIEW RESULTS PERSONNEL DISTRICTS] Change page",
  CHANGE_VIEW_RESULTS_PERSONNEL_DISTRICTS_QUERY = "[VIEW RESULTS PERSONNEL DISTRICTS] Change query",

  RESET_SELECTED = "[VIEW RESULTS PERSONNEL DISTRICTS] Reset selected personnel districts",

  RESET_VIEW_RESULTS_PERSONNEL_DISTRICTS_PAGE_STATE = "[VIEW RESULTS PERSONNEL DISTRICTS] Reset state",
}

export const getViewResultsPersonnelDistrictsAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_DISTRICTS,
  () => TypedAction<ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_DISTRICTS>
> = createAction(ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_DISTRICTS);
export const getViewResultsPersonnelDistrictsErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_DISTRICTS_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_DISTRICTS_ERROR,
  createPayload<IServerError>(),
);
export const getViewResultsPersonnelDistrictsSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_DISTRICTS_SUCCESS,
  IProductResultsResponse<IPersonnelRecords>
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_PERSONNEL_DISTRICTS_SUCCESS,
  createPayload<IProductResultsResponse<IPersonnelRecords>>(),
);

export const toggleViewResultsPersonnelDistrictAction: CreateActionType<
  ACTION_TYPES.TOGGLE_PERSONNEL_DISTRICT,
  ITogglePersonnelRecordPayload
> = createAction(
  ACTION_TYPES.TOGGLE_PERSONNEL_DISTRICT,
  createPayload<ITogglePersonnelRecordPayload>(),
);

export const setViewResultsPersonnelDistrictsSpinnerAction: CreateActionType<
  ACTION_TYPES.SET_VIEW_RESULTS_PERSONNEL_DISTRICTS_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_VIEW_RESULTS_PERSONNEL_DISTRICTS_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeViewResultsPersonnelDistrictsPage: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_DISTRICTS_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_DISTRICTS_PAGE,
  createPayload<number>(),
);

export const changeViewResultsPersonnelDistrictsQuery: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_DISTRICTS_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_PERSONNEL_DISTRICTS_QUERY,
  createPayload<Params>(),
);

export const resetSelectedPersonnelDistrictsAction: ActionCreator<
  ACTION_TYPES.RESET_SELECTED,
  () => TypedAction<ACTION_TYPES.RESET_SELECTED>
> = createAction(ACTION_TYPES.RESET_SELECTED);

export const resetViewResultsPersonnelDistrictsPageState: ActionCreator<
  ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_DISTRICTS_PAGE_STATE,
  () => TypedAction<ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_DISTRICTS_PAGE_STATE>
> = createAction(
  ACTION_TYPES.RESET_VIEW_RESULTS_PERSONNEL_DISTRICTS_PAGE_STATE,
);
