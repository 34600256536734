import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import { DropDownComponent } from "../drop-down/drop-down.component";

@Component({
  selector: "bl-points-menu",
  templateUrl: "./points-menu.component.html",
  styleUrls: ["./points-menu.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PointsMenuComponent {
  @ViewChild("dropDown", { static: true }) dropDown: DropDownComponent;

  @Input() disableState: boolean;
  @Input() disable: boolean = false;
  @Input() zIndex: number;
  @Input() offset: number;
  @Input() toggleOnHover: boolean;

  @Output() pointsMenuOpen: EventEmitter<null> = new EventEmitter<null>();
  @Output() pointsMenuClose: EventEmitter<null> = new EventEmitter<null>();

  get isOpen(): boolean {
    return this.dropDown ? this.dropDown.isOpen : false;
  }

  open(): void {
    if (this.dropDown) {
      this.dropDown.open();
    }
  }

  close(): void {
    if (this.dropDown && !this.disableState) {
      this.dropDown.close();
    }
  }
}
