import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { updateListStateAction } from "@modules/list-common-store/store/actions/list.actions";
import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/list.actions";

import { IListData } from "@shared/interfaces/list";
import { IServerError } from "@shared/interfaces/server-error";
import { ISegmentData } from "../../../segment/interfaces/segment";

import { LIST_MAX_NAME_STATUS } from "@shared/constants/data/list-statuses";

export interface ICustomerListState {
  list: IListData | null;

  loaded: boolean;
  loading: boolean;
  error: IServerError | null;

  deleting: boolean;
  deleteError: IServerError | null;

  changingListType: boolean;
  changingListTypeError: IServerError | null;

  isShowCalculations: boolean;

  renaming: boolean;
  renameError: IServerError | null;
}

const initialState: ICustomerListState = {
  list: null,

  loaded: false,
  loading: false,
  error: null,

  deleting: false,
  deleteError: null,

  changingListType: false,
  changingListTypeError: null,

  isShowCalculations: true,

  renaming: false,
  renameError: null,
};

const getList: OnReducer<ICustomerListState, ActionType<any>> = (
  state: ICustomerListState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const getListError: OnReducer<
  ICustomerListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomerListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getListSuccess: OnReducer<
  ICustomerListState,
  ActionType<Payload<any>>
> = (state: ICustomerListState, { payload }: Payload<any>) => ({
  ...state,
  list: { ...payload },
  loading: !!payload.listingCountsCalculating,
  loaded: true,
});

const deleteList: OnReducer<ICustomerListState, ActionType<any>> = (
  state: ICustomerListState,
) => ({
  ...state,
  deleting: true,
  deleteError: null,
});

const deleteListError: OnReducer<
  ICustomerListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomerListState, { payload }: Payload<IServerError>) => ({
  ...state,
  deleting: false,
  deleteError: { ...payload },
});

const deleteListSuccess: OnReducer<ICustomerListState, ActionType<any>> = (
  state: ICustomerListState,
) => ({
  ...state,
  list: null,
  deleting: false,
  deleteError: null,
});

const changeListType: OnReducer<ICustomerListState, ActionType<any>> = (
  state: ICustomerListState,
) => ({
  ...state,
  loading: true,
  changingListType: true,
  changingListTypeError: null,
});

const changeListTypeError: OnReducer<
  ICustomerListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomerListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  changingListType: false,
  changingListTypeError: { ...payload },
});

const changeListTypeSuccess: OnReducer<
  ICustomerListState,
  ActionType<Payload<any>>
> = (state: ICustomerListState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  list: { ...payload },
  changingListType: false,
  changingListTypeError: null,
});

const renameList: OnReducer<ICustomerListState, ActionType<any>> = (
  state: ICustomerListState,
) => ({
  ...state,
  loading: true,
  renaming: true,
  renameError: null,
});

const renameListError: OnReducer<
  ICustomerListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomerListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  renaming: false,
  renameError: { ...payload },
});

const renameListSuccess: OnReducer<
  ICustomerListState,
  ActionType<Payload<any>>
> = (state: ICustomerListState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  list: { ...payload },
  renaming: false,
  renameError: null,
});

const renameListCancel: OnReducer<ICustomerListState, ActionType<any>> = (
  state: ICustomerListState,
) => ({
  ...state,
  renaming: false,
  renameError: null,
});

const reloadList: OnReducer<ICustomerListState, ActionType<any>> = (
  state: ICustomerListState,
) => ({
  ...state,
  loading: true,
  error: null,
});

const reloadListError: OnReducer<
  ICustomerListState,
  ActionType<Payload<IServerError>>
> = (state: ICustomerListState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const reloadListSuccess: OnReducer<
  ICustomerListState,
  ActionType<Payload<any>>
> = (state: ICustomerListState, { payload }: Payload<any>) => ({
  ...state,
  list: { ...payload },
  loading: false,
});

const changesStart: OnReducer<ICustomerListState, ActionType<any>> = (
  state: ICustomerListState,
) => ({
  ...state,
  loading: true,
});

const isShowCalculations: OnReducer<
  ICustomerListState,
  ActionType<Payload<any>>
> = (state: ICustomerListState, { payload }: Payload<any>) => ({
  ...state,
  isShowCalculations: payload,
});

const updateListState: OnReducer<
  ICustomerListState,
  ActionType<Payload<any>>
> = (state: ICustomerListState, { payload }: Payload<any>) => ({
  ...state,
  loaded: true,
  list: { ...state.list, ...payload },
});

const resetList: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICustomerListState> = createReducer(
  initialState,

  on(actions.getListAction, getList),
  on(actions.getListErrorAction, getListError),
  on(actions.getListSuccessAction, getListSuccess),

  on(actions.deleteListAction, deleteList),
  on(actions.deleteListErrorAction, deleteListError),
  on(actions.deleteListSuccessAction, deleteListSuccess),

  on(actions.changeListTypeAction, changeListType),
  on(actions.changeListTypeErrorAction, changeListTypeError),
  on(actions.changeListTypeSuccessAction, changeListTypeSuccess),

  on(actions.isShowCalculations, isShowCalculations),

  on(actions.renameListAction, renameList),
  on(actions.renameListErrorAction, renameListError),
  on(actions.renameListSuccessAction, renameListSuccess),
  on(actions.renameListCancelAction, renameListCancel),

  on(actions.reloadListAction, reloadList),
  on(actions.reloadListWithRedirectAction, reloadList),
  on(actions.reloadListErrorAction, reloadListError),
  on(actions.reloadListSuccessAction, reloadListSuccess),
  on(actions.reloadListWithRedirectSuccessAction, reloadListSuccess),

  on(actions.changesStartAction, changesStart),

  on(updateListStateAction, updateListState),

  on(actions.resetListAction, resetList),
);

export function customerListReducer(
  state: ICustomerListState,
  action: Action,
): ICustomerListState {
  return reducer(state, action);
}

export const list: GetFromState<IListData, ICustomerListState> = (
  state: ICustomerListState,
): IListData => state && state.list;

export const shouldBeCalculated: GetFromState<boolean, IListData> = (
  _list: IListData,
): boolean =>
  _list && _list.maxNamesStatus === LIST_MAX_NAME_STATUS.SHOULD_BE_CALCULATED;

export const listId: GetFromState<number, IListData> = (
  _list: IListData,
): number => _list && _list.id;

export const loaded: GetFromState<boolean, ICustomerListState> = (
  state: ICustomerListState,
): boolean => state && state.loaded;
export const loading: GetFromState<boolean, ICustomerListState> = (
  state: ICustomerListState,
): boolean => state && state.loading;

export const showCalculations: GetFromState<boolean, ICustomerListState> = (
  state: ICustomerListState,
): boolean => state && state.isShowCalculations;

export const getListSegments: GetFromState<
  ISegmentData[],
  ICustomerListState
> = (state: ICustomerListState): ISegmentData[] =>
  state && state.list && state.list.segments;

export const changingListType: GetFromState<boolean, ICustomerListState> = (
  state: ICustomerListState,
): boolean => state && state.changingListType;

export const renameError: GetFromState<
  IServerError | null,
  ICustomerListState
> = (state: ICustomerListState): IServerError | null =>
  state && state.renameError;
