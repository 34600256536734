import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "../../../../interfaces/store";
import { GetFromState } from "../../../../store/types/reducer.types";
import * as actions from "../actions/download-entity.actions";

import { IListMainTypes } from "@modules/list-shared/interfaces";
import { IListData } from "../../../../interfaces/list";
import { IServerError } from "../../../../interfaces/server-error";

export interface IDownloadEntityState {
  info: IListData | null;
  loaded: boolean;
  loading: boolean;
  error: IServerError | null;
  isFileGenerating: boolean;
}

const initialState: IDownloadEntityState = {
  info: null,
  loaded: false,
  loading: false,
  error: null,
  isFileGenerating: false,
};

const getDownloadEntityInfo: OnReducer<
  IDownloadEntityState,
  ActionType<any>
> = (state: IDownloadEntityState) => ({
  ...state,
  loading: true,
  error: null,
});

const getDownloadEntityInfoError: OnReducer<
  IDownloadEntityState,
  ActionType<Payload<IServerError>>
> = (state: IDownloadEntityState, { payload }: Payload<IServerError>) => ({
  ...state,
  loading: false,
  error: { ...payload },
});

const getDownloadEntityInfoSuccess: OnReducer<
  IDownloadEntityState,
  ActionType<Payload<any>>
> = (state: IDownloadEntityState, { payload }: Payload<any>) => ({
  ...state,
  loading: false,
  loaded: true,
  info: { ...payload },
});

const generateDownloadEntityFile: OnReducer<
  IDownloadEntityState,
  ActionType<any>
> = (state: IDownloadEntityState) => ({
  ...state,
  isFileGenerating: true,
  error: null,
});

const generateDownloadEntityFileError: OnReducer<
  IDownloadEntityState,
  ActionType<Payload<IServerError>>
> = (state: IDownloadEntityState, { payload }: Payload<IServerError>) => ({
  ...state,
  isFileGenerating: false,
  error: { ...payload },
});

const generateDownloadEntityFileSuccess: OnReducer<
  IDownloadEntityState,
  ActionType<any>
> = (state: IDownloadEntityState) => ({
  ...state,
  isFileGenerating: false,
});

const reducer: ActionReducer<IDownloadEntityState> =
  createReducer<IDownloadEntityState>(
    initialState,

    on(actions.getDownloadEntityInfoAction, getDownloadEntityInfo),
    on(actions.getDownloadEntityInfoErrorAction, getDownloadEntityInfoError),
    on(
      actions.getDownloadEntityInfoSuccessAction,
      getDownloadEntityInfoSuccess,
    ),

    on(actions.generateDownloadEntityFileAction, generateDownloadEntityFile),
    on(
      actions.generateDownloadEntityFileErrorAction,
      generateDownloadEntityFileError,
    ),
    on(
      actions.generateDownloadEntityFileSuccessAction,
      generateDownloadEntityFileSuccess,
    ),
  );

export function downloadEntityReducer(
  state: IDownloadEntityState,
  action: Action,
): IDownloadEntityState {
  return reducer(state, action);
}

export const info: GetFromState<IListData, IDownloadEntityState> = (
  state: IDownloadEntityState,
): IListData => state.info;
export const infoMainTypes: GetFromState<IListMainTypes, IListData> = (
  _info: IListData,
): IListMainTypes => {
  const { name, recordsType, type, entityType }: IListData = _info;
  return { name, recordType: recordsType.id, listType: type.id, entityType };
};
export const generating: GetFromState<boolean, IDownloadEntityState> = (
  state: IDownloadEntityState,
): boolean => state.isFileGenerating;
