import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  dashboardListsFilterReducer,
  IDashboardListsFilterState,
} from "./dashboard-lists-filter.reducer";
import {
  dashboardListsSearchReducer,
  IDashboardListsQueryState,
} from "./dashboard-lists-search.reducer";
import {
  dashboardListsSortingReducer,
  IDashboardListsSortingState,
} from "./dashboard-lists-sorting.reducer";
import {
  dashboardListsViewTypeReducer,
  IDashboardListsViewType,
} from "./dashboard-lists-view.reducer";
import {
  dashboardListsReducer,
  IDashboardListsState,
} from "./dashboard-lists.reducer";

export interface IDashboardListsModuleState {
  dashboardLists: IDashboardListsState;
  dashboardListsFilter: IDashboardListsFilterState;
  dashboardListsQuery: IDashboardListsQueryState;
  dashboardListsSorting: IDashboardListsSortingState;
  dashboardListsViewType: IDashboardListsViewType;
}

const DashboardListsModuleState: ActionReducerMap<IDashboardListsModuleState> =
  {
    dashboardLists: dashboardListsReducer,
    dashboardListsFilter: dashboardListsFilterReducer,
    dashboardListsQuery: dashboardListsSearchReducer,
    dashboardListsSorting: dashboardListsSortingReducer,
    dashboardListsViewType: dashboardListsViewTypeReducer,
  };

// eslint-disable-next-line
export const DASHBOARD_LIST_STATE_NAME = 'DashboardListsModule';
export const DASHBOARD_LIST_STATE: InjectionToken<
  ActionReducerMap<IDashboardListsModuleState>
> = new InjectionToken<ActionReducerMap<IDashboardListsModuleState>>(
  DASHBOARD_LIST_STATE_NAME,
  {
    factory: () => DashboardListsModuleState,
  },
);
