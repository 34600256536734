import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { listAppendsReducer, IListAppendsState } from "./list-appends.reducer";
import { customerListReducer, ICustomerListState } from "./list.reducer";

export interface IListCommonModuleState {
  customerListAppends: IListAppendsState;
  customerList: ICustomerListState;
}

const listModuleReducer: ActionReducerMap<IListCommonModuleState> = {
  customerListAppends: listAppendsReducer,
  customerList: customerListReducer,
};

// eslint-disable-next-line
export const LIST_COMMON_STATE_NAME = 'commonListModule';
export const LIST_COMMON_STATE: InjectionToken<
  ActionReducerMap<IListCommonModuleState>
> = new InjectionToken<ActionReducerMap<IListCommonModuleState>>(
  LIST_COMMON_STATE_NAME,
  {
    factory: () => listModuleReducer,
  },
);
