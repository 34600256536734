import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
} from "@angular/forms";
import { select, Store } from "@ngrx/store";

import { Observable, Subject } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  skip,
  switchMapTo,
  takeUntil,
} from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { checkAddressAction } from "@modules/auth/store/actions/auth.action";
import { getSignUpAddressError } from "@modules/auth/store/selectors/auth.selector";
import { getStatesByCountryAction } from "@modules/countries/store/actions/countries.action";
import {
  getCountriesAsControlOptions,
  getCountryStatesAsControlOptions,
} from "@modules/countries/store/selectors/countries.selector";
import {
  resetErrorsAction,
  updateProfileAndBillingInfoAction,
} from "@modules/profile/store/actions/profile.action";
import {
  getProfileUpdateSuccessMessage,
  getProfileUpdating,
  getUpdatingProfileError,
} from "@modules/profile/store/selectors/profile.selector";

import { AuthService } from "@modules/auth/services/auth.service";

import { PhoneMaskPipe } from "@shared/modules/pipes/shared-pipes/pipes/phone-mask.pipe";

import { IUser } from "@modules/auth/interfaces/user";
import { IControlOption } from "@shared/interfaces/forms";
import { AddressError, IServerError } from "@shared/interfaces/server-error";

import { PopUpRef } from "../../models/pop-up-ref";

import { FLAT_INPUT_THEME } from "@shared/constants/flat-input";
import { SECURITY_TYPE } from "@shared/constants/log-rocket-config";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-e-commerce-edit-user-pop-up-content",
  templateUrl: "./e-commerce-edit-user-pop-up-content.component.html",
  styleUrls: ["./e-commerce-edit-user-pop-up-content.component.scss"],
  providers: [PhoneMaskPipe],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ECommerceEditUserPopUpContentComponent
  implements OnInit, OnDestroy
{
  private _destroyer$: Subject<void> = new Subject();
  readonly securityType: typeof SECURITY_TYPE = SECURITY_TYPE;
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  loading$: Observable<boolean> = this._store.pipe(select(getProfileUpdating));
  serverError$: Observable<IServerError> = this._store.pipe(
    select(getUpdatingProfileError),
  );
  updateSuccessMessage$: Observable<string> = this._store.pipe(
    select(getProfileUpdateSuccessMessage),
  );
  serverAddressError$: Observable<AddressError> = this._store.pipe(
    select(getSignUpAddressError),
  );
  countriesOptions$: Observable<IControlOption[]> = this._store.pipe(
    select(getCountriesAsControlOptions),
  );
  countryStatesOptions$: Observable<IControlOption[]> = this._store.pipe(
    select(getCountryStatesAsControlOptions),
  );

  form: UntypedFormGroup;
  loading: boolean;
  opened: boolean;

  get address(): UntypedFormGroup {
    return this.form.get("address") as UntypedFormGroup;
  }

  get stateCode(): AbstractControl {
    return this.address.get("stateCode");
  }

  get countryCode(): AbstractControl {
    return this.address.get("countryCode");
  }

  constructor(
    @Inject(POP_UP_DATA) public userData: { user: IUser },
    private _popUpRef: PopUpRef,
    private _fb: UntypedFormBuilder,
    private _store: Store<CoreState>,
    private _service: AuthService,
    public phoneMask: PhoneMaskPipe,
  ) {}

  ngOnInit(): void {
    this.generateForm();
    if (!this.userData.user.countryCode) {
      this.stateCode.disable();
    }
    this.subscribeUpdateProfileError();
    this.subscribeUpdateProfileSuccessMessage();
    this.subscribeToAddressError();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
    this._store.dispatch(resetErrorsAction());
  }

  generateForm(): void {
    this.form = this._service.profileForm(false, false, this.userData.user);
  }

  subscribeUpdateProfileError(): void {
    this.loading$
      .pipe(
        takeUntil(this._destroyer$),
        skip(1), // skip initial value
        filter((isLoading: boolean) => !isLoading),
        switchMapTo(this.serverError$),
        filter((errors: IServerError) => !!errors),
      )
      .subscribe((errors: IServerError) => {
        Object.entries(errors.errors).forEach(
          ([fieldName, message]: [string, string[]]) => {
            const control: AbstractControl = this.form.get(fieldName);

            if (control) {
              control.setErrors(message as ValidationErrors);
            }
          },
        );
      });
  }

  subscribeUpdateProfileSuccessMessage(): void {
    this.updateSuccessMessage$
      .pipe(
        takeUntil(this._destroyer$),
        skip(1),
        distinctUntilChanged(),
        filter((message: string) => !!message),
      )
      .subscribe(() => {
        this._popUpRef.close();
      });
  }

  subscribeToAddressError(): void {
    this.serverAddressError$
      .pipe(
        takeUntil(this._destroyer$),
        filter((error: AddressError) => !!error),
      )
      .subscribe((error: AddressError) => {
        const { errorMessages, suspectAddress }: AddressError = error;

        if (suspectAddress && this.form) {
          this.form = this._service.profileForm(false, false, {
            ...this.userData.user,
            ...this.form.value,
            ...suspectAddress,
          });
        }

        if (errorMessages && !suspectAddress && this.form) {
          this.address.setErrors({
            general: errorMessages.join(". "),
          } as ValidationErrors);
        }
      });
  }

  submit(): void {
    this.form.enable();
    if (!this.form.valid) {
      return;
    }

    const { address, ...form }: any = this.form.value;

    this._store.dispatch(
      checkAddressAction({
        addressValidateData: address,
        successAction: updateProfileAndBillingInfoAction({
          ...form,
          ...address,
        } as IUser),
      }),
    );
  }

  cancel(): void {
    this._popUpRef.close();
  }

  onCountryChange(): void {
    this._store.dispatch(getStatesByCountryAction(this.countryCode.value));
    this.stateCode.enable();
  }
}
