// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[disabled] + .fake-checkbox {
  opacity: 0.3;
  cursor: default;
}

.content {
  margin-left: 5px;
  position: relative;
  top: 1px;
}

.disabled .content {
  cursor: default;
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/custom-controls/components/custom-checkbox/custom-checkbox.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;AACF;;AAEA;EACE,gBAAA;EACA,kBAAA;EACA,QAAA;AACF;;AAGE;EACE,eAAA;EACA,YAAA;AAAJ","sourcesContent":["input[disabled] + .fake-checkbox {\n  opacity: 0.3;\n  cursor: default;\n}\n\n.content {\n  margin-left: 5px;\n  position: relative;\n  top: 1px;\n}\n\n.disabled {\n  .content {\n    cursor: default;\n    opacity: 0.5;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
