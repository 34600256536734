import { ITableHeader } from "@ui/tables/components/table/table.component";

export enum BIDS_ENTITY_SORTING {
  NAME = "name",
  SUBMITTAL = "submittal",
}

export const MAX_BIDS_ENTITY_CACHE: number = 180;

export const BIDS_TABLE_HEADERS: ITableHeader[] = [
  {
    key: "name",
    title: "Project Name",
    sortable: true,
  },
  {
    key: "type",
    title: "Type",
    sortable: true,
  },
  {
    key: "location",
    title: "Location",
    sortable: true,
  },
  {
    key: "publish",
    title: "Publish",
    sortable: true,
  },
  {
    key: "submittal",
    title: "Submittal",
    sortable: true,
  },
];

export const BIDS_TABLE_HEADERS_MOBILE: ITableHeader[] = [
  {
    key: "name",
    title: "Project Name",
    sortable: true,
  },
  {
    key: "location",
    title: "Location",
    sortable: true,
  },
  {
    key: "type",
    title: "Type",
    sortable: true,
  },
  {
    key: "submittal",
    title: "Submittal",
    sortable: true,
  },
];

export const BIDS_TABLE_DESCRIPTION_MAX_LENGTH: number = 280;
