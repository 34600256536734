import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/create-list-from-view.action";

import {
  CREATE_VIEW_LIST_BY_TYPE,
  CREATE_VIEW_LIST_TYPE,
} from "../../constants";

export interface ICreateListFromViewState {
  type: CREATE_VIEW_LIST_TYPE | null;
}

const initialState: ICreateListFromViewState = {
  type: null,
};

const setCreateListFromViewType: OnReducer<
  ICreateListFromViewState,
  ActionType<any>
> = (state: ICreateListFromViewState, { payload: type }: Payload<any>) => ({
  ...state,
  type,
});

const resetCreateListFromViewType: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICreateListFromViewState> =
  createReducer<ICreateListFromViewState>(
    initialState,

    on(actions.setCreateListFromViewTypeAction, setCreateListFromViewType),

    on(actions.resetCreateListFromViewTypeAction, resetCreateListFromViewType),
  );

export function createListFromViewReducer(
  state: ICreateListFromViewState,
  action: Action,
): ICreateListFromViewState {
  return reducer(state, action);
}

export const createListFromViewType: GetFromState<
  CREATE_VIEW_LIST_TYPE,
  ICreateListFromViewState
> = (state: ICreateListFromViewState): CREATE_VIEW_LIST_TYPE => state.type;
export const createListFromViewOptions: GetFromState<
  any,
  CREATE_VIEW_LIST_TYPE
> = (type: CREATE_VIEW_LIST_TYPE): any =>
  CREATE_VIEW_LIST_BY_TYPE[type] || null;
