import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from "@angular/core";

import { fromEvent, merge, timer, Observable, Subject } from "rxjs";
import { delay, filter, map, takeUntil } from "rxjs/operators";

import { DEFAULT_INFINITY_SCROLL_TABLE_COUNT } from "@shared/constants/pagination";
import { DEFAULT_SCROLL_WIDTH } from "@shared/constants/scroll";
import { SORT_DIRECTION } from "@shared/constants/sort";

export type ITableHeaders = ITableHeader[];

export interface ITableHeader {
  key: string;
  title: string | number;
  sortable?: boolean;
  align?: "left" | "center" | "right";
  sortingFunction?: (data: any[], sortDirection: SORT_DIRECTION) => any;
  checkbox?: boolean;
  disabled?: boolean;
  tooltipText?: string;
  backgroundColor?: string;
}

@Component({
  selector: "bl-table",
  templateUrl: "./table.component.html",
  styleUrls: ["./table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent
  implements OnInit, OnDestroy, OnChanges, AfterViewInit
{
  @Input() data: Array<{ [key: string]: any }> = [];
  @Input() headers: ITableHeader[] = [];

  @Input() sortBy: string;
  @Input() sortDirection: SORT_DIRECTION;
  @Input() searchBy: string = "";

  @Input() searchableKeys: string[];

  @Input() infinityScroll: boolean;
  @Input() infinityScrollFreeze: boolean;
  @Input() infinityScrollVisibleRowCounter: number =
    DEFAULT_INFINITY_SCROLL_TABLE_COUNT;

  @Input() pending: boolean;

  @Input() manageFiltersExternally: boolean = false;
  @Input() computedProperties: { [key: string]: (row: any) => any };

  @Input() firstRowTemplate: TemplateRef<any>;
  @Input() rowTemplate: TemplateRef<any>;
  @Input() headerTemplate: TemplateRef<ITableHeader>;

  @Input() isAllChecked: boolean;

  @Output() changeCheckbox: EventEmitter<boolean> = new EventEmitter();
  @Output() changeSortBy: EventEmitter<string> = new EventEmitter<string>();
  @Output() changeSortDirection: EventEmitter<SORT_DIRECTION> =
    new EventEmitter<SORT_DIRECTION>();

  @Output() infinityScrollNextPage: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("tableHeaderTpl") tableHeaderTpl: any;
  @ViewChild("tableHeaderRowTpl") tableHeaderRowTpl: any;
  @ViewChild("tableBodyTpl") tableBodyTpl: any;
  @ViewChild("scrollWrapper") scrollWrapper: any;
  @ViewChildren("tableBodyTpl") childrenTableBodyTpl: QueryList<ElementRef>;

  private destroyer$: Subject<any> = new Subject<any>();

  _data: Array<{ [key: string]: any }>;

  _sortBy: string;
  _sortDirection: SORT_DIRECTION = SORT_DIRECTION.DESC;
  scrollWidth: number = DEFAULT_SCROLL_WIDTH;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    if (!this._sortBy) {
      const sortableHeaders: ITableHeader[] = this.headers.filter(
        (headerRow: ITableHeader) => headerRow.sortable,
      );
      this._sortBy = sortableHeaders.length ? sortableHeaders[0].key : "";
    }

    if (this.sortDirection) {
      const sortableFunction: ITableHeader = this.headers.find(
        (row: ITableHeader) => typeof row.sortingFunction !== "undefined",
      );

      if (sortableFunction) {
        this._data = sortableFunction.sortingFunction(
          [...this._data],
          this._sortDirection,
        );
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }

  ngAfterViewInit(): void {
    if (this.infinityScroll && this.infinityScrollNextPage.observers.length) {
      this.setInfinityScrollStyles();
      this.infinityScrollListener();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data || changes.computedProperties) {
      this._data = this.computedProperties
        ? this.data.map((item: { [key: string]: any }) =>
            Object.entries(this.computedProperties).reduce(
              (
                res: { [key: string]: any },
                [name, fn]: [string, Function],
              ) => ({
                ...res,
                [name]: fn(item),
              }),
              { ...item },
            ),
          )
        : this.data && [...this.data];
    }

    if (changes.sortBy) {
      this._sortBy = this.sortBy;
    }

    if (changes.sortDirection) {
      this._sortDirection = this.sortDirection;
    }
  }

  sort(header: ITableHeader): void {
    if (this.pending) {
      return;
    }

    this.manageFiltersExternally
      ? this.externalSort(header.key)
      : this.internalSort(header);
  }

  private internalSort(header: ITableHeader): void {
    if (this._sortBy !== header.key) {
      this._sortBy = header.key;
    } else {
      this._sortDirection === SORT_DIRECTION.DESC
        ? (this._sortDirection = SORT_DIRECTION.ASC)
        : (this._sortDirection = SORT_DIRECTION.DESC);
    }

    if (header.sortingFunction) {
      this._data = header.sortingFunction([...this._data], this._sortDirection);
    }

    if (!this.cdr["destroyed"]) {
      this.cdr.detectChanges();
    }
  }

  private externalSort(sortBy: string): void {
    if (this._sortBy !== sortBy) {
      this.changeSortBy.emit(sortBy);
    } else {
      this.changeSortDirection.emit(
        this._sortDirection === SORT_DIRECTION.ASC
          ? SORT_DIRECTION.DESC
          : SORT_DIRECTION.ASC,
      );
    }
  }

  setInfinityScrollStyles(): void {
    this.childrenTableBodyTpl.changes
      .pipe(
        map((tbody: any) => tbody.last.nativeElement),
        takeUntil(this.destroyer$),
        delay(0),
      )
      .subscribe((nativeElement: any) => {
        const firstTr: HTMLTableRowElement = nativeElement.querySelector("tr");
        const headerThCollection: NodeListOf<HTMLTableDataCellElement> =
          this.tableHeaderRowTpl.nativeElement.querySelectorAll("th");
        const firstBodyTrTdCollection: NodeListOf<HTMLTableDataCellElement> =
          firstTr.querySelectorAll("td");

        const scrollWidthInPercentage: number =
          (this.scrollWidth / this.tableBodyTpl.nativeElement.offsetWidth) *
          100;

        this.renderer.setStyle(
          this.tableHeaderTpl.nativeElement,
          "width",
          `${(firstTr.offsetWidth / this.tableBodyTpl.nativeElement.offsetWidth) * 100 - scrollWidthInPercentage}%`,
        );

        this.renderer.setStyle(
          this.scrollWrapper.nativeElement,
          "max-height",
          `${firstTr.offsetHeight * this.infinityScrollVisibleRowCounter}px`,
        );

        Array.from(firstBodyTrTdCollection).forEach(
          (td: HTMLTableDataCellElement, index: number) => {
            this.renderer.setStyle(
              headerThCollection[index],
              "width",
              `${(td.offsetWidth / firstTr.offsetWidth) * 100}%`,
            );
          },
        );

        this.renderer.setStyle(
          this.elementRef.nativeElement,
          "padding-top",
          `${this.tableHeaderTpl.nativeElement.offsetHeight}px`,
        );

        this.renderer.addClass(this.elementRef.nativeElement, "scrollable");
      });
  }

  infinityScrollListener(): void {
    const timer$: Observable<number> = timer(0);
    const scrollEvent$: Observable<unknown> = fromEvent(
      this.scrollWrapper.nativeElement,
      "scroll",
    );

    merge(timer$, scrollEvent$)
      .pipe(
        takeUntil(this.destroyer$),
        filter(() => !this.infinityScrollFreeze && this.infinityScroll),
        filter(() => {
          return (
            this.tableBodyTpl.nativeElement.getBoundingClientRect().bottom <=
            this.scrollWrapper.nativeElement.getBoundingClientRect().bottom
          );
        }),
      )
      .subscribe(() => {
        this.infinityScrollNextPage.emit();
      });
  }

  callChangeCheckbox(state: boolean): void {
    this.changeCheckbox.emit(state);
  }

  trackByKey(index: number, value: ITableHeader): string | number {
    return value.key;
  }
}
