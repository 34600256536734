import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/dashboard-views-view.action";

import { SessionStorageService } from "@core/services/session-storage.service";

import { DASHBOARD_VIEW_TYPE } from "../../../dashboard/interfaces";

export interface IDashboardViewsViewType {
  viewType: DASHBOARD_VIEW_TYPE;
}

const initialState: IDashboardViewsViewType = {
  viewType:
    SessionStorageService.dashboardViewsViewType || DASHBOARD_VIEW_TYPE.PRODUCT,
};

const changeDashboardViewsViewType: OnReducer<
  IDashboardViewsViewType,
  ActionType<Payload<any>>
> = (state: IDashboardViewsViewType, { payload }: Payload<any>) => ({
  ...state,
  viewType: payload,
});

export const reducer: ActionReducer<IDashboardViewsViewType> =
  createReducer<IDashboardViewsViewType>(
    initialState,

    on(
      actions.changeDashboardViewsViewTypeAction,
      changeDashboardViewsViewType,
    ),
  );

export function dashboardViewsViewTypeReducer(
  state: IDashboardViewsViewType,
  action: Action,
): IDashboardViewsViewType {
  return reducer(state, action);
}

export const dashboardViewsViewType: GetFromState<
  DASHBOARD_VIEW_TYPE,
  IDashboardViewsViewType
> = (state: IDashboardViewsViewType): DASHBOARD_VIEW_TYPE => state.viewType;
