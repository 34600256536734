import { Pipe, PipeTransform } from "@angular/core";

import {
  LIST_STATUS_ID,
  LIST_STATUS_NAMES_BY_ID,
} from "@shared/constants/data/list-statuses";

@Pipe({
  name: "blListStatusName",
  pure: true,
})
export class ListStatusNamePipe implements PipeTransform {
  transform(value: LIST_STATUS_ID): string {
    if (typeof value !== "number") {
      return value;
    }

    if (LIST_STATUS_NAMES_BY_ID[value]) {
      return LIST_STATUS_NAMES_BY_ID[value];
    }

    return value.toString();
  }
}
