import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  cdlListRefreshScheduleReducer,
  ICDLRefreshScheduleState,
} from "./cdl-list-refresh-schedule.reducer";

export interface ICDLRefreshScheduleModuleState {
  cdlRefreshSchedule: ICDLRefreshScheduleState;
}

const cdlRefreshScheduleModuleReducer: ActionReducerMap<ICDLRefreshScheduleModuleState> =
  {
    cdlRefreshSchedule: cdlListRefreshScheduleReducer,
  };

// eslint-disable-next-line
export const CDL_REFRESH_SCHEDULE_MODULE_STATE_NAME = 'cdlRefreshSchedule';
export const CDL_REFRESH_SCHEDULE_MODULE_STATE: InjectionToken<
  ActionReducerMap<ICDLRefreshScheduleModuleState>
> = new InjectionToken<ActionReducerMap<ICDLRefreshScheduleModuleState>>(
  CDL_REFRESH_SCHEDULE_MODULE_STATE_NAME,
  { factory: () => cdlRefreshScheduleModuleReducer },
);
