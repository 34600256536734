import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "decimal",
})
export class DecimalPipe implements PipeTransform {
  transform(value: number, minValue: number = 1): number {
    if (typeof value !== "number") {
      return value;
    }

    // round down
    if (value < minValue) {
      return Math.ceil(value);
    }

    // round up
    return Math.floor(value);
  }
}
