import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/edit-user-account.action";

import {
  checkedAddOns,
  checkedAddOnsOnUser,
  checkedAvailableAddOns,
} from "../../utils/add-ons";
import { MapSeat } from "../../utils/products";
import {
  checkedAvailableStatesIds,
  checkedStateIdsOnUser,
  checkedUnassignedStateIds,
} from "../../utils/states";

import { IControlOption, IControlOptions } from "@shared/interfaces/forms";
import { ICDLLists } from "@shared/interfaces/manage-account-lists";
import { IServerError } from "@shared/interfaces/server-error";
import {
  IAccountSeat,
  IAccountUser,
  IAccountUsers,
  IEditAccountUser,
  IEditUserPopUpPayload,
  IOpenSeatAddOns,
  IOpenSeatStates,
  ISelectedAddOnIds,
  ISelectedStateIds,
  Roles,
  SEATS,
} from "../../interfaces/marketview";

import { ADD_ON_FIELD } from "../../constants/add-ons";
import { USER_STATUS_IS_MERGE } from "../../constants/marketview";
import { RolesKeys } from "../../constants/roles";

export interface IEditUserAccountState {
  user: IEditAccountUser;

  isShouldShowAvailableStates: boolean;

  selectedStateIds: ISelectedStateIds;
  selectedAddOnsIds: ISelectedAddOnIds;

  error: IServerError | null;
  pending: boolean;
  pendingInvitation: boolean;
}

const _initialState: IEditUserAccountState = {
  user: null,

  isShouldShowAvailableStates: true,

  selectedStateIds: {
    unassigned: [],
    available: [],
    userHas: [],
  },
  selectedAddOnsIds: {
    bidAddOnIds: [],
    bidAvailableIds: [],
    bidAddOnIdsOnUser: [],
    grantAddOnIds: [],
    grantAvailableIds: [],
    grantAddOnIdsOnUser: [],
  },

  error: null,
  pending: false,
  pendingInvitation: false,
};

const openEditUserPopUpHandler: OnReducer<
  IEditUserAccountState,
  ActionType<Payload<any>>
> = (state: IEditUserAccountState, { payload }: Payload<any>) => ({
  ...state,
  user: { ...payload },
});

export const setShowAvailableStatesHandler: OnReducer<
  IEditUserAccountState,
  ActionType<Payload<any>>
> = (state: IEditUserAccountState, { payload }: Payload<any>) => ({
  ...state,
  isShouldShowAvailableStates: payload
    ? payload
    : !state.isShouldShowAvailableStates,
});

const editAccountUserHandler: OnReducer<
  IEditUserAccountState,
  ActionType<any>
> = (state: IEditUserAccountState) => ({
  ...state,
  pending: true,
  error: null,
});

const editAccountUserErrorHandler: OnReducer<
  IEditUserAccountState,
  ActionType<Payload<any>>
> = (state: IEditUserAccountState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const editAccountUserSuccessHandler: OnReducer<
  IEditUserAccountState,
  ActionType<any>
> = (state: IEditUserAccountState) => ({
  ...state,
  pending: false,
  error: null,
});

const resendInvitationHandler: OnReducer<
  IEditUserAccountState,
  ActionType<any>
> = (state: IEditUserAccountState) => ({
  ...state,
  pendingInvitation: true,
  error: null,
});

const resendInvitationErrorHandler: OnReducer<
  IEditUserAccountState,
  ActionType<Payload<any>>
> = (state: IEditUserAccountState, { payload }: Payload<any>) => ({
  ...state,
  pendingInvitation: false,
  error: { ...payload },
});

const resendInvitationSuccessHandler: OnReducer<
  IEditUserAccountState,
  ActionType<any>
> = (state: IEditUserAccountState) => ({
  ...state,
  pendingInvitation: false,
  error: null,
});

const changeSelectedStateHandler: OnReducer<
  IEditUserAccountState,
  ActionType<Payload<any>>
> = (state: IEditUserAccountState, { payload }: Payload<any>) => {
  const {
    states,
    openStates: { availableIds },
    openAddOns: { bidStateAvailable, grantStateAvailable },
  }: any = payload;

  const { stateIds, bidStateIds, grantStateIds }: Partial<IEditAccountUser> =
    state.user;

  const _unassignedStateIds: number[] = checkedUnassignedStateIds(
    states,
    stateIds,
    availableIds,
  );
  const _availableStateIds: number[] = checkedAvailableStatesIds(
    states,
    availableIds,
  );
  const _onUserStateIds: number[] = checkedStateIdsOnUser(
    states,
    stateIds,
    availableIds,
  );

  const _bidAddOnIds: number[] = checkedAddOns(
    states,
    bidStateIds,
    bidStateAvailable,
  );
  const _bidAvailableIds: number[] = checkedAvailableAddOns(
    states,
    bidStateAvailable,
  );
  const _bidAddOnIdsOnUser: number[] = checkedAddOnsOnUser(
    states,
    bidStateIds,
    bidStateAvailable,
  );

  const _grantAddOnIds: number[] = checkedAddOns(
    states,
    grantStateIds,
    grantStateAvailable,
    ADD_ON_FIELD.GRANT,
  );
  const _grantAvailableIds: number[] = checkedAvailableAddOns(
    states,
    grantStateAvailable,
    ADD_ON_FIELD.GRANT,
  );
  const _grantAddOnIdsOnUser: number[] = checkedAddOnsOnUser(
    states,
    grantStateIds,
    grantStateAvailable,
    ADD_ON_FIELD.GRANT,
  );

  return {
    ...state,
    selectedStateIds: {
      unassigned: [..._unassignedStateIds],
      available: [..._availableStateIds],
      userHas: [..._onUserStateIds],
    },
    selectedAddOnsIds: {
      bidAddOnIds: [..._bidAddOnIds],
      bidAvailableIds: [..._bidAvailableIds],
      bidAddOnIdsOnUser: [..._bidAddOnIdsOnUser],
      grantAddOnIds: [..._grantAddOnIds],
      grantAvailableIds: [..._grantAvailableIds],
      grantAddOnIdsOnUser: [..._grantAddOnIdsOnUser],
    },
  };
};

const changeSeatTypeHandler: OnReducer<
  IEditUserAccountState,
  ActionType<Payload<any>>
> = (state: IEditUserAccountState, { payload }: Payload<any>) => ({
  ...state,
  user: {
    ...state.user,
    seatId: payload,
  },
  selectedStateIds: {
    unassigned: [],
    available: [],
    userHas: [],
  },
  selectedAddOnsIds: {
    bidAddOnIds: [],
    bidAvailableIds: [],
    bidAddOnIdsOnUser: [],
    grantAddOnIds: [],
    grantAvailableIds: [],
    grantAddOnIdsOnUser: [],
  },
  isShouldShowAvailableStates: true,
});

const resetStateHandler: OnReducer<any, ActionType<any>> = () => ({
  ..._initialState,
});

const reducer: ActionReducer<IEditUserAccountState> =
  createReducer<IEditUserAccountState>(
    _initialState,

    on(actions.openEditUserPopUpAction, openEditUserPopUpHandler),
    on(actions.closeEditUserPopUpAction, resetStateHandler),

    on(actions.setShowAvailableStatesAction, setShowAvailableStatesHandler),

    on(actions.editAccountUserAction, editAccountUserHandler),
    on(actions.editSelfUserAccountAction, editAccountUserHandler),
    on(actions.editAccountUserErrorAction, editAccountUserErrorHandler),

    on(actions.editSelfUserAccountErrorAction, editAccountUserErrorHandler),

    on(actions.editAccountUserSuccessAction, editAccountUserSuccessHandler),
    on(actions.editSelfUserAccountSuccessAction, editAccountUserSuccessHandler),

    on(actions.resendInvitationAction, resendInvitationHandler),
    on(actions.resendInvitationErrorAction, resendInvitationErrorHandler),
    on(actions.resendInvitationSuccessAction, resendInvitationSuccessHandler),

    on(actions.changeSelectedStateAction, changeSelectedStateHandler),

    on(actions.changeSeatTypeAction, changeSeatTypeHandler),

    on(actions.resetEditUserStateAction, resetStateHandler),
  );

export function editUserAccountReducer(
  _state: IEditUserAccountState,
  _action: Action,
): IEditUserAccountState {
  return reducer(_state, _action);
}

export const user: GetFromState<IEditAccountUser, IEditUserAccountState> = (
  _state: IEditUserAccountState,
): IEditAccountUser => _state.user;
export const seatId: GetFromState<SEATS, IEditAccountUser> = (
  _user: IEditAccountUser,
): SEATS => _user && _user.seatId;
export const userStatus: GetFromState<number, IEditAccountUser> = (
  _user: IEditAccountUser,
): number => _user && _user.status;

export const userId: GetFromState<number, IEditAccountUser> = (
  _user: IEditAccountUser,
): number => _user && _user.id;
export const roleId: GetFromState<Roles, IEditAccountUser> = (
  _user: IEditAccountUser,
): Roles => _user && _user.roleId;

export const pending: GetFromState<boolean, IEditUserAccountState> = (
  _state: IEditUserAccountState,
): boolean => _state.pending;
export const error: GetFromState<IServerError, IEditUserAccountState> = (
  _state: IEditUserAccountState,
): IServerError => _state.error;

export const usersWithoutEditingUserId: GetFromState<
  IAccountUsers,
  IAccountUsers,
  number
> = (_users: IAccountUsers, _currentUserId: number): IAccountUsers => {
  return (
    _users &&
    _users.filter((_item: IAccountUser) => _item.id !== _currentUserId)
  );
};

export const usersWithoutEditingUserAndInvited: GetFromState<
  IAccountUsers,
  IAccountUsers,
  number
> = (_users: IAccountUsers, _currentUserId: number): IAccountUsers => {
  return (
    _users &&
    _users.filter(
      (_user: IAccountUser) => _user.id !== _currentUserId && !_user.isInvited,
    )
  );
};

export const currentUserOpened: GetFromState<
  IAccountUser,
  IAccountUsers,
  number
> = (_users: IAccountUsers, _currentUserId: number): IAccountUser => {
  return (
    _users && _users.find((_item: IAccountUser) => _item.id === _currentUserId)
  );
};

export const isEditSelf: GetFromState<boolean, number, number> = (
  _editUserId: number,
  _userSelfId: number,
): boolean => _editUserId && _userSelfId && _editUserId === _userSelfId;

export const canRemoveUsers: GetFromState<boolean, RolesKeys, Roles> = (
  _selfRole: RolesKeys,
  _currentUserRoleId: Roles,
): boolean => {
  return (
    _selfRole === RolesKeys.Owner ||
    (_selfRole === RolesKeys.Admin && _currentUserRoleId === Roles.User)
  );
};

export const isDisableDeleteBtn: GetFromState<boolean, number, boolean> = (
  status: number,
  isHasPermissions: boolean,
): boolean => !isHasPermissions || USER_STATUS_IS_MERGE.includes(status);

export const canChangePermissions: GetFromState<boolean, RolesKeys, Roles> = (
  _selfRole: RolesKeys,
  _currentUserRoleId: Roles,
): boolean => {
  return (
    _selfRole === RolesKeys.Owner ||
    (_selfRole === RolesKeys.Admin && _currentUserRoleId === Roles.Admin) ||
    (_selfRole === RolesKeys.Admin && _currentUserRoleId === Roles.User)
  );
};

export const pendingInvitation: GetFromState<boolean, IEditUserAccountState> = (
  _state: IEditUserAccountState,
): boolean => _state.pendingInvitation;

export const isShouldShowAvailableSeatStates: GetFromState<
  boolean,
  IEditUserAccountState
> = (_state: IEditUserAccountState): boolean =>
  _state.isShouldShowAvailableStates;

export const selectedAllStateIds: GetFromState<
  number[],
  IEditUserAccountState
> = (_state: IEditUserAccountState): number[] => [
  ..._state.selectedStateIds.available,
  ..._state.selectedStateIds.userHas,
  ..._state.selectedStateIds.unassigned,
];

export const selectedAddOnsIds: GetFromState<
  ISelectedAddOnIds,
  IEditUserAccountState
> = (_state: IEditUserAccountState): ISelectedAddOnIds =>
  _state && _state.selectedAddOnsIds;

export const availableUncheckedStates: GetFromState<
  IControlOptions,
  IEditUserAccountState,
  IControlOptions,
  IControlOptions,
  IOpenSeatStates,
  number[]
> = (
  _state: IEditUserAccountState,
  _states: IControlOptions = [],
  _availableStates: IControlOptions = [],
  _openStates: IOpenSeatStates,
  _selectedStateIds: number[] = [],
): IControlOptions => {
  const _userStateIds: number[] =
    (_state && _state.user && _state.user.stateIds) || [];
  const _selectedUnassigned: number = _state.selectedStateIds.unassigned.length;

  if (_state.isShouldShowAvailableStates && _openStates.availableIds.length) {
    return _availableStates.filter(
      (_item: IControlOption) => !_selectedStateIds.includes(_item.value),
    );
  }

  if (_selectedUnassigned < _openStates.unassigned) {
    return _states.filter(
      (_item: IControlOption) => !_selectedStateIds.includes(_item.value),
    );
  }

  if (
    _selectedUnassigned === _openStates.unassigned &&
    _state.selectedStateIds.userHas.length < _userStateIds.length
  ) {
    return _states.filter(
      (_item: IControlOption) =>
        _userStateIds.includes(_item.value) &&
        !_selectedStateIds.includes(_item.value),
    );
  }

  return [];
};

export const isShowAddState: GetFromState<
  boolean,
  IEditUserAccountState,
  IOpenSeatStates,
  number[]
> = (
  _state: IEditUserAccountState,
  _openStates: IOpenSeatStates,
  _selectedAllStateIds: number[],
): boolean => {
  const _userHasStates: number[] =
    (_state && _state.user && _state.user.stateIds) || [];
  const _allUniqueStateIds: Set<number> = new Set([
    ..._userHasStates,
    ..._openStates.availableIds,
  ]);

  return (
    _selectedAllStateIds.length <
    _allUniqueStateIds.size + _openStates.unassigned
  );
};

export const isHasStates: GetFromState<boolean, IControlOptions> = (
  _states: IControlOptions,
): boolean => _states && _states.length > 0;

export const canCreateNewState: GetFromState<
  boolean,
  IEditUserAccountState,
  IOpenSeatStates
> = (_state: IEditUserAccountState, _openStates: IOpenSeatStates): boolean => {
  const { selectedStateIds: _selectedIds }: Partial<IEditUserAccountState> =
    _state;
  const _userStatesCount: number =
    _state.user &&
    _state.user.stateIds.filter(
      (_id: number) => !_openStates.availableIds.includes(_id),
    ).length;

  return (
    _selectedIds &&
    (_selectedIds.unassigned.length < _openStates.unassigned ||
      _selectedIds.userHas.length < _userStatesCount)
  );
};

export const isShowAvailableStates: GetFromState<
  boolean,
  IEditUserAccountState,
  number[]
> = (_state: IEditUserAccountState, _statesAvailable: number[]): boolean =>
  _state && _state.isShouldShowAvailableStates && !!_statesAvailable.length;

export const userSelectedSeat: GetFromState<number, IAccountUser> = (
  _user: IAccountUser,
): number => {
  return _user && _user.seats && _user.seats.length && _user.seats[0].id;
};

export const baseSeatsOptions: GetFromState<
  IControlOptions,
  IAccountSeat[],
  SEATS
> = (_seats: IAccountSeat[] = [], _selectedSeat: SEATS): IControlOptions => {
  return _seats.map((item: IAccountSeat) => new MapSeat(item, _selectedSeat));
};

export const editUserPopUpPayload: GetFromState<
  IEditUserPopUpPayload,
  boolean,
  IControlOptions,
  IOpenSeatStates,
  IOpenSeatAddOns,
  ICDLLists,
  boolean
> = (
  _isEditSelf: boolean,
  allStates: IControlOptions,
  openStates: IOpenSeatStates,
  openAddOns: IOpenSeatAddOns,
  cdlLists: ICDLLists,
  asAdmin: boolean,
): IEditUserPopUpPayload => ({
  isEditSelf: _isEditSelf,
  allStates,
  openStates,
  openAddOns,
  cdlLists,
  asAdmin,
});
