import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  schoolDistrictSchoolsReducer,
  ISchoolDistrictSchoolsState,
} from "./school-district-schools.reducer";
import {
  schoolProfileReducer,
  ISchoolProfileState,
} from "./school-profile.reducer";

export interface ISchoolProfileReducers {
  profile: ISchoolProfileState;
  schoolDistrictSchools: ISchoolDistrictSchoolsState;
}

const schoolProfileReducers: ActionReducerMap<ISchoolProfileReducers> = {
  profile: schoolProfileReducer,
  schoolDistrictSchools: schoolDistrictSchoolsReducer,
};

// eslint-disable-next-line
export const SCHOOL_PROFILE_STATE_NAME = 'school';
export const SCHOOL_PROFILE_STATE: InjectionToken<
  ActionReducerMap<ISchoolProfileReducers>
> = new InjectionToken<ActionReducerMap<ISchoolProfileReducers>>(
  SCHOOL_PROFILE_STATE_NAME,
  { factory: () => schoolProfileReducers },
);
