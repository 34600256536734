import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  cdlListCustomizeReducer,
  ICDLListCustomizeState,
} from "@modules/cdl/cdl-list-customize/store/reducers/cdl-list-customize.reducer";

export interface ICDLListCustomizeModuleState {
  cdlListCustomize: ICDLListCustomizeState;
}

const ICDLListCustomizeModuleReducer: ActionReducerMap<ICDLListCustomizeModuleState> =
  {
    cdlListCustomize: cdlListCustomizeReducer,
  };
// eslint-disable-next-line
export const CDL_LIST_CUSTOMIZE_MODULE_STATE_NAME = 'cdlListCustomizeModule';
export const CDL_LIST_CUSTOMIZE_MODULE_STATE: InjectionToken<
  ActionReducerMap<ICDLListCustomizeModuleState>
> = new InjectionToken<ActionReducerMap<ICDLListCustomizeModuleState>>(
  CDL_LIST_CUSTOMIZE_MODULE_STATE_NAME,
  {
    factory: () => ICDLListCustomizeModuleReducer,
  },
);
