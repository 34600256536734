import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { NavigationStart, Router, RouterEvent } from "@angular/router";

import { fromEvent, merge, Subject } from "rxjs";
import { debounceTime, filter, takeUntil } from "rxjs/operators";

import { WindowRef } from "@core/refs/window-ref.service";
import { LayoutService } from "@core/services/layout.service";

import { IDropDownParams } from "../../interfaces/drop-down";

import { DropDownRef } from "../../models/drop-down-ref";

import {
  BIG_DROP_DOWN_ARROW_OFFSET,
  CORRECT_DROP_DOWN_ARROW_LEFT_POS,
} from "../../constants/drop-down";

@Component({
  selector: "bl-drop-down-portal",
  templateUrl: "./drop-down-portal.component.html",
  styleUrls: ["./drop-down-portal.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class DropDownPortalComponent implements AfterViewInit, OnDestroy {
  @ViewChild("content") contentEl: ElementRef;

  bodyClass: any;
  bodyStyle: any;

  arrowClass: any;
  outerArrowStyle: any;
  innerArrowStyle: any;

  contentTemplate: TemplateRef<any>;
  params: IDropDownParams;

  private _targetEl: ElementRef;
  private _destroyer$: Subject<void> = new Subject();

  constructor(
    private _dropDownRef: DropDownRef,
    private _layoutService: LayoutService,
    private _router: Router,
    private _changeDetectorRef: ChangeDetectorRef,
    private _window: WindowRef,
  ) {
    const { params, contentTemplate, targetEl }: DropDownRef =
      this._dropDownRef;

    this.params = params;
    this.contentTemplate = contentTemplate;
    this._targetEl = targetEl;
  }

  ngAfterViewInit(): void {
    if (this.params.fullScreen) {
      this.setFullScreen();
    } else {
      this.setStyles();
      this.calculatePositionsOnLayoutChanges();
    }

    if (!this._changeDetectorRef["destroyed"]) {
      this._changeDetectorRef.detectChanges();
    }

    this.closeOnNavigation();
    this.onUpdateParams();
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  @HostListener("document:click", ["$event"])
  onOutsideClick(event: MouseEvent): void {
    if (
      !this.contentEl.nativeElement.contains(event.target) &&
      !this.params.isPreventClose
    ) {
      this.close();
    }
  }

  close(): void {
    this._dropDownRef.close();
  }

  private calculatePositionsOnLayoutChanges(): void {
    merge(
      fromEvent(this._window.nativeElement, "resize"),
      fromEvent(this._window.nativeElement, "scroll"),
      this._layoutService.isScrollOverlay,
      this._layoutService.layoutHasBeenChanged.pipe(debounceTime(0)),
    )
      .pipe(takeUntil(this._destroyer$))
      .subscribe(this.setStyles);
  }

  private onUpdateParams(): void {
    this._dropDownRef.paramsUpdated
      .pipe(takeUntil(this._destroyer$))
      .subscribe(() => (this.params = this._dropDownRef.params));
  }

  private closeOnNavigation(): void {
    this._router.events
      .pipe(
        takeUntil(this._destroyer$),
        filter((event: any) => event instanceof NavigationStart),
      )
      .subscribe(() => this._dropDownRef.close());
  }

  private setStyles = () => {
    this.bodyClass = this.getBodyClassDefault();
    this.bodyStyle = this.getBodyStyle();
    this.arrowClass = this.getArrowClass();
    this.outerArrowStyle = this.getArrowStyle();
    this.innerArrowStyle = this.getArrowStyle(true);
  };

  private getBodyClassDefault(): { [key: string]: string | boolean } {
    const { theme }: IDropDownParams = this.params;
    return {
      [`drop-down__theme--${theme}`]: theme || "default",
      "drop-down__body--left": this.params.bodyPosition === "left",
      "drop-down__body--right": this.params.bodyPosition === "right",
      "drop-down__body--center": this.params.bodyPosition === "center",
    };
  }

  private getBodyClassFullScreen(): { [key: string]: boolean } {
    return {
      "drop-down__body--fullScreen": this.params.fullScreen,
    };
  }

  private setFullScreen(): void {
    this.bodyClass = this.getBodyClassFullScreen();
  }

  private getBodyStyle(): { [key: string]: string | number } {
    let isGoOutOfWindowRight: boolean = false;

    const { _targetEl, params }: DropDownPortalComponent = this;
    const {
      right: rootElRight,
      left: rootElLeft,
      bottom: rootElBottom,
    }: ClientRect = _targetEl.nativeElement.getBoundingClientRect();

    if (this.contentEl) {
      const { right: contentElRight, left: contentElLeft }: ClientRect =
        this.contentEl.nativeElement.getBoundingClientRect();
      isGoOutOfWindowRight =
        contentElRight >=
          this._window.nativeElement.innerWidth - params.rightOffset &&
        rootElLeft >= contentElLeft;
    }

    const widthStyle: { width?: string } = params.width
      ? { width: `${params.width}px` }
      : {};
    const zIndexStyle: { zIndex?: number } = params.zIndex
      ? { zIndex: params.zIndex }
      : {};

    const commonStyles: { width?: string; zIndex?: number } = {
      ...widthStyle,
      ...zIndexStyle,
    };

    switch (params.bodyPosition) {
      case "left":
        return {
          ...commonStyles,
          left: isGoOutOfWindowRight
            ? "auto"
            : `${rootElLeft + params.leftOffset}px`,
          top: `${rootElBottom + params.topOffset}px`,
          right: isGoOutOfWindowRight ? `${params.rightSpacing}px` : "auto",
        };

      case "right":
        return {
          ...commonStyles,
          left: `${rootElRight + params.leftOffset}px`,
          top: `${rootElBottom + params.topOffset}px`,
        };

      case "center":
        return {
          ...commonStyles,
          left: `${rootElLeft + (rootElRight - rootElLeft) / 2 + params.leftOffset}px`,
          top: `${rootElBottom + params.topOffset}px`,
        };
    }
  }

  private getArrowClass(): { [key: string]: string | boolean } {
    const { theme, isBigArrow }: IDropDownParams = this.params;
    return {
      [`arrow-theme--${theme}`]: theme || "default",
      "arrow-container--big": isBigArrow,
    };
  }

  private getArrowStyle(isInner?: boolean): { [key: string]: string | number } {
    const { _targetEl, params }: DropDownPortalComponent = this;
    const {
      right: rootElRight,
      bottom: rootElBottom,
      width: rootElWidth,
    }: ClientRect = _targetEl.nativeElement.getBoundingClientRect();

    const zIndexStyle: { zIndex?: number } = params.zIndex
      ? { zIndex: params.zIndex }
      : {};

    let topPos: number;
    topPos = isInner ? rootElBottom + 1 : rootElBottom;
    topPos = this.params.isBigArrow
      ? topPos - CORRECT_DROP_DOWN_ARROW_LEFT_POS
      : topPos;

    return {
      ...zIndexStyle,
      left: `${rootElRight - rootElWidth / 2 - BIG_DROP_DOWN_ARROW_OFFSET}px`,
      top: `${topPos + this.params.topOffset}px`,
    };
  }
}
