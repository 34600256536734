import { Params } from "@angular/router";
import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import {
  IInstitutionsRecords,
  IProductResultsResponse,
} from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

enum ACTION_TYPES {
  GET_VIEW_RESULTS_INSTITUTION_ALL = "[VIEW RESULTS INSTITUTION ALL] Get all institution",
  GET_VIEW_RESULTS_INSTITUTION_ALL_ERROR = "[VIEW RESULTS INSTITUTION ALL] Get all institution error",
  GET_VIEW_RESULTS_INSTITUTION_ALL_SUCCESS = "[VIEW RESULTS INSTITUTION ALL] Get all institution success",

  SET_VIEW_RESULTS_INSTITUTION_ALL_SPINNER_STATE = "[VIEW RESULTS INSTITUTION ALL] Set spinner state",

  CHANGE_VIEW_RESULTS_INSTITUTION_ALL_PAGE = "[VIEW RESULTS INSTITUTION ALL] Change page",
  CHANGE_VIEW_RESULTS_INSTITUTION_ALL_QUERY = "[VIEW RESULTS INSTITUTION ALL] Change query",

  RESET_VIEW_RESULTS_INSTITUTION_ALL_STATE = "[VIEW RESULTS INSTITUTION ALL] Reset state",
}

export const getViewResultsAllInstitutionAllAction: ActionCreator<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTION_ALL,
  () => TypedAction<ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTION_ALL>
> = createAction(ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTION_ALL);
export const getViewResultsAllInstitutionAllErrorAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTION_ALL_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTION_ALL_ERROR,
  createPayload<IServerError>(),
);
export const getViewResultsAllInstitutionAllSuccessAction: CreateActionType<
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTION_ALL_SUCCESS,
  IProductResultsResponse<IInstitutionsRecords>
> = createAction(
  ACTION_TYPES.GET_VIEW_RESULTS_INSTITUTION_ALL_SUCCESS,
  createPayload<IProductResultsResponse<IInstitutionsRecords>>(),
);

export const setViewResultsInstitutionAllSpinnerStateAction: CreateActionType<
  ACTION_TYPES.SET_VIEW_RESULTS_INSTITUTION_ALL_SPINNER_STATE,
  boolean
> = createAction(
  ACTION_TYPES.SET_VIEW_RESULTS_INSTITUTION_ALL_SPINNER_STATE,
  createPayload<boolean>(),
);

export const changeViewResultsAllInstitutionPageAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTION_ALL_PAGE,
  number
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTION_ALL_PAGE,
  createPayload<number>(),
);
export const changeViewResultsAllInstitutionQueryAction: CreateActionType<
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTION_ALL_QUERY,
  Params
> = createAction(
  ACTION_TYPES.CHANGE_VIEW_RESULTS_INSTITUTION_ALL_QUERY,
  createPayload<Params>(),
);

export const resetViewResultsAllInstitutionStateAction: ActionCreator<
  ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTION_ALL_STATE,
  () => TypedAction<ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTION_ALL_STATE>
> = createAction(ACTION_TYPES.RESET_VIEW_RESULTS_INSTITUTION_ALL_STATE);
