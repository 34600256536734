import { ChangeDetectionStrategy, Component } from "@angular/core";

import { environment } from "@env/environment";

import { CORE_PATHS } from "@core/constants/core-paths";
import { E_COMMERCE_PATHS } from "../../../e-commerce/constants/e-commerce-paths";

@Component({
  selector: "bl-choose-purchase",
  templateUrl: "./choose-purchase.component.html",
  styleUrls: ["./choose-purchase.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChoosePurchaseComponent {
  readonly corePaths: typeof CORE_PATHS = CORE_PATHS;
  readonly eCommercePaths: typeof E_COMMERCE_PATHS = E_COMMERCE_PATHS;
  readonly phoneNumber: string = environment.contactPhoneNumber;
}
