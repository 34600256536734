interface ITooltipPositions {
  TOP: "top";
  BOTTOM: "bottom";
  LEFT: "left";
  RIGHT: "right";
}

export const TooltipPositions: ITooltipPositions = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
};
