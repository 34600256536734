import { Component, Inject } from "@angular/core";

import { ListTypesData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";
import { ConfirmPopUpResult } from "../../models/pop-up-result";

import { LIST_TYPES_NAME } from "@shared/constants/data/list-types";

import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-list-types-pop-up-content",
  templateUrl: "./list-types-pop-up-content.component.html",
  styleUrls: ["./list-types-pop-up-content.component.scss"],
})
export class ListTypesPopUpContentComponent {
  readonly listTypeNames: typeof LIST_TYPES_NAME = LIST_TYPES_NAME;

  constructor(
    @Inject(POP_UP_DATA) public data: ListTypesData,
    private popUpRef: PopUpRef<
      ListTypesPopUpContentComponent,
      ConfirmPopUpResult
    >,
  ) {}

  onClose(): void {
    this.popUpRef.close();
  }

  dynamicLabel(type: string, checked: boolean): string {
    if (checked === undefined) {
      return type + " is absent";
    }
    return type + " is " + checked;
  }
}
