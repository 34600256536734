import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/list-cloud-sync-history.actions";

import { sortArray } from "@shared/utils/sort-array";

import { IServerError } from "@shared/interfaces/server-error";
import { ISyncHistoryTableRows } from "../../../list-cloud-sync-history/interfaces";

import {
  HISTORY_DEFAULT_QUERY_PARAMS,
  HISTORY_SORT_BY,
} from "../../../list-cloud-sync-history/constants";

export interface IListCloudSyncHistoryState {
  hasActiveProcess: boolean;
  history: ISyncHistoryTableRows;
  historyListId: number;
  historyPending: boolean;
  historyError: IServerError | null;
}

const initialState: IListCloudSyncHistoryState = {
  hasActiveProcess: false,
  history: [],
  historyListId: null,
  historyPending: false,
  historyError: null,
};

const getListSyncHistoryHandler: OnReducer<
  IListCloudSyncHistoryState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncHistoryState, { payload }: Payload<any>) => ({
  ...state,
  historyListId: payload,
  historyPending: true,
});

const getListSyncHistorySuccessHandler: OnReducer<
  IListCloudSyncHistoryState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncHistoryState, { payload }: Payload<any>) => ({
  ...state,
  hasActiveProcess: payload.hasActiveProcess,
  history: [...payload.history],
  historyPending: false,
  historyError: null,
});

const getListSyncHistoryErrorHandler: OnReducer<
  IListCloudSyncHistoryState,
  ActionType<Payload<any>>
> = (state: IListCloudSyncHistoryState, { payload }: Payload<any>) => ({
  ...state,
  history: [],
  historyPending: false,
  historyError: { ...payload },
});

const onResetHandler: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IListCloudSyncHistoryState> =
  createReducer<IListCloudSyncHistoryState>(
    initialState,

    on(actions.getListSyncHistoryAction, getListSyncHistoryHandler),
    on(
      actions.getListSyncHistorySuccessAction,
      getListSyncHistorySuccessHandler,
    ),
    on(actions.getListSyncHistoryErrorAction, getListSyncHistoryErrorHandler),

    on(actions.resetListSyncHistoryAction, onResetHandler),
  );

// history
export const hasActiveProcess: GetFromState<
  boolean,
  IListCloudSyncHistoryState
> = (state: IListCloudSyncHistoryState): boolean =>
  state && state.hasActiveProcess;
export const history: GetFromState<
  ISyncHistoryTableRows,
  IListCloudSyncHistoryState
> = (state: IListCloudSyncHistoryState): ISyncHistoryTableRows =>
  state && state.history;
export const isHasHistory: GetFromState<
  boolean,
  ISyncHistoryTableRows,
  boolean
> = (_history: ISyncHistoryTableRows, isProcessing: boolean): boolean =>
  (_history && !!_history.length) || isProcessing;

export function listCloudSyncHistoryReducer(
  state: IListCloudSyncHistoryState,
  action: Action,
): IListCloudSyncHistoryState {
  return reducer(state, action);
}

export const historyRecordsCount: GetFromState<
  number,
  IListCloudSyncHistoryState
> = (state: IListCloudSyncHistoryState): number =>
  state && state.history && state.history.length;

export const requestParams: GetFromState<any, Params> = (
  queryParams: Params,
): any => {
  const { sortBy: _sortBy }: Params = queryParams;

  return {
    ...HISTORY_DEFAULT_QUERY_PARAMS,
    ...queryParams,
    sortBy: _sortBy || HISTORY_SORT_BY,
  };
};

export const currentPageByUrl: GetFromState<number, Params> = (
  queryParams: Params,
): number => {
  const { offset, limit }: Params = queryParams;

  return Math.ceil(offset / limit) + 1;
};

export const historyWithSort: GetFromState<
  any,
  ISyncHistoryTableRows,
  number,
  Params
> = (
  _history: ISyncHistoryTableRows,
  currentPage: number,
  _queryParams: Params,
): any => {
  const {
    limit,
    offset,
    sortBy: _sortBy,
    orderBy: _orderBy,
  }: Params = _queryParams;

  const startPage: number = +offset;
  const perPage: number = +limit;
  const endPage: number =
    startPage + perPage >= _history.length
      ? _history.length
      : startPage + perPage;

  const results: number[] = sortArray(_history, _sortBy, _orderBy);

  if (typeof startPage === "number" && typeof endPage === "number") {
    return results && results.slice(startPage, endPage);
  }
};
