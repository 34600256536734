import { IGeoSuggestionBackEnd } from "../interfaces/geography";
import { IJobSuggestion } from "../interfaces/jobs";

import { JobSend } from "./job-send";
import { Place } from "./place";

export class Suggestion {
  text: string;
  description: string;
  title: string;
  preTitle?: string;
  markAs?: string;

  constructor(suggestion: IGeoSuggestionBackEnd) {
    this.text = suggestion.isRange
      ? Place.getRangeLocationName(suggestion)
      : Place.getLocationNameFromSuggestion(suggestion);

    this.description = Place.TYPE_NAMES[suggestion.type];
    this.title = suggestion.title;
  }

  static fromJobSuggestion(suggestion: IJobSuggestion): Suggestion {
    const newSuggestion: Partial<Suggestion> = {};

    newSuggestion.text = suggestion.name;
    newSuggestion.description = JobSend.TYPES_NAMES[suggestion.type];
    newSuggestion.title = suggestion.institutionType;
    newSuggestion.preTitle = suggestion.historicAlphaCode;
    newSuggestion.markAs = suggestion.markAs;

    return newSuggestion as Suggestion;
  }
}
