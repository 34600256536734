import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { IfAdminAccountDirective } from "./directives/if-admin-account.directive";
import { IfAdminDirective } from "./directives/if-admin.directive";
import { IfLoggedInDirective } from "./directives/if-logged-in.directive";
import { IfTosPageDirective } from "./directives/if-tos-page.directive";
import { OnlyPositiveIntegerDirective } from "./directives/only-positive-integer.directive";
import { PaginationContentDirective } from "./directives/pagination-content-directive.directive";
import { TargetHeightDirective } from "./directives/target-height.directive";
import { TrimOnBlurDirective } from "./directives/trim-on-blur.directive";

@NgModule({
  declarations: [
    ClickOutsideDirective,
    IfAdminDirective,
    IfAdminAccountDirective,
    IfLoggedInDirective,
    IfTosPageDirective,
    PaginationContentDirective,
    TargetHeightDirective,
    OnlyPositiveIntegerDirective,
    TrimOnBlurDirective,
  ],
  imports: [CommonModule],
  exports: [
    ClickOutsideDirective,
    IfAdminDirective,
    IfAdminAccountDirective,
    IfLoggedInDirective,
    IfTosPageDirective,
    PaginationContentDirective,
    TargetHeightDirective,
    OnlyPositiveIntegerDirective,
    TrimOnBlurDirective,
  ],
})
export class DirectivesModule {}
