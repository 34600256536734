import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

@Injectable()
export class DocumentRef {
  constructor(@Inject(DOCUMENT) private _document: Document) {}

  get nativeElement(): Document {
    return this._document;
  }
}
