// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  position: relative;
}

bl-mobile-table-row {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/tables/mobile-table/components/mobile-table/mobile-table.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,kBAAA;AACF;;AAEA;EACE,mBAAA;AACF","sourcesContent":[":host {\n  display: block;\n  position: relative;\n}\n\nbl-mobile-table-row {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
