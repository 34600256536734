import {
  ICommonListStatusesByIds,
  IListsStatus,
  IListStatuses,
} from "../../interfaces/list";

import { LIST_STATUS_CLASSES, LIST_STATUS_ID_NAME } from "./list-types";

export enum LIST_STATUS_ID {
  SAVED_FOR_LATER,
  EXPIRED = 2,
  ACTIVE,
  PROCESSING,
  STATUS_PROCESSING_WITH_PERCENTS,
}
// LIST_STATUS_ID_NAME
export const AVAILABLE_FOR_OPEN_CDL_STATUSES: LIST_STATUS_ID[] = [
  LIST_STATUS_ID.SAVED_FOR_LATER,
  LIST_STATUS_ID.ACTIVE,
  LIST_STATUS_ID.EXPIRED,
];

export const LIST_STATUS_NAMES_BY_ID: {
  [key in LIST_STATUS_ID]: LIST_STATUS_ID_NAME;
} = Object.freeze({
  [LIST_STATUS_ID.SAVED_FOR_LATER]: LIST_STATUS_ID_NAME.SAVED_FOR_LATER,
  [LIST_STATUS_ID.EXPIRED]: LIST_STATUS_ID_NAME.EXPIRED,
  [LIST_STATUS_ID.ACTIVE]: LIST_STATUS_ID_NAME.ACTIVE,
  [LIST_STATUS_ID.PROCESSING]: LIST_STATUS_ID_NAME.PROCESSING,
  [LIST_STATUS_ID.STATUS_PROCESSING_WITH_PERCENTS]:
    LIST_STATUS_ID_NAME.STATUS_PROCESSING_WITH_PERCENTS,
});

export const LIST_STATUS_CLASSES_BY_ID: {
  [key in LIST_STATUS_ID]: LIST_STATUS_CLASSES;
} = Object.freeze({
  [LIST_STATUS_ID.SAVED_FOR_LATER]: LIST_STATUS_CLASSES.SAVED_FOR_LATER,
  [LIST_STATUS_ID.EXPIRED]: LIST_STATUS_CLASSES.EXPIRED,
  [LIST_STATUS_ID.ACTIVE]: LIST_STATUS_CLASSES.ACTIVE,
  [LIST_STATUS_ID.PROCESSING]: LIST_STATUS_CLASSES.PROCESSING,
  [LIST_STATUS_ID.STATUS_PROCESSING_WITH_PERCENTS]:
    LIST_STATUS_CLASSES.STATUS_PROCESSING_WITH_PERCENTS,
});

export enum LIST_MAX_NAME_STATUS {
  MAX_NAMES_DOESNT_ACTIVATED,
  SHOULD_BE_CALCULATED,
  CALCULATED,
}

export enum LIST_PENDING_STATUS {
  PENDING_REMOVAL = "Pending Removal",
  SHOW_PENDING_REMOVAL = "showPendingRemoval",
}

export const ListActions: { [key: string]: string } = {
  Duplicate: "duplicate",
  Rename: "rename",
  Delete: "delete",
  Repurchase: "repurchase",
  Download: "download",
  ActiveAppendData: "appendData",
};

export enum LIST_REDIRECT_TO {
  CAN_NOT_REDIRECT = "",
  SUMMARY = "summary",
  PURCHASE = "purchase",
  DASHBOARD = "dashboard",
}

const LIST_STATUSES: IListsStatus[] = [
  {
    id: LIST_STATUS_ID.SAVED_FOR_LATER,
    status: LIST_STATUS_ID_NAME.SAVED_FOR_LATER,
    canEdit: true,
    // TODO create method availableTo instead of Array, to configure available Actions
    //  see task CCP-1265 and product-menu isStatusNotSaveForLater()
    //  method and ngOnChanges availableTo. We have to configure it because of list Ownership
    availableTo: ["purchase", "duplicate", "rename", "delete", "append"],
    redirectTo: LIST_REDIRECT_TO.CAN_NOT_REDIRECT,
    shouldCheckStatus: false,
  },
  {
    id: LIST_STATUS_ID.EXPIRED,
    status: LIST_STATUS_ID_NAME.EXPIRED,
    canEdit: false,
    availableTo: ["summary", "repurchase", "duplicate", "rename", "delete"],
    redirectTo: LIST_REDIRECT_TO.SUMMARY,
    shouldCheckStatus: false,
  },
  {
    id: LIST_STATUS_ID.ACTIVE,
    status: LIST_STATUS_ID_NAME.ACTIVE,
    canEdit: false,
    // TODO: removed 'sync' from availableTo by ticket CCP-2035
    availableTo: [
      "summary",
      "download",
      "appendData",
      "refresh",
      "duplicate",
      "createCustomList",
      "rename",
      "delete",
    ],
    redirectTo: LIST_REDIRECT_TO.SUMMARY,
    shouldCheckStatus: true,
    shouldShowExpireDate: true,
  },
  {
    id: LIST_STATUS_ID.PROCESSING,
    status: LIST_STATUS_ID_NAME.PROCESSING,
    canEdit: false,
    availableTo: ["summary", "duplicate"],
    redirectTo: LIST_REDIRECT_TO.SUMMARY,
    shouldCheckStatus: true,
  },
  {
    id: LIST_STATUS_ID.STATUS_PROCESSING_WITH_PERCENTS,
    status: LIST_STATUS_ID_NAME.STATUS_PROCESSING_WITH_PERCENTS,
    canEdit: false,
    availableTo: [],
    redirectTo: LIST_REDIRECT_TO.CAN_NOT_REDIRECT,
    shouldCheckStatus: true,
  },
  {
    id: LIST_STATUS_ID.STATUS_PROCESSING_WITH_PERCENTS,
    status: LIST_STATUS_ID_NAME.STATUS_PROCESSING_WITH_PERCENTS,
    canEdit: false,
    availableTo: [],
    redirectTo: LIST_REDIRECT_TO.CAN_NOT_REDIRECT,
    shouldCheckStatus: true,
  },
];

export const LIST_STATUSES_MAP: IListStatuses = LIST_STATUSES.reduce(
  (res: ICommonListStatusesByIds<IListsStatus>, status: IListsStatus) => {
    res[status.id] = status;
    return res;
  },
  {},
);

export const LIST_STATUSES_MAP_FOR_ADMIN: IListStatuses = LIST_STATUSES.reduce(
  (res: ICommonListStatusesByIds<IListsStatus>, status: IListsStatus) => {
    const statusResult: IListsStatus = { ...status };
    if (status.id === LIST_STATUS_ID.SAVED_FOR_LATER) {
      const { availableTo }: IListsStatus = statusResult;
      statusResult.availableTo = [...availableTo, "share"];
    }
    res[status.id] = statusResult;
    return res;
  },
  {},
);
