import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatRippleModule } from "@angular/material/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { EffectsModule } from "@ngrx/effects";
import { RouterState, StoreRouterConnectingModule } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";

import { environment } from "@env/environment";

import { AuthModule } from "@modules/auth/auth.module";
import { CountriesModule } from "@modules/countries/countries.module";
import { CreditsModule } from "@modules/credits/credits.module";
import { NavModule } from "@modules/nav/nav.module";
import { NotificationsModule } from "@modules/notifications/notifications.module";
import { ProfileModule } from "@modules/profile/profile.module";
import { DirectivesModule } from "@shared/modules/directives/directives.module";
import { DatePipesModule } from "@shared/modules/pipes/date-pipes/date-pipes.module";
import { SharedPipesModule } from "@shared/modules/pipes/shared-pipes/shared-pipes.module";
import { CustomScrollsModule } from "@ui/custom-scrolls/custom-scrolls.module";
import { DropDownModule } from "@ui/drop-down/drop-down.module";
import { ErrorsModule } from "@ui/errors/errors.module";
import { NavigationsModule } from "@ui/navigations/navigations.module";
import { OnBoardModule } from "@ui/on-board/on-board.module";
import { PopUpModule } from "@ui/pop-up/pop-up.module";
import { SnackBarModule } from "@ui/snack-bar/snack-bar.module";
import { UploadModule } from "@ui/upload/upload.module";
import { ViewPointsModule } from "@ui/view-points/view-points.module";
import { ListLargeDownloadPageComponent } from "@modules/list/pages/list-large-download-page/list-large-download-page.component";
import { AccountUsersService } from "@modules/profile/services/account-users.service";
import { ViewService } from "@modules/view/services/view.service";
import { LoadingInterceptor } from "@ui/spinner/services/loading-interceptor";
import { BillingInfoExistGuard } from "@modules/e-commerce/guards/billing-info-exist.guard";
import { WebSocketsModule } from "@modules/websockets";
import { RECAPTCHA_SETTINGS } from "ng-recaptcha";
import { GainSightAnalyticsModule } from "@modules/gain-sight-analytics/gain-sight-analytics.module";
import { AppRoutingModule } from "../app-routing.module";

import { extModules } from "../build-specifics";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";

import { DownloadLinkContainerComponent } from "./components/download-link-container/download-link-container.component";
import { DownloadLinkComponent } from "./components/download-link/download-link.component";
import { HeaderContainerComponent } from "./components/header-container/header-container.component";
import { HeaderComponent } from "./components/header/header.component";

import { DocumentRef } from "./refs/document-ref.service";
import {
  windowFactory,
  WindowRef,
  WINDOW_TOKEN,
} from "./refs/window-ref.service";
import { AppLoadService } from "./services/app-load.service";
import { AsyncValidatorService } from "./services/async-validator.service";
import { CdlLastActivitiesService } from "./services/cdl-last-activities.service";
import { ListDownloadService } from "./services/list-download.service";
import { ListService } from "./services/list.service";
import { OrderService } from "./services/order.service";
import { PaymentOperationsService } from "./services/payment-operations.service";
import { PrivacyPolicyService } from "./services/pivacy-policy.service";
import { PortalService } from "./services/portal.service";
import { ProfileService } from "./services/profile.service";
import { TosService } from "./services/tos.service";

import { ProfileSettingsExistGuard } from "./guards/profile-settings-exist.guard";
import { ReasonCodeGuard } from "./guards/reason-code.guard";
import { UserDataExistGuard } from "./guards/user-data-exist.guard";

import { CAPTCHA_VALUE } from "./constants/captcha";

import { CORE_REDUCERS } from "./store/reducers";
import { coreEffects } from "./store/effects";
import { CustomRouterStateSerializer } from "./store/custom-router-state-serializer";
import { AppErrorInterceptor } from "./errors/app-error-interceptor";
import { AuthInterceptor } from "@modules/auth/services/auth-interceptor";
import { verifyInvitationGuard } from "./guards/verify-invitation.guard";
import { TrustArcConsentModule } from "@modules/trust-arc-consent/trust-arc-consent.module";

export function initializeApp(
  appLoadService: AppLoadService,
): () => Promise<any> {
  return () => appLoadService.initApp();
}

@NgModule({
  imports: [
    CommonModule,
    MatRippleModule,
    MatSidenavModule,
    AppRoutingModule,
    AuthModule.forRoot(),
    PopUpModule.forRoot(),
    DropDownModule.forRoot(),
    ProfileModule,
    CreditsModule,
    UploadModule.forRoot(),
    CustomScrollsModule,
    // replace state static boolean to environment.envName === 'prod' based on requirement
    GainSightAnalyticsModule.forRoot(true),
    TrustArcConsentModule.forRoot(true),
    StoreModule.forRoot(CORE_REDUCERS, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false,
      },
    }),
    StoreRouterConnectingModule.forRoot({
      routerState: RouterState.Minimal,
      serializer: CustomRouterStateSerializer,
    }),
    extModules,
    EffectsModule.forRoot(coreEffects),
    WebSocketsModule.config({
      ...environment.webSockets,
    }),
    ViewPointsModule,
    NavModule,
    OnBoardModule,
    NotificationsModule,
    SnackBarModule,
    CountriesModule,
    NavigationsModule,
    DirectivesModule,
    DatePipesModule,
    SharedPipesModule,
    ErrorsModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppLoadService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
    {
      provide: WINDOW_TOKEN,
      useFactory: windowFactory,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: CAPTCHA_VALUE,
    },
    WindowRef,
    DocumentRef,
    ListService,
    PrivacyPolicyService,
    AppErrorInterceptor,
    TosService,
    ListDownloadService,
    OrderService,
    PortalService,
    AsyncValidatorService,
    BillingInfoExistGuard,
    verifyInvitationGuard,
    ReasonCodeGuard,
    ProfileSettingsExistGuard,
    UserDataExistGuard,
    ProfileService,
    AccountUsersService,
    ViewService,
    PaymentOperationsService,
    AppLoadService,
    CdlLastActivitiesService,
  ],
  declarations: [
    HeaderComponent,
    NotFoundPageComponent,
    DownloadLinkContainerComponent,
    DownloadLinkComponent,
    ListLargeDownloadPageComponent,
    HeaderContainerComponent,
  ],
  exports: [
    AppRoutingModule,
    NotificationsModule,
    DownloadLinkContainerComponent,
    DownloadLinkComponent,
    HeaderContainerComponent,
  ],
})
export class CoreModule {}
