import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import {
  AbstractControl,
  UntypedFormGroup,
  ValidationErrors,
} from "@angular/forms";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { AuthService } from "../../../auth/services/auth.service";

import { PhoneMaskPipe } from "@shared/modules/pipes/shared-pipes/pipes/phone-mask.pipe";

import { isDifferentProfileData } from "@shared/utils/is-different-profile-data";

import { IControlOption } from "@shared/interfaces/forms";
import { AddressError, IServerError } from "@shared/interfaces/server-error";
import {
  INewUser,
  IUser,
  IUserAddress,
  IUserTransformed,
} from "../../../auth/interfaces/user";
import { ICountryCode } from "../../../countries/interfaces";

import { FLAT_INPUT_THEME } from "@shared/constants/flat-input";
import { STICKY_TYPES } from "@shared/constants/sticky-type";
import { FORM_VALIDATION_ERRORS } from "@shared/constants/validators/forms-validations-errors";
import { RolesKeys } from "../../constants/roles";

import { Validators } from "@shared/validators/validators";

@Component({
  selector: "bl-profile-form",
  templateUrl: "./profile-form.component.html",
  styleUrls: ["./profile-form.component.scss"],
  providers: [PhoneMaskPipe],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class ProfileFormComponent implements OnInit, OnChanges, OnDestroy {
  readonly stickyTypes: typeof STICKY_TYPES = STICKY_TYPES;
  readonly rolesKeys: typeof RolesKeys = RolesKeys;
  readonly flatInputTheme: typeof FLAT_INPUT_THEME = FLAT_INPUT_THEME;

  @Input() user: IUserTransformed;
  @Input() role: string;
  @Input() loading: boolean;
  @Input() serverError: IServerError;
  @Input() serverAddressError: AddressError;

  @Input() countries: IControlOption[];
  @Input() countryStates: IControlOption[];

  @Input() shouldShowPasswordForm: boolean;
  @Input() changePasswordLoading: boolean;
  @Input() changePasswordDisabled: boolean;

  @Output() countryChanged: EventEmitter<ICountryCode> =
    new EventEmitter<ICountryCode>();
  @Output() profileFormChangeAddress: EventEmitter<any> = new EventEmitter();
  @Output() profileFormSubmit: EventEmitter<IUser> = new EventEmitter();

  @Output() profileFormChangePassword: EventEmitter<any> = new EventEmitter();
  @Output() profileFormCloseChangePasswordForm: EventEmitter<any> =
    new EventEmitter();

  private _destroyer$: Subject<void> = new Subject();

  form: UntypedFormGroup;

  get address(): UntypedFormGroup {
    return this.form.get("address") as UntypedFormGroup;
  }

  get countryCode(): AbstractControl {
    return this.address.get("countryCode");
  }

  get postalCode(): AbstractControl {
    return this.address.get("postalCode");
  }

  get shouldShowSave(): boolean {
    if (!this.form || !this.user) {
      return true;
    }
    return isDifferentProfileData(this.user, this.form.value);
  }

  constructor(
    private service: AuthService,
    public phoneMask: PhoneMaskPipe,
  ) {}

  ngOnInit(): void {
    this.generateForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && changes.user) {
      this.form.patchValue(changes.user.currentValue);
    }

    const errors: SimpleChange = changes.serverError;
    const serverAddressError: SimpleChange = changes.serverAddressError;

    if (serverAddressError && serverAddressError.currentValue) {
      const { errorMessages, suspectAddress }: AddressError =
        serverAddressError.currentValue;

      if (errorMessages && suspectAddress && this.form) {
        this.generateForm(this.form.value, suspectAddress);
      }

      if (errorMessages && !suspectAddress && this.form) {
        this.address.setErrors({
          general: serverAddressError.currentValue.errorMessages.join(". "),
        } as ValidationErrors);
      }
    }

    if (errors && errors.currentValue) {
      Object.entries(this.serverError.errors).forEach(
        ([fieldName, messages]: [string, string[]]): void => {
          const control: AbstractControl = this.form.get(fieldName);

          if (control) {
            control.setErrors(messages as ValidationErrors);
          }
        },
      );
    }
  }

  ngOnDestroy(): void {
    this._destroyer$.next();
    this._destroyer$.complete();
  }

  generateForm(
    currentValue?: IUserAddress,
    suspectAddress?: IUserAddress,
  ): void {
    const { address, ...user }: IUserTransformed = this.user;

    if (!currentValue && !suspectAddress) {
      this.form = this.service.profileForm(
        false,
        false,
        this.user ? ({ ...address, ...user } as IUser) : null,
      );
      this.changePostalCodeAndPhoneValidations();
      return;
    }

    this.form = this.service.profileForm(
      false,
      false,
      this.user
        ? ({ ...address, ...user, ...currentValue, ...suspectAddress } as IUser)
        : null,
    );
    this.changePostalCodeAndPhoneValidations();
  }

  changePostalCodeAndPhoneValidations(): void {
    this.address.controls.postalCode.setValidators([
      Validators.required(FORM_VALIDATION_ERRORS.zipPostalCode.required),
    ]);
    this.address.controls.postalCode.updateValueAndValidity();

    this.form.controls["phone"].setValidators([
      Validators.required(FORM_VALIDATION_ERRORS.telephoneNumber.required),
      Validators.minLength(FORM_VALIDATION_ERRORS.telephoneNumber.invalid, 10),
      Validators.maxLength(FORM_VALIDATION_ERRORS.telephoneNumber.invalid, 10),
    ]);
    this.form.controls["phone"].updateValueAndValidity();
  }

  submit(): void {
    if (!this.form.valid) {
      return;
    }

    const { address, ...form }: any = this.form.value;

    this.profileFormSubmit.emit({ ...form, ...address } as INewUser);
  }

  resetForm(): void {
    this.form.reset(this.user);
  }

  onCountryChange(): void {
    this.countryChanged.emit(this.countryCode.value);
  }

  changePassword(): void {
    this.profileFormChangePassword.emit();
  }

  closeChangePasswordForm(): void {
    this.profileFormCloseChangePasswordForm.emit();
  }
}
