export const MISSING_LIST_TOKEN_FOR_DOWNLOAD: string =
  "Looks like that page has gone missing (404)...";
export const LIST_TOKEN_ERROR: string = "Oops! Internal server error";
export const COMMON_ERROR_PARTIAL: string[] = [
  "There was an error on our side, and your request couldn't be fulfilled.",
  "If the problem persists, please let us know at",
];
export const COMMON_ERROR: string =
  COMMON_ERROR_PARTIAL[0] + COMMON_ERROR_PARTIAL[1];
export const AVALARA_ERROR: string =
  "Sorry, the service is temporarily unavailable, please try again later or contact";
