import { TemplateRef } from "@angular/core";

export interface ITab {
  index: number;
  title: string;
  headerTemplate: TemplateRef<any>;
  contentTemplate: TemplateRef<any>;
  isActive: boolean;
  isDisabled: boolean;
  width: number;
  height: number;
  fontSize: number;
}

export enum TABS_LIST_POSITIONING {
  START = "flex-start",
  CENTER = "center",
  END = "flex-end",
}
