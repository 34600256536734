import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import * as creditsState from "../reducers/credits.reducer";

import {
  ICreditsResponse,
  IUpdateICreditsResponse,
} from "@modules/auth/interfaces/user";

import { selectCreditsState } from "./";

export const isCreditsPending: MemoizedSelector<CoreState, boolean> =
  createSelector(selectCreditsState, creditsState.creditsPending);
export const getCreditsFullInfo: MemoizedSelector<
  CoreState,
  ICreditsResponse | IUpdateICreditsResponse
> = createSelector(selectCreditsState, creditsState.creditsFullInfo);
export const getCredits: MemoizedSelector<CoreState, number> = createSelector(
  getCreditsFullInfo,
  creditsState.credits,
);
export const getCreditExpirationDate: MemoizedSelector<CoreState, string> =
  createSelector(getCreditsFullInfo, creditsState.expirationDate);

export const getIsSkipUpdateCredits: MemoizedSelector<CoreState, boolean> =
  createSelector(selectCreditsState, creditsState.isSkipUpdateCredits);
