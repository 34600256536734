import { Inject, Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { select, Action, Store } from "@ngrx/store";

import { defer, of, throwError, Observable } from "rxjs";
import {
  catchError,
  delay,
  filter,
  map,
  mapTo,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { go } from "@core/store/actions/router-history.action";
import { resetTosAction } from "@core/store/actions/tos.action";
import { CoreState } from "@core/store/reducers";
import { resetDashboardListsSearchWithoutReloadAction } from "@modules/dashboard-lists/store/actions/dashboard-lists-search.action";
import { resetDashboardViewsSearchWithoutReloadAction } from "@modules/dashboard-views/store/actions/dashboard-views-search.action";
import { ActionWithPayload, Payload } from "@shared/interfaces/store";

import { SnackBarComponent } from "@ui/snack-bar/components/snack-bar/snack-bar.component";

import { AsyncValidatorService } from "@core/services/async-validator.service";
import { ProfileService } from "@core/services/profile.service";
import { StorageService } from "@core/services/storage.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";
import { leftDays } from "@shared/utils/left-days";

import { AddressError, IServerError } from "@shared/interfaces/server-error";

import { CORE_PATHS } from "@core/constants/core-paths";
import { NOTIFICATION_TYPES } from "@core/constants/notifications";
import {
  marketViewNotSubscription,
  PERMISSIONS_MSG,
} from "@core/constants/permissions";
import { SNACK_BAR_CONFIG } from "@ui/snack-bar/constants";
import {
  ICheckAddressResponse,
  ICheckUserNameResponse,
  IForgotPasswordResponse,
  ILoginResponse,
  IRefreshTokenResponse,
  ISuccessMessageResponse,
} from "../../interfaces/user";
import { IInvitationUser } from "../../interfaces/invitation";
import {
  ICheckAddressSignUpData,
  IFirstLoginFormData,
  IForgotPasswordData,
  ISignInFormData,
  ISignOutData,
  ISignUpFormData,
  ISuccessLoginActionData,
} from "../../interfaces/formsActionsData";
import { AuthService } from "../../services/auth.service";
import { getInvitationExistedUser } from "../selectors/invitation.selector";
import { getEmailForSignUp, getIsLoggedIn } from "../selectors/auth.selector";
import * as invitationActions from "../actions/invitation.action";
import * as authActions from "../actions/auth.action";
import * as profileActions from "../../../profile/store/actions/profile.action";
import * as notificationActions from "../../../notifications/store/actions/notification.action";
import { resetPaymentAction } from "../../../e-commerce/store/actions/payment.action";
import { resetCountriesStateAction } from "../../../countries/store/actions/countries.action";
import { ACCOUNT_MESSAGES } from "../../../profile/constants/messages";
import { PROFILE_PATHS } from "../../../profile/constants/profile-route-paths";
import { AUTH_PATHS } from "../../constants/auth-paths";
import { INVITATION_ACTION } from "../../constants/invitation";
import { RESET_PASSWORD_REDIRECT_TIMER } from "../../constants/timers";

import { WebSocketsProvider } from "../../../websockets";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";
import { getRouterUrl } from "@core/store/selectors/router.selector";

@Injectable()
export class AuthEffects {
  signIn$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signInAction),
      withLatestFrom(this.store.pipe(select(getInvitationExistedUser))),
      switchMap(
        ([
          {
            payload: { credentials, remember },
          },
          invitation,
        ]: [ActionWithPayload<ISignInFormData>, IInvitationUser]) =>
          this.authService.login(credentials).pipe(
            tap((response: ILoginResponse) => {
              const withoutRedirect: boolean =
                invitation &&
                invitation.token &&
                invitation.email &&
                invitation.email === response.user.email;
              this.authService.onSignIn(
                response,
                remember,
                false,
                withoutRedirect,
              );
              ProfileService.setUserData(response as any);
            }),
            map((response: ILoginResponse) =>
              authActions.signInSuccessAction({
                response,
                remember,
                firstLogin: false,
              }),
            ),
            catchError((error: IServerError) =>
              of(authActions.signInErrorAction(error)),
            ),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  signIpSuccess$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this.actions$.pipe(
          ofType(authActions.signInSuccessAction),
          withLatestFrom(this.store.pipe(select(getInvitationExistedUser))),
          tap(
            ([
              {
                payload: { response },
              },
              invitation,
            ]: [
              ActionWithPayload<ISuccessLoginActionData>,
              IInvitationUser,
            ]) => {
              this.store.dispatch(authActions.checkShowTrialMsgAction());

              if (typeof response.isFirstAssignAvailable !== "undefined") {
                this.store.dispatch(
                  profileActions.updateIsFirstAssignAvailableAction(
                    response.isFirstAssignAvailable,
                  ),
                );
              }

              this.invitationAPICalls(invitation);
            },
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  invitationAPICallsAction$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this.actions$.pipe(
          ofType(authActions.invitationAPICallsAction),
          withLatestFrom(this.store.pipe(select(getInvitationExistedUser))),
          tap(([e]) => {
            this.invitationAPICalls(e[1]);
          }),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  tokenSignIn$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.tokenSignInAction),
      tap(
        ({
          payload: { response },
        }: ActionWithPayload<ISuccessLoginActionData>) =>
          this.authService.onSignIn(response),
      ),
      map(({ payload }: ActionWithPayload<ISuccessLoginActionData>) =>
        authActions.signInSuccessAction(payload),
      ),
      catchErrorWithErrorType,
    ),
  );

  signOut$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signOutAction),
      tap(({ payload: { withoutNavigate } }: ActionWithPayload<ISignOutData>) =>
        this.authService.onSignOut(withoutNavigate),
      ),
      switchMap(({ payload }: ActionWithPayload<ISignOutData>) => [
        resetPaymentAction(),
        authActions.signOutSuccessAction(payload),
        authActions.hideTrialMsgAction(),
        resetTosAction(),
        resetCountriesStateAction(),
        resetDashboardListsSearchWithoutReloadAction(),
        resetDashboardViewsSearchWithoutReloadAction(),
      ]),
      catchErrorWithErrorType,
    ),
  );

  signOutAndRedirectToAdminPanel$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signOutAndGoToAdminPanelAction),
      map(() => authActions.signOutAction({ withoutNavigate: true })),
      tap(() => AuthService.toAdminPanel()),
      catchErrorWithErrorType,
    ),
  );

  checkAddress$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.checkAddressAction),
      switchMap(
        ({
          payload: { addressValidateData, successAction },
        }: ActionWithPayload<ICheckAddressSignUpData>) =>
          this.validateService.address(addressValidateData).pipe(
            map(
              ({
                valid,
                errorMessages,
                suspectAddress,
              }: ICheckAddressResponse | null) => {
                if (valid) {
                  return authActions.checkAddressSuccessAction(successAction);
                } else {
                  if (errorMessages && suspectAddress) {
                    this.snackBar.openFromComponent(SnackBarComponent, {
                      data: {
                        message: ACCOUNT_MESSAGES.ADDRESS_WAS_CHANGED,
                        canCall: false,
                      },
                      ...SNACK_BAR_CONFIG,
                    });
                  }
                  return authActions.checkAddressErrorAction(
                    new AddressError(errorMessages, suspectAddress),
                  );
                }
              },
            ),
            catchError((error: IServerError) =>
              of(
                authActions.checkAddressErrorAction(
                  new AddressError(error.errors.postalCode),
                ),
              ),
            ),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  actionValidateAddressSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.checkAddressSuccessAction),
      filter(({ payload }: ActionWithPayload<Action | null>) => !!payload),
      map(({ payload }: ActionWithPayload<Action | null>) => payload),
    ),
  );

  checkUserName$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.checkUserNameAction),
      withLatestFrom(
        this.store.pipe(select(getEmailForSignUp)),
        this.store.pipe(select(getRouterUrl)),
      ),
      switchMap(
        ([{ payload }, email, route]: [Payload<string>, string, string]) => {
          if (route.includes("sign-up")) go("auth/sign-up");

          return this.authService.checkUserName({ email: payload }).pipe(
            map((response: ICheckUserNameResponse) => {
              return authActions.checkUserNameSuccessAction({
                ...response,
                email,
              });
            }),
            catchError((error: IServerError) => {
              this.store.dispatch(authActions.checkUserNameErrorAction(error));
              this.store.dispatch(
                notificationActions.showNotificationAction({
                  message: error.message,
                  type: NOTIFICATION_TYPES.ERROR,
                  timeout: 3000,
                  canClose: true,
                }),
              );
              return throwError(error);
            }),
          );
        },
      ),
    ),
  );

  checkUserNameSuccess$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this.actions$.pipe(
          ofType(authActions.checkUserNameSuccessAction),
          tap(({ payload }) => {
            payload.responseCode === 1
              ? this.store.dispatch(go("auth/sign-up"))
              : this.oktaLogin(payload["email"]);
          }),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  signUp$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signUpAction),
      switchMap(
        ({
          payload: { newUser, remember },
        }: ActionWithPayload<ISignUpFormData>) =>
          this.authService.signUp(newUser).pipe(
            tap((response: ILoginResponse) => {
              // this.authService.onSignIn(response, remember, true);
              // ProfileService.setUserData(response as any); // TODO: remove any when back-end will be ready
            }),
            switchMap((response: ILoginResponse) => [
              authActions.signUpSuccessAction(),
              // authActions.signInSuccessAction({
              //   response,
              //   remember,
              //   firstLogin: true,
              // }),
            ]),
            catchError((error: IServerError) =>
              of(authActions.signUpErrorAction(error)),
            ),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  signUpByFrame$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signUpByFrameAction),
      switchMap(
        ({
          payload: { newUser, remember },
        }: ActionWithPayload<ISignUpFormData>) =>
          this.authService.signUp(newUser).pipe(
            tap((response: ILoginResponse) => {
              this.authService.onSignIn(response, remember, true);
              ProfileService.setUserData(response as any); // TODO: remove any when back-end will be ready
            }),
            map((response: ILoginResponse) =>
              authActions.signUpByFrameSuccessAction({
                response,
                remember,
                firstLogin: true,
              }),
            ),
            catchError((error: IServerError) =>
              of(authActions.signUpErrorAction(error)),
            ),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  redirectAfterSignUpFormFrameSuccess$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.signUpByFrameSuccessAction),
      mapTo(go(["/", CORE_PATHS.AUTH, AUTH_PATHS.SIGN_UP_IN_FRAME_SUCCESS])),
      catchErrorWithErrorType,
    ),
  );

  firstLogin$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.firstLoginAction),
      switchMap(({ payload }: ActionWithPayload<IFirstLoginFormData>) =>
        this.authService.firstLogin(payload).pipe(
          tap((response: ILoginResponse) => {
            this.authService.onSignIn(response, false, true);
            ProfileService.setUserData(response as any); // TODO: remove any when back-end will be ready
          }),
          map((response: ILoginResponse) =>
            authActions.signInSuccessAction({
              response,
              remember: false,
              firstLogin: true,
            }),
          ),
          catchError((error: IServerError) =>
            of(authActions.signInErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  checkShowTrialMsg$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.checkShowTrialMsgAction),
      withLatestFrom(this.store.pipe(select(getIsLoggedIn))),
      map(([action, isLoggedIn]: [Action, boolean]) => {
        if (StorageService.trialSeatExpirationDate <= new Date().getTime()) {
          StorageService.trialSeatExpirationDate = null;
        }

        return isLoggedIn && StorageService.trialSeatExpirationDate
          ? authActions.showTrialMsgAction(
              StorageService.trialSeatExpirationDate,
            )
          : authActions.hideTrialMsgAction();
      }),
      catchErrorWithErrorType,
    ),
  );

  showTrialMsg$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.showTrialMsgAction),
      filter(({ payload }: ActionWithPayload<number>) => !!payload),
      map(({ payload: trialSeatExpirationDate }: ActionWithPayload<number>) =>
        notificationActions.showNotificationAction({
          id: "trial_msg",
          type: NOTIFICATION_TYPES.SUCCESS,
          messageToolTip: marketViewNotSubscription(),
          message: PERMISSIONS_MSG.TRIAL_DATE(
            leftDays(new Date().getTime(), trialSeatExpirationDate),
          ),
          canCall: true,
        }),
      ),
      catchErrorWithErrorType,
    ),
  );

  hideTrialMsg$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.hideTrialMsgAction),
      map(() => notificationActions.hideNotificationAction("trial_msg")),
      catchErrorWithErrorType,
    ),
  );

  resetPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.resetPasswordAction),
      switchMap(({ payload }: ActionWithPayload<IFirstLoginFormData>) =>
        this.authService.changeTemporaryPassword(payload).pipe(
          map((data: ISuccessMessageResponse) =>
            authActions.resetPasswordSuccessAction(data),
          ),
          catchError((error: IServerError) =>
            of(authActions.resetPasswordErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  resetPasswordSuccess$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this.actions$.pipe(
          ofType(authActions.resetPasswordSuccessAction),
          delay(RESET_PASSWORD_REDIRECT_TIMER),
          tap(() => this.authService.onResetTemporaryPassword()),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  forgotPassword$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.forgotPasswordAction),
      switchMap(({ payload }: ActionWithPayload<IForgotPasswordData>) =>
        this.authService.resetPassword(payload).pipe(
          map((data: IForgotPasswordResponse) =>
            authActions.forgotPasswordSuccessAction(data),
          ),
          catchError((error: IServerError) =>
            of(authActions.forgotPasswordErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  disconnectSocket$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this.actions$.pipe(
          ofType(authActions.signOutSuccessAction),
          tap(() => this.ws.disconnect()),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  getRefreshToken$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this.actions$.pipe(
          ofType(authActions.getRefreshTokenAction),
          tap(() =>
            this.authService
              .getRefreshToken()
              .subscribe((data: IRefreshTokenResponse) => {
                StorageService.token = data.token;
                StorageService.refreshToken = `Bearer ${data.refreshToken}`;
                StorageService.expiryDuration = parseInt(
                  data.expiryDuration,
                  10,
                );
                StorageService.refreshTokenCreatedDate = new Date();
              }),
          ),
          catchError((error: Error | unknown) => {
            return throwError(error);
          }),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private validateService: AsyncValidatorService,
    private authService: AuthService,
    private store: Store<CoreState>,
    private ws: WebSocketsProvider,
    private snackBar: MatSnackBar,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {}

  oktaLogin(email): any {
    this.oktaAuth.signInWithRedirect({ loginHint: email });
    return go(CORE_PATHS.DASHBOARD);
  }

  invitationAPICalls(invitation: IInvitationUser) {
    if (
      invitation &&
      invitation.token &&
      invitation.action &&
      StorageService.user &&
      invitation.email === StorageService.user.email
    ) {
      if (
        INVITATION_ACTION.OWNER_KEEP_SEPARATE.toString() ===
        invitation.action.toString()
      ) {
        this.store.dispatch(
          go(["/", CORE_PATHS.ACCOUNT, PROFILE_PATHS.PRODUCTS]),
        );
      } else if (
        INVITATION_ACTION.OWNER_MERGE.toString() ===
        invitation.action.toString()
      ) {
        this.store.dispatch(
          invitationActions.invitationWithoutNotificationAction(),
        );
      } else {
        this.store.dispatch(invitationActions.invitationAction(invitation));
      }
    } else {
      this.store.dispatch(invitationActions.resetInvitationStateAction());
    }
  }
}
