import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { ActionWithPayload, Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/cloud-sync-auth.action";

import { StorageService } from "../../services/storage.service";

import { IRole } from "@modules/auth/interfaces/user";
import { IServerError } from "@shared/interfaces/server-error";
import {
  ICloudSyncAuthFields,
  ICloudSyncPlatform,
  ICloudSyncPlatforms,
  IShowCloudSyncPopUpPayload,
} from "../../interfaces/cloud-sync";

import { RolesKeys } from "@modules/profile/constants/roles";
import { ASSIGN_PLATFORM_TYPE } from "../../constants/cloud-sync";

export interface ICloudSyncAuthState {
  platforms: ICloudSyncPlatforms;
  platformsLoading: boolean;
  platformsError: IServerError | null;

  listRecords: number;
  listId: number;
  sandbox: boolean;

  selectedPlatformId: number;
  currentPlatformId: number;

  platformAssignLoading: boolean;
  platformAssignError: IServerError | null;
  platformAssignType: ASSIGN_PLATFORM_TYPE;

  platformFields: ICloudSyncAuthFields;
  platformFieldsLoading: boolean;
  platformFieldsError: IServerError | null;

  platformAuthLoading: boolean;
  platformAuthError: IServerError | null;
  connectionId: number;

  syncLimitedErrorMsg: string;
}

const initialState: ICloudSyncAuthState = {
  platforms: [],
  platformsLoading: false,
  platformsError: null,

  listRecords: null,
  listId: null,
  sandbox: true,

  selectedPlatformId: null,
  currentPlatformId: null,

  platformAssignLoading: false,
  platformAssignError: null,
  platformAssignType: ASSIGN_PLATFORM_TYPE.PRODUCT,

  platformFields: [],
  platformFieldsLoading: false,
  platformFieldsError: null,

  platformAuthLoading: false,
  platformAuthError: null,
  connectionId: null,

  syncLimitedErrorMsg: "",
};

// get platforms
const getPlatformsHandler: OnReducer<ICloudSyncAuthState, ActionType<any>> = (
  state: ICloudSyncAuthState,
) => ({
  ...state,
  platformsLoading: true,
  platformsError: null,
});

const getPlatformsErrorHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformsLoading: false,
  platformsError: { ...payload },
});

const getPlatformsSuccessHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformsLoading: false,
  platformsError: null,
  platforms: [...payload],
});

// set current platform
const setCurrentPlatformHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => {
  const found: ICloudSyncPlatform = state.platforms.find(
    (item: any) => item.id === payload,
  );
  const _connectionId: number =
    (found && found.connectionId) || state.connectionId;
  return {
    ...state,
    selectedPlatformId: payload,
    currentPlatformId: payload,
    connectionId: _connectionId,
  };
};

const clearSelectedPlatformHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<any>
> = (state: ICloudSyncAuthState) => ({
  ...state,
  selectedPlatformId: null,
  currentPlatformId: null,
  connectionId: null,
});

// set list id
const setListIdHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  listId: payload,
});

const clearListIdHandler: OnReducer<ICloudSyncAuthState, ActionType<any>> = (
  state: ICloudSyncAuthState,
) => ({
  ...state,
  listId: null,
});

// show auth-pages or sync to pop up
const showAuthOrSyncToPopUpHandler: OnReducer<
  any,
  ActionType<ActionWithPayload<IShowCloudSyncPopUpPayload>>
> = (
  state: any,
  { payload }: ActionWithPayload<IShowCloudSyncPopUpPayload>,
) => ({
  ...state,
  listRecords: payload.records ? payload.records : state.records,
  listId: payload.listId,
  sandbox: payload.sandbox,
  connectionId: payload.platform.connectionId,
});

const showCloudSyncToPopUpHandler: OnReducer<any, ActionType<Payload<any>>> = (
  state: any,
  { payload }: Payload<any>,
) => ({
  ...state,
  listRecords: payload.records ? payload.records : state.records,
  listId: payload.listId,
});

// platform assign to account
const platformAssignToAccountHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformAssignLoading: true,
  platformAssignError: null,
  platformAssignType: payload.type,
  selectedPlatformId:
    payload.type === ASSIGN_PLATFORM_TYPE.CREDITS ? payload.platformId : null,
  currentPlatformId:
    payload.type === ASSIGN_PLATFORM_TYPE.CREDITS ? payload.platformId : null,
});

const platformAssignToAccountErrorHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformAssignLoading: false,
  platformAssignError: { ...payload },
});

const platformAssignToAccountSuccessHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformAssignLoading: false,
  platformAssignError: null,
  connectionId: payload.connectionId,
});

// platform fields
const getPlatformFieldsHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformFieldsLoading: true,
  platformFieldsError: null,
  selectedPlatformId: payload,
});
const getPlatformFieldsErrorHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformFieldsLoading: false,
  platformFieldsError: { ...payload },
});

const getPlatformFieldsSuccessHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformFieldsLoading: false,
  platformFieldsError: null,
  platformFields: [...payload],
});

// platform auth-pages
const platformAuthHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformAuthLoading: true,
  platformAuthError: null,
  sandbox: payload.sandbox,
});

const platformAuthErrorHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformAuthLoading: false,
  platformAuthError: { ...payload },
});

const platformAuthSuccessHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  platformAuthLoading: false,
  platformAuthError: null,
  currentPlatform: payload,
});

const resetPlatformAuthErrorHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<any>
> = (state: ICloudSyncAuthState) => ({
  ...state,
  platformAuthError: null,
});

// sync limited error
const syncLimitedErrorHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<Payload<any>>
> = (state: ICloudSyncAuthState, { payload }: Payload<any>) => ({
  ...state,
  syncLimitedErrorMsg: payload,
});

const clearSyncLimitedErrorHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<any>
> = (state: ICloudSyncAuthState) => ({
  ...state,
  syncLimitedErrorMsg: "",
});

// reset data for auth-pages pop up
const resetCloudSyncAuthPopUpHandler: OnReducer<
  ICloudSyncAuthState,
  ActionType<any>
> = (state: ICloudSyncAuthState) => ({
  ...state,
  listRecords: null,
  listId: null,
  selectedPlatformId: null,
  currentPlatformId: null,
  connectionId: null,
});

// reset state
const resetStateHandler: OnReducer<ICloudSyncAuthState, ActionType<any>> = (
  state: ICloudSyncAuthState,
): ICloudSyncAuthState => ({
  ...initialState,
});

const reducer: ActionReducer<ICloudSyncAuthState> =
  createReducer<ICloudSyncAuthState>(
    initialState,

    on(actions.getPlatformsAction, getPlatformsHandler),
    on(actions.getPlatformsErrorAction, getPlatformsErrorHandler),
    on(actions.getPlatformsSuccessAction, getPlatformsSuccessHandler),

    on(actions.setCurrentPlatformAction, setCurrentPlatformHandler),
    on(actions.clearSelectedPlatformAction, clearSelectedPlatformHandler),

    on(actions.setListIdAction, setListIdHandler),
    on(actions.clearListIdAction, clearListIdHandler),

    on(actions.showAuthOrSyncToPopUpAction, showAuthOrSyncToPopUpHandler),
    on(actions.showCloudSyncToPopUp, showCloudSyncToPopUpHandler),

    on(actions.platformAssignToAccountAction, platformAssignToAccountHandler),
    on(
      actions.platformAssignToAccountErrorAction,
      platformAssignToAccountErrorHandler,
    ),
    on(
      actions.platformAssignToAccountSuccessAction,
      platformAssignToAccountSuccessHandler,
    ),

    on(actions.platformAssignByCreditsAction, platformAssignToAccountHandler),
    on(
      actions.platformAssignByCreditsByCreditsErrorAction,
      platformAssignToAccountErrorHandler,
    ),
    on(
      actions.platformAssignByCreditsSuccessAction,
      platformAssignToAccountSuccessHandler,
    ),

    on(actions.getPlatformFieldsAction, getPlatformFieldsHandler),
    on(actions.getPlatformFieldsErrorAction, getPlatformFieldsErrorHandler),
    on(actions.getPlatformFieldsSuccessAction, getPlatformFieldsSuccessHandler),
    on(actions.resetPlatformAuthError, resetPlatformAuthErrorHandler),

    on(actions.platformAuthAction, platformAuthHandler),
    on(actions.platformAuthErrorAction, platformAuthErrorHandler),
    on(actions.platformAuthSuccessAction, platformAuthSuccessHandler),

    on(actions.syncLimitedErrorAction, syncLimitedErrorHandler),
    on(actions.clearSyncLimitedErrorAction, clearSyncLimitedErrorHandler),

    on(actions.resetCloudSyncAuthPopUpAction, resetCloudSyncAuthPopUpHandler),

    on(actions.resetStateAction, resetStateHandler),
  );

export function cloudSyncAuthReducer(
  state: ICloudSyncAuthState,
  action: Action,
): ICloudSyncAuthState {
  return reducer(state, action);
}

export const platforms: GetFromState<
  ICloudSyncPlatforms,
  ICloudSyncAuthState
> = (state: ICloudSyncAuthState): ICloudSyncPlatforms => state.platforms;
export const platformsAvailable: GetFromState<
  ICloudSyncPlatforms,
  ICloudSyncPlatforms
> = (_platforms: ICloudSyncPlatforms): ICloudSyncPlatforms =>
  _platforms &&
  _platforms.filter((item: ICloudSyncPlatform) => item.connectionId === null);
export const platformsAuthorized: GetFromState<
  ICloudSyncPlatforms,
  ICloudSyncPlatforms
> = (_platforms: ICloudSyncPlatforms): ICloudSyncPlatforms =>
  _platforms &&
  _platforms.filter((item: ICloudSyncPlatform) => item.connectionId);

export const availablePlatforms: GetFromState<
  ICloudSyncPlatforms,
  ICloudSyncPlatforms,
  ICloudSyncPlatforms
> = (
  _platformEntitle: ICloudSyncPlatforms,
  _platformUnEntitled: ICloudSyncPlatforms,
): ICloudSyncPlatforms => {
  return StorageService.cloudSyncForProducts
    ? _platformEntitle
    : _platformUnEntitled;
};

export const platformAssignType: GetFromState<
  ASSIGN_PLATFORM_TYPE,
  ICloudSyncAuthState
> = (state: ICloudSyncAuthState): ASSIGN_PLATFORM_TYPE =>
  state && state.platformAssignType;

export const platformFields: GetFromState<
  ICloudSyncAuthFields,
  ICloudSyncAuthState
> = (state: ICloudSyncAuthState): ICloudSyncAuthFields => state.platformFields;
export const selectedPlatformId: GetFromState<number, ICloudSyncAuthState> = (
  state: ICloudSyncAuthState,
): number => state.selectedPlatformId;
export const selectedPlatform: GetFromState<
  ICloudSyncPlatform,
  ICloudSyncPlatforms,
  number
> = (
  _platforms: ICloudSyncPlatforms,
  _selectedPlatformId: number,
): ICloudSyncPlatform => {
  return (
    _platforms &&
    _platforms.find(
      (item: ICloudSyncPlatform) => item.id === _selectedPlatformId,
    )
  );
};

export const selectedConnectionId: GetFromState<number, ICloudSyncAuthState> = (
  state: ICloudSyncAuthState,
): number => state && state.connectionId;
export const connectionId: GetFromState<number, ICloudSyncPlatform, number> = (
  _selectedPlatform: ICloudSyncPlatform,
  _connectionId: number,
): number =>
  (_selectedPlatform && _selectedPlatform.connectionId) || _connectionId;

export const currentPlatformId: GetFromState<number, ICloudSyncAuthState> = (
  state: ICloudSyncAuthState,
): number => state && state.currentPlatformId;
export const currentPlatform: GetFromState<
  ICloudSyncPlatform,
  ICloudSyncPlatforms,
  number
> = (
  _platforms: ICloudSyncPlatforms,
  _currentPlatformId: number,
): ICloudSyncPlatform => {
  return (
    _platforms &&
    _platforms.find(
      (item: ICloudSyncPlatform) => item.id === _currentPlatformId,
    )
  );
};

export const sandbox: GetFromState<boolean, ICloudSyncAuthState> = (
  state: ICloudSyncAuthState,
): boolean => state.sandbox;

export const platformAuthLoading: GetFromState<boolean, ICloudSyncAuthState> = (
  state: ICloudSyncAuthState,
): boolean => state.platformAuthLoading;
export const platformAuthError: GetFromState<
  IServerError | null,
  ICloudSyncAuthState
> = (state: ICloudSyncAuthState): IServerError | null =>
  state.platformAuthError;

export const currentListRecords: GetFromState<number, ICloudSyncAuthState> = (
  state: ICloudSyncAuthState,
): number => state.listRecords;
export const currentListId: GetFromState<number, ICloudSyncAuthState> = (
  state: ICloudSyncAuthState,
): number => state.listId;

export const isCanBuyForCredits: GetFromState<
  boolean,
  boolean,
  number,
  number
> = (unlimited: boolean, userCredits: number, listRecords: number): boolean =>
  unlimited || userCredits >= listRecords;

export const isShowAssignCloudSyncProduct: GetFromState<
  boolean,
  IRole,
  boolean
> = (role: IRole, hasAvailableProduct: boolean): boolean =>
  role && role.key === RolesKeys.Owner && hasAvailableProduct;

export const syncLimitedErrorMsg: GetFromState<string, ICloudSyncAuthState> = (
  state: ICloudSyncAuthState,
): string => state && state.syncLimitedErrorMsg;
