import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators as AngularValidators,
} from "@angular/forms";

import { EditInfoPopUpData } from "../../models/pop-up-data";
import { PopUpRef } from "../../models/pop-up-ref";
import { EditInfoPopUpResult } from "../../models/pop-up-result";

import { VALIDATION_REG_EXP } from "@shared/constants/validators/validation-reg-exp";

import { Validators } from "@shared/validators/validators";
import { POP_UP_DATA } from "../../injection-tokens";

@Component({
  selector: "bl-edit-info-pop-up",
  templateUrl: "./edit-info-pop-up.component.html",
  styleUrls: ["./edit-info-pop-up.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditInfoPopUpComponent {
  form: UntypedFormGroup;

  constructor(
    private _popUpRef: PopUpRef<EditInfoPopUpComponent, EditInfoPopUpResult>,
    @Inject(POP_UP_DATA) public data: EditInfoPopUpData,
    private _fb: UntypedFormBuilder,
  ) {
    this.createForm(data);
  }

  createForm(data: EditInfoPopUpData): void {
    this.form = this._fb.group({
      comment: [
        data.comment || "",
        [
          Validators.required(),
          AngularValidators.pattern(VALIDATION_REG_EXP.noOnlySpaces),
        ],
      ],
    });
  }

  onConfirm(): void {
    if (!this.form.valid) {
      return;
    }

    this._popUpRef.close({
      answer: true,
      comment: this.form.get("comment").value,
    });
  }

  onDecline(): void {
    this._popUpRef.close({ answer: false });
  }
}
