// eslint-disable  max-len

// Unavailable text
export const GEO_UNAVAILABLE: string =
  "There are no leads in this area with your current target criteria. Consider broadening your targeting or adding a new segment.";
export const JOBS_UNAVAILABLE: string =
  "There are no leads with this job function with your current target criteria. Consider broadening your targeting or adding a new segment.";
export const DEMO_UNAVAILABLE: string =
  "There are no leads in this demographic with your current target criteria. Consider broadening your targeting or adding a new segment.";
export const INSTITUTIONS_UNAVAILABLE: string =
  "There are no leads in this institution with your current target criteria. Consider broadening your targeting or adding a new segment.";
export const BIDS_CATEGORIES: string =
  "There are no leads with this categories with your current target criteria. Consider broadening your targeting or adding a new segment.";

export enum SEGMENT_CUSTOM_EMITTER_EVENTS {
  CLOSE_DATE_PICKERS = "[SEGMENT CUSTOM EMITTER] Emit close date pickers",
  CLOSE_BROWSE_TARGETING = "[SEGMENT CUSTOM EMITTER] Close browse targeting",
}
