import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { IDashboardListsModuleState } from "../reducers";
import {
  by,
  direction,
  isOpen,
  IDashboardListsSortingState,
} from "../reducers/dashboard-lists-sorting.reducer";

import { getDashboardListsModuleState } from "./";

export const dashboardListsSortState: GetFromStateInSelectors<
  CoreState,
  IDashboardListsSortingState
> = createSelector(
  getDashboardListsModuleState,
  (state: IDashboardListsModuleState): IDashboardListsSortingState =>
    state.dashboardListsSorting,
);

export const getDashboardListsSortDirection: MemoizedSelector<
  CoreState,
  string
> = createSelector(dashboardListsSortState, direction);
export const getDashboardListsSortBy: MemoizedSelector<CoreState, string> =
  createSelector(dashboardListsSortState, by);
export const isDashboardListsSortByOpen: MemoizedSelector<CoreState, boolean> =
  createSelector(dashboardListsSortState, isOpen);
