export const RESULTS_PAGINATION: { [key: string]: number } = {
  limit: 20,
  offset: 0,
  rangeDisplayPages: 3,
};

export type IPerPages = number[];
export const PER_PAGES: IPerPages = [25, 50, 100, 200];

export const DEFAULT_INFINITY_SCROLL_TABLE_COUNT: number = 20;
export const DEFAULT_BIDS_SCROLL_TABLE_COUNT: number = 10;
