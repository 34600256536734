import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  grantsEntityReducer,
  IGrantsEntityState,
} from "@shared/modules/grants-entity/store/reducers/grants-entity.reducer";

export interface IGrantsEntityModuleState {
  grantsEntity: IGrantsEntityState;
}

const downloadEntityModuleReducer: ActionReducerMap<IGrantsEntityModuleState> =
  {
    grantsEntity: grantsEntityReducer,
  };

// eslint-disable-next-line
export const GRANTS_ENTITY_STATE_NAME = 'grantsEntityModule';
export const GRANTS_ENTITY_STATE: InjectionToken<
  ActionReducerMap<IGrantsEntityModuleState>
> = new InjectionToken<ActionReducerMap<IGrantsEntityModuleState>>(
  GRANTS_ENTITY_STATE_NAME,
  {
    factory: () => downloadEntityModuleReducer,
  },
);
