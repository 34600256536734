import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import * as actions from "@modules/cdl/cdl-list-customize/store/actions/cdl-list-customize.action";
import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";

import {
  availableAddOns,
  changeCheckedAvailableAddOns,
  filterColumnsByAddOns,
  isCheckedAddOns,
  resetCheckedAvailableAddOns,
  uniqueAddOns,
} from "@modules/cdl/cdl-list-customize/utils";

import {
  IAddonFieldItems,
  IOutputColumnsResponse,
  IOutputSettings,
} from "@modules/cdl/cdl-list-customize/interfaces";
import { IServerError } from "@shared/interfaces/server-error";

import {
  COLUMN_TYPE,
  FILE_TYPE,
  FLAT_FILE_BY_ACTIVE_TAB,
  VIEW_AS,
} from "@modules/cdl/cdl-list-customize/constants";
import { SEGMENT_TYPES } from "@modules/cdl/cdl-list/constants";
import {
  IListRecordType,
  LIST_RECORDS_TYPES,
} from "@shared/constants/data/list-types";

export interface ICDLListCustomizeState {
  pending: boolean;
  error: IServerError | null;

  name: string | null;
  recordsType: IListRecordType | null;
  listId: number | null;

  activeTab: SEGMENT_TYPES;

  fileTypeId: FILE_TYPE;
  outputSettings: IOutputSettings | null;
  columns: IOutputColumnsResponse | null;

  includedAvailableAddons: IAddonFieldItems;
  excludedAvailableAddons: IAddonFieldItems;
}

const initialState: ICDLListCustomizeState = {
  pending: false,
  error: null,

  name: null,
  recordsType: null,
  listId: null,

  activeTab: SEGMENT_TYPES.INSTITUTIONS,

  fileTypeId: FILE_TYPE.INSTITUTIONS,
  outputSettings: {
    viewAs: VIEW_AS.TRADITIONAL,
    detailedOutput: false,
    cloudSync: false,
    flatFile: false,
  },
  columns: {
    included: [],
    excluded: [],
  },

  includedAvailableAddons: [],
  excludedAvailableAddons: [],
};

const getCDLListData: OnReducer<ICDLListCustomizeState, ActionType<any>> = (
  state: ICDLListCustomizeState,
) => ({
  ...state,
  pending: true,
  error: null,
});

export const getCDLListDataError: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListCustomizeState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getCDLListDataSuccess: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<any>>
> = (
  state: ICDLListCustomizeState,
  { payload: { name, recordsType, id } }: Payload<any>,
) => ({
  ...state,
  pending: false,
  error: null,
  name,
  recordsType,
  listId: id,
});

const setActiveTab: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<any>>
> = (state: ICDLListCustomizeState, { payload }: Payload<any>) => ({
  ...state,
  activeTab: payload,
  fileTypeId: FLAT_FILE_BY_ACTIVE_TAB[payload] || FILE_TYPE.INSTITUTIONS,
  includedAvailableAddons: [],
  excludedAvailableAddons: [],
});

const getOutputSettings: OnReducer<ICDLListCustomizeState, ActionType<any>> = (
  state: ICDLListCustomizeState,
) => ({
  ...state,
  pending: true,
  error: null,
});

export const getOutputSettingsError: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListCustomizeState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getOutputSettingsSuccess: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<any>>
> = (state: ICDLListCustomizeState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: null,
  outputSettings: { ...payload },
  fileTypeId:
    (payload.flatFile
      ? FILE_TYPE.FLAT
      : FLAT_FILE_BY_ACTIVE_TAB[state.activeTab]) || FILE_TYPE.INSTITUTIONS,
});

const editOutputSettings: OnReducer<ICDLListCustomizeState, ActionType<any>> = (
  state: ICDLListCustomizeState,
) => ({
  ...state,
  pending: true,
  error: null,
});

export const editOutputSettingsError: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListCustomizeState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const editOutputSettingsSuccess: OnReducer<
  ICDLListCustomizeState,
  ActionType<any>
> = (state: ICDLListCustomizeState) => ({
  ...state,
  pending: false,
  error: null,
});

const getColumns: OnReducer<ICDLListCustomizeState, ActionType<any>> = (
  state: ICDLListCustomizeState,
) => ({
  ...state,
  pending: true,
  error: null,
});

export const getColumnsError: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListCustomizeState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getColumnsSuccess: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<any>>
> = (state: ICDLListCustomizeState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  error: null,
  columns: { ...payload },
  includedAvailableAddons:
    availableAddOns(payload.included, state.includedAvailableAddons) || [],
  excludedAvailableAddons:
    availableAddOns(payload.excluded, state.excludedAvailableAddons) || [],
});

const editColumn: OnReducer<ICDLListCustomizeState, ActionType<any>> = (
  state: ICDLListCustomizeState,
) => ({
  ...state,
  pending: true,
  error: null,
});

export const editColumnError: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListCustomizeState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const editColumnSuccess: OnReducer<ICDLListCustomizeState, ActionType<any>> = (
  state: ICDLListCustomizeState,
) => ({
  ...state,
  pending: false,
  error: null,
});

const columnMoveTo: OnReducer<ICDLListCustomizeState, ActionType<any>> = (
  state: ICDLListCustomizeState,
) => ({
  ...state,
  pending: true,
  error: null,
});

export const columnMoveToError: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListCustomizeState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const columnMoveToSuccess: OnReducer<
  ICDLListCustomizeState,
  ActionType<any>
> = (state: ICDLListCustomizeState) => ({
  ...state,
  pending: false,
  error: null,
});

const toggleColumns: OnReducer<ICDLListCustomizeState, ActionType<any>> = (
  state: ICDLListCustomizeState,
) => ({
  ...state,
  pending: true,
  error: null,
});

export const toggleColumnsError: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<IServerError>>
> = (state: ICDLListCustomizeState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const toggleColumnsSuccess: OnReducer<
  ICDLListCustomizeState,
  ActionType<any>
> = (state: ICDLListCustomizeState) => ({
  ...state,
  pending: false,
  error: null,
});

const changeFilterByAddons: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<any>>
> = (state: ICDLListCustomizeState, { payload }: Payload<any>) => ({
  ...state,
  includedAvailableAddons:
    payload.columnType === COLUMN_TYPE.included
      ? changeCheckedAvailableAddOns(
          state.includedAvailableAddons,
          payload.item,
        )
      : state.includedAvailableAddons,

  excludedAvailableAddons:
    payload.columnType === COLUMN_TYPE.excluded
      ? changeCheckedAvailableAddOns(
          state.excludedAvailableAddons,
          payload.item,
        )
      : state.excludedAvailableAddons,
});

const resetFilterAddOns: OnReducer<
  ICDLListCustomizeState,
  ActionType<Payload<any>>
> = (state: ICDLListCustomizeState, { payload }: Payload<any>) => ({
  ...state,
  includedAvailableAddons:
    payload === COLUMN_TYPE.included
      ? resetCheckedAvailableAddOns(state.includedAvailableAddons)
      : state.includedAvailableAddons,
  excludedAvailableAddons:
    payload === COLUMN_TYPE.excluded
      ? resetCheckedAvailableAddOns(state.excludedAvailableAddons)
      : state.excludedAvailableAddons,
});

const onReset: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICDLListCustomizeState> =
  createReducer<ICDLListCustomizeState>(
    initialState,

    on(actions.getCDLListCustomizeAction, getCDLListData),
    on(actions.getCDLListCustomizeErrorAction, getCDLListDataError),
    on(actions.getCDLListCustomizeSuccessAction, getCDLListDataSuccess),

    on(actions.setCustomizeActiveTabAction, setActiveTab),

    on(actions.getOutputSettingsAction, getOutputSettings),
    on(actions.getOutputSettingsErrorAction, getOutputSettingsError),
    on(actions.getOutputSettingsSuccessAction, getOutputSettingsSuccess),

    on(actions.editOutputSettingsAction, editOutputSettings),
    on(actions.editOutputSettingsErrorAction, editOutputSettingsError),
    on(actions.editOutputSettingsSuccessAction, editOutputSettingsSuccess),

    on(actions.getColumnsAction, getColumns),
    on(actions.getColumnsErrorAction, getColumnsError),
    on(actions.getColumnsSuccessAction, getColumnsSuccess),

    on(actions.editColumnAction, editColumn),
    on(actions.editColumnErrorAction, editColumnError),
    on(actions.editColumnSuccessAction, editColumnSuccess),

    on(actions.columnMoveToAction, columnMoveTo),
    on(actions.columnMoveToErrorAction, columnMoveToError),
    on(actions.columnMoveToSuccessAction, columnMoveToSuccess),

    on(actions.toggleColumnsAction, toggleColumns),
    on(actions.toggleColumnsErrorAction, toggleColumnsError),
    on(actions.toggleColumnsSuccessAction, toggleColumnsSuccess),

    on(actions.changeFilterByAddOnsAction, changeFilterByAddons),
    on(actions.resetFilterAddOnsAction, resetFilterAddOns),

    on(actions.resetCDLListCustomizeAction, onReset),
  );

export function cdlListCustomizeReducer(
  state: ICDLListCustomizeState,
  action: Action,
): ICDLListCustomizeState {
  return reducer(state, action);
}

export const pending: GetFromState<boolean, ICDLListCustomizeState> = (
  state: ICDLListCustomizeState,
): boolean => state && state.pending;
export const cdlListName: GetFromState<string, ICDLListCustomizeState> = (
  state: ICDLListCustomizeState,
): string => (state && state.name) || null;
export const cdlListRecordsType: GetFromState<
  IListRecordType,
  ICDLListCustomizeState
> = (state: ICDLListCustomizeState): IListRecordType =>
  state && state.recordsType;
export const cdlListId: GetFromState<number, ICDLListCustomizeState> = (
  state: ICDLListCustomizeState,
): number => state && state.listId;
export const cdlCustomizeActiveTab: GetFromState<
  SEGMENT_TYPES,
  ICDLListCustomizeState
> = (state: ICDLListCustomizeState): SEGMENT_TYPES => state && state.activeTab;
export const cdlFIleTypeId: GetFromState<FILE_TYPE, ICDLListCustomizeState> = (
  state: ICDLListCustomizeState,
): FILE_TYPE => state && state.fileTypeId;

export const cdlOutputSettings: GetFromState<
  IOutputSettings,
  ICDLListCustomizeState
> = (state: ICDLListCustomizeState): IOutputSettings =>
  state && state.outputSettings;
export const isFlatFile: GetFromState<boolean, IOutputSettings> = (
  settings: IOutputSettings,
): boolean => settings && settings.flatFile;
export const isDisabledFlatFile: GetFromState<boolean, IListRecordType> = (
  recordsType: IListRecordType,
): boolean =>
  recordsType && recordsType.id === LIST_RECORDS_TYPES.BUILDINGS_ONLY;

export const cdlOutputColumns: GetFromState<
  IOutputColumnsResponse,
  ICDLListCustomizeState
> = (state: ICDLListCustomizeState): IOutputColumnsResponse =>
  state && state.columns;

export const includedAvailableAddons: GetFromState<
  IAddonFieldItems,
  ICDLListCustomizeState
> = (state: ICDLListCustomizeState): IAddonFieldItems =>
  state && state.includedAvailableAddons;
export const excludedAvailableAddons: GetFromState<
  IAddonFieldItems,
  ICDLListCustomizeState
> = (state: ICDLListCustomizeState): IAddonFieldItems =>
  state && state.excludedAvailableAddons;

export const uniqueIncludedAvailableAddons: GetFromState<
  IAddonFieldItems,
  IAddonFieldItems
> = (items: IAddonFieldItems): IAddonFieldItems => items && uniqueAddOns(items);

export const uniqueExcludedAvailableAddons: GetFromState<
  IAddonFieldItems,
  IAddonFieldItems
> = (items: IAddonFieldItems): IAddonFieldItems => items && uniqueAddOns(items);

export const isCheckedIncludedAddOns: GetFromState<
  boolean,
  IAddonFieldItems
> = (includedAddOns: IAddonFieldItems): boolean =>
  isCheckedAddOns(includedAddOns);
export const isCheckedExcludedAddOns: GetFromState<
  boolean,
  IAddonFieldItems
> = (excludedAddOns: IAddonFieldItems): boolean =>
  isCheckedAddOns(excludedAddOns);

export const outputColumnsFiltered: GetFromState<
  IOutputColumnsResponse,
  IOutputColumnsResponse,
  IAddonFieldItems,
  IAddonFieldItems,
  boolean,
  boolean
> = (
  _columns: IOutputColumnsResponse,
  _includedAvailableAddons: IAddonFieldItems,
  _excludedAvailableAddons: IAddonFieldItems,
  _isCheckedIncludedAddOns: boolean,
  _isCheckedExcludedAddOns: boolean,
): IOutputColumnsResponse => ({
  included: _isCheckedIncludedAddOns
    ? filterColumnsByAddOns(_columns.included, _includedAvailableAddons)
    : _columns.included,
  excluded: _isCheckedExcludedAddOns
    ? filterColumnsByAddOns(_columns.excluded, _excludedAvailableAddons)
    : _columns.excluded,
});
