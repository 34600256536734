import { ChangeDetectionStrategy, Component } from "@angular/core";
import { select, Store } from "@ngrx/store";

import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { closeMenuMobileAction } from "@core/store/actions/common-pages.action";
import { go } from "@core/store/actions/router-history.action";
import { CoreState } from "@core/store/reducers";
import { CORE_PATHS } from "@core/constants/core-paths";
import { signOutAction } from "../../../auth/store/actions/auth.action";
import { getIsLoggedIn } from "../../../auth/store/selectors/auth.selector";
import {
  getIsAdminsAccount,
  getIsBroker,
  getProfileSubscription,
  getUserIsHasAddress,
} from "../../../profile/store/selectors/profile.selector";

import { E_COMMERCE_PATHS } from "../../../e-commerce/constants/e-commerce-paths";

@Component({
  selector: "bl-nav-mobile-container",
  templateUrl: "./nav-mobile-container.component.html",
  styleUrls: ["./nav-mobile-container.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavMobileContainerComponent {
  userIsHasAddress$: Observable<boolean> = this._store.pipe(
    select(getUserIsHasAddress),
  );
  isBroker$: Observable<boolean> = this._store.pipe(
    select(getIsBroker),
    shareReplay(),
  );
  isAdminsAccount$: Observable<boolean> = this._store.pipe(
    select(getIsAdminsAccount),
  );
  isLoggedIn$: Observable<boolean> = this._store.pipe(select(getIsLoggedIn));
  profileSubscription$: Observable<boolean> = this._store.pipe(
    select(getProfileSubscription),
  );

  constructor(private _store: Store<CoreState>) {}

  closeMenuMobile(): void {
    this._store.dispatch(closeMenuMobileAction());
  }

  goToPricingPage(): void {
    this.closeMenuMobile();
    this._store.dispatch(go(["/", CORE_PATHS.ECOMM, E_COMMERCE_PATHS.PRICING]));
  }

  logout(): void {
    this.closeMenuMobile();
    this._store.dispatch(signOutAction());
  }
}
