import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import {
  forgotPasswordError,
  forgotPasswordLoading,
  forgotPasswordSuccessStatus,
  isLoggedIn,
  resetPasswordError,
  resetPasswordLoading,
  resetPasswordSuccessMessage,
  signInError,
  signInLoading,
  signUpAddressError,
  signUpError,
  signUpLoading,
  AuthState,
  emailForSignUp,
  checkUserNameLoading,
  signUpComplete,
  checkUserNameMessage,
} from "../reducers/auth.reducer";

import { AddressError, IServerError } from "@shared/interfaces/server-error";

export const authState: GetFromStateInSelectors<CoreState, AuthState> = (
  state: CoreState,
): AuthState => state.auth;
export const getIsLoggedIn: MemoizedSelector<CoreState, boolean> =
  createSelector(authState, isLoggedIn);
export const getSignInLoading: MemoizedSelector<CoreState, boolean> =
  createSelector(authState, signInLoading);

export const getSignInError: MemoizedSelector<CoreState, IServerError> =
  createSelector(authState, signInError);

export const getSignUpLoading: MemoizedSelector<CoreState, boolean> =
  createSelector(authState, signUpLoading);
export const getSignUpError: MemoizedSelector<CoreState, IServerError> =
  createSelector(authState, signUpError);

export const getSignUpAddressError: MemoizedSelector<CoreState, AddressError> =
  createSelector(authState, signUpAddressError);

export const getResetPasswordLoading: MemoizedSelector<CoreState, boolean> =
  createSelector(authState, resetPasswordLoading);
export const getResetPasswordError: MemoizedSelector<CoreState, IServerError> =
  createSelector(authState, resetPasswordError);
export const getResetPasswordSuccessMessage: MemoizedSelector<
  CoreState,
  string
> = createSelector(authState, resetPasswordSuccessMessage);

export const getForgotPasswordLoading: MemoizedSelector<CoreState, boolean> =
  createSelector(authState, forgotPasswordLoading);
export const getForgotPasswordError: MemoizedSelector<CoreState, IServerError> =
  createSelector(authState, forgotPasswordError);
export const getForgotPasswordSuccessStatus: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(authState, forgotPasswordSuccessStatus);

export const getEmailForSignUp: MemoizedSelector<CoreState, string> =
  createSelector(authState, emailForSignUp);

export const checkUserNameLoad: MemoizedSelector<CoreState, boolean> =
  createSelector(authState, checkUserNameLoading);

export const getCheckUserNameMessage: MemoizedSelector<CoreState, string> =
  createSelector(authState, checkUserNameMessage);

export const getSignUpCompleteStatus: MemoizedSelector<CoreState, boolean> =
  createSelector(authState, signUpComplete);
