import { Injectable } from "@angular/core";
import { SafeHtml } from "@angular/platform-browser";
import { Params } from "@angular/router";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { select, Action, Store } from "@ngrx/store";

import { defer, of, Observable } from "rxjs";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  switchMapTo,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import * as creditsActions from "@modules/credits/store/actions/credits.action";
import * as profileActions from "@modules/profile/store/actions/profile.action";
import { getIsUnlimited } from "@modules/profile/store/selectors/profile.selector";

import { WelcomeWithMarketViewPopUpContentComponent } from "@ui/pop-up/components/welcome-with-market-view-pop-up-content/welcome-with-market-view-pop-up-content.component";

import { PopUpService } from "@ui/pop-up/services/pop-up/pop-up.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IUserData } from "@modules/auth/interfaces/user";
import { IServerError } from "@shared/interfaces/server-error";
import { IWelcomeWithMarketViewPopUp } from "@ui/pop-up/interfaces";

import { DASHBOARD_PATHS } from "@modules/dashboard/constants/dashboard-paths";
import { PROFILE_PATHS } from "@modules/profile/constants/profile-route-paths";
import { WELCOME_WITH_MARKETVIEW_POP_UP } from "@ui/pop-up/constants/pop-up-data";
import { GainSightAnalyticsTagService } from "@modules/gain-sight-analytics/services/gain-sight-analytics-tag.service";
import { CORE_PATHS } from "../../constants/core-paths";
import {
  IHtmlPageData,
  ITermsOfUsePageData,
} from "../../interfaces/terms-of-servise";
import { TosService } from "../../services/tos.service";
import { StorageService } from "../../services/storage.service";
import { ProfileService } from "../../services/profile.service";
import { getPreviousUrl } from "../selectors/tos.selector";
import { CoreState } from "../reducers";
import * as tosActions from "../actions/tos.action";
import { go } from "../actions/router-history.action";

@Injectable({ providedIn: "root" })
export class TosEffects {
  redirectToTos$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(tosActions.redirectToTosAction),
          tap(() =>
            this._store.dispatch(go(["/", CORE_PATHS.COMMON, CORE_PATHS.TOS])),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  getGeneralTerms$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(tosActions.getGeneralTermsAction),
      switchMap(() =>
        this._tosService.loadGeneralTerms().pipe(
          map((response: IHtmlPageData<SafeHtml>) =>
            tosActions.getGeneralTermsSuccessAction(response),
          ),
          catchError((error: IServerError) =>
            of(tosActions.getGeneralTermsErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  getTermsOfUse$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(tosActions.getTermsOfUseAction),
      switchMapTo(
        this._tosService.loadTermsOfUseData().pipe(
          map((response: ITermsOfUsePageData<SafeHtml>) =>
            tosActions.getTermsOfUseSuccessAction(response),
          ),
          catchError((error: IServerError) =>
            of(tosActions.getTermsOfUseErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  acceptAcceptTOS$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(tosActions.acceptTOSAction),
      switchMapTo(
        this._tosService.acceptTOS().pipe(
          withLatestFrom(this._store.pipe(select(getIsUnlimited))),
          mergeMap(([response, isUnlimited]: [any, boolean]) =>
            isUnlimited
              ? [tosActions.acceptTOSSuccessAction()]
              : [
                  tosActions.acceptTOSSuccessAction(),
                  creditsActions.getCreditsAction(),
                  creditsActions.setSkipUpdateCreditsAction(true),
                ],
          ),
          catchError((error: IServerError) =>
            of(tosActions.acceptTOSErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  acceptBrokerTos$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(tosActions.acceptBrokerTOSAction),
      switchMap(() =>
        this._tosService.acceptBrokerTos().pipe(
          map(() => tosActions.acceptBrokerTOSSuccessAction()),
          catchError((error: IServerError) =>
            of(tosActions.acceptBrokerTOSErrorAction(error)),
          ),
        ),
      ),
      catchErrorWithErrorType,
    ),
  );

  acceptTosSuccess$: Observable<Action> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(
            tosActions.acceptTOSSuccessAction,
            tosActions.acceptBrokerTOSSuccessAction,
          ),
          withLatestFrom(this._store.pipe(select(getPreviousUrl))),
          switchMap(([action, { url, queryParams }]: [Action, Params]) =>
            this._profileService.loadUser().pipe(
              tap((data: IUserData) => {
                this._gainSightAnalyticsService.callGainSight(data.user, data);
                ProfileService.setUserData(data);

                if (
                  data.isFirstAssignAvailable &&
                  !StorageService.doNotShowWelcomeWithMarketViewPopUp
                ) {
                  this._popUpService
                    .open<
                      WelcomeWithMarketViewPopUpContentComponent,
                      IWelcomeWithMarketViewPopUp
                    >(WelcomeWithMarketViewPopUpContentComponent, {
                      data: {
                        ...WELCOME_WITH_MARKETVIEW_POP_UP,
                      },
                    })
                    .afterClose.pipe(take(1))
                    .subscribe(({ answer }: { answer: boolean }) => {
                      StorageService.doNotShowWelcomeWithMarketViewPopUp = true;

                      if (answer) {
                        this._store.dispatch(
                          profileActions.updateIsFirstAssignAvailableAction(
                            true,
                          ),
                        );
                        this._store.dispatch(
                          go(["/", CORE_PATHS.ACCOUNT, PROFILE_PATHS.PRODUCTS]),
                        );
                      } else {
                        this._store.dispatch(
                          go([
                            "/",
                            CORE_PATHS.DASHBOARD,
                            DASHBOARD_PATHS.VIEWS,
                          ]),
                        );
                      }
                    });
                } else {
                  this._store.dispatch(go(url, queryParams));
                }
              }),
              map((data: IUserData) =>
                profileActions.getProfileSuccessAction(data),
              ),
              catchError((error: IServerError) =>
                of(profileActions.getProfileErrorAction(error)),
              ),
            ),
          ),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private _actions$: Actions,
    private _tosService: TosService,
    private _store: Store<CoreState>,
    private _popUpService: PopUpService,
    private _profileService: ProfileService,
    private _gainSightAnalyticsService: GainSightAnalyticsTagService,
  ) {}
}
