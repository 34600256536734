import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";

import { environment } from "@env/environment";

import { timer, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
  IBelowNotificationLink,
  INotificationBelowButton,
} from "@core/interfaces/notifications";

import { NOTIFICATION_TYPES } from "@core/constants/notifications";

@Component({
  selector: "bl-mobile-notification",
  templateUrl: "./mobile-notification.component.html",
  styleUrls: ["./mobile-notification.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNotificationComponent implements OnInit, OnDestroy {
  readonly phoneNumber: string = environment.contactPhoneNumber;

  @Input() id: number | string;
  @Input() message: string;
  @Input() messageToolTip: string;
  @Input() belowLink: IBelowNotificationLink;
  @Input() belowButton: INotificationBelowButton;
  @Input() type: NOTIFICATION_TYPES = NOTIFICATION_TYPES.INFO;
  @Input() canClose: boolean = false;
  @Input() canCall: boolean = false;
  @Input() timeout: number;

  @Output() mobileNotificationClose: EventEmitter<number | string> =
    new EventEmitter();

  private destroyer$: Subject<void> = new Subject();

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(
      this.elementRef.nativeElement,
      `notification--${this.type}`,
    );

    if (this.timeout) {
      timer(this.timeout)
        .pipe(takeUntil(this.destroyer$))
        .subscribe((): void => this.mobileNotificationClose.emit(this.id));
    }
  }

  ngOnDestroy(): void {
    this.destroyer$.next();
    this.destroyer$.complete();
  }
}
