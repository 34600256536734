export function sortByField<T>(data: T[], field: string, key?: string): T[] {
  const results: T[] = [...data];

  return results.sort((a: T, b: T): number => {
    if (key ? a[field][key] > b[field][key] : a[field] > b[field]) {
      return 1;
    }

    if (key ? a[field][key] < b[field][key] : a[field] < b[field]) {
      return -1;
    }

    return 0;
  });
}

export function sortByFields<T>(
  data: T[],
  firstField: string,
  secondField: string,
): T[] {
  const result: T[] = [...data];

  return result.sort((a: T, b: T): number => {
    const _a: number | string = a[firstField][0];
    const _b: number | string = b[firstField][0];

    if (!_a) {
      return -1;
    }

    if (!_b) {
      return 1;
    }

    if (_a[secondField] > _b[secondField]) {
      return 1;
    }

    if (_a[secondField] < _b[secondField]) {
      return -1;
    }

    return 0;
  });
}
