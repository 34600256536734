import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { geoCodesReducer, IGeographyCodesState } from "./geo-codes.reducer";

export interface IGeographyCodesModuleState {
  geographyCodes: IGeographyCodesState;
}

const GEOGRAPHY_CODES_MODULE_STATE_REDUCER: ActionReducerMap<IGeographyCodesModuleState> =
  {
    geographyCodes: geoCodesReducer,
  };

// eslint-disable-next-line
export const GEOGRAPHY_CODES_MODULE_STATE_NAME = 'GEOGRAPHY_CODES_STATE';
export const GEOGRAPHY_CODES_MODULE_STATE: InjectionToken<
  ActionReducerMap<IGeographyCodesModuleState>
> = new InjectionToken<ActionReducerMap<IGeographyCodesModuleState>>(
  GEOGRAPHY_CODES_MODULE_STATE_NAME,
  { factory: () => GEOGRAPHY_CODES_MODULE_STATE_REDUCER },
);
