import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "abs",
})
export class AbsPipe implements PipeTransform {
  transform(value: number): number {
    if (value) {
      return Math.abs(value);
    }

    return value;
  }
}
