import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import {
  cdlListOrderSummaryReducer,
  ICDLOrderSummaryState,
} from "./cdl-list-order-summary.reducer";

export interface ICDLOrderSummaryModuleState {
  cdlOrderCountsSummary: ICDLOrderSummaryState;
}

const cdlOrderSummaryModuleReducer: ActionReducerMap<ICDLOrderSummaryModuleState> =
  {
    cdlOrderCountsSummary: cdlListOrderSummaryReducer,
  };

// eslint-disable-next-line
export const CDL_ORDER_SUMMARY_MODULE_STATE_NAME = 'cdlOrderSummary';
export const CDL_ORDER_SUMMARY_MODULE_STATE: InjectionToken<
  ActionReducerMap<ICDLOrderSummaryModuleState>
> = new InjectionToken<ActionReducerMap<ICDLOrderSummaryModuleState>>(
  CDL_ORDER_SUMMARY_MODULE_STATE_NAME,
  { factory: () => cdlOrderSummaryModuleReducer },
);
