import { InjectionToken } from "@angular/core";
import { ActionReducerMap } from "@ngrx/store";

import { spinnerReducer, ISpinnerState } from "./spinner.reducer";

export interface ISpinnerModuleState {
  spinner: ISpinnerState;
}

const spinnerModuleState: ActionReducerMap<ISpinnerModuleState> = {
  spinner: spinnerReducer,
};

// eslint-disable-next-line
export const SPINNER_MODULE_FEATURE_NAME = 'SPINNER_MODULE_FEATURE_NAME';
export const SPINNER_MODULE_FEATURE_STATE: InjectionToken<
  ActionReducerMap<ISpinnerModuleState>
> = new InjectionToken<ActionReducerMap<ISpinnerModuleState>>(
  SPINNER_MODULE_FEATURE_NAME,
  {
    factory: () => spinnerModuleState,
  },
);
