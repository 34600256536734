// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: inline-block;
  height: 20px;
  width: 20px;
  align-items: center;
}
:host:not(.is-white) {
  background-size: 100%;
}
:host.is-white {
  background-size: 100%;
}

kendo-loader {
  color: #16A99B;
}`, "",{"version":3,"sources":["webpack://./src/app/ui/spinner/components/spinner/spinner.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,YAAA;EACA,WAAA;EACA,mBAAA;AACF;AACE;EAEE,qBAAA;AAAJ;AAGE;EAEE,qBAAA;AAFJ;;AAMA;EACE,cAAA;AAHF","sourcesContent":[":host {\n  display: inline-block;\n  height: 20px;\n  width: 20px;\n  align-items: center;\n\n  &:not(.is-white) {\n    // background: url('../../../../../assets/img/blue-spinner.svg') no-repeat 50% 50%;\n    background-size: 100%;\n  }\n\n  &.is-white {\n    // background: url('../../../../../assets/img/white-spinner.svg') no-repeat 50% 50%;\n    background-size: 100%;\n  }\n}\n\nkendo-loader{\n  color: #16A99B;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
