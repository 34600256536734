import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import { IServerError } from "@shared/interfaces/server-error";
import { SORT_DIRECTION } from "@shared/constants/sort";
import * as actions from "../actions/college-campuses.action";

import { ICollegeCampuses } from "../../interfaces/college-campuses";

import { COLLEGE_CAMPUSES_SORTING } from "../../constants/college-campuses";

export interface ICollegeCampusesState {
  campuses: ICollegeCampuses;
  pending: boolean;
  loaded: boolean;
  campusesIsOpen: boolean;
  error: IServerError | null;

  count: number;

  limit: number;
  offset: number;
  sortBy: COLLEGE_CAMPUSES_SORTING;
  orderBy: SORT_DIRECTION;
}

const initialState: ICollegeCampusesState = {
  campuses: [],
  pending: false,
  loaded: false,
  campusesIsOpen: false,
  error: null,

  count: 0,

  limit: 20,
  offset: 0,
  sortBy: COLLEGE_CAMPUSES_SORTING.NAME,
  orderBy: SORT_DIRECTION.ASC,
};

const getCollegeCampuses: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<any>>
> = (state: ICollegeCampusesState, { payload }: Payload<any>) => ({
  ...state,
  pending: true,
  error: null,
  offset: typeof payload === "number" ? (payload - 1) * state.limit : 0,
});

const getCollegeCampusesError: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<IServerError>>
> = (state: ICollegeCampusesState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getCollegeCampusesSuccess: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<any>>
> = (state: ICollegeCampusesState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  loaded: true,
  campuses: [...payload.items],
  count: payload.count,
  campusesIsOpen: !![...state.campuses, ...payload.items].length,
});

const sortCollegeCampuses: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<any>>
> = (state: ICollegeCampusesState, { payload }: Payload<any>) => ({
  ...state,
  ...payload,
  pending: true,
  error: null,
});

const sortCollegeCampusesError: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<IServerError>>
> = (state: ICollegeCampusesState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const sortCollegeCampusesSuccess: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<any>>
> = (state: ICollegeCampusesState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  campuses: [...payload.items],
  count: payload.count,
});

const toggleCollegeCampusesSuccess: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<any>>
> = (state: ICollegeCampusesState) => ({
  ...state,
  campusesIsOpen: !state.campusesIsOpen,
});

const resetToggleCollegeCampuses: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<any>>
> = (state: ICollegeCampusesState) => ({
  ...state,
  campusesIsOpen: false,
});

const resetCollegeCampuses: OnReducer<
  ICollegeCampusesState,
  ActionType<Payload<any>>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICollegeCampusesState> =
  createReducer<ICollegeCampusesState>(
    initialState,

    on(actions.getCollegeCampusesAction, getCollegeCampuses),
    on(actions.getCollegeCampusesErrorAction, getCollegeCampusesError),
    on(actions.getCollegeCampusesSuccessAction, getCollegeCampusesSuccess),

    on(actions.sortCollegeCampusesAction, sortCollegeCampuses),
    on(actions.sortCollegeCampusesErrorAction, sortCollegeCampusesError),
    on(actions.sortCollegeCampusesSuccessAction, sortCollegeCampusesSuccess),

    on(
      actions.toggleCollegeCampusesSuccessAction,
      toggleCollegeCampusesSuccess,
    ),
    on(actions.resetToggleCollegeCampusesAction, resetToggleCollegeCampuses),

    on(actions.resetCollegeCampusesAction, resetCollegeCampuses),
  );

export function collegeCampusesReducer(
  state: ICollegeCampusesState,
  action: Action,
): ICollegeCampusesState {
  return reducer(state, action);
}

export const campuses: GetFromState<ICollegeCampuses, ICollegeCampusesState> = (
  state: ICollegeCampusesState,
): ICollegeCampuses => state.campuses;
export const pending: GetFromState<boolean, ICollegeCampusesState> = (
  state: ICollegeCampusesState,
): boolean => state.pending;
export const loaded: GetFromState<boolean, ICollegeCampusesState> = (
  state: ICollegeCampusesState,
): boolean => state.loaded;
export const campusesIsOpen: GetFromState<boolean, ICollegeCampusesState> = (
  state: ICollegeCampusesState,
): boolean => state.campusesIsOpen;
export const count: GetFromState<number, ICollegeCampusesState> = (
  state: ICollegeCampusesState,
): number => state.count;
export const getOrderBy: GetFromState<SORT_DIRECTION, ICollegeCampusesState> = (
  state: ICollegeCampusesState,
): SORT_DIRECTION => state.orderBy;
export const getSortBy: GetFromState<
  COLLEGE_CAMPUSES_SORTING,
  ICollegeCampusesState
> = (state: ICollegeCampusesState): COLLEGE_CAMPUSES_SORTING => state.sortBy;

export const collegeCampusesQueryParams: GetFromState<
  Partial<ICollegeCampusesState>,
  ICollegeCampusesState
> = (state: ICollegeCampusesState): Partial<ICollegeCampusesState> => {
  const { limit, offset, sortBy, orderBy }: ICollegeCampusesState = state;
  return { limit, offset, sortBy, orderBy };
};

export const campusesParamsWithOfficeId: GetFromState<any, number, Params> = (
  id: number,
  params: Params,
): any => ({
  id,
  ...params,
});

export const currentPageByParams: GetFromState<
  number,
  ICollegeCampusesState
> = (state: ICollegeCampusesState): number => {
  const { offset, limit }: ICollegeCampusesState = state;

  return Math.ceil(offset / limit) + 1;
};
