import { createSelector, MemoizedSelector } from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import {
  exportingLists,
  IListPurchaseState,
} from "@core/store/reducers/list-purchase.reducer";
import * as listPurchase from "@core/store/reducers/list-purchase.reducer";
import {
  getRouterParams,
  getRouterQueryParams,
} from "@core/store/selectors/router.selector";
import { getCredits } from "@modules/credits/store/selectors/credits.selector";
import { selectListAppendsState } from "@modules/list-common-store/store/selectors";
import { getIsUnlimited } from "@modules/profile/store/selectors/profile.selector";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";

import { IListMainTypes } from "@modules/list-shared/interfaces";
import { IListCreditInfo } from "@modules/list/interfaces/generate-list-params";
import {
  ISegmentData,
  ISegmentTargetingCriteria,
} from "@modules/segment/interfaces/segment";
import { IDataListSummary, ISummaryList } from "@shared/interfaces/list";

export const listPurchaseState: GetFromStateInSelectors<
  CoreState,
  IListPurchaseState
> = (state: CoreState): IListPurchaseState =>
  state && state.customerListPurchase;

export const getListSummaryData: MemoizedSelector<CoreState, IDataListSummary> =
  createSelector(listPurchaseState, listPurchase.listSummaryData);
export const getListSummaryDataList: MemoizedSelector<CoreState, ISummaryList> =
  createSelector(getListSummaryData, listPurchase.listSummaryDataList);

export const getListSummaryActiveSegmentId: MemoizedSelector<
  CoreState,
  number
> = createSelector(
  getListSummaryDataList,
  getRouterParams,
  listPurchase.listRefreshOrSummaryActiveSegmentId,
);

export const getListSummaryActiveSegment: MemoizedSelector<
  CoreState,
  ISegmentData
> = createSelector(
  listPurchaseState,
  (state: IListPurchaseState) => state.listSummarySegmentData,
);

export const getListMainTypes: MemoizedSelector<CoreState, IListMainTypes> =
  createSelector(getListSummaryDataList, listPurchase.listMainTypes);
export const getListSummaryDataListPremiumCredits: MemoizedSelector<
  CoreState,
  number
> = createSelector(
  getListSummaryDataList,
  listPurchase.listSummaryDataListPremiumCredits,
);

export const getListSummarySegmentTargeting: MemoizedSelector<
  CoreState,
  ISegmentTargetingCriteria
> = createSelector(listPurchaseState, listPurchase.listSummarySegmentTargeting);

export const getListRefreshData: MemoizedSelector<CoreState, IDataListSummary> =
  createSelector(listPurchaseState, listPurchase.listRefreshData);
export const getListRefreshDataList: MemoizedSelector<CoreState, ISummaryList> =
  createSelector(getListRefreshData, listPurchase.listRefreshDataList);
export const getListRefreshActiveSegmentId: MemoizedSelector<
  CoreState,
  number
> = createSelector(
  getListRefreshDataList,
  getRouterParams,
  listPurchase.listRefreshOrSummaryActiveSegmentId,
);

export const getListMainTypesRefreshData: MemoizedSelector<
  CoreState,
  IListMainTypes
> = createSelector(getListRefreshDataList, listPurchase.listMainTypes);

export const getRefreshSegmentTargeting: MemoizedSelector<
  CoreState,
  ISegmentTargetingCriteria
> = createSelector(listPurchaseState, listPurchase.refreshSegmentTargeting);
export const getFetching: MemoizedSelector<CoreState, boolean> = createSelector(
  listPurchaseState,
  listPurchase.fetching,
);

export const getIsPurchasing: MemoizedSelector<CoreState, boolean> =
  createSelector(listPurchaseState, listPurchase.purchasing);

export const getIsRePurchasing: MemoizedSelector<CoreState, boolean> =
  createSelector(listPurchaseState, listPurchase.rePurchasing);
export const getRePurchasingListId: MemoizedSelector<CoreState, number> =
  createSelector(listPurchaseState, listPurchase.rePurchasingListId);

export const getIsRefreshing: MemoizedSelector<CoreState, boolean> =
  createSelector(listPurchaseState, listPurchase.refreshing);
export const getWithAdditional: MemoizedSelector<CoreState, boolean> =
  createSelector(listPurchaseState, listPurchase.withAdditional);

export const getCanPurchase: MemoizedSelector<CoreState, boolean> =
  createSelector(
    getIsUnlimited,
    getListSummaryData,
    getCredits,
    listPurchase.canConfirm,
  );
export const getCanRefresh: MemoizedSelector<CoreState, boolean> =
  createSelector(
    getIsUnlimited,
    getListRefreshData,
    getCredits,
    listPurchase.canConfirm,
  );
export const getIsForRefresh: MemoizedSelector<CoreState, boolean> =
  createSelector(getRouterQueryParams, listPurchase.isForRefreshed);
export const isPurchaseActivateBtnDisabled: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(
  getIsUnlimited,
  getListSummaryData,
  listPurchase.isActivateBtnDisabled,
);

// for purchase
export const getListSummaryOrRefreshData: MemoizedSelector<
  CoreState,
  IDataListSummary
> = createSelector(
  getListSummaryData,
  getListRefreshData,
  getIsForRefresh,
  listPurchase.summaryOrRefreshData,
);
export const getListSummaryOrRefreshCredits: MemoizedSelector<
  CoreState,
  number
> = createSelector(
  getListSummaryOrRefreshData,
  listPurchase.listSummaryOrRefreshCredits,
);
export const getListSummaryOrRefreshCost: MemoizedSelector<CoreState, number> =
  createSelector(
    getIsForRefresh,
    getWithAdditional,
    getListSummaryOrRefreshCredits,
    listPurchase.listCostForRefresh,
  );

export const getListSummaryOrRefreshPremium: MemoizedSelector<
  CoreState,
  number
> = createSelector(
  getListSummaryOrRefreshData,
  listPurchase.listSummaryOrRefreshPremium,
);
export const getListSummaryOrRefreshAppends: MemoizedSelector<
  CoreState,
  number
> = createSelector(
  getListSummaryOrRefreshData,
  listPurchase.listSummaryOrRefreshAppends,
);

export const getListSummaryOrRefreshCreditsInfo: MemoizedSelector<
  CoreState,
  IListCreditInfo
> = createSelector(
  getListSummaryOrRefreshCost,
  getListSummaryOrRefreshAppends,
  getListSummaryOrRefreshPremium,
  listPurchase.listSummaryOrRefreshCreditsInfo,
);

// for appends/active appends
export const getPurchaseListCost: MemoizedSelector<CoreState, number> =
  createSelector(
    getListSummaryOrRefreshData,
    getIsForRefresh,
    listPurchase.purchaseListCost,
  );
export const getPurchaseListPremiumCost: MemoizedSelector<CoreState, number> =
  createSelector(
    getListSummaryOrRefreshData,
    getIsForRefresh,
    listPurchase.purchaseListPremiumCost,
  );
export const getPurchaseListAppendsCost: MemoizedSelector<CoreState, number> =
  createSelector(
    getListSummaryOrRefreshData,
    getIsForRefresh,
    listPurchase.purchaseListAppendsCost,
  );
export const getPurchaseListAppendsPageAppendsCost: MemoizedSelector<
  CoreState,
  number
> = createSelector(
  getListSummaryOrRefreshData,
  getIsForRefresh,
  selectListAppendsState,
  listPurchase.purchaseListAppendsPageAppendsCost,
);
export const getIsActiveListPurchaseBtnDisabled: MemoizedSelector<
  CoreState,
  boolean
> = createSelector(
  getListSummaryOrRefreshData,
  listPurchase.isActiveListPurchaseBtnDisabled,
);

export const getExportingLists: MemoizedSelector<
  CoreState,
  { [key: string]: number }
> = createSelector(listPurchaseState, exportingLists);
