import { Inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { select, Store } from "@ngrx/store";

import { Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";

import { CoreState } from "@core/store/reducers";
import { getIsLoggedIn } from "../store/selectors/auth.selector";

import { CORE_PATHS } from "@core/constants/core-paths";
import { AUTH_PATHS } from "../constants/auth-paths";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";
import { DASHBOARD_PATHS } from "@modules/dashboard/constants/dashboard-paths";
import {
  invitationAPICallsAction,
  signInSuccessAction,
} from "../store/actions/auth.action";

@Injectable()
export class AuthGuard {
  constructor(
    private _store: Store<CoreState>,
    private _router: Router,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.isLoggedIn(next);
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return this.isLoggedIn(next);
  }

  canLoad(route: Route): Observable<boolean> {
    return this.isLoggedIn(route);
  }

  isOktaLoggedIn(next: ActivatedRouteSnapshot | Route): Observable<boolean> {
    // stall flow when redirected from SSO dashboard;
    // okta signInWithRedirect continues in app-load.service
    if (next["queryParams"] && next["queryParams"]["iss"]) {
      return of(false);
    }

    if (!this.oktaAuth.getAccessToken()) {
      // this._store.dispatch(invitationAPICallsAction());
      this._router.navigateByUrl(
        (next && next.data && next.data.authGuardRedirect) ||
          `${CORE_PATHS.AUTH}/${AUTH_PATHS.LOGIN}`,
      );
    }
    if (!next["path"] && !!this.oktaAuth.getAccessToken()) {
      this._router.navigateByUrl(
        next.data.authGuardRedirect ||
          `${CORE_PATHS.DASHBOARD}/${DASHBOARD_PATHS.LISTS}`,
      );
    }
    return of(true);
  }

  private isLoggedIn(
    next: ActivatedRouteSnapshot | Route,
  ): Observable<boolean> {
    return this._store.pipe(
      select(getIsLoggedIn),
      take(1),
      map((isLoggedIn: boolean) => {
        if (!isLoggedIn) {
          this.isOktaLoggedIn(next);
        } else {
          return isLoggedIn;
        }
      }),
    );
  }
}
