import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "bl-custom-spinner",
  templateUrl: "./custom-spinner.component.html",
  styleUrls: ["./custom-spinner.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomSpinnerComponent {
  @Input() width: string = "100%";
  @Input() minHeight: string = "400px";
}
