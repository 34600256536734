import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/view.action";

import { ITargetingType } from "@shared/interfaces/list";
import { IProductCounter } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";
import { IViewData } from "@shared/interfaces/view";
import { IMVEntityAvailable } from "../../../profile/interfaces/mv-entity-available";
import { ISegmentData } from "../../../segment/interfaces/segment";

import { TARGETING_TYPES_ID } from "@shared/constants/data/list-types";
import { VIEW_TARGETING_TYPES } from "../../../segment/constants/targeting-types";

export interface ICustomerViewState {
  view: IViewData | null;
  pending: boolean;
  error: IServerError | null;

  renaming: boolean;
  renameError: IServerError | null;
}

const initialState: ICustomerViewState = {
  view: null,
  pending: false,
  error: null,

  renaming: false,
  renameError: null,
};

const createView: OnReducer<ICustomerViewState, ActionType<any>> = (
  state: ICustomerViewState,
) => ({
  ...state,
  error: null,
  pending: true,
});

const createViewError: OnReducer<
  ICustomerViewState,
  ActionType<Payload<IServerError>>
> = (state: ICustomerViewState, { payload }: Payload<IServerError>) => ({
  ...state,
  error: { ...payload },
  pending: false,
});

const createViewSuccess: OnReducer<
  ICustomerViewState,
  ActionType<Payload<any>>
> = (state: ICustomerViewState, { payload: { viewData } }: Payload<any>) => ({
  ...state,
  pending: false,
  view: viewData,
});

const viewPending: OnReducer<ICustomerViewState, ActionType<any>> = (
  state: ICustomerViewState,
) => ({
  ...state,
  pending: true,
  error: null,
});

const viewPendingSuccess: OnReducer<ICustomerViewState, ActionType<any>> = (
  state: ICustomerViewState,
) => ({
  ...state,
  pending: false,
  error: null,
});

const errorView: OnReducer<ICustomerViewState, ActionType<Payload<any>>> = (
  state: ICustomerViewState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const renameView: OnReducer<ICustomerViewState, ActionType<any>> = (
  state: ICustomerViewState,
) => ({
  ...state,
  pending: true,
  renaming: true,
  renameError: null,
});

const renameViewError: OnReducer<
  ICustomerViewState,
  ActionType<Payload<IServerError>>
> = (state: ICustomerViewState, { payload }: Payload<IServerError>) => ({
  ...state,
  pending: false,
  renaming: false,
  renameError: { ...payload },
});

const renameViewSuccess: OnReducer<
  ICustomerViewState,
  ActionType<Payload<any>>
> = (state: ICustomerViewState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  view: {
    ...state.view,
    ...payload,
  },
  renaming: false,
  renameError: null,
});

const renameViewCancel: OnReducer<ICustomerViewState, ActionType<any>> = (
  state: ICustomerViewState,
) => ({
  ...state,
  renaming: false,
  renameError: null,
});

const getViewSuccess: OnReducer<
  ICustomerViewState,
  ActionType<Payload<any>>
> = (state: ICustomerViewState, { payload }: Payload<any>) => ({
  ...state,
  pending: !!payload.listingCountsCalculating,
  view: { ...payload },
});

const updateViewState: OnReducer<
  ICustomerViewState,
  ActionType<Payload<any>>
> = (state: ICustomerViewState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  view: { ...state.view, ...payload },
});

const resetViewStore: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ICustomerViewState> =
  createReducer<ICustomerViewState>(
    initialState,

    on(actions.createViewAction, createView),
    on(actions.createViewErrorAction, createViewError),
    on(actions.createViewSuccessAction, createViewSuccess),

    on(actions.reloadViewWithRedirectAction, viewPending),
    on(actions.viewChangesStartAction, viewPending),

    on(actions.reloadViewWithRedirectSuccessAction, getViewSuccess),

    on(actions.viewChangesEndAction, viewPendingSuccess),
    on(actions.viewChangesResetAction, viewPendingSuccess),
    on(actions.viewChangesResetWithCache, viewPendingSuccess),

    on(actions.reloadViewWithRedirectErrorAction, errorView),

    on(actions.renameViewAction, renameView),
    on(actions.renameViewErrorAction, renameViewError),
    on(actions.renameViewSuccessAction, renameViewSuccess),
    on(actions.renameViewCancelAction, renameViewCancel),

    on(actions.updateViewStateAction, updateViewState),
  );

export function customerViewReducer(
  state: ICustomerViewState,
  action: Action,
): ICustomerViewState {
  return reducer(state, action);
}

export const view: GetFromState<IViewData, ICustomerViewState> = (
  state: ICustomerViewState,
): IViewData => state && state.view;
export const pending: GetFromState<boolean, ICustomerViewState> = (
  state: ICustomerViewState,
): boolean => state && state.pending;
export const viewError: GetFromState<IServerError, ICustomerViewState> = (
  state: ICustomerViewState,
): IServerError => state && state.error;

export const viewId: GetFromState<number, IViewData> = (
  viewData: IViewData,
): number => (viewData ? viewData.id : null);
export const viewName: GetFromState<string, IViewData> = (
  viewData: IViewData,
): string => viewData && viewData.name;

export const viewSegments: GetFromState<ISegmentData[], IViewData> = (
  viewData: IViewData,
): ISegmentData[] => viewData && viewData.segments;
export const viewSegmentId: GetFromState<number, Params> = ({
  segmentId: _segmentId,
}: Params): number => (_segmentId ? +_segmentId : null);
export const viewSegmentSelected: GetFromState<
  ISegmentData,
  ISegmentData[],
  number
> = (segments: ISegmentData[] = [], segmentId: number): ISegmentData =>
  segments.find(({ id }: ISegmentData) => id === segmentId) || null;

export const viewCounts: GetFromState<
  IProductCounter,
  IViewData,
  ISegmentData
> = (viewData: IViewData, segment: ISegmentData): IProductCounter => {
  if (segment && segment.id) {
    return {
      institutionsCount: segment.institutionsCount,
      grantsCount: segment.grantsCount,
      rfpIbfCount: segment.rfpIbfCount,
      count: segment.personsCount,
    };
  }
  if (viewData) {
    const { institutionsCount, grantsCount, rfpIbfCount, count }: IViewData =
      viewData;
    return { institutionsCount, grantsCount, rfpIbfCount, count };
  }

  return { institutionsCount: 0, grantsCount: 0, rfpIbfCount: 0, count: 0 };
};

export const viewRenameError: GetFromState<
  IServerError | null,
  ICustomerViewState
> = (state: ICustomerViewState): IServerError | null =>
  state && state.renameError;

export const targetingTypes: GetFromState<
  any,
  IMVEntityAvailable,
  IMVEntityAvailable
> = (
  _bidsAvailable: IMVEntityAvailable,
  _grantsAvailable: IMVEntityAvailable,
): any => {
  const _targetingTypes: typeof VIEW_TARGETING_TYPES = [
    ...VIEW_TARGETING_TYPES,
  ];

  return _targetingTypes.map((target: ITargetingType) => {
    if (target.id === TARGETING_TYPES_ID.RFP_IFB) {
      target.disabled = _bidsAvailable.disabled;
      target.callLink = _bidsAvailable.callLink;
      target.redirectLink = _bidsAvailable.redirectLink;
      target.tooltipText = _bidsAvailable.disabled ? _bidsAvailable.msg : "";
      target.iconClass = _bidsAvailable.disabled ? "tooltip--bids" : "";
    }

    if (target.id === TARGETING_TYPES_ID.GRANT_DETAILS) {
      target.disabled = _grantsAvailable.disabled;
      target.callLink = _grantsAvailable.callLink;
      target.redirectLink = _grantsAvailable.redirectLink;
      target.tooltipText = _grantsAvailable.disabled
        ? _grantsAvailable.msg
        : "";
      target.iconClass = _grantsAvailable.disabled ? "tooltip--grants" : "";
    }

    return target;
  });
};
