export const MAX_VIEW_RESULTS_PERSONNEL_CACHE: number = 60;
export const MAX_VIEW_RESULTS_INSTITUTION_CACHE: number = 60;
export const MAX_VIEW_RESULTS_COUNTERS_CACHE: number = 120;

export const MAX_VIEW_RESULTS_BIDS_CACHE: number = 30;
export const MAX_VIEW_RESULTS_GRANTS_CACHE: number = 30;

export const MAX_QUICK_SEARCH_CACHE: number = 180;

export const GET_RESULTS_ERROR_MSG: string =
  "Due to technical issues we can not display this page";

export enum CREATE_VIEW_LIST_TYPE {
  INSTITUTION_ALL = "institution_all",
  INSTITUTION_DISTRICT = "districts",
  INSTITUTION_SCHOOL = "schools",
  INSTITUTION_COLLEGE = "colleges",

  PERSONNEL_ALL = "personnel_all",
  PERSONNEL_DISTRICT = "personnel_districts",
  PERSONNEL_SCHOOL = "personnel_schools",
  PERSONNEL_COLLEGE = "personnel_colleges",
}

export enum CREATE_LIST_VARIANTS_KEY {
  FULL_INSTITUTION = "full_institution",
  FULL_PERSONNEL = "full_personnel",

  FROM_SELECTED_PERSONNEL_ALL = "from_selected_personnels",
  FROM_SELECTED_PERSONNEL_DISTRICT = "from_selected_personnel_district",
  FROM_SELECTED_PERSONNEL_SCHOOL = "from_selected_personnel_school",
  FROM_SELECTED_PERSONNEL_COLLEGE = "from_selected_personnel_college",

  FROM_SELECTED_INSTITUTION_ALL = "from_selected_institutions",
  FROM_SELECTED_INSTITUTION_DISTRICT = "from_selected_institution_district",
  FROM_SELECTED_INSTITUTION_SCHOOL = "from_selected_institution_school",
  FROM_SELECTED_INSTITUTION_COLLEGE = "from_selected_institution_college",

  INSTITUTION_DISTRICT = "districts",
  INSTITUTION_SCHOOL = "schools",
  INSTITUTION_COLLEGE = "colleges",

  PERSONNEL_DISTRICT = "personnel_districts",
  PERSONNEL_SCHOOL = "personnel_schools",
  PERSONNEL_COLLEGE = "personnel_colleges",
}

export type ICreateListVariantNames = {
  [key in CREATE_LIST_VARIANTS_KEY]: string;
};

export const CREATE_LIST_VARIANTS_NAME: ICreateListVariantNames = Object.freeze(
  {
    [CREATE_LIST_VARIANTS_KEY.FULL_INSTITUTION]: "Create Full List",
    [CREATE_LIST_VARIANTS_KEY.FULL_PERSONNEL]: "Create Full List",

    [CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_INSTITUTION_ALL]:
      "Create List from Selections",
    [CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_PERSONNEL_ALL]:
      "Create List from Selections",

    [CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_INSTITUTION_DISTRICT]:
      "Create List from Selections",
    [CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_INSTITUTION_SCHOOL]:
      "Create List from Selections",
    [CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_INSTITUTION_COLLEGE]:
      "Create List from Selections",

    [CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_PERSONNEL_DISTRICT]:
      "Create List from Selections",
    [CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_PERSONNEL_SCHOOL]:
      "Create List from Selections",
    [CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_PERSONNEL_COLLEGE]:
      "Create List from Selections",

    [CREATE_LIST_VARIANTS_KEY.INSTITUTION_DISTRICT]: "Create District List",
    [CREATE_LIST_VARIANTS_KEY.INSTITUTION_SCHOOL]: "Create School List",
    [CREATE_LIST_VARIANTS_KEY.INSTITUTION_COLLEGE]: "Create College List",

    [CREATE_LIST_VARIANTS_KEY.PERSONNEL_DISTRICT]:
      "Create District Personnel List",
    [CREATE_LIST_VARIANTS_KEY.PERSONNEL_SCHOOL]: "Create School Personnel List",
    [CREATE_LIST_VARIANTS_KEY.PERSONNEL_COLLEGE]:
      "Create College Personnel List",
  },
);

export type ICreateTypeVariants = CREATE_LIST_VARIANTS_KEY[];

export const CREATE_PERSONNEL_ALL: ICreateTypeVariants = [
  CREATE_LIST_VARIANTS_KEY.FULL_PERSONNEL,
  CREATE_LIST_VARIANTS_KEY.PERSONNEL_DISTRICT,
  CREATE_LIST_VARIANTS_KEY.PERSONNEL_SCHOOL,
  CREATE_LIST_VARIANTS_KEY.PERSONNEL_COLLEGE,
  CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_PERSONNEL_ALL,
];

export const CREATE_PERSONNEL_DISTRICT: ICreateTypeVariants = [
  CREATE_LIST_VARIANTS_KEY.FULL_PERSONNEL,
  CREATE_LIST_VARIANTS_KEY.PERSONNEL_DISTRICT,
  CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_PERSONNEL_DISTRICT,
];

export const CREATE_PERSONNEL_SCHOOL: ICreateTypeVariants = [
  CREATE_LIST_VARIANTS_KEY.FULL_PERSONNEL,
  CREATE_LIST_VARIANTS_KEY.PERSONNEL_SCHOOL,
  CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_PERSONNEL_SCHOOL,
];

export const CREATE_PERSONNEL_COLLEGE: ICreateTypeVariants = [
  CREATE_LIST_VARIANTS_KEY.FULL_PERSONNEL,
  CREATE_LIST_VARIANTS_KEY.PERSONNEL_COLLEGE,
  CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_PERSONNEL_COLLEGE,
];

export const CREATE_INSTITUTION_ALL: ICreateTypeVariants = [
  CREATE_LIST_VARIANTS_KEY.FULL_INSTITUTION,
  CREATE_LIST_VARIANTS_KEY.INSTITUTION_DISTRICT,
  CREATE_LIST_VARIANTS_KEY.INSTITUTION_SCHOOL,
  CREATE_LIST_VARIANTS_KEY.INSTITUTION_COLLEGE,
  CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_INSTITUTION_ALL,
];

export const CREATE_INSTITUTION_DISTRICT: ICreateTypeVariants = [
  CREATE_LIST_VARIANTS_KEY.FULL_INSTITUTION,
  CREATE_LIST_VARIANTS_KEY.INSTITUTION_DISTRICT,
  CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_INSTITUTION_DISTRICT,
];

export const CREATE_INSTITUTION_SCHOOL: ICreateTypeVariants = [
  CREATE_LIST_VARIANTS_KEY.FULL_INSTITUTION,
  CREATE_LIST_VARIANTS_KEY.INSTITUTION_SCHOOL,
  CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_INSTITUTION_SCHOOL,
];

export const CREATE_INSTITUTION_COLLEGE: ICreateTypeVariants = [
  CREATE_LIST_VARIANTS_KEY.FULL_INSTITUTION,
  CREATE_LIST_VARIANTS_KEY.INSTITUTION_COLLEGE,
  CREATE_LIST_VARIANTS_KEY.FROM_SELECTED_INSTITUTION_COLLEGE,
];

export type ICreateListVariantsByType = {
  [key in CREATE_VIEW_LIST_TYPE]: ICreateTypeVariants;
};
export const CREATE_VIEW_LIST_BY_TYPE: ICreateListVariantsByType =
  Object.freeze({
    [CREATE_VIEW_LIST_TYPE.INSTITUTION_ALL]: CREATE_INSTITUTION_ALL,
    [CREATE_VIEW_LIST_TYPE.INSTITUTION_COLLEGE]: CREATE_INSTITUTION_COLLEGE,
    [CREATE_VIEW_LIST_TYPE.INSTITUTION_SCHOOL]: CREATE_INSTITUTION_SCHOOL,
    [CREATE_VIEW_LIST_TYPE.INSTITUTION_DISTRICT]: CREATE_INSTITUTION_DISTRICT,

    [CREATE_VIEW_LIST_TYPE.PERSONNEL_ALL]: CREATE_PERSONNEL_ALL,
    [CREATE_VIEW_LIST_TYPE.PERSONNEL_SCHOOL]: CREATE_PERSONNEL_SCHOOL,
    [CREATE_VIEW_LIST_TYPE.PERSONNEL_COLLEGE]: CREATE_PERSONNEL_COLLEGE,
    [CREATE_VIEW_LIST_TYPE.PERSONNEL_DISTRICT]: CREATE_PERSONNEL_DISTRICT,
  });
