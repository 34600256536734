import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from "@ngrx/store";

import { CoreState } from "@core/store/reducers";
import { INotificationState } from "@modules/notifications/store/reducers/notification.reducer";
import { GetFromStateInSelectors } from "@shared/store/types/selector.types";
import { INotificationModuleState, NOTIFICATION_NAME } from "../reducers";

export const getNotificationModuleState: GetFromStateInSelectors<
  CoreState,
  INotificationModuleState
> = createFeatureSelector<INotificationModuleState>(NOTIFICATION_NAME);

export const selectNotificationState: MemoizedSelector<
  CoreState,
  INotificationState
> = createSelector(
  getNotificationModuleState,
  (state: INotificationModuleState) => state && state.notifications,
);
