import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { select, Store } from "@ngrx/store";

import { of, throwError, Observable } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  take,
  withLatestFrom,
} from "rxjs/operators";

import { getIsLoggedIn } from "@modules/auth/store/selectors/auth.selector";
import { getInvitationExistedUser } from "@modules/auth/store/selectors/invitation.selector";
import {
  getProfileErrorAction,
  getProfileSuccessAction,
} from "@modules/profile/store/actions/profile.action";

import { IInvitationUser } from "@modules/auth/interfaces/invitation";
import { IUserData } from "@modules/auth/interfaces/user";
import { IServerError } from "@shared/interfaces/server-error";
import { GainSightAnalyticsTagService } from "@modules/gain-sight-analytics/services/gain-sight-analytics-tag.service";
import { ProfileService } from "../services/profile.service";
import { CoreState } from "../store/reducers";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";
import { INVITATION_ACTION } from "@modules/auth/constants/invitation";

@Injectable({
  providedIn: "root",
})
export class UserDataExistGuard {
  constructor(
    private _store: Store<CoreState>,
    private _profileService: ProfileService,
    private _gainSightAnalyticsService: GainSightAnalyticsTagService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._store.pipe(
      select(getIsLoggedIn),
      take(1),
      withLatestFrom(this._store.pipe(select(getInvitationExistedUser))),
      switchMap(([isLoggedIn, invitation]: [boolean, IInvitationUser]) => {
        let loggedInFlag = isLoggedIn || !!this.oktaAuth.getAccessToken();
        let invitationFlag =
          !invitation ||
          (invitation &&
            invitation.action &&
            invitation.action.toString() ===
              INVITATION_ACTION.OWNER_KEEP_SEPARATE.toString());

        if (loggedInFlag && invitationFlag) {
          return this._profileService.loadUser().pipe(
            map((_user: IUserData) => {
              this._gainSightAnalyticsService.callGainSight(_user.user, _user);
              this._store.dispatch(getProfileSuccessAction(_user));
              return true;
            }),
            catchError((error: IServerError) => {
              this._store.dispatch(getProfileErrorAction(error));
              return throwError(error);
            }),
          );
        }
        // return of(isLoggedIn);
      }),
      catchError(() => of(false)),
    );
  }
}
