import {
  ComponentFactoryResolver,
  ComponentRef,
  Injectable,
  Injector,
} from "@angular/core";

import { DropDownPortalComponent } from "../components/drop-down-portal/drop-down-portal.component";

import { PortalService } from "@core/services/portal.service";

import { DropDownRef } from "../models/drop-down-ref";

import { CustomInjector } from "@core/custom-injector";

@Injectable()
export class DropDownService {
  private _openedDropDown: DropDownRef = null;

  constructor(
    private _injector: Injector,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _portalService: PortalService,
  ) {}

  open(dropDownRef: DropDownRef): void {
    this.close();

    const injector: CustomInjector = this.createInjector(dropDownRef);
    const detachFn: () => void = this.attachPortal(injector);

    dropDownRef.setDetachFunction(detachFn);

    dropDownRef.afterClose.subscribe(() => (this._openedDropDown = null));

    this._openedDropDown = dropDownRef;
  }

  close(): void {
    if (this._openedDropDown) {
      this._openedDropDown.close();
    }
  }

  private createInjector(dropDownRef: DropDownRef): CustomInjector {
    const tokens: WeakMap<object, any> = new WeakMap();

    tokens.set(DropDownRef, dropDownRef);

    return new CustomInjector(this._injector, tokens);
  }

  private attachPortal(injector: Injector): () => void {
    const componentRef: ComponentRef<DropDownPortalComponent> =
      this._componentFactoryResolver
        .resolveComponentFactory(DropDownPortalComponent)
        .create(injector);

    return this._portalService.attachComponentRef(componentRef);
  }
}
