import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/quick-search-results-institution-colleges.action";

import { IInstitutionsRecord } from "@shared/interfaces/product";
import { IServerError } from "@shared/interfaces/server-error";

import { INSTITUTIONS_DEFAULT_PARAMS } from "@shared/constants/data/product-results";

export interface IQuickSearchResultsInstitutionCollegesState {
  colleges: IInstitutionsRecord[];
  pending: boolean;
  error: IServerError | null;

  count: number;
}

const initialState: IQuickSearchResultsInstitutionCollegesState = {
  colleges: [],
  pending: false,
  error: null,

  count: 0,
};

const quickSearchInstitutionColleges: OnReducer<
  IQuickSearchResultsInstitutionCollegesState,
  ActionType<any>
> = (state: IQuickSearchResultsInstitutionCollegesState) => ({
  ...state,
  pending: true,
  error: null,
});

const getQuickSearchInstitutionCollegesError: OnReducer<
  IQuickSearchResultsInstitutionCollegesState,
  ActionType<Payload<IServerError>>
> = (
  state: IQuickSearchResultsInstitutionCollegesState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getQuickSearchInstitutionCollegesSuccess: OnReducer<
  IQuickSearchResultsInstitutionCollegesState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsInstitutionCollegesState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: false,
  colleges: [...payload.items],
  count: payload.count,
});

const setQuickSearchInstitutionCollegesSpinnerState: OnReducer<
  IQuickSearchResultsInstitutionCollegesState,
  ActionType<Payload<any>>
> = (
  state: IQuickSearchResultsInstitutionCollegesState,
  { payload }: Payload<any>,
) => ({
  ...state,
  pending: payload,
});

const resetQuickSearchInstitutionCollegesState: OnReducer<
  any,
  ActionType<any>
> = () => ({
  ...initialState,
});

const reducer: ActionReducer<IQuickSearchResultsInstitutionCollegesState> =
  createReducer<IQuickSearchResultsInstitutionCollegesState>(
    initialState,

    on(
      actions.getQuickSearchInstitutionCollegesAction,
      quickSearchInstitutionColleges,
    ),
    on(
      actions.getQuickSearchInstitutionCollegesErrorAction,
      getQuickSearchInstitutionCollegesError,
    ),
    on(
      actions.getQuickSearchInstitutionCollegesSuccessAction,
      getQuickSearchInstitutionCollegesSuccess,
    ),

    on(
      actions.changeQuickSearchInstitutionCollegesPageAction,
      quickSearchInstitutionColleges,
    ),
    on(
      actions.changeQuickSearchInstitutionCollegesQueryAction,
      quickSearchInstitutionColleges,
    ),

    on(
      actions.setQuickSearchInstitutionCollegesSpinnerStateAction,
      setQuickSearchInstitutionCollegesSpinnerState,
    ),

    on(
      actions.resetQuickSearchInstitutionCollegesStateAction,
      resetQuickSearchInstitutionCollegesState,
    ),
  );

export function quickSearchResultsInstitutionCollegesReducer(
  state: IQuickSearchResultsInstitutionCollegesState,
  action: Action,
): IQuickSearchResultsInstitutionCollegesState {
  return reducer(state, action);
}

export const colleges: GetFromState<
  IInstitutionsRecord[],
  IQuickSearchResultsInstitutionCollegesState
> = (
  state: IQuickSearchResultsInstitutionCollegesState,
): IInstitutionsRecord[] => state.colleges;
export const pending: GetFromState<
  boolean,
  IQuickSearchResultsInstitutionCollegesState
> = (state: IQuickSearchResultsInstitutionCollegesState): boolean =>
  state.pending;
export const collegesError: GetFromState<
  IServerError,
  IQuickSearchResultsInstitutionCollegesState
> = (state: IQuickSearchResultsInstitutionCollegesState): IServerError =>
  state.error;

export const collegesPaginationCount: GetFromState<
  number,
  IQuickSearchResultsInstitutionCollegesState
> = (state: IQuickSearchResultsInstitutionCollegesState): number => state.count;

export const currentPageByUrl: GetFromState<number, Params> = (
  state: Params,
): number => {
  const { offset, limit }: Params = state;

  return Math.ceil(offset / limit) + 1;
};

export const requestParams: GetFromState<Params, Params, Params> = (
  queryParams: Params,
  additionalParams: Params,
): Params => {
  return {
    ...INSTITUTIONS_DEFAULT_PARAMS,
    ...queryParams,
    ...additionalParams,
    filterBy: "colleges",
  };
};
