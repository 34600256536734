import { createAction } from "@ngrx/store";
import { ActionCreator, TypedAction } from "@ngrx/store/src/models";

import { CreateActionType } from "@shared/store/types/action.types";
import { createPayload } from "@shared/utils/store";

import { IServerError } from "@shared/interfaces/server-error";
import { ISelectedPersons } from "@shared/modules/personnel-entity/interfaces/personnel-entity";
import {
  IDistrict,
  IDistrictProfileId,
} from "../../interfaces/district-profile";

enum ACTION_TYPES {
  GET_DISTRICT_PROFILE = "[DISTRICT PROFILE]: Get district profile",
  GET_DISTRICT_PROFILE_ERROR = "[DISTRICT PROFILE]: Get district profile error",
  GET_DISTRICT_PROFILE_SUCCESS = "[DISTRICT PROFILE]: Get district profile success",

  CREATE_LIST_FROM_SELECTED = "[DISTRICT PROFILE] Create list from selected persons",

  RESET_DISTRICT_PROFILE = "[DISTRICT PROFILE]: Reset district profile state",
}

export const getDistrictProfileAction: CreateActionType<
  ACTION_TYPES.GET_DISTRICT_PROFILE,
  IDistrictProfileId
> = createAction(
  ACTION_TYPES.GET_DISTRICT_PROFILE,
  createPayload<IDistrictProfileId>(),
);
export const getDistrictProfileErrorAction: CreateActionType<
  ACTION_TYPES.GET_DISTRICT_PROFILE_ERROR,
  IServerError
> = createAction(
  ACTION_TYPES.GET_DISTRICT_PROFILE_ERROR,
  createPayload<IServerError>(),
);
export const getDistrictProfileSuccessAction: CreateActionType<
  ACTION_TYPES.GET_DISTRICT_PROFILE_SUCCESS,
  IDistrict
> = createAction(
  ACTION_TYPES.GET_DISTRICT_PROFILE_SUCCESS,
  createPayload<IDistrict>(),
);

export const createListFromSelectedAction: CreateActionType<
  ACTION_TYPES.CREATE_LIST_FROM_SELECTED,
  ISelectedPersons
> = createAction(
  ACTION_TYPES.CREATE_LIST_FROM_SELECTED,
  createPayload<ISelectedPersons>(),
);

export const resetProfileDistrictAction: ActionCreator<
  ACTION_TYPES.RESET_DISTRICT_PROFILE,
  () => TypedAction<ACTION_TYPES.RESET_DISTRICT_PROFILE>
> = createAction(ACTION_TYPES.RESET_DISTRICT_PROFILE);
