import {
  ICommonListStatusesByIds,
  IListsStatus,
  IListStatuses,
} from "../../interfaces/list";

import { LIST_REDIRECT_TO, LIST_STATUS_ID } from "./list-statuses";
import { LIST_STATUS_ID_NAME } from "./list-types";

export const CUSTOM_LIST_STATUSES: IListsStatus[] = [
  {
    id: LIST_STATUS_ID.ACTIVE,
    status: LIST_STATUS_ID_NAME.ACTIVE,
    canEdit: true,
    availableTo: ["duplicate", "rename", "delete"],
    redirectTo: LIST_REDIRECT_TO.SUMMARY,
    shouldCheckStatus: false,
  },
  {
    id: LIST_STATUS_ID.PROCESSING,
    status: LIST_STATUS_ID_NAME.PROCESSING,
    canEdit: false,
    availableTo: ["duplicate"],
    redirectTo: LIST_REDIRECT_TO.DASHBOARD,
    shouldCheckStatus: true,
  },
  {
    id: LIST_STATUS_ID.STATUS_PROCESSING_WITH_PERCENTS,
    status: LIST_STATUS_ID_NAME.STATUS_PROCESSING_WITH_PERCENTS,
    canEdit: false,
    availableTo: [],
    redirectTo: LIST_REDIRECT_TO.CAN_NOT_REDIRECT,
    shouldCheckStatus: true,
  },
];

export const CUSTOM_LIST_STATUSES_MAP: IListStatuses =
  CUSTOM_LIST_STATUSES.reduce(
    (res: ICommonListStatusesByIds<IListsStatus>, status: IListsStatus) => {
      res[status.id] = status;
      return res;
    },
    {},
  );
