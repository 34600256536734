import { Params } from "@angular/router";
import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import * as actions from "../actions/school-district-schools.action";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IDistrictSchool,
  IDistrictSchools,
} from "@shared/modules/institution-profiles/interfaces/district-school";

import { SORT_DIRECTION } from "@shared/constants/sort";
import { DISTRICT_SCHOOLS_SORTING } from "../../constants/school-district-schools";

export interface ISchoolDistrictSchoolsState {
  schools: IDistrictSchools;
  pending: boolean;
  loaded: boolean;
  schoolsIsOpen: boolean;
  error: IServerError | null;

  count: number;

  limit: number;
  offset: number;
  sortBy: DISTRICT_SCHOOLS_SORTING;
  orderBy: SORT_DIRECTION;

  checkedSchools: number[];
  isCheckedAllSchools: boolean;
}

const initialState: ISchoolDistrictSchoolsState = {
  schools: [],
  pending: false,
  loaded: false,
  schoolsIsOpen: false,
  error: null,

  count: 0,

  limit: 20,
  offset: 0,
  sortBy: DISTRICT_SCHOOLS_SORTING.NAME,
  orderBy: SORT_DIRECTION.ASC,

  checkedSchools: [],
  isCheckedAllSchools: false,
};

const getSchools: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: ISchoolDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  pending: true,
  error: null,
  offset: typeof payload === "number" ? (payload - 1) * state.limit : 0,
});

const getSchoolsError: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<Payload<IServerError>>
> = (
  state: ISchoolDistrictSchoolsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const getSchoolsSuccess: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: ISchoolDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  loaded: true,
  schools: [...payload.items],
  count: payload.count,
  schoolsIsOpen: !![...state.schools, ...payload.items].length,
});

const resetCheckedSchools: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<any>
> = (state: ISchoolDistrictSchoolsState) => ({
  ...state,
  checkedSchools: [],
  isCheckedAllSchools: false,
});

const sortSchools: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: ISchoolDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  ...payload,
  pending: true,
  error: null,
});

const sortSchoolsError: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<Payload<IServerError>>
> = (
  state: ISchoolDistrictSchoolsState,
  { payload }: Payload<IServerError>,
) => ({
  ...state,
  pending: false,
  error: { ...payload },
});

const sortSchoolsSuccess: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: ISchoolDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  pending: false,
  schools: [...payload.items],
  count: payload.count,
});

const toggleSchools: OnReducer<ISchoolDistrictSchoolsState, ActionType<any>> = (
  state: ISchoolDistrictSchoolsState,
) => ({
  ...state,
  schoolsIsOpen: !state.schoolsIsOpen,
});

const setCheckedSchool: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: ISchoolDistrictSchoolsState, { payload }: Payload<any>) => {
  const newCheckedSchool: number[] = state.checkedSchools.includes(payload)
    ? state.checkedSchools.filter((entityId: number) => entityId !== payload)
    : [...state.checkedSchools, payload];

  return {
    ...state,
    isCheckedAllSchools: newCheckedSchool.length === state.count,
    checkedSchools: newCheckedSchool,
  };
};

const checkAllSchools: OnReducer<
  ISchoolDistrictSchoolsState,
  ActionType<Payload<any>>
> = (state: ISchoolDistrictSchoolsState, { payload }: Payload<any>) => ({
  ...state,
  isCheckedAllSchools: payload,
  checkedSchools: payload
    ? state.schools.map(
        (personnelItem: IDistrictSchool) => personnelItem.entity.entityId,
      )
    : [],
});

const resetSchoolsAction: OnReducer<any, ActionType<any>> = () => ({
  ...initialState,
});

const reducer: ActionReducer<ISchoolDistrictSchoolsState> =
  createReducer<ISchoolDistrictSchoolsState>(
    initialState,

    on(actions.getSchoolsAction, getSchools),
    on(actions.getSchoolsErrorAction, getSchoolsError),
    on(actions.getSchoolsSuccessAction, getSchoolsSuccess),

    on(actions.resetCheckedSchoolsAction, resetCheckedSchools),

    on(actions.sortSchoolsAction, sortSchools),
    on(actions.sortSchoolsErrorAction, sortSchoolsError),
    on(actions.sortSchoolsSuccessAction, sortSchoolsSuccess),

    on(actions.toggleSchoolsAction, toggleSchools),

    on(actions.setCheckedSchoolActions, setCheckedSchool),
    on(actions.checkAllSchoolsAction, checkAllSchools),

    on(actions.resetSchoolsAction, resetSchoolsAction),
  );

export function schoolDistrictSchoolsReducer(
  state: ISchoolDistrictSchoolsState,
  action: Action,
): ISchoolDistrictSchoolsState {
  return reducer(state, action);
}

export const schools: GetFromState<
  IDistrictSchools,
  ISchoolDistrictSchoolsState
> = (state: ISchoolDistrictSchoolsState): IDistrictSchools => state.schools;
export const pending: GetFromState<boolean, ISchoolDistrictSchoolsState> = (
  state: ISchoolDistrictSchoolsState,
): boolean => state.pending;
export const loaded: GetFromState<boolean, ISchoolDistrictSchoolsState> = (
  state: ISchoolDistrictSchoolsState,
): boolean => state.loaded;
export const schoolsIsOpen: GetFromState<
  boolean,
  ISchoolDistrictSchoolsState
> = (state: ISchoolDistrictSchoolsState): boolean => state.schoolsIsOpen;
export const count: GetFromState<number, ISchoolDistrictSchoolsState> = (
  state: ISchoolDistrictSchoolsState,
): number => state.count;
export const getOrderBy: GetFromState<
  SORT_DIRECTION,
  ISchoolDistrictSchoolsState
> = (state: ISchoolDistrictSchoolsState): SORT_DIRECTION => state.orderBy;
export const getSortBy: GetFromState<
  DISTRICT_SCHOOLS_SORTING,
  ISchoolDistrictSchoolsState
> = (state: ISchoolDistrictSchoolsState): DISTRICT_SCHOOLS_SORTING =>
  state.sortBy;

export const districtSchoolsError: GetFromState<
  IServerError,
  ISchoolDistrictSchoolsState
> = (state: ISchoolDistrictSchoolsState): IServerError => state.error;

export const districtSchoolsQueryParams: GetFromState<
  Partial<ISchoolDistrictSchoolsState>,
  ISchoolDistrictSchoolsState
> = (
  state: ISchoolDistrictSchoolsState,
): Partial<ISchoolDistrictSchoolsState> => {
  const {
    limit,
    offset,
    sortBy,
    orderBy,
  }: Partial<ISchoolDistrictSchoolsState> = state;
  return { limit, offset, sortBy, orderBy };
};

export const checkedDistrictSchool: GetFromState<
  number[],
  ISchoolDistrictSchoolsState
> = (state: ISchoolDistrictSchoolsState): number[] => state.checkedSchools;
export const isAllDistrictSchoolsChecked: GetFromState<
  boolean,
  ISchoolDistrictSchoolsState
> = (state: ISchoolDistrictSchoolsState): boolean => state.isCheckedAllSchools;

export const districtSchoolsParamsWithDistrictId: (
  id: number,
  entityType: string,
  params: Params,
) => any = (id: number, entityType: string, params: Params) => {
  return {
    id,
    entityType,
    ...params,
  };
};

export const currentPageByParams: GetFromState<
  number,
  ISchoolDistrictSchoolsState
> = (state: ISchoolDistrictSchoolsState): number => {
  const { offset, limit }: Partial<ISchoolDistrictSchoolsState> = state;

  return Math.ceil(offset / limit) + 1;
};
