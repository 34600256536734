import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";

import { WindowRef } from "@core/refs/window-ref.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { WINDOW_POINTS } from "../constants/view-points";

@Injectable({ providedIn: "root" })
export class ViewPointsService {
  constructor(private window: WindowRef) {}

  private _currentPoints$: BehaviorSubject<WINDOW_POINTS> = new BehaviorSubject(
    WINDOW_POINTS.PHONE,
  );

  set point(point: WINDOW_POINTS) {
    this._currentPoints$.next(point);
  }

  get currentPoints$(): Observable<WINDOW_POINTS> {
    return this._currentPoints$.asObservable();
  }

  listenerOnHideByPoints(...points: WINDOW_POINTS[]): Observable<boolean> {
    return this.currentPoints$.pipe(
      map(
        (currentPoint: WINDOW_POINTS) =>
          !!points.find((point: WINDOW_POINTS) => point === currentPoint),
      ),
      distinctUntilChanged(),
      catchErrorWithErrorType,
    );
  }

  checkViewPoints(): void {
    if (this.window.nativeElement.innerWidth <= WINDOW_POINTS.PHONE) {
      this.point = WINDOW_POINTS.PHONE;
      return;
    }

    if (
      this.window.nativeElement.innerWidth >= WINDOW_POINTS.PHONE &&
      this.window.nativeElement.innerWidth <= WINDOW_POINTS.TABLET
    ) {
      this.point = WINDOW_POINTS.TABLET;
      return;
    }

    if (
      this.window.nativeElement.innerWidth >= WINDOW_POINTS.TABLET &&
      this.window.nativeElement.innerWidth <= WINDOW_POINTS.DESKTOP
    ) {
      this.point = WINDOW_POINTS.DESKTOP;
      return;
    }

    if (this.window.nativeElement.innerWidth >= WINDOW_POINTS.DESKTOP) {
      this.point = WINDOW_POINTS.DESKTOP_LARGE;
    }
  }
}
